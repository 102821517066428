import { useEffect, useMemo, useCallback, useRef } from 'react';
import * as PIXI from 'pixi.js';
import * as d3 from 'd3';
import { toast } from 'react-toastify';

import { FloorplanCoordinates } from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import { Seconds } from 'lib/units';
import PlanSensor from 'lib/sensor';
import { AggregatedData, MappedPoint } from 'components/editor/state';
import { FloorplanTargetInfo } from 'components/track-visualizer';
import { FloorplanV2Plan } from 'lib/api';
import { Meters } from 'lib/units';
import { useAppSelector } from 'redux/store';

import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { OALiveSocketMessage } from 'lib/oa-live-socket';

import { Blue300, Blue600 } from '@density/dust/dist/tokens/dust.tokens';

// NOTE: Change these to affect square spacing and corner radius
// --------------------------------------------------------------
// This is the space between squares as a percentage of grid step
const AGGREGATED_POINTS_LAYER_SQUARE_SPACING = 0.25;
// This is corner radius as a percentage of resulting square size
const AGGREGATED_POINTS_LAYER_SQUARE_CORNER_RADIUS = 1 / 3;
// Track settings
const TRACK_FILL_COLOR = toRawHex(Blue300);
const TRACK_STROKE_COLOR = toRawHex(Blue600);
const TRACK_DIAMETER_PX = 18;
const TRACK_STROKE_WIDTH_PX = 2;
const TRACK_VISUAL_FADE_TIME_SECONDS = 1.5; // How long before the track visually fades out
const opacityScale = d3
  .scaleLinear()
  .domain([0, TRACK_VISUAL_FADE_TIME_SECONDS])
  .range([1, 0])
  .clamp(true);

// The aggregated points layer renders little squares indicating where points have been received by
// an oa sensor when it is "streaming" mode and data from the sensor is being sent to the frontend
// app over a websocket.
const AllSensorAggregatedPointsLayer: React.FunctionComponent<{
  gridSize?: number;
  colorScaleDomain?: [number, number];
  snrThreshold?: number;

  planId: FloorplanV2Plan['id'];
  sensors: FloorplanCollection<PlanSensor>;
  showEntryPoints?: boolean;
  showEntryTracks?: boolean;
  showOpenAreaPoints?: boolean;
  showOpenAreaTracks?: boolean;
}> = ({
  gridSize = Meters.fromInches(6),
  colorScaleDomain = [3, 8],
  snrThreshold = 0,
  planId,
  sensors,
  showEntryPoints,
  showEntryTracks,
  showOpenAreaPoints,
  showOpenAreaTracks,
}) => {
  const context = useFloorplanLayerContext();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const aggregatedPointsData = useRef<AggregatedData>([]);
  const aggregatedTracksData = useRef<Array<FloorplanTargetInfo>>([]);

  const onMessage = useCallback(
    (message: string) => {
      const timestamp = Seconds.fromMilliseconds(Date.now());
      const payload: OALiveSocketMessage = JSON.parse(message);

      const sensorSerial = payload.entity_id;
      const planSensor = Array.from(sensors.items)
        .map(([_id, s]) => s)
        .find((planSensor) => planSensor.serialNumber === sensorSerial);

      if (!planSensor) {
        console.warn(
          `Unable to find sensor ${payload.entity_id} on this floorplan!`
        );
        return;
      }

      const mappedPoints: Array<MappedPoint> = [];
      const mappedTracks: Array<FloorplanTargetInfo> = [];
      if (payload.message_type === 'targets') {
        if (
          (planSensor.type === 'entry' && showEntryTracks) ||
          (planSensor.type === 'oa' && showOpenAreaTracks)
        ) {
          payload.targets.forEach((target) => {
            mappedTracks.push({
              timestamp,
              position: FloorplanCoordinates.create(target.x, target.y),
              sensorSerial,
            });
          });
        }
      } else if (payload.message_type === 'points') {
        if (
          (planSensor.type === 'entry' && showEntryPoints) ||
          (planSensor.type === 'oa' && showOpenAreaPoints)
        ) {
          payload.points.forEach((point) => {
            mappedPoints.push({
              isSimulated: false,
              sensorId: planSensor.id,
              timestamp,
              floorplanPosition: FloorplanCoordinates.create(point.x, point.y),
            });
          });
        }
      }

      aggregatedPointsData.current =
        aggregatedPointsData.current.concat(mappedPoints);
      aggregatedTracksData.current =
        aggregatedTracksData.current.concat(mappedTracks);
    },
    [
      sensors.items,
      showEntryTracks,
      showOpenAreaTracks,
      showEntryPoints,
      showOpenAreaPoints,
    ]
  );

  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    let socket: WebSocket | null = null;

    const authURL = `/v2/presence/status/floorplan/${planId}/auth`;

    densityAPIClient
      .post(authURL)
      .then((response) => {
        const socketURL = `${response.data.url}?targets=true&points=true`;

        socket = new WebSocket(socketURL);

        socket.addEventListener('open', () => {
          aggregatedPointsData.current = [];
          aggregatedTracksData.current = [];
        });

        socket.addEventListener('message', (evt) => {
          const payload: unknown = evt.data;
          if (typeof payload !== 'string') {
            throw new Error('Socket message data must be of type string');
          }

          onMessage(payload);
        });

        socket.addEventListener('close', () => {
          aggregatedPointsData.current = [];
          aggregatedTracksData.current = [];
        });

        socket.addEventListener('error', () => {
          toast.error('Sensor data stream encountered an error!');
          aggregatedPointsData.current = [];
          aggregatedTracksData.current = [];
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error('Unable to stream sensor data!');
        aggregatedPointsData.current = [];
        aggregatedTracksData.current = [];
      });

    // Make sure the socket gets closed before unloading this component
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [densityAPIClient, onMessage, planId]);

  const colorScale = useMemo(() => {
    return d3
      .scaleSequential(d3.interpolateRgb(Blue300, Blue600))
      .clamp(true)
      .domain(colorScaleDomain);
  }, [colorScaleDomain]);

  // Create a graphics element that will be drawn to in order to display aggregated points
  useEffect(() => {
    const aggregatedPointsLayer = new PIXI.Graphics();
    aggregatedPointsLayer.name = 'aggregated-points-layer';
    aggregatedPointsLayer.x = 0;
    aggregatedPointsLayer.y = 0;

    const intervalId = setInterval(() => {
      if (aggregatedPointsData.current.length === 0) {
        return;
      }
      aggregatedPointsData.current = AggregatedData.releaseExpiredData(
        aggregatedPointsData.current
      );
    }, 10000);

    context.app.stage.addChild(aggregatedPointsLayer);
    return () => {
      context.app.stage.removeChild(aggregatedPointsLayer);
      clearInterval(intervalId);
    };
  }, [context.app.stage]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }

        const viewport = context.viewport.current;

        const aggregatedPointsLayer = context.app.stage.getChildByName(
          'aggregated-points-layer'
        ) as PIXI.Graphics;

        const scale = context.floorplan.scale * viewport.zoom;

        // Compute aggregated points buckets based off of raw point data
        const aggregatedPoints = new Map<number, Map<number, number>>();
        const now = Seconds.fromMilliseconds(Date.now());
        const data = aggregatedPointsData.current.filter((point) => {
          const isRecent = point.timestamp > now - 0.333;
          const isValid = point.isSimulated
            ? true
            : (point.sensorPoint?.snr || 0) >= snrThreshold;
          return isRecent && isValid;
        });
        for (const point of data) {
          const gridCoords = FloorplanCoordinates.toGridCoordinates(
            point.floorplanPosition,
            gridSize
          );
          let row = aggregatedPoints.get(gridCoords.y);
          if (typeof row === 'undefined') {
            row = new Map();
            aggregatedPoints.set(gridCoords.y, row);
          }
          const count = row.get(gridCoords.x);
          if (typeof count === 'undefined') {
            row.set(gridCoords.x, 1);
          } else {
            row.set(gridCoords.x, count + 1);
          }
        }

        // --------------------------------------------------------------
        // Don't try to tweak these
        const gridStepPixels = scale * gridSize;
        const squareComputedCornerOffset =
          (gridStepPixels * AGGREGATED_POINTS_LAYER_SQUARE_SPACING) / 2;
        const squareComputedSize =
          gridStepPixels - 2 * squareComputedCornerOffset;
        const squareComputedRadius =
          squareComputedSize * AGGREGATED_POINTS_LAYER_SQUARE_CORNER_RADIUS;

        aggregatedPointsLayer.clear();

        if (showEntryPoints || showOpenAreaPoints) {
          // Aggregated points display
          aggregatedPoints.forEach((row, y) => {
            row.forEach((count, x) => {
              // Make sure value is within the domain
              if (count < colorScaleDomain[0] || count > colorScaleDomain[1]) {
                return;
              }

              const pos = FloorplanCoordinates.toViewportCoordinates(
                FloorplanCoordinates.create(x * gridSize, y * gridSize),
                context.floorplan,
                viewport
              );

              const color = d3.rgb(colorScale(count) || Blue300).formatHex();
              aggregatedPointsLayer.beginFill(toRawHex(color));

              aggregatedPointsLayer.drawRoundedRect(
                pos.x + squareComputedCornerOffset,
                pos.y + squareComputedCornerOffset,
                squareComputedSize,
                squareComputedSize,
                squareComputedRadius
              );
            });
          });
        }

        if (showEntryTracks || showOpenAreaTracks) {
          for (const track of aggregatedTracksData.current) {
            const pos = FloorplanCoordinates.toViewportCoordinates(
              track.position,
              context.floorplan,
              viewport
            );

            const opacity = opacityScale(now - track.timestamp);
            if (opacity < 0) {
              continue;
            }
            aggregatedPointsLayer.beginFill(TRACK_FILL_COLOR, opacity);
            aggregatedPointsLayer.lineStyle({
              width: TRACK_STROKE_WIDTH_PX,
              color: TRACK_STROKE_COLOR,
              alpha: opacity,
            });
            aggregatedPointsLayer.drawCircle(
              pos.x,
              pos.y,
              TRACK_DIAMETER_PX / 2
            );
          }
        }
      }}
    />
  );
};

export default AllSensorAggregatedPointsLayer;
