import * as React from 'react';
import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';
import { Icons } from '@density/dust';
import styles from './styles.module.scss';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';

import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import AddressInputSelect from 'components/mapbox/address-input-select';
import { useMapboxSession } from 'components/mapbox/mapbox-session';

const isNumericString = (val: string): boolean => {
  const parsed = parseInt(val);
  if (isNaN(parsed)) {
    return false;
  }

  return true;
};

const numericStringSchema = z.string().refine(isNumericString);

const buildingFormSchema = z.object({
  name: z.string().min(1),
  capacity: numericStringSchema,

  area: numericStringSchema.nullable(),
  rent: numericStringSchema.nullable(),
  parentId: z.string().nullable(),

  latitude: z.number(),
  longitude: z.number(),

  timeZone: z.string().min(1),
  address: z.string().min(1),
});
type BuildingFormValues = z.infer<typeof buildingFormSchema>;
// A component to render an address for something and allow it to be edited by clicking on it
const EditableAddress: React.FunctionComponent<{
  address: string;
  leadingIcon?: React.ReactNode;
  buttons: boolean;
  placeholder: string;
  'data-cy'?: string;
  theme: 'dark' | 'light';
  loading: boolean;
  onEditAddress?: (
    address: string,
    time_zone: string,
    lat: number,
    lon: number
  ) => void;
  onEditCancel?: () => void;
}> = ({
  address,
  leadingIcon,
  buttons,
  theme,
  placeholder,
  'data-cy': dataCy,
  loading,
  onEditAddress,
  onEditCancel,
}) => {
  const [addressEditHovered, setAddressEditHovered] = useState(false);
  const [addressEditVisible, setAddressEditVisible] = useState(false);

  const isMouseDown = useRef<boolean>(false);

  // Keep a local copy of the building address cached locally for handling the in progress edits
  const [workingAddress, setWorkingAddress] = useState({
    address: address,
    timeZone: '',
    latitude: 0,
    longitude: 0,
  });

  const { sessionToken } = useMapboxSession();

  // Validation for the address using zod
  const { setValue, getValues } = useForm<BuildingFormValues>({
    resolver: zodResolver(buildingFormSchema),
    mode: 'onChange',
    defaultValues: undefined,
  });
  useEffect(() => {
    setWorkingAddress({ address, timeZone: '', latitude: 0, longitude: 0 });
  }, [address]);

  const isAddressEditable = typeof onEditAddress !== 'undefined';

  const onSubmit = useCallback(() => {
    setAddressEditVisible(false);
    setAddressEditHovered(false);

    if (!onEditAddress) {
      return;
    }

    // Only actually call onEditAddress if the user changed the address
    if (workingAddress.address === address) {
      return;
    }

    onEditAddress(
      workingAddress.address,
      workingAddress.timeZone,
      workingAddress.latitude,
      workingAddress.longitude
    );
  }, [address, workingAddress, onEditAddress]);

  let activationTarget: React.ReactNode = (
    <Fragment>
      {address}
      {addressEditHovered && buttons ? (
        <span className={styles.editableAddressEditHint}>
          <div className={styles.editableAddressEditHintIcon}>
            <Icons.PencilOutlineEditor size={14} />
          </div>
          Click to edit address
        </span>
      ) : null}
    </Fragment>
  );

  const handleInputChange = () => {
    const { address, timeZone, latitude, longitude } = getValues();
    setWorkingAddress({ address, timeZone, latitude, longitude });
  };

  return (
    <Fragment>
      {leadingIcon && (
        <span style={{ marginTop: 'auto', marginRight: '4px' }}>
          {!addressEditVisible && (leadingIcon || null)}
        </span>
      )}
      <div
        className={classnames(styles.editableAddress, {
          [styles.clickable]: isAddressEditable,
        })}
        data-cy={dataCy}
        // Only show and hide the building address edit workflow if the address is editable
        onMouseEnter={() => {
          if (isAddressEditable) {
            setAddressEditHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (isAddressEditable) {
            setAddressEditHovered(false);
          }
        }}
        onClick={() => {
          if (isAddressEditable) {
            setAddressEditVisible(true);
          }
        }}
      >
        {addressEditVisible || loading ? (
          <HorizontalForm size="medium">
            <AddressInputSelect
              name="address"
              theme={theme}
              placeholder={placeholder}
              sessionToken={sessionToken}
              data-cy={dataCy}
              onExit={() => {
                onSubmit();
              }}
              onChange={(data) => {
                setValue('address', data.address, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setValue('timeZone', data.timeZone, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setValue('latitude', data.latitude, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setValue('longitude', data.longitude, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                handleInputChange();
              }}
            />
            {buttons && (
              <div style={{ display: 'flex', gap: '12px' }}>
                <Button
                  size="medium"
                  type="hollow"
                  disabled={loading}
                  onMouseDown={() => (isMouseDown.current = true)}
                  onMouseUp={() => (isMouseDown.current = false)}
                  onClick={(e) => {
                    // NOTE: if stopPropagation is not called here, the onClick on the parent
                    // .editableAddress div will be called, which will show the edit name state again
                    e.stopPropagation();

                    setAddressEditVisible(false);
                    setAddressEditHovered(false);

                    if (onEditCancel) {
                      onEditCancel();
                    }
                  }}
                  data-cy={`${dataCy}-cancel`}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  disabled={loading || workingAddress.address.length === 0}
                  onMouseDown={() => (isMouseDown.current = true)}
                  onMouseUp={() => (isMouseDown.current = false)}
                  onClick={(e) => {
                    // NOTE: if stopPropagation is not called here, the onClick on the parent
                    // .editableAddress div will be called, which will show the edit name state again
                    e.stopPropagation();
                    onSubmit();
                  }}
                  data-cy={`${dataCy}-submit`}
                >
                  {loading ? 'Saving...' : 'Save Address'}
                </Button>
              </div>
            )}
          </HorizontalForm>
        ) : (
          activationTarget
        )}
      </div>
    </Fragment>
  );
};

export default EditableAddress;
