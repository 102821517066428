import { AxiosInstance } from 'axios';
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Icons } from '@density/dust';
import { CoreSpace, CoreOrganization } from '@densityco/lib-api-types';
import styles from './styles.module.scss';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import FillCenter from 'components/fill-center';
import Tooltip from 'components/tooltip';
import EditableName from 'components/editable-name';
import Popup from 'components/popup';
import Button from 'components/button';
import PopupListItem from 'components/popup-list-item';
import { DarkTheme } from 'components/theme';

import {
  CoreAPI,
  FloorplanAPI,
  Paginated,
  FloorplanV2PlanSummary,
} from 'lib/api';
import { sortSpacesByNameThatMightContainNumber } from 'lib/space';

const FloorNameDropdown: React.FunctionComponent<{
  client: AxiosInstance;
  name: CoreSpace['name'];
  parentBuildingId: CoreSpace['id'];
  onDropdownOpened?: () => void;
  onEditName?: (name: CoreSpace['name']) => void;
  editNameLoading?: boolean;
  'data-cy'?: string;
}> = ({
  client,
  name,
  parentBuildingId,
  onDropdownOpened,
  onEditName,
  editNameLoading = false,
  'data-cy': dataCy,
}) => {
  const { organizationId } =
    useParams<{ organizationId: CoreOrganization['id'] }>();

  const [nameDropdownOpen, setNameDropdownOpen] = useState(false);
  const [nameDropdownHovered, setNameDropdownHovered] = useState(false);

  // When the dropdown is opened, fetch a list of all sibling floors and allow one to be selected
  const [siblingFloors, setSiblingFloors] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'error' }
    | {
        status: 'complete';
        data: Array<
          Pick<
            CoreSpace,
            | 'id'
            | 'name'
            | 'space_type'
            | 'daily_reset'
            | 'capacity'
            | 'address'
            | 'function'
            | 'time_zone'
          >
        >;
      }
  >({ status: 'pending' });
  const isSiblingFloorsComplete = siblingFloors.status === 'complete';
  useEffect(() => {
    if (!client) {
      return;
    }

    if (!nameDropdownOpen) {
      return;
    }

    if (isSiblingFloorsComplete) {
      return;
    }

    const abortController = new AbortController();

    setSiblingFloors({ status: 'loading', abortController });
    CoreAPI.spacesChildren(client, parentBuildingId, abortController.signal)
      .then((response) => {
        const siblingFloors = response.data.children.filter(
          (space) => space.space_type === 'floor'
        );
        setSiblingFloors({
          status: 'complete',
          data: siblingFloors,
        });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          setSiblingFloors({ status: 'pending' });
          return;
        }
        setSiblingFloors({ status: 'error' });
      });

    return () => {
      abortController.abort();
    };
  }, [client, parentBuildingId, nameDropdownOpen, isSiblingFloorsComplete]);

  // FIXME: this is only fetching the first page of floorplans! If a floorplan has many siblings
  // some may be missing...
  const [floorplansList, setFloorplansList] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'error' }
    | { status: 'complete'; data: Paginated<FloorplanV2PlanSummary> }
  >({ status: 'pending' });
  const isFloorplansListComplete = floorplansList.status === 'complete';
  useEffect(() => {
    if (!client) {
      return;
    }

    if (!nameDropdownOpen) {
      return;
    }
    if (isFloorplansListComplete) {
      return;
    }

    const abortController = new AbortController();

    setFloorplansList({ status: 'loading', abortController });
    FloorplanAPI.listFloorplans(client, undefined, abortController.signal)
      .then((response) => {
        setFloorplansList({ status: 'complete', data: response.data });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          setFloorplansList({ status: 'error' });
          return;
        }
        setFloorplansList({ status: 'error' });
      });

    return () => {
      abortController.abort();
    };
  }, [client, nameDropdownOpen, isFloorplansListComplete]);

  // Convert the flooplan list data into a mapping of floor id to plan id. This will be used to
  // generate the proper Link `to` props within the dropdown list.
  const siblingFloorAssociatedPlanIds = useMemo(() => {
    const mapping = new Map<CoreSpace['id'], FloorplanV2PlanSummary['id']>();
    if (floorplansList.status !== 'complete') {
      return mapping;
    }
    for (const plan of floorplansList.data.results) {
      mapping.set(plan.floor.id, plan.id);
    }
    return mapping;
  }, [floorplansList]);

  let popupContents: React.ReactNode = null;
  if (
    siblingFloors.status === 'loading' ||
    floorplansList.status === 'loading'
  ) {
    popupContents = (
      <div
        className={styles.loadingErrorWrapper}
        data-cy="floor-name-editable-siblings-wrapper"
      >
        <FillCenter>Loading sibling floors...</FillCenter>
      </div>
    );
  } else if (
    siblingFloors.status === 'error' ||
    floorplansList.status === 'error'
  ) {
    popupContents = (
      <div
        className={styles.loadingErrorWrapper}
        style={{ color: dust.Red400 }}
        data-cy="floor-name-editable-siblings-wrapper"
      >
        <FillCenter>Error loading sibling floors!</FillCenter>
      </div>
    );
  } else if (
    siblingFloors.status === 'complete' &&
    floorplansList.status === 'complete' &&
    siblingFloors.data.length === 0
  ) {
    popupContents = (
      <div
        className={styles.loadingErrorWrapper}
        data-cy="floor-name-editable-siblings-wrapper"
      >
        <FillCenter>No sibling floors found</FillCenter>
      </div>
    );
  } else if (
    siblingFloors.status === 'complete' &&
    floorplansList.status === 'complete'
  ) {
    popupContents = (
      <div
        className={styles.scrollingWrapper}
        data-cy="floor-name-editable-siblings-wrapper"
      >
        {siblingFloors.data
          .sort((a, b) =>
            sortSpacesByNameThatMightContainNumber(a.name, b.name)
          )
          .map((floor) => {
            const associatedPlanId = siblingFloorAssociatedPlanIds.get(
              floor.id
            );
            if (associatedPlanId) {
              return (
                <PopupListItem
                  type="link"
                  to={`/${organizationId}/floorplans/${associatedPlanId}`}
                  minWidth={312}
                  size="small"
                >
                  {floor.name}
                </PopupListItem>
              );
            } else {
              return (
                <PopupListItem disabled minWidth={320}>
                  {floor.name}
                </PopupListItem>
              );
            }
          })}
      </div>
    );
  }

  return (
    <EditableName
      name={name}
      placeholder="eg, Floor 5"
      loading={editNameLoading}
      onEditName={onEditName}
      onEditCancel={() => setNameDropdownHovered(false)}
      customActivationTarget={(activateEdit) => (
        <DarkTheme>
          <Popup
            open={nameDropdownOpen}
            onClose={() => {
              setNameDropdownOpen(false);
              setNameDropdownHovered(false);
            }}
            position={{ left: 0, top: 40 }}
            noPadding
            target={
              <DarkTheme>
                <Button
                  trailingIcon={<Icons.ChevronDown size={18} />}
                  type="cleared"
                  size="medium"
                  active={nameDropdownOpen}
                  onClick={() => {
                    setNameDropdownOpen(true);
                    if (onDropdownOpened) {
                      onDropdownOpened();
                    }
                  }}
                  onMouseEnter={() => setNameDropdownHovered(true)}
                  onMouseLeave={() => setNameDropdownHovered(false)}
                  data-cy={`${dataCy}-target`}
                >
                  <div className={styles.nameAndAction}>
                    <span className={styles.name}>{name}</span>
                    {onEditName && (nameDropdownHovered || nameDropdownOpen) ? (
                      <Tooltip
                        target={
                          <Button
                            size="nano"
                            type="hollow"
                            trailingIcon={
                              <Icons.PencilOutlineEditor size={14} />
                            }
                            width={24}
                            onClick={(e) => {
                              e.stopPropagation();
                              activateEdit();
                            }}
                            data-cy="floor-name-editable-button"
                          />
                        }
                        contents="Rename Floor"
                        enterDelay={0}
                      />
                    ) : (
                      <div style={{ width: 24 }} />
                    )}
                  </div>
                </Button>
              </DarkTheme>
            }
          >
            {popupContents}
          </Popup>
        </DarkTheme>
      )}
      data-cy={dataCy}
    />
  );
};

export default FloorNameDropdown;
