import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { CoreUser } from '@densityco/lib-api-types';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getUser } from 'lib/api';

export const asyncFetchUserThunk = createAsyncThunk<
  // return value
  CoreUser,
  // parameters
  void,
  // types for thunkApi
  AppThunkApiConfig
>(
  'user/asyncFetchUser',
  async (_, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const response = await getUser(auth.densityAPIClient);

    return response.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
