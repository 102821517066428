import { useRef, useEffect, useCallback, useMemo } from 'react';
import * as React from 'react';
import * as PIXI from 'pixi.js';
import Mousetrap from 'mousetrap';
import {
  White,
  Red400,
  Blue400,
  Pink400,
  Gray000,
  Gray700,
  Gray900,
} from '@density/dust/dist/tokens/dust.tokens';

import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
  ResizeHandle,
  MetricLabel,
  isWithinViewport,
} from 'components/floorplan';

import {
  FloorplanCoordinates,
  ViewportCoordinates,
  snapToAngle,
  computePolygonEdges,
} from 'lib/geometry';
import { distance } from 'lib/math';
import { closestPointOnLineSegment } from 'lib/algorithm';
import WallSegment from 'lib/wall-segment';

import { WallsEditorTool } from './walls-editor';
import { toast } from 'react-toastify';

const SEGMENT_SPLIT_THRESHOLD_PIXELS = 16;
const REGION_DIAGONAL_MINIMUM_SIZE_PIXELS = 24;
const VERTEX_MOVE_DEBOUNCE_THRESHOLD_PX = 4;
const VERTEX_SNAP_THRESHOLD_PX = 12;

const PLACEMENT_TOOLTIP_OFFSET_X_PX = 16;

const WallSegmentEditLayer: React.FunctionComponent<{
  walls: Array<WallSegment>;
  tool: WallsEditorTool;
  onAddWallSegments: (
    segmentParams: Array<[FloorplanCoordinates, FloorplanCoordinates, boolean]>
  ) => void;
  onSplitSegment: (
    segment: WallSegment,
    newMidpointPosition: FloorplanCoordinates
  ) => void;
  onChangeVertexPosition: (
    oldPosition: FloorplanCoordinates,
    newPosition: FloorplanCoordinates
  ) => void;
  onDeleteWallSegments: (segments: Array<WallSegment>) => void;
  onRemoveSegmentSectionsInRegion: (
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates
  ) => void;
}> = ({
  walls,
  tool,
  onAddWallSegments,
  onSplitSegment,
  onChangeVertexPosition,
  onDeleteWallSegments,
  onRemoveSegmentSectionsInRegion,
}) => {
  const context = useFloorplanLayerContext();

  const mousePosition = useRef<ViewportCoordinates | null>(null);

  const hoveringOverRemoveButton = useRef(false);

  const newSegment = useRef<{
    positions: Array<FloorplanCoordinates>;
    nextPosition: FloorplanCoordinates | null;
    hoveringOverVertex: boolean;
  } | null>(null);

  const selectedVertex = useRef<{
    oldPosition: FloorplanCoordinates;
    newPosition: FloorplanCoordinates;
    segmentIds: Array<WallSegment['id']>;
  } | null>(null);

  const additionRegion = useRef<{
    visible: boolean;
    positionA: FloorplanCoordinates;
    positionB: FloorplanCoordinates | null;
  } | null>(null);

  const removalRegion = useRef<{
    visible: boolean;
    positionA: FloorplanCoordinates;
    positionB: FloorplanCoordinates | null;
  } | null>(null);

  const ignoreNextMouseUp = useRef(false);

  const onCompleteNewSegmentPath = useCallback(
    (addPointAtNextPosition = true) => {
      if (!context.viewport.current) {
        return;
      }
      if (!newSegment.current) {
        return;
      }
      if (newSegment.current.positions.length < 1) {
        return;
      }

      const isDoorway = tool === 'doorway';

      const onAddWallSegmentsParams: Array<
        [FloorplanCoordinates, FloorplanCoordinates, boolean]
      > = [];

      const positions = newSegment.current.positions;
      if (addPointAtNextPosition && newSegment.current.nextPosition) {
        positions.push(newSegment.current.nextPosition);
      }

      let edges = computePolygonEdges(positions);
      if (edges.length > 2) {
        edges = edges.slice(0, -1);
      }

      for (let [startPoint, endPoint] of edges) {
        // Attempt to see if the either endpoint of the segment just created is really close to an
        // existing segment, and if so, insert a point on that segment and make THAT point the point
        // on that side of the segment.
        for (const segment of walls) {
          const closestPoint = closestPointOnLineSegment(
            startPoint,
            segment.positionA,
            segment.positionB
          );
          const distanceFromStartPointToClosestPointMeters = distance(
            startPoint,
            closestPoint
          );
          const distanceFromStartPointToClosestPointPixels =
            distanceFromStartPointToClosestPointMeters *
            context.floorplan.scale *
            context.viewport.current.zoom;
          if (
            distanceFromStartPointToClosestPointPixels <
            SEGMENT_SPLIT_THRESHOLD_PIXELS
          ) {
            // Found a segment that the start point is close to! So:
            // 1. Split the segment in half
            onSplitSegment(segment, closestPoint);
            // 2. Make the start point now also this closest point value
            startPoint = closestPoint;
          }
        }
        for (const segment of walls) {
          const closestPoint = closestPointOnLineSegment(
            endPoint,
            segment.positionA,
            segment.positionB
          );
          const distanceFromEndPointToClosestPointMeters = distance(
            endPoint,
            closestPoint
          );
          const distanceFromEndPointToClosestPointPixels =
            distanceFromEndPointToClosestPointMeters *
            context.floorplan.scale *
            context.viewport.current.zoom;
          if (
            distanceFromEndPointToClosestPointPixels <
            SEGMENT_SPLIT_THRESHOLD_PIXELS
          ) {
            // Found a segment that the end point is close to! So:
            // 1. Split the segment in half
            onSplitSegment(segment, closestPoint);
            // 2. Make the end point now also this closest point value
            endPoint = closestPoint;
          }
        }

        onAddWallSegmentsParams.push([startPoint, endPoint, isDoorway]);
      }

      onAddWallSegments(onAddWallSegmentsParams);
      newSegment.current = null;
    },
    [
      tool,
      context.floorplan.scale,
      context.viewport,
      onAddWallSegments,
      onSplitSegment,
      walls,
    ]
  );

  // Kind of a hacky way to get the key binds to not unmount and remount causing errors
  // The alternative behavior is the escape key was not working on the first press,
  // which fails the cypress test.
  // The alternative approaches and their drawbacks:
  // 1. use a useCallback on the dependencies for onCompleteNewSegmentPath
  //      The parent uses inline functions, which means it would be a refactor that goes against the
  //      current design of the component.
  // 2. Remove some of the dependencies from onCompleteNewSegmentPath
  //      This is not recommended by react

  // Save the onCompleteNewSegment in a ref
  const onCompleteNewSegmentPathRef = useRef<any>(onCompleteNewSegmentPath);
  if (!onCompleteNewSegmentPathRef.current)
    onCompleteNewSegmentPathRef.current = onCompleteNewSegmentPath;

  // save the walls in a ref
  const wallsRef = useRef<any>();
  if (!wallsRef.current) wallsRef.current = walls;

  // only change the onCompleteNewSegmentPath if the walls change
  useEffect(() => {
    if (walls !== wallsRef.current) {
      wallsRef.current = walls;
      onCompleteNewSegmentPathRef.current = onCompleteNewSegmentPath;
    }
  }, [walls, onCompleteNewSegmentPath]);

  // Add keyboard shortcuts for clearing active segment
  useEffect(() => {
    const mousetrap = new Mousetrap();
    mousetrap.bind('esc', () => {
      newSegment.current = null;
      toast.warn('Cancelled drawing segment');
      if (additionRegion.current) {
        toast.warn('Cancelled drawing rectangle');
        additionRegion.current = null;
        ignoreNextMouseUp.current = true;
      }
      if (removalRegion.current) {
        toast.warn('Cancelled removal region');
        removalRegion.current = null;
        ignoreNextMouseUp.current = true;
      }
    });

    mousetrap.bind('enter', () => {
      if (!newSegment.current) {
        return;
      }
      if (newSegment.current.positions.length < 2) {
        return;
      }

      onCompleteNewSegmentPathRef.current(false);
      newSegment.current = null;
    });
    return () => {
      mousetrap.reset();
    };
  }, [tool, onCompleteNewSegmentPathRef]);

  // Pre compute and cache the center remove button texture
  const removeTexture = useMemo(() => {
    const gr = new PIXI.Graphics();
    gr.lineStyle({ width: 1, color: toRawHex(Red400) });

    gr.beginFill(toRawHex(Red400));
    gr.lineStyle({ width: 0 });
    gr.drawCircle(0, 0, 16);
    gr.endFill();

    gr.lineStyle({ width: 4, color: toRawHex(White) });
    gr.moveTo(-8, -8);
    gr.lineTo(8, 8);
    gr.moveTo(-8, 8);
    gr.lineTo(8, -8);

    return context.app.renderer.generateTexture(gr);
  }, [context.app]);

  // Compute a mapping from vertices to all the segment a certain vertex is within
  const verticesToSegments = useMemo(() => {
    // Part 1: Aggregate all segments by each end's vertex. Store the vertex as a string so that non
    // reference equivilent FloorplanCoordinates instances will be equal.
    const verticesToSegmentsRaw = new Map<string, Array<WallSegment>>();
    for (const segment of walls) {
      for (const vertex of [segment.positionA, segment.positionB]) {
        const key = `${vertex.x},${vertex.y}`;
        const segments = verticesToSegmentsRaw.get(key) || [];
        verticesToSegmentsRaw.set(key, [...segments, segment]);
      }
    }

    // Part 2: Convert each raw key into a real set of floorplan coordinates
    const verticesToSegments = new Map<
      FloorplanCoordinates,
      Array<WallSegment>
    >();
    for (const [rawKey, segments] of Array.from(verticesToSegmentsRaw)) {
      const [x, y] = rawKey.split(',');
      verticesToSegments.set(
        FloorplanCoordinates.create(parseFloat(x), parseFloat(y)),
        segments
      );
    }
    return verticesToSegments;
  }, [walls]);

  const verticesToSegmentEntries = useMemo(
    () => Array.from(verticesToSegments),
    [verticesToSegments]
  );

  // Create all the pixi objects that this layer needs to work
  useEffect(() => {
    if (!context.viewport.current) {
      return;
    }

    const container = new PIXI.Container();
    container.name = 'wall-segment-edit-layer';

    // A transparent backgrop is rendered overtop of the whole stage
    // to ensure that we have control over the mouse cursor and can always get the mouse position
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.name = 'wall-segment-editor-backdrop';
    backdrop.width = context.viewport.current.width;
    backdrop.height = context.viewport.current.height;
    backdrop.alpha = 0;
    backdrop.interactive = true;
    backdrop.cursor = 'crosshair';
    backdrop.on('mousedown', (event) => {
      if (!context.viewport.current) {
        return;
      }
      if (selectedVertex.current) {
        return;
      }
      if (newSegment.current) {
        return;
      }

      const position = ViewportCoordinates.create(
        event.data.global.x,
        event.data.global.y
      );
      let positionFloorplan = ViewportCoordinates.toFloorplanCoordinates(
        position,
        context.viewport.current,
        context.floorplan
      );
      switch (tool) {
        // If the eraser tool is active, start making a removal region
        case 'eraser':
          removalRegion.current = {
            visible: false,
            positionA: positionFloorplan,
            positionB: positionFloorplan,
          };
          return;

        // If one of the the wall tools is active, start making an addition region
        case 'wall':
          additionRegion.current = {
            visible: false,
            positionA: positionFloorplan,
            positionB: positionFloorplan,
          };
          return;
      }
    });
    backdrop.on('mouseup', (event) => {
      if (ignoreNextMouseUp.current) {
        ignoreNextMouseUp.current = false;
        return;
      }

      if (!context.viewport.current) {
        return;
      }
      if (selectedVertex.current) {
        return;
      }

      const position = ViewportCoordinates.create(
        event.data.global.x,
        event.data.global.y
      );
      let positionFloorplan = ViewportCoordinates.toFloorplanCoordinates(
        position,
        context.viewport.current,
        context.floorplan
      );

      // If the removal region is active, and the removal region is of a sufficient size, remove
      // all walls in that region
      if (removalRegion.current) {
        if (
          removalRegion.current.positionA &&
          removalRegion.current.positionB &&
          removalRegion.current.visible
        ) {
          onRemoveSegmentSectionsInRegion(
            removalRegion.current.positionA,
            removalRegion.current.positionB
          );
        }
        removalRegion.current = null;
        return;
      }

      // If the addition region is active, and the addition region is of a sufficient size, place
      // walls around the borders of it
      if (
        additionRegion.current &&
        additionRegion.current.positionA &&
        additionRegion.current.positionB &&
        additionRegion.current.visible
      ) {
        const topLeft = additionRegion.current.positionA;
        const bottomLeft = FloorplanCoordinates.create(
          additionRegion.current.positionA.x,
          additionRegion.current.positionB.y
        );
        const bottomRight = additionRegion.current.positionB;
        const topRight = FloorplanCoordinates.create(
          additionRegion.current.positionB.x,
          additionRegion.current.positionA.y
        );
        onAddWallSegments([
          // Top
          [topLeft, topRight, false],
          // Right
          [topRight, bottomRight, false],
          // Bottom
          [bottomRight, bottomLeft, false],
          // Left
          [bottomLeft, topLeft, false],
        ]);
        additionRegion.current = null;
        return;
      }
      additionRegion.current = null;

      if (newSegment.current && newSegment.current.nextPosition) {
        // If the user clicked on a vertex, then complete the series of wall segments
        if (newSegment.current.hoveringOverVertex) {
          onCompleteNewSegmentPath();
          newSegment.current = null;
        } else {
          newSegment.current = {
            ...newSegment.current,
            positions: [
              ...newSegment.current.positions,
              newSegment.current.nextPosition,
            ],
            nextPosition: null,
          };

          // If the user is placing a door, then it can only have two possible endpoints
          if (tool === 'doorway') {
            onCompleteNewSegmentPath();
            newSegment.current = null;
          }
        }
      } else {
        newSegment.current = {
          positions: [positionFloorplan],
          nextPosition: null,
          hoveringOverVertex: false,
        };
      }
    });
    backdrop.on('mousemove', (event) => {
      if (!context.viewport.current) {
        return;
      }
      const position = ViewportCoordinates.create(
        event.data.global.x,
        event.data.global.y
      );
      // Make sure the cursor is inside the viewport... for some reason this is not always the case?
      if (
        position.x < 0 ||
        position.y < 0 ||
        position.x > context.viewport.current.width ||
        position.y > context.viewport.current.height
      ) {
        return;
      }

      mousePosition.current = position;

      let positionFloorplan = ViewportCoordinates.toFloorplanCoordinates(
        position,
        context.viewport.current,
        context.floorplan
      );

      // When the addition region is active, set the mouse position equal to one extent
      if (additionRegion.current) {
        additionRegion.current.positionB = positionFloorplan;

        const diagonalOfRemovalRegionInMeters = distance(
          additionRegion.current.positionA,
          additionRegion.current.positionB
        );
        const diagonalOfRemovalRegionInPixels =
          diagonalOfRemovalRegionInMeters *
          context.floorplan.scale *
          context.viewport.current.zoom;

        additionRegion.current.visible =
          diagonalOfRemovalRegionInPixels > REGION_DIAGONAL_MINIMUM_SIZE_PIXELS;
        return;
      }

      // When the removal region is active, set the mouse position equal to one extent
      if (removalRegion.current) {
        removalRegion.current.positionB = positionFloorplan;

        const diagonalOfRemovalRegionInMeters = distance(
          removalRegion.current.positionA,
          removalRegion.current.positionB
        );
        const diagonalOfRemovalRegionInPixels =
          diagonalOfRemovalRegionInMeters *
          context.floorplan.scale *
          context.viewport.current.zoom;

        removalRegion.current.visible =
          diagonalOfRemovalRegionInPixels > REGION_DIAGONAL_MINIMUM_SIZE_PIXELS;
        return;
      }

      // When the new segment creation mode is active, set the mouse position equal to the end point
      if (newSegment.current) {
        // Holding shift snaps to 45 degree angle marks
        if (
          newSegment.current.positions.length > 0 &&
          event.data.originalEvent.shiftKey
        ) {
          positionFloorplan = snapToAngle(
            newSegment.current.positions[
              newSegment.current.positions.length - 1
            ],
            positionFloorplan
          );
        }

        newSegment.current.hoveringOverVertex = false;
        newSegment.current.nextPosition = positionFloorplan;

        for (const vertex of [
          ...newSegment.current.positions,
          ...Array.from(verticesToSegments).map((i) => i[0]),
        ]) {
          const distanceToVertex = distance(
            FloorplanCoordinates.toViewportCoordinates(
              positionFloorplan,
              context.floorplan,
              context.viewport.current
            ),
            FloorplanCoordinates.toViewportCoordinates(
              vertex,
              context.floorplan,
              context.viewport.current
            )
          );
          if (distanceToVertex < VERTEX_SNAP_THRESHOLD_PX) {
            newSegment.current.nextPosition = vertex;
            newSegment.current.hoveringOverVertex = true;
            break;
          }
        }
      }
    });
    backdrop.on('mouseout', () => {
      mousePosition.current = null;
    });
    container.addChild(backdrop);

    // This graphics element has all of the wall segments drawn to it
    const wallLines = new PIXI.Graphics();
    wallLines.name = 'wall-segment-lines';
    container.addChild(wallLines);

    // This group contains all the resize handles for controlling the position of each vertex
    const resizeHandles = new PIXI.Container();
    resizeHandles.name = 'resize-handles';
    container.addChild(resizeHandles);

    // This group contains all the buttons in the middle of each segment that can be used to remove
    // it
    const removeButtons = new PIXI.Container();
    removeButtons.name = 'remove-buttons';
    container.addChild(removeButtons);

    context.app.stage.addChild(container);

    const objectPlacementLabel = new MetricLabel('', {
      backgroundColor: toRawHex(Gray700),
      pinHorizontal: 'start',
      textStyle: new PIXI.TextStyle({
        // FIXME: load correct font here
        fontFamily: 'Arial',
        fontSize: 14,
        fontWeight: 'bold',
        fill: '#ffffff',
      }),
      horizontalPaddingPixels: 16,
      verticalPaddingPixels: 9,
      radiusPixels: 32,
    });
    objectPlacementLabel.name = 'object-placement-label';
    context.app.stage.addChild(objectPlacementLabel);

    return () => {
      context.app.stage.removeChild(container);
      context.app.stage.removeChild(objectPlacementLabel);
    };
  }, [
    context.app.stage,
    context.viewport,
    context.floorplan,
    walls,
    tool,
    onAddWallSegments,
    onSplitSegment,
    onRemoveSegmentSectionsInRegion,
    onCompleteNewSegmentPath,
    verticesToSegments,
  ]);

  // const potentialOpenings = useMemo(() => {
  //   return WallSegment.computePotentialOpenings(walls);
  // }, [walls]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }
        const viewport = context.viewport.current;

        const container = context.app.stage.getChildByName(
          'wall-segment-edit-layer'
        ) as PIXI.Container | null;
        if (!container) {
          return;
        }

        const wallLines = container.getChildByName(
          'wall-segment-lines'
        ) as PIXI.Graphics | null;
        if (!wallLines) {
          return;
        }

        const objectPlacementLabel = context.app.stage.getChildByName(
          'object-placement-label'
        ) as MetricLabel | undefined;
        if (!objectPlacementLabel) {
          return;
        }

        if (mousePosition.current) {
          objectPlacementLabel.x =
            mousePosition.current.x + PLACEMENT_TOOLTIP_OFFSET_X_PX;
          objectPlacementLabel.y = mousePosition.current.y;

          let text = '';
          if (hoveringOverRemoveButton.current) {
            text = 'Remove this segment';
          } else if (additionRegion.current && additionRegion.current.visible) {
            text = 'Release to draw rectangle';
          } else if (removalRegion.current && removalRegion.current.visible) {
            text = 'Release to remove';
          } else if (removalRegion.current) {
            text = 'Click and drag to remove walls';
          } else if (
            newSegment.current &&
            newSegment.current.hoveringOverVertex
          ) {
            text = `Finish placing ${newSegment.current.positions.length} ${
              newSegment.current.positions.length === 1 ? 'segment' : 'segments'
            }`;
          } else if (newSegment.current && tool === 'doorway') {
            text = 'Click to complete doorway';
          } else if (newSegment.current) {
            text = 'Click to place next point,\nor press [enter] to finish';
          } else if (tool === 'wall') {
            text = 'Click to begin placing wall segments';
          } else if (tool === 'doorway') {
            text = 'Click to place a doorway';
          } else if (tool === 'eraser') {
            text = 'Click and drag to erase';
          }

          // Expose a reference to the floorplan tooltip text globally so that cypress can
          // interrogate it when tests are running
          if (
            process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
            process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() ===
              'true'
          ) {
            (window as any).wallsGetTooltipText = () => text;
          }

          if (text === '') {
            objectPlacementLabel.renderable = false;
          } else {
            objectPlacementLabel.renderable = true;
            objectPlacementLabel.setText(text);
          }
        } else {
          objectPlacementLabel.renderable = false;
        }

        // Draw all segment lines
        wallLines.clear();
        wallLines.lineStyle({ width: 5, color: toRawHex(Blue400) });
        for (const segment of walls) {
          let positionA = segment.positionA;
          let positionB = segment.positionB;

          // If a vertex on one end of the segment is currently being edited, adjust the end that
          // has the vertex being moved to match the stored position
          if (
            selectedVertex.current &&
            selectedVertex.current.segmentIds.includes(segment.id)
          ) {
            if (
              positionA.x === selectedVertex.current.oldPosition.x &&
              positionA.y === selectedVertex.current.oldPosition.y
            ) {
              positionA = selectedVertex.current.newPosition;
            }
            if (
              positionB.x === selectedVertex.current.oldPosition.x &&
              positionB.y === selectedVertex.current.oldPosition.y
            ) {
              positionB = selectedVertex.current.newPosition;
            }
          }

          const positionAViewport = FloorplanCoordinates.toViewportCoordinates(
            positionA,
            context.floorplan,
            viewport
          );
          const positionBViewport = FloorplanCoordinates.toViewportCoordinates(
            positionB,
            context.floorplan,
            viewport
          );

          const isDoorway = segment.isDoorway;
          if (isDoorway) {
            wallLines.lineStyle({ width: 5, color: toRawHex(Pink400) });
          } else {
            wallLines.lineStyle({ width: 3, color: toRawHex(Blue400) });
          }
          wallLines.moveTo(positionAViewport.x, positionAViewport.y);
          wallLines.lineTo(positionBViewport.x, positionBViewport.y);
        }

        // Draw the currently being placed segment line
        if (newSegment.current) {
          const positions = [...newSegment.current.positions];
          if (newSegment.current.nextPosition) {
            positions.push(newSegment.current.nextPosition);
          }

          const positionsViewport = positions.map((position) =>
            FloorplanCoordinates.toViewportCoordinates(
              position,
              context.floorplan,
              viewport
            )
          );

          if (tool === 'wall') {
            wallLines.lineStyle({ width: 4, color: toRawHex(Blue400) });
          } else {
            wallLines.lineStyle({ width: 3, color: toRawHex(Pink400) });
          }

          for (let i = 0; i < positionsViewport.length; i += 1) {
            const positionViewport = positionsViewport[i];
            if (i === 0) {
              wallLines.moveTo(positionViewport.x, positionViewport.y);
            } else {
              wallLines.lineTo(positionViewport.x, positionViewport.y);
            }
          }
        }

        // Draw addition region
        if (
          additionRegion.current &&
          additionRegion.current.positionA &&
          additionRegion.current.positionB &&
          additionRegion.current.visible
        ) {
          const positionAViewport = FloorplanCoordinates.toViewportCoordinates(
            additionRegion.current.positionA,
            context.floorplan,
            viewport
          );
          const positionBViewport = FloorplanCoordinates.toViewportCoordinates(
            additionRegion.current.positionB,
            context.floorplan,
            viewport
          );

          wallLines.lineStyle({ width: 4, color: toRawHex(Blue400) });
          wallLines.drawRect(
            positionAViewport.x,
            positionAViewport.y,
            positionBViewport.x - positionAViewport.x,
            positionBViewport.y - positionAViewport.y
          );
        }

        // Draw removal region
        if (
          removalRegion.current &&
          removalRegion.current.positionA &&
          removalRegion.current.positionB &&
          removalRegion.current.visible
        ) {
          const positionAViewport = FloorplanCoordinates.toViewportCoordinates(
            removalRegion.current.positionA,
            context.floorplan,
            viewport
          );
          const positionBViewport = FloorplanCoordinates.toViewportCoordinates(
            removalRegion.current.positionB,
            context.floorplan,
            viewport
          );

          wallLines.lineStyle({ width: 1, color: toRawHex(Gray900) });
          wallLines.beginFill(toRawHex(Gray000), 0.5);
          wallLines.drawRoundedRect(
            positionAViewport.x,
            positionAViewport.y,
            positionBViewport.x - positionAViewport.x,
            positionBViewport.y - positionAViewport.y,
            2
          );
          wallLines.endFill();
        }

        // Draw potential wall openings
        // wallLines.lineStyle({ width: 3, color: toRawHex(Pink400) });
        // for (const [positionA, positionB] of potentialOpenings) {
        //   const positionAViewport = FloorplanCoordinates.toViewportCoordinates(
        //     positionA,
        //     context.floorplan,
        //     viewport
        //   );
        //   const positionBViewport = FloorplanCoordinates.toViewportCoordinates(
        //     positionB,
        //     context.floorplan,
        //     viewport
        //   );
        //
        //   wallLines.moveTo(positionAViewport.x, positionAViewport.y);
        //   wallLines.lineTo(positionBViewport.x, positionBViewport.y);
        // }

        // Only show resize handles if in wall drawing or doorway drawing mode
        const resizeHandles = container.getChildByName(
          'resize-handles'
        ) as PIXI.Container;
        resizeHandles.renderable = tool === 'wall' || tool === 'doorway';

        // Add / remove resize handles so that the number of resize handles equals the number of
        // bounding region vertices
        if (resizeHandles.renderable) {
          if (resizeHandles.children.length < verticesToSegmentEntries.length) {
            // Add new resize handles
            for (
              let index = resizeHandles.children.length;
              index < verticesToSegmentEntries.length;
              index += 1
            ) {
              const resizeHandle = new ResizeHandle(
                context,
                (newPosition, _viewportcoords, event) => {
                  if (!selectedVertex.current) {
                    return;
                  }

                  // holding shift snaps to 45 degree angle marks based off the first segment attached
                  // to this vertex
                  if (event.shiftKey) {
                    const entry = verticesToSegmentEntries[index];
                    if (!entry) {
                      return;
                    }
                    const segments = entry[1];
                    if (segments.length > 0) {
                      const otherVertexOnFirstSegment =
                        segments[0].positionA.x ===
                          selectedVertex.current.oldPosition.x &&
                        segments[0].positionA.y ===
                          selectedVertex.current.oldPosition.y
                          ? segments[0].positionB
                          : segments[0].positionA;
                      newPosition = snapToAngle(
                        otherVertexOnFirstSegment,
                        newPosition
                      );
                    }
                  }
                  selectedVertex.current.newPosition = newPosition;
                },
                {
                  color: toRawHex(Gray900),
                  onPress: () => {
                    const entry = verticesToSegmentEntries[index];
                    if (!entry) {
                      return;
                    }
                    const [vertex, segments] = entry;

                    selectedVertex.current = {
                      oldPosition: vertex,
                      newPosition: vertex,
                      segmentIds: segments.map((s) => s.id),
                    };
                  },
                  onRelease: () => {
                    if (!context.viewport.current) {
                      return;
                    }
                    if (!selectedVertex.current) {
                      return;
                    }

                    // Check to see if the vertex has been moved. If not - create a new segment coming
                    // off of this segment!
                    //
                    const oldPositionViewport =
                      FloorplanCoordinates.toViewportCoordinates(
                        selectedVertex.current.oldPosition,
                        context.floorplan,
                        context.viewport.current
                      );
                    const newPositionViewport =
                      FloorplanCoordinates.toViewportCoordinates(
                        selectedVertex.current.newPosition,
                        context.floorplan,
                        context.viewport.current
                      );
                    if (
                      distance(oldPositionViewport, newPositionViewport) <
                      VERTEX_MOVE_DEBOUNCE_THRESHOLD_PX
                    ) {
                      if (newSegment.current) {
                        // Complete a segment that's already being drawn!
                        newSegment.current.hoveringOverVertex = true;
                        newSegment.current.nextPosition =
                          selectedVertex.current.oldPosition;
                        onCompleteNewSegmentPath();
                        newSegment.current = null;
                      } else {
                        // Create a new segment!
                        newSegment.current = {
                          positions: [selectedVertex.current.oldPosition],
                          nextPosition: null,
                          hoveringOverVertex: false,
                        };
                      }
                      selectedVertex.current = null;
                    } else {
                      // Move the existing segment
                      onChangeVertexPosition(
                        selectedVertex.current.oldPosition,
                        selectedVertex.current.newPosition
                      );
                      setTimeout(() => {
                        selectedVertex.current = null;
                      }, 100);
                    }
                  },
                  onDelete: () => {
                    const entry = verticesToSegmentEntries[index];
                    if (!entry) {
                      return;
                    }
                    onDeleteWallSegments(entry[1]);
                  },
                }
              );
              resizeHandles.addChild(resizeHandle);
            }
          } else if (
            resizeHandles.children.length > verticesToSegmentEntries.length
          ) {
            // Remove extra resize handles
            for (
              let index = verticesToSegmentEntries.length - 1;
              index < resizeHandles.children.length;
              index += 1
            ) {
              resizeHandles.removeChild(resizeHandles.children[index]);
            }
          }

          // Adjust the position of each resize handle to match each vertex
          const verticesViewport = verticesToSegmentEntries.map(
            ([vertex, _segments]) => {
              // If the vertex is being moved, use that position instead
              if (
                selectedVertex.current &&
                vertex.x === selectedVertex.current.oldPosition.x &&
                vertex.y === selectedVertex.current.oldPosition.y
              ) {
                vertex = selectedVertex.current.newPosition;
              }

              return FloorplanCoordinates.toViewportCoordinates(
                vertex,
                context.floorplan,
                viewport
              );
            }
          );

          for (let index = 0; index < verticesViewport.length; index += 1) {
            const resizeHandle = resizeHandles.children[index];
            resizeHandle.renderable = isWithinViewport(
              context,
              verticesViewport[index]
            );
            if (!resizeHandle.renderable) {
              continue;
            }
            resizeHandle.x = verticesViewport[index].x;
            resizeHandle.y = verticesViewport[index].y;
            resizeHandle.cursor = 'move';
          }
        }

        // Only show remove buttons if in wall mode
        const removeButtons = container.getChildByName(
          'remove-buttons'
        ) as PIXI.Container;
        removeButtons.renderable = tool === 'wall' || tool === 'doorway';

        // Add / remove remove buttons so that the number of buttons equals the number of walls
        if (removeButtons.renderable) {
          if (removeButtons.children.length < walls.length) {
            // Add new remove buttons
            for (
              let index = removeButtons.children.length;
              index < walls.length;
              index += 1
            ) {
              const removeButton = new PIXI.Sprite(removeTexture);
              removeButton.scale.set(0.5, 0.5);
              removeButton.anchor.set(0.5, 0.5);
              removeButton.interactive = true;
              removeButton.on('mouseover', () => {
                hoveringOverRemoveButton.current = true;
              });
              removeButton.on('mouseout', () => {
                hoveringOverRemoveButton.current = false;
              });
              removeButton.on('mouseup', () => {
                const wallSegment = walls[index];
                onDeleteWallSegments([wallSegment]);
                hoveringOverRemoveButton.current = false;
              });
              removeButton.cursor = 'pointer';
              removeButtons.addChild(removeButton);
            }
          } else if (removeButtons.children.length > walls.length) {
            // Remove extra remove buttons
            for (
              let index = walls.length - 1;
              index < removeButtons.children.length;
              index += 1
            ) {
              removeButtons.removeChild(removeButtons.children[index]);
            }
          }

          // Adjust the position of each remove button to be in the center of each wall segment
          const centersViewport = walls.map((wallSegment) => {
            // Don' show the remove button if the segment is being moved
            if (
              selectedVertex.current &&
              selectedVertex.current.segmentIds.includes(wallSegment.id)
            ) {
              return null;
            }

            const positionAViewport =
              FloorplanCoordinates.toViewportCoordinates(
                wallSegment.positionA,
                context.floorplan,
                viewport
              );

            const positionBViewport =
              FloorplanCoordinates.toViewportCoordinates(
                wallSegment.positionB,
                context.floorplan,
                viewport
              );

            // If the segment is too small, then don't render the button
            if (distance(positionAViewport, positionBViewport) < 32) {
              return null;
            }

            return ViewportCoordinates.create(
              (positionAViewport.x + positionBViewport.x) / 2,
              (positionAViewport.y + positionBViewport.y) / 2
            );
          });

          for (let index = 0; index < centersViewport.length; index += 1) {
            const removeButton = removeButtons.children[index];
            if (!removeButton) {
              continue;
            }

            const centerViewport = centersViewport[index];
            if (!centerViewport) {
              removeButton.renderable = false;
              continue;
            }

            removeButton.renderable = isWithinViewport(context, centerViewport);
            if (!removeButton.renderable) {
              continue;
            }

            removeButton.x = centerViewport.x;
            removeButton.y = centerViewport.y;
          }
        }
      }}
    />
  );
};

export default WallSegmentEditLayer;
