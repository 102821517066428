import { v4 as uuidv4 } from 'uuid';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { Meters, LengthUnit } from 'lib/units';
import { degreesToRadians, distance } from 'lib/math';
import { FloorplanV2Sensor, isFloorplanPlanSensorId, Unsaved } from 'lib/api';
import { FloorplanCoordinates, CADCoordinates } from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import { closestPointOnLineSegment } from 'lib/algorithm';
import Floorplan from 'lib/floorplan';
import WallSegment from 'lib/wall-segment';
import { ParsedPlanDXFSensorPlacement } from 'lib/dxf';

import { State } from 'components/editor/state';

const FIRST_BUILD_WITH_SENSOR_LOCATE_AND_IMMEDIATE_COMMANDS = 10094;

export type PlanSensorHardwareType = 'oa1' | 'oa1b' | 's5' | 's5b';

export const decodeSerialNumberToHardwareType = (
  serialNumber: string
): PlanSensorHardwareType | 'invalid_sensor_hw' => {
  if (serialNumber[0] === 'Z') {
    // Need to support virtual serial numbers for unit tests...
    const sn = serialNumber.toUpperCase();
    if (sn.includes('OA1B')) {
      return 'oa1b';
    } else if (sn.includes('OA1')) {
      return 'oa1';
    } else if (sn.includes('S5B')) {
      return 's5b';
    } else if (sn.includes('S5')) {
      return 's5';
    }
  } else if (serialNumber[0] === 'K') {
    return 's5';
  } else {
    if (serialNumber[1] === '0') {
      return 's5';
    } else if (serialNumber[1] === '1') {
      return 's5b';
    } else if (serialNumber[1] === '2') {
      return 'oa1';
    } else if (serialNumber[1] === '3') {
      return 'oa1b';
    }
  }
  return 'invalid_sensor_hw';
};

export type OpenAreaFunction = 'oaLongRange' | 'oaShortRange' | 'oaMidRange';
export type EntryFunction = 'tofEntry' | 'openEntry';
export type PlanSensorFunction = OpenAreaFunction | EntryFunction;
export namespace PlanSensorFunction {
  export function generateDisplayName(sensorType: PlanSensorFunction) {
    switch (sensorType) {
      case 'oaLongRange':
        return 'Long Range';
      case 'oaShortRange':
        return 'Short Range';
      case 'oaMidRange':
        return 'Mid Range';
      case 'tofEntry':
        return 'ToF Entry';
      default:
        // openEntry
        return 'Entry LR';
    }
  }
  export function toAPIFormat(sensorType: PlanSensorFunction) {
    switch (sensorType) {
      case 'oaLongRange':
        return 'oalr';
      case 'oaShortRange':
        return 'oasr';
      case 'oaMidRange':
        return 'oa';
      case 'tofEntry':
        return 'entry';
      default:
        // openEntry
        return 'oe';
    }
  }
  export function hardwareToFunction(sensorHardware: PlanSensorHardwareType) {
    switch (sensorHardware) {
      case 'oa1b':
        return 'oaLongRange';
      case 's5b':
        return 'tofEntry';
      case 's5':
        return 'tofEntry';
      default:
        return 'oaMidRange';
    }
  }
  export function toPlannerFormat(
    sensorType: 'oalr' | 'oasr' | 'oa' | 'entry' | 'oe'
  ) {
    switch (sensorType) {
      case 'oalr':
        return 'oaLongRange';
      case 'oasr':
        return 'oaShortRange';
      case 'oa':
        return 'oaMidRange';
      case 'entry':
        return 'tofEntry';
      default:
        // oe
        return 'openEntry';
    }
  }

  export const getSupportedHardwareTypes = (
    sensorType: EntryFunction | OpenAreaFunction
  ): Array<PlanSensorHardwareType> => {
    switch (sensorType) {
      case 'oaLongRange':
        return ['oa1b'];
      case 'oaShortRange':
        return ['oa1', 'oa1b'];
      case 'oaMidRange':
        return ['oa1'];
      case 'tofEntry':
        return ['s5b', 's5'];
      case 'openEntry':
        return ['oa1b'];
      default:
        return [];
    }
  };
}

export type PlanSensorType = 'oa' | 'entry';
export namespace PlanSensorType {
  export function generateDisplayName(sensorType: PlanSensorType) {
    return sensorType === 'oa' ? 'OA' : 'Entry';
  }
}

export enum SensorStatus {
  UNCONFIGURED = 'unconfigured',
  PROVISIONING = 'provisioning',
  ERROR = 'error',
  ONLINE = 'online',
  LOW_POWER = 'low_power',
  ARCHIVED = 'archived',
}

export function sensorStatusDisplayName(sensorStatus: SensorStatus) {
  if (sensorStatus === SensorStatus.LOW_POWER) {
    return 'Low Power';
  } else {
    const string = sensorStatus.toLowerCase(); // Convert enum value to lowercase
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export const getSensorStatusColor = (status?: string) => {
  switch (status) {
    case SensorStatus.ONLINE:
      return dust.Green400;
    case SensorStatus.LOW_POWER:
      return dust.Yellow400;
    case SensorStatus.ERROR:
    case SensorStatus.ARCHIVED:
      return dust.Red400;
    default:
      return dust.Gray400;
  }
};

export type SensorStreamingStatus = 'connecting' | 'connected' | 'disconnected';
export type SensorConnection = {
  status: SensorStreamingStatus;
  serialNumber: string;
};

// This datatype is used to represent the sensor's coverage area when it comes out of the coverage
// area calculation.
//
// It's an array of pairs, where each element in the array represents a vector starting at the
// sensor's location and moving outwards along an angle. This angle is stored at the first element
// of the pair.
//
// The second element in the pair is itself an array of distances from the sensor along that vector
// in which coverage was obstructed. The final item is the final coverage distance.
//
// If you wanted to convert this into a polygon, you'd project the each vector along the given angle
// at the magnitude equal to the final element in the distances array.
export type SensorCoverageIntersectionVectors = Array<[number, Array<number>]>;

type BaseSensorValidation = {
  id: string;
  objectType: 'sensor';
  objectId: PlanSensor['id'];
  severity: 'warning' | 'error';
};
export type SensorValidation =
  | (BaseSensorValidation & {
      severity: 'warning';
      validationType: 'sensor.coverageObstructedDueToCeiling';
    })
  | (BaseSensorValidation & {
      severity: 'warning';
      validationType: 'sensor.positionTooCloseToWall';
      segmentsAndClosestPoint: Array<[WallSegment, FloorplanCoordinates]>;
    })
  | (BaseSensorValidation & {
      severity: 'error';
      validationType: 'sensor.heightTooLow';
    })
  | (BaseSensorValidation & {
      severity: 'error';
      validationType: 'sensor.heightTooHigh';
    });

type PlanSensor = {
  id: string;
  type: PlanSensorType;
  sensorFunction: EntryFunction | OpenAreaFunction;
  name: string;
  position: FloorplanCoordinates;
  height: number;
  rotation: number;
  // NOTE: nudge is applied after rotation
  dataNudgeX?: number;
  dataNudgeY?: number;
  serialNumber: string | null;
  locked: boolean;
  last_heartbeat?: string | null;
  status?: SensorStatus;
  ipv4?: string | null;
  ipv6?: string | null;
  mac?: string | null;
  os?: string | null;
  notes: string;
  plan_sensor_index?: number | null;
  cadId: string;
  boundingBoxFilter: 'none' | 'cloud' | 'device';
  clippedFOV: FloorplanCoordinates[];
  doorwayClippedFOV: FloorplanCoordinates[];
  noisePolygons: Array<FloorplanCoordinates[]>;
};

export type CopiedSensor = {
  height: number;
  rotation: number;
  sensorFunction: OpenAreaFunction | EntryFunction;
};

namespace PlanSensor {
  export function create(
    type: PlanSensorType,
    sensorFunction: EntryFunction | OpenAreaFunction,
    position: FloorplanCoordinates,
    height: number,
    rotation: number,
    name: string,
    notes: string = '',
    cadId: string = ''
  ): PlanSensor {
    const id = uuidv4();
    return {
      id,
      type,
      sensorFunction,
      name,
      position,
      height,
      rotation,
      dataNudgeX: 0,
      dataNudgeY: 0,
      serialNumber: null,
      locked: false,
      last_heartbeat: null,
      status: SensorStatus.UNCONFIGURED,
      ipv4: null,
      ipv6: null,
      mac: null,
      os: null,
      notes,
      plan_sensor_index: null,
      cadId,
      boundingBoxFilter: 'none',
      clippedFOV: [],
      doorwayClippedFOV: [],
      noisePolygons: [],
    };
  }

  // Given a ParsedPlanDXFSensorPlacement generated from a DXF import, create a corresponding
  // PlanSensor that can be placed on the plan.
  //
  // This is used heavily within the DXF import workflow!
  export function createFromCADSensorPlacement(
    processedCADSensorPlacement: ParsedPlanDXFSensorPlacement,
    planSensors: State['planSensors'],
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number
  ): PlanSensor {
    const filteredSensors = PlanSensor.filterByType(
      processedCADSensorPlacement.type,
      planSensors
    );
    const sensorName = PlanSensor.generateName(
      processedCADSensorPlacement.type,
      filteredSensors.length
    );
    const sensorPosition = CADCoordinates.toFloorplanCoordinates(
      processedCADSensorPlacement.position,
      floorplan,
      floorplanCADOrigin,
      cadFileUnitOrDefault,
      cadFileScaleOrDefault
    );
    const planSensor = PlanSensor.create(
      processedCADSensorPlacement.type,
      processedCADSensorPlacement.sensorFunction,
      sensorPosition,
      processedCADSensorPlacement.height,
      processedCADSensorPlacement.rotation,
      sensorName,
      '',
      processedCADSensorPlacement.cadId
    );
    planSensor.serialNumber = processedCADSensorPlacement.serialNumber;
    planSensor.locked = true;
    return planSensor;
  }

  // Given a PlanSensor, return a copy of the PlanSensor that is detached from the underlying
  // physical sensor (ie, no serial number attached, no metadata, etc)
  export function duplicate(oldSensor: PlanSensor): PlanSensor {
    const id = uuidv4();
    return {
      ...oldSensor,
      id,
      serialNumber: null,
      last_heartbeat: null,
      status: SensorStatus.UNCONFIGURED,
      ipv4: null,
      ipv6: null,
      mac: null,
      os: null,
      plan_sensor_index: null,
    };
  }

  export function filterByType(
    type: PlanSensorType,
    sensors: FloorplanCollection<PlanSensor>
  ) {
    return FloorplanCollection.list(sensors).filter((sensor) => {
      return sensor.type === type;
    });
  }

  export function filterByFunction(
    sensorFunction: PlanSensorFunction,
    sensors: FloorplanCollection<PlanSensor>
  ) {
    return FloorplanCollection.list(sensors).filter((sensor) => {
      return sensor.sensorFunction === sensorFunction;
    });
  }

  // Run sensor checks and returns a list of all validations that do not pass
  export function validate(
    planSensor: Pick<
      PlanSensor,
      'id' | 'height' | 'position' | 'type' | 'sensorFunction'
    >,
    stateWalls: State['walls'],
    stateSensorCoverageIntersectionVectors: State['planSensorCoverageIntersectionVectors']
  ): Array<SensorValidation> {
    const validations: Array<SensorValidation> = [];

    if (
      planSensor.height < PlanSensor.computeMinHeight(planSensor.sensorFunction)
    ) {
      validations.push({
        id: uuidv4(),
        objectType: 'sensor',
        objectId: planSensor.id,
        severity: 'error',
        validationType: 'sensor.heightTooLow',
      });
    }

    if (
      planSensor.height > PlanSensor.computeMaxHeight(planSensor.sensorFunction)
    ) {
      validations.push({
        id: uuidv4(),
        objectType: 'sensor',
        objectId: planSensor.id,
        severity: 'error',
        validationType: 'sensor.heightTooHigh',
      });
    }

    if (!FloorplanCollection.isEmpty(stateWalls)) {
      const wallSegments = FloorplanCollection.list(stateWalls);
      const wallThresholdInMeters = Meters.fromFeet(3);

      const [upperLeft, lowerRight] =
        PlanSensor.computeAxisAlignedBoundingBox(planSensor);
      const wallSegmentsInBoundingRegion =
        WallSegment.computeWallSegmentsInBoundingRegion(
          wallSegments,
          // Pad the bounding box outwards to take into account the wall distance threshold
          FloorplanCoordinates.create(
            upperLeft.x - wallThresholdInMeters,
            lowerRight.y - wallThresholdInMeters
          ),
          FloorplanCoordinates.create(
            lowerRight.x + wallThresholdInMeters,
            lowerRight.y + wallThresholdInMeters
          )
        );

      const segmentsAndClosestPoint: Array<
        [WallSegment, FloorplanCoordinates]
      > = wallSegmentsInBoundingRegion.flatMap((wallSegment) => {
        const closestPoint = closestPointOnLineSegment(
          planSensor.position,
          wallSegment.positionA,
          wallSegment.positionB
        );

        const distanceToWallSegmentInMeters = distance(
          closestPoint,
          planSensor.position
        );
        if (distanceToWallSegmentInMeters < wallThresholdInMeters) {
          return [[wallSegment, closestPoint]];
        } else {
          return [];
        }
      });

      if (segmentsAndClosestPoint.length > 0) {
        validations.push({
          id: uuidv4(),
          objectType: 'sensor',
          objectId: planSensor.id,
          severity: 'warning',
          validationType: 'sensor.positionTooCloseToWall',
          segmentsAndClosestPoint,
        });
      }
    }

    const vectors = stateSensorCoverageIntersectionVectors.get(planSensor.id);
    if (vectors && vectors !== 'empty' && vectors !== 'loading') {
      // The coverage intersection vector format stores coverage intersections as an array of pairs,
      // where the first item in the pair is an angle, and the second item is an array of magnitudes
      // along that angle where the sensor encountered an obstacle, and the final entry being the
      // boundary of the green coverage area.
      //
      // If a sensor has more than one item in that magnitudes array, then there was a obstruction
      // due to ceiling coverage.
      const isObstructed = Boolean(
        vectors.find(([angle, magnitudes]) => magnitudes.length > 1)
      );
      if (isObstructed) {
        validations.push({
          id: uuidv4(),
          objectType: 'sensor',
          objectId: planSensor.id,
          severity: 'warning',
          validationType: 'sensor.coverageObstructedDueToCeiling',
        });
      }
    }

    return validations;
  }

  // Format the given PlanSensor as a FloorplanV2Sensor, the datatype that the floorplan api uses to
  // represent a PlanSensor internally.
  //
  // If the PlanSensor has a client-generated uuid for its id, the id of the resulting
  // FloorplanV2Sensor will be `undefined`.
  export function toFloorplanSensor(
    sensor: PlanSensor
  ): FloorplanV2Sensor | Unsaved<FloorplanV2Sensor> {
    return {
      id: isFloorplanPlanSensorId(sensor.id) ? sensor.id : undefined,
      sensor_serial_number: sensor.serialNumber,
      sensor_type: sensor.type,
      sensor_function: PlanSensorFunction.toAPIFormat(sensor.sensorFunction),
      height_meters: sensor.height,
      centroid_from_origin_x_meters: sensor.position.x,
      centroid_from_origin_y_meters: sensor.position.y,
      rotation: sensor.rotation,
      locked: sensor.locked,
      last_heartbeat: sensor.last_heartbeat,
      status: sensor.status,
      diagnostic_info: {
        ipv4: sensor.ipv4,
        ipv6: sensor.ipv6,
        mac: sensor.mac,
        os: sensor.os,
      },
      notes: sensor.notes,
      // this will send the plan_sensor_index field for saving
      // but it's readonly, so it should be fine
      plan_sensor_index: sensor.plan_sensor_index,
      cad_id: sensor.cadId,
      bounding_box_filter: sensor.boundingBoxFilter,
    };
  }

  // Given a FloorplanV2Sensor, the datatype that the the floorplan api uses to represent a
  // PlanSensor internally, construct a PlanSensor that can be used to represent the sensor in
  // planner.

  export function createFromFloorplanSensor(
    planSensor: FloorplanV2Sensor,
    floorplan?: Floorplan,
    nullGeometryIdx?: number
  ): PlanSensor {
    // If geometry is null, let's render this PlanSensor to the right of the floorplan.
    // This is a special case to handle legacy ToF sensors that never had any geometry.
    // The moment these entities are moved, they will persist their new location and this become
    // redundant.

    const positionX =
      planSensor.centroid_from_origin_x_meters ||
      (floorplan
        ? (floorplan.width - floorplan.origin.x) / floorplan.scale +
          ((3 / 15) * (floorplan.width - floorplan.origin.x)) / floorplan.scale
        : 0);
    const positionY =
      planSensor.centroid_from_origin_y_meters ||
      (nullGeometryIdx !== undefined && floorplan
        ? ((floorplan.height - floorplan.origin.y) / floorplan.scale / 15) *
          nullGeometryIdx
        : 0);

    const sensor = PlanSensor.create(
      planSensor.sensor_type,
      PlanSensorFunction.toPlannerFormat(planSensor.sensor_function),
      FloorplanCoordinates.create(positionX, positionY),
      planSensor.height_meters,
      planSensor.rotation,
      '',
      planSensor.notes
    );

    sensor.id = planSensor.id;
    sensor.serialNumber = planSensor.sensor_serial_number || null;
    sensor.locked = planSensor.locked;
    sensor.last_heartbeat = planSensor.last_heartbeat;
    sensor.status = planSensor.status;
    sensor.ipv4 = planSensor.diagnostic_info?.ipv4;
    sensor.ipv6 = planSensor.diagnostic_info?.ipv6;
    sensor.mac = planSensor.diagnostic_info?.mac;
    sensor.os = planSensor.diagnostic_info?.os;
    sensor.plan_sensor_index = planSensor.plan_sensor_index; // this is the name of json coming from backend
    sensor.cadId = planSensor.cad_id || '';
    sensor.boundingBoxFilter = planSensor.bounding_box_filter || 'none';
    // By defaults, clipped_fov vertices are in sensor's coordinates. The vertices
    // need to be translated by sensor's position.
    sensor.clippedFOV =
      planSensor.clipped_fov?.map((vertex) =>
        FloorplanCoordinates.create(
          vertex.x_meters + sensor.position.x,
          -1 * vertex.y_meters + sensor.position.y
        )
      ) || [];
    sensor.doorwayClippedFOV =
      planSensor.doorway_clipped_fov?.map((vertex) =>
        FloorplanCoordinates.create(
          vertex.x_meters + sensor.position.x,
          -1 * vertex.y_meters + sensor.position.y
        )
      ) || [];
    sensor.noisePolygons =
      planSensor.noise_polygons?.map((polygon) =>
        polygon.region.map((vertex) =>
          FloorplanCoordinates.create(
            vertex[0] + sensor.position.x,
            -1 * vertex[1] + sensor.position.y
          )
        )
      ) || [];

    if (planSensor.diagnostic_info?.low_power_mode) {
      // I found that the SensorStatus returned from core
      // is inconsistent when LOW_POWER is set. The sensor
      // keeps flipping between ERROR and LOW_POWER - it must
      // be some sort of race condition on core end, hence
      // this override right here.
      sensor.status = SensorStatus.LOW_POWER;
    }

    return sensor;
  }

  // Given a sensor, return the os build number part os the os version
  export function computeOSBuildNumber(osVersion: string): number | null {
    if (!osVersion) {
      return null;
    }

    const buildNumber = window.parseInt(osVersion.split('-')[0], 10);
    if (isNaN(buildNumber)) {
      return null;
    }

    return buildNumber;
  }

  // Can this sensor be sent a locate command and understand it?
  // (This feature was added in like 2019ish, so pretty much everything can at this point)
  export function supportsLocate(planSensor: PlanSensor): boolean {
    const buildNumber = PlanSensor.computeOSBuildNumber(planSensor.os || '');

    if (!buildNumber) {
      return false;
    }

    return buildNumber >= FIRST_BUILD_WITH_SENSOR_LOCATE_AND_IMMEDIATE_COMMANDS;
  }

  // Given a PlanSensor, return the upper left and lower right coordinates that refers to the axis
  // aligned bounding box around it.
  //
  // This is often helpful when trying to scope a query or computation to only run in an area
  // associated with a sensor's coverage field of view - axis aligned bounding boxes are very fast
  // to compute.
  //
  // NOTE: right now, this doesn't take rotation into account - the bounding box for that reason is
  // larger than it needs to be.
  export function computeAxisAlignedBoundingBox(
    planSensor: Pick<PlanSensor, 'height' | 'position'>
  ): [FloorplanCoordinates, FloorplanCoordinates] {
    const cornerPositions =
      PlanSensor.computeAxisAlignedBoundingBoxCornerPositions(planSensor);
    const smallestXPosition = Math.min(...cornerPositions.map((p) => p.x));
    const smallestYPosition = Math.min(...cornerPositions.map((p) => p.y));
    const largestXPosition = Math.max(...cornerPositions.map((p) => p.x));
    const largestYPosition = Math.max(...cornerPositions.map((p) => p.y));

    return [
      FloorplanCoordinates.create(smallestXPosition, smallestYPosition),
      FloorplanCoordinates.create(largestXPosition, largestYPosition),
    ];
  }

  // Figure out the extents of the bounding region around the sensor coverage area.
  // NOTE: this is probably not the function you want, look for PlanSensor.computeAxisAlignedBoundingBox
  export function computeAxisAlignedBoundingBoxCornerPositions(
    planSensor: Pick<PlanSensor, 'height' | 'position'>
  ): Array<FloorplanCoordinates> {
    const [major] = computeCoverageMajorMinorAxisOA(
      planSensor.height,
      'oaMidRange'
    ); //FIXME: Add support for sensor hardware
    const cornerPositions = [
      [1, 1],
      [1, -1],
      [-1, 1],
      [-1, -1],
    ].map(([xMultiplier, yMultiplier]) =>
      FloorplanCoordinates.create(
        planSensor.position.x + xMultiplier * major,
        planSensor.position.y + yMultiplier * major
      )
    );

    return cornerPositions;
  }

  // DEPRECATED
  // Given an OA sensor's height, compute the radius that the coverage circle should be rendered at.
  //
  // NOTE: this is deprecated, OA sensors now have a coverage field of view that is an ellipse
  // shape, not a circle. However, in certain customer facing situations, sometimes it is still
  // important to be able to render a field of view shape that is circular and not elliptical, so
  // this has stuck around. Generally though, if you are showing the field of view of an OA sensor,
  // you should use `PlanSensor.computeCoverageMajorMinorAxisOA` instead.
  export function computeCoverageRadiusOA(installHeight: number): number {
    // assumptions for now
    const fovDegrees = 105;
    const requiredSubjectCoverageHeight = 0; // for now...

    // the computed height accounting for the requirement of subject coverage
    const H = installHeight - requiredSubjectCoverageHeight;

    const fovRadians = degreesToRadians(fovDegrees);
    return Math.min(H * Math.tan(fovRadians / 2), Meters.fromFeet(12));
  }

  // Given an OA sensor's height, compute the major and minor axis of the ellipse in meters that
  // represents in a 2D context the coverage of the sensor.
  export function computeCoverageMajorMinorAxisOA(
    installHeightMeters: number,
    sensorType: PlanSensor['sensorFunction']
  ): [number, number] {
    let majorMeters, minorMeters;

    switch (sensorType) {
      case 'oaLongRange':
        // OA1b:
        // https://www.notion.so/densityinc/OA1b-Technical-Specifications-Frequently-Asked-Questions-FAQ-DRAFT-2a332200874b41049e05cf9443828662

        /*
            import numpy as np
            from scipy.optimize import curve_fit

            x = np.array([7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]) * 0.3048 # convert feet to meters
            y_major = np.array([10.14, 11.45, 12.69, 13.82, 14.86, 15.8, 16.6, 17.39, 18.05, 18.61, 19.08, 19.45]) * 0.3048
            y_minor = np.array([8.14, 9.16, 10.14, 11.03, 11.87, 12.65, 13.37, 14.01, 14.6, 15.13, 15.59, 15.99]) * 0.3048

            def polynomial_func(x, a, b, c, d):
                return a + b*x + c*x**2 + d*x**3

            popt, pcov = curve_fit(polynomial_func, x, y_major)
            a, b, c, d = popt

            print("Major coefficients: a = %.5f, b = %.5f, c = %.5f, d = %.5f" % (a, b, c, d))

            popt, pcov = curve_fit(polynomial_func, x, y_minor)
            a, b, c, d = popt

            print("Minor coefficients: a = %.5f, b = %.5f, c = %.5f, d = %.5f" % (a, b, c, d))
            */

        installHeightMeters = Math.max(installHeightMeters, Meters.fromFeet(7)); // min supported height
        installHeightMeters = Math.min(
          installHeightMeters,
          Meters.fromFeet(18)
        ); // max supported height

        const h = installHeightMeters * 3.28084; // h is in feet..
        // This polynomial changed on Jun 26 2023
        // https://docs.google.com/spreadsheets/d/1hrefdIQcRWnEixC0tEKvjXQcHuTaBnbhw04px0_vKWY/edit#gid=0
        majorMeters = Meters.fromFeet(
          -0.0014 * Math.pow(h, 4) +
            0.0729 * Math.pow(h, 3) -
            1.4358 * Math.pow(h, 2) +
            13.3606 * h -
            35.0043
        );
        minorMeters = Meters.fromFeet(
          -0.0008 * Math.pow(h, 4) +
            0.044 * Math.pow(h, 3) -
            0.911 * Math.pow(h, 2) +
            8.9873 * h -
            23.5908
        );
        break;
      default:
        //oaMidRange
        // See this document for where these multipliers were found:
        // OA1:
        // https://www.notion.so/densityinc/OA1-Technical-Specifications-Frequently-Asked-Questions-FAQ-DRAFT-Data-Sheet-1ef5fb5bd81d443f93fade8c33234a0f#a66c61a854144024bed791cfd8eb6394

        if (installHeightMeters < Meters.fromFeet(8)) {
          installHeightMeters = Meters.fromFeet(8);
        }

        if (installHeightMeters > Meters.fromFeet(12)) {
          installHeightMeters = Meters.fromFeet(12);
        }

        majorMeters = Math.min(
          installHeightMeters * 1.3,
          Meters.fromFeet(14.3)
        );
        minorMeters = Math.min(installHeightMeters * 0.92, Meters.fromFeet(9));
        break;
    }

    return [majorMeters, minorMeters];
  }

  // Given an Entry sensor's height, cumpute the radius in meters that the half-circle shape
  // should be rendered at when visualizing the sensor's field of view in 2D.
  export function computeCoverageRadiusEntry(
    installHeightMeters: number
  ): number {
    // this uses the linear scale from the installation documentation:
    // install height     maximum install width
    // 90"                40"
    // 93"                45"
    // 96"                50"
    // 99"                55"
    // 102"               60"
    // 105"               65"
    // 108"               70"
    // 111"               75"
    // 114"               80"
    // 117"               85"
    // 120"               90"
    const heightInches = Meters.toInches(installHeightMeters);
    const installWidth = 40 + (5 * (heightInches - 90)) / 3;
    return Meters.fromInches(installWidth / 2);
  }

  export function computeDefaultHeight(
    sensorType: PlanSensor['sensorFunction']
  ) {
    switch (sensorType) {
      case 'oaLongRange':
        return 3.048; // 10 feet
      case 'oaShortRange':
        return 2.4384; // 8 feet
      default:
        // oaMidRange & entry
        return 3.048; // 10 feet
    }
  }

  export function computeMinHeight(sensorType: PlanSensor['sensorFunction']) {
    switch (sensorType) {
      case 'oaLongRange':
        return 1.9812; // 6.5 feet
      case 'oaMidRange':
        return 1.9812; // 6.5 feet
      case 'oaShortRange':
        return 1.9812; // 6.5 feet
      default:
        // entry & openEntry
        return 2.1336; // 7 feet
    }
  }

  export function computeMaxHeight(sensorType: PlanSensor['sensorFunction']) {
    switch (sensorType) {
      case 'oaLongRange':
        return 5.4864; // 18 feet
      case 'oaShortRange':
        return 2.4384; // 8 feet
      default:
        // oaMidRange & entry
        return 3.6576; // 12 feet
    }
  }

  // Perform an approximation of the ellipse - in effect, this makes a large, multi-sided
  // regular polygon.
  export function computePolygonBoundaryForFoV(
    sensor: PlanSensor
  ): Array<FloorplanCoordinates> {
    const [major, minor] = computeCoverageMajorMinorAxisOA(
      sensor.height,
      sensor.sensorFunction
    );
    const circularApproximationPoints: Array<FloorplanCoordinates> = [];

    for (let alpha = 0; alpha < 360; alpha += 10) {
      // We call a sensor pointed directly north at the 12 o'clock position as having rotation of 0 degrees.
      // In a unit circle, 0 degrees is at the 3 o'clock position, and 90 degrees is at the 12 o'clock position.
      // so we have to convert our sensor rotation positioning to the correct radian rotation.
      let theta = sensor.rotation + 90;

      let x =
        major *
          Math.cos(degreesToRadians(alpha)) *
          Math.cos(degreesToRadians(theta)) -
        minor *
          Math.sin(degreesToRadians(alpha)) *
          Math.sin(degreesToRadians(theta)) +
        sensor.position.x;

      let y =
        major *
          Math.cos(degreesToRadians(alpha)) *
          Math.sin(degreesToRadians(theta)) +
        minor *
          Math.sin(degreesToRadians(alpha)) *
          Math.cos(degreesToRadians(theta)) +
        sensor.position.y;

      circularApproximationPoints.push(FloorplanCoordinates.create(x, y));
    }

    return circularApproximationPoints;
  }

  export function getSensorFoV(
    sensor: PlanSensor
  ): Array<Array<[number, number]>> {
    const vertices = computePolygonBoundaryForFoV(sensor);
    return [vertices.map((v) => [v.x, v.y])];
  }

  export function generateName(
    sensorType: PlanSensorType,
    numExistingSensors: number
  ) {
    const sensorTypeText = PlanSensorType.generateDisplayName(sensorType);
    return `${sensorTypeText} Sensor ${numExistingSensors + 1}`;
  }
}

export default PlanSensor;
