import { useState, Fragment } from 'react';
import { Threshold } from 'lib/threshold';
import { Layer, PlacementMode } from 'components/floorplan';

import ThresholdsLayer from './thresholds-layer';

// The reference ruler creation layer renders information about the currently being placed reference
// ruler during the reference ruler creation process.
const ThresholdCreationLayer: React.FunctionComponent<{
  placementModeRef: React.RefObject<PlacementMode | null>;
}> = ({ placementModeRef }) => {
  // FIXME: this is a little weird, let me explain what's going on here.
  //
  // The ThresholdsLayer accepts a prop defining a list of rulers to render. However, the
  // placementmode data is in a ref because this data updates often, and if it was in the editor
  // state (or a useState hook higher up in the tree) it would rerender the app many times per
  // second and cause the app to be slow. But, because the placementmode data is in a ref, it won't
  // cause this component to rerender, so just putting `placementModeRef.current` as a prop to
  // ThresholdsLayer won't work.
  //
  // So, instead, this `localPlacementMode` state is kept in sync with the data from the
  // placementModeRef. This is kinda gross, and isn't all that performant at the end of the day
  // either. Doing this properly would require making a new version of the ThresholdsLayer that
  // accepted a ref, which I don't really want to do because then it's more code to maintain. Maybe
  // there's some better way to do this than I am missing...
  const [localPlacementMode, setLocalPlacementMode] =
    useState<PlacementMode | null>(null);

  const syncingLayer = (
    <Layer
      onAnimationFrame={() => {
        if (localPlacementMode !== placementModeRef.current) {
          setLocalPlacementMode(placementModeRef.current);
        }
      }}
    />
  );

  // Ensure that the current placement mode is placing a reference ruler
  if (!localPlacementMode) {
    return syncingLayer;
  }
  if (localPlacementMode.type !== 'threshold') {
    return syncingLayer;
  }

  // Ensure that the threshold has known endpoints.
  const { positionA, positionB } = localPlacementMode;
  if (!positionA || !positionB) {
    return syncingLayer;
  }

  return (
    <Fragment>
      {syncingLayer}
      <ThresholdsLayer
        thresholds={[
          {
            id: 'new',
            name: 'beingdrawn',
            positionA,
            positionB,
            relatedPlanSensors: [],
            distanceLabelPosition: Threshold.calculateCenterPoint({
              positionA,
              positionB,
            }),
            relatedSpaces: [],
            locked: false,
            notes: '',
            triggerRegion: [],
            swingsIntoFOV: false,
          },
        ]}
        // Always show custom label, no matter how far in or out the user zooms
        labelVisibilityZoomThreshold={0}
      />
    </Fragment>
  );
};

export default ThresholdCreationLayer;
