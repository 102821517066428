import { useRef, useState, useEffect } from 'react';
import { Icons } from '@density/dust';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import Tooltip from 'components/tooltip';
import { round } from 'lib/math';

type Scale = [number, number];
const DEFAULT_SCALES: Array<Scale> = [
  [1, 1],
  [1, 4],
  [1, 8],
  [1, 12],
  [1, 16],
];

// A custom input control to choose a scale at which an incoming drawing should be imported at
// It has a number of preset scales, as well as a "custom" field
const ScaleField: React.FunctionComponent<{
  value: number;
  scales?: Array<Scale>;
  disabled?: boolean;
  computeDefaultValue?: () => number;
  onChange: (newScale: number) => void;
}> = ({
  value,
  scales = DEFAULT_SCALES,
  disabled = false,
  computeDefaultValue,
  onChange,
}) => {
  const customScaleRef = useRef<HTMLInputElement | null>(null);

  const [scale, setScale] = useState(`${value}`);
  useEffect(() => {
    const roundedValue = round(value, 5);
    if (scales.find(([n, d]) => roundedValue === round(n / d, 4))) {
      setScale(`${roundedValue}`);
    } else {
      setScale('custom');
      setCustomScale(`${round(roundedValue * 100, 4)}`);
    }
  }, [value, scales]);

  // If "custom" selected, use a custom scale percentage
  const [customScale, setCustomScale] = useState('');
  const [customScaleInvalid, setCustomScaleInvalid] = useState(false);
  const onBlurCustomScale = () => {
    if (scale !== 'custom') {
      setCustomScale('');
      setCustomScaleInvalid(false);
      return;
    }

    const customScalePercent = window.parseFloat(customScale);
    if (isNaN(customScalePercent) || customScalePercent < 0) {
      setCustomScaleInvalid(true);
      return;
    }

    setCustomScaleInvalid(false);
    onChange(customScalePercent / 100);
  };

  const scaleChoices = scales.map(([numerator, denominator]) => ({
    id: `${numerator / denominator}`,
    label: `${numerator}:${denominator}`,
    numerator,
    denominator,
  }));

  return (
    <HorizontalForm size="medium">
      {/* Scale presets */}
      <SelectField
        size="medium"
        disabled={disabled}
        width={100}
        value={scale}
        choices={[...scaleChoices, { id: 'custom', label: 'Custom' }]}
        onChange={(rawChoice) => {
          const choice = rawChoice as typeof scaleChoices[0];
          const scale = choice.id;
          if (scale === 'custom') {
            onChange(1.2);
            setCustomScale('120');
            setScale('custom');

            // Focus the custom scale text box
            setTimeout(() => {
              if (customScaleRef.current) {
                customScaleRef.current.select();
                customScaleRef.current.focus();
              }
            }, 0);
          } else {
            setCustomScale('');
            setScale(`${scale}`);
            onChange(choice.numerator / choice.denominator);
          }
        }}
      />

      {/* Custom scale option */}
      {scale === 'custom' ? (
        <TextField
          size="medium"
          ref={customScaleRef}
          width={84}
          placeholder="eg, 31"
          type="number"
          disabled={disabled}
          value={customScale}
          onChange={(e) => setCustomScale(e.currentTarget.value)}
          onBlur={onBlurCustomScale}
          error={customScaleInvalid}
          trailingSuffix="%"
        />
      ) : null}

      {computeDefaultValue ? (
        <Tooltip
          contents="Reset to default"
          placement="bottom"
          target={
            <Button
              size="medium"
              type="cleared"
              trailingIcon={<Icons.RefreshArrowSync size={18} />}
              onClick={() => onChange(computeDefaultValue())}
              disabled={disabled}
            />
          }
        />
      ) : null}
    </HorizontalForm>
  );
};

export default ScaleField;
