import { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { FloorplanCoordinates } from 'lib/geometry';

import { LengthUnit, Meters } from 'lib/units';

import {
  Layer,
  MetricLabel,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { Blue200, Blue300 } from '@density/dust/dist/tokens/dust.tokens';

const SNAP_POINT_CIRCLE_RADIUS_PX = 2;
const SNAP_POINT_CIRCLE_LINE_WIDTH_PX = 5;

// We're drawing a single ruler with two vertices to
// indicate how x/y shift.
export type MovingState = {
  type: 'move';
  vertexA: FloorplanCoordinates | null;
  vertexB: FloorplanCoordinates | null;
  nextPlacement: FloorplanCoordinates | null;
};

const RenderMovingLayer: React.FunctionComponent<{
  movingStateRef: React.RefObject<MovingState | null>;
  displayUnit: LengthUnit;
}> = ({ movingStateRef, displayUnit }) => {
  const context = useFloorplanLayerContext();

  useEffect(() => {
    const line = new PIXI.Graphics();
    line.name = 'move-geometry';
    context.app.stage.addChild(line);

    const endpoint = new PIXI.Graphics();
    endpoint.name = 'endpoint';
    context.app.stage.addChild(endpoint);

    const lengthLabelStyle = {
      backgroundColor: toRawHex(Blue300),
      pinVertical: 'middle',
      pinHorizontal: 'middle',
      textStyle: new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 14,
        fill: '#ffffff',
        fontWeight: 'bold',
      }),
    };

    const lengthLabelAdj = new MetricLabel('0', lengthLabelStyle as any);
    lengthLabelAdj.name = 'length-label-adjacent';
    context.app.stage.addChild(lengthLabelAdj);

    const lengthLabelOpp = new MetricLabel('0', lengthLabelStyle as any);
    lengthLabelOpp.name = 'length-label-opposite';
    context.app.stage.addChild(lengthLabelOpp);

    return () => {
      line.renderable = false;
      endpoint.renderable = false;
      lengthLabelAdj.renderable = false;
      lengthLabelOpp.renderable = false;
      context.app.stage.removeChild(line);
      context.app.stage.removeChild(endpoint);
      context.app.stage.removeChild(lengthLabelOpp);
      context.app.stage.removeChild(lengthLabelAdj);
    };
  }, [context.app]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }
        const viewport = context.viewport.current;

        const line = context.app.stage.getChildByName(
          'move-geometry'
        ) as PIXI.Graphics;
        line.visible = false;

        const endpoint = context.app.stage.getChildByName(
          'endpoint'
        ) as PIXI.Graphics;
        endpoint.visible = false;

        const lengthLabelAdj = context.app.stage.getChildByName(
          'length-label-adjacent'
        ) as MetricLabel;
        lengthLabelAdj.visible = false;

        const lengthLabelOpp = context.app.stage.getChildByName(
          'length-label-opposite'
        ) as MetricLabel;
        lengthLabelOpp.visible = false;

        if (
          !movingStateRef.current ||
          movingStateRef.current.type !== 'move' ||
          !movingStateRef.current.nextPlacement
        ) {
          return;
        }

        line.clear();
        endpoint.clear();

        const nextPlacementViewport =
          FloorplanCoordinates.toViewportCoordinates(
            movingStateRef.current.nextPlacement,
            context.floorplan,
            viewport
          );

        // Draw the rectangle
        endpoint.lineStyle({
          width: SNAP_POINT_CIRCLE_LINE_WIDTH_PX,
          color: toRawHex(Blue300),
        });
        endpoint.drawCircle(0, 0, SNAP_POINT_CIRCLE_RADIUS_PX);
        endpoint.lineStyle({
          width: SNAP_POINT_CIRCLE_LINE_WIDTH_PX / 2,
          color: toRawHex(Blue300),
        });
        endpoint.drawCircle(0, 0, SNAP_POINT_CIRCLE_RADIUS_PX * 4);
        endpoint.x = nextPlacementViewport.x;
        endpoint.y = nextPlacementViewport.y;

        // Generate vertex coordinate positions
        const originViewport = FloorplanCoordinates.toViewportCoordinates(
          movingStateRef.current.vertexA ||
            movingStateRef.current.nextPlacement,
          context.floorplan,
          viewport
        );

        if (movingStateRef.current.vertexA) {
          const targetViewport = FloorplanCoordinates.toViewportCoordinates(
            movingStateRef.current.nextPlacement,
            context.floorplan,
            viewport
          );

          // Calculate the other two vertices of the rectangle
          const geometryPoint = { x: originViewport.x, y: targetViewport.y };

          const deltaX = Math.abs(
            movingStateRef.current.vertexA.x -
              movingStateRef.current.nextPlacement.x
          );
          const deltaY = Math.abs(
            movingStateRef.current.vertexA.y -
              movingStateRef.current.nextPlacement.y
          );

          // Draw the rectangle

          line.lineStyle({ width: 4, color: toRawHex(Blue200) });
          line.moveTo(originViewport.x, originViewport.y);
          line.lineTo(targetViewport.x, targetViewport.y);

          if (deltaY !== 0 && deltaX !== 0) {
            // These actually wouldnt be visible anyway if any of the delats are 0
            line.lineStyle({ width: 1, color: toRawHex(Blue200) });
            line.moveTo(originViewport.x, originViewport.y);
            line.lineTo(geometryPoint.x, geometryPoint.y);
            line.lineTo(targetViewport.x, targetViewport.y);
          }

          let distStringX = `${deltaX.toFixed(2)} m`;
          let distStringY = `${deltaY.toFixed(2)} m`;

          switch (displayUnit) {
            case 'inches':
              distStringX = `${Meters.toInches(deltaX).toFixed(0)} in`;
              distStringY = `${Meters.toInches(deltaY).toFixed(0)} in`;
              break;
            case 'centimeters':
              distStringX = `${Meters.toCentimeters(deltaX).toFixed(0)} cm`;
              distStringY = `${Meters.toCentimeters(deltaY).toFixed(0)} cm`;
              break;
            case 'millimeters':
              distStringX = `${Meters.toMillimeters(deltaX).toFixed(0)} mm`;
              distStringY = `${Meters.toMillimeters(deltaY).toFixed(0)} mm`;
              break;
            case 'feet_and_inches':
              const [ftX, inX] = Meters.toFeetAndInches(deltaX);
              distStringX = `${ftX}' ${inX}"`;
              const [ftY, inY] = Meters.toFeetAndInches(deltaY);
              distStringY = `${ftY}' ${inY}"`;
              break;
          }

          lengthLabelAdj.setText(distStringX);
          lengthLabelAdj.x = (geometryPoint.x + targetViewport.x) / 2;
          lengthLabelAdj.y = targetViewport.y;
          lengthLabelOpp.setText(distStringY);
          lengthLabelOpp.x = geometryPoint.x;
          lengthLabelOpp.y = (geometryPoint.y + originViewport.y) / 2;
          lengthLabelAdj.visible = deltaX !== 0;
          lengthLabelOpp.visible = deltaY !== 0;
        }

        line.visible = true;
        endpoint.visible = true;
      }}
    />
  );
};

export default RenderMovingLayer;
