import { useMemo } from 'react';
import * as React from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import * as PIXI from 'pixi.js';

import {
  toRawHex,
  ObjectLayer,
  useFloorplanLayerContext,
  isWithinViewport,
} from 'components/floorplan';

import { degreesToRadians } from 'lib/math';
import { LengthUnit } from 'lib/units';
import {
  FloorplanCoordinates,
  CADCoordinates,
  ViewportCoordinates,
} from 'lib/geometry';
import PlanSensor from 'lib/sensor';
import {
  FloorplanSpaceChange,
  FloorplanSensorChange,
  FloorplanAreaOfConcernChange,
} from 'lib/cad';

const SENSOR_FOCUSED_OUTLINE_WIDTH_PX = 4;

// A layer that shows all sensors that will be added or removed to the plan during the dxf import
// process.
// Sensors are shown in green (additions), red (deletions), yellow (modifications), grey (no change)
export const SensorsDiffLayer: React.FunctionComponent<{
  floorplanSensorsChanges: Array<FloorplanSensorChange>;
  floorplanCADOrigin: FloorplanCoordinates;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
}> = ({
  floorplanSensorsChanges,
  floorplanCADOrigin,
  cadFileUnit,
  cadFileScale,
}) => {
  const context = useFloorplanLayerContext();

  const sensorMajorMinorInMeters = useMemo(() => {
    const sensorMajorMinorInMeters: { [sensorId: string]: [number, number] } =
      {};

    for (const change of floorplanSensorsChanges) {
      if (change.data.type === 'oa') {
        sensorMajorMinorInMeters[change.data.cadId] =
          PlanSensor.computeCoverageMajorMinorAxisOA(
            change.data.height,
            change.data.sensorFunction
          );
      } else {
        const radiusMeters = PlanSensor.computeCoverageRadiusEntry(
          change.data.height
        );
        sensorMajorMinorInMeters[change.data.cadId] = [
          radiusMeters,
          radiusMeters,
        ];
      }
    }

    return sensorMajorMinorInMeters;
  }, [floorplanSensorsChanges]);

  return (
    <ObjectLayer
      objects={floorplanSensorsChanges}
      extractId={(change) =>
        change.data.cadId +
        change.data.position.x.toString() +
        change.data.position.y.toString()
      }
      onCreate={(getSensor) => {
        const sensorGraphic = new PIXI.Container();

        const coverageArea = new PIXI.Graphics();
        coverageArea.name = 'coverage-area';
        sensorGraphic.addChild(coverageArea);

        return sensorGraphic;
      }}
      onUpdate={(change: FloorplanSensorChange, sensorGraphic) => {
        if (!context.viewport.current) {
          return;
        }

        const majorMinorMeters = sensorMajorMinorInMeters[change.data.cadId];
        if (!majorMinorMeters) {
          return;
        }
        const [majorMeters, minorMeters] = majorMinorMeters;
        const majorPixels =
          majorMeters * context.floorplan.scale * context.viewport.current.zoom;
        const minorPixels =
          minorMeters * context.floorplan.scale * context.viewport.current.zoom;

        let color: number, viewportCoords: ViewportCoordinates;
        if (change.type === 'addition') {
          color = toRawHex(dust.Green400);
          viewportCoords = CADCoordinates.toViewportCoordinates(
            change.data.position,
            floorplanCADOrigin,
            cadFileUnit,
            cadFileScale,
            context.floorplan,
            context.viewport.current
          );
        } else if (change.type === 'modification') {
          color = toRawHex(dust.Yellow400);
          viewportCoords = CADCoordinates.toViewportCoordinates(
            change.data.position,
            floorplanCADOrigin,
            cadFileUnit,
            cadFileScale,
            context.floorplan,
            context.viewport.current
          );
        } else if (change.type === 'deletion') {
          color = toRawHex(dust.Red400);
          viewportCoords = FloorplanCoordinates.toViewportCoordinates(
            change.data.position,
            context.floorplan,
            context.viewport.current
          );
        } else {
          // no-change
          color = toRawHex(dust.Gray400);
          viewportCoords = FloorplanCoordinates.toViewportCoordinates(
            change.data.position,
            context.floorplan,
            context.viewport.current
          );
        }

        // Hide sensors that are not on the screen
        sensorGraphic.renderable = isWithinViewport(
          context,
          viewportCoords,
          -1 * majorPixels
        );

        if (!sensorGraphic.renderable) {
          return;
        }

        sensorGraphic.x = viewportCoords.x;
        sensorGraphic.y = viewportCoords.y;

        // Draw main sensor coverage area
        const coverageArea = sensorGraphic.getChildByName(
          'coverage-area'
        ) as PIXI.Graphics;
        coverageArea.clear();
        coverageArea.lineStyle({
          width: 1,
          color,
          alignment: change.data.type === 'oa' ? 0 : 1,
        });
        coverageArea.beginFill(color, 0.08);
        switch (change.data.type) {
          case 'oa':
            // OA coverage area is a circle
            coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
            break;
          case 'entry':
            // Entry coverage area is a half-circle
            coverageArea.moveTo(0, 0);
            const startAngle = degreesToRadians(change.data.rotation);
            const endAngle = startAngle + Math.PI;
            coverageArea.arc(0, 0, majorPixels, startAngle, endAngle, true);
            coverageArea.lineTo(0, 0);
            break;
        }
        coverageArea.endFill();

        // Draw inset shadow used to indicate that the sensor is selected
        coverageArea.lineStyle({
          width: SENSOR_FOCUSED_OUTLINE_WIDTH_PX,
          color,
          alpha: 0.2,
          join: PIXI.LINE_JOIN.ROUND,
          alignment: change.data.type === 'oa' ? 0 : 1,
        });
        if (change.data.type === 'oa') {
          coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
        } else {
          coverageArea.moveTo(0, 0);
          const startAngle = degreesToRadians(change.data.rotation);
          const endAngle = startAngle + Math.PI;
          coverageArea.arc(0, 0, majorPixels, startAngle, endAngle, true);
          coverageArea.lineTo(0, 0);
        }
      }}
      onRemove={(change, coverageArea) => {
        coverageArea.destroy(true);
      }}
    />
  );
};

export const AreasOfConcernDiffLayer: React.FunctionComponent<{
  floorplanAreasOfConcernChanges: Array<FloorplanAreaOfConcernChange>;
  floorplanCADOrigin: FloorplanCoordinates;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
}> = ({
  floorplanAreasOfConcernChanges,
  floorplanCADOrigin,
  cadFileUnit,
  cadFileScale,
}) => {
  const context = useFloorplanLayerContext();

  return (
    <ObjectLayer
      objects={floorplanAreasOfConcernChanges}
      extractId={(change) => `${change.data.name}-${change.type}`}
      onCreate={(getSensor) => {
        const aocGraphic = new PIXI.Container();
        const coverageArea = new PIXI.Graphics();
        coverageArea.name = 'aoc';
        aocGraphic.addChild(coverageArea);

        return aocGraphic;
      }}
      onUpdate={(change: FloorplanAreaOfConcernChange, aocGraphic) => {
        if (!context.viewport.current) {
          return;
        }

        let color: number, viewportVertices: Array<ViewportCoordinates>;
        if (change.type === 'addition') {
          let vertices: Array<CADCoordinates> = change.data.vertices;
          color = toRawHex(dust.Green400);
          viewportVertices = vertices.map((v) =>
            CADCoordinates.toViewportCoordinates(
              v,
              floorplanCADOrigin,
              cadFileUnit,
              cadFileScale,
              context.floorplan,
              context.viewport.current!
            )
          );
        } else if (change.type === 'modification') {
          let vertices: Array<CADCoordinates> = change.data.vertices;
          color = toRawHex(dust.Yellow400);
          viewportVertices = vertices.map((v) =>
            CADCoordinates.toViewportCoordinates(
              v,
              floorplanCADOrigin,
              cadFileUnit,
              cadFileScale,
              context.floorplan,
              context.viewport.current!
            )
          );
        } else if (change.type === 'deletion') {
          let vertices: Array<FloorplanCoordinates> = change.data.vertices;
          color = toRawHex(dust.Red400);
          viewportVertices = vertices.map((v) =>
            FloorplanCoordinates.toViewportCoordinates(
              v,
              context.floorplan,
              context.viewport.current!
            )
          );
        } else {
          // no-change
          let vertices: Array<FloorplanCoordinates> = change.data.vertices;
          color = toRawHex(dust.Gray400);
          viewportVertices = vertices.map((v) =>
            FloorplanCoordinates.toViewportCoordinates(
              v,
              context.floorplan,
              context.viewport.current!
            )
          );
        }
        //TODO: Do not render outside the viewport.

        // Draw main sensor coverage area
        const coverageArea = aocGraphic.getChildByName('aoc') as PIXI.Graphics;

        const vertices = viewportVertices.map((v) => [v.x, v.y]).flat();

        coverageArea.clear();
        coverageArea.beginFill(color, 0.12);
        coverageArea.lineStyle(2, color, 1); // width, color, alpha
        coverageArea.drawPolygon(vertices);
        coverageArea.endFill();
      }}
      onRemove={(change, coverageArea) => {
        coverageArea.destroy(true);
      }}
    />
  );
};

export const SpacesDiffLayer: React.FunctionComponent<{
  floorplanSpacesChanges: Array<FloorplanSpaceChange>;
  floorplanCADOrigin: FloorplanCoordinates;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
}> = ({
  floorplanSpacesChanges,
  floorplanCADOrigin,
  cadFileUnit,
  cadFileScale,
}) => {
  const context = useFloorplanLayerContext();

  return (
    <ObjectLayer
      objects={floorplanSpacesChanges}
      extractId={(change) => `${change.data.name}-${change.type}`}
      onCreate={(getSensor) => {
        const container = new PIXI.Container();
        const polygon = new PIXI.Graphics();
        polygon.name = 'aoc';
        container.addChild(polygon);

        return container;
      }}
      onUpdate={(change: FloorplanSpaceChange, container) => {
        if (!context.viewport.current) {
          return;
        }

        let color: number, viewportVertices: Array<ViewportCoordinates>;
        if (change.type === 'addition') {
          let vertices: Array<CADCoordinates> = change.data.vertices;
          color = toRawHex(dust.Green400);
          viewportVertices = vertices.map((v) =>
            CADCoordinates.toViewportCoordinates(
              v,
              floorplanCADOrigin,
              cadFileUnit,
              cadFileScale,
              context.floorplan,
              context.viewport.current!
            )
          );
        } else if (change.type === 'modification') {
          let vertices: Array<CADCoordinates> = change.data.vertices;
          color = toRawHex(dust.Yellow400);
          viewportVertices = vertices.map((v) =>
            CADCoordinates.toViewportCoordinates(
              v,
              floorplanCADOrigin,
              cadFileUnit,
              cadFileScale,
              context.floorplan,
              context.viewport.current!
            )
          );
        } else if (change.type === 'deletion') {
          if (change.data.shape.type !== 'polygon') {
            return;
          }
          let vertices: Array<FloorplanCoordinates> =
            change.data.shape.vertices;
          color = toRawHex(dust.Red400);
          viewportVertices = vertices.map((v) =>
            FloorplanCoordinates.toViewportCoordinates(
              v,
              context.floorplan,
              context.viewport.current!
            )
          );
        } else {
          // no-change
          if (change.data.shape.type !== 'polygon') {
            return;
          }
          let vertices: Array<FloorplanCoordinates> =
            change.data.shape.vertices;
          color = toRawHex(dust.Gray400);
          viewportVertices = vertices.map((v) =>
            FloorplanCoordinates.toViewportCoordinates(
              v,
              context.floorplan,
              context.viewport.current!
            )
          );
        }
        //TODO: Do not render outside the viewport.

        const polygon = container.getChildByName('aoc') as PIXI.Graphics;

        const vertices = viewportVertices.map((v) => [v.x, v.y]).flat();

        polygon.clear();
        polygon.beginFill(color, 0.12);
        polygon.lineStyle(2, color, 1); // width, color, alpha
        polygon.drawPolygon(vertices);
        polygon.endFill();
      }}
      onRemove={(change, polygon) => {
        polygon.destroy(true);
      }}
    />
  );
};
