import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Icons } from '@density/dust';
import { SensorsInfo } from './sensors-info';
import { SpaceBuildingManager, SpacesInfo } from './spaces-info';
import { BuildingEntityData, FloorData } from './building-manager';
import { Tooltip } from '@densityco/ui';
import { AOCInfo } from './aoc-info';
import { Meters } from 'lib/units';
import { CoreAPI } from 'lib/api';
import { useAppSelector } from 'redux/store';

type BuildingInfoProps = {
  data: BuildingEntityData | undefined;
};

export const BuildingInfo: React.FunctionComponent<BuildingInfoProps> = ({
  data,
}) => {
  const [showSensorData, setShowSensorData] = useState(false);
  const [showSpaceData, setShowSpaceData] = useState(false);
  const [showAOCData, setShowAOCData] = useState(false);
  const [aocFloorCount, setAOCFloorCount] = useState(0);

  const [spaceFunctionList, setSpaceFunctionList] = useState<{
    [key: string]: string;
  }>({});

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  useEffect(() => {
    if (densityAPIClient) {
      CoreAPI.getSpaceFunctions(densityAPIClient)
        .then((response) => {
          const newFunctions: { [key: string]: string } = { none: 'None' };
          if (response) {
            response.data.forEach((spaceFunction) => {
              newFunctions[spaceFunction.function] =
                spaceFunction.function_display_name;
            });
          }
          setSpaceFunctionList(newFunctions);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, [densityAPIClient, setSpaceFunctionList]);

  useEffect(() => {
    if (!data) return;
    if (data.aoc.length > 0) {
      const uniqueAOCFloorIds = new Set(data.aoc.map((aoc) => aoc.floorId));
      setAOCFloorCount(Array.from(uniqueAOCFloorIds).length);
    }
  }, [data]);

  if (!data) {
    return null;
  }
  const totalCoverage = Meters.toSquareFeet(
    data.aoc.reduce((acc, aoc) => acc + aoc.area_meters_squared, 0)
  );

  const floorCount = data.floors?.length || 0;
  const planningFloorCount =
    data.floors?.filter((floor: FloorData) => floor.status === 'planning')
      .length || 0;
  const liveFloorCount =
    data.floors?.filter((floor: FloorData) => floor.status === 'live').length ||
    0;
  const sensorCount = data.sensors?.length || 0;
  const spaceCount = data.spaces?.length || 0;

  return (
    <section className={styles.dataSection}>
      <h2>Building Info</h2>
      <article className={styles.buildingDataContainer}>
        <section className={styles.buildingInfoDataContainer}>
          <header
            className={styles.buildingInfoDataHeader}
            data-cy="building-info-floors"
          >
            Floors: {floorCount}{' '}
            <span style={{ fontSize: '12px' }}>
              ({planningFloorCount} planning / {liveFloorCount} live)
            </span>
          </header>
        </section>
        <section className={styles.buildingInfoDataContainer}>
          <header
            className={styles.buildingInfoExpandableDataHeader}
            onClick={() => setShowSensorData(!showSensorData)}
            data-cy="building-info-sensors"
          >
            Sensors: {sensorCount}{' '}
            {showSensorData ? <Icons.ArrowDown /> : <Icons.ArrowRightForward />}
          </header>
          {showSensorData && <SensorsInfo sensors={data.sensors} />}
        </section>
        <section className={styles.buildingInfoDataContainer}>
          <header
            className={styles.buildingInfoExpandableDataHeader}
            onClick={() => setShowSpaceData(!showSpaceData)}
            data-cy="building-info-spaces"
          >
            Spaces: {spaceCount}{' '}
            {showSpaceData ? <Icons.ArrowDown /> : <Icons.ArrowRightForward />}
          </header>
          {showSpaceData && (
            <SpacesInfo
              spaces={data.spaces as SpaceBuildingManager[]}
              spaceFunctionList={spaceFunctionList}
            />
          )}
        </section>
        <section className={styles.buildingInfoDataContainer}>
          <div style={{ display: 'flex' }}>
            {aocFloorCount < floorCount && (
              <Tooltip
                contents={
                  aocFloorCount === 0
                    ? `No floors have AOC`
                    : `Only ${aocFloorCount} of ${floorCount} floors have Areas of Coverage`
                }
                placement="bottom"
                target={
                  <div style={{ width: 'fit-content', marginRight: '.3em' }}>
                    <Icons.DangerWarning color="yellow" />
                  </div>
                }
              />
            )}
            <header
              className={styles.buildingInfoExpandableDataHeader}
              onClick={
                totalCoverage ? () => setShowAOCData(!showAOCData) : () => {}
              }
              data-cy="building-info-aoc"
            >
              AOC:{' '}
              {totalCoverage
                ? Math.round(totalCoverage).toLocaleString('en-US') + ' sqft'
                : 'No Areas of Coverage'}{' '}
              {totalCoverage ? (
                showAOCData ? (
                  <Icons.ArrowDown />
                ) : (
                  <Icons.ArrowRightForward />
                )
              ) : null}
            </header>
          </div>
          {showAOCData && <AOCInfo aocArr={data.aoc} />}
        </section>
      </article>
    </section>
  );
};
