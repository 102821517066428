import { toast } from 'react-toastify';
import { State } from '../state';
import {
  syncAreaOfConcernDeleteToAPI,
  syncPlanSensorDeleteToAPI,
  syncSerializedPhotoGroupDeleteToAPI,
  syncSpaceDeleteToAPI,
  syncThresholdDeleteToAPI,
} from '../editor';
import { selectClosestObject } from '../utils';
import { KeybindConfig } from './keybindings';

export function keybindActions({
  dispatch,
  state,
  mutation,
  onZoomToFitClick,
  onUndoClick,
  onRedoClick,
  client,
  plan,
}: KeybindConfig) {
  const handleArrowKeyPress = (direction: string) => {
    if (!state.focusedObject) return;
    // only types that arrow key is implemented for on focused objects
    const focusedObject = state.focusedObject as {
      type: 'areaofconcern' | 'sensor' | 'space';
      id: string;
    };
    const itemsMap = {
      areaofconcern: state.areasOfConcern.items,
      sensor: state.planSensors.items,
      space: state.spaces.items,
    };
    // grab all of the objects of the same type as the focused object
    const objectList = itemsMap[focusedObject.type];
    if (!objectList) return;
    const closestObject = selectClosestObject(
      state.focusedObject,
      objectList,
      direction
    );
    const focusType = state.focusedObject.type;
    const type =
      focusType === 'sensor'
        ? 'sensor.focus'
        : focusType === 'space'
        ? 'space.focus'
        : 'areaOfConcern.focus';

    if (closestObject) dispatch({ type, id: closestObject });
  };
  return {
    toggleWalls() {
      dispatch({ type: 'menu.planning.toggleWalls' });

      toast.success('Toggled Wall Segments.', {
        position: 'top-center',
      });
    },

    toggleAllOn() {
      dispatch({ type: 'menu.planning.toggleAllOn' });
      toast.success('Toggled all layers on.', {
        position: 'top-center',
      });
    },

    toggleAllOff() {
      dispatch({ type: 'menu.planning.toggleAllOff' });
      toast.success('Toggled all layers off.', { position: 'top-center' });
    },

    toggleOffAllButSpaces() {
      dispatch({ type: 'menu.planning.toggleOffAllButSpaces' });
      toast.success('Showing only Space layers.', { position: 'top-center' });
    },

    toggleOffAllButOA() {
      dispatch({ type: 'menu.planning.toggleOffAllButOpenArea' });
      toast.success('Showing only Open Area layers: Sensors and Spaces.', {
        position: 'top-center',
      });
    },

    toggleOffAllButEntry() {
      dispatch({ type: 'menu.planning.toggleOffAllButEntry' });
      toast.success(
        'Showing only Entry layers: Sensors, Spaces, and Doorways.',
        {
          position: 'top-center',
        }
      );
    },

    loadToggles() {
      dispatch({ type: 'menu.planning.loadFromLocalStorage' });
      toast.success('Loaded your saved toggles.', { position: 'top-center' });
    },

    ctrl() {
      dispatch({ type: 'keys.ctrl', active: true });
    },

    ctrlUp() {
      dispatch({ type: 'keys.ctrl', active: false });
      dispatch({ type: 'keys.ctrlShift', active: false });
    },

    ctrlShift() {
      dispatch({ type: 'keys.ctrlShift', active: true });
    },

    ctrlShiftUp() {
      dispatch({ type: 'keys.ctrlShift', active: false });
    },

    // alt not handled in dispatch
    alt() {},

    altUp() {},

    escape() {
      dispatch({ type: 'placement.cancel' });
      dispatch({ type: 'selectionMode.end' });
      dispatch({ type: 'bulk.end' });
      dispatch({ type: 'item.graphic.clearCopy' });
    },

    addOASensor() {
      if (state.locked) return;
      dispatch({ type: 'menu.addSensor', sensorType: 'oa' });
      toast.success('Adding an OA Sensor...', { position: 'top-center' });
    },

    addEntrySensor() {
      if (state.locked) return;
      dispatch({ type: 'menu.addSensor', sensorType: 'entry' });
      toast.success('Adding an Entry Sensor...', { position: 'top-center' });
    },

    addOABoxSpace() {
      if (state.locked) {
        return;
      }
      toast.success('Adding a boxed OA Space...', {
        position: 'top-center',
      });
      dispatch({ type: 'menu.addSpace', shape: 'box', countingMode: 'oa' });
    },

    addOACircleSpace() {
      if (state.locked) {
        return;
      }
      toast.success('Adding a circled OA Space...', {
        position: 'top-center',
      });
      dispatch({
        type: 'menu.addSpace',
        shape: 'circle',
        countingMode: 'oa',
      });
    },

    addOAPolygonSpace() {
      if (state.locked) {
        return;
      }
      toast.success('Adding a polygonal OA Space...', {
        position: 'top-center',
      });
      dispatch({
        type: 'menu.addSpace',
        shape: 'polygon',
        countingMode: 'oa',
      });
    },

    addEntryBoxSpace() {
      if (state.locked) {
        return;
      }
      toast.success('Adding a boxed Entry Space...', {
        position: 'top-center',
      });
      dispatch({
        type: 'menu.addSpace',
        shape: 'box',
        countingMode: 'entry',
      });
    },

    addEntryPolygonSpace() {
      if (state.locked) {
        return;
      }
      toast.success('Adding a polygonal Entry Space...', {
        position: 'top-center',
      });
      dispatch({
        type: 'menu.addSpace',
        shape: 'polygon',
        countingMode: 'entry',
      });
    },

    drawAOC() {
      if (state.locked) {
        return;
      }
      toast.success('Drawing an Area of Coverage...', {
        position: 'top-center',
      });
      dispatch({ type: 'menu.addAreaOfConcern' });
    },

    zoomToFit() {
      onZoomToFitClick();
    },

    delBackspace() {
      if (state.locked) {
        return;
      }
      // NOTE: photo groups have a different focusing mechanism so that it can be focused while
      // other things are also focused
      if (state.focusedPhotoGroupId) {
        const focusedPhotoGroup = state.photoGroups.items.get(
          state.focusedPhotoGroupId
        );
        if (!focusedPhotoGroup) {
          return;
        }
        mutation({
          objectIds: [focusedPhotoGroup.id],
          initialActionCreator: ([photoGroupId]: string[]) => ({
            type: 'photoGroup.remove',
            id: photoGroupId,
          }),
          rollbackActionCreator: ([photoGroupId]: string[]) => ({
            type: 'placement.addPhotoGroup',
            photoGroup: focusedPhotoGroup,
            photoGroupId,
          }),
          syncToAPI: async (
            state: State,
            direction: 'forwards' | 'backwards',
            [photoGroupId]: string[]
          ) =>
            syncSerializedPhotoGroupDeleteToAPI(
              client,
              plan.id,
              state,
              focusedPhotoGroup,
              photoGroupId,
              direction,
              dispatch
            ),
        });
        return;
      }
      if (!state.focusedObject) {
        return;
      }
      const focusedObject = state.focusedObject;

      switch (focusedObject.type) {
        case 'sensor':
          const focusedSensor = state.planSensors.items.get(focusedObject.id);
          if (!focusedSensor) {
            return;
          }
          mutation({
            objectIds: [focusedSensor.id],
            initialActionCreator: ([sensorId]) => ({
              type: 'sensor.remove',
              id: sensorId,
            }),
            rollbackActionCreator: ([sensorId]) => ({
              type: 'placement.addSensor',
              sensor: focusedSensor,
              sensorId,
            }),
            syncToAPI: async (state, direction, [sensorId]) =>
              syncPlanSensorDeleteToAPI(
                client,
                plan.id,
                state,
                focusedSensor,
                sensorId,
                direction,
                dispatch
              ),
          });
          break;
        case 'areaofconcern':
          const focusedAreaOfConcern = state.areasOfConcern.items.get(
            focusedObject.id
          );
          if (!focusedAreaOfConcern) {
            return;
          }
          mutation({
            objectIds: [focusedAreaOfConcern.id],
            initialActionCreator: ([areaOfConcernId]) => ({
              type: 'areaOfConcern.remove',
              id: areaOfConcernId,
            }),
            rollbackActionCreator: ([areaOfConcernId]) => ({
              type: 'placement.addAreaOfConcern',
              areaOfConcern: focusedAreaOfConcern,
              areaOfConcernId,
            }),
            syncToAPI: async (state, direction, [areaOfConcernId]) =>
              syncAreaOfConcernDeleteToAPI(
                client,
                plan.id,
                state,
                focusedAreaOfConcern,
                areaOfConcernId,
                direction,
                dispatch
              ),
          });
          break;
        case 'threshold':
          const focusedThreshold = state.thresholds.items.get(focusedObject.id);
          if (!focusedThreshold) {
            return;
          }
          mutation({
            objectIds: [focusedThreshold.id],
            initialActionCreator: ([thresholdId]) => ({
              type: 'threshold.remove',
              id: thresholdId,
            }),
            rollbackActionCreator: ([thresholdId]) => ({
              type: 'placement.addThreshold',
              threshold: focusedThreshold,
              thresholdId,
            }),
            syncToAPI: async (state, direction, [thresholdId]) =>
              syncThresholdDeleteToAPI(
                client,
                plan.id,
                state,
                focusedThreshold,
                thresholdId,
                direction,
                dispatch
              ),
          });
          break;
        case 'space':
          const focusedSpace = state.spaces.items.get(focusedObject.id);
          if (!focusedSpace) {
            return;
          }
          mutation({
            objectIds: [focusedSpace.id],
            initialActionCreator: ([spaceId]) => ({
              type: 'space.remove',
              id: spaceId,
            }),
            rollbackActionCreator: ([spaceId]) => ({
              type: 'placement.addSpace',
              space: focusedSpace,
              spaceId,
            }),
            syncToAPI: async (state, direction, [spaceId]) =>
              syncSpaceDeleteToAPI(
                client,
                plan.id,
                state,
                focusedSpace,
                spaceId,
                direction,
                dispatch
              ),
          });
          break;
      }
    },

    addReferenceRuler() {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addReference', referenceType: 'ruler' });
    },

    addReferenceHeight() {
      if (
        state.locked ||
        !state.heightMap.enabled ||
        !state.planning.showHeights
      ) {
        return;
      }
      dispatch({ type: 'menu.addReference', referenceType: 'height' });
    },

    flipRenderOrder() {
      dispatch({ type: 'menu.flipRenderOrder' });
    },

    undo(e: Mousetrap.ExtendedKeyboardEvent) {
      if (state.locked) {
        return;
      }
      if (state.wallsEdit.active) {
        return;
      }
      e.preventDefault();
      onUndoClick();
    },

    redo(e: Mousetrap.ExtendedKeyboardEvent) {
      if (state.locked) {
        return;
      }
      if (state.wallsEdit.active) {
        return;
      }
      e.preventDefault();
      onRedoClick();
    },

    upKey() {
      handleArrowKeyPress('up');
    },

    downKey() {
      handleArrowKeyPress('down');
    },

    leftKey() {
      handleArrowKeyPress('left');
    },

    rightKey() {
      handleArrowKeyPress('right');
    },

    cmd() {
      dispatch({ type: 'keys.ctrl', active: true });
    },

    cmdUp() {
      dispatch({ type: 'keys.ctrl', active: false });
      dispatch({ type: 'keys.ctrlShift', active: false });
    },
  };
}
