import * as React from 'react';
import { Fragment, useState, useMemo, useEffect } from 'react';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import robustPointInPolygon from 'robust-point-in-polygon';

import { toast } from 'react-toastify';
import { Icons as DensityUIIcons } from '@densityco/ui';
import classNames from 'classnames';
import styles from './styles.module.scss';

import { Action } from './actions';
import { State } from './state';

import AreaOfConcern from 'lib/area-of-concern';
import { LengthUnit, displayPolygonalArea } from 'lib/units';
import { round, degreesToRadians } from 'lib/math';
import { FloorplanCoordinates } from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import TextField from 'components/text-field';
import NotesBox from 'components/notes-box';
import PercentField from 'components/percent-field';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import Switch from 'components/switch/switch';
import Tooltip from 'components/tooltip';

import Panel, {
  PanelHeader,
  PanelBody,
  PanelActions,
  PanelTitle,
} from 'components/panel';
import RotationField from 'components/rotation-field';
import PlanSensor from 'lib/sensor';
import { isFloorplanAreaOfConcernId } from 'lib/api';

import { SensorHeightInput } from './focused-sensor-panel';

const FocusedAreaOfConcernPanel: React.FunctionComponent<{
  state: State;
  areaOfConcern: AreaOfConcern;
  displayUnit: LengthUnit;
  heightMapExists: boolean;
  wallsExist: boolean;
  onSwapSensors: (
    areaOfConcern: AreaOfConcern,
    existingPlanSensors: Array<PlanSensor>,
    newPlanSensors: Array<PlanSensor>
  ) => void;
  onChangeName: (name: AreaOfConcern['name']) => void;
  onChangeNotes: (notes: AreaOfConcern['notes']) => void;
  onChangeLocked: (locked: AreaOfConcern['locked']) => void;
  onDelete: () => void;
  dispatch: React.Dispatch<Action>;
}> = ({
  state,
  areaOfConcern,
  displayUnit,
  heightMapExists,
  wallsExist,
  onSwapSensors,
  onChangeName,
  onChangeNotes,
  onChangeLocked,
  onDelete,
  dispatch,
}) => {
  const [minimumExclusiveAreaText, setMinimumExclusiveAreaText] = useState('');
  const [minimumExclusiveAreaTextValid, setMinimumExclusiveAreaTextValid] =
    useState(true);

  useEffect(() => {
    setMinimumExclusiveAreaText(`${areaOfConcern.minimumExclusiveArea}`);
    setMinimumExclusiveAreaTextValid(true);
  }, [areaOfConcern.minimumExclusiveArea]);

  const [cadIdPrefixText, setCadIdPrefixText] = useState('');
  useEffect(() => {
    setCadIdPrefixText(areaOfConcern.cadIdPrefix);
  }, [areaOfConcern.cadIdPrefix]);

  const [workingName, setWorkingName] = useState(areaOfConcern.name);
  useEffect(() => {
    setWorkingName(areaOfConcern.name);
  }, [areaOfConcern.name]);

  const [workingSensorBaseAngleDegrees, setWorkingSensorBaseAngleDegrees] =
    useState(0);
  useEffect(() => {
    setWorkingSensorBaseAngleDegrees(areaOfConcern.sensorBaseAngleDegrees);
  }, [areaOfConcern.sensorBaseAngleDegrees]);

  // Compute all pre-existing sensors that are within the area of concern
  const existingPlanSensors = useMemo(() => {
    return FloorplanCollection.list(state.planSensors).filter((sensor) => {
      if (sensor.type !== 'oa') {
        return false;
      }

      const [major, minor] = PlanSensor.computeCoverageMajorMinorAxisOA(
        sensor.height,
        sensor.sensorFunction
      );

      // Convert sensor dealized ellipse shape into a polygon
      const sensorEllipsePolygonPoints: Array<FloorplanCoordinates> = [];
      for (let theta = 0; theta < 360; theta += 2) {
        // ref: https://stackoverflow.com/a/4467275/4115328
        const cosTheta = Math.cos(degreesToRadians(theta));
        const sinTheta = Math.sin(degreesToRadians(theta));
        const cosRotation = Math.cos(degreesToRadians(sensor.rotation));
        const sinRotation = Math.sin(degreesToRadians(sensor.rotation));
        const x =
          minor * cosTheta * cosRotation - major * sinTheta * sinRotation;
        const y =
          major * sinTheta * cosRotation + minor * cosTheta * sinRotation;

        sensorEllipsePolygonPoints.push(
          FloorplanCoordinates.create(
            sensor.position.x + x,
            sensor.position.y + y
          )
        );
      }

      // Check all the polygon points, and if any are in the area of concern, the sensor is in
      // the area of concern
      const sensorWithinRegion = sensorEllipsePolygonPoints.some((point) => {
        const result = robustPointInPolygon(
          areaOfConcern.vertices.map((vertex) => [vertex.x, vertex.y]),
          [point.x, point.y]
        );
        const pointWithinRegion = result !== 1; /* 1 = outside polygon */
        return pointWithinRegion;
      });

      return sensorWithinRegion;
    });
  }, [state.planSensors, areaOfConcern.vertices]);

  const coverageArea = displayPolygonalArea(
    areaOfConcern.vertices,
    displayUnit
  );

  if (areaOfConcern.sensorsEnabled) {
    return (
      <Fragment>
        <Panel position="top-left" height={420}>
          <PanelTitle icon={<Icons.CogGearSettings size={18} />}>
            Adjustments
          </PanelTitle>
          <PanelBody>
            Sensor Height:{' '}
            <SensorHeightInput
              value={areaOfConcern.sensorHeight}
              sensorHeightBounds={{
                min: PlanSensor.computeMinHeight('oaMidRange'), // TODO: Fix this when introducing autolayout
                max: PlanSensor.computeMaxHeight('oaMidRange'), // TODO: same
              }}
              disabled={areaOfConcern.sensorPlacements.type === 'loading'}
              displayUnits={displayUnit}
              onChange={(height: number) => {
                // Only perform an update if the height actually changed.
                if (height === areaOfConcern.sensorHeight) {
                  return;
                }

                dispatch({
                  type: 'areaOfConcern.changeSensorHeight',
                  id: areaOfConcern.id,
                  height,
                });
              }}
            />
            Sensor Angle:{' '}
            <RotationField
              value={workingSensorBaseAngleDegrees}
              onChange={setWorkingSensorBaseAngleDegrees}
              disabled={areaOfConcern.sensorPlacements.type === 'loading'}
              onBlur={(angle: number) => {
                // Only perform an update if the rotation actually changed.
                if (angle === areaOfConcern.sensorBaseAngleDegrees) {
                  return;
                }

                dispatch({
                  type: 'areaOfConcern.changeSensorBaseAngle',
                  id: areaOfConcern.id,
                  angle,
                });
              }}
              onRotateRight90={() => {
                let angle = workingSensorBaseAngleDegrees + 90;
                while (angle >= 360) {
                  angle -= 360;
                }

                dispatch({
                  type: 'areaOfConcern.changeSensorBaseAngle',
                  id: areaOfConcern.id,
                  angle,
                });
              }}
            />
            <HorizontalForm size="small">
              <Button
                size="small"
                type="hollow"
                disabled={areaOfConcern.sensorPlacements.type === 'loading'}
                onClick={() => {
                  dispatch({
                    type: 'areaOfConcern.changeSafetyFactorPercentage',
                    id: areaOfConcern.id,
                    value: 33,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeMinimumExclusiveArea',
                    id: areaOfConcern.id,
                    value: 2,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionWallsEnabled',
                    id: areaOfConcern.id,
                    enabled: false,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled',
                    id: areaOfConcern.id,
                    enabled: false,
                  });
                }}
              >
                ROM
              </Button>

              <Button
                size="small"
                type="hollow"
                disabled={
                  !wallsExist ||
                  areaOfConcern.sensorPlacements.type === 'loading'
                }
                onClick={() => {
                  dispatch({
                    type: 'areaOfConcern.changeSafetyFactorPercentage',
                    id: areaOfConcern.id,
                    value: 33,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeMinimumExclusiveArea',
                    id: areaOfConcern.id,
                    value: 2,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionWallsEnabled',
                    id: areaOfConcern.id,
                    enabled: true,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled',
                    id: areaOfConcern.id,
                    enabled: false,
                  });
                }}
              >
                Walls
              </Button>

              <Button
                size="small"
                type="hollow"
                disabled={
                  !wallsExist ||
                  !heightMapExists ||
                  areaOfConcern.sensorPlacements.type === 'loading'
                }
                onClick={() => {
                  dispatch({
                    type: 'areaOfConcern.changeSafetyFactorPercentage',
                    id: areaOfConcern.id,
                    value: 45,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeMinimumExclusiveArea',
                    id: areaOfConcern.id,
                    value: 8,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionWallsEnabled',
                    id: areaOfConcern.id,
                    enabled: true,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled',
                    id: areaOfConcern.id,
                    enabled: true,
                  });
                }}
              >
                All
              </Button>
              <Button
                size="small"
                type="hollow"
                disabled={
                  !wallsExist ||
                  areaOfConcern.sensorPlacements.type === 'loading'
                }
                onClick={() => {
                  dispatch({
                    type: 'areaOfConcern.changeSafetyFactorPercentage',
                    id: areaOfConcern.id,
                    value: 20,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeMinimumExclusiveArea',
                    id: areaOfConcern.id,
                    value: 1,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionWallsEnabled',
                    id: areaOfConcern.id,
                    enabled: true,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled',
                    id: areaOfConcern.id,
                    enabled: false,
                  });
                }}
              >
                W&rarr;W
              </Button>
              <Button
                size="small"
                type="hollow"
                disabled={
                  !wallsExist ||
                  !heightMapExists ||
                  areaOfConcern.sensorPlacements.type === 'loading'
                }
                onClick={() => {
                  dispatch({
                    type: 'areaOfConcern.changeSafetyFactorPercentage',
                    id: areaOfConcern.id,
                    value: 20,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeMinimumExclusiveArea',
                    id: areaOfConcern.id,
                    value: 1,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionWallsEnabled',
                    id: areaOfConcern.id,
                    enabled: true,
                  });
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled',
                    id: areaOfConcern.id,
                    enabled: true,
                  });
                }}
              >
                W&rarr;W + C
              </Button>
            </HorizontalForm>
            Minimum Exclusive Area:{' '}
            <TextField
              size="medium"
              value={minimumExclusiveAreaText}
              onChange={(e) =>
                setMinimumExclusiveAreaText(e.currentTarget.value)
              }
              error={!minimumExclusiveAreaTextValid}
              disabled={areaOfConcern.sensorPlacements.type === 'loading'}
              placeholder="eg: 1"
              onBlur={() => {
                const minimumExclusiveArea = parseFloat(
                  minimumExclusiveAreaText
                );
                if (
                  isNaN(minimumExclusiveArea) ||
                  `${minimumExclusiveArea}` !== minimumExclusiveAreaText
                ) {
                  setMinimumExclusiveAreaTextValid(false);
                  return;
                }
                setMinimumExclusiveAreaTextValid(true);

                // Only perform an update if the value actually changed.
                if (
                  minimumExclusiveArea === areaOfConcern.minimumExclusiveArea
                ) {
                  return;
                }

                dispatch({
                  type: 'areaOfConcern.changeMinimumExclusiveArea',
                  id: areaOfConcern.id,
                  value: minimumExclusiveArea,
                });
              }}
              trailingSuffix="m^2"
              type="number"
              min={0}
              step={0.1}
            />
            Safety Factor:{' '}
            <PercentField
              value={areaOfConcern.safetyFactorPercentage}
              onChange={(value) =>
                dispatch({
                  type: 'areaOfConcern.changeSafetyFactorPercentage',
                  id: areaOfConcern.id,
                  value,
                })
              }
              disabled={areaOfConcern.sensorPlacements.type === 'loading'}
              minValue={-Infinity}
              maxValue={Infinity}
            />
            Use height map when computing sensor coverage:
            <Switch
              isChecked={areaOfConcern.coverageIntersectionHeightMapEnabled}
              isDisabled={
                !heightMapExists ||
                areaOfConcern.sensorPlacements.type === 'loading'
              }
              onChange={(event) =>
                dispatch({
                  type: 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled',
                  id: areaOfConcern.id,
                  enabled: event.currentTarget.checked,
                })
              }
            />
            <Fragment>
              Use walls when computing sensor coverage:
              <Switch
                isChecked={areaOfConcern.coverageIntersectionWallsEnabled}
                isDisabled={
                  !wallsExist ||
                  areaOfConcern.sensorPlacements.type === 'loading'
                }
                onChange={(event) =>
                  dispatch({
                    type: 'areaOfConcern.changeCoverageIntersectionWallsEnabled',
                    id: areaOfConcern.id,
                    enabled: event.currentTarget.checked,
                  })
                }
              />
            </Fragment>
            <Fragment>
              Small Room Detection - <small>EXPERIMENTAL</small>
              <Switch
                isChecked={areaOfConcern.smallRoomMode}
                isDisabled={
                  !wallsExist ||
                  !areaOfConcern.coverageIntersectionWallsEnabled ||
                  areaOfConcern.sensorPlacements.type === 'loading'
                }
                onChange={(event) =>
                  dispatch({
                    type: 'areaOfConcern.changeSmallRoomMode',
                    id: areaOfConcern.id,
                    enabled: event.currentTarget.checked,
                  })
                }
              />
            </Fragment>
          </PanelBody>
          <PanelActions
            left={
              <Button
                size="medium"
                type="cleared"
                leadingIcon={<Icons.ArrowLeftBack size={18} />}
                onClick={() =>
                  dispatch({
                    type: 'areaOfConcern.changeSensorsEnabled',
                    id: areaOfConcern.id,
                    enabled: false,
                  })
                }
              >
                Back
              </Button>
            }
          />
        </Panel>
        <Panel position="bottom-left" bottom={64}>
          <PanelTitle icon={<Icons.IntegrationsAngleInclined size={18} />}>
            Place OA Sensors
          </PanelTitle>
          <PanelBody>
            {areaOfConcern.sensorPlacements.type === 'loading' ? (
              <Fragment>Loading...</Fragment>
            ) : null}
            {areaOfConcern.sensorPlacements.type === 'done' ? (
              <Fragment>
                Computed number of sensors:{' '}
                {areaOfConcern.sensorPlacements.data.length}
                {!areaOfConcern.coverageIntersectionHeightMapEnabled ? (
                  <span style={{ color: dust.Yellow400, fontSize: 14 }}>
                    <br />
                    <Icons.DangerWarningFill size={14} />
                    The number of sensors and sensor placements are approximate.
                    To get a better result, upload a height map or walls.
                  </span>
                ) : null}
                (
                {round(
                  areaOfConcern.sensorPlacements.ellapsedMilliseconds / 1000,
                  2
                )}{' '}
                seconds)
              </Fragment>
            ) : null}
            {areaOfConcern.sensorPlacements.type === 'failed' ? (
              <Fragment>
                Failed to autolayout sensors! See the console for a stack trace.
              </Fragment>
            ) : null}
            <br />
            CAD ID Prefix:{' '}
            <TextField
              value={cadIdPrefixText}
              placeholder="ex: CUST-LOC-FLR-"
              trailingSuffix={
                cadIdPrefixText.length > 0 ? '-OAS-###' : 'OAS-###'
              }
              onChange={(e) => setCadIdPrefixText(e.currentTarget.value)}
              onBlur={() => {
                dispatch({
                  type: 'areaOfConcern.changeCadIdPrefix',
                  id: areaOfConcern.id,
                  prefix: cadIdPrefixText,
                });
              }}
            />
            <br />
          </PanelBody>

          <PanelActions
            right={
              <HorizontalForm size="medium">
                {areaOfConcern.sensorPlacements.type === 'loading' ? (
                  <Button size="medium" disabled>
                    Loading
                  </Button>
                ) : null}
                {areaOfConcern.sensorPlacements.type === 'done' ? (
                  <Button
                    size="medium"
                    onClick={() => {
                      if (areaOfConcern.sensorPlacements.type !== 'done') {
                        return;
                      }

                      // Find the largest CAD ID which starts with the specified prefix
                      const cadIdPrefix = areaOfConcern.cadIdPrefix || '';
                      const allOACadIdNumberSections = FloorplanCollection.list(
                        state.planSensors
                      )
                        .filter((sensor) =>
                          sensor.cadId.startsWith(cadIdPrefix)
                        )
                        .map((sensor) => sensor.cadId.slice(cadIdPrefix.length))
                        // Disregard entry cad ids
                        .filter(
                          (unPrefixedCadId) => !unPrefixedCadId.startsWith('E')
                        )
                        .map((unPrefixedCadId) => {
                          const match = /([0-9]+)$/.exec(unPrefixedCadId);
                          return match ? match[1] : null;
                        });
                      const allOACadIdNumbers = allOACadIdNumberSections
                        .filter((num) => num !== null)
                        .map((num) => parseInt(num as string, 10))
                        .filter((num) => !isNaN(num));

                      let largestPrexistingCadIdNumber = Math.max(
                        ...allOACadIdNumbers
                      );
                      if (largestPrexistingCadIdNumber < 0) {
                        largestPrexistingCadIdNumber = 0;
                      }

                      // Figure out how many numerical digits were used in cad ids on this plan
                      // ie, OAS0001 would have 4 numerical digits
                      const longestNumberOfDigitsInCadId = Math.max(
                        ...allOACadIdNumberSections.map((n) =>
                          n ? n.length : 0
                        )
                      );

                      // When generating a cad id, the numeric part should have at least this many digits
                      const maxNumberOfSensors =
                        largestPrexistingCadIdNumber +
                        areaOfConcern.sensorPlacements.data.length;
                      const defaultCadIdDigits = 3;
                      const maxCadIdDigits = Math.max(
                        `${maxNumberOfSensors}`.length,
                        longestNumberOfDigitsInCadId,
                        defaultCadIdDigits
                      );

                      // Create actual sensors based off the auto generated sensor placements
                      let newPlanSensors: Array<PlanSensor> = [];
                      let cadIdNumber = largestPrexistingCadIdNumber;
                      for (const {
                        positionOffset,
                        heightMeters,
                        angleDegrees,
                      } of areaOfConcern.sensorPlacements.data) {
                        const position = FloorplanCoordinates.create(
                          areaOfConcern.position.x + positionOffset[0],
                          areaOfConcern.position.y + positionOffset[1]
                        );
                        const filteredSensors = PlanSensor.filterByType(
                          'oa',
                          state.planSensors
                        );
                        const sensorName = PlanSensor.generateName(
                          'oa',
                          filteredSensors.length
                        );
                        const sensor = PlanSensor.create(
                          'oa',
                          'oaMidRange', // TODO: Make this a variable once autopopulation impemented
                          position,
                          heightMeters,
                          angleDegrees,
                          sensorName
                        );
                        sensor.locked = true;

                        cadIdNumber += 1;
                        const paddedCadId = `${cadIdNumber}`.padStart(
                          maxCadIdDigits,
                          '0'
                        );
                        sensor.cadId = areaOfConcern.cadIdPrefix
                          ? `${areaOfConcern.cadIdPrefix}-OAS${paddedCadId}`
                          : `OAS${paddedCadId}`;

                        newPlanSensors.push(sensor);
                      }

                      onSwapSensors(
                        areaOfConcern,
                        existingPlanSensors,
                        newPlanSensors
                      );
                    }}
                  >
                    {existingPlanSensors.length > 0
                      ? `Replace ${existingPlanSensors.length} ${
                          existingPlanSensors.length === 1
                            ? 'sensor'
                            : 'sensors'
                        }`
                      : `Create ${areaOfConcern.sensorPlacements.data.length} ${
                          areaOfConcern.sensorPlacements.data.length === 1
                            ? 'sensor'
                            : 'sensors'
                        }`}
                  </Button>
                ) : null}
                {areaOfConcern.sensorPlacements.type === 'failed' ? (
                  <Button size="medium" disabled>
                    Autolayout Failed
                  </Button>
                ) : null}
              </HorizontalForm>
            }
          />
        </Panel>
      </Fragment>
    );
  }

  return (
    <div data-cy="focused-aoc-panel">
      <Panel position="top-left" width={320}>
        <PanelHeader>
          <TextField
            type="text"
            size="medium"
            value={workingName}
            placeholder={'ex. South Desk Area'}
            disabled={state.locked || areaOfConcern.locked}
            onChange={(evt) => setWorkingName(evt.currentTarget.value)}
            onBlur={() => {
              if (workingName !== areaOfConcern.name) {
                onChangeName(workingName);
              }
            }}
            leadingIcon={
              <DensityUIIcons.TagFill
                height={18}
                width={18}
                color={dust.Orange400}
              />
            }
            width="100%"
            data-cy="aoc-name"
          />
        </PanelHeader>

        <PanelBody>
          <label htmlFor="aoc-notes">Notes:</label>
          <NotesBox
            notes={areaOfConcern.notes}
            disabled={state.locked || areaOfConcern.locked}
            onNotesEdited={(notes) => onChangeNotes(notes)}
            data-cy="aoc-notes"
          />

          <div className={styles.spaceConfigMeta}>
            <div className={styles.sensorConfigMetaRow}>
              <span>Area:&nbsp;</span>
              <span className={styles.muted}>{coverageArea}</span>
            </div>

            <div className={styles.sensorConfigMetaRow}>
              <span>Sides:&nbsp;</span>
              <span className={styles.muted}>
                {areaOfConcern.vertices.length}
              </span>
            </div>

            <div className={styles.sensorConfigMetaRow}>
              <span>Centroid:&nbsp;</span>
              <span className={styles.muted}>
                [{areaOfConcern.position.x.toFixed(2)},&nbsp;
                {areaOfConcern.position.y.toFixed(2)}]
              </span>
            </div>

            <div
              className={classNames(styles.sensorConfigMetaRow, {
                [styles.dimmed]:
                  isFloorplanAreaOfConcernId(areaOfConcern.id) === null,
              })}
            >
              <span>ID:&nbsp;</span>
              {isFloorplanAreaOfConcernId(areaOfConcern.id) ? (
                <HorizontalForm size="small">
                  {areaOfConcern.id}
                  <Button
                    size="nano"
                    type="cleared"
                    trailingIcon={<Icons.CopyDuplicate size={14} />}
                    onClick={() => {
                      navigator.clipboard.writeText(areaOfConcern.id);
                      toast.success('Copied to clipboard.');
                    }}
                  />
                </HorizontalForm>
              ) : (
                `Unsaved [${areaOfConcern.id}]`
              )}
            </div>
          </div>
          {areaOfConcern.sensorPlacements.type === 'loading' ? (
            <Fragment>Loading...</Fragment>
          ) : null}
          {areaOfConcern.sensorPlacements.type === 'done' ? (
            <Fragment>
              Number of sensors: {areaOfConcern.sensorPlacements.data.length}
              {!areaOfConcern.coverageIntersectionHeightMapEnabled ||
              !areaOfConcern.coverageIntersectionWallsEnabled ? (
                <span style={{ color: dust.Yellow400, fontSize: 14 }}>
                  <br />
                  <Icons.DangerWarningFill size={14} />
                  The number of sensors and sensor placements are approximate.
                  To get a better result, upload a height map and/or walls.
                </span>
              ) : null}
            </Fragment>
          ) : null}
          {areaOfConcern.sensorPlacements.type === 'failed' ? (
            <Fragment>
              Failed to autolayout sensors! See the console for a stack trace.
            </Fragment>
          ) : null}
          <br />
          <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            Place OA Sensors [disabled]
            <Button
              size="medium"
              onClick={() =>
                dispatch({
                  type: 'areaOfConcern.changeSensorsEnabled',
                  id: areaOfConcern.id,
                  enabled: true,
                })
              }
              trailingIcon={<Icons.ArrowRightForward size={16} />}
              disabled={true || areaOfConcern.locked || state.locked} // FIXME: enabled when autolayout correctly implemented
            >
              Next
            </Button>
          </div>
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.Trash size={18} />}
                danger
                disabled={areaOfConcern.locked || state.locked}
                onClick={() => onDelete()}
                data-cy="aoc-delete-button"
              />
              <Button
                type="cleared"
                disabled={state.locked}
                size="medium"
                trailingIcon={
                  areaOfConcern.locked ? (
                    <Icons.LockClosed size={18} color={dust.Yellow400} />
                  ) : (
                    <Icons.LockOpen size={18} />
                  )
                }
                data-cy={
                  areaOfConcern.locked ? 'aoc-unlock-button' : 'aoc-lock-button'
                }
                onClick={() => {
                  onChangeLocked(!areaOfConcern.locked);
                }}
              />
              <Tooltip
                contents={'Duplicate This Area'}
                placement="bottom"
                target={
                  <Button
                    type="cleared"
                    size="medium"
                    onClick={() =>
                      dispatch({
                        type: 'menu.duplicateAreaOfConcern',
                        id: areaOfConcern.id,
                      })
                    }
                    trailingIcon={<Icons.CopyPlusDuplicate size={18} />}
                    data-cy="aoc-focused-panel-duplicate"
                    disabled={state.locked}
                  />
                }
              />
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              data-cy="aoc-dismiss"
              onClick={() =>
                dispatch({
                  type: 'areaOfConcern.dismiss',
                  id: areaOfConcern.id,
                })
              }
            >
              Done
            </Button>
          }
        />
      </Panel>
    </div>
  );
};

export default FocusedAreaOfConcernPanel;
