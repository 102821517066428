import { useEffect, useCallback, useRef } from 'react';
import * as PIXI from 'pixi.js';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/store';

import { FloorplanCoordinates } from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import PlanSensor from 'lib/sensor';

import {
  ObjectLayer,
  useFloorplanLayerContext,
  toRawHex,
  MetricLabel,
} from 'components/floorplan';

import { EntryCountSocketMessage } from 'lib/entry-counts-live-socket';

import { Blue700 } from '@density/dust/dist/tokens/dust.tokens';

export type CountsData = {
  serialNumber: string | null;
  cadId: string;
  count: number;
  position: FloorplanCoordinates;
  lastEventTimestamp: Array<string>;
  sensorFunction: PlanSensor['sensorFunction'];
  id: string;
};

// Insert description here.
const EntryCountsLayer: React.FunctionComponent<{
  sensors: FloorplanCollection<PlanSensor>;
  onWebsocketConnectionClosed: () => void;
  showOpenEntry?: boolean;
  showTofEntry?: boolean;
}> = ({
  sensors,
  onWebsocketConnectionClosed,
  showOpenEntry,
  showTofEntry,
}) => {
  const context = useFloorplanLayerContext();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const aggregatedCountsData = useRef<Array<CountsData>>(
    FloorplanCollection.list(sensors)
      .filter((sensor) => sensor.type === 'entry')
      .map((sensor) => {
        return {
          serialNumber: sensor.serialNumber,
          count: 0,
          cadId: sensor.cadId,
          position: sensor.position,
          sensorFunction: sensor.sensorFunction,
          lastEventTimestamp: [],
          id: sensor.id,
        };
      })
  );

  // When message is received, append count to appropriate sensor.
  const onMessage = useCallback((message: string, serialNumbers: string[]) => {
    const event: EntryCountSocketMessage = JSON.parse(message);
    if (!event.payload || event.payload.type !== 'count') {
      return;
    }

    const sensorSerial = event.payload.serial_number;
    const timestamp = event.payload.timestamp;

    if (!serialNumbers.includes(sensorSerial)) {
      return;
    }

    aggregatedCountsData.current.forEach((sensor) => {
      if (sensor.serialNumber !== sensorSerial) {
        return;
      }

      if (sensor.lastEventTimestamp.includes(timestamp)) {
        return;
      }

      sensor.count += 1;
      sensor.lastEventTimestamp.push(timestamp);
      toast.info(
        `Event @ ${sensor.serialNumber}${
          sensor.cadId ? ', ' + sensor.cadId : ''
        }`,
        {
          draggable: false,
          autoClose: 3000,
        }
      );

      if (sensor.lastEventTimestamp.length > 20) {
        sensor.lastEventTimestamp = sensor.lastEventTimestamp.slice(-10);
      }
    });
  }, []);

  // Subscribe to the websocket.
  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    let socket: WebSocket | null = null;

    const authURL = `/v2/sockets`;

    densityAPIClient
      .post(authURL)
      .then((response) => {
        const socketURL = response.data.url;

        socket = new WebSocket(socketURL);

        socket.addEventListener('open', () => {
          aggregatedCountsData.current = FloorplanCollection.list(sensors)
            .filter((sensor) => sensor.type === 'entry')
            .map((sensor) => {
              return {
                serialNumber: sensor.serialNumber,
                count: 0,
                cadId: sensor.cadId,
                position: sensor.position,
                sensorFunction: sensor.sensorFunction,
                lastEventTimestamp: [],
                id: sensor.id,
              };
            });
        });

        const serialNumbers: string[] = FloorplanCollection.list(sensors)
          .filter((sensor) => sensor.type === 'entry' && sensor.serialNumber)
          .map((sensor) => sensor.serialNumber as string);

        socket.addEventListener('message', (evt) => {
          const payload: unknown = evt.data;

          if (typeof payload !== 'string') {
            throw new Error('Socket message data must be of type string.');
          }

          onMessage(payload, serialNumbers);
        });

        socket.addEventListener('close', () => {
          onWebsocketConnectionClosed();
        });

        socket.addEventListener('error', () => {
          toast.error('Sensor data stream encountered an error!');
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error('Unable to stream sensor data!');
      });

    // Make sure the socket gets closed before unloading this component
    return () => {
      if (socket) {
        socket.close();
      }
    };

    // sensors dependency is ignored as this is not supposed to be a
    // long lived session. I would not expect user to add sensors or
    // assign new serial numbers during the live stream

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [densityAPIClient, onMessage, onWebsocketConnectionClosed]);

  return (
    <ObjectLayer
      objects={aggregatedCountsData.current}
      extractId={(eventCount) => eventCount.id}
      onRemove={(_eventCount, eventCountLabel) => eventCountLabel.destroy(true)}
      onCreate={() => {
        const eventCountLabel = new MetricLabel('0', {
          backgroundColor: toRawHex(Blue700),
          pinVertical: 'middle',
          pinHorizontal: 'middle',
          textStyle: new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 18,
            fontWeight: 'bold',
            fill: '#ffffff',
          }),
        });

        return eventCountLabel;
      }}
      onUpdate={(eventCount: CountsData, eventCountLabel: MetricLabel) => {
        if (!context.viewport.current) {
          return;
        }

        if (
          (!showOpenEntry && eventCount.sensorFunction === 'openEntry') ||
          (!showTofEntry && eventCount.sensorFunction === 'tofEntry')
        ) {
          eventCountLabel.renderable = false;
          // If this type of sensor is hidden via toggle,
          // hide the count as well.
          return;
        } else {
          eventCountLabel.renderable = true;
        }

        const viewport = context.viewport.current;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          eventCount.position,
          context.floorplan,
          viewport
        );

        eventCountLabel.setText(
          eventCount.serialNumber ? eventCount.count.toString() : 'X'
        );
        eventCountLabel.x = viewportCoords.x;
        eventCountLabel.y = viewportCoords.y;
      }}
    />
  );
};

export default EntryCountsLayer;
