import { Middleware, isRejected } from '@reduxjs/toolkit';

import { ErrorReporting } from 'lib/error-reporting';

const IGNORED_ERRORS = ['AbortError', 'ConditionError'];

// https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level
// https://redux.js.org/understanding/history-and-design/middleware#seven-examples
export const crashReporterMiddleware: Middleware =
  (store) => (next) => (action) => {
    if (isRejected(action)) {
      if (
        !IGNORED_ERRORS.some((errorName) => action.error.name === errorName)
      ) {
        ErrorReporting.report(action.error, 'error', {
          action,
          state: store.getState(),
        });
      }
    }

    try {
      return next(action);
    } catch (err) {
      ErrorReporting.report(err, 'error', {
        action,
        state: store.getState(),
      });
      throw err;
    }
  };
