import { FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from '@density/dust';
import styles from './styles.module.scss';
import classnames from 'classnames';

import { SpaceMetaCSVImportError } from 'lib/csv';

import Button from 'components/button';
import Panel, { PanelBody } from 'components/panel';

const DiffOperationIndicator: FunctionComponent<{
  type: 'error' | 'update';
}> = ({ type }) => {
  const [Icon, className] = {
    update: [Icons.Plus, styles.addition],
    error: [Icons.Minus, styles.deletion],
  }[type];

  return (
    <div className={classnames(styles.operationTypeIndicator, className)}>
      <Icon size={14} />
    </div>
  );
};

const SpaceMetaCSVDiffTableError: FunctionComponent<{
  csvErrors: Array<SpaceMetaCSVImportError>;
  onClose: () => void;
}> = ({ csvErrors, onClose }) => {
  let counter = 0;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '50vh',
      }}
    >
      <table
        className={styles.diffCSVTable}
        style={{
          opacity: 1,
          display: 'block',
          overflow: 'auto',
        }}
      >
        <thead>
          <tr>
            <th className={styles.diffHeader}></th>
            <th className={styles.diffHeader}>#</th>
            <th className={styles.diffHeader}>Space ID</th>
            <th className={styles.diffHeader}>Error Details</th>
          </tr>
        </thead>

        <tbody>
          {csvErrors.map((change) => {
            counter++;
            return (
              <tr
                className={classnames(styles.diffRow, {
                  [styles.deletion]: true,
                })}
                data-cy={`csv-diff-row`}
                key={uuidv4()}
              >
                <td>
                  <DiffOperationIndicator type={'error'} />
                </td>
                <td>
                  <div style={{ height: 24 }}>{counter}</div>
                </td>
                <td>
                  <div>{change.spaceId ? change.spaceId : '---'}</div>
                </td>
                <td>
                  <div style={{ height: 24 }}>{change.errorMessage}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: '10px auto', width: '25%' }}>
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
  );
};

const SpaceMetaCSVImport: FunctionComponent<{
  csvErrors: Array<SpaceMetaCSVImportError>;
  onClose: () => void;
}> = ({ csvErrors, onClose }) => {
  return (
    <div
      style={{
        position: 'fixed',
        margin: '0px auto',
        width: '1200px',
        height: '60vh',
        display: 'flex',
        left: '-500px',
        overflowY: 'scroll',
        overflowX: 'scroll',
      }}
    >
      <Panel position="top-left" width="60%">
        <PanelBody>
          {csvErrors.length === 0 ? null : (
            <div>
              <div
                className={styles.diffCSVTableTitle}
                style={{ marginTop: '25px' }}
              >
                Errors
              </div>
              <div className={styles.diffCSVTableDetail}>
                NOTE: The rows below will not be imported. All other rows will
                be imported.
              </div>
              <SpaceMetaCSVDiffTableError
                csvErrors={csvErrors}
                onClose={onClose}
              />
            </div>
          )}
        </PanelBody>
      </Panel>
    </div>
  );
};

export default SpaceMetaCSVImport;
