import * as PIXI from 'pixi.js';

import { toRawHex } from './utils';
import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';

type MetricLabelOptions = {
  backgroundColor: number; // Note: raw hex color format that pixi.js requires
  textStyle: PIXI.TextStyle;
  pinVertical: 'start' | 'middle' | 'end';
  pinHorizontal: 'start' | 'middle' | 'end';
  horizontalPaddingPixels: number;
  verticalPaddingPixels: number;
  radiusPixels: number;
};

export default class MetricLabel extends PIXI.Container {
  contents: PIXI.Text;
  background: PIXI.Graphics;
  options: MetricLabelOptions;
  _text: string;

  constructor(text: string, options?: Partial<MetricLabelOptions>) {
    super();

    options = options || {};
    options.backgroundColor = options.backgroundColor || toRawHex(Blue400);
    options.pinVertical = options.pinVertical || 'middle';
    options.pinHorizontal = options.pinHorizontal || 'middle';
    options.radiusPixels = options.radiusPixels || 16;
    options.textStyle =
      options.textStyle ||
      new PIXI.TextStyle({
        // FIXME: load correct font here
        fontFamily: 'Arial',
        fontSize: 12,
        fontWeight: 'bold',
        fill: '#ffffff',
      });
    options.horizontalPaddingPixels = options.horizontalPaddingPixels || 6;
    options.verticalPaddingPixels = options.verticalPaddingPixels || 2;
    this.options = options as MetricLabelOptions;

    this.contents = new PIXI.Text(text, this.options.textStyle);
    this._text = text;

    this.background = new PIXI.Graphics();
    this.addChild(this.background);

    this.redrawTextBounds();
    this.addChild(this.contents);
  }

  setText(text: string) {
    if (text === this._text) {
      return;
    }
    this.removeChild(this.contents);
    this.contents.destroy(true);

    this.contents = new PIXI.Text(text, this.options.textStyle);
    this._text = text;
    this.addChild(this.contents);
    this.redrawTextBounds();
  }

  redrawTextBounds() {
    // Compute the anchor point of the metric label based on the pin values in the options
    switch (this.options.pinHorizontal) {
      case 'start':
        this.contents.x = this.options.horizontalPaddingPixels;
        break;
      case 'middle':
        this.contents.x = (-1 * this.contents.width) / 2;
        break;
      case 'end':
        this.contents.x =
          -1 * (this.contents.width + this.options.horizontalPaddingPixels);
        break;
    }
    switch (this.options.pinVertical) {
      case 'start':
        this.contents.y = this.options.verticalPaddingPixels;
        break;
      case 'middle':
        this.contents.y = (-1 * this.contents.height) / 2;
        break;
      case 'end':
        this.contents.y =
          -1 * (this.contents.height + this.options.verticalPaddingPixels);
        break;
    }

    this.background.clear();
    this.background.beginFill(this.options.backgroundColor);
    this.background.drawRoundedRect(
      this.contents.x - this.options.horizontalPaddingPixels,
      this.contents.y - this.options.verticalPaddingPixels,
      this.contents.width + this.options.horizontalPaddingPixels * 2,
      this.contents.height + this.options.verticalPaddingPixels * 2,
      this.options.radiusPixels
    );
    this.background.endFill();
  }
}
