import * as React from 'react';
import { Fragment, useEffect, useState, useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { CoreSpaceLabel } from '@densityco/lib-api-types';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';

import FloorplanCollection from 'lib/floorplan-collection';
import { CoreAPI } from 'lib/api';
import Space, { SpaceCountingMode } from 'lib/space';

import Button from 'components/button';
import Tooltip from 'components/tooltip';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import MultiTagField from 'components/multi-tag-field';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';

const SelectedSpacesTable: React.FunctionComponent<{
  selectedSpaces: Space[];
}> = ({ selectedSpaces }) => {
  return (
    <div
      style={{
        width: '235px',
        height: '190px',
        overflowY: 'auto',
        overflowX: 'auto',
      }}
    >
      <table style={{ width: '100%' }}>
        <tbody>
          {selectedSpaces.map((item, index) => (
            <tr key={index}>
              <td style={{ textAlign: 'left' }}>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const BulkEditSpacesPanel: React.FunctionComponent<{
  state: State;
  selectedSpaceIds: Set<Space['id']>;
  client: AxiosInstance;
  dispatch: React.Dispatch<Action>;
  onSubmit: (
    selectedSpaces: Array<Space>,
    modifiedSelectedSpaces: Array<Space>
  ) => void;
  onCancel: () => void;
  saving?: boolean;
}> = ({
  state,
  selectedSpaceIds,
  client,
  dispatch,
  onSubmit,
  onCancel,
  saving = false,
}) => {
  const [workingPrefix, setWorkingPrefix] = useState('');
  const [workingPostfix, setWorkingPostfix] = useState('');
  const [workingStartValue, setWorkingStartValue] = useState('1');
  const [capacity, setWorkingCapacity] = useState('');
  const [capacityError, setWorkingCapacityError] = useState(false);

  const [spaceType, setSpaceType] = useState<SpaceCountingMode | ''>('');
  const [spaceFunction, setSpaceFunction] = useState<
    Space['coreSpaceFunction'] | ''
  >('');
  const [spaceLabels, setSpaceLabels] = useState<Space['coreSpaceLabels']>([]);

  const [changeSummary, setChangeSummary] = useState<string>('');

  const selectedSpaces = useMemo(() => {
    return FloorplanCollection.list(state.spaces)
      .filter((space) => selectedSpaceIds.has(space.id))
      .sort((spaceA, spaceB) => {
        if (spaceA.name < spaceB.name) {
          return -1;
        }
        if (spaceA.name > spaceB.name) {
          return 1;
        }
        return 0;
      });

    // eslint-disable-next-line
  }, [selectedSpaceIds.size, state.spaces]);

  // Fetch a list of all space functions to use in the dropdown
  const [spaceFunctions, setSpaceFunctions] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | {
        status: 'complete';
        data: Array<{
          function: string;
          function_display_name: string;
          program: string;
          program_display_name: string;
        }>;
      }
    | { status: 'error' }
  >(
    // FIXME: this cached space functions thing is really gross and probably is going to break
    // someday... but the idea is to somehow cache this data so that when the second focused panel
    // opens, it won't need to refetch this data
    (window as any).CACHED_SPACE_FUNCTIONS
      ? { status: 'complete', data: (window as any).CACHED_SPACE_FUNCTIONS }
      : { status: 'pending' }
  );
  useEffect(() => {
    if (spaceFunctions.status !== 'pending') {
      return;
    }
    const abortController = new AbortController();

    setSpaceFunctions({ status: 'loading', abortController });
    CoreAPI.getSpaceFunctions(client, abortController.signal)
      .then((response) => {
        (window as any).CACHED_SPACE_FUNCTIONS = response.data;
        setSpaceFunctions({
          status: 'complete',
          data: response.data,
        });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setSpaceFunctions({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setSpaceFunctions({ status: 'pending' });
    };
    // FIXME: Ignore dependencies on this because adding one on spaceFunctions.status will cause this
    // hook to run way too often, and I can't figure out an easy way to work around this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  // Fetch a list of space labels to use for autocompletion
  const [spaceLabelOptions, setSpaceLabelOptions] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | {
        status: 'complete';
        data: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
      }
    | { status: 'error' }
  >(
    // FIXME: this cached space labels thing is really gross and probably is going to break
    // someday... but the idea is to somehow cache this data so that when the second focused panel
    // opens, it won't need to refetch this data
    (window as any).CACHED_SPACE_LABELS
      ? { status: 'complete', data: (window as any).CACHED_SPACE_LABELS }
      : { status: 'pending' }
  );

  const getAllLabels = async (abortController: AbortController) => {
    const labels: Pick<CoreSpaceLabel, 'id' | 'name'>[] = [];
    let page = 1;
    while (true) {
      let response;
      try {
        response = await CoreAPI.getSpaceLabels(
          client,
          page,
          abortController.signal
        );
      } catch (err) {
        console.error('Error loading labels:', err);
        toast.error('Error loading labels!');
        setSpaceLabelOptions({ status: 'error' });
        break;
      }

      const data = response.data.results.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      labels.push(...data);
      if (!response.data.next) {
        (window as any).CACHED_SPACE_LABELS = labels;

        setSpaceLabelOptions({ status: 'complete', data: labels });
        break;
      }
      page += 1;
    }
  };

  useEffect(() => {
    if (spaceLabelOptions.status !== 'pending') {
      return;
    }
    const abortController = new AbortController();

    setSpaceLabelOptions({ status: 'loading', abortController });
    getAllLabels(abortController);
    return () => {
      abortController.abort();
      setSpaceLabelOptions({ status: 'pending' });
    };
    // FIXME: Ignore dependencies on this because adding one on spaceLabelOptions.status will cause this
    // hook to run way too often, and I can't figure out an easy way to work around this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    // This effect updates a "summary" string (changeSummary state) for user's eyes.
    // The items that changed and stayed the same are separated by "|" so that
    // the information can be separated into two sentences on display.

    const changed = [];
    const unchanged = [];

    if (workingPrefix.length > 0) {
      changed.push('Name');
    } else {
      unchanged.push('Name');
    }

    if (workingPostfix.length > 0) {
      changed.push('Name');
    } else {
      unchanged.push('Name');
    }

    if (spaceLabels.length >= 1) {
      changed.push('Labels');
    } else {
      unchanged.push('Labels');
    }

    if (spaceType) {
      changed.push('Space Type');
    } else {
      unchanged.push('Space Type');
    }

    if (spaceFunction) {
      changed.push('Function');
    } else {
      unchanged.push('Function');
    }

    if (capacity) {
      changed.push('Capacity');
    } else {
      unchanged.push('Capacity');
    }

    let str = '';

    // The logic below merely deals with pretty English. Commas and ands in the right place...
    if (changed.length > 2) {
      str += `${changed.slice(0, -1).join(', ')}, and ${changed.slice(
        -1
      )} will be overriden by your selections`;
    } else if (changed.length === 2) {
      str += `${changed.join(' and ')} will be overriden by your selections`;
    } else {
      str = `${changed.join(', ')} will be overridden by your selection`;
    }

    if (unchanged.length > 0) {
      if (unchanged.length > 2) {
        str += `. |${unchanged.slice(0, -1).join(', ')}, and ${unchanged.slice(
          -1
        )} will remain unchanged for all`;
      } else if (unchanged.length === 2) {
        str += `. |${unchanged.join(' and ')} will remain unchanged for all`;
      } else {
        str += `. |${unchanged.join(', ')} will remain unchanged for all`;
      }
    }

    str = str + '.';

    if (changed.length === 0) {
      str = '';
    }

    setChangeSummary(str);
  }, [
    spaceLabels,
    spaceFunction,
    spaceType,
    capacity,
    workingPrefix,
    workingPostfix,
    workingStartValue,
  ]);

  function getFinalNameOutcome(
    workingPrefix: string,
    workingPostfix: string,
    workingStartValue: string
  ) {
    if (workingPrefix && workingPostfix) {
      return `Selections will be renamed ${workingPrefix}-${
        workingStartValue || '1'
      }${workingPostfix},  (...)`;
    } else if (workingPrefix) {
      return `Selections will be renamed ${workingPrefix}-${
        workingStartValue || '1'
      },  (...)`;
    } else if (workingPostfix) {
      return `Selections will be renamed CurrentSpaceName${workingPostfix},  (...)`;
    }
  }

  return (
    <div data-cy="bulk-edit-spaces-panel">
      <Panel position="top-left" width={'540px'} left={'calc(45% - 270px)'}>
        <PanelHeader>
          <label
            style={{
              fontSize: '1.30em',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              color: dust.Blue400,
              padding: '10px',
            }}
          >
            <Icons.PencilEditor size={30} />
            &nbsp;
            {`You're bulk editing ${selectedSpaces.length} selected Spaces.`}
          </label>
        </PanelHeader>

        <PanelBody>
          <HorizontalForm size="medium">
            <Fragment>
              <HorizontalForm size="medium">
                <div className={styles.focusedSpaceMetadataField}>
                  <label htmlFor="bulk-edit-spaces-prefix-label">
                    <HorizontalForm size="small">
                      Space Name Prefix
                      <Tooltip
                        contents={
                          'Space name will be overridden by the sequence you provide.'
                        }
                        placement="bottom"
                        target={
                          <Icons.HelpQuestionMark
                            size={15}
                            color={dust.Blue400}
                          />
                        }
                      />
                    </HorizontalForm>
                  </label>
                  <TextField
                    type="text"
                    size="medium"
                    value={workingPrefix}
                    placeholder="eg: Lounge"
                    disabled={state.locked}
                    onChange={(evt) =>
                      setWorkingPrefix(evt.currentTarget.value)
                    }
                    leadingIcon={
                      <Icons.ArrowRightForward size={18} color={dust.Gray700} />
                    }
                    width="100%"
                    data-cy="bulk-edit-spaces-prefix"
                  />
                </div>
                <div className={styles.focusedSpaceMetadataField}>
                  <label htmlFor="bulk-edit-spaces-prefix-index-label">
                    <HorizontalForm size="small">
                      Start Value
                      <Tooltip
                        contents={
                          'If you specified a Prefix, you can choose your Space names to start count at a digit of your choice, rather than 1'
                        }
                        placement="bottom"
                        target={
                          <Icons.HelpQuestionMark
                            size={15}
                            color={dust.Blue400}
                          />
                        }
                      />
                    </HorizontalForm>
                  </label>
                  <TextField
                    type="text"
                    size="medium"
                    value={workingStartValue}
                    placeholder="1"
                    disabled={state.locked}
                    onChange={(evt) =>
                      setWorkingStartValue(evt.currentTarget.value)
                    }
                    width="80px"
                    data-cy="bulk-edit-spaces-prefix-index"
                  />
                </div>
              </HorizontalForm>

              <div className={styles.focusedSpaceMetadataField}>
                <label htmlFor="bulk-edit-spaces-postfix-label">
                  <HorizontalForm size="small">
                    Space Name Postfix
                    <Tooltip
                      contents={
                        'All selected spaces will be appended this Postfix. For example, if you selected a space named "Lounge-1" and your Postfix is "-ABC", the resulting name would be "Lounge-1-ABC".'
                      }
                      placement="bottom"
                      target={
                        <Icons.HelpQuestionMark
                          size={15}
                          color={dust.Blue400}
                        />
                      }
                    />
                  </HorizontalForm>
                </label>
                <TextField
                  type="text"
                  size="medium"
                  value={workingPostfix}
                  placeholder="eg: -DEN"
                  disabled={state.locked}
                  onChange={(evt) => setWorkingPostfix(evt.currentTarget.value)}
                  trailingIcon={
                    <Icons.ArrowLeftBack size={18} color={dust.Gray700} />
                  }
                  width="100%"
                  data-cy="bulk-edit-spaces-postfix"
                />
              </div>

              <div className={styles.focusedSpaceMetadataField}>
                <label htmlFor="bulk-edit-spaces-capacity-label">
                  Space Labels
                </label>
                {spaceLabelOptions.status === 'complete' ? (
                  <MultiTagField
                    disabled={state.locked}
                    value={spaceLabels.map((l) => ({
                      id: l.id,
                      label: l.name,
                    }))}
                    options={spaceLabelOptions.data.map((l) => ({
                      id: l.id,
                      label: l.name,
                    }))}
                    data-cy="bulk-edit-spaces-labels"
                    // When a new option is selected, create it on the server
                    onCreateOption={async (newLabelName) => {
                      let newLabel: CoreSpaceLabel;
                      try {
                        const response = await CoreAPI.createSpaceLabel(
                          client,
                          newLabelName
                        );
                        newLabel = response.data;
                      } catch (err) {
                        toast.error('Error creating label!');
                        console.error('Error creating label:', err);
                        return;
                      }

                      // Add the new label to the global list of space labels used in the
                      // autocomplete
                      setSpaceLabelOptions((labelsData) => {
                        if (labelsData.status !== 'complete') {
                          return labelsData;
                        }

                        const data = [...labelsData.data, newLabel].sort(
                          (a, b) => a.name.localeCompare(b.name)
                        );

                        (window as any).CACHED_SPACE_LABELS = data;
                        return { status: 'complete', data };
                      });

                      setSpaceLabels((spaceLabels) => [
                        ...spaceLabels,
                        { id: newLabel.id, name: newLabel.name },
                      ]);
                    }}
                    popupMaxHeight={100}
                    onChange={(choices) => {
                      setSpaceLabels(
                        choices.map((l) => ({ id: l.id, name: l.label }))
                      );
                    }}
                  />
                ) : (
                  <TextField
                    error={spaceLabelOptions.status === 'error'}
                    placeholder={
                      spaceLabelOptions.status === 'error'
                        ? 'Error loading space labels'
                        : 'Loading space labels...'
                    }
                    disabled
                    size="medium"
                    width="100%"
                  />
                )}
              </div>
              <div className={styles.focusedSpaceMetadataField}>
                <label htmlFor="bulkd-edit-spaces-space-type-label">
                  Space Type
                </label>
                <SelectField
                  id="space-type"
                  placeholder="Select Space Type"
                  size="medium"
                  value={spaceType}
                  onChange={(choice) => setSpaceType(choice.id)}
                  disabled={state.locked || true}
                  choices={[
                    { id: '', label: '' },
                    { id: 'oa', label: 'Open Area Space' },
                    { id: 'entry', label: 'Entry Space' },
                  ]}
                  data-cy="bulk-edit-spaces-space-type"
                />
              </div>
              <HorizontalForm size="medium">
                <div className={styles.focusedSpaceMetadataField}>
                  <label htmlFor="bulk-edit-spaces-capacity-label">
                    Capacity
                  </label>
                  <TextField
                    id="space-capacity"
                    size="medium"
                    type="number"
                    min="0"
                    step="1"
                    disabled={state.locked}
                    error={capacityError}
                    value={capacity}
                    onChange={(e) => setWorkingCapacity(e.currentTarget.value)}
                    trailingSuffix={
                      <span className={styles.focusedSpaceMetadataFieldSuffix}>
                        {capacity === '1' ? 'person' : 'people'}
                      </span>
                    }
                    width={110}
                    onBlur={() => {
                      if (capacity === '') {
                        setWorkingCapacityError(false);
                        //onChangeSpaceCapacity(null);
                        return;
                      }

                      const parsedCapacity = window.parseInt(capacity);
                      if (isNaN(parsedCapacity)) {
                        setWorkingCapacityError(true);
                        return;
                      }
                      if (parsedCapacity < 0) {
                        setWorkingCapacityError(true);
                        return;
                      }

                      setWorkingCapacityError(false);
                      //onChangeSpaceCapacity(parsedCapacity);
                    }}
                    data-cy="bulk-edit-spaces-capacity"
                  />
                </div>
                <div className={styles.focusedSpaceMetadataField}>
                  <label htmlFor="bulk-edit-spaces-function-label">
                    Function
                  </label>
                  {spaceFunctions.status === 'complete' ? (
                    <SelectField
                      id="space-function"
                      placeholder="Select Function"
                      size="medium"
                      value={spaceFunction}
                      onChange={(choice) => setSpaceFunction(choice.id)}
                      disabled={state.locked}
                      choices={
                        spaceFunctions.status === 'complete'
                          ? [
                              { id: '', label: '' },
                              { id: 'no_function', label: 'No Function' },
                              ...spaceFunctions.data.map(
                                (rawSpaceFunction) => ({
                                  id: rawSpaceFunction.function,
                                  label: rawSpaceFunction.function_display_name,
                                })
                              ),
                            ]
                          : []
                      }
                      width={150}
                      data-cy="bulk-edit-spaces-function"
                    />
                  ) : (
                    <TextField
                      error={spaceFunctions.status === 'error'}
                      placeholder={
                        spaceFunctions.status === 'error'
                          ? 'Error loading space functions'
                          : 'Loading space functions...'
                      }
                      disabled
                      size="medium"
                      width={200}
                    />
                  )}
                </div>
              </HorizontalForm>
            </Fragment>
            <Fragment>
              <label htmlFor="bulk-edit-spaces-table-header">
                Selected Spaces:
              </label>
              <div
                className={classnames([
                  styles.focusedSpaceMetadataField,
                  styles.bulkSpaceConfigMeta,
                ])}
              >
                <SelectedSpacesTable selectedSpaces={selectedSpaces} />
              </div>
            </Fragment>
          </HorizontalForm>
          {changeSummary.split('|')[0] && (
            <label
              style={{
                fontSize: '0.8em',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Icons.WrenchTool size={20} /> &nbsp;
              {changeSummary.split('|')[0]}
            </label>
          )}
          {changeSummary.split('|')[1] && (
            <label
              style={{
                fontSize: '0.8em',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Icons.LockClosed size={20} /> &nbsp;
              {changeSummary.split('|')[1]}
            </label>
          )}

          {(workingPrefix || workingPostfix) && (
            <label
              style={{
                fontSize: '0.8em',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Icons.Information size={20} /> &nbsp;
              {getFinalNameOutcome(
                workingPrefix,
                workingPostfix,
                workingStartValue
              )}
            </label>
          )}
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                onClick={() => onCancel()}
                data-cy="bulk-edit-spaces-dismiss"
                leadingIcon={<Icons.CloseFill size={20} />}
              >
                Cancel
              </Button>
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              disabled={
                changeSummary.length === 0 ||
                selectedSpaces.length === 0 ||
                saving
              }
              onClick={() => {
                // When renaming a bunch of Spaces to, say, 'Lounge X' ... Lounge X + N,
                // we need to make sure that 'Lounge 1', say, doesn't already exist outside
                // of this selection. If it does, then '1' should be forbidden as an index
                // and we shall start our renames with '2', or whatever index is available.

                let commonNames: string[] = [];

                // Get all non-selected Spaces that have `workingPrefix` in their name...
                if (workingPrefix.length > 0) {
                  commonNames = FloorplanCollection.list(state.spaces)
                    .filter(
                      (space) =>
                        !selectedSpaceIds.has(space.id) &&
                        space.name.startsWith(workingPrefix)
                    )
                    .map((space) => space.name);
                }

                let forbiddenIndices: number[] = [];

                // Now extract the numbers from these Spaces. If there is a 'Lounge 4' there already,
                // we shall skip it and rename selected Spaces to Lounge 3 and Lounge 5 and so on...
                commonNames.forEach((str) => {
                  // Match numbers with regex...
                  let numbers = str.match(/\d+/g);
                  if (numbers) {
                    numbers.forEach((number) => {
                      forbiddenIndices.push(parseInt(number));
                    });
                  }
                });

                // Shallow copy of the array
                const modifiedSelectedSpaces = selectedSpaces.map((space) => ({
                  ...space,
                }));

                let nameIndex = parseInt(workingStartValue) || 1;
                modifiedSelectedSpaces.forEach((space) => {
                  if (workingPrefix.length > 0) {
                    while (forbiddenIndices.includes(nameIndex)) {
                      nameIndex += 1;
                    }

                    if (workingPostfix) {
                      space.name = `${workingPrefix}-${nameIndex}${workingPostfix}`;
                    } else {
                      space.name = `${workingPrefix}-${nameIndex}`;
                    }

                    nameIndex += 1;
                  } else if (workingPostfix.length > 0) {
                    space.name = `${space.name}${workingPostfix}`;
                  }

                  if (spaceType) {
                    space.countingMode = spaceType;
                  }

                  if (spaceFunction) {
                    if ((spaceFunction as string) === 'no_function') {
                      space.coreSpaceFunction =
                        '' as Space['coreSpaceFunction'];
                    } else {
                      space.coreSpaceFunction = spaceFunction;
                    }
                  }

                  if (spaceLabels.length > 0) {
                    space.coreSpaceLabels = spaceLabels;
                  }

                  if (capacity) {
                    space.coreSpaceCapacity = parseInt(capacity);
                  }
                });

                onSubmit(selectedSpaces, modifiedSelectedSpaces);
              }}
              data-cy="bulk-edit-spaces-save"
              leadingIcon={<Icons.CheckCircleFill size={20} />}
            >
              Override All
            </Button>
          }
        />
      </Panel>
    </div>
  );
};

export default BulkEditSpacesPanel;
