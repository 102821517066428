import EventEmitter from 'events';
import { AxiosInstance, AxiosResponse } from 'axios';

import {
  FloorplanV2Plan,
  FloorplanV2PhotoGroup,
  FloorplanV2Sensor,
  FloorplanV2Space,
  FloorplanV2Threshold,
  FloorplanV2AreaOfConcern,
  FloorplanV2ReferenceRuler,
  FloorplanV2ReferenceHeight,
} from 'lib/api';
import { PlanDXF, PlanDXFExport } from 'lib/dxf';

type SocketsResponseBody = { url: string; ttl: number };

export type FloorplanEventStreamStatus =
  | 'disconnected'
  | 'connecting'
  | 'connected'
  | 'error';

type FloorplanEventStreamOptions = {
  reconnectAutomatically: boolean;
};

// "PlanEventMessage" and associated types represent the data that enters FloorplanEventStream
// via the websocket connection with the backend.
export type PlanEventMessageBase = {
  type: 'plan_event';
  plan_id: string;
  user_email: string;
  user_id: string;
  published_at: string;
};
export type PlanEventMessagePlan = PlanEventMessageBase & {
  event: 'plan.created' | 'plan.updated' | 'plan.deleted';
  plan: FloorplanV2Plan;
};
export type PlanEventMessageSensor = PlanEventMessageBase & {
  event: 'plan.sensor.created' | 'plan.sensor.updated' | 'plan.sensor.deleted';
  plan_sensor_id: string;
  plan_sensor: FloorplanV2Sensor | null;
};
export type PlanEventMessageThreshold = PlanEventMessageBase & {
  event:
    | 'plan.doorway.created'
    | 'plan.doorway.updated'
    | 'plan.doorway.deleted';
  plan_doorway_id: string;
  plan_doorway: FloorplanV2Threshold | null;
};
// NOTE: areas are no longer processed by planner. This entry exists for completeness, but is
// ignored.
export type PlanEventMessageArea = PlanEventMessageBase & {
  event: 'plan.area.created' | 'plan.area.updated' | 'plan.area.deleted';
  plan_area_id: string;
  area: unknown | null;
};
export type PlanEventMessageAreaOfConcern = PlanEventMessageBase & {
  event:
    | 'plan.area_of_coverage.created'
    | 'plan.area_of_coverage.updated'
    | 'plan.area_of_coverage.deleted';
  plan_area_of_coverage_id: string;
  plan_area_of_coverage: FloorplanV2AreaOfConcern | null;
};
export type PlanEventMessageSpace = PlanEventMessageBase & {
  event: 'plan.space.created' | 'plan.space.updated' | 'plan.space.deleted';
  space_id: string;
  space: FloorplanV2Space | null;
};
export type PlanEventMessageRuler = PlanEventMessageBase & {
  event: 'plan.ruler.created' | 'plan.ruler.updated' | 'plan.ruler.deleted';
  plan_reference_ruler_id: string;
  plan_reference_ruler: FloorplanV2ReferenceRuler | null;
};
export type PlanEventMessageHeight = PlanEventMessageBase & {
  event:
    | 'plan.reference_height.created'
    | 'plan.reference_height.updated'
    | 'plan.reference_height.deleted';
  plan_reference_height_id: string;
  plan_reference_height: FloorplanV2ReferenceHeight | null;
};
export type PlanEventMessagePhoto = PlanEventMessageBase & {
  event: 'plan.photo.created' | 'plan.photo.updated' | 'plan.photo.deleted';
  plan_photo_id: string;
  plan_photo_group_id: string;
  photo: { id: string; name: string; url: string } | null;
};
export type PlanEventMessagePhotoGroup = PlanEventMessageBase & {
  event:
    | 'plan.photo_group.created'
    | 'plan.photo_group.updated'
    | 'plan.photo_group.deleted';
  plan_photo_group_id: string;
  plan_photo_group: FloorplanV2PhotoGroup | null;
};
export type PlanEventMessageDXF = PlanEventMessageBase & {
  event: 'plan.dxf.created' | 'plan.dxf.updated' | 'plan.dxf.deleted';
  plan_dxf: PlanDXF;
  plan_dxf_id: string;
};
export type PlanEventMessageExport = PlanEventMessageBase & {
  event: 'plan.export.created' | 'plan.export.updated' | 'plan.export.deleted';
  plan_export: PlanDXFExport;
  plan_export_id: string;
};
export type PlanEventMessage =
  | PlanEventMessagePlan
  | PlanEventMessageSensor
  | PlanEventMessageAreaOfConcern
  | PlanEventMessageArea
  | PlanEventMessageSpace
  | PlanEventMessageThreshold
  | PlanEventMessageRuler
  | PlanEventMessageHeight
  | PlanEventMessagePhoto
  | PlanEventMessagePhotoGroup
  | PlanEventMessageDXF
  | PlanEventMessageExport;

export default class FloorplanEventStream extends EventEmitter {
  status: FloorplanEventStreamStatus;
  client: AxiosInstance;
  planId: string;
  websocket: WebSocket | null;
  connectionCount: number;
  options: FloorplanEventStreamOptions;

  onMessage: (event: MessageEvent) => void;

  localEvents: Array<{
    eventName: PlanEventMessage['event'];
    matcher: (event: PlanEventMessage) => boolean;
    registeredAt: Date;
  }>;
  localEventTimeoutInMilliseconds = 30 * 1000; // 30 seconds

  constructor(
    client: AxiosInstance,
    planId: string,
    options: Partial<FloorplanEventStreamOptions> = {}
  ) {
    super();
    this.status = 'disconnected';

    this.client = client;
    this.planId = planId;
    this.websocket = null;
    this.connectionCount = 0;

    options.reconnectAutomatically = options.reconnectAutomatically || false;
    this.options = options as FloorplanEventStreamOptions;

    this.onMessage = async (event: MessageEvent) => {
      let rawMessage;
      try {
        rawMessage = JSON.parse(event.data);
      } catch (err) {
        console.warn('Error parsing websocket event data:', event.data);
        return;
      }

      if (rawMessage.type !== 'plan_event') {
        console.warn(
          `Received websocket event with type "${rawMessage.type}" - expected "plan_event". Skipping...`
        );
        return;
      }

      const message = rawMessage as PlanEventMessage;

      if (message.plan_id !== this.planId) {
        // This message is for another plan, so disregard
        return;
      }

      // Check to see if this event was registered because it was initially created in this browser
      // session.
      // If it was, ignore the event - it's already been processed elsewhere.
      //
      const now = new Date();
      for (let i = 0; i < this.localEvents.length; i += 1) {
        const event = this.localEvents[i];

        // If the event is too old, get rid of it
        const millisecondsSinceEventWasRegistered =
          now.getTime() - event.registeredAt.getTime();
        if (
          millisecondsSinceEventWasRegistered >
          this.localEventTimeoutInMilliseconds
        ) {
          this.localEvents.splice(i, 1);
          i -= 1;
          continue;
        }

        if (event.eventName !== message.event) {
          continue;
        }
        if (!event.matcher(message)) {
          continue;
        }

        // The message seems to have matched, so ignore it
        this.localEvents.splice(i, 1);
        return;
      }

      this.emit(message.event, message);
    };

    this.localEvents = [];
  }

  async connect() {
    const isNotDisconnected = this.status !== 'disconnected';
    if (isNotDisconnected) {
      return;
    }

    await this.connectOnce();

    if (this.status === 'connected') {
      this.connectionCount = 0;
      return;
    }

    setTimeout(() => {
      this.connectionCount += 1;
      this.connect(); //
    }, Math.pow(this.connectionCount, 2));
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.removeEventListener('message', this.onMessage);
      this.websocket.close();
    }
    this.status = 'disconnected';
    this.emit('statusChange', this.status);
  }

  // Call this function to inform the FloorplanEventStream about an event that was initiated by this
  // browser client. With this information, the FloorplanEventStream will filter these events so
  // that they are not emitted to higher level consumers.
  //
  // this.registerLocalEvent('plan.area.updated', event => event.plan_area_id === 'are_123');
  registerLocalEvent(
    eventName: PlanEventMessage['event'],
    matcher: (event: PlanEventMessage) => boolean
  ) {
    this.localEvents.push({ eventName, matcher, registeredAt: new Date() });
  }

  async connectOnce() {
    this.status = 'connecting';
    this.emit('statusChange', this.status);

    // Connection step one: request a "websocket url" from the core api
    let response: AxiosResponse<SocketsResponseBody>;
    try {
      response = await this.client.post('/v2/sockets?types=plan_event');
    } catch (err) {
      this.status = 'error';
      this.emit('statusChange', this.status);
      return;
    }

    // NOTE: typescript thinks it's impossible for this.status to be anything other than
    // "connecting", but it's possible that when exeution is deferred in the await that
    // ".disconnect()" might be called. This guards against that.
    if ((this.status as any) === 'disconnected') {
      return;
    }

    // Connection step two: connect to the wesocket url that was fetched
    this.websocket = new WebSocket(response.data.url);
    await new Promise((resolve) => {
      (this.websocket as WebSocket).addEventListener('open', resolve);
    });

    // NOTE: typescript thinks it's impossible for this.status to be anything other than
    // "connecting", but it's possible that when exeution is deferred in the await that
    // ".disconnect()" might be called. This guards against that.
    if ((this.status as any) === 'disconnected') {
      this.websocket.close();
      return;
    }

    this.status = 'connected';
    this.emit('statusChange', this.status);

    this.websocket.addEventListener('close', (e) => {
      // Before reconnecting, check to see if the connection was aleady disconnected.
      // If it was - the disconnect was purposeful and already handled. Skip the code for when an
      // unexpected disconnection occurs.
      const wasAlreadyDisconnected = this.status === 'disconnected';
      if (wasAlreadyDisconnected) {
        return;
      }

      this.status = 'disconnected';
      this.emit('statusChange', this.status);
      (this.websocket as WebSocket).removeEventListener(
        'message',
        this.onMessage
      );

      if (this.options.reconnectAutomatically) {
        this.connect();
      }
    });

    this.websocket.addEventListener('message', this.onMessage);
  }
}
