import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

type HorizontalFormProps = {
  size: 'large' | 'medium' | 'small';
};

const SIZE_TO_CLASSNAME = {
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

const HorizontalForm: React.FunctionComponent<HorizontalFormProps> = ({
  size = 'large',
  children,
}) => (
  <div className={styles.horizontalFormWrapper}>
    {React.Children.map(children, (child) =>
      child ? (
        <div
          className={classnames(
            styles.horizontalFormItem,
            SIZE_TO_CLASSNAME[size]
          )}
        >
          {child}
        </div>
      ) : null
    )}
  </div>
);

export default HorizontalForm;
