import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'components/modal';
import Button from 'components/button';
import { Checkbox, FormLabel } from '@densityco/ui';
import { Remote, proxy, releaseProxy, wrap } from 'comlink';
import { SVGLabelCountWorkerResult } from 'lib/svg-label-counting-worker';
import { Bytes } from 'lib/units';
import MultiTagField from 'components/multi-tag-field';

type FilterLayersModalProps = {
  visible: boolean;
  layers: string[] | null;
  onCancel: () => void;
  onContinueWithoutFiltering: () => void;
  onDismiss: () => void;
  onSubmit: (layers: Filters) => void;
  svgFullImageUrl: string | null;
  fileSize: number;
};

type LayerData = {
  count: number;
  checked?: boolean;
  data?: string | null;
};

type Layer = Map<string, LayerData>;

type FilteredLayers = {
  count: number;
  layersMap: Map<string, Layer>;
};

export type Filters = string[] | undefined;

// initialize the state for the filtered layers object
const initializeFilteredLayers = (layers: string[] | null): FilteredLayers => {
  const layersMap = new Map<string, Layer>();

  layers
    ?.sort((a, b) => (a < b ? -1 : 1))
    .forEach((layerName) => {
      // Assuming each layer initially has one sub-layer with the same name
      const layerData: LayerData = {
        count: 0,
        checked:
          layerName.toUpperCase().includes('WALL') || layerName[1] !== '-',
        data: layerName,
      };

      const subLayerMap = new Map<string, LayerData>();
      subLayerMap.set(layerName, layerData); // Initialize with one sub-layer

      layersMap.set(layerName, subLayerMap); // Set the layer in the main map
    });

  return {
    count: layersMap.size,
    layersMap,
  };
};
/**
 *
 * Presents the user with the option to filter out layers from the SVG file.
 *
 * If there are no layers provided in the DXF metadata, a web worker will be
 * instantiated to count the layers in the SVG file.
 */
const FilterLayersModal = ({
  visible,
  onCancel,
  onContinueWithoutFiltering,
  onDismiss,
  onSubmit,
  fileSize,
  svgFullImageUrl,
  layers,
}: FilterLayersModalProps) => {
  const [filteredLayers, setFilteredLayers] = useState<FilteredLayers>(
    initializeFilteredLayers(layers)
  );
  const [isCountingLabels, setIsCountingLabels] = useState<boolean>(true);
  const [loadingPercent, setLoadingPercent] = useState<number>(0);
  const [filters, setFilters] = useState<Filters>(
    layers
      ?.map((layer) => {
        if (layer.toUpperCase().includes('WALL') || layer[1] !== '-') {
          return layer;
        } else {
          return null;
        }
      })
      .filter((v): v is string => v !== null && v !== undefined)
  );
  const fileSizeInMegabytes: number = Math.round(Bytes.toMegabytes(fileSize));
  // setup workers to count the labels
  const svgLabelCountingWorker = useRef<Worker | null>(null);
  const svgLabelCountingWorkerWrapped = useRef<Remote<
    import('lib/svg-label-counting-worker').SVGLabelCountWorker
  > | null>(null);

  // terminate and release the workers
  const cleanupWorker = () => {
    if (svgLabelCountingWorkerWrapped.current) {
      svgLabelCountingWorkerWrapped.current[releaseProxy]();
      svgLabelCountingWorkerWrapped.current = null;
    }
    if (svgLabelCountingWorker.current) {
      svgLabelCountingWorker.current.terminate();
      svgLabelCountingWorker.current = null;
    }
  };

  useEffect(() => {
    if (layers) {
      setIsCountingLabels(false);
      return;
    }
    // if the layers are not passed in, count them with the instantiated worker
    if (!svgLabelCountingWorker.current) {
      svgLabelCountingWorker.current = new Worker(
        new URL('lib/svg-label-counting-worker', import.meta.url),
        { name: 'svg-label-analyzing-worker' }
      );
    }

    if (!svgLabelCountingWorkerWrapped.current) {
      svgLabelCountingWorkerWrapped.current = wrap(
        svgLabelCountingWorker.current
      );
    }

    return () => {
      cleanupWorker();
    };
  }, [layers]);

  const result = useRef<SVGLabelCountWorkerResult>();

  // initiate the counting of labels with the worker
  const countLabels = useCallback(async () => {
    const worker = svgLabelCountingWorkerWrapped.current;

    if (worker && svgFullImageUrl) {
      result.current = await new Promise((resolve) =>
        worker.svgWallLabelCountWorker(
          svgFullImageUrl,
          fileSize,
          proxy((percent: number) => {
            setLoadingPercent(percent);
          }),
          proxy((result: SVGLabelCountWorkerResult | undefined) =>
            resolve(result)
          )
        )
      );
    }

    if (result.current) {
      const results = result.current.result;
      const layersMap = new Map<string, Layer>();

      Object.keys(results).forEach((layerName) => {
        const subLayerMap = new Map<string, LayerData>();

        Object.keys(results[layerName].subcategories).forEach(
          (subKey: string) => {
            const subLayerData: LayerData = {
              count: results[layerName].subcategories[subKey].count,
              checked:
                subKey === 'Wall' ||
                subKey === 'Other' ||
                subKey === 'Door' ||
                layerName === 'Other'
                  ? true
                  : false,
              data: results[layerName].subcategories[subKey].data,
            };

            subLayerMap.set(subKey, subLayerData);
          }
        );

        layersMap.set(layerName, subLayerMap);
      });

      setFilteredLayers({
        count: layersMap.size,
        layersMap,
      });
    }

    // cleanup
    cleanupWorker();

    setIsCountingLabels(false);
  }, [svgFullImageUrl, fileSize]);

  useEffect(() => {
    countLabels();

    // post worker cleanup
    return () => {
      if (svgLabelCountingWorkerWrapped.current) {
        svgLabelCountingWorkerWrapped.current[releaseProxy]();
        svgLabelCountingWorkerWrapped.current = null;
      }
      if (svgLabelCountingWorker.current) {
        svgLabelCountingWorker.current.terminate();
        svgLabelCountingWorker.current = null;
      }
    };
  }, [countLabels]);

  const handleSubmit = () => {
    if (filters) {
      onSubmit(filters);
    } else {
      const selectedLayers: Filters = Array.from(filteredLayers.layersMap)
        .flatMap(([_, subLayers]) => {
          return Array.from(subLayers).flatMap(([_, subLayerData]) => {
            if (subLayerData.checked) {
              return subLayerData.data !== undefined ? subLayerData.data : null;
            } else {
              return null;
            }
          });
        })
        .filter((v): v is string => v !== undefined && v !== null);
      onSubmit(selectedLayers);
    }
  };
  return (
    <Fragment>
      {isCountingLabels ? (
        <Modal
          visible={visible}
          width={450}
          height={300}
          onEscape={onDismiss}
          onBlur={onDismiss}
        >
          <div style={{ padding: '24px' }}>
            <h1>Analyzing SVG Layers</h1>
            <p>
              The SVG file is <b>{fileSizeInMegabytes} Mb</b>, it is recommended
              you filter out layers for processing performance.
              <br />
              <br />
              Collecting layer information for filter selection.
            </p>
            <h3>{loadingPercent}%</h3>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '24px',
              }}
            >
              <Button type="hollow" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          width={1200}
          height={
            layers
              ? Math.max(layers.length * 10 - 150, 225)
              : Math.max(filteredLayers.layersMap.size * 100 - 100, 500)
          }
          onEscape={onDismiss}
          onBlur={onDismiss}
        >
          <div style={{ padding: '24px' }}>
            <h1>Choose Layers to Process</h1>
            <p>
              The SVG file is <b>{fileSizeInMegabytes} Mb</b> in size. Choose
              the layers that should be included in processing.
              <br />
              <br />
              <i>
                NOTE: Wall layers are always included. Many layer elements will
                be filtered out when fetched and parsed.
              </i>
              <br />
            </p>
            <div style={{ marginBottom: '4px' }}>
              {!layers &&
                Array.from(filteredLayers.layersMap).map(
                  ([layerName, subLayers]) => {
                    return (
                      <div style={{ marginBottom: '5px' }}>
                        <h3 style={{ marginBottom: '2px' }}>{layerName}</h3>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(6, 1fr)',
                          }}
                        >
                          <LayerCheckboxes
                            key={layerName}
                            subLayers={subLayers}
                            onLayerChange={(subLayerKey, isChecked) => {
                              const existingLayerData =
                                subLayers.get(subLayerKey);
                              if (!existingLayerData) {
                                return;
                              }

                              const updatedLayerData: LayerData = {
                                ...existingLayerData,
                                checked: isChecked,
                                count: existingLayerData.count ?? 0,
                              };

                              const updatedSubLayers = new Map(subLayers);
                              updatedSubLayers.set(
                                subLayerKey,
                                updatedLayerData
                              );

                              setFilteredLayers((prev) => {
                                const updatedLayersMap = new Map(
                                  prev.layersMap
                                );
                                updatedLayersMap.set(
                                  layerName,
                                  updatedSubLayers
                                );
                                return { ...prev, layersMap: updatedLayersMap };
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
            {layers && filters && (
              <FormLabel
                label="Layers to Process"
                input={
                  <MultiTagField
                    options={layers.map((l) => ({ id: l, label: l }))}
                    value={
                      filters?.map((l) => ({ id: l, label: l })) as {
                        id: string;
                        label: string;
                      }[]
                    }
                    placeholder="Enter a layer name to filter lines..."
                    popupMaxHeight={128}
                    onChange={(newValue) => {
                      setFilters(newValue.map((v) => v.id));
                    }}
                  />
                }
              />
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '24px',
              }}
            >
              <Button type="hollow" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="outlined" onClick={onContinueWithoutFiltering}>
                Continue Without Filtering
              </Button>
              <Button type="filled" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

interface LayerCheckboxesProps {
  subLayers: Layer;
  onLayerChange: (layerKey: string, checked: boolean) => void;
}

/**
 * Component to render the checkboxes to filter out layers -
 * checkboxes give a UI that can display the count of elements in the layer.
 * Only renders if the layers are not present in the DXF.
 */

const LayerCheckboxes = ({
  subLayers,
  onLayerChange,
}: LayerCheckboxesProps) => {
  return (
    <>
      {Array.from(subLayers).map(([subLayerKey, subLayerData]) => {
        return (
          <Checkbox
            key={subLayerKey}
            label={`${subLayerKey} (${subLayerData.count.toLocaleString()})`}
            checked={subLayerData.checked || false}
            disabled={subLayerKey.includes('Wall')}
            onChange={(e) => onLayerChange(subLayerKey, e.target.checked)}
          />
        );
      })}
    </>
  );
};

export default FilterLayersModal;
