import { useRef, useState, useMemo, useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  snapToAngle,
} from 'lib/geometry';
import { displayLength } from 'lib/units';
import { radiansToDegrees } from 'lib/math';
import { Threshold } from 'lib/threshold';

import {
  ObjectLayer,
  MetricLabel,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  toRawHex,
  drawPolygon,
} from 'components/floorplan';

import * as dust from '@density/dust/dist/tokens/dust.tokens';

const FOCUSED_LINE_WIDTH_PX = 3;
const LEADER_LINE_STUB_LENGTH_PX = 16;
const THRESHOLD_ENDPOINT_CIRCLE_RADIUS_PX = 4;
const THRESHOLD_ARROW_HEAD_SIZE = 16;

// The reference threshold layer renders a list of reference lines with draggable endpoints to the
// floorplan.
//
// Holding shift while dragging an endpoint will cause the reference threshold to snap to
// 45 degree angles.
//
// Finally, clicking and dragging the center label will move the whole reference threshold. Clicking and
// dragging while holding shift will cause it to undock and be rendered seperate, with a leader line
// connecting to the floorplan.
const ThresholdsLayer: React.FunctionComponent<{
  thresholds: Array<Threshold & { distanceLabelText?: string }>;
  locked?: boolean;
  focusedObject?: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer' | 'threshold';
    id: string;
  };
  highlightedObject?: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'reference'
      | 'threshold'
      | 'layer';
    id: string;
  } | null;
  bulkSelectedIds?: Set<string>;
  thresholdColor?: number;
  thresholdColorHighlighted?: number;
  labelVisibilityZoomThreshold?: number;
  onMouseEnter?: (threshold: Threshold, event: FederatedPointerEvent) => void;
  onMouseLeave?: (threshold: Threshold, event: FederatedPointerEvent) => void;
  onMouseDown?: (threshold: Threshold, event: FederatedPointerEvent) => void;
  onEndpointsMoved?: (
    threshold: Threshold,
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates,
    distanceLabelPosition: FloorplanCoordinates
  ) => void;
  onDuplicateThreshold?: (threshold: Threshold) => void;
  showTriggerRegions?: boolean;
  showIngressEgressRegions?: boolean;
}> = ({
  thresholds,
  locked = false,
  focusedObject = null,
  highlightedObject = null,
  bulkSelectedIds = new Set<string>(),
  thresholdColor = toRawHex(dust.Gray500),
  thresholdColorHighlighted = toRawHex(dust.Gray900),
  labelVisibilityZoomThreshold = 0.65,
  onMouseEnter = null,
  onMouseLeave = null,
  onMouseDown = null,
  onEndpointsMoved = null,
  onDuplicateThreshold = null,
  showTriggerRegions = true,
  showIngressEgressRegions = true,
}) => {
  const context = useFloorplanLayerContext();

  // FIXME: this probably is not the best approach to this, but because `onCreate` is not updated
  // within ObjectLayer due to dependency issues in the useEffect, it's possible that invocations of
  // `onDragMove` and friends within `onCreate` might be holding onto older references of these
  // functions from a previous render. So, cache the latest versions here in a ref so that the
  // latest version can always be called.
  //
  // The proper way to address this is by fixing the dependency issues within the `ObjectLayer`, but
  // this is a larger problem because the `onCreate` / `onUpdate` / `onRemove` function references
  // change every render and moving away from this is a large project across all layers.

  const latestOnMouseEnter = useRef(onMouseEnter);
  useEffect(() => {
    latestOnMouseEnter.current = onMouseEnter;
  }, [onMouseEnter]);

  const latestOnMouseLeave = useRef(onMouseLeave);
  useEffect(() => {
    latestOnMouseLeave.current = onMouseLeave;
  }, [onMouseLeave]);

  const latestOnMouseDown = useRef(onMouseDown);
  useEffect(() => {
    latestOnMouseDown.current = onMouseDown;
  }, [onMouseDown]);

  const latestOnEndpointsMoved = useRef(onEndpointsMoved);
  useEffect(() => {
    latestOnEndpointsMoved.current = onEndpointsMoved;
  }, [onEndpointsMoved]);

  const latestOnDuplicateThreshold = useRef(onDuplicateThreshold);
  useEffect(() => {
    latestOnDuplicateThreshold.current = onDuplicateThreshold;
  }, [onDuplicateThreshold]);

  const latestLocked = useRef(locked);
  useEffect(() => {
    latestLocked.current = locked;
  }, [locked]);

  // The `duplicatedThreshold` state stores a copy of a reference threshold that is being duplicated so that
  // this new space (which is not yet in the `thresholds` prop) can be rendered via the
  // ObjectLayer
  const [duplicatedThreshold, setDuplicatedThreshold] =
    useState<Threshold | null>(null);

  /*
  // Now not in use.
  // When a threshold is focused, add serial number and sensor labels.
  const focusedThreshold =
    duplicatedThreshold ||
    (focusedObject && focusedObject.type === 'threshold'
      ? thresholds.find((threshold) => threshold.id === focusedObject.id)
      : null);
  */

  // Cache the endpoint balls texture shown at each end of the threshold
  const [endpointTexture, endpointHighlightedTexture] = useMemo(() => {
    return [thresholdColor, thresholdColorHighlighted].map((color) => {
      const gr = new PIXI.Graphics();

      gr.beginFill(color); // Set the fill color
      gr.drawCircle(0, 0, THRESHOLD_ENDPOINT_CIRCLE_RADIUS_PX);
      gr.endFill(); // End the fill

      return context.app.renderer.generateTexture(gr);
    });
  }, [context.app, thresholdColor, thresholdColorHighlighted]);

  // Cache the arrow sprite texture shown at the end of the leader line
  const [arrowheadTexture, arrowheadHighlightedTexture] = useMemo(() => {
    return [thresholdColor, thresholdColorHighlighted].map((color) => {
      const gr = new PIXI.Graphics();
      gr.lineStyle({ width: 0 });
      gr.beginFill(color);

      gr.moveTo(THRESHOLD_ARROW_HEAD_SIZE / 2, 0);
      gr.lineTo(THRESHOLD_ARROW_HEAD_SIZE, THRESHOLD_ARROW_HEAD_SIZE);
      gr.lineTo(0, THRESHOLD_ARROW_HEAD_SIZE);
      gr.lineTo(THRESHOLD_ARROW_HEAD_SIZE / 2, 0);

      return context.app.renderer.generateTexture(gr);
    });
  }, [context.app, thresholdColor, thresholdColorHighlighted]);

  // Cache the length of each reference threshold as it would be rendered to the user
  // Computing this on each frame is quite expensive it turns out
  const thresholdDisplayLengths = useMemo(() => {
    const thresholdDisplayLengths: { [thresholdId: string]: string } = {};

    for (const threshold of thresholds) {
      const floorplanX = threshold.positionB.x - threshold.positionA.x;
      const floorplanY = threshold.positionB.y - threshold.positionA.y;
      const distanceMeters = Math.hypot(floorplanX, floorplanY);
      thresholdDisplayLengths[threshold.id] = displayLength(
        distanceMeters,
        context.lengthUnit
      );
    }

    return thresholdDisplayLengths;
  }, [thresholds, context.lengthUnit]);

  const focusedThresholdPositions = useRef<{
    id: Threshold['id'];
    positionA: FloorplanCoordinates;
    positionB: FloorplanCoordinates;
    distanceLabelPosition: FloorplanCoordinates;
  } | null>(null);

  const thresholdsPlusDuplicatedThreshold: Array<
    Threshold & { distanceLabelText?: string }
  > = useMemo(
    () =>
      duplicatedThreshold ? [...thresholds, duplicatedThreshold] : thresholds,
    [thresholds, duplicatedThreshold]
  );

  return (
    <ObjectLayer
      objects={thresholdsPlusDuplicatedThreshold}
      extractId={(threshold) => threshold.id}
      onCreate={(getThreshold) => {
        const container = new PIXI.Container();

        const line = new PIXI.Graphics();
        line.name = 'line';
        container.addChild(line);

        const triggerRegion = new PIXI.Graphics();
        triggerRegion.name = 'triggerRegion';
        container.addChild(triggerRegion);

        const ingressRegion = new PIXI.Graphics();
        ingressRegion.name = 'ingressRegion';
        container.addChild(ingressRegion);

        const egressRegion = new PIXI.Graphics();
        egressRegion.name = 'egressRegion';
        container.addChild(egressRegion);

        const endpointA = new PIXI.Sprite(endpointTexture);
        endpointA.name = 'endpoint-a';
        endpointA.anchor.set(0.5, 0.5);
        endpointA.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          focusedThresholdPositions.current = {
            id: getThreshold().id,
            positionA: getThreshold().positionA,
            positionB: getThreshold().positionB,
            distanceLabelPosition: getThreshold().distanceLabelPosition,
          };

          const isDistanceLockedToCenter = Threshold.isDistanceLockedToCenter(
            context.floorplan,
            context.viewport.current,
            getThreshold().distanceLabelPosition,
            Threshold.calculateCenterPoint(getThreshold())
          );

          addDragHandler(
            context,
            getThreshold().positionA,
            evt,
            (newPosition, rawMousePosition) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedThresholdPositions.current) {
                return;
              }
              endpointA.cursor = 'grabbing';

              // Snap reference lines when shift is held
              if (evt.data.originalEvent.shiftKey) {
                const rawMousePositionFloorplan =
                  ViewportCoordinates.toFloorplanCoordinates(
                    rawMousePosition,
                    context.viewport.current,
                    context.floorplan
                  );
                const snappedPosition = snapToAngle(
                  focusedThresholdPositions.current.positionB,
                  rawMousePositionFloorplan
                );
                focusedThresholdPositions.current.positionA = snappedPosition;
              } else {
                focusedThresholdPositions.current.positionA = newPosition;
              }

              // If distance label is locked to center then keep it there
              if (isDistanceLockedToCenter) {
                focusedThresholdPositions.current.distanceLabelPosition =
                  Threshold.calculateCenterPoint(
                    focusedThresholdPositions.current
                  );
              }
            },
            () => {
              if (!focusedThresholdPositions.current) {
                return;
              }

              endpointA.cursor = 'grab';

              latestOnEndpointsMoved.current &&
                latestOnEndpointsMoved.current(
                  getThreshold(),
                  focusedThresholdPositions.current.positionA,
                  focusedThresholdPositions.current.positionB,
                  focusedThresholdPositions.current.distanceLabelPosition
                );
              focusedThresholdPositions.current = null;
            }
          );
        });
        container.addChild(endpointA);

        const endpointB = new PIXI.Sprite(endpointTexture);
        endpointB.name = 'endpoint-b';
        endpointB.anchor.set(0.5, 0.5);
        endpointB.interactive = true;
        endpointB.cursor = 'grab';
        endpointB.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          focusedThresholdPositions.current = {
            id: getThreshold().id,
            positionA: getThreshold().positionA,
            positionB: getThreshold().positionB,
            distanceLabelPosition: getThreshold().distanceLabelPosition,
          };

          endpointB.cursor = 'grabbing';

          const isDistanceLockedToCenter = Threshold.isDistanceLockedToCenter(
            context.floorplan,
            context.viewport.current,
            getThreshold().distanceLabelPosition,
            Threshold.calculateCenterPoint(getThreshold())
          );

          addDragHandler(
            context,
            getThreshold().positionB,
            evt,
            (newPosition, rawMousePosition) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedThresholdPositions.current) {
                return;
              }

              endpointB.cursor = 'grabbing';

              // Snap reference lines when shift is held
              if (evt.data.originalEvent.shiftKey) {
                const rawMousePositionFloorplan =
                  ViewportCoordinates.toFloorplanCoordinates(
                    rawMousePosition,
                    context.viewport.current,
                    context.floorplan
                  );
                const snappedPosition = snapToAngle(
                  focusedThresholdPositions.current.positionA,
                  rawMousePositionFloorplan
                );
                focusedThresholdPositions.current.positionB = snappedPosition;
              } else {
                focusedThresholdPositions.current.positionB = newPosition;
              }

              // If distance label is locked to center then keep it there
              if (isDistanceLockedToCenter) {
                focusedThresholdPositions.current.distanceLabelPosition =
                  Threshold.calculateCenterPoint(
                    focusedThresholdPositions.current
                  );
              }
            },
            () => {
              if (!focusedThresholdPositions.current) {
                return;
              }
              endpointB.cursor = 'grab';
              if (latestOnEndpointsMoved.current) {
                latestOnEndpointsMoved.current(
                  getThreshold(),
                  focusedThresholdPositions.current.positionA,
                  focusedThresholdPositions.current.positionB,
                  focusedThresholdPositions.current.distanceLabelPosition
                );
              }
              focusedThresholdPositions.current = null;
            }
          );
        });
        container.addChild(endpointB);

        const lengthLabel = new MetricLabel(
          getThreshold().distanceLabelText ||
            thresholdDisplayLengths[getThreshold().id],
          { backgroundColor: thresholdColor, radiusPixels: 5 }
        );
        lengthLabel.name = 'length-label';
        lengthLabel.interactive = true;
        lengthLabel.cursor = 'grab';

        lengthLabel.on('mouseover', (evt) => {
          if (latestOnMouseEnter.current) {
            latestOnMouseEnter.current(getThreshold(), evt);
          }
        });
        lengthLabel.on('mouseout', (evt) => {
          if (latestOnMouseLeave.current) {
            latestOnMouseLeave.current(getThreshold(), evt);
          }
        });

        lengthLabel.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          if (latestOnMouseDown.current) {
            latestOnMouseDown.current(getThreshold(), evt);
          }

          if (latestLocked.current || getThreshold().locked) {
            return;
          }

          focusedThresholdPositions.current = {
            id: getThreshold().id,
            positionA: getThreshold().positionA,
            positionB: getThreshold().positionB,
            distanceLabelPosition: getThreshold().distanceLabelPosition,
          };

          // If a user alt/option clicked, then duplicate the threshold instead
          //
          // the `duplicatedThreshold` state stores the threshold that is being duplicated so that this
          // threshold (which is not yet in the `thresholds` prop) can be rendered via the ObjectLayer
          let isDuplicating =
            latestOnDuplicateThreshold.current !== null &&
            evt.data.originalEvent.altKey;
          let duplicatedThreshold: Threshold | null = null;
          if (isDuplicating) {
            duplicatedThreshold = Threshold.duplicate(getThreshold());
            focusedThresholdPositions.current.id = duplicatedThreshold.id;
            setDuplicatedThreshold(duplicatedThreshold);
          }

          addDragHandler(
            context,
            getThreshold().distanceLabelPosition,
            evt,
            (newDistanceLabelPosition, raw, evt) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedThresholdPositions.current) {
                return;
              }
              lengthLabel.cursor = 'grabbing';

              // If the shift key is pressed, then "undock" the label
              if (evt.shiftKey) {
                focusedThresholdPositions.current.distanceLabelPosition =
                  newDistanceLabelPosition;
                focusedThresholdPositions.current.positionA =
                  getThreshold().positionA;
                focusedThresholdPositions.current.positionB =
                  getThreshold().positionB;

                // Figure out if the distance label should re-dock to the center
                const centerPoint = Threshold.calculateCenterPoint(
                  getThreshold()
                );
                const isNowLockedInCenter = Threshold.isDistanceLockedToCenter(
                  context.floorplan,
                  context.viewport.current,
                  newDistanceLabelPosition,
                  centerPoint
                );
                if (isNowLockedInCenter) {
                  focusedThresholdPositions.current.distanceLabelPosition =
                    centerPoint;
                }
              } else {
                // Otherwise, move / translate the whole reference line
                const deltaX =
                  newDistanceLabelPosition.x -
                  getThreshold().distanceLabelPosition.x;
                const deltaY =
                  newDistanceLabelPosition.y -
                  getThreshold().distanceLabelPosition.y;

                // Move reference endpoints in sync when the label is dragged
                const newPositionA = FloorplanCoordinates.create(
                  getThreshold().positionA.x + deltaX,
                  getThreshold().positionA.y + deltaY
                );
                const newPositionB = FloorplanCoordinates.create(
                  getThreshold().positionB.x + deltaX,
                  getThreshold().positionB.y + deltaY
                );

                focusedThresholdPositions.current.distanceLabelPosition =
                  newDistanceLabelPosition;
                focusedThresholdPositions.current.positionA = newPositionA;
                focusedThresholdPositions.current.positionB = newPositionB;
              }
            },
            () => {
              if (!focusedThresholdPositions.current) {
                return;
              }

              // If the space was duplicated, then call a different method once the drag has
              // completed
              if (duplicatedThreshold && latestOnDuplicateThreshold.current) {
                latestOnDuplicateThreshold.current({
                  ...duplicatedThreshold,
                  positionA: focusedThresholdPositions.current.positionA,
                  positionB: focusedThresholdPositions.current.positionB,
                  distanceLabelPosition:
                    focusedThresholdPositions.current.distanceLabelPosition,
                });
                setDuplicatedThreshold(null);
                return;
              }

              if (latestOnEndpointsMoved.current) {
                latestOnEndpointsMoved.current(
                  getThreshold(),
                  focusedThresholdPositions.current.positionA,
                  focusedThresholdPositions.current.positionB,
                  focusedThresholdPositions.current.distanceLabelPosition
                );
              }
              focusedThresholdPositions.current = null;
              lengthLabel.cursor = 'grab';
            }
          );
        });
        container.addChild(lengthLabel);

        // The arrowhead is used when rendering the leader line. It's hidden by default.
        const arrowhead = new PIXI.Sprite(arrowheadTexture);
        arrowhead.name = 'arrowhead';
        arrowhead.renderable = false;
        // Anchor the sprite at the arrowhead tip
        arrowhead.anchor.set(0.5, 0);
        container.addChild(arrowhead);

        return container;
      }}
      onUpdate={(threshold, container) => {
        if (!context.viewport.current) {
          return;
        }

        // Hide reference thresholds that are disabled
        container.renderable = true;
        if (!container.renderable) {
          // When this object moves out of the Viewport, clean it up.
          // Without clearing it, the object was selectable by clicking
          // on the very border of the Viewport.
          const line = container.getChildByName('line') as PIXI.Graphics;

          if (line) {
            line.clear();
          }
          return;
        }

        const isMovable =
          !latestLocked.current && latestOnEndpointsMoved.current !== null;

        const isFocused =
          focusedObject &&
          focusedObject.type === 'threshold' &&
          focusedObject.id === threshold.id;

        const isHighlighted =
          bulkSelectedIds.has(threshold.id) ||
          (highlightedObject &&
            ((highlightedObject.type === 'threshold' &&
              highlightedObject.id === threshold.id) ||
              (highlightedObject.type === 'sensor' &&
                threshold.relatedPlanSensors.includes(
                  highlightedObject.id
                )))) ||
          (focusedObject &&
            focusedObject.type === 'sensor' &&
            threshold.relatedPlanSensors.includes(focusedObject.id));

        const isBeingDragged =
          focusedThresholdPositions.current &&
          focusedThresholdPositions.current.id === threshold.id;
        const positionA =
          isBeingDragged && focusedThresholdPositions.current
            ? focusedThresholdPositions.current.positionA
            : threshold.positionA;
        const positionB =
          isBeingDragged && focusedThresholdPositions.current
            ? focusedThresholdPositions.current.positionB
            : threshold.positionB;
        const distanceLabelPosition =
          isBeingDragged && focusedThresholdPositions.current
            ? focusedThresholdPositions.current.distanceLabelPosition
            : threshold.distanceLabelPosition;

        const positionACoords = FloorplanCoordinates.toViewportCoordinates(
          positionA,
          context.floorplan,
          context.viewport.current
        );

        const positionBCoords = FloorplanCoordinates.toViewportCoordinates(
          positionB,
          context.floorplan,
          context.viewport.current
        );

        const distanceLabelPositionCoords =
          FloorplanCoordinates.toViewportCoordinates(
            distanceLabelPosition,
            context.floorplan,
            context.viewport.current
          );

        // Don't render reference lines that are outside of the viewport
        const lengthLabel = container.getChildByName(
          'length-label'
        ) as MetricLabel;
        container.renderable =
          isWithinViewport(
            context,
            positionACoords,
            -1 * lengthLabel.contents.width
          ) ||
          isWithinViewport(
            context,
            positionBCoords,
            -1 * lengthLabel.contents.width
          ) ||
          isWithinViewport(
            context,
            distanceLabelPositionCoords,
            -1 * lengthLabel.contents.width
          );
        if (!container.renderable) {
          return;
        }

        if (
          showTriggerRegions &&
          threshold.triggerRegion &&
          threshold.triggerRegion.length >= 3
        ) {
          const drawRegion = (
            regionName: string,
            colors: { color: string; outline: string; focused: string },
            regionArr: Array<FloorplanCoordinates> | undefined
          ) => {
            if (regionArr === undefined) return;
            const region = container.getChildByName(
              regionName
            ) as PIXI.Graphics | null;
            if (region) {
              const activeColor =
                isHighlighted || isFocused ? colors.color : colors.outline;

              region.clear();
              region.beginFill(toRawHex(activeColor), 0.4);

              const outlineColor =
                isHighlighted || isFocused
                  ? toRawHex(colors.focused)
                  : toRawHex(colors.outline);

              region.lineStyle({
                width: 1,
                join: PIXI.LINE_JOIN.ROUND,
                color: outlineColor,
              });

              region.x = positionACoords.x;
              region.y = positionACoords.y;

              const verticesViewport = regionArr.map((v) => {
                if (!context.viewport.current) {
                  throw new Error('Viewport not initialized');
                }

                return ViewportCoordinates.create(
                  (v.x - positionA.x) *
                    context.floorplan.scale *
                    context.viewport.current.zoom,
                  (v.y - positionA.y) *
                    context.floorplan.scale *
                    context.viewport.current.zoom
                );
              });
              drawPolygon(region, verticesViewport);

              region.endFill();
            }
          };
          const triggerRegion = container.getChildByName(
            'triggerRegion'
          ) as PIXI.Graphics | null;
          if (triggerRegion) {
            drawRegion(
              'triggerRegion',
              {
                color: dust.Gray300,
                outline: dust.Gray400,
                focused: dust.Gray700,
              },
              threshold.triggerRegion
            );
          }
          const ingressRegion = container.getChildByName(
            'ingressRegion'
          ) as PIXI.Graphics | null;
          if (ingressRegion) {
            if (showIngressEgressRegions && (isHighlighted || isFocused)) {
              drawRegion(
                'ingressRegion',
                {
                  color: dust.Blue400,
                  outline: dust.Orange000,
                  focused: dust.Blue300,
                },
                threshold.ingressRegion
              );
            } else {
              ingressRegion.clear();
            }
          }

          const egressRegion = container.getChildByName(
            'egressRegion'
          ) as PIXI.Graphics | null;
          if (egressRegion) {
            if (showIngressEgressRegions && (isHighlighted || isFocused)) {
              drawRegion(
                'egressRegion',
                {
                  color: dust.Yellow400,
                  outline: dust.Yellow000,
                  focused: dust.Orange300,
                },
                threshold.egressRegion
              );
            } else {
              egressRegion.clear();
            }
          }
        } else if (!showTriggerRegions) {
          const triggerRegion = container.getChildByName(
            'triggerRegion'
          ) as PIXI.Graphics | null;
          const ingressRegion = container.getChildByName(
            'ingressRegion'
          ) as PIXI.Graphics | null;
          const egressRegion = container.getChildByName(
            'egressRegion'
          ) as PIXI.Graphics | null;
          if (triggerRegion) {
            triggerRegion.clear();
            ingressRegion?.clear();
            egressRegion?.clear();
          }
        }

        const line = container.getChildByName('line') as PIXI.Graphics;
        line.clear();
        line.lineStyle({
          width: isHighlighted || isFocused ? FOCUSED_LINE_WIDTH_PX : 2,
          color:
            isHighlighted || isFocused
              ? thresholdColorHighlighted
              : thresholdColor,
        });
        line.moveTo(positionACoords.x, positionACoords.y);
        line.lineTo(positionBCoords.x, positionBCoords.y);

        const endpointA = container.getChildByName('endpoint-a') as PIXI.Sprite;
        endpointA.x = positionACoords.x;
        endpointA.y = positionACoords.y;
        endpointA.interactive = isMovable;
        endpointA.cursor = isMovable ? 'grab' : 'default';
        endpointA.texture =
          isHighlighted || isFocused
            ? endpointHighlightedTexture
            : endpointTexture;

        const endpointB = container.getChildByName('endpoint-b') as PIXI.Sprite;
        endpointB.x = positionBCoords.x;
        endpointB.y = positionBCoords.y;
        endpointB.interactive = isMovable;
        endpointB.cursor = isMovable ? 'grab' : 'default';
        endpointB.texture =
          isHighlighted || isFocused
            ? endpointHighlightedTexture
            : endpointTexture;

        const arrowhead = container.getChildByName('arrowhead') as PIXI.Sprite;
        arrowhead.texture =
          isHighlighted || isFocused
            ? arrowheadHighlightedTexture
            : arrowheadTexture;

        // Show the distance label only if not super zoomed out
        // This speeds up floorplan rendering noticably
        if (context.viewport.current.zoom > labelVisibilityZoomThreshold) {
          lengthLabel.renderable = true;
          lengthLabel.cursor = isMovable ? 'grab' : 'pointer';
          lengthLabel.x = distanceLabelPositionCoords.x;
          lengthLabel.y = distanceLabelPositionCoords.y;

          const newLabelBackgroundColor =
            isHighlighted || isFocused
              ? thresholdColorHighlighted
              : thresholdColor;
          if (newLabelBackgroundColor !== lengthLabel.options.backgroundColor) {
            lengthLabel.options.backgroundColor = newLabelBackgroundColor;
            lengthLabel.redrawTextBounds();
          }

          // If a custom text string is provided, then use that
          if (threshold.distanceLabelText) {
            lengthLabel.setText(threshold.distanceLabelText);
            // Otherwise if it's being dragged, compute the value based off of local cached state
          } else if (isBeingDragged || threshold['name'] === 'beingdrawn') {
            // This is a bit of a hack...
            const floorplanX = positionB.x - positionA.x;
            const floorplanY = positionB.y - positionA.y;
            const distanceMeters = Math.hypot(floorplanX, floorplanY);
            lengthLabel.setText(
              displayLength(distanceMeters, context.lengthUnit)
            );
            // Default to the cached measurement values
          } else {
            const name = threshold['name']
              ? threshold['name']
              : threshold['id'].slice(0, 7) + '...';
            const numSensors = threshold.relatedPlanSensors.length;
            const numSpaces = threshold.relatedSpaces.length;
            lengthLabel.setText(
              name +
                '\nLinked Sensors: ' +
                numSensors +
                '\nLinked Spaces: ' +
                numSpaces
            );
          }

          // Render distance label
          const centerPoint = Threshold.calculateCenterPoint({
            positionA,
            positionB,
          });
          const isDistanceLockedToCenter = Threshold.isDistanceLockedToCenter(
            context.floorplan,
            context.viewport.current,
            distanceLabelPosition,
            centerPoint
          );

          // Only render leader line / arrowhead if the distance label is seperated from the
          // reference line
          if (isDistanceLockedToCenter) {
            arrowhead.renderable = false;
            return;
          }

          const centerPointCoords = FloorplanCoordinates.toViewportCoordinates(
            centerPoint,
            context.floorplan,
            context.viewport.current
          );

          // Render the leader line connecting the metric label and the reference line
          line.lineStyle({
            width: 1,
            color:
              isHighlighted || isFocused
                ? thresholdColorHighlighted
                : thresholdColor,
          });
          line.moveTo(
            distanceLabelPositionCoords.x,
            distanceLabelPositionCoords.y
          );
          // Leader lines have a little "stub" that comes out of the metric label that is
          // horizontal before connecting to the center of the main reference line
          let stubEndpointPositionCoords: ViewportCoordinates;
          if (distanceLabelPositionCoords.x > centerPointCoords.x) {
            // Stub goes off to the left
            stubEndpointPositionCoords = ViewportCoordinates.create(
              distanceLabelPositionCoords.x -
                lengthLabel.contents.width / 2 -
                LEADER_LINE_STUB_LENGTH_PX,
              distanceLabelPositionCoords.y
            );
          } else {
            // Stub goes off to the right
            stubEndpointPositionCoords = ViewportCoordinates.create(
              distanceLabelPositionCoords.x +
                lengthLabel.contents.width / 2 +
                LEADER_LINE_STUB_LENGTH_PX,
              distanceLabelPositionCoords.y
            );
          }
          line.lineTo(
            stubEndpointPositionCoords.x,
            stubEndpointPositionCoords.y
          );
          line.lineTo(centerPointCoords.x, centerPointCoords.y);

          // Draw arrow on the end of the leader line
          arrowhead.renderable = true;
          arrowhead.x = centerPointCoords.x;
          arrowhead.y = centerPointCoords.y;

          const lineAngleRadians = Math.atan2(
            centerPointCoords.y - stubEndpointPositionCoords.y,
            centerPointCoords.x - stubEndpointPositionCoords.x
          );
          arrowhead.angle = radiansToDegrees(lineAngleRadians) + 90;
        } else {
          lengthLabel.renderable = false;
          arrowhead.renderable = false;
        }
      }}
      onRemove={(threshold, container) => {
        const line = container.getChildByName('line') as PIXI.Graphics;
        line.destroy();

        const triggerRegion = container.getChildByName(
          'triggerRegion'
        ) as PIXI.Graphics;
        triggerRegion.destroy();

        // NOTE: The endpoint texture is shared between all reference lines, so when cleaning them
        // up, don't destroy the underlying texture
        const endpointA = container.getChildByName('endpoint-a') as PIXI.Sprite;
        endpointA.destroy({ texture: false, baseTexture: false });
        const endpointB = container.getChildByName('endpoint-b') as PIXI.Sprite;
        endpointB.destroy({ texture: false, baseTexture: false });

        const lengthLabel = container.getChildByName(
          'length-label'
        ) as MetricLabel;
        lengthLabel.destroy();

        // NOTE: The arrowhead texture is shared between all reference lines, so when cleaning them
        // up, don't destroy the underlying texture
        const arrowhead = container.getChildByName('arrowhead') as PIXI.Sprite;
        arrowhead.destroy({ texture: false, baseTexture: false });
      }}
    />
  );
};

export default ThresholdsLayer;
