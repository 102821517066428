import { CoreOrganization } from '@densityco/lib-api-types';
import Modal from 'components/modal';
import { useContext, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styles from './styles.module.scss';
import { Icons } from '@density/dust';
import { FloorplanV2Sensor, FloorplanV2Space } from 'lib/api';
import { SpaceBuildingManager } from './spaces-info';
import Button from 'components/button';
import { useAppSelector } from 'redux/store';
import { BuildingContext } from './building-context';

export type DataTableData =
  | (FloorplanV2Sensor & { floorId: string; floorName: string })
  | (SpaceBuildingManager & { floorId: string; floorName: string });

export type DataTableHeader = {
  id:
    | 'floorName'
    | 'id'
    | 'floorId'
    | 'status'
    | 'sensor_function'
    | 'cad_id'
    | 'id'
    | 'sensor_serial_number'
    | 'mac'
    | 'ip';
  label: string;
};

type DataTableHeaderActions = {
  header: string;
  sort: 'asc' | 'desc' | '';
  filter: string;
  filterEnabled: boolean;
};

type DataTableSensor = FloorplanV2Sensor &
  DataTableData & { mac: string; ip: string };
type DataTableSpace = FloorplanV2Space & DataTableData & { size: number };

type DataTableProps = {
  data: DataTableData[];
  headers: DataTableHeader[];
  type: 'sensor' | 'space';
  closeModal: () => void;
};

function convertArrayOfObjectsToCSV(array: DataTableData[]) {
  if (!array.length) return '';

  const escapeChars = (str: string) => {
    if (typeof str !== 'string') return str;
    if (str.includes('"')) str = str.replace(/"/g, '""');
    if (str.includes(',') || str.includes('\n')) {
      str = `"${str}"`;
    }
    return str;
  };
  const header = Object.keys(array[0]).join(',') + '\n';
  const rows = array
    .map((obj) =>
      Object.values(obj)
        .map((val) => escapeChars(val))
        .join(',')
    )
    .join('\n');
  return header + rows;
}

export const DataTable: React.FunctionComponent<DataTableProps> = ({
  data,
  headers,
  type,
  closeModal,
}) => {
  const pruneData = (data: DataTableSensor & DataTableSpace) => {
    if (type === 'sensor') {
      return {
        status: data.status,
        sensor_function: data.sensor_function,
        cad_id: data.cad_id,
        id: data.id,
        sensor_serial_number: data.sensor_serial_number,
        mac: data.mac,
        ip: data.ip,
        floorName: data.floorName,
        floorId: data.floorId,
      };
    } else if (type === 'space') {
      return {
        name: data.name,
        id: data.id,
        iwms_id: data.iwms_id,
        capacity: data.capacity,
        counting_mode: data.counting_mode,
        size: data.size,
        function: data.function,
        floorName: data.floorName,
        floorId: data.floorId,
      };
    }
  };
  data = data.map((row) =>
    pruneData(row as DataTableSensor & DataTableSpace)
  ) as unknown as DataTableData[];

  const emptyActions: DataTableHeaderActions = {
    header: '',
    sort: 'asc',
    filter: '',
    filterEnabled: false,
  };
  const [headerActions, setHeaderActions] =
    useState<DataTableHeaderActions>(emptyActions);

  const [filteredData, setFilteredData] = useState<DataTableData[]>(data);

  const exportType = useRef('csv');

  const orgName = useAppSelector((state) => state.user.data?.organization.name);
  const { buildingName, dateString } = useContext(BuildingContext);

  const { organizationId } =
    useParams<{ organizationId: CoreOrganization['id'] }>();
  const history = useHistory();

  const filterData = (value: string, header: keyof DataTableData) => {
    let filteredData;
    if (value[0] === '"') {
      if (value.length === 1) return;
      const filterValue = value.slice(1, value.length - 1);
      filteredData = data.filter((row) => {
        return row[header].toLowerCase() === filterValue.toLowerCase();
      });
    } else {
      filteredData = data.filter((row) => {
        return row[header].toLowerCase().includes(value.toLowerCase());
      });
    }
    setFilteredData([...filteredData]);
  };

  const sortData = (header: keyof DataTableData) => {
    const sortedData = filteredData.sort((a, b) => {
      if (headerActions.sort === 'asc') {
        return a[header] > b[header] ? -1 : 1;
      } else {
        return a[header] < b[header] ? -1 : 1;
      }
    });
    setHeaderActions({
      ...headerActions,
      header: header,
      sort: headerActions.sort === 'asc' ? 'desc' : 'asc',
    });
    setFilteredData([...sortedData]);
  };

  const exportData = () => {
    let data: Blob = new Blob();
    const exportData = filteredData.map((row) => {
      const { floorId, ...rest } = row;
      return rest as DataTableData;
    });
    if (exportType.current === 'json') {
      const jsonData = JSON.stringify(exportData, null, 2);
      data = new Blob([jsonData], {
        type: `application/${exportType.current}`,
      });
    } else if (exportType.current === 'csv') {
      const csvString = convertArrayOfObjectsToCSV(exportData);
      data = new Blob([csvString], { type: `text/${exportType.current}` });
    }
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = `${orgName
      ?.replaceAll(' ', '_')
      .toLowerCase()}_${buildingName
      .replaceAll(' ', '_')
      .toLowerCase()}_${type}s_${dateString}.${exportType.current}`;

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Modal visible={true} onBlur={closeModal}>
      <ExportControl exportData={exportData} exportType={exportType} />
      <div className={styles.dataTableContainer}>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              {headers.map((header, i) => {
                return (
                  <th className={styles.dataTableHeaders} key={i}>
                    <div className={styles.dataTableHeaderContent}>
                      {headerActions.header === header.id &&
                      headerActions.filterEnabled ? (
                        <input
                          type="text"
                          autoFocus
                          value={headerActions.filter}
                          onChange={(e) => {
                            filterData(
                              e.target.value,
                              header.id as keyof DataTableData
                            );
                            setHeaderActions({
                              ...headerActions,
                              filter: e.target.value,
                            });
                          }}
                          onBlur={() =>
                            setHeaderActions({
                              ...emptyActions,
                              filter: headerActions.filter,
                              header: header.id,
                              sort: headerActions.sort,
                            })
                          }
                        />
                      ) : (
                        <>
                          <button
                            className={styles.dataTableHeaderButton}
                            onClick={() =>
                              setHeaderActions({
                                header: header.id,
                                filter: '',
                                sort: '',
                                filterEnabled: true,
                              })
                            }
                          >
                            <Icons.Filter />
                          </button>
                          <strong>{header.label}</strong>
                          <button
                            className={styles.dataTableHeaderButton}
                            onClick={() =>
                              sortData(header.id as keyof DataTableData)
                            }
                          >
                            {headerActions.header === header.id ? (
                              headerActions.sort === 'asc' ? (
                                <Icons.SortArrowUpSmall />
                              ) : (
                                <Icons.SortArrowDownSmall />
                              )
                            ) : (
                              <Icons.DotBullet />
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, i) => {
              return (
                <tr className={styles.dataTableRow} key={i}>
                  {headers.map((header, j) => {
                    return header.id === 'floorName' ? (
                      <td
                        title={row[header.id as keyof DataTableData]}
                        key={j}
                        onClick={(e) => {
                          const newTab = e.ctrlKey || e.metaKey;
                          const path = `/${organizationId}/floorplans/${row.floorId}`;
                          if (newTab) {
                            window.open(`${window.location.origin}${path}`);
                          } else {
                            history.push(path, {
                              id: row.id,
                              type: row.hasOwnProperty('sensor_function')
                                ? 'sensor'
                                : 'space',
                            });
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {row[header.id as keyof DataTableData] || ''}
                      </td>
                    ) : (
                      <td title={row[header.id as keyof DataTableData]} key={j}>
                        {row[header.id as keyof DataTableData] || ''}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={styles.dataTableFooter}>
          Showing {filteredData.length} / {data.length} results
        </div>
      </div>
    </Modal>
  );
};

interface ExportControlProps {
  exportData: () => void;
  exportType: React.MutableRefObject<string>;
}

const ExportControl: React.FunctionComponent<ExportControlProps> = ({
  exportData,
  exportType,
}) => {
  return (
    <div className={styles.dataTableHeader}>
      <div className={styles.dataTableTitle}>entity table</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button size="medium" type="hollow" onClick={() => exportData()}>
          Export
        </Button>
        <div style={{ display: 'flex', gap: '.25em', marginLeft: '.25em' }}>
          <input
            type="radio"
            id="csv"
            name="export"
            value="csv"
            onChange={(e) => {
              if (e.target.checked) {
                exportType.current = e.target.value;
              }
            }}
            defaultChecked
          />
          <label htmlFor="csv">CSV</label>
          <input
            type="radio"
            id="json"
            name="export"
            value="json"
            onChange={(e) => {
              if (e.target.checked) {
                exportType.current = e.target.value;
              }
            }}
          />
          <label htmlFor="json">JSON</label>
        </div>
      </div>
    </div>
  );
};
