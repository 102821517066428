import { v4 as uuidv4 } from 'uuid';
import { Matrix, applyToPoint } from 'transformation-matrix';
import robustPointInPolygon from 'robust-point-in-polygon';
import { union, intersection, difference } from 'polygon-clipping';

import {
  CoreSpace,
  CoreSpaceFunction,
  CoreSpaceLabel,
} from '@densityco/lib-api-types';
import WallSegment from 'lib/wall-segment';
import { Meters, polygonalArea, LengthUnit } from 'lib/units';
import { round, distance, degreesToRadians } from 'lib/math';
import Floorplan from 'lib/floorplan';
import { ParsedPlanDXFSpace } from 'lib/dxf';
import AreaOfConcern from 'lib/area-of-concern';
import {
  FloorplanCoordinates,
  CADCoordinates,
  computePolygonEdges,
  computeLineSegmentsInBoundingRegion,
  calculatePolygonCentroid,
} from 'lib/geometry';
import {
  lineSegmentIntersection2d,
  distanceFromLineSegmentToLineSegment,
} from 'lib/algorithm';
import {
  FloorplanV2SpaceWrite,
  FloorplanV2Space,
  FloorplanV2SpacePolygon,
  isFloorplanSpaceId,
  Unsaved,
} from 'lib/api';

export function sortSpacesByNameThatMightContainNumber(
  spaceAName: CoreSpace['name'],
  spaceBName: CoreSpace['name']
): number {
  // Try to parse a number at the start of end of the space name - something like "Floor 5" or "5th floor"
  // If one is found - sort based on that number
  const spaceANumberMatch = /^.*?(\d+).*?$/.exec(spaceAName);
  const spaceBNumberMatch = /^.*?(\d+).*?$/.exec(spaceBName);
  if (spaceANumberMatch && spaceBNumberMatch) {
    const spaceANumber = parseInt(spaceANumberMatch[1]);
    const spaceBNumber = parseInt(spaceBNumberMatch[1]);
    return spaceANumber < spaceBNumber ? -1 : 1;
  }

  // If a number exists on one floor and not another, then put the numbered floors first
  if (spaceANumberMatch && !spaceBNumberMatch) {
    return -1;
  }
  if (!spaceANumberMatch && spaceBNumberMatch) {
    return 1;
  }

  // Fall back to regular string sorting
  return spaceAName.localeCompare(spaceBName);
}

export function isSpaceRectilinear(
  floorplanV2Space: Pick<FloorplanV2SpacePolygon, 'polygon_verticies'>
): boolean {
  if (!floorplanV2Space.polygon_verticies) {
    return false;
  }
  if (floorplanV2Space.polygon_verticies.length !== 4) {
    return false;
  }
  const topLeft = floorplanV2Space.polygon_verticies[0];
  const bottomLeft = floorplanV2Space.polygon_verticies[1];
  const bottomRight = floorplanV2Space.polygon_verticies[2];
  const topRight = floorplanV2Space.polygon_verticies[3];

  const isRectilinear =
    topLeft.y_from_origin_meters === bottomLeft.y_from_origin_meters &&
    bottomLeft.x_from_origin_meters === bottomRight.x_from_origin_meters &&
    bottomRight.y_from_origin_meters === topRight.y_from_origin_meters &&
    topRight.x_from_origin_meters === topLeft.x_from_origin_meters;

  return isRectilinear;
}

export type SpaceCountingMode = 'oa' | 'entry';

export type SpaceShape =
  | {
      type: 'box';
      width: number;
      height: number;
    }
  | {
      type: 'circle';
      radius: number;
    }
  | {
      type: 'polygon';
      vertices: Array<FloorplanCoordinates>;
    };

type BaseSpaceValidation = {
  id: string;
  objectType: 'space';
  objectId: Space['id'];
  severity: 'warning' | 'error';
};

export type SpaceValidation =
  | (BaseSpaceValidation & {
      severity: 'warning';
      validationType: 'space.circularNotRecommended';
    })
  | (BaseSpaceValidation & {
      severity: 'warning';
      validationType: 'space.outsideOfAreaOfConcern';
    })
  | (BaseSpaceValidation & {
      validationType: 'space.tooManyEdges';
      severity: 'warning';
      numberOfEdges: number;
    })
  | (BaseSpaceValidation & {
      validationType: 'space.tooSmall';
      severity: 'error';
    })
  | (BaseSpaceValidation & {
      validationType: 'space.circleSpaceTooSmall';
      severity: 'error';
    })
  | (BaseSpaceValidation & {
      validationType: 'space.selfIntersects';
      severity: 'error';
      intersections: Array<FloorplanCoordinates>;
    })
  | (BaseSpaceValidation & {
      validationType: 'space.intersectsAnotherSpace';
      severity: 'error';
      intersectedSpaceIds: Array<Space['id']>;
      intersectionPoints: Array<FloorplanCoordinates>;
    })
  | (BaseSpaceValidation & {
      validationType: 'space.spaceTooClose';
      severity: 'error';
      nearbySpaces: Array<{
        nearbySpaceId: Space['id'];
        distanceBetweenSpacesMeters: number;
        positionA: FloorplanCoordinates;
        positionB: FloorplanCoordinates;
      }>;
    });

type Space = {
  id: string;
  name: string;
  position: FloorplanCoordinates;
  shape: SpaceShape;
  locked: boolean;
  countingMode: SpaceCountingMode;
  iwmsId: string | null;

  coreSpaceCapacity: CoreSpace['capacity'];
  coreSpaceFunction: CoreSpace['function'];
  coreSpaceLabels: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
};

export type V2CoreSpace = CoreSpace & {
  cost_per_sqft: number | null;
  cost_per_sqft_currency: string | null;
};

export type CopiedSpace = {
  shape: SpaceShape;
  position: FloorplanCoordinates;
  coreSpaceLabels?: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
  coreSpaceCapacity: number | null;
  coreSpaceFunction: CoreSpaceFunction | null;
  countingMode: SpaceCountingMode;
  name: string;
};

namespace Space {
  export const MIN_RADIUS = Meters.fromFeet(2);
  export const MIN_WIDTH = Meters.fromFeet(4);
  export const MIN_HEIGHT = Meters.fromFeet(4);
  export const DEFAULT_BOX_SHAPE = {
    width: Meters.fromFeet(6),
    height: Meters.fromFeet(6),
  };
  export const DEFAULT_CIRCLE_SHAPE = {
    radius: Meters.fromFeet(5),
  };
  export const MAX_RECOMMENDED_POLYGON_SPACE_EDGES = 6;

  export function generateName(existingSpaces: Array<Space>): string {
    // Looks for the highest space named "Space X" and returns Space X+1
    let maxNumFound = 0;
    for (let space of existingSpaces) {
      let result = space.name.match(/^Space (\d+)/);
      if (result && result.length === 2) {
        // the second result should be the capture group which is the number
        const spaceNum = parseInt(result[1], 10);
        if (spaceNum > maxNumFound) {
          maxNumFound = spaceNum;
        }
      }
    }
    return `Space ${maxNumFound + 1}`;
  }

  export function getAffixValue(
    name: string | undefined,
    affix: 'post' | 'pre' | undefined
  ) {
    if (name === undefined || affix === undefined) return -1;
    let regex;
    if (affix === 'pre') regex = /^\d+/;
    else regex = /\d+$/;

    let match = name.match(regex);

    return match ? parseInt(match[0]) : -1;
  }

  export function incrementSpaceName(
    name: string,
    increment: number,
    affix: string
  ) {
    if (name === undefined || affix === undefined) return increment.toString();
    let regex: RegExp;
    if (affix === 'pre') {
      regex = /^(\d+)/;
    } else {
      // 'post'
      regex = /(\d+)$/;
    }
    let match = name.match(regex);

    if (!match) {
      if (affix === 'pre') return (increment - 1).toString() + `-${name}`;
      if (affix === 'post') return `${name} ` + increment.toString();
    }

    // function to get the leading zeroes so we can retain them
    function getLeadingZeroes(numStr: string) {
      let zeroesCount = 0;
      for (let i = 0; i < numStr.length; i++) {
        if (numStr[i] === '0') {
          zeroesCount++;
        } else {
          if (parseInt(numStr[i]) + increment > 9) {
            let newNumLength = (parseInt(numStr[i]) + increment).toString()
              .length;
            zeroesCount = Math.max(0, zeroesCount - newNumLength + 1);
          }
          break;
        }
      }
      return '0'.repeat(zeroesCount);
    }

    if (match && match[1]) {
      const leadingZeroes = getLeadingZeroes(match[1]);
      const numberPart = parseInt(match[1]);
      const incrementedNumber = numberPart + increment;
      if (affix === 'pre') {
        // Replace only the prefix number
        return (
          leadingZeroes +
          incrementedNumber.toString() +
          name.substring(match[0].length)
        );
      } else {
        // Replace only the postfix number
        return (
          name.slice(0, -match[0].length) +
          leadingZeroes +
          incrementedNumber.toString()
        );
      }
    }
    return name;
  }

  export function createBox(
    position: FloorplanCoordinates,
    name: string,
    width: number,
    height: number,
    countingMode: SpaceCountingMode
  ): Space {
    const id = uuidv4();
    return {
      id,
      name,
      position,
      shape: {
        type: 'box',
        width,
        height,
      },
      locked: false,
      coreSpaceCapacity: null,
      coreSpaceFunction: null,
      coreSpaceLabels: [],
      countingMode,
      iwmsId: null,
    };
  }

  export function createCircle(
    position: FloorplanCoordinates,
    name: string,
    radius: number
  ): Space {
    const id = uuidv4();
    return {
      id,
      name,
      position,
      shape: {
        type: 'circle',
        radius,
      },
      locked: false,
      coreSpaceCapacity: null,
      coreSpaceFunction: null,
      coreSpaceLabels: [],
      countingMode: 'oa',
      iwmsId: null,
    };
  }

  export function createPolygon(
    vertices: Array<FloorplanCoordinates>,
    name: string,
    countingMode: SpaceCountingMode
  ): Space {
    const id = uuidv4();
    return {
      id,
      name,
      position: calculatePolygonCentroid(vertices),
      shape: {
        type: 'polygon',
        vertices,
      },
      locked: false,
      coreSpaceCapacity: null,
      coreSpaceFunction: null,
      coreSpaceLabels: [],
      countingMode,
      iwmsId: null,
    };
  }

  export function toPolygon(space: Space): Array<Array<[number, number]>> {
    const vertices = computePolygonBoundaryForSpace(space, true);
    return [vertices.map((v) => [v.x, v.y])];
  }

  export function duplicateSpace(oldSpace: Space): Space {
    const id = uuidv4();
    return { ...oldSpace, id };
  }

  // Given a position on the floorplan, attempt to place a polygon space that fills the nearest
  // fully enclosed room.
  //
  // NOTE: This only really works for small rooms, and doesn't really scale to massive rooms all
  // that well. I (Ryan Gaus) am not sure why and this needs some investigation before this can
  // actually be used as a space creation method.
  export function createPolygonWithinWallSegments(
    startPoint: FloorplanCoordinates,
    name: string,
    floorplan: Floorplan,
    wallSegments: Array<WallSegment>
  ): Space | null {
    // Step 1: Get the polygons for all wall segments that make closed loops
    let polygons = WallSegment.computeWallSegmentLoops(wallSegments);

    // Step 2: filter out all polygons that don't contain the start point
    polygons = polygons.filter((polygon) => {
      const startPointWithinRegion = robustPointInPolygon(
        polygon.map((p) => [p.x, p.y]),
        [startPoint.x, startPoint.y]
      );

      return startPointWithinRegion !== 1; /* 1 = outside of polygon */
    });

    polygons = polygons.filter((polygon) => polygonalArea(polygon) > 0);

    // No polygons could be found? Bail out early.
    if (polygons.length === 0) {
      return null;
    }

    // Step 3: Pick the polygon with the smallest area
    const polygonAreas = polygons.map((polygon) => polygonalArea(polygon));
    const minPolygonAreaIndex = polygonAreas.indexOf(Math.min(...polygonAreas));
    const polygonWithSmallestArea = polygons[minPolygonAreaIndex];

    // Step 4: Simplify the polygon
    for (let i = 0; i < polygonWithSmallestArea.length; i += 1) {
      // Get the slope from the previous point to the current point and compare that with the slope
      // from the current point to the next point
      const lastPoint =
        polygonWithSmallestArea[
          i === 0 ? polygonWithSmallestArea.length - 1 : i - 1
        ];
      const thisPoint = polygonWithSmallestArea[i];
      const nextPoint =
        polygonWithSmallestArea[
          i === polygonWithSmallestArea.length - 1 ? 0 : i + 1
        ];
      const lastThisSlope =
        (lastPoint.y - thisPoint.y) / (lastPoint.x - thisPoint.x);
      const thisNextSlope =
        (thisPoint.y - nextPoint.y) / (thisPoint.x - nextPoint.x);

      if (lastThisSlope !== thisNextSlope) {
        continue;
      }

      // If the slopes are the same, then deleting the current point will result in the same
      // geometry
      polygonWithSmallestArea.splice(i, 1);
      i -= 1;
    }

    return Space.createPolygon(polygonWithSmallestArea, name, 'oa'); // FIXME: address proper countingMode here
  }

  export function computePolygonBoundaryForSpace(
    space: Space,
    approximateCircularSpaceEdges = false
  ): Array<FloorplanCoordinates> {
    switch (space.shape.type) {
      case 'box': {
        const topLeft = FloorplanCoordinates.create(
          space.position.x - space.shape.width / 2,
          space.position.y - space.shape.height / 2
        );
        const topRight = FloorplanCoordinates.create(
          space.position.x + space.shape.width / 2,
          space.position.y - space.shape.height / 2
        );
        const bottomLeft = FloorplanCoordinates.create(
          space.position.x - space.shape.width / 2,
          space.position.y + space.shape.height / 2
        );
        const bottomRight = FloorplanCoordinates.create(
          space.position.x + space.shape.width / 2,
          space.position.y + space.shape.height / 2
        );
        return [topLeft, topRight, bottomRight, bottomLeft];
      }
      case 'polygon':
        return space.shape.vertices;
      case 'circle': {
        if (!approximateCircularSpaceEdges) {
          return [];
        }

        // Perform an approximation of the circle - in effect, this makes a large, multi-sided
        // regular polygon.
        const circularApproximationPoints: Array<FloorplanCoordinates> = [];
        for (let theta = 0; theta < 360; theta += 10) {
          circularApproximationPoints.push(
            FloorplanCoordinates.create(
              space.position.x +
                Math.cos(degreesToRadians(theta)) * space.shape.radius,
              space.position.y +
                Math.sin(degreesToRadians(theta)) * space.shape.radius
            )
          );
        }

        return circularApproximationPoints;
      }
    }
  }

  export function computeAreaInSquareMeters(space: Space) {
    if (space.shape.type === 'circle') {
      return Math.PI * space.shape.radius ** 2;
    } else if (space.shape.type === 'box') {
      return space.shape.width * space.shape.height;
    } else {
      return polygonalArea(space.shape.vertices);
    }
  }

  // Generate all pairs of points that make up edges of a space's geometry. For a box space, this
  // means the top, left, right, and bottom sides, and for polygons, this means the segment in
  // between each pair of vertices
  export function computeEdgesOfSpace(
    space: Space,
    approximateCircularSpaceEdges = false
  ): Array<[FloorplanCoordinates, FloorplanCoordinates]> {
    return computePolygonEdges(
      Space.computePolygonBoundaryForSpace(space, approximateCircularSpaceEdges)
    );
  }

  // Returns the upper left and lower right coordinates as a pair that represent the axis aligned
  // bounding box of the space
  export function computeAxisAlignedBoundingBox(
    space: Space
  ): [FloorplanCoordinates, FloorplanCoordinates] {
    switch (space.shape.type) {
      case 'box': {
        const upperLeft = FloorplanCoordinates.create(
          space.position.x - space.shape.width / 2,
          space.position.y - space.shape.height / 2
        );
        const lowerRight = FloorplanCoordinates.create(
          space.position.x + space.shape.width / 2,
          space.position.y + space.shape.height / 2
        );
        return [upperLeft, lowerRight];
      }
      case 'circle': {
        const upperLeft = FloorplanCoordinates.create(
          space.position.x - space.shape.radius,
          space.position.y - space.shape.radius
        );
        const lowerRight = FloorplanCoordinates.create(
          space.position.x + space.shape.radius,
          space.position.y + space.shape.radius
        );
        return [upperLeft, lowerRight];
      }
      case 'polygon': {
        const xValues = space.shape.vertices.map((vertex) => vertex.x);
        const yValues = space.shape.vertices.map((vertex) => vertex.y);
        const upperLeft = FloorplanCoordinates.create(
          Math.min(...xValues),
          Math.min(...yValues)
        );
        const lowerRight = FloorplanCoordinates.create(
          Math.max(...xValues),
          Math.max(...yValues)
        );
        return [upperLeft, lowerRight];
      }
    }
  }

  // Given a precomputed mapping of space bounding boxes and space adges (computed with
  // Space.computeAxisAlignedBoundingBox and Space.computeEdgesOfSpace), return a list of space ids
  // of spaces that the specified space overlaps. This is used as a validation step to ensure that
  // spaces do not intersect with each other.
  //
  // In addition, return all intersection points between the specified space and other spaces.
  export function computeSpaceEdgeIntersections(
    space: Space,
    allSpaces: Array<Space>,
    spaceEdges: Array<
      [Space['id'], [FloorplanCoordinates, FloorplanCoordinates]]
    >,
    skipIntersections = false
  ): [Array<Space['id']>, Array<FloorplanCoordinates>] {
    const otherSpaces = allSpaces.filter((s) => s.id !== space.id);

    // Step 1: If when the space in question and the other space are intersected together, they
    // result in a shape with area, then it's an overlapping space
    const spaceMultiPolygon = [
      [
        Space.computePolygonBoundaryForSpace(space, true).map(
          (pt) => [pt.x, pt.y] as [number, number]
        ),
      ],
    ];
    const overlappingSpaceIds = otherSpaces
      .filter((otherSpace) => {
        const otherSpaceMultiPolygon = [
          [
            Space.computePolygonBoundaryForSpace(otherSpace, true).map(
              (pt) => [pt.x, pt.y] as [number, number]
            ),
          ],
        ];
        const intersectedMultiPolygon = intersection(
          spaceMultiPolygon,
          otherSpaceMultiPolygon
        );

        // If the intersected polygon doesn't contain any parts then it has no area
        if (intersectedMultiPolygon.length === 0) {
          return false;
        }

        // Then, calculate the area of each inner polygon within the multipolygon
        const polygonRegionAreas = intersectedMultiPolygon.map((polygon) => {
          return polygon
            .map((section) => {
              return polygonalArea(
                section.map((p: [number, number]) =>
                  FloorplanCoordinates.create(p[0], p[1])
                )
              );
            })
            .reduce((a, b) => a + b, 0);
        });

        // And use this to compute the area of the whole multi polygon
        let totalArea = polygonRegionAreas[0];
        for (let i = 1; i < polygonRegionAreas.length; i += 1) {
          totalArea -= polygonRegionAreas[i];
        }

        // If the area the two polygons share in common is larger than 0, they intersect
        return totalArea > 1e-6;
      })
      .map((otherSpace) => otherSpace.id);

    // Step 2: Get all "other space" edges that overlap our space in question
    const otherSpaceEdgePairs: Array<
      [Space['id'], [FloorplanCoordinates, FloorplanCoordinates]]
    > = spaceEdges.filter(([otherSpaceId]) =>
      overlappingSpaceIds.includes(otherSpaceId)
    );

    // Bail out early if calculating the exact point that the intersections occur is not needed
    if (skipIntersections) {
      return [overlappingSpaceIds, []];
    }

    // Step 3: Check to see if the space's edges intersect with another space
    const intersectionPoints: Array<FloorplanCoordinates> = [];
    const selectedSpaceEdges = Space.computeEdgesOfSpace(space, true);
    for (const selectedSpaceEdge of selectedSpaceEdges) {
      // NOTE: _otherSpaceId isn't being used, but needs to be there forthe destructuring to work
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [_otherSpaceId, otherSpaceEdge] of otherSpaceEdgePairs) {
        // If the two edges are at the same angle (like if two spaces share a side), then
        // this is not considered an overlap
        const selectedSpaceEdgeSlope =
          (selectedSpaceEdge[0].y - selectedSpaceEdge[1].y) /
          (selectedSpaceEdge[0].x - selectedSpaceEdge[1].x);
        const otherSpaceEdgeSlope =
          (otherSpaceEdge[0].y - otherSpaceEdge[1].y) /
          (otherSpaceEdge[0].x - otherSpaceEdge[1].x);
        if (selectedSpaceEdgeSlope === otherSpaceEdgeSlope) {
          continue;
        }

        // Do the two edges intersect?
        const intersectionPoint = lineSegmentIntersection2d(
          [],
          [selectedSpaceEdge[0].x, selectedSpaceEdge[0].y],
          [selectedSpaceEdge[1].x, selectedSpaceEdge[1].y],
          [otherSpaceEdge[0].x, otherSpaceEdge[0].y],
          [otherSpaceEdge[1].x, otherSpaceEdge[1].y]
        );
        if (!intersectionPoint) {
          continue;
        }

        // Make sure the intersection point wasn't right at a vertex of the space
        let isEndpointAtVertexPoint = false;
        for (const vertex of selectedSpaceEdges.flat()) {
          if (
            intersectionPoint[0] === vertex.x &&
            intersectionPoint[1] === vertex.y
          ) {
            isEndpointAtVertexPoint = true;
            break;
          }
        }
        if (isEndpointAtVertexPoint) {
          continue;
        }

        // Log any intersections - both the space id, and the point that the intersection
        // occurred at
        intersectionPoints.push(
          FloorplanCoordinates.create(
            intersectionPoint[0],
            intersectionPoint[1]
          )
        );
      }
    }

    return [overlappingSpaceIds, intersectionPoints];
  }

  // Given a space and some context about the other spaces that exist, return all spaces that are
  // near the given space. This is used as a validation step to ensure that any two spaces aren't
  // within 4 feet of each other.
  export function computeNearbySpaces(
    space: Space,
    spaceOverlappingSpaceIds: Array<Space['id']>,
    spaceEdges: Array<
      [Space['id'], [FloorplanCoordinates, FloorplanCoordinates]]
    >,
    closenessThresholdMeters = Meters.fromFeet(4)
  ): Array<{
    nearbySpaceId: Space['id'];
    distanceBetweenSpacesMeters: number;
    positionA: FloorplanCoordinates;
    positionB: FloorplanCoordinates;
  }> {
    // If this space overlaps other spaces, then it's not "nearby", it's at least "partially
    // within"... so bail out early
    if (spaceOverlappingSpaceIds.length > 0) {
      return [];
    }

    const boundingBox = Space.computeAxisAlignedBoundingBox(space);
    if (!boundingBox) {
      return [];
    }
    const upperLeft = FloorplanCoordinates.create(
      boundingBox[0].x - Meters.fromFeet(4),
      boundingBox[0].y - Meters.fromFeet(4)
    );
    const lowerRight = FloorplanCoordinates.create(
      boundingBox[1].x + Meters.fromFeet(4),
      boundingBox[1].y + Meters.fromFeet(4)
    );

    // If there are not spaces in the nearby bounding box, no spaces are nearby
    const spaceEdgesNearBoundingRegion = computeLineSegmentsInBoundingRegion(
      spaceEdges.filter(([spaceId]) => spaceId !== space.id),
      upperLeft,
      lowerRight,
      ([spaceId, edge]) => edge
    );
    if (spaceEdgesNearBoundingRegion.length === 0) {
      return [];
    }

    const edgesNearBoundingRegionBySpaceId = new Map<
      Space['id'],
      Array<[FloorplanCoordinates, FloorplanCoordinates]>
    >();
    for (const [spaceId, edge] of spaceEdgesNearBoundingRegion) {
      const edges = edgesNearBoundingRegionBySpaceId.get(spaceId) || [];
      edges.push(edge);
      edgesNearBoundingRegionBySpaceId.set(spaceId, edges);
    }

    const nearbySpaces = [];
    for (const [otherSpaceId, otherSpaceEdges] of Array.from(
      edgesNearBoundingRegionBySpaceId
    )) {
      // Find the closest set of edges between the two spaces
      let closestDistanceMeters = Infinity;
      let closestDistanceSegmentPositionA: FloorplanCoordinates | null = null;
      let closestDistanceSegmentPositionB: FloorplanCoordinates | null = null;
      for (const spaceEdge of Space.computeEdgesOfSpace(space, true)) {
        for (const otherSpaceEdge of otherSpaceEdges) {
          const result = distanceFromLineSegmentToLineSegment(
            {
              start: [spaceEdge[0].x, spaceEdge[0].y, 0],
              end: [spaceEdge[1].x, spaceEdge[1].y, 0],
            },
            {
              start: [otherSpaceEdge[0].x, otherSpaceEdge[0].y, 0],
              end: [otherSpaceEdge[1].x, otherSpaceEdge[1].y, 0],
            }
          );

          // Disregard matches that are close to zero - if two spaces are touching, that is ok -
          // the problem is only is they are close to each other.
          if (result.distance < 1e-6) {
            continue;
          }

          if (result.distance < closestDistanceMeters) {
            closestDistanceMeters = result.distance;
            const spaceEdgeOffsetX =
              (spaceEdge[1].x - spaceEdge[0].x) * result.s1IntersectionScale;
            const spaceEdgeOffsetY =
              (spaceEdge[1].y - spaceEdge[0].y) * result.s1IntersectionScale;
            const segmentPositionA = FloorplanCoordinates.create(
              spaceEdge[0].x + spaceEdgeOffsetX,
              spaceEdge[0].y + spaceEdgeOffsetY
            );

            const otherSpaceEdgeOffsetX =
              (otherSpaceEdge[1].x - otherSpaceEdge[0].x) *
              result.s2IntersectionScale;
            const otherSpaceEdgeOffsetY =
              (otherSpaceEdge[1].y - otherSpaceEdge[0].y) *
              result.s2IntersectionScale;
            const segmentPositionB = FloorplanCoordinates.create(
              otherSpaceEdge[0].x + otherSpaceEdgeOffsetX,
              otherSpaceEdge[0].y + otherSpaceEdgeOffsetY
            );

            closestDistanceSegmentPositionA = segmentPositionA;
            closestDistanceSegmentPositionB = segmentPositionB;
          }
        }
      }

      if (
        closestDistanceSegmentPositionA &&
        closestDistanceSegmentPositionB &&
        closestDistanceMeters <= closenessThresholdMeters
      ) {
        nearbySpaces.push({
          nearbySpaceId: otherSpaceId,
          distanceBetweenSpacesMeters: closestDistanceMeters,
          positionA: closestDistanceSegmentPositionA,
          positionB: closestDistanceSegmentPositionB,
        });
      }
    }
    return nearbySpaces;
  }

  // Given a space, transform the space's position by the given matrix.
  export function transformByMatrix(space: Space, matrix: Matrix): Space {
    const [x, y] = applyToPoint(matrix, [space.position.x, space.position.y]);
    const nextPosition = FloorplanCoordinates.create(x, y);

    switch (space.shape.type) {
      case 'circle': {
        // NOTE: the scale is the same in both the x and y axis, so a radius computed this
        // way should work.
        const [centerX, centerY] = applyToPoint(matrix, [
          space.position.x,
          space.position.y,
        ]);
        const [rightwardsRadiusX, rightwardsRadiusY] = applyToPoint(matrix, [
          space.position.x + space.shape.radius,
          space.position.y,
        ]);
        return {
          ...space,
          position: nextPosition,
          shape: {
            ...space.shape,
            radius: distance(
              FloorplanCoordinates.create(centerX, centerY),
              FloorplanCoordinates.create(rightwardsRadiusX, rightwardsRadiusY)
            ),
          },
        };
      }
      case 'box': {
        return Space._transformBoxSpaceByMatrix(
          // FIXME: why doesn't the type assertion in the case work here?
          space as Space & {
            shape: { type: 'box'; width: number; height: number };
          },
          matrix
        );
      }
      case 'polygon': {
        return {
          ...space,
          position: nextPosition,
          shape: {
            ...space.shape,
            vertices: space.shape.vertices.map((v) => {
              const [x, y] = applyToPoint(matrix, [v.x, v.y]);
              return FloorplanCoordinates.create(x, y);
            }),
          },
        };
      }
    }
  }

  export function _transformBoxSpaceByMatrix(
    boxSpace: Space & { shape: { type: 'box'; width: number; height: number } },
    matrix: Matrix
  ): Space {
    // Start by converting all vertices of this space into the new coordinate space
    const [upperLeftX, upperLeftY] = applyToPoint(matrix, [
      boxSpace.position.x - boxSpace.shape.width / 2,
      boxSpace.position.y - boxSpace.shape.height / 2,
    ]);
    const [upperRightX, upperRightY] = applyToPoint(matrix, [
      boxSpace.position.x + boxSpace.shape.width / 2,
      boxSpace.position.y - boxSpace.shape.height / 2,
    ]);
    const [lowerLeftX, lowerLeftY] = applyToPoint(matrix, [
      boxSpace.position.x - boxSpace.shape.width / 2,
      boxSpace.position.y + boxSpace.shape.height / 2,
    ]);
    const [lowerRightX, lowerRightY] = applyToPoint(matrix, [
      boxSpace.position.x + boxSpace.shape.width / 2,
      boxSpace.position.y + boxSpace.shape.height / 2,
    ]);

    // To determine if the boxSpace shape is rectilinear and axis aligned, compute the space's
    // bounding box to the box. If they are the same shape, then this space can be represented as a
    // box!
    //
    // Keep in mind with the below that due to rotations, the "upper left" point in the
    // pre-transformed coordinate system may not be the upper left point after the transformation
    // completes. This is why this bounding box method was picked, because it's resilient to this
    // behavior.
    let isRectilinear = false;

    const transformedboxSpacePolygon: Array<[number, number]> = [
      [round(upperLeftX, 3), round(upperLeftY, 3)],
      [round(upperRightX, 3), round(upperRightY, 3)],
      [round(lowerRightX, 3), round(lowerRightY, 3)],
      [round(lowerLeftX, 3), round(lowerLeftY, 3)],
    ];

    const boundingBoxUpperLeftX = Math.min(
      upperLeftX,
      upperRightX,
      lowerLeftX,
      lowerRightX
    );
    const boundingBoxUpperLeftY = Math.min(
      upperLeftY,
      upperRightY,
      lowerLeftY,
      lowerRightY
    );
    const boundingBoxLowerLeftX = Math.max(
      upperLeftX,
      upperRightX,
      lowerLeftX,
      lowerRightX
    );
    const boundingBoxLowerLeftY = Math.max(
      upperLeftY,
      upperRightY,
      lowerLeftY,
      lowerRightY
    );

    const boundingBoxPolygon: Array<[number, number]> = [
      [round(boundingBoxUpperLeftX, 3), round(boundingBoxUpperLeftY, 3)],
      [round(boundingBoxLowerLeftX, 3), round(boundingBoxUpperLeftY, 3)],
      [round(boundingBoxLowerLeftX, 3), round(boundingBoxLowerLeftY, 3)],
      [round(boundingBoxUpperLeftX, 3), round(boundingBoxLowerLeftY, 3)],
    ];

    // Start by computing the offset in index between the two point arrays
    //
    // The below logic takes advantage of the fact that points in both `transformedBoxSpacePolygon`
    // and `boundingBoxPolygon` will always be in the same order, but just potentially shifted
    // by a constant offset.
    let offset = -1;
    const [x, y] = transformedboxSpacePolygon[0];
    for (let i = 0; i < boundingBoxPolygon.length; i += 1) {
      const [bx, by] = boundingBoxPolygon[i];
      if (x === bx && y === by) {
        offset = i;
        break;
      }
    }

    // Using this offset, figure out if the bounding box and boxSpace cover the same region by
    // comparing each pair of points
    if (offset >= 0) {
      isRectilinear = true;

      // We have found the starting point! Now, cycle through each item after the
      // starting point and make sure that it matches
      for (let k = 0; k < boundingBoxPolygon.length; k += 1) {
        const transformedboxSpacePolygonIndex = k;
        let boundingBoxPolygonIndex = k + offset;
        if (boundingBoxPolygonIndex >= boundingBoxPolygon.length) {
          boundingBoxPolygonIndex -= boundingBoxPolygon.length;
        }
        const [tx, ty] =
          transformedboxSpacePolygon[transformedboxSpacePolygonIndex];
        const [bx, by] = boundingBoxPolygon[boundingBoxPolygonIndex];
        if (bx !== tx || by !== ty) {
          isRectilinear = false;
          break;
        }
      }
    }

    if (isRectilinear) {
      // This space is rectilinear, so keep it a rectangle
      return {
        ...boxSpace,
        position: FloorplanCoordinates.create(
          boundingBoxUpperLeftX +
            (boundingBoxLowerLeftX - boundingBoxUpperLeftX) / 2,
          boundingBoxUpperLeftY +
            (boundingBoxLowerLeftY - boundingBoxUpperLeftY) / 2
        ),
        shape: {
          type: 'box',
          width: boundingBoxLowerLeftX - boundingBoxUpperLeftX,
          height: boundingBoxLowerLeftY - boundingBoxUpperLeftY,
        },
      };
    } else {
      // This boxSpace can no longer be represented as a rectangle, so convert it to a
      // polygon
      return {
        ...boxSpace,
        shape: {
          type: 'polygon',
          vertices: [
            FloorplanCoordinates.create(upperLeftX, upperLeftY),
            FloorplanCoordinates.create(upperRightX, upperRightY),
            FloorplanCoordinates.create(lowerRightX, lowerRightY),
            FloorplanCoordinates.create(lowerLeftX, lowerLeftY),
          ],
        },
      };
    }
  }

  export function computeSpaceSelfIntersections(
    space: Space
  ): Array<FloorplanCoordinates> {
    if (space.shape.type !== 'polygon') {
      return [];
    }

    const intersections: Array<FloorplanCoordinates> = [];
    const edges = computePolygonEdges(space.shape.vertices);
    for (let i = 0; i < edges.length; i += 1) {
      for (let j = 0; j < edges.length; j += 1) {
        if (i === j) {
          continue;
        }
        const edgeA = edges[i];
        const edgeB = edges[j];

        const intersectionPoint = lineSegmentIntersection2d(
          [],
          [edgeA[0].x, edgeA[0].y],
          [edgeA[1].x, edgeA[1].y],
          [edgeB[0].x, edgeB[0].y],
          [edgeB[1].x, edgeB[1].y]
        );

        if (!intersectionPoint) {
          continue;
        }

        // Make sure that the intersection wasn't at an end point of either edge
        if (
          intersectionPoint[0] === edgeA[0].x &&
          intersectionPoint[1] === edgeA[0].y
        ) {
          continue;
        }
        if (
          intersectionPoint[0] === edgeA[1].x &&
          intersectionPoint[1] === edgeA[1].y
        ) {
          continue;
        }
        if (
          intersectionPoint[0] === edgeB[0].x &&
          intersectionPoint[1] === edgeB[0].y
        ) {
          continue;
        }
        if (
          intersectionPoint[0] === edgeB[1].x &&
          intersectionPoint[1] === edgeB[1].y
        ) {
          continue;
        }

        intersections.push(
          FloorplanCoordinates.create(
            intersectionPoint[0],
            intersectionPoint[1]
          )
        );
      }
    }

    return intersections;
  }

  export function isWithinAreaOfConcern(
    space: Space,
    areasOfConcern: Array<AreaOfConcern>
  ): boolean {
    const polygons = areasOfConcern.map((aoc) => AreaOfConcern.toPolygon(aoc));

    // Turn all areas of concern into one huge polygon.
    const aocMultiPolygon = union(polygons);
    // Approximate circular and box places with a bounding box.
    const polygonSpace = Space.toPolygon(space);
    // Figure out whether the two polygons intersect...
    return difference([polygonSpace], aocMultiPolygon).length === 0;
  }

  export function validate(
    space: Space,
    spaces: Array<Space>,
    areasOfConcern: Array<AreaOfConcern>
  ): Array<SpaceValidation> {
    const validations: Array<SpaceValidation> = [];

    // Check whether a space is within an AOC, if they exist.
    if (
      areasOfConcern.length > 0 &&
      !Space.isWithinAreaOfConcern(space, areasOfConcern)
    ) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'warning',
        validationType: 'space.outsideOfAreaOfConcern',
      });
    }

    /*
    // Check for Space overlaps, intersections, proximity, etc.
    // This is outdated as of late Jun 2023, as Spaces can now ovelap.
   const spaceEdges: Array<
      [Space['id'], [FloorplanCoordinates, FloorplanCoordinates]]
    > = [];

    for (const space of spaces) {
      for (const edge of Space.computeEdgesOfSpace(space, true)) {
        spaceEdges.push([space.id, edge]);
      }
    }

    if (space.shape.type === 'circle') {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'warning',
        validationType: 'space.circularNotRecommended',
      });
    }

    // AOI sensor coverage

    if (
      space.shape.type === 'polygon' &&
      space.shape.vertices.length - 1 >
        Space.MAX_RECOMMENDED_POLYGON_SPACE_EDGES
    ) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'warning',
        validationType: 'space.tooManyEdges',
        numberOfEdges: space.shape.vertices.length,
      });
    }

    const [overlappingSpaceIds, intersectionPoints] =
      Space.computeSpaceEdgeIntersections(space, spaces, spaceEdges);
    if (overlappingSpaceIds.length > 0) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'error',
        validationType: 'space.intersectsAnotherSpace',
        intersectedSpaceIds: overlappingSpaceIds,
        intersectionPoints,
      });
    }

    if (
      space.shape.type === 'box' &&
      (space.shape.width < Space.MIN_WIDTH ||
        space.shape.height < Space.MIN_HEIGHT)
    ) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'error',
        validationType: 'space.tooSmall',
      });
    }
    if (
      space.shape.type === 'circle' &&
      space.shape.radius < Space.MIN_RADIUS
    ) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'error',
        validationType: 'space.circleSpaceTooSmall',
      });
    }
    if (space.shape.type === 'polygon') {
      const [upperLeft, lowerRight] =
        Space.computeAxisAlignedBoundingBox(space);
      if (
        lowerRight.x - upperLeft.x < Space.MIN_WIDTH ||
        lowerRight.y - upperLeft.y < Space.MIN_HEIGHT
      ) {
        validations.push({
          id: uuidv4(),
          objectType: 'space',
          objectId: space.id,
          severity: 'error',
          validationType: 'space.tooSmall',
        });
      }
    }

    const selfIntersections = Space.computeSpaceSelfIntersections(space);
    if (selfIntersections.length > 0) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'error',
        validationType: 'space.selfIntersects',
        intersections: selfIntersections,
      });
    }

    const nearbySpaces = Space.computeNearbySpaces(
      space,
      overlappingSpaceIds,
      spaceEdges
    );
    if (nearbySpaces.length > 0) {
      validations.push({
        id: uuidv4(),
        objectType: 'space',
        objectId: space.id,
        severity: 'error',
        validationType: 'space.spaceTooClose',
        nearbySpaces,
      });
    }
    */

    return validations;
  }

  // Given a space from the floorplan api, construct a new Space.
  export function createFromFloorplanSpace(
    floorplanSpace: FloorplanV2Space,
    floorplan?: Floorplan,
    nullGeometryIdx?: number
  ): Space {
    let space: Space;

    switch (floorplanSpace.shape) {
      case 'circle': {
        const position = FloorplanCoordinates.create(
          floorplanSpace.circle_centroid_x_meters,
          floorplanSpace.circle_centroid_y_meters
        );
        space = Space.createCircle(
          position,
          floorplanSpace.name,
          floorplanSpace.circle_radius_meters
        );
        break;
      }
      case 'polygon': {
        // NOTE: This effectively disables "box" spaces.
        // Figure out if the "polygon" stored on the server is a box or a polygon
        // NOTE: I think ideally it would be nice to have this descriminated on the backend rather
        // than using a hueristic here.
        if (isSpaceRectilinear(floorplanSpace)) {
          // We need to find the topLeft and bottomRight vertices first...

          // The above is tricky becase it depends on whether polygon is CW/CCW and how it's stored.
          // Figure out topLeft and bottomRight

          // Find minimum and maximum x, y coordinates
          let minX = Number.POSITIVE_INFINITY;
          let minY = Number.POSITIVE_INFINITY;
          let maxX = Number.NEGATIVE_INFINITY;
          let maxY = Number.NEGATIVE_INFINITY;

          for (const vertex of floorplanSpace.polygon_verticies) {
            if (vertex.x_from_origin_meters < minX) {
              minX = vertex.x_from_origin_meters;
            }
            if (vertex.x_from_origin_meters > maxX) {
              maxX = vertex.x_from_origin_meters;
            }
            if (vertex.y_from_origin_meters < minY) {
              minY = vertex.y_from_origin_meters;
            }
            if (vertex.y_from_origin_meters > maxY) {
              maxY = vertex.y_from_origin_meters;
            }
          }

          // Determine topLeft and bottomRight assuming rectilinear space
          const topLeft = {
            x_from_origin_meters: minX,
            y_from_origin_meters: minY,
          };
          const bottomRight = {
            x_from_origin_meters: maxX,
            y_from_origin_meters: maxY,
          };

          // The space is a rectangle, so create a box space
          const left = topLeft.x_from_origin_meters;
          const top = topLeft.y_from_origin_meters;
          const right = bottomRight.x_from_origin_meters;
          const bottom = bottomRight.y_from_origin_meters;
          const width = right - left;
          const height = bottom - top;
          const cx = left + width / 2;
          const cy = top + height / 2;
          const position = FloorplanCoordinates.create(cx, cy);
          space = Space.createBox(
            position,
            floorplanSpace.name,
            width,
            height,
            floorplanSpace.counting_mode
          );
        } else if (floorplanSpace.polygon_verticies) {
          // The space is not a rectangle, so create a polygon space
          const vertices = floorplanSpace.polygon_verticies.map((v) =>
            FloorplanCoordinates.create(
              v.x_from_origin_meters,
              v.y_from_origin_meters
            )
          );
          space = Space.createPolygon(
            vertices,
            floorplanSpace.name,
            floorplanSpace.counting_mode
          );
        } else {
          // If the geometry is null, let's make it a square and render to the right of the floorplan.
          // This is a special case to handle legacy ToF sensors that never had any geometry.
          // The moment these entities are moved, they will persist their new location and this become
          // redundant.

          const width = floorplan
            ? (floorplan.width - floorplan.origin.x) / floorplan.scale / 20
            : 2;
          const position = FloorplanCoordinates.create(
            floorplan
              ? (floorplan.width - floorplan.origin.x) / floorplan.scale +
                  2 * width
              : 0,
            nullGeometryIdx && floorplan
              ? -floorplan.origin.y / floorplan.scale / 20 +
                  nullGeometryIdx * width * 1.05
              : 0
          );

          const vertices = [
            FloorplanCoordinates.create(
              position.x + width / 2,
              position.y + width / 2
            ),
            FloorplanCoordinates.create(
              position.x + width / 2,
              position.y - width / 2
            ),
            FloorplanCoordinates.create(
              position.x - width / 2,
              position.y - width / 2
            ),
            FloorplanCoordinates.create(
              position.x - width / 2,
              position.y + width / 2
            ),
          ];

          space = Space.createPolygon(
            vertices,
            (floorplanSpace as FloorplanV2SpacePolygon).name,
            (floorplanSpace as FloorplanV2SpacePolygon).counting_mode
          );
        }
        break;
      }
    }

    space.id = floorplanSpace.id;
    space.locked = floorplanSpace.locked;
    space.coreSpaceCapacity = floorplanSpace.capacity;
    space.coreSpaceFunction = floorplanSpace.function;
    space.coreSpaceLabels = floorplanSpace.labels;
    space.countingMode = floorplanSpace.counting_mode;
    space.iwmsId = floorplanSpace.iwms_id;

    return space;
  }

  // Given a space, format it the normal represetation used by the floorplan api.
  //
  // NOTE: if you are trying to send the result of this operation to the floorplan api as a POST or
  // PATCH, it may not work. Most likely you want `Space.toFloorplanSpaceWrite`!
  export function toFloorplanSpace(
    space: Space
  ): FloorplanV2Space | Unsaved<FloorplanV2Space> {
    const cx = space.position.x;
    const cy = space.position.y;

    // NOTE: Only include the id if it is an space id from the database (already saved space)
    // NOTE: Using `undefined` here means that when JSON.stringify is called on it, the key is not included
    const id = isFloorplanSpaceId(space.id) ? space.id : undefined;

    if (space.shape.type === 'box') {
      const halfWidth = space.shape.width / 2;
      const halfHeight = space.shape.height / 2;

      const left = cx - halfWidth;
      const right = cx + halfWidth;
      const top = cy - halfHeight;
      const bottom = cy + halfHeight;

      const floorplanSpace: FloorplanV2Space | Unsaved<FloorplanV2Space> = {
        id,
        name: space.name,
        locked: space.locked,
        capacity: space.coreSpaceCapacity,
        function: space.coreSpaceFunction,
        labels: space.coreSpaceLabels,
        counting_mode: space.countingMode,
        iwms_id: space.iwmsId,
        meta: {},
        shape: 'polygon',
        polygon_verticies: [
          {
            x_from_origin_meters: left,
            y_from_origin_meters: top,
          },
          {
            x_from_origin_meters: right,
            y_from_origin_meters: top,
          },
          {
            x_from_origin_meters: right,
            y_from_origin_meters: bottom,
          },
          {
            x_from_origin_meters: left,
            y_from_origin_meters: bottom,
          },
        ],
        circle_centroid_x_meters: null,
        circle_centroid_y_meters: null,
        circle_radius_meters: null,
      };
      return floorplanSpace;
    } else if (space.shape.type === 'circle') {
      const floorplanSpace: FloorplanV2Space | Unsaved<FloorplanV2Space> = {
        id,
        name: space.name,
        locked: space.locked,
        capacity: space.coreSpaceCapacity,
        function: space.coreSpaceFunction,
        labels: space.coreSpaceLabels,
        counting_mode: space.countingMode,
        iwms_id: space.iwmsId,
        meta: {},
        shape: 'circle',
        polygon_verticies: null,
        circle_centroid_x_meters: cx,
        circle_centroid_y_meters: cy,
        circle_radius_meters: space.shape.radius,
      };
      return floorplanSpace;
    } else if (space.shape.type === 'polygon') {
      const floorplanSpace: FloorplanV2Space | Unsaved<FloorplanV2Space> = {
        id,
        name: space.name,
        locked: space.locked,
        capacity: space.coreSpaceCapacity,
        function: space.coreSpaceFunction,
        labels: space.coreSpaceLabels,
        counting_mode: space.countingMode,
        iwms_id: space.iwmsId,
        meta: {},
        shape: 'polygon',
        polygon_verticies: space.shape.vertices.map((v) => ({
          x_from_origin_meters: v.x,
          y_from_origin_meters: v.y,
        })),
        circle_centroid_x_meters: null,
        circle_centroid_y_meters: null,
        circle_radius_meters: null,
      };
      return floorplanSpace;
    } else {
      throw new Error(
        'Valid space shape types are "box", "circle", and "polygon"'
      );
    }
  }

  export function createFromParsedPlanDXFSpace(
    planDXFSpace: ParsedPlanDXFSpace,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number
  ): Space {
    const vertices: Array<FloorplanCoordinates> = planDXFSpace.vertices.map(
      (v) =>
        CADCoordinates.toFloorplanCoordinates(
          v,
          floorplan,
          floorplanCADOrigin,
          cadFileUnitOrDefault,
          cadFileScaleOrDefault
        )
    );

    const space = Space.createPolygon(
      vertices,
      planDXFSpace.name,
      planDXFSpace.countingMode
    );

    space.locked = true;
    return space;
  }

  // Given a space, format it in a representation that can be sent to the floorplan api when
  // performing writes. This includes PATCHes and POSTs.
  export function toFloorplanSpaceWrite(
    space: Space,
    create: boolean = false
  ): FloorplanV2SpaceWrite | Unsaved<FloorplanV2SpaceWrite> {
    const cx = space.position.x;
    const cy = space.position.y;

    // NOTE: Only include the id if it is an space id from the database (already saved space)
    // NOTE: Using `undefined` here means that when JSON.stringify is called on it, the key is not included
    const id = isFloorplanSpaceId(space.id) ? space.id : undefined;

    if (space.shape.type === 'box') {
      const halfWidth = space.shape.width / 2;
      const halfHeight = space.shape.height / 2;

      const left = cx - halfWidth;
      const right = cx + halfWidth;
      const top = cy - halfHeight;
      const bottom = cy + halfHeight;

      return {
        id,
        name: space.name,
        locked: space.locked,
        capacity: space.coreSpaceCapacity,
        function: space.coreSpaceFunction,
        counting_mode: space.countingMode,
        iwms_id: space.iwmsId,
        meta: {},
        shape: 'polygon',
        polygon_verticies: [
          {
            x_from_origin_meters: left,
            y_from_origin_meters: top,
          },
          {
            x_from_origin_meters: right,
            y_from_origin_meters: top,
          },
          {
            x_from_origin_meters: right,
            y_from_origin_meters: bottom,
          },
          {
            x_from_origin_meters: left,
            y_from_origin_meters: bottom,
          },
        ],
        ...(create
          ? {}
          : { label_ids: space.coreSpaceLabels.map((l) => l.id) }),
      } as FloorplanV2SpaceWrite | Unsaved<FloorplanV2SpaceWrite>;
    } else if (space.shape.type === 'circle') {
      return {
        id,
        name: space.name,
        locked: space.locked,
        capacity: space.coreSpaceCapacity,
        function: space.coreSpaceFunction,
        counting_mode: space.countingMode,
        iwms_id: space.iwmsId,
        meta: {},
        shape: 'circle',
        circle_centroid_x_meters: cx,
        circle_centroid_y_meters: cy,
        circle_radius_meters: space.shape.radius,
        ...(create
          ? {}
          : { label_ids: space.coreSpaceLabels.map((l) => l.id) }),
      } as FloorplanV2SpaceWrite | Unsaved<FloorplanV2SpaceWrite>;
    } else if (space.shape.type === 'polygon') {
      return {
        id,
        name: space.name,
        locked: space.locked,
        capacity: space.coreSpaceCapacity,
        function: space.coreSpaceFunction,
        counting_mode: space.countingMode,
        iwms_id: space.iwmsId,
        meta: {},
        shape: 'polygon',
        polygon_verticies: space.shape.vertices.map((v) => ({
          x_from_origin_meters: v.x,
          y_from_origin_meters: v.y,
        })),
        ...(create
          ? {}
          : { label_ids: space.coreSpaceLabels.map((l) => l.id) }),
      } as FloorplanV2SpaceWrite | Unsaved<FloorplanV2SpaceWrite>;
    } else {
      throw new Error(
        'Valid space shape types are "box", "circle", and "polygon"'
      );
    }
  }
}

export default Space;
