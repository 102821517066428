import { useRef, useCallback, useEffect } from 'react';
import * as React from 'react';
import * as PIXI from 'pixi.js';

import ImageRegistrationLayer from '../floorplan-layers/image-registration-layer';

import { useFloorplanLayerContext, BigImage } from 'components/floorplan';

import { round } from 'lib/math';
import { LengthUnit } from 'lib/units';
import { FloorplanCoordinates, CADCoordinates } from 'lib/geometry';

// This floorplan layer shows the new base image from the cad file, so it can be overlayed on top of
// the existing floorplan image
const NewBaseImageLayer: React.FunctionComponent<{
  image: HTMLImageElement;
  grayscale?: boolean;
  floorplanCADOrigin: FloorplanCoordinates;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
  pixelsPerCADUnit: number;
  onDragMove: (position: FloorplanCoordinates) => void;
  interactive?: boolean;
}> = ({
  image,
  grayscale = true,
  floorplanCADOrigin,
  cadFileUnit,
  cadFileScale,
  pixelsPerCADUnit,
  onDragMove,
  interactive = true,
}) => {
  const context = useFloorplanLayerContext();

  const coordA = CADCoordinates.toFloorplanCoordinates(
    CADCoordinates.create(0, 0),
    context.floorplan,
    floorplanCADOrigin,
    cadFileUnit,
    cadFileScale
  );
  const coordB = CADCoordinates.toFloorplanCoordinates(
    CADCoordinates.create(1, 0),
    context.floorplan,
    floorplanCADOrigin,
    cadFileUnit,
    cadFileScale
  );
  const metersPerCADUnit = coordB.x - coordA.x;

  // Round the scale to a high level of precision to get rid of floating point math uncertainty
  // If this isn't done, many of the useEffects / useCallbacks in ImageRegistrationLayer will
  // run on every render
  const cadImageScale = round(
    metersPerCADUnit * (1 / pixelsPerCADUnit) * context.floorplan.scale,
    8
  );

  // The ImageRegistrationLayer needs an upper left position, but the cad origin is in the lower
  // left.
  const upperLeftPosition = FloorplanCoordinates.create(
    floorplanCADOrigin.x,
    floorplanCADOrigin.y -
      (image.height * cadImageScale) / context.floorplan.scale
  );

  const onDragMoveCallback = useCallback(
    (upperLeftPosition) => {
      const lowerLeftPosition = FloorplanCoordinates.create(
        upperLeftPosition.x,
        upperLeftPosition.y +
          (image.height * cadImageScale) / context.floorplan.scale
      );
      onDragMove(lowerLeftPosition);
    },
    [image.height, cadImageScale, context.floorplan.scale, onDragMove]
  );

  const bigImageRef = useRef<BigImage | null>(null);
  useEffect(() => {
    const imageSprite = new BigImage(image.src);
    imageSprite.name = 'new-base-layer-image';

    // Apply blend mode and make floorplan grayscale
    // ref: https://github.com/pixijs/pixijs/issues/1598#issuecomment-284810464
    let colorMatrix = new PIXI.filters.ColorMatrixFilter();
    if (grayscale) {
      colorMatrix.desaturate();
    }
    colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    imageSprite.filters = [colorMatrix];

    bigImageRef.current = imageSprite;
  }, [image.src, grayscale]);

  return (
    <ImageRegistrationLayer
      imageWidth={image.width}
      imageHeight={image.height}
      createImage={(container) => {
        if (!bigImageRef.current) {
          return;
        }
        container.addChild(bigImageRef.current);
      }}
      removeImage={(container) => {
        if (bigImageRef.current) {
          container.removeChild(bigImageRef.current);
        }
        container.destroy({ children: true });
      }}
      position={upperLeftPosition}
      rotationInDegrees={0}
      scale={cadImageScale / context.floorplan.scale}
      isMovable={interactive}
      onDragMove={onDragMoveCallback}
    />
  );
};

export default NewBaseImageLayer;
