import { useContext, createContext, FunctionComponent } from 'react';
import { FloorplanLayerContextData } from './types';

const FloorplanLayerContext = createContext<FloorplanLayerContextData | null>(
  null
);

export const FloorplanLayerContextProvider: FunctionComponent<{
  value: FloorplanLayerContextData | null;
}> = ({ value, children }) => (
  <FloorplanLayerContext.Provider value={value}>
    {children}
  </FloorplanLayerContext.Provider>
);

export const useFloorplanLayerContext: () => FloorplanLayerContextData = () => {
  const data = useContext(FloorplanLayerContext);
  if (!data) {
    throw new Error(
      'useFloorplanLayerContext was called in a component not inside of a `<Floorplan />`. This is not allowed.'
    );
  }
  return data;
};
