import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import sanitizeHtml, { defaults } from 'sanitize-html';

import styles from './styles.module.scss';

import Tooltip from 'components/tooltip';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ breaks: true });

const NotesBox: React.FunctionComponent<{
  notes: string;
  disabled?: boolean;
  onNotesEdited: (notes: string) => void;
  'data-cy'?: string;
}> = ({ notes, disabled = false, onNotesEdited, 'data-cy': dataCy }) => {
  const [editing, isEditing] = useState(false);
  const [inProgressNotes, setInProgressNotes] = useState('');

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // Focus the text area when the control moves into the "editing" state
  useEffect(() => {
    if (!textareaRef.current) {
      return;
    }
    if (!editing) {
      return;
    }
    textareaRef.current.focus();
  }, [editing]);

  // When the notes change on the sensor (ie, "done" was clicked), refresh the internal state
  useEffect(() => {
    setInProgressNotes(notes);
  }, [notes]);

  if (editing) {
    return (
      <div className={styles.sensorNotesBoxEditable}>
        <textarea
          className={styles.textAreaSmall}
          value={inProgressNotes}
          onChange={(e) => setInProgressNotes(e.target.value)}
          placeholder="Notes..."
          ref={textareaRef}
          data-cy={`${dataCy}-text`}
        />
        <div className={styles.sensorNotesBoxActions}>
          <Tooltip
            placement="left-end"
            target={<Button size="medium" type="cleared" trailingIcon="Aa" />}
            contents={
              <div className={styles.sensorNotesFormattingTooltip}>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  Bold:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    *bold*
                  </div>
                </div>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  Italics:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    _italics_
                  </div>
                </div>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  Strikethrough:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    ~strike~
                  </div>
                </div>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  Code:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    `code`
                  </div>
                </div>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  List:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    - item
                  </div>
                </div>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  Numbered List:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    1. item
                  </div>
                </div>
                <div className={styles.sensorNotesFormattingTooltipRow}>
                  Link:{' '}
                  <div
                    className={styles.sensorNotesFormattingTooltipRowExample}
                  >
                    &lt;label|url&gt;
                  </div>
                </div>
              </div>
            }
          />

          <HorizontalForm size="medium">
            <Button
              type="hollow"
              size="medium"
              onClick={() => isEditing(false)}
              data-cy={`${dataCy}-cancel`}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              onClick={() => {
                isEditing(false);
                onNotesEdited(inProgressNotes);
              }}
              data-cy={`${dataCy}-done`}
            >
              Done
            </Button>
          </HorizontalForm>
        </div>
      </div>
    );
  }

  // This notes box is markdown format, with special line breaking behavior
  // becausr this text isn't itself a document.
  const notesHtml = md.render(notes);

  return (
    <div
      className={classnames(styles.sensorNotesBox, {
        [styles.disabled]: disabled,
      })}
      onClick={() => {
        if (disabled) {
          return;
        }
        isEditing(true);
      }}
      title="Edit notes..."
      data-cy={dataCy}
    >
      {notes ? (
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(notesHtml, {
              ...defaults,
              allowedTags: [...defaults.allowedTags, 'h1', 'h2', 'u'],

              // Any disallowed tags should be escaped, this hopefully will avoid the surprise of "where did my tag go??"
              disallowedTagsMode: 'escape',

              // Add target="_blank" to all a tags
              transformTags: {
                a: (tagName, attribs) => ({
                  tagName: 'a',
                  attribs: {
                    ...attribs,
                    target: '_blank',
                  },
                }),
              },
            }),
          }}
        />
      ) : (
        <span className={styles.sensorNotesBoxPlaceholder}>Notes...</span>
      )}
    </div>
  );
};

export default NotesBox;
