import { useCallback, useState } from 'react';
import classnames from 'classnames';
import * as React from 'react';
import { Icons } from '@density/dust';

import styles from './styles.module.scss';

import KeyboardShortcut from 'components/keyboard-shortcut';

type KeyboardShortcutEntry = {
  icon: typeof Icons.AccuracyTarget;
  title: string;
  shortcut: string;
  availableOutsideEditor: boolean;
};

const ENTRIES_EDITOR: Array<KeyboardShortcutEntry> = [
  {
    icon: Icons.PanHand,
    title: 'Pan Floor',
    shortcut: 'space + drag',
    availableOutsideEditor: true,
  },
  {
    icon: Icons.Ruler,
    title: 'Measure',
    shortcut: 'alt/option',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.MoreMenuHorizontal,
    title: 'Bulk Select',
    shortcut: 'ctrl + select',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.ZoomIn,
    title: 'Zoom In/Out',
    shortcut: 'cmd + scroll',
    availableOutsideEditor: true,
  },
  {
    icon: Icons.ZoomToFit,
    title: 'Zoom To Fit',
    shortcut: 'shift + space',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.VisibilityShowEye,
    title: 'Isolate Sensor',
    shortcut: 'shift + ctrl',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.SwapVerticalArrow,
    title: 'Flip Render Order',
    shortcut: 'x',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Undo,
    title: 'Undo',
    shortcut: 'cmd+z',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Redo,
    title: 'Redo',
    shortcut: 'cmd+shift+z',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.CopyDuplicate,
    title: 'Duplicate Object',
    shortcut: 'alt + drag',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Trash,
    title: 'Delete Object',
    shortcut: 'backspace',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.ArrowRightForward,
    title: 'Focus Adjacent Object',
    shortcut: 'arrows',
    availableOutsideEditor: false,
  },
];

const ENTRIES_OBJECTS: Array<KeyboardShortcutEntry> = [
  {
    icon: Icons.DeviceEntrySideIsometricAngle,
    title: 'Add OA Sensor',
    shortcut: '1',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.DeviceEntryTopFront,
    title: 'Add Entry Sensor',
    shortcut: '2',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.EditorSquare,
    title: 'Add OA Box Space',
    shortcut: '3',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.EditorCircle,
    title: 'Add OA Circle Space',
    shortcut: '4',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.DrawFreehand,
    title: 'Add OA Polygon Space',
    shortcut: '5',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.EditorSquare,
    title: 'Add Entry Box Space',
    shortcut: '6',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.DrawFreehand,
    title: 'Add Entry Polygon Space',
    shortcut: '7',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Control4,
    title: 'Add Area of Coverage',
    shortcut: '8',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Ruler,
    title: 'Add Reference Ruler',
    shortcut: 'R',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.RulerVertical,
    title: 'Add Reference Height',
    shortcut: 'H',
    availableOutsideEditor: false,
  },
];

const ENTRIES_MISC: Array<KeyboardShortcutEntry> = [
  {
    icon: Icons.Bookmark,
    title: 'Load Bookmarked Layers',
    shortcut: 'B',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.SpacesAreasFloorplanPlanner,
    title: 'Toggle Wall Segments',
    shortcut: 'shift + 0',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.CheckCircle,
    title: 'Toggle All Layers On',
    shortcut: 'shift + 1',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.CloseCircle,
    title: 'Toggle All Layers Off',
    shortcut: 'shift + 2',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.SpaceTypeSpace,
    title: 'Show Only Space Layers',
    shortcut: 'shift + 3',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.DeviceEntrySideIsometricAngle,
    title: 'Show Only OA Layers',
    shortcut: 'shift + 4',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.PlusOne,
    title: 'Show Only Entry Layers',
    shortcut: 'shift + 5',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.CopyDuplicate,
    title: 'Copy Sensor/Space Settings',
    shortcut: 'shift + right-click',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Paintbrush,
    title: 'Paste Sensor/Space Settings',
    shortcut: 'shift + left-click',
    availableOutsideEditor: false,
  },
];

export type Props = {
  open: boolean;
  onMenuToggle: () => void;
  entriesEditor?: Array<KeyboardShortcutEntry>;
  entriesObjects?: Array<KeyboardShortcutEntry>;
  entriesMisc?: Array<KeyboardShortcutEntry>;
  inEditor?: boolean;
};

type ComponentWithController<P> = React.FunctionComponent<P> & {
  Controller: React.FunctionComponent<{
    render: (controlledProps: P) => JSX.Element;
  }>;
};

const KeyboardShortcutsMenu: ComponentWithController<Props> = ({
  open,
  onMenuToggle,
  entriesEditor = ENTRIES_EDITOR,
  entriesObjects = ENTRIES_OBJECTS,
  entriesMisc = ENTRIES_MISC,
  inEditor = false,
}) => {
  const small = !inEditor && !open;

  return (
    <div style={{ display: 'block' }}>
      <div
        className={classnames(styles.containerExtended, {
          [styles.small]: small,
          [styles.closed]: !open,
        })}
      >
        <div className={classnames(styles.menu, { [styles.open]: open })}>
          {entriesEditor.map((entry, index) => {
            const IconComponent = entry.icon;
            return (
              <div key={index} className={styles.entry}>
                <IconComponent size={16} />
                <div className={styles.title}>{entry.title}</div>
                <KeyboardShortcut>{entry.shortcut}</KeyboardShortcut>
              </div>
            );
          })}
        </div>
        <div className={classnames(styles.menu, { [styles.open]: open })}>
          {entriesObjects.map((entry, index) => {
            const IconComponent = entry.icon;
            return (
              <div key={index} className={styles.entry}>
                <IconComponent size={16} />
                <div className={styles.title}>{entry.title}</div>
                <KeyboardShortcut>{entry.shortcut}</KeyboardShortcut>
              </div>
            );
          })}
        </div>
        <div className={classnames(styles.menu, { [styles.open]: open })}>
          {entriesMisc.map((entry, index) => {
            const IconComponent = entry.icon;
            return (
              <div key={index} className={styles.entry}>
                <IconComponent size={16} />
                <div className={styles.title}>{entry.title}</div>
                <KeyboardShortcut>{entry.shortcut}</KeyboardShortcut>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classnames(styles.container, { [styles.small]: small })}>
        <div
          className={classnames(styles.menuToggle, { [styles.small]: small })}
          onClick={onMenuToggle}
        >
          <Icons.Keyboard size={16} />
          {!small ? (
            <div className={styles.title}>Keyboard Shortcuts</div>
          ) : (
            <div style={{ width: 4 }} />
          )}
          <Icons.ChevronToggleExpand size={18} />
        </div>
      </div>
    </div>
  );
};

KeyboardShortcutsMenu.Controller = ({ render }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onMenuToggle = useCallback(() => {
    return setOpen(!open);
  }, [open]);
  return render({ open, onMenuToggle });
};

export default KeyboardShortcutsMenu;
