import {
  CoreSpace,
  CoreSpaceHierarchyNode,
  CoreSpaceTimeSegment,
  CoreSpaceType,
} from '@densityco/lib-api-types';
import { useParams } from 'react-router';
import styles from './styles.module.scss';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import FillCenter from 'components/fill-center';
import { useAppSelector } from 'redux/store';
import {
  CoreAPI,
  ENTITY_PAGE_SIZE,
  FloorplanAPI,
  FloorplanV2PlanSummary,
  FloorplanV2Sensor,
} from 'lib/api';
import { Icons } from '@density/dust';
import { Link } from 'react-router-dom';
import { AxiosInstance } from 'axios';
import { LoadingBar } from './loading-bar';
import { BuildingInfo } from './building-info';
import { FloorInfo } from './floor-info';
import BuildingSettingsModal from './building-settings-modal';
import { SpaceBuildingManager } from './spaces-info';
import { AOC } from './aoc-info';
import { BuildingContext, BuildingProvider } from './building-context';

export interface FloorData {
  name: string;
  status: string;
  image: string | null;
  id: string;
  updatedAt: string | null;
  createdAt: string;
  sensors: FloorplanV2Sensor[];
  spaces: SpaceBuildingManager[];
  aoc: AOC[];
}

export type BuildingEntityFloor = SpaceBuildingManager & {
  floorId: string;
  floorName: string;
};

export type BuildingEntitySensor = FloorplanV2Sensor & {
  floorId: string;
  floorName: string;
};

export type V2Building = {
  id: string;
  name: string;
  address: string | null;
  time_zone: string;
  has_purview: boolean;
  space_type: CoreSpaceType;
  daily_reset: string;
  inherits_time_segments: boolean;
  time_segments: Array<CoreSpaceTimeSegment>;
  capacity: number | null;
  children?: Array<CoreSpaceHierarchyNode>;
};

export type BuildingEntityData = {
  floors: FloorData[];
  sensors: BuildingEntitySensor[];
  spaces: BuildingEntityFloor[];
  aoc: AOC[];
};

const BuildingManager: React.FunctionComponent = () => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const { buildingName, setBuildingName } = useContext(BuildingContext);

  const [floorData, setFloorData] = useState<{ status: string }>({
    status: 'pending',
  });
  const [totalEntities, setTotalEntities] = useState<number>(0);
  const [loadedSensors, setLoadedSensors] = useState<number>(0);
  const [loadedSpaces, setLoadedSpaces] = useState<number>(0);
  const [loadedAOC, setLoadedAOC] = useState<number>(0);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const [buildingData, setBuildingData] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'error' }
    | {
        status: 'complete';
        data: CoreSpaceHierarchyNode;
      }
  >({ status: 'pending' });
  const { buildingId, organizationId } = useParams<{
    buildingId: CoreSpace['id'];
    organizationId: CoreSpace['id'];
  }>();
  const [floors, setFloors] = useState<FloorplanV2PlanSummary[] | undefined>();
  const [data, setData] = useState<BuildingEntityData | undefined>();

  const getSpaceHierarchy = useCallback(
    (abortController: AbortController) => {
      if (!densityAPIClient) {
        return;
      }
      setBuildingData({ status: 'loading', abortController });
      CoreAPI.spacesHierarchyWithinSpace(densityAPIClient, buildingId)
        .then((response) => {
          let floorplanData: FloorplanV2PlanSummary[] = [];
          FloorplanAPI.listFloorplans(densityAPIClient).then((res) => {
            floorplanData = res.data.results.filter(
              (floor) => floor.floor.parent_id === buildingId
            );
            setFloors(floorplanData);
          });
          setBuildingData({
            status: 'complete',
            data: response.data,
          });
          setBuildingName(response.data.name);
        })
        .catch((err) => {
          if (err.name === 'CanceledError') {
            return;
          }
          setBuildingData({ status: 'error' });
        });
    },
    [buildingId, densityAPIClient, setBuildingName]
  );

  useEffect(() => {
    const abortController = new AbortController();
    getSpaceHierarchy(abortController);

    return () => {
      abortController.abort();
      setBuildingData({ status: 'pending' });
    };
  }, [densityAPIClient, buildingId, getSpaceHierarchy]);

  const updateSpace = useCallback(
    async (data) => {
      if (!densityAPIClient || !buildingId)
        return new Error('No client or building ID');
      if (data.address === '') data.address = null; // blank address must be null
      const res = await CoreAPI.updateSpace(densityAPIClient, buildingId, data);

      if (res.status !== 200) {
        return new Error('Error, bad request');
      } else {
        setBuildingName(res.data.name);
      }
    },
    [densityAPIClient, buildingId, setBuildingName]
  );

  async function fetchAllSensorsForFloor(
    client: AxiosInstance,
    floorId: string,
    floorName: string
  ): Promise<BuildingEntitySensor[]> {
    let page = 1;
    const sensors: BuildingEntitySensor[] = [];
    while (true) {
      try {
        const res = await FloorplanAPI.listSensors(client, floorId, page);
        const sensorData = res.data.results.map((sensor) => {
          return {
            ...sensor,
            mac: sensor.diagnostic_info?.mac,
            ip: sensor.diagnostic_info?.ipv4,
            floorId,
            floorName,
          };
        });
        setLoadedSensors((prevLoaded) => prevLoaded + res.data.results.length);
        sensors.push(...sensorData);
        page++;
        if (!res.data.next) {
          setTotalEntities(
            (prevTotal) => prevTotal - ENTITY_PAGE_SIZE + res.data.total
          );

          break;
        }
      } catch (err) {
        console.error(err);
      }
    }
    return sensors;
  }

  async function fetchAllAOCForFloor(
    client: AxiosInstance,
    floorId: string,
    floorName: string,
    floorStatus: string
  ): Promise<AOC[] & BuildingEntityFloor[]> {
    let page = 1;
    const areasOfCoverage: AOC[] & BuildingEntityFloor[] = [];
    while (true) {
      try {
        const res = await FloorplanAPI.listAreasOfConcern(
          client,
          floorId,
          page
        );
        const aocData = res.data.results.map((aoc) => {
          return { ...aoc, floorId, floorName, floorStatus };
        }) as unknown as AOC[] & BuildingEntityFloor[];
        areasOfCoverage.push(...aocData);
        setLoadedAOC((prevLoaded) => prevLoaded + res.data.results.length);
        if (!res.data.next) {
          setTotalEntities(
            (prevTotal) => prevTotal - ENTITY_PAGE_SIZE + res.data.total
          );

          break;
        }
        page++;
      } catch (err) {
        console.error(`Error fetching AOC for floor ${floorId}:`, err);
        break;
      }
    }
    return areasOfCoverage;
  }

  async function fetchAllSpacesForFloor(
    client: AxiosInstance,
    floorId: string,
    floorName: string
  ): Promise<BuildingEntityFloor[]> {
    let page = 1;
    const spaces: BuildingEntityFloor[] = [];
    while (true) {
      try {
        const res = await FloorplanAPI.listSpaces(client, floorId, page);
        const spacesData = res.data.results.map((space) => {
          return { ...space, floorId, floorName };
        }) as unknown as BuildingEntityFloor[];
        spaces.push(...spacesData);
        setLoadedSpaces((prevLoaded) => prevLoaded + res.data.results.length);
        if (!res.data.next) {
          setTotalEntities(
            (prevTotal) => prevTotal - ENTITY_PAGE_SIZE + res.data.total
          );
          break;
        }
        page++;
      } catch (err) {
        console.error(`Error fetching spaces for floor ${floorId}:`, err);
        break;
      }
    }
    return spaces;
  }

  useEffect(() => {
    if (!densityAPIClient || !floors) {
      return;
    }

    const fetchData = async () => {
      const apiData: BuildingEntityData = {
        floors: [],
        sensors: [],
        spaces: [],
        aoc: [],
      };
      setTotalEntities(floors.length * ENTITY_PAGE_SIZE * 3); // 3 requests per floor (sensors, spaces, and aoc)
      for (const floor of floors) {
        const floorData: FloorData = {
          name: floor.floor.name,
          status: floor.floor.status,
          image: floor.image_url,
          id: floor.id,
          updatedAt: floor.updated_at,
          createdAt: floor.created_at,
          sensors: [],
          spaces: [],
          aoc: [],
        };

        // Fetch sensors and spaces in parallel for each floor
        const [sensorData, spaceData, aocData] = await Promise.all([
          fetchAllSensorsForFloor(densityAPIClient, floor.id, floor.floor.name),
          fetchAllSpacesForFloor(densityAPIClient, floor.id, floor.floor.name),
          fetchAllAOCForFloor(
            densityAPIClient,
            floor.id,
            floor.floor.name,
            floor.floor.status
          ),
        ]);

        floorData.sensors = floorData.sensors.concat(sensorData);
        apiData.sensors = apiData.sensors.concat(sensorData);

        floorData.spaces = floorData.spaces.concat(spaceData);
        apiData.spaces = apiData.spaces.concat(spaceData);

        apiData.aoc = apiData.aoc.concat(aocData);
        floorData.aoc = floorData.aoc.concat(aocData);

        apiData.floors.push(floorData);
      }
      setData(apiData);
      setFloorData({ status: 'complete' });
    };

    fetchData().catch(console.error);
  }, [floors, densityAPIClient]);

  if (buildingData.status === 'loading') {
    return (
      <Fragment>
        <div className={styles.header} />
        <div className={styles.wrapper}>
          <FillCenter>Loading building data...</FillCenter>
        </div>
      </Fragment>
    );
  }
  if (buildingData.status === 'error') return null;
  if (buildingData.status === 'pending') return null;
  const header = (
    <div className={styles.header}>
      <Link to={`/${organizationId}/buildings`}>
        <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <Icons.ArrowLeftBack /> Back To Portfolio
        </span>
      </Link>

      <h1
        onClick={() => setShowSettingsModal(true)}
        className={styles.buildingName}
        data-cy="building-manager-building-name"
      >
        <Icons.WrenchTool /> <span>{buildingName} </span>
      </h1>

      <h3>Building Manager</h3>
    </div>
  );
  const loadPercentage =
    ((loadedSensors + loadedSpaces + loadedAOC) / totalEntities) * 100 || 0;
  return (
    <Fragment>
      {
        <BuildingSettingsModal
          visible={showSettingsModal}
          title={'Building metadata'}
          building={buildingData.data as V2Building}
          client={densityAPIClient}
          onDismiss={() => setShowSettingsModal(false)}
          updateSpace={updateSpace}
        />
      }
      {header}
      {floorData.status === 'pending' ? (
        <div className={styles.wrapper}>
          <div className={styles.loadingContainer}>
            <div>Loading sensor, space and area of coverage data</div>
            <LoadingBar percentage={loadPercentage} />
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <BuildingInfo data={data} />
          <FloorInfo
            data={data}
            client={densityAPIClient}
            orgId={organizationId}
          />
        </div>
      )}
    </Fragment>
  );
};

const BuildingManagerWithContext: React.FunctionComponent = () => {
  return (
    <BuildingProvider>
      <BuildingManager />
    </BuildingProvider>
  );
};

export default BuildingManagerWithContext;
