import * as React from 'react';
import {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { Dialogger } from '@densityco/ui';
import { toast } from 'react-toastify';
import { distance } from 'fastest-levenshtein';
import { Link, useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import Fuse from 'fuse.js';
import {
  CoreSpace,
  CoreSpaceStatus,
  CoreSpaceType,
  CoreOrganization,
  CoreUser,
  CoreSpaceTimeSegment,
} from '@densityco/lib-api-types';

import { useAppSelector } from 'redux/store';
import styles from './styles.module.scss';

import FillCenter from 'components/fill-center';
import ErrorMessage from 'components/error-message';
import Button from 'components/button';
import TextField from 'components/text-field';
import { DarkTheme } from 'components/theme';
import AppBar from 'components/app-bar';
import HorizontalForm from 'components/horizontal-form';
import Modal from 'components/modal';
import PopupListItem from 'components/popup-list-item/index';
import Switch from 'components/switch/switch';
import Popup from 'components/popup';
import EditableName from 'components/editable-name';
import NewFloorplanModal, {
  NewFloorplanModalParams,
} from 'components/new-floorplan-modal';

import { FixMe } from 'types/fixme';

import {
  FloorplanAPI,
  CoreAPI,
  Paginated,
  FloorplanV2PlanSummary,
  FloorplanV2ProjectLoginCode,
} from 'lib/api';
import { AsyncTaskStatus } from 'lib/redux';
import { Analytics } from 'lib/analytics';
import { SPLITS } from 'lib/treatments';
import { useTreatment } from 'contexts/treatments';
import { MapboxSessionProvider } from 'components/mapbox/mapbox-session';
import EditableAddress from 'components/editable-address';

type BuildingFloorplan =
  | {
      floorplanCreationPlaceholder: false;
      spaceId: CoreSpace['id'];
      planId: FloorplanV2PlanSummary['id'];
      name: string;
      timeZone: CoreSpace['time_zone'];
      capacity: CoreSpace['capacity'];
      status: CoreSpace['status'];
      updatedAt: string | null;
      thumbnailImageSrc: string | null;
    }
  // Creation placeholders represent spaces not associated with plans that can become
  // associated using this interface.
  | {
      floorplanCreationPlaceholder: true;
      spaceId: CoreSpace['id'];
      name: string;
      timeZone: CoreSpace['time_zone'];
      capacity: CoreSpace['capacity'];
      updatedAt: '1970-01-01T00:00:00Z'; // The old timestamp ensures these will be sorted last.
    };

type CoreSpaceHierarchyNode = {
  id: string;
  name: string;
  address?: string | null;
  time_zone?: string;
  has_purview: boolean;
  space_type: CoreSpaceType;
  daily_reset: string;
  inherits_time_segments: boolean;
  time_segments: Array<CoreSpaceTimeSegment>;
  capacity: number | null;
  children?: Array<CoreSpaceHierarchyNode>;
};

export type Building = {
  spaceId: string;
  name: string;
  address?: string | null;
  capacity?: number | null;
  dailyReset?: string | null;
  timeZone?: string;
  floorplans:
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error' }
    | { status: 'complete'; data: Array<BuildingFloorplan> };
};

type Project = Building & {
  organizationName: CoreOrganization['name'];
  expiresAt: string;
};

type NewBuildingPlaceholder =
  | { visible: false }
  | {
      visible: true;
      name: CoreSpace['name'];
      loading: boolean;
    };

function applyFuzzySearch<T extends object>(
  collection: Array<T>,
  searchText: string,
  fns: Array<(item: T, index: number) => string>
): Array<[T, number]> {
  if (searchText.length === 0) {
    return collection.map((item, index) => [item, index]);
  }
  const collectionWithIndex = collection.map((item, index) => ({
    item,
    index,
  }));

  // NOTE: I'm really not satisfied with this fuse.js library. `fuzzy`, my preferred library, wasn't
  // working and I couldn't figure out why (was always just returning an empty array).
  const fuse = new Fuse(collectionWithIndex, {
    threshold: 0.1,
    includeScore: true,
    keys: ['bogus string needed here to trigger getFn below'],
    getFn: ({ item, index }) => fns.map((fn) => fn(item, index)),
  });

  return fuse.search(searchText).map((i) => [i.item.item, i.item.index]);
}

type ShareModalParams =
  | {
      visible: true;
      building: Building;
    }
  | {
      visible: false;
      building: null;
    };
type ShareModalProps = ShareModalParams & {
  onDismiss: () => void;
};

const ShareModal: React.FunctionComponent<ShareModalProps> = ({
  visible,
  building,
  onDismiss,
}) => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const [loginCodeData, setLoginCodeData] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error' }
    | {
        status: 'complete';
        data: FloorplanV2ProjectLoginCode | null;
      }
  >({ status: 'pending' });
  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }
    if (!building) {
      setLoginCodeData({ status: 'pending' });
      return;
    }
    setLoginCodeData({ status: 'loading' });
    CoreAPI.getProjectLoginCode(densityAPIClient, building.spaceId)
      .then((response) => {
        // If the code exists but has expired, then consider the login code inactive
        const isProjectCodeValid =
          response.data.expires_at > moment.utc().format();
        if (isProjectCodeValid) {
          setEnabled(true);
          setLoginCodeData({ status: 'complete', data: response.data });
        } else {
          setEnabled(false);
          setLoginCodeData({ status: 'complete', data: null });
        }
      })
      .catch((err) => {
        // If the login code doesn't exist then don't throw an error
        if (err.response.status === 404) {
          setEnabled(false);
          setLoginCodeData({ status: 'complete', data: null });
        } else {
          setLoginCodeData({ status: 'error' });
        }
      });
  }, [densityAPIClient, building]);

  const [enabled, setEnabled] = useState(false);
  const onChangeEnabled = useCallback(
    (enabled: boolean) => {
      if (!densityAPIClient) {
        return;
      }
      if (!building) {
        return;
      }

      setEnabled(enabled);

      const oldLoginCodeData = loginCodeData;
      setLoginCodeData({ status: 'loading' });

      if (enabled) {
        // Create a new project login code
        const expiresAt = moment.utc().add(1, 'month').format('YYYY-MM-DD');
        CoreAPI.createProjectLoginCode(
          densityAPIClient,
          building.spaceId,
          expiresAt
        )
          .then((response) => {
            setLoginCodeData({ status: 'complete', data: response.data });
          })
          .catch(() => {
            // If creating a new code fails, try to reactivate an old one
            return CoreAPI.updateProjectLoginCode(
              densityAPIClient,
              building.spaceId,
              expiresAt
            )
              .then((response) => {
                setLoginCodeData({ status: 'complete', data: response.data });
              })
              .catch(() => {
                toast.error('Failed to enable sharing for building!');
                setEnabled(false);
                setLoginCodeData({ status: 'complete', data: null });
              });
          });
      } else {
        // To remove the existing project login code, make it's expiry date in the past
        CoreAPI.updateProjectLoginCode(
          densityAPIClient,
          building.spaceId,
          '1970-01-01'
        )
          .then((response) => {
            setLoginCodeData({ status: 'complete', data: null });
          })
          .catch(() => {
            toast.error('Failed to disable sharing for building!');
            setEnabled(true);
            setLoginCodeData(oldLoginCodeData);
          });
      }
    },
    [densityAPIClient, building, loginCodeData]
  );

  if (!building) {
    return null;
  }

  switch (loginCodeData.status) {
    case 'pending': {
      return (
        <Modal visible={visible} width={420} height={320}>
          <AppBar
            title={`Share ${building.name}`}
            actions={<Switch isDisabled isChecked={enabled} />}
          />
        </Modal>
      );
    }
    case 'loading': {
      return (
        <Modal visible={visible} width={420} height={320}>
          <AppBar
            title={`Share ${building.name}`}
            actions={<Switch isDisabled isChecked={enabled} />}
          />
        </Modal>
      );
    }
    case 'error': {
      return (
        <Modal visible={visible} width={420} height={320}>
          <AppBar
            title={`Share ${building.name}`}
            actions={<Switch isDisabled isChecked={enabled} />}
          />
          Error
        </Modal>
      );
    }
    case 'complete': {
      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={420}
          height={320}
        >
          <div>
            <AppBar
              title={`Share ${building.name}`}
              actions={
                <Switch
                  isDisabled={loginCodeData.status !== 'complete'}
                  isChecked={enabled}
                  onChange={(event) => {
                    const checked = event.currentTarget.checked;
                    onChangeEnabled(checked);
                  }}
                />
              }
            />
            {loginCodeData.data !== null ? (
              <pre>{JSON.stringify(loginCodeData.data, null, 2)}</pre>
            ) : (
              <p>No login code active</p>
            )}
          </div>
        </Modal>
      );
    }
  }
};

const BuildingItem: React.FunctionComponent<{
  building: Building;
  searchText?: string;
  nameEditLoading: boolean;
  onEditName?: (newName: string) => void;
  onEditAddress?: (newAddress: string, newTimeZone: string) => void;
  onClickShare: () => void;
  spaceIdsWithDeleteLoading: Array<CoreSpace['id']>;
  onClickDelete?: () => void;
  onClickNewFloorplan: () => void;
  onClickNewFloorplanFromMaps: () => void;
  onAddFloorplan: (buildingFloorplanPlaceholder: BuildingFloorplan) => void;
  onDeleteFloorplan?: (floorplan: BuildingFloorplan) => void;
}> = ({
  building,
  searchText,
  nameEditLoading,
  onEditName,
  onEditAddress,
  onClickShare,
  spaceIdsWithDeleteLoading,
  onClickDelete,
  onClickNewFloorplan,
  onClickNewFloorplanFromMaps,
  onAddFloorplan,
  onDeleteFloorplan,
}) => {
  const isProjectSharingEnabled = useTreatment(SPLITS.PROJECT_SHARING);
  const isMapsImportEnabled = useTreatment(SPLITS.MAPS_IMPORT);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const { organizationId } = useParams<{
    organizationId: CoreOrganization['id'];
  }>();

  const deleteLoading = spaceIdsWithDeleteLoading.includes(building.spaceId);

  const header = (
    <div className={styles.buildingHeader}>
      <div className={styles.buildingHeaderLeft}>
        {building.spaceId !== '' ? (
          <DarkTheme>
            <EditableName
              name={building.name}
              leadingIcon={<Icons.SpaceTypeBuilding size={18} />}
              placeholder="eg, 101 Front Ave"
              data-cy="building-name-editable"
              loading={nameEditLoading}
              onEditName={
                onEditName
                  ? (newName) => {
                      Analytics.track('Edit Building Name', {
                        spaceId: building.spaceId,
                      });
                      onEditName(newName);
                    }
                  : undefined
              }
            />
            <div className={styles.buildingAddressField}>
              <EditableAddress
                loading={nameEditLoading}
                buttons={true}
                theme="dark"
                placeholder={building.address || 'eg, 101 Front Ave'}
                data-cy="building-address-editable"
                address={building.address || 'No Address'}
                onEditAddress={
                  onEditAddress
                    ? (newAddress, newTimezone) => {
                        onEditAddress(newAddress, newTimezone);
                      }
                    : undefined
                }
                leadingIcon={
                  building.address ? (
                    <Icons.LocationPin size={14} />
                  ) : (
                    <Icons.Flag size={14} color={dust.Yellow200} />
                  )
                }
              />
              <span className={styles.timezoneText}>{building.timeZone}</span>
            </div>
          </DarkTheme>
        ) : (
          <div className={styles.buildingName}>Other</div>
        )}
      </div>
      <div className={styles.buildingHeaderRight}>
        <DarkTheme>
          {building.spaceId !== '' ? (
            <HorizontalForm size="medium">
              {isProjectSharingEnabled ? (
                <Button
                  type="cleared"
                  size="medium"
                  leadingIcon={<Icons.ShareArrowOutbox size={18} />}
                  disabled={deleteLoading}
                  onClick={() => onClickShare()}
                >
                  Share
                </Button>
              ) : null}
              <Button
                type="hollow"
                size="medium"
                leadingIcon={<Icons.Plus size={18} />}
                disabled={deleteLoading}
                onClick={() => {
                  Analytics.track('Create New Floorplan');
                  onClickNewFloorplan();
                }}
                data-cy="new-floorplan"
              >
                New Floorplan
              </Button>
              {isMapsImportEnabled ? (
                <Button
                  type="hollow"
                  size="medium"
                  leadingIcon={<Icons.Plus size={18} />}
                  disabled={deleteLoading}
                  onClick={() => onClickNewFloorplanFromMaps()}
                >
                  New Floorplan From Maps
                </Button>
              ) : null}
              <Popup
                open={moreMenuOpen}
                onClose={() => setMoreMenuOpen(false)}
                position={{ right: 0, top: 36 }}
                popupWidth={200}
                noPadding
                target={
                  <Button
                    type="cleared"
                    size="medium"
                    trailingIcon={<Icons.MoreMenuVertical size={18} />}
                    active={moreMenuOpen}
                    disabled={deleteLoading}
                    onClick={() => setMoreMenuOpen((open) => !open)}
                  />
                }
              >
                <div style={{ padding: dust.Space2 }}>
                  <PopupListItem
                    type="link"
                    to={`/${organizationId}/buildings/${building.spaceId}/`}
                    leadingIcon={<Icons.SearchMagnifier size={16} />}
                  >
                    Building Manager
                  </PopupListItem>
                  <PopupListItem
                    onClick={() => {
                      Analytics.track('Copy Space ID', {
                        spaceType: 'building',
                        location: 'buildingList',
                        spaceId: building.spaceId,
                      });
                      setMoreMenuOpen(false);
                      navigator.clipboard.writeText(building.spaceId);
                      toast.success('Copied to clipboard.');
                    }}
                    leadingIcon={<Icons.CopyDuplicate size={16} />}
                  >
                    Copy Space ID
                  </PopupListItem>
                  {onClickDelete ? (
                    <PopupListItem
                      danger
                      onClick={async () => {
                        if (deleteLoading) {
                          return;
                        }
                        if (building.floorplans.status !== 'complete') {
                          return;
                        }
                        const ok = await Dialogger.confirm({
                          title: 'Delete Building',
                          confirmText: 'Delete',
                          prompt: (
                            <Fragment>
                              Are you sure you would like to delete{' '}
                              <strong>{building.name}</strong> and the{' '}
                              <strong>
                                {building.floorplans.data.length} floors and all
                                plan sensors
                              </strong>{' '}
                              inside? You will <strong>not</strong> be able to
                              undo this action.
                            </Fragment>
                          ),
                        });

                        if (!ok) {
                          return;
                        }

                        const code: string | null = await Dialogger.prompt({
                          title: 'Delete Building Confirmation',
                          prompt: 'Type "delete" to proceed with the deletion.',
                        });

                        if (code !== 'delete') {
                          toast.warn('Action aborted.');
                          return;
                        }

                        setMoreMenuOpen(false);
                        onClickDelete();
                      }}
                      leadingIcon={<Icons.Trash size={16} />}
                    >
                      Delete Building
                    </PopupListItem>
                  ) : null}
                </div>
              </Popup>
            </HorizontalForm>
          ) : null}
        </DarkTheme>
      </div>
    </div>
  );

  let contents: React.ReactNode = null;
  switch (building.floorplans.status) {
    case 'loading':
      // FIXME: add proper loading state here
      contents = <p>Loading building...</p>;
      break;
    case 'error':
      // FIXME: add proper error state here
      contents = <p>Error loading building!</p>;
      break;
    case 'complete':
      if (building.floorplans.data.length === 0) {
        contents = (
          <div className={styles.buildingFloorplanListEmpty}>
            {building.spaceId === ''
              ? 'No unassociated floorplans'
              : 'No floors in this building'}
          </div>
        );
      } else {
        let floorplanList = building.floorplans.data.sort((a, b) =>
          (b.updatedAt || '').localeCompare(a.updatedAt || '')
        );

        // Apply fuzzy searching if a search string was specified
        if (typeof searchText === 'string') {
          const searchResults = applyFuzzySearch(floorplanList, searchText, [
            (floorplan) => floorplan.name,
          ]).map(([item]) => item);
          if (searchResults.length > 0) {
            floorplanList = searchResults;
          }
        }

        contents = (
          <div className={styles.buildingFloorplanList}>
            {floorplanList.map((floorplan) => (
              <BuildingFloorplanItem
                key={floorplan.spaceId}
                floorplan={floorplan}
                deleteLoading={
                  deleteLoading ||
                  spaceIdsWithDeleteLoading.includes(floorplan.spaceId)
                }
                onAddFloorplan={() => onAddFloorplan(floorplan)}
                onDeleteFloorplan={
                  onDeleteFloorplan
                    ? () => {
                        if (onDeleteFloorplan) {
                          onDeleteFloorplan(floorplan);
                        }
                      }
                    : undefined
                }
              />
            ))}
          </div>
        );
      }
      break;
  }

  return (
    <div
      className={styles.building}
      key={building.spaceId}
      data-cy="building-item"
      data-cy-building-name={building.name}
      data-cy-building-address={building.address}
    >
      {header}
      {contents}
    </div>
  );
};

const ProjectItem: React.FunctionComponent<{
  project: Project;
  searchText?: string;
  onClickDetach: () => void;
}> = ({ project, searchText, onClickDetach }) => {
  const header = (
    <div className={styles.buildingHeader}>
      <div className={styles.buildingHeaderLeft}>
        <Icons.CategoriesObjectsShapesElements size={18} />
        <div className={styles.buildingName}>
          {project.organizationName}
          <Icons.ChevronRight size={24} />
          {project.name}
        </div>
      </div>
      <div className={styles.buildingHeaderRight}>
        <DarkTheme>
          <HorizontalForm size="medium">
            <div className={styles.buildingExpiresAt}>
              Expires at{' '}
              {moment(project.expiresAt).format('MM/DD/YYYY hh:mm a')}
            </div>
            <Button
              type="hollow"
              size="medium"
              leadingIcon={<Icons.LinkBroken size={18} />}
              onClick={() => onClickDetach()}
            >
              Detach Project
            </Button>
          </HorizontalForm>
        </DarkTheme>
      </div>
    </div>
  );

  let contents: React.ReactNode = null;
  switch (project.floorplans.status) {
    case 'loading':
      contents = (
        // FIXME: add proper loading state here
        <p>Loading project...</p>
      );
      break;
    case 'error':
      // FIXME: add proper error state here
      contents = <p>Error loading project!</p>;
      break;
    case 'complete':
      if (project.floorplans.data.length === 0) {
        contents = (
          <div className={styles.buildingFloorplanListEmpty}>
            No floors in this project
          </div>
        );
      } else {
        let floorplanList = project.floorplans.data.sort((a, b) =>
          (b.updatedAt || '').localeCompare(a.updatedAt || '')
        );

        // Apply fuzzy searching if a search string was specified
        if (typeof searchText === 'string') {
          const searchResults = applyFuzzySearch(floorplanList, searchText, [
            (floorplan) => floorplan.name,
          ]).map(([item]) => item);
          if (searchResults.length > 0) {
            floorplanList = searchResults;
          }
        }

        contents = (
          <div className={styles.buildingFloorplanList}>
            {floorplanList.map((floorplan) => (
              <BuildingFloorplanItem
                key={floorplan.spaceId}
                floorplan={floorplan}
              />
            ))}
          </div>
        );
      }
      break;
  }

  return (
    <div className={styles.building} key={project.spaceId}>
      {header}
      {contents}
    </div>
  );
};

const BuildingFloorplanItem: React.FunctionComponent<{
  floorplan: BuildingFloorplan;
  deleteLoading?: boolean;
  onAddFloorplan?: () => void;
  onDeleteFloorplan?: () => void;
}> = ({
  floorplan,
  deleteLoading = false,
  onAddFloorplan,
  onDeleteFloorplan,
}) => {
  const { organizationId } = useParams<{
    organizationId: CoreOrganization['id'];
  }>();

  const [moreMenuOpen, setMoreMenuOpen] = useState(false);

  if (floorplan.floorplanCreationPlaceholder) {
    return (
      <button
        className={classnames(styles.floorplanItem, styles.creationPlaceholder)}
        key={floorplan.spaceId}
        onClick={() => {
          if (!onAddFloorplan) {
            return;
          }
          Analytics.track('Add Floorplan', { spaceId: floorplan.spaceId });
          onAddFloorplan();
        }}
        data-cy="building-floorplan-item"
        data-cy-floorplan-name={floorplan.name}
        data-cy-is-creation-placeholder={true}
      >
        <div className={styles.floorplanItemThumbnail}>
          <Icons.PapersDocs size={24} />
        </div>

        <div className={styles.floorplanItemDetails}>
          <div className={styles.floorplanItemDetailsName}>
            {floorplan.name}
          </div>
          <div className={styles.floorplanItemDetailsLineTwo}>
            Click to add floorplan...
          </div>
        </div>
      </button>
    );
  } else if (deleteLoading) {
    return (
      <div
        className={classnames(styles.floorplanItem, styles.deleting)}
        key={floorplan.spaceId}
        data-cy="building-floorplan-item"
        data-cy-floorplan-name={floorplan.name}
        data-cy-is-creation-placeholder={false}
      >
        <div
          className={styles.floorplanItemThumbnail}
          style={{
            backgroundImage: `url(${floorplan.thumbnailImageSrc})`,
          }}
        >
          {!floorplan.thumbnailImageSrc ? <Icons.PapersDocs size={24} /> : null}
        </div>

        <div className={styles.floorplanItemDetails}>
          <div className={styles.floorplanItemDetailsName}>
            {floorplan.name}
          </div>
          <div className={styles.floorplanItemDetailsLineTwo}>Deleting...</div>
        </div>
      </div>
    );
  } else {
    return (
      <Link
        className={styles.floorplanItem}
        key={floorplan.spaceId}
        to={`/${organizationId}/floorplans/${floorplan.planId}`}
        data-cy="building-floorplan-item"
        data-cy-floorplan-name={floorplan.name}
        data-cy-is-creation-placeholder={false}
      >
        <div
          className={styles.floorplanItemThumbnail}
          style={{
            backgroundImage: `url(${floorplan.thumbnailImageSrc})`,
          }}
        >
          {!floorplan.thumbnailImageSrc ? <Icons.PapersDocs size={24} /> : null}
        </div>

        <div className={styles.floorplanItemDetails}>
          <div className={styles.floorplanItemDetailsName}>
            {floorplan.name}
          </div>
          <div className={styles.floorplanItemDetailsLineTwo}>
            Updated: {moment(floorplan.updatedAt).format('MMM Do, YYYY')}
            <div
              className={classnames(styles.floorplanItemDetailsLineTwoTag, {
                [styles.dimmed]: floorplan.status === 'live',
              })}
            >
              {floorplan.status === 'live' ? 'Live' : 'Planning'}
            </div>
          </div>
        </div>

        <div
          className={styles.floorplanItemAction}
          onClick={(e) => {
            // NOTE: call preventDefault and stopPropagation here to keep clicking of the
            // more button from triggering the .floorplanItem `Link` component
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <DarkTheme>
            <Popup
              open={moreMenuOpen}
              onClose={() => setMoreMenuOpen(false)}
              position={{ right: 0, top: 36 }}
              popupWidth={200}
              noPadding
              target={
                <Button
                  size="medium"
                  type="hollow"
                  trailingIcon={
                    <Icons.MoreMenuVertical size={18} color={dust.Gray200} />
                  }
                  width={32}
                  height={32}
                  active={moreMenuOpen}
                  onClick={(e) => setMoreMenuOpen((o) => !o)}
                />
              }
            >
              <div style={{ padding: dust.Space2 }}>
                <PopupListItem
                  onClick={() => {
                    Analytics.track('Copy Space ID', {
                      spaceType: 'floor',
                      location: 'buildingList',
                      spaceId: floorplan.spaceId,
                    });
                    setMoreMenuOpen(false);
                    navigator.clipboard.writeText(floorplan.spaceId);
                    toast.success('Copied to clipboard.');
                  }}
                  leadingIcon={<Icons.CopyDuplicate size={16} />}
                >
                  Copy Space ID
                </PopupListItem>
                {onDeleteFloorplan ? (
                  <PopupListItem
                    danger
                    onClick={async () => {
                      const ok = await Dialogger.confirm({
                        title: 'Delete Floorplan',
                        confirmText: 'Delete',
                        prompt: (
                          <Fragment>
                            Are you sure you would like to delete{' '}
                            <strong>{floorplan.name}</strong> and all Plan
                            Sensors it contains? You will <strong>not</strong>{' '}
                            be able to undo this action.
                          </Fragment>
                        ),
                      });

                      if (!ok) {
                        return;
                      }

                      const code: string | null = await Dialogger.prompt({
                        title: 'Delete Floorplan Confirmation',
                        prompt: 'Type "delete" to proceed with the deletion.',
                      });

                      if (code !== 'delete') {
                        toast.warn('Action aborted.');
                        return;
                      }

                      setMoreMenuOpen(false);
                      onDeleteFloorplan();
                    }}
                    leadingIcon={<Icons.Trash size={16} />}
                  >
                    Delete Floorplan
                  </PopupListItem>
                ) : null}
              </div>
            </Popup>
          </DarkTheme>
        </div>
      </Link>
    );
  }
};

const NewBuildingItem: React.FunctionComponent<{
  newBuildingPlaceholder: NewBuildingPlaceholder;
  onChangeName: (text: CoreSpace['name']) => void;
  onDismiss: () => void;
  onSubmit: () => void;
}> = ({ newBuildingPlaceholder, onChangeName, onDismiss, onSubmit }) => {
  const nameTextFieldRef = useRef<HTMLInputElement | null>(null);

  // When first rendering the component or when the name changes externally, focus the input
  useEffect(() => {
    if (!nameTextFieldRef.current) {
      return;
    }
    nameTextFieldRef.current.focus();
  }, [newBuildingPlaceholder]);

  if (!newBuildingPlaceholder.visible) {
    return null;
  }

  return (
    <div className={styles.building}>
      <div className={styles.buildingHeader}>
        <div className={styles.buildingHeaderLeft}>
          <Icons.SpaceTypeBuilding size={18} />
          <div className={styles.buildingName}>
            <DarkTheme>
              <HorizontalForm size="medium">
                <TextField
                  ref={nameTextFieldRef}
                  size="medium"
                  placeholder="eg. 123 Oak Street"
                  value={newBuildingPlaceholder.name}
                  onChange={(event) => onChangeName(event.currentTarget.value)}
                  width={300}
                  disabled={newBuildingPlaceholder.loading}
                  onKeyDown={(event) => {
                    switch (event.key) {
                      case 'Escape':
                        onDismiss();
                        break;
                      case 'Enter':
                        onSubmit();
                        break;
                    }
                  }}
                  data-cy="new-building-name"
                />
                <Button
                  size="medium"
                  type="hollow"
                  onClick={(e) => onDismiss()}
                  data-cy="new-building-cancel"
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  disabled={newBuildingPlaceholder.loading}
                  onClick={onSubmit}
                  data-cy="new-building-submit"
                >
                  {newBuildingPlaceholder.loading ? 'Loading...' : 'Create'}
                </Button>
              </HorizontalForm>
            </DarkTheme>
          </div>
        </div>
      </div>
      <div className={styles.buildingFloorplanListEmpty}>
        No floors in this building
      </div>
    </div>
  );
};

const OrganizationCreationForm: React.FunctionComponent<{
  existingOrganizations:
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error' }
    | { status: 'complete'; data: Array<CoreOrganization> };
  onRenameOrganization?: (
    organizationId: CoreOrganization['id'],
    newName: CoreOrganization['name']
  ) => void;
  onOrganizationCreated: (
    organization: CoreOrganization & { service_user: CoreUser }
  ) => void;
  onNavigateToOrganization: (organization: CoreOrganization) => void;
  onBack: () => void;
}> = ({
  existingOrganizations,
  onRenameOrganization,
  onOrganizationCreated,
  onNavigateToOrganization,
  onBack,
}) => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const [organizationName, setOrganizationName] = useState('');
  const [loading, setLoading] = useState(false);

  // Provide a hint to the user if they are tryign to create an organization that already exists so
  // that they don't create a duplicate one
  const existingOrganizationDuplicate = useMemo(() => {
    if (existingOrganizations.status !== 'complete') {
      return null;
    }

    // There needs to be enough characters typed to have confidence in a match
    if (organizationName.length < 4) {
      return null;
    }

    // Find the closest organization name to what was typed
    let resultDistance = Infinity;
    let result: CoreOrganization | null = null;
    for (const organization of existingOrganizations.data) {
      const d = distance(
        organization.name.toLowerCase(),
        organizationName.toLowerCase()
      );
      if (d < resultDistance) {
        resultDistance = d;
        result = organization;
      }
    }

    // Filter out results that are too dissimilar to the typed organization name
    const acceptableDistance = Math.round(organizationName.length / 3);
    if (resultDistance > acceptableDistance) {
      return null;
    }

    return result;
  }, [existingOrganizations, organizationName]);

  const onCreateOrganization = useCallback(
    (name: CoreOrganization['name']) => {
      if (!densityAPIClient) {
        return;
      }

      setLoading(true);
      CoreAPI.createOrganizationWithServiceUser(densityAPIClient, name)
        .then((response) => {
          setLoading(false);
          toast.success('Created organization');
          onOrganizationCreated(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast.error('Failed to create organization!');
          console.error(`Failed to create organization: ${error}`);
        });
    },
    [densityAPIClient, onOrganizationCreated]
  );

  return (
    <Fragment>
      <div className={styles.organizationCreationAppBar}>
        <Button
          size="medium"
          type="hollow"
          trailingIcon={<Icons.ArrowLeftBack size={16} />}
          onClick={() => onBack()}
        />
        Create Organization
      </div>
      <br />
      Name:
      <TextField
        size="large"
        placeholder="eg. Acme Inc"
        value={organizationName}
        onChange={(event) => setOrganizationName(event.currentTarget.value)}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Escape':
              onBack();
              break;

            case 'Enter':
              // When enter is pressed, perform the default action in the form
              // TODO: make sure that pressing enter to navigate to the the organization is the ideal thing
              if (existingOrganizationDuplicate) {
                onNavigateToOrganization(existingOrganizationDuplicate);
              } else {
                onCreateOrganization(organizationName);
              }
              break;
          }
        }}
        data-cy="organization-creator-name"
      />
      <div className={styles.organizationCreationExistingOrganizationHint}>
        {existingOrganizationDuplicate ? (
          <Fragment>
            <div
              className={
                styles.organizationCreationExistingOrganizationHintText
              }
            >
              <strong>{existingOrganizationDuplicate.name}</strong> already
              exists
            </div>
            <HorizontalForm size="small">
              {onRenameOrganization ? (
                <Button
                  size="small"
                  type="cleared"
                  onClick={() =>
                    onRenameOrganization(
                      existingOrganizationDuplicate.id,
                      organizationName
                    )
                  }
                  trailingIcon={<Icons.PencilOutlineEditor size={14} />}
                />
              ) : null}
              <Button
                size="small"
                onClick={() =>
                  onNavigateToOrganization(existingOrganizationDuplicate)
                }
                data-cy="organization-creator-suggestion-select"
              >
                Select
              </Button>
            </HorizontalForm>
          </Fragment>
        ) : null}
      </div>
      <div className={styles.organizationCreationActions}>
        <HorizontalForm size="medium">
          <Button
            size="medium"
            type="cleared"
            onClick={() => onBack()}
            data-cy="organization-creator-cancel"
          >
            Cancel
          </Button>
          <Button
            size="medium"
            type={existingOrganizationDuplicate ? 'hollow' : 'filled'}
            disabled={loading || organizationName.length === 0}
            onClick={() => onCreateOrganization(organizationName)}
            data-cy="organization-creator-submit"
          >
            {loading ? 'Loading...' : 'Create'}
          </Button>
        </HorizontalForm>
      </div>
    </Fragment>
  );
};

const OrganizationPicker: React.FunctionComponent<{
  organization:
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error' }
    | {
        status: 'complete';
        id: CoreOrganization['id'] | null;
        name: CoreOrganization['name'] | null;
      };
  creationEnabled: boolean;
  renameEnabled: boolean;
}> = ({ organization, creationEnabled, renameEnabled }) => {
  const history = useHistory();
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const [organizationPopupOpen, setOrganizationPopupOpen] = useState(false);
  const [creationFormVisible, setCreationFormVisible] = useState(false);
  const [organizationNameEditActive, setOrganizationNameEditActive] =
    useState(false);

  const searchTextFieldRef = useRef<HTMLInputElement | null>(null);
  const organizationListRef = useRef<HTMLDivElement | null>(null);

  // Keep track of the index of the organization that is currently focused
  const [focusedOrganizationIndex, setFocusedOrganizationIndex] = useState(-1);
  const onChangeFocusedOrganizationIndex = useCallback(
    (setter: ((old: number) => number) | number, skipScroll = false) => {
      setFocusedOrganizationIndex((oldIndex) => {
        // If the organization name is currently being edited, then don't allow the focused index to change
        if (organizationNameEditActive) {
          return oldIndex;
        }

        const newIndex = typeof setter === 'number' ? setter : setter(oldIndex);
        if (newIndex < 0) {
          return newIndex;
        }

        if (skipScroll) {
          return newIndex;
        }

        // After changing the index, make sure that the organization list item element is visible
        if (!organizationListRef.current) {
          return newIndex;
        }
        const organizationListItemNode =
          organizationListRef.current.children[newIndex];
        if (!organizationListItemNode) {
          return newIndex;
        }
        organizationListItemNode.scrollIntoView({ block: 'nearest' });
        return newIndex;
      });
    },
    [organizationNameEditActive]
  );

  // When the popup opens, focus the search box
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (!organizationPopupOpen) {
      return;
    }
    if (creationFormVisible) {
      return;
    }
    if (!searchTextFieldRef.current) {
      return;
    }
    searchTextFieldRef.current.focus();
  }, [organizationPopupOpen, creationFormVisible]);

  // When the popup closes, reset the creation form visibility and clear the search box
  useEffect(() => {
    if (organizationPopupOpen) {
      return;
    }
    // NOTE: this setTimeout ensures that the form "flips" after the popup has fully faded out
    // Otherwise it looks a little janky
    // setTimeout(() => {
    setCreationFormVisible(false);
    setFocusedOrganizationIndex(0);
    setSearchText('');
    // }, 100);
  }, [organizationPopupOpen]);

  const onRenameOrganization = useCallback(
    (
      organizationId: CoreOrganization['id'],
      newName: CoreOrganization['name']
    ) => {
      console.log('Rename:', organizationId, newName);
    },
    []
  );

  const [organizations, setOrganizations] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error' }
    | { status: 'complete'; data: Array<CoreOrganization> }
  >({ status: 'pending' });
  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }
    if (!organizationPopupOpen) {
      return;
    }
    if (
      organizations.status === 'complete' ||
      organizations.status === 'error'
    ) {
      return;
    }

    setOrganizations({ status: 'loading' });
    CoreAPI.listOrganizations(densityAPIClient)
      .then((response) => {
        // Set the item that is currently highlighted / focused in the list - the first one
        onChangeFocusedOrganizationIndex(0, true);

        setOrganizations({
          status: 'complete',
          data: response.data,
        });
      })
      .catch((err) => {
        setOrganizations({ status: 'error' });
      });
  }, [
    organizationPopupOpen,
    densityAPIClient,
    organizations.status,
    onChangeFocusedOrganizationIndex,
  ]);

  const filteredOrganizationList = useMemo(() => {
    if (organizations.status !== 'complete') {
      return [];
    }

    return applyFuzzySearch(organizations.data, searchText, [
      (organization) => organization.name,
    ]).map(([organization]) => organization);
  }, [organizations, searchText]);

  useEffect(() => {
    // If the filtered organization list has items in it but none of them are focused, focus the
    // first one
    if (
      filteredOrganizationList.length > 0 &&
      focusedOrganizationIndex === -1
    ) {
      setFocusedOrganizationIndex(0);
    }
    // If the filtered organization list has ended up empty, then clear the focused index
    if (filteredOrganizationList.length === 0 && focusedOrganizationIndex > 0) {
      setFocusedOrganizationIndex(-1);
    }
  }, [filteredOrganizationList, focusedOrganizationIndex]);

  const [workingOrganizationName, setWorkingOrganizationName] = useState('');
  const organizationNameEditTextFieldRef = useRef<HTMLInputElement | null>(
    null
  );
  // When entering the organization name edit mode, update the working organization name with the
  // name from the organization that was focused
  useEffect(() => {
    if (!organizationNameEditActive) {
      return;
    }
    const organization = filteredOrganizationList[focusedOrganizationIndex];
    if (!organization) {
      return;
    }
    setWorkingOrganizationName(organization.name);
  }, [
    organizationNameEditActive,
    filteredOrganizationList,
    focusedOrganizationIndex,
  ]);
  // When going into the organization name edit mode, focus and select the text box
  useEffect(() => {
    if (!organizationNameEditActive) {
      return;
    }
    // NOTE: do the focus / select in a setTimeout because the edit state needs to show up first
    // before focusing the text field will have any effect. There is probably a better way to do
    // this...
    setTimeout(() => {
      if (!organizationNameEditTextFieldRef.current) {
        return;
      }
      organizationNameEditTextFieldRef.current.focus();
      organizationNameEditTextFieldRef.current.select();
    }, 0);
  }, [organizationNameEditActive]);
  // When going out of the organization name edit mode, focus the search box
  useEffect(() => {
    if (organizationNameEditActive) {
      return;
    }
    if (!searchTextFieldRef.current) {
      return;
    }
    searchTextFieldRef.current.focus();
  }, [organizationNameEditActive]);

  const [SelectedOrganizationIcon, selectedOrganizationName] = useMemo(() => {
    switch (organization.status) {
      case 'pending':
      case 'loading':
        return [Icons.Loading, 'Loading...'];
      case 'error':
        return [Icons.DangerWarningFill, 'Error loading!'];
      case 'complete':
        if (!organization.name) {
          return [Icons.DangerWarningFill, 'Error loading!'];
        } else {
          return [Icons.ImpersonateOnFillSecurityShieldSafe, organization.name];
        }
    }
  }, [organization]);

  return (
    <DarkTheme>
      <Popup
        // open={true}
        // onClose={() => {}}
        open={organizationPopupOpen}
        onClose={() => setOrganizationPopupOpen(false)}
        position={{ left: 12, top: 48 }}
        popupWidth={320}
        target={
          <Button
            type="cleared"
            leadingIcon={<SelectedOrganizationIcon size={20} />}
            trailingIcon={<Icons.ChevronDown size={20} />}
            active={organizationPopupOpen}
            onClick={() => setOrganizationPopupOpen((open) => !open)}
            data-cy="organization-picker-target"
          >
            {selectedOrganizationName}
          </Button>
        }
      >
        {creationFormVisible ? (
          <OrganizationCreationForm
            existingOrganizations={organizations}
            onBack={() => setCreationFormVisible(false)}
            onOrganizationCreated={(newOrganization) => {
              history.push(`/${newOrganization.id}`);
            }}
            onNavigateToOrganization={(newOrganization) => {
              if (organization.status !== 'complete') {
                return;
              }
              if (newOrganization.id !== organization.id) {
                history.push(`/${newOrganization.id}`);
              }
              setOrganizationPopupOpen(false);
            }}
            onRenameOrganization={
              renameEnabled
                ? (organizationId, newName) => {
                    if (organizations.status !== 'complete') {
                      return;
                    }

                    // Make all organizations visible by getting rid of any filtering
                    setSearchText('');

                    // Go back to the main organization list page
                    setCreationFormVisible(false);

                    // FIXME: This code must run after the creation form has been hidden so that
                    // `onChangeFocusedOrganizationIndex` can scroll the list view to make the given
                    // organization visible. There is probably a better way to do this...
                    setTimeout(() => {
                      const index = organizations.data.findIndex(
                        (o) => o.id === organizationId
                      );
                      if (index < 0) {
                        toast.error(
                          `Cannot find organization ${organizationId} in list!`
                        );
                        return;
                      }
                      onChangeFocusedOrganizationIndex(index);

                      // Go into edit mode
                      setOrganizationNameEditActive(true);
                    }, 0);
                  }
                : undefined
            }
          />
        ) : (
          <Fragment>
            <div className={styles.organizationAppBar}>
              <div className={styles.organizationAppBarSearch}>
                <TextField
                  size="medium"
                  leadingIcon={
                    <Icons.SearchMagnifier size={16} color={dust.Gray400} />
                  }
                  placeholder="eg. Density Inc"
                  value={searchText}
                  onChange={(event) => setSearchText(event.currentTarget.value)}
                  ref={searchTextFieldRef}
                  onKeyDown={(event) => {
                    switch (event.key) {
                      case 'Escape':
                        setOrganizationPopupOpen(false);
                        break;

                      case 'Enter':
                        const selectedOrganization =
                          filteredOrganizationList[focusedOrganizationIndex];
                        if (selectedOrganization) {
                          setOrganizationPopupOpen(false);
                          history.push(`/${selectedOrganization.id}`);
                        }
                        break;

                      case 'ArrowUp':
                        onChangeFocusedOrganizationIndex((index) => {
                          switch (index) {
                            case -1:
                              return 0;
                            case 0:
                              // Wrap around to the end of the list if up is pressed at the top of the
                              // list
                              return filteredOrganizationList.length - 1;
                            default:
                              return index - 1;
                          }
                        });
                        break;

                      case 'ArrowDown':
                        onChangeFocusedOrganizationIndex((index) => {
                          switch (index) {
                            case -1:
                              return filteredOrganizationList.length - 1;
                            case filteredOrganizationList.length - 1:
                              // Wrap around to the start of the list if down is pressed at the bottom of the
                              // list
                              return 0;
                            default:
                              return index + 1;
                          }
                        });
                        break;
                    }
                  }}
                  data-cy="organization-picker-search"
                />
              </div>
              {creationEnabled ? (
                <Button
                  size="medium"
                  trailingIcon={<Icons.Plus size={16} />}
                  onClick={() => setCreationFormVisible(true)}
                  data-cy="organization-picker-new"
                />
              ) : null}
            </div>
            {organizations.status === 'pending' ||
            organizations.status === 'loading' ? (
              <div className={styles.organizationLoading}>
                <FillCenter>Loading organizations...</FillCenter>
              </div>
            ) : null}
            {organizations.status === 'error' ? (
              <div
                className={styles.organizationError}
                data-cy="organization-picker-error"
              >
                <FillCenter>Error loading organizations!</FillCenter>
              </div>
            ) : null}
            {organizations.status === 'complete' &&
            filteredOrganizationList.length > 0 ? (
              <div
                className={styles.organizationList}
                ref={organizationListRef}
                data-cy="organization-picker-list"
              >
                {filteredOrganizationList.map((organization, index) => {
                  const isActive = index === focusedOrganizationIndex;

                  // Show the special "name edit state" when the name edit mode is active
                  if (isActive && organizationNameEditActive) {
                    return (
                      <PopupListItem key={organization.id}>
                        <TextField
                          size="small"
                          placeholder="eg. Acme Inc"
                          value={workingOrganizationName}
                          onChange={(event) =>
                            setWorkingOrganizationName(
                              event.currentTarget.value
                            )
                          }
                          width="100%"
                          ref={organizationNameEditTextFieldRef}
                          onKeyDown={(event) => {
                            switch (event.key) {
                              case 'Escape':
                                setOrganizationNameEditActive(false);
                                break;
                              case 'Enter':
                                setOrganizationNameEditActive(false);
                                onRenameOrganization(
                                  organization.id,
                                  workingOrganizationName
                                );
                                break;
                            }
                          }}
                        />
                        <div className={styles.organizationListItemAction}>
                          <Button
                            size="small"
                            onClick={() => {
                              setOrganizationNameEditActive(false);
                              onRenameOrganization(
                                organization.id,
                                workingOrganizationName
                              );
                            }}
                          >
                            Rename
                          </Button>
                        </div>
                      </PopupListItem>
                    );
                  }

                  return (
                    <PopupListItem
                      type="link"
                      key={organization.id}
                      active={isActive}
                      to={`/${organization.id}`}
                      onMouseEnter={() =>
                        onChangeFocusedOrganizationIndex(index, true)
                      }
                    >
                      {organization.name}
                      {renameEnabled && isActive ? (
                        <div className={styles.organizationListItemAction}>
                          <Button
                            size="small"
                            type="cleared"
                            width={24}
                            height={24}
                            trailingIcon={
                              <Icons.PencilOutlineEditor size={14} />
                            }
                            onClick={(event) => {
                              // NOTE: call event.preventDefault and event.stopPropagation here
                              // because clicking on the pencil button shouldn't activate the
                              // .organizationListItem Link element
                              event.preventDefault();
                              event.stopPropagation();
                              setOrganizationNameEditActive(true);
                            }}
                          />
                        </div>
                      ) : null}
                    </PopupListItem>
                  );
                })}
              </div>
            ) : null}
            {organizations.status === 'complete' &&
            filteredOrganizationList.length === 0 ? (
              <div className={styles.organizationEmpty}>
                <FillCenter>No organizations found.</FillCenter>
              </div>
            ) : null}
          </Fragment>
        )}
      </Popup>
    </DarkTheme>
  );
};

const BuildingList: React.FunctionComponent = () => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const isProjectSharingEnabled = useTreatment(SPLITS.PROJECT_SHARING);
  const isDeleteEnabled = useTreatment(SPLITS.LIST_PAGE_DELETE);

  const user = useAppSelector((state) => state.user);
  const organization = useMemo(() => {
    switch (user.status) {
      case AsyncTaskStatus.Uninitialized:
        return { status: 'pending' as const };
      case AsyncTaskStatus.Loading:
      case AsyncTaskStatus.Fetching:
        return { status: 'loading' as const };
      case AsyncTaskStatus.Success:
        return {
          status: 'complete' as const,
          id: user.data ? user.data.organization.id : null,
          name: user.data ? user.data.organization.name : null,
        };
      case AsyncTaskStatus.Failure:
        return { status: 'error' as const };
    }
  }, [user]);

  const permissions = useAppSelector(
    (state) => state.auth.tokenCheckResponse?.permissions || []
  );
  const userCanImpersonate = permissions.includes('impersonate');
  const userCanCreateOrganizations = permissions.includes(
    'create_organizations'
  );

  // When new buildings are created, their ids are added to this list so that they are sorted to be
  // at the top of the building list
  const [newlyCreatedBuildingIds, setNewlyCreatedBuildingIds] = useState<
    Array<CoreSpace['id']>
  >([]);

  const [addFloorplanModalParams, setNewFloorplanModalParams] =
    useState<NewFloorplanModalParams>({
      visible: false,
      mapsImport: false,
      parentBuildingId: null,
      existingFloorMetadata: null,
      initiallyLoadedFloorplanImage: null,
      initiallyLoadedFloorplanImageScale: null,
    });

  const [shareModalParams, setShareModalParams] = useState<ShareModalParams>({
    visible: false,
    building: null,
  });

  // The "new building placeholder" manages the creation workflow for new buildings
  // which is a fake placeholder item shown at the top of the page that has a text box for typing a
  // building name in it.
  const [newBuildingPlaceholder, setNewBuildingPlaceholder] =
    useState<NewBuildingPlaceholder>({
      visible: false,
    });
  const onCreateBuilding = useCallback(async () => {
    if (!densityAPIClient) {
      return;
    }
    if (!newBuildingPlaceholder.visible) {
      return;
    }

    // Show the loading state while creating the building
    setNewBuildingPlaceholder((value) => {
      if (!value.visible) {
        return value;
      }
      return { ...value, loading: true };
    });

    let building: CoreSpace;
    try {
      const response = await CoreAPI.createSpace(densityAPIClient, {
        name: newBuildingPlaceholder.name,
        space_type: CoreSpaceType.BUILDING,
        status: CoreSpaceStatus.PLANNING, // all buildings created in planner should be set to planning mode
      });
      building = response.data;
    } catch (err) {
      toast.error('Error creating building.');
      console.warn(`Error creating building: ${err}`);

      // Reset the loading state after creating a building
      setNewBuildingPlaceholder((value) => {
        if (!value.visible) {
          return value;
        }
        return { ...value, loading: false };
      });
      return;
    }

    // Reset the new building creation state
    setNewBuildingPlaceholder({ visible: true, loading: false, name: '' });

    // Sort the newly created building first in the list
    setNewlyCreatedBuildingIds((ids) => [...ids, building.id]);

    // Reset the search text - otherwise this could filter out the newly created building
    setSearchText('');

    // Do an optimistic update and add the newly created building to the space hierarchy
    setSpaceHierarchyData((spaceHierarchyData) => {
      if (spaceHierarchyData.status !== 'complete') {
        return spaceHierarchyData;
      }

      const spaceHierarchyList = [
        ...spaceHierarchyData.spaceHierarchyList,
        {
          id: building.id,
          name: building.name,
          space_type: building.space_type,
          time_zone: building.time_zone,
          address: building.address,
          capacity: building.capacity,
          children: [],
          daily_reset: building.daily_reset,
          function: building.function,
          has_purview: true,
          inherits_time_segments: building.inherits_time_segments,
          labels: building.labels,
          time_segments: building.time_segments,
        },
      ];

      return { status: 'complete', spaceHierarchyList };
    });
  }, [newBuildingPlaceholder, densityAPIClient]);

  // THe search text the the backing state behind the search text box
  const [searchText, setSearchText] = useState('');

  const [spaceIdsWithNameLoading, setSpaceIdsWithNameLoading] = useState<
    Array<CoreSpace['id']>
  >([]);
  const onEditBuildingData = useCallback(
    async (
      buildingId: CoreSpace['id'],
      data:
        | { name: CoreSpace['name'] }
        | { address: CoreSpace['address']; time_zone: CoreSpace['time_zone'] }
    ) => {
      const isName = data.hasOwnProperty('name');
      const isAddress = data.hasOwnProperty('address');

      if (!densityAPIClient) {
        return;
      }
      setSpaceIdsWithNameLoading((ids) => [...ids, buildingId]);

      let newBuildingName: CoreSpace['name'];
      let newAddress: CoreSpace['address'];
      let newTimeZone: CoreSpace['time_zone'];
      try {
        const response = await CoreAPI.updateSpace(
          densityAPIClient,
          buildingId,
          { ...data }
        );
        newBuildingName = response.data.name;
        newAddress = response.data.address;
        newTimeZone = response.data.time_zone;
      } catch (err) {
        setSpaceIdsWithNameLoading((ids) =>
          ids.filter((id) => id !== buildingId)
        );
        if (isName) {
          toast.error('Error renaming building.');
          console.warn(`Error renaming building: ${err}`);
        } else if (isAddress) {
          toast.error('Error setting address of the building.');
          console.warn(`Error setting address of the building: ${err}`);
        }
        return;
      }

      // Reset the loading state
      setSpaceIdsWithNameLoading((ids) =>
        ids.filter((id) => id !== buildingId)
      );

      // Do an optimistic update and rename the building in the space hierarchy data
      setSpaceHierarchyData((spaceHierarchyData) => {
        if (spaceHierarchyData.status !== 'complete') {
          return spaceHierarchyData;
        }

        const traverse = (
          node: CoreSpaceHierarchyNode
        ): CoreSpaceHierarchyNode => {
          if (node.id === buildingId) {
            if (isName) {
              node = { ...node, name: newBuildingName };
            } else if (isAddress) {
              node = { ...node, address: newAddress, time_zone: newTimeZone };
            }
          }

          return {
            ...node,
            children: node.children ? node.children.map(traverse) : undefined,
          };
        };

        return {
          status: 'complete',
          spaceHierarchyList:
            spaceHierarchyData.spaceHierarchyList.map(traverse),
        };
      });
    },
    [densityAPIClient]
  );

  const [spaceIdsWithDeleteLoading, setSpaceIdsWithDeleteLoading] = useState<
    Array<CoreSpace['id']>
  >([]);
  const onDeleteBuilding = useCallback(
    async (buildingId: CoreSpace['id'], buildingName: CoreSpace['name']) => {
      if (!densityAPIClient) {
        return;
      }

      setSpaceIdsWithDeleteLoading((ids) => [...ids, buildingId]);

      try {
        await CoreAPI.deleteSpace(densityAPIClient, buildingId, buildingName);
      } catch (err) {
        setSpaceIdsWithDeleteLoading((ids) =>
          ids.filter((id) => id !== buildingId)
        );
        toast.error('Error deleting building.');
        console.warn(`Error deleting building: ${err}`);
        return;
      }

      setSpaceIdsWithDeleteLoading((ids) =>
        ids.filter((id) => id !== buildingId)
      );

      // Do an optimistic update and remove the building from the space hierarchy data
      setSpaceHierarchyData((spaceHierarchyData) => {
        if (spaceHierarchyData.status !== 'complete') {
          return spaceHierarchyData;
        }

        const traverse = (
          node: CoreSpaceHierarchyNode
        ): CoreSpaceHierarchyNode | null => {
          if (node.id === buildingId) {
            return null;
          }

          return {
            ...node,
            children: node.children
              ? node.children.flatMap((node) => {
                  const result = traverse(node);
                  return result ? [result] : [];
                })
              : undefined,
          };
        };

        const newSpaceHierarchyList =
          spaceHierarchyData.spaceHierarchyList.flatMap((node) => {
            const result = traverse(node);
            return result ? [result] : [];
          });

        return {
          status: 'complete',
          spaceHierarchyList: newSpaceHierarchyList,
        };
      });

      toast.success('Building deleted.');
    },
    [densityAPIClient]
  );
  const onDeleteFloorplan = useCallback(
    async (floorId: CoreSpace['id'], floorName: CoreSpace['name']) => {
      if (!densityAPIClient) {
        return;
      }

      setSpaceIdsWithDeleteLoading((ids) => [...ids, floorId]);

      try {
        await CoreAPI.deleteSpace(densityAPIClient, floorId, floorName);
      } catch (err) {
        setSpaceIdsWithDeleteLoading((ids) =>
          ids.filter((id) => id !== floorId)
        );
        toast.error('Error deleting floorplan.');
        console.warn(`Error deleting floorplan: ${err}`);
        return;
      }

      setSpaceIdsWithDeleteLoading((ids) => ids.filter((id) => id !== floorId));

      // Do an optimistic update and remove the floor from the space hierarchy data
      setSpaceHierarchyData((spaceHierarchyData) => {
        if (spaceHierarchyData.status !== 'complete') {
          return spaceHierarchyData;
        }

        const traverse = (
          node: CoreSpaceHierarchyNode
        ): CoreSpaceHierarchyNode | null => {
          if (node.id === floorId) {
            return null;
          }

          return {
            ...node,
            children: node.children
              ? node.children.flatMap((node) => {
                  const result = traverse(node);
                  return result ? [result] : [];
                })
              : undefined,
          };
        };

        const newSpaceHierarchyList =
          spaceHierarchyData.spaceHierarchyList.flatMap((node) => {
            const result = traverse(node);
            return result ? [result] : [];
          });

        return {
          status: 'complete',
          spaceHierarchyList: newSpaceHierarchyList,
        };
      });

      toast.success('Floorplan deleted.');
    },
    [densityAPIClient]
  );

  //
  //
  //
  //
  const [spaceHierarchyData, setSpaceHierarchyData] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'error' }
    | {
        status: 'complete';
        spaceHierarchyList: Array<CoreSpaceHierarchyNode>;
      }
  >({ status: 'pending' });
  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    const abortController = new AbortController();

    setSpaceHierarchyData({ status: 'loading', abortController });
    // If at the root of the space hierarchy, fetch all space parameters
    CoreAPI.spacesHierarchy(densityAPIClient, abortController.signal)
      .then((response) => {
        setSpaceHierarchyData({
          status: 'complete',
          spaceHierarchyList: response.data,
        });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setSpaceHierarchyData({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setSpaceHierarchyData({ status: 'pending' });
    };
  }, [densityAPIClient]);

  const [floorplansData, setFloorplansData] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error' }
    | { status: 'complete'; data: Paginated<FloorplanV2PlanSummary> }
  >({ status: 'pending' });

  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    const abortController = new AbortController();

    setFloorplansData({ status: 'loading' });
    FloorplanAPI.listFloorplans(
      densityAPIClient,
      undefined,
      abortController.signal
    )
      .then((response) => {
        setFloorplansData({ status: 'complete', data: response.data });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setFloorplansData({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setFloorplansData({ status: 'pending' });
    };
  }, [densityAPIClient]);

  const floorplansBySpaceId = useMemo(() => {
    const floorplansBySpaceId = new Map<
      CoreSpace['id'],
      FloorplanV2PlanSummary
    >();
    if (floorplansData.status !== 'complete') {
      return floorplansBySpaceId;
    }

    for (const floorplan of floorplansData.data.results) {
      floorplansBySpaceId.set(floorplan.floor.id, floorplan);
    }
    return floorplansBySpaceId;
  }, [floorplansData]);

  // Generate the list of buildings by traversing through the space hierarchy to find all buildings,
  // and then finding all floors that are underneath those buildings.
  const buildings: Array<Building> = useMemo(() => {
    const buildings: Map<CoreSpace['id'], Building> = new Map([
      [
        '',
        {
          spaceId: '',
          name: '',
          address: '',
          timeZone: '',
          floorplans: { status: 'complete', data: [] },
        },
      ],
    ]);

    if (spaceHierarchyData.status !== 'complete') {
      return Array.from(buildings.values());
    }

    function traverse(
      node: CoreSpaceHierarchyNode,
      parentBuildingId: string | null = null
    ) {
      if (node.space_type === 'floor') {
        if (!node.has_purview) {
          return;
        }

        const building = buildings.get(parentBuildingId || '');
        if (!building) {
          return;
        }
        if (building.floorplans.status !== 'complete') {
          throw new Error(
            'Space hierarchy traversal should always have fully loaded floorplan data!'
          );
        }

        const floorplan = floorplansBySpaceId.get(node.id);
        if (!floorplan) {
          // If there's no floorplan associated, then this is a creation placeholder
          building.floorplans.data.push({
            floorplanCreationPlaceholder: true,
            name: node.name,
            // FIXME: the CoreSpaceHierarchyNode doesn't seem to have the "time_zone" key in it!
            timeZone: (node as FixMe).time_zone,
            capacity: node.capacity,
            spaceId: node.id,
            updatedAt: '1970-01-01T00:00:00Z',
          });
          return;
        }

        building.floorplans.data.push({
          floorplanCreationPlaceholder: false,
          name: node.name,
          // FIXME: the CoreSpaceHierarchyNode doesn't seem to have the "time_zone" key in it!
          timeZone: (node as FixMe).time_zone,
          capacity: node.capacity,
          status: floorplan.floor.status,
          spaceId: node.id,
          planId: floorplan.id,
          updatedAt: floorplan.updated_at,
          thumbnailImageSrc: floorplan.active_dxf_thumbnail_url, //|| floorplan.image_url,
        });
      } else {
        if (node.space_type === 'building') {
          parentBuildingId = node.id;
          buildings.set(node.id, {
            spaceId: node.id,
            address: node.address!,
            capacity: node.capacity!,
            dailyReset: node.daily_reset!,
            timeZone: node.time_zone!,
            name: node.name,
            floorplans: { status: 'complete', data: [] },
          });
        }
        for (const child of node.children || []) {
          traverse(child, parentBuildingId);
        }
      }
    }

    for (const node of spaceHierarchyData.spaceHierarchyList) {
      traverse(node);
    }

    // Sort buildings by three things:
    // Primarily: newly created buildings are put on top
    // Secondarily: whether it has floorplans or not
    // Tertiarily: alphabetically
    const values = Array.from(buildings.values()).filter(
      (building) => building.spaceId !== ''
    ); // Remove "Other"

    const newlyCreatedBuildings = values.filter((building) =>
      newlyCreatedBuildingIds.includes(building.spaceId)
    );

    const buildingsWithNoFloorplans = values
      .filter(
        (building) =>
          building.floorplans.status === 'complete' &&
          !newlyCreatedBuildingIds.includes(building.spaceId) &&
          building.floorplans.data.filter(
            (f) => !f.floorplanCreationPlaceholder
          ).length === 0
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    const buildingsWithFloorplans = values
      .filter(
        (building) =>
          building.floorplans.status === 'complete' &&
          !newlyCreatedBuildingIds.includes(building.spaceId) &&
          building.floorplans.data.filter(
            (f) => !f.floorplanCreationPlaceholder
          ).length > 0
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    // And the "Other" building goes at the bottom of the "buildings with floorplans" list
    const uncategorizedBuilding = buildings.get('');
    if (
      uncategorizedBuilding &&
      uncategorizedBuilding.floorplans.status === 'complete' &&
      uncategorizedBuilding.floorplans.data.filter(
        (f) => !f.floorplanCreationPlaceholder
      ).length > 0
    ) {
      buildingsWithFloorplans.push(uncategorizedBuilding);
    }

    return [
      ...newlyCreatedBuildings,
      ...buildingsWithFloorplans,
      ...buildingsWithNoFloorplans,
    ];
  }, [spaceHierarchyData, floorplansBySpaceId, newlyCreatedBuildingIds]);

  // Keep a list of all project login codes that have been entered.
  // TODO: this should be managed / returned by the server!
  const [projectLoginCodeList, setProjectLoginCodeList] = useState<
    Array<
      Omit<FloorplanV2ProjectLoginCode, 'login_code'> & {
        token: string;
        spaceHierarchyAndFloorplans:
          | { status: 'pending' }
          | { status: 'loading' }
          | { status: 'error' }
          | {
              status: 'complete';
              floorplansBySpaceId: Map<CoreSpace['id'], FloorplanV2PlanSummary>;
              hierarchy: CoreSpaceHierarchyNode;
            };
      }
    >
  >([]);
  // When new project login codes are added to the list which haven't been loaded, load the
  // associated floorplans and space hierarchy for each
  // FIXME: this is very inefficient, and there really needs to be a domain specific api endpoint
  // for this
  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    const buildingIdsToLoad: Array<
      FloorplanV2ProjectLoginCode['building']['id']
    > = [];

    // Mark all codes that are not already loaded as now "loading"
    const projectLoginCodeListLoading = projectLoginCodeList.map(
      (projectLoginCode) => {
        if (projectLoginCode.spaceHierarchyAndFloorplans.status !== 'pending') {
          return projectLoginCode;
        }
        buildingIdsToLoad.push(projectLoginCode.building.id);
        return {
          ...projectLoginCode,
          spaceHierarchyAndFloorplans: { status: 'loading' as const },
        };
      }
    );

    if (buildingIdsToLoad.length > 0) {
      setProjectLoginCodeList(projectLoginCodeListLoading);

      // And then actually load these `ProjectLoginCode`s
      Promise.all(
        projectLoginCodeList.map(async (projectLoginCode) => {
          if (!buildingIdsToLoad.includes(projectLoginCode.building.id)) {
            return projectLoginCode;
          }

          try {
            const floorplansResponse = await FloorplanAPI.listFloorplans(
              densityAPIClient,
              projectLoginCode.token
            );
            const hierarchyResponse = await CoreAPI.spacesHierarchyWithinSpace(
              densityAPIClient,
              projectLoginCode.building.id,
              projectLoginCode.token
            );
            return {
              ...projectLoginCode,
              spaceHierarchyAndFloorplans: {
                status: 'complete' as const,
                floorplansBySpaceId: new Map(
                  floorplansResponse.data.results.map((floorplan) => [
                    floorplan.floor.id,
                    floorplan,
                  ]) as Array<[CoreSpace['id'], FloorplanV2PlanSummary]>
                ),
                hierarchy: hierarchyResponse.data,
              },
            };
          } catch (err) {
            return {
              ...projectLoginCode,
              spaceHierarchyAndFloorplans: { status: 'error' as const },
            };
          }
        })
      ).then((projectLoginCodeListLoaded) => {
        setProjectLoginCodeList(projectLoginCodeListLoaded);
      });
    }
  }, [densityAPIClient, projectLoginCodeList]);

  // Generate the list of projects by rearranging the `projectLoginCodeList` data
  const projects: Array<Project> = useMemo(() => {
    return projectLoginCodeList.map((projectLoginCode) => {
      switch (projectLoginCode.spaceHierarchyAndFloorplans.status) {
        case 'complete':
          const floorplansBySpaceId =
            projectLoginCode.spaceHierarchyAndFloorplans.floorplansBySpaceId;
          return {
            spaceId: projectLoginCode.building.id,
            name: projectLoginCode.building.name,
            expiresAt: projectLoginCode.expires_at,
            organizationName: projectLoginCode.building.organization.name,
            floorplans: {
              status: 'complete',
              data: (
                projectLoginCode.spaceHierarchyAndFloorplans.hierarchy
                  .children || []
              )
                .flatMap((spaceHierarchyNode) => {
                  const floorplan = floorplansBySpaceId.get(
                    spaceHierarchyNode.id
                  );
                  if (!floorplan) {
                    return [];
                  }
                  return [
                    {
                      floorplanCreationPlaceholder: false as const,
                      spaceId: spaceHierarchyNode.id,
                      planId: floorplan.id,
                      name: spaceHierarchyNode.name,
                      // FIXME: the CoreSpaceHierarchyNode doesn't seem to have the "time_zone" key in it!
                      timeZone: (spaceHierarchyNode as FixMe).time_zone,
                      capacity: spaceHierarchyNode.capacity,
                      status: floorplan.floor.status,
                      updatedAt: floorplan.updated_at,
                      thumbnailImageSrc: floorplan.active_dxf_thumbnail_url, //|| spaceHierarchyNode.image_url,
                    },
                  ];
                })
                .sort((a, b) =>
                  (b.updatedAt || '').localeCompare(a.updatedAt || '')
                ),
            },
          };

        case 'pending':
        case 'loading':
        case 'error':
        default:
          return {
            spaceId: projectLoginCode.building.id,
            name: projectLoginCode.building.name,
            expiresAt: projectLoginCode.expires_at,
            organizationName: projectLoginCode.building.organization.name,
            floorplans: {
              status: projectLoginCode.spaceHierarchyAndFloorplans.status,
            },
          };
      }
    });
  }, [projectLoginCodeList]);

  if (spaceHierarchyData.status === 'pending') {
    return null;
  }
  if (spaceHierarchyData.status === 'loading') {
    return (
      <Fragment>
        <div className={styles.header} />
        <div className={styles.wrapper}>
          <FillCenter>Loading spaces...</FillCenter>
        </div>
      </Fragment>
    );
  }
  if (spaceHierarchyData.status === 'error') {
    return (
      <FillCenter>
        <ErrorMessage>Error loading spaces!</ErrorMessage>
      </FillCenter>
    );
  }

  if (floorplansData.status === 'pending') {
    return null;
  }
  if (floorplansData.status === 'loading') {
    return (
      <Fragment>
        <div className={styles.header} />
        <div className={styles.wrapper}>
          <FillCenter>Loading floorplans...</FillCenter>
        </div>
      </Fragment>
    );
  }
  if (floorplansData.status === 'error') {
    return (
      <FillCenter>
        <ErrorMessage>Error loading floorplans!</ErrorMessage>
      </FillCenter>
    );
  }

  const filteredProjects = applyFuzzySearch(projects, searchText, [
    (project) => project.name,
    (project) => project.organizationName,
    (project) =>
      project.floorplans.status === 'complete'
        ? project.floorplans.data.map((floorplan) => floorplan.name).join(' ')
        : '',
  ]);

  const filteredBuildings = applyFuzzySearch(buildings, searchText, [
    (building) => building.name,
    (building) =>
      building.floorplans.status === 'complete'
        ? building.floorplans.data.map((floorplan) => floorplan.name).join(' ')
        : '',
  ]);
  return (
    <MapboxSessionProvider>
      <NewFloorplanModal
        {...addFloorplanModalParams}
        onDismiss={() =>
          setNewFloorplanModalParams({
            visible: false,
            mapsImport: false,
            parentBuildingId: null,
            existingFloorMetadata: null,
            initiallyLoadedFloorplanImage: null,
            initiallyLoadedFloorplanImageScale: null,
          })
        }
      />
      <ShareModal
        {...shareModalParams}
        onDismiss={() =>
          setShareModalParams({
            visible: false,
            building: null,
          })
        }
      />

      <div className={styles.header}>
        {userCanImpersonate ? (
          <OrganizationPicker
            organization={organization}
            creationEnabled={userCanCreateOrganizations}
            renameEnabled={false /* userCanCreateOrganizations */} // TODO: get rename working
          />
        ) : (
          <div className={styles.headerTitle}>Buildings</div>
        )}
        <DarkTheme>
          <HorizontalForm size="medium">
            <TextField
              size="medium"
              placeholder="Search..."
              leadingIcon={
                <Icons.SearchMagnifier size={16} color={dust.Gray400} />
              }
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
              data-cy="search"
            />
            {isProjectSharingEnabled ? (
              <Button
                size="medium"
                type="hollow"
                leadingIcon={
                  <Icons.CategoriesObjectsShapesElements size={18} />
                }
                onClick={async () => {
                  if (!densityAPIClient) {
                    return;
                  }

                  const code = await Dialogger.prompt({
                    title: 'Enter code',
                    prompt: 'Enter your project code:',
                  });
                  if (!code) {
                    return;
                  }

                  CoreAPI.loginWithProjectLoginCode(densityAPIClient, code)
                    .then((response) => {
                      if (
                        projectLoginCodeList.find(
                          (p) => p.building.id === response.data.building.id
                        )
                      ) {
                        toast.error('Project has already been attached!');
                        return;
                      }

                      setProjectLoginCodeList([
                        ...projectLoginCodeList,
                        {
                          ...response.data,
                          spaceHierarchyAndFloorplans: { status: 'pending' },
                        },
                      ]);
                    })
                    .catch(() => {
                      toast.error('Invalid project code!');
                    });
                }}
              >
                Attach Project...
              </Button>
            ) : null}
            <Button
              size="medium"
              leadingIcon={<Icons.SpaceTypeBuilding size={18} />}
              onClick={() => {
                Analytics.track('Create New Building');
                setNewBuildingPlaceholder((newBuildingPlaceholder) => {
                  if (newBuildingPlaceholder.visible) {
                    return { visible: false };
                  }
                  return { visible: true, loading: false, name: '' };
                });
              }}
              data-cy="new-building"
            >
              New Building
            </Button>
          </HorizontalForm>
        </DarkTheme>
      </div>
      {filteredProjects.length > 0 ||
      filteredBuildings.length > 0 ||
      newBuildingPlaceholder.visible ? (
        <div className={styles.wrapper}>
          {newBuildingPlaceholder.visible ? (
            <NewBuildingItem
              newBuildingPlaceholder={newBuildingPlaceholder}
              onChangeName={(name) =>
                setNewBuildingPlaceholder({
                  visible: true,
                  loading: false,
                  name,
                })
              }
              onDismiss={() => setNewBuildingPlaceholder({ visible: false })}
              onSubmit={onCreateBuilding}
            />
          ) : null}
          {filteredProjects.map(([project]) => (
            <ProjectItem
              key={project.spaceId}
              project={project}
              searchText={searchText}
              onClickDetach={async () => {
                const ok = await Dialogger.confirm({
                  title: 'Are you sure?',
                  prompt:
                    'After detaching this project, you will no longer have access to it.',
                  confirmText: 'Detach',
                });
                if (ok) {
                  setProjectLoginCodeList(
                    projectLoginCodeList.filter(
                      (projectLoginCode) =>
                        projectLoginCode.building.id !== project.spaceId
                    )
                  );
                }
              }}
            />
          ))}

          {filteredBuildings.map(([building]) => (
            <BuildingItem
              key={building.spaceId}
              building={building}
              searchText={searchText}
              nameEditLoading={spaceIdsWithNameLoading.includes(
                building.spaceId
              )}
              onEditName={(newName) =>
                onEditBuildingData(building.spaceId, { name: newName })
              }
              onEditAddress={(newAddress, newTimeZone) => {
                onEditBuildingData(building.spaceId, {
                  address: newAddress,
                  time_zone: newTimeZone,
                });
              }}
              spaceIdsWithDeleteLoading={spaceIdsWithDeleteLoading}
              onClickDelete={
                isDeleteEnabled
                  ? () => onDeleteBuilding(building.spaceId, building.name)
                  : undefined
              }
              onClickShare={() =>
                setShareModalParams({ visible: true, building })
              }
              onClickNewFloorplan={() => {
                setNewFloorplanModalParams({
                  visible: true,
                  mapsImport: false,
                  parentBuildingId: building.spaceId,
                  existingFloorMetadata: null,
                  initiallyLoadedFloorplanImage: null,
                  initiallyLoadedFloorplanImageScale: null,
                });
              }}
              onClickNewFloorplanFromMaps={() => {
                setNewFloorplanModalParams({
                  visible: true,
                  mapsImport: true,
                  parentBuildingId: building.spaceId,
                  existingFloorMetadata: null,
                  initiallyLoadedFloorplanImage: null,
                  initiallyLoadedFloorplanImageScale: null,
                });
              }}
              onAddFloorplan={(floorplan) => {
                setNewFloorplanModalParams({
                  visible: true,
                  mapsImport: false,
                  parentBuildingId: building.spaceId,
                  existingFloorMetadata: floorplan,
                  initiallyLoadedFloorplanImage: null,
                  initiallyLoadedFloorplanImageScale: null,
                });
              }}
              onDeleteFloorplan={
                isDeleteEnabled
                  ? (floorplan) =>
                      onDeleteFloorplan(floorplan.spaceId, floorplan.name)
                  : undefined
              }
            />
          ))}
        </div>
      ) : (
        <div className={styles.wrapper}>
          {searchText.length === 0 ? (
            <FillCenter>
              No buildings! Click "New Building" to create one.
            </FillCenter>
          ) : (
            <FillCenter>Nothing matched your search query.</FillCenter>
          )}
        </div>
      )}
    </MapboxSessionProvider>
  );
};

export default BuildingList;
