import { useEffect, useRef, useState, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import { ViewportCoordinates, FloorplanCoordinates } from 'lib/geometry';
import { degreesToRadians } from 'lib/math';
import PlanSensor, { CopiedSensor, getSensorStatusColor } from 'lib/sensor';
import { toast } from 'react-toastify';

import { State } from 'components/editor/state';

import {
  ObjectLayer,
  MetricLabel,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  toRawHex,
  drawPolygon,
} from 'components/floorplan';

import {
  Blue400,
  Purple400,
  Green400,
  Teal400,
  Green700,
  Gray500,
  Gray900,
  White,
  Orange400,
} from '@density/dust/dist/tokens/dust.tokens';

const SENSOR_FOCUSED_BORDER_WIDTH_PX = 2;
const SENSOR_FOCUSED_OUTLINE_WIDTH_PX = 5;
const SENSOR_STATUS_INDICATOR_SIZE_PX = 10;
const SENSOR_STATUS_INDICATOR_OUTLINE_PX = 1;
const getSensorColor = (planSensor: PlanSensor) =>
  planSensor.type === 'oa'
    ? planSensor.sensorFunction === 'oaLongRange'
      ? toRawHex(Teal400)
      : toRawHex(Blue400)
    : toRawHex(Purple400);

const SENSOR_COVERAGE_INTERSECTION_POINT_RADIUS_PX = 5;

// The sensors layer renders a list of sensors, both oa and entry, to the floorplan.
const SensorsLayer: React.FunctionComponent<{
  sensors: Array<PlanSensor>;
  locked?: boolean;
  highlightedObject?: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'threshold'
      | 'reference'
      | 'layer';
    id: string;
  } | null;
  focusedObject?: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer' | 'threshold';
    id: string;
  };
  focusedObjectLink?: null | string;
  bulkSelectedIds?: Set<string>;
  coverageIntersectionEnabled?: boolean;
  copiedObject?: {
    id: string;
    type: string;
    sensor?: CopiedSensor;
    shape?: null;
  };
  coverageIntersectionVectors?: State['planSensorCoverageIntersectionVectors'];
  hideOpenAreaCoverage?: boolean;
  hideOpenAreaLabels?: boolean;
  hideEntryCoverage?: boolean;
  hideEntryLabels?: boolean;
  hideDoorwayFOV?: boolean;
  onMouseEnter?: (sensor: PlanSensor, event: FederatedPointerEvent) => void;
  onMouseLeave?: (sensor: PlanSensor, event: FederatedPointerEvent) => void;
  onMouseDown?: (sensor: PlanSensor, event: FederatedPointerEvent) => void;
  onDragMove?: (
    sensor: PlanSensor,
    newCoordinates: FloorplanCoordinates
  ) => void;
  onDragEnd?: (sensor: PlanSensor) => void;
  onDuplicateSensor?: (sensor: PlanSensor) => void;
  showSensorNoisePolygons?: boolean;
  onRightClick?: (sensor: PlanSensor, event: FederatedPointerEvent) => void;
  onPasteSettings?: (sensor: PlanSensor) => void;
  isolateFocusedSensor?: boolean;
}> = ({
  sensors,
  locked = false,
  highlightedObject = null,
  focusedObject = null,
  focusedObjectLink = null,
  bulkSelectedIds = new Set<string>(),
  coverageIntersectionEnabled = false,
  coverageIntersectionVectors = new Map(),
  hideOpenAreaCoverage = false,
  hideOpenAreaLabels = false,
  hideEntryCoverage = false,
  hideEntryLabels = false,
  hideDoorwayFOV = true,
  copiedObject = null,
  onMouseEnter = null,
  onMouseLeave = null,
  onMouseDown = null,
  onDragMove = null,
  onDragEnd = null,
  onDuplicateSensor = null,
  showSensorNoisePolygons = false,
  onRightClick = null,
  onPasteSettings = null,
  isolateFocusedSensor = false,
}) => {
  const context = useFloorplanLayerContext();

  // FIXME: this probably is not the best approach to this, but because `onCreate` is not updated
  // within ObjectLayer due to dependency issues in the useEffect, it's possible that invocations of
  // `onDragMove` and friends within `onCreate` might be holding onto older references of these
  // functions from a previous render. So, cache the latest versions here in a ref so that the
  // latest version can always be called.
  //
  // The proper way to address this is by fixing the dependency issues within the `ObjectLayer`, but
  // this is a larger problem because the `onCreate` / `onUpdate` / `onRemove` function references
  // change every render and moving away from this is a large project across all layers.
  const latestOnMouseEnter = useRef(onMouseEnter);
  useEffect(() => {
    latestOnMouseEnter.current = onMouseEnter;
  }, [onMouseEnter]);

  const latestOnMouseLeave = useRef(onMouseLeave);
  useEffect(() => {
    latestOnMouseLeave.current = onMouseLeave;
  }, [onMouseLeave]);

  const latestOnMouseDown = useRef(onMouseDown);
  useEffect(() => {
    latestOnMouseDown.current = onMouseDown;
  }, [onMouseDown]);

  const latestOnDragMove = useRef(onDragMove);
  useEffect(() => {
    latestOnDragMove.current = onDragMove;
  }, [onDragMove]);

  const latestOnDragEnd = useRef(onDragEnd);
  useEffect(() => {
    latestOnDragEnd.current = onDragEnd;
  }, [onDragEnd]);

  const latestOnDuplicateSensor = useRef(onDuplicateSensor);
  useEffect(() => {
    latestOnDuplicateSensor.current = onDuplicateSensor;
  }, [onDuplicateSensor]);

  const latestOnRightClick = useRef(onRightClick);
  useEffect(() => {
    latestOnRightClick.current = onRightClick;
  }, [onRightClick]);

  const latestOnPasteSettings = useRef(onPasteSettings);
  useEffect(() => {
    latestOnPasteSettings.current = onPasteSettings;
  }, [onPasteSettings]);

  const latestLocked = useRef(locked);
  useEffect(() => {
    latestLocked.current = locked;
  }, [locked]);

  // The `duplicatedSensor` state stores a copy of a sensor that is being duplicated so that this
  // new sensor (which is not yet in the `sensors` prop) can be rendered via the ObjectLayer
  const [duplicatedSensor, setDuplicatedSensor] = useState<PlanSensor | null>(
    null
  );

  const sensorsPlusDuplicatedSensor = useMemo(
    () => (duplicatedSensor ? [...sensors, duplicatedSensor] : sensors),
    [sensors, duplicatedSensor]
  );

  const sensorMajorMinorInMeters = useMemo(() => {
    const sensorMajorMinorInMeters: { [sensorId: string]: [number, number] } =
      {};

    for (const sensor of sensorsPlusDuplicatedSensor) {
      if (sensor.type === 'oa') {
        sensorMajorMinorInMeters[sensor.id] =
          PlanSensor.computeCoverageMajorMinorAxisOA(
            sensor.height,
            sensor.sensorFunction
          );
      } else {
        const radiusMeters = PlanSensor.computeCoverageRadiusEntry(
          sensor.height
        );
        sensorMajorMinorInMeters[sensor.id] = [radiusMeters, radiusMeters];
      }
    }

    return sensorMajorMinorInMeters;
  }, [sensorsPlusDuplicatedSensor]);

  // When a sensor is focused, add serial number and sensor labels.
  const focusedSensor =
    duplicatedSensor ||
    (focusedObject && focusedObject.type === 'sensor'
      ? sensors.find((sensor) => sensor.id === focusedObject.id)
      : null);
  useEffect(() => {
    if (!focusedSensor) {
      return;
    }

    const sensorLabelGroup = new PIXI.Container();
    sensorLabelGroup.name = 'sensor-label-group';

    let serialNumberLabel: MetricLabel | null = null;
    if (focusedSensor.serialNumber) {
      serialNumberLabel = new MetricLabel(focusedSensor.serialNumber, {
        backgroundColor: getSensorColor(focusedSensor),
      });
      serialNumberLabel.name = 'serial-number-label';
      serialNumberLabel.renderable = false;
      sensorLabelGroup.addChild(serialNumberLabel);
    }

    context.app.stage.addChild(sensorLabelGroup);

    return () => {
      context.app.stage.removeChild(sensorLabelGroup);
    };
  }, [context, focusedSensor]);

  // Convert each sensor's set of coverage intersection vectors into cartesian coordinates
  const coverageIntersectionPoints = useMemo(() => {
    const result = new Map<
      PlanSensor['id'],
      {
        perimeter: Array<[number, number]>;
        obstructions: Array<[number, number]>;
      }
    >();

    if (!coverageIntersectionEnabled) {
      return result;
    }

    for (const [sensorId, coverageIntersectionsForSensor] of Array.from(
      coverageIntersectionVectors
    )) {
      if (
        coverageIntersectionsForSensor === 'loading' ||
        coverageIntersectionsForSensor === 'empty'
      ) {
        continue;
      }

      const sensor = sensors.find((sensor) => sensor.id === sensorId);
      if (!sensor) {
        continue;
      }

      const perimeter: Array<[number, number]> = [];
      const obstructions: Array<[number, number]> = [];
      for (const [
        theta,
        magnitudesInMeters,
      ] of coverageIntersectionsForSensor) {
        for (let index = 0; index < magnitudesInMeters.length; index += 1) {
          const magnitudeInMeters = magnitudesInMeters[index];

          if (index === magnitudesInMeters.length - 1) {
            // The final intersection forms the perimeter of the coverage area!
            // NOTE: take into account the sensor rotation because the perimeter shape will be
            // rendered on the `coverage-area` graphics entity which is rotated. So "invert that
            // rotation".
            const x =
              magnitudeInMeters *
              Math.cos(degreesToRadians(theta - sensor.rotation));
            const y =
              magnitudeInMeters *
              Math.sin(degreesToRadians(theta - sensor.rotation));
            perimeter.push([x, y]);
          } else {
            const x = magnitudeInMeters * Math.cos(degreesToRadians(theta));
            const y = magnitudeInMeters * Math.sin(degreesToRadians(theta));
            obstructions.push([x, y]);
          }
        }
      }

      result.set(sensorId, { perimeter, obstructions });
    }

    return result;
  }, [coverageIntersectionEnabled, coverageIntersectionVectors, sensors]);

  const coverageIntersectionPointTexture = useMemo(() => {
    const gr = new PIXI.Graphics();
    gr.lineStyle({
      width: 1,
      color: toRawHex(Gray900),
      join: PIXI.LINE_JOIN.ROUND,
      alpha: 1,
    });

    // Draw white circle
    gr.beginFill(toRawHex(White), 1);
    gr.drawCircle(0, 0, SENSOR_COVERAGE_INTERSECTION_POINT_RADIUS_PX);
    gr.endFill();

    return context.app.renderer.generateTexture(gr);
  }, [context.app.renderer]);

  // When a focused sensor has coverage vectors, render a sprite at each coverage itnersection point
  // to indicate what blocked the sensor coverage
  const coverageIntersectionPointsForFocusedSensor = focusedSensor
    ? coverageIntersectionPoints.get(focusedSensor.id)
    : null;
  useEffect(() => {
    if (!focusedSensor) {
      return;
    }
    if (!coverageIntersectionPointsForFocusedSensor) {
      return;
    }

    const sensorCoverageIntersectionsGroup = new PIXI.Container();
    sensorCoverageIntersectionsGroup.name =
      'sensor-coverage-intersections-group';

    for (
      let i = 0;
      i < coverageIntersectionPointsForFocusedSensor.obstructions.length;
      i += 1
    ) {
      const sprite = new PIXI.Sprite(coverageIntersectionPointTexture);
      sprite.anchor.set(0.5, 0.5);
      sensorCoverageIntersectionsGroup.addChild(sprite);
    }

    context.app.stage.addChild(sensorCoverageIntersectionsGroup);

    return () => {
      context.app.stage.removeChild(sensorCoverageIntersectionsGroup);
    };
  }, [
    context,
    focusedSensor,
    coverageIntersectionEnabled,
    coverageIntersectionPointsForFocusedSensor,
    coverageIntersectionPointTexture,
  ]);

  const focusedSensorCoordinates = useRef<FloorplanCoordinates | null>(null);

  return (
    <ObjectLayer
      objects={sensorsPlusDuplicatedSensor}
      extractId={(sensor) => sensor.id}
      onCreate={(getSensor) => {
        const sensorGraphic = new PIXI.Container();

        const noisePolygons = new PIXI.Graphics();
        noisePolygons.name = 'noise-polygons';
        noisePolygons.interactive = false;
        sensorGraphic.addChild(noisePolygons);

        const coverageArea = new PIXI.Graphics();
        coverageArea.name = 'coverage-area';
        coverageArea.interactive = true;
        sensorGraphic.interactive = true;

        coverageArea.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          if (evt.data.originalEvent.shiftKey) {
            if (latestOnPasteSettings.current) {
              latestOnPasteSettings.current(getSensor());
            }
            return;
          }

          if (latestOnMouseDown.current) {
            latestOnMouseDown.current(getSensor(), evt);
          }

          let isDuplicating = evt.data.originalEvent.altKey;
          if (latestLocked.current || getSensor().locked) {
            if (isDuplicating) {
              toast.error('You must unlock the PlanSensor before duplicating.');
            }
            return;
          }

          // If a user alt/option clicked, then duplicate the sensor instead
          //
          // the `duplicatedSensor` state stores the sensors that is being duplicated so that this
          // sensor (which is not yet in the `sensors` prop) can be rendered via the ObjectLayer
          let duplicatedSensor: PlanSensor | null = null;
          if (isDuplicating) {
            duplicatedSensor = PlanSensor.duplicate(getSensor());
            setDuplicatedSensor(duplicatedSensor);
          }

          // If there's no drag handler for the layer, don't allow moving sensors
          if (!latestOnDragMove.current && !latestOnDragEnd.current) {
            return;
          }

          addDragHandler(
            context,
            getSensor().position,
            evt,
            (newPosition) => {
              focusedSensorCoordinates.current = FloorplanCoordinates.create(
                newPosition.x,
                newPosition.y
              );
            },
            () => {
              if (!focusedSensorCoordinates.current) {
                return;
              }

              // If the sensor was duplicated, then call a different method once the drag has
              // completed
              if (latestOnDuplicateSensor.current && duplicatedSensor) {
                latestOnDuplicateSensor.current({
                  ...duplicatedSensor,
                  position: focusedSensorCoordinates.current,
                });
                setDuplicatedSensor(null);
                return;
              }

              if (latestOnDragMove.current) {
                latestOnDragMove.current(
                  getSensor(),
                  focusedSensorCoordinates.current
                );
              }
              if (latestOnDragEnd.current) {
                latestOnDragEnd.current(getSensor());
              }
              focusedSensorCoordinates.current = null;
            }
          );
        });
        coverageArea.on('mouseover', (evt) => {
          if (latestOnMouseEnter.current) {
            latestOnMouseEnter.current(getSensor(), evt);
          }
        });
        coverageArea.on('mouseout', (evt) => {
          if (latestOnMouseLeave.current) {
            latestOnMouseLeave.current(getSensor(), evt);
          }
        });

        coverageArea.on('rightclick', (evt) => {
          if (latestOnRightClick.current) {
            latestOnRightClick.current(getSensor(), evt);
          }
        });
        sensorGraphic.addChild(coverageArea);

        const focusedLabels = new PIXI.Graphics();
        focusedLabels.name = 'focused-labels';
        sensorGraphic.addChild(focusedLabels);

        return sensorGraphic;
      }}
      onUpdate={(sensor: PlanSensor, sensorGraphic) => {
        if (!context.viewport.current) {
          return;
        }

        const majorMinorMeters = sensorMajorMinorInMeters[sensor.id];
        if (!majorMinorMeters) {
          return;
        }
        const [majorMeters, minorMeters] = majorMinorMeters;
        const majorPixels =
          majorMeters * context.floorplan.scale * context.viewport.current.zoom;
        const minorPixels =
          minorMeters * context.floorplan.scale * context.viewport.current.zoom;

        const isFocused = focusedSensor && focusedSensor.id === sensor.id;
        const isHighlighted =
          (highlightedObject &&
            highlightedObject.type === 'sensor' &&
            highlightedObject.id === sensor.id) ||
          focusedObjectLink === sensor.id ||
          bulkSelectedIds.has(sensor.id);

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          isFocused && focusedSensorCoordinates.current
            ? focusedSensorCoordinates.current
            : sensor.position,
          context.floorplan,
          context.viewport.current
        );
        if (focusedSensor && !isFocused && isolateFocusedSensor) {
          sensorGraphic.renderable = false;
        } else if (
          highlightedObject &&
          highlightedObject.type === 'sensor' &&
          !isHighlighted &&
          isolateFocusedSensor
        ) {
          sensorGraphic.renderable = false;
        } else {
          // Hide sensors that are not on the screen
          sensorGraphic.renderable = isWithinViewport(
            context,
            viewportCoords,
            -1 * majorPixels
          );
        }

        if (!sensorGraphic.renderable) {
          // When this object moves out of the Viewport, clean it up.
          // Without clearing it, the object was selectable by clicking
          // on the very border of the Viewport.
          const coverageArea = sensorGraphic.getChildByName(
            'coverage-area'
          ) as PIXI.Graphics;

          if (coverageArea) {
            coverageArea.clear();
          }

          const noisePolygons = sensorGraphic.getChildByName(
            'noise-polygons'
          ) as PIXI.Graphics;

          if (noisePolygons) {
            noisePolygons.clear();
          }

          return;
        }

        sensorGraphic.x = viewportCoords.x;
        sensorGraphic.y = viewportCoords.y;

        // Draw main sensor coverage area
        const coverageArea = sensorGraphic.getChildByName(
          'coverage-area'
        ) as PIXI.Graphics;
        if (
          !latestOnMouseDown.current &&
          !latestOnDragMove.current &&
          !latestOnDragEnd.current
        ) {
          coverageArea.cursor = 'default';
        } else if (
          latestLocked.current ||
          sensor.locked ||
          (!latestOnDragMove.current && !latestOnDragEnd.current)
        ) {
          coverageArea.cursor = 'pointer';
        } else {
          coverageArea.cursor = 'grab';
        }
        coverageArea.angle = sensor.rotation;
        coverageArea.clear();
        coverageArea.beginFill(getSensorColor(sensor), 0.2);
        if (isFocused || isHighlighted) {
          coverageArea.lineStyle({
            width: SENSOR_FOCUSED_BORDER_WIDTH_PX,
            color: getSensorColor(sensor),
            join: PIXI.LINE_JOIN.ROUND,
          });
        }

        const noisePolygons = sensorGraphic.getChildByName(
          'noise-polygons'
        ) as PIXI.Graphics;
        noisePolygons.clear();

        if (copiedObject && copiedObject.id === sensor.id) {
          coverageArea.lineStyle({
            width: SENSOR_FOCUSED_BORDER_WIDTH_PX,
            color: toRawHex(Orange400),
            join: PIXI.LINE_JOIN.ROUND,
          });
        }

        switch (sensor.type) {
          case 'oa':
            if (hideOpenAreaCoverage) {
              break;
            }

            // If clipped FOV for this sensor exists, render it
            if (sensor.clippedFOV.length > 3) {
              const verticesViewport = sensor.clippedFOV.map((v) => {
                if (!context.viewport.current) {
                  throw new Error('This is impossible');
                }

                return ViewportCoordinates.create(
                  (v.x - sensor.position.x) *
                    context.floorplan.scale *
                    context.viewport.current.zoom,
                  (v.y - sensor.position.y) *
                    context.floorplan.scale *
                    context.viewport.current.zoom
                );
              });
              drawPolygon(coverageArea, verticesViewport);
              if (
                sensor.doorwayClippedFOV.length > 3 &&
                sensor.doorwayClippedFOV.length !== sensor.clippedFOV.length &&
                (isFocused || isHighlighted) &&
                !hideDoorwayFOV
              ) {
                const verticesViewport = sensor.doorwayClippedFOV.map((v) => {
                  if (!context.viewport.current) {
                    throw new Error('This is impossible');
                  }

                  return ViewportCoordinates.create(
                    (v.x - sensor.position.x) *
                      context.floorplan.scale *
                      context.viewport.current.zoom,
                    (v.y - sensor.position.y) *
                      context.floorplan.scale *
                      context.viewport.current.zoom
                  );
                });
                coverageArea.beginFill(getSensorColor(sensor), 0.3);
                coverageArea.lineStyle({ width: 0 });
                drawPolygon(coverageArea, verticesViewport);
              }
            } else {
              // Otherwise render the idealized ellipse
              coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
            }

            // Now render noise polygons, if they exist...
            // NOTE: render them only when not zoomed-out as generaly they will be fairly
            //       hard to see anyway. Hopefully that'll yield some performance gains.
            if (
              showSensorNoisePolygons &&
              sensor.noisePolygons.length > 0 &&
              context.viewport.current.zoom > 0.4
            ) {
              noisePolygons.angle = sensor.rotation;
              noisePolygons.beginFill(getSensorColor(sensor), 0.6);
              if (isFocused || isHighlighted) {
                noisePolygons.lineStyle({
                  width: SENSOR_FOCUSED_BORDER_WIDTH_PX * 1.5, // let them be really visible
                  color: getSensorColor(sensor),
                  join: PIXI.LINE_JOIN.ROUND,
                });
              }

              sensor.noisePolygons.forEach((polygon) => {
                if (polygon.length < 3) {
                  // Shouldn't ever happen, but let's guard
                  // this anyway...
                  console.error(
                    'Noise polygon has less than 3 vertices! Report to Data Science!'
                  );
                  return;
                }

                const verticesViewport = polygon.map((v) => {
                  if (!context.viewport.current) {
                    throw new Error('This is impossible');
                  }

                  return ViewportCoordinates.create(
                    (v.x - sensor.position.x) *
                      context.floorplan.scale *
                      context.viewport.current.zoom,
                    (v.y - sensor.position.y) *
                      context.floorplan.scale *
                      context.viewport.current.zoom
                  );
                });
                drawPolygon(noisePolygons, verticesViewport);
              });
              noisePolygons.endFill();
            }

            break;
          case 'entry':
            if (hideEntryCoverage) {
              break;
            }

            if (sensor.sensorFunction === 'tofEntry') {
              // Entry coverage area is a half-circle
              coverageArea.moveTo(0, 0);
              const startAngle = degreesToRadians(0);
              const endAngle = startAngle + Math.PI;
              coverageArea.arc(
                0,
                0,
                majorPixels * 1.5,
                startAngle,
                endAngle,
                true
              );
              coverageArea.lineTo(0, 0);
            } else {
              // entry LR is a rounded square
              coverageArea.moveTo(0, 0);

              const rectWidth = majorPixels * 2;
              const rectHeight = majorPixels * 2;
              const cornerRadius = majorPixels / 2;

              coverageArea.drawRoundedRect(
                -rectWidth / 2,
                -rectHeight / 2,
                rectWidth,
                rectHeight,
                cornerRadius
              );
            }

            break;
        }
        coverageArea.endFill();

        // Draw inset shadow used to indicate that the sensor is selected
        if (isFocused) {
          coverageArea.lineStyle({
            width:
              sensor.clippedFOV.length > 3
                ? SENSOR_FOCUSED_OUTLINE_WIDTH_PX / 3
                : SENSOR_FOCUSED_OUTLINE_WIDTH_PX,
            color: getSensorColor(sensor),
            alpha: 0.2,
            join: PIXI.LINE_JOIN.ROUND,
            alignment: sensor.type === 'oa' ? 0 : 1,
          });
          if (sensor.type === 'oa') {
            coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
          } else {
            coverageArea.moveTo(0, 0);
            if (sensor.sensorFunction === 'tofEntry') {
              const startAngle = degreesToRadians(0);
              const endAngle = startAngle + Math.PI;
              coverageArea.arc(
                0,
                0,
                majorPixels * 1.5,
                startAngle,
                endAngle,
                true
              );
            } else {
              const rectWidth = majorPixels * 2;
              const rectHeight = majorPixels * 2;
              const cornerRadius = majorPixels / 2;
              coverageArea.drawRoundedRect(
                -rectWidth / 2,
                -rectHeight / 2,
                rectWidth,
                rectHeight,
                cornerRadius
              );
            }
            coverageArea.lineTo(0, 0);
          }
        }

        // Unlike in the function commented out below, we draw the indicator
        // using a stroke, not a line, which is supposed to be more performant.
        // FIXME: Ideally, this would be a sprite, though.
        function drawStatus(graphics: PIXI.Graphics) {
          graphics.beginFill(toRawHex(getSensorStatusColor(sensor.status)));
          graphics.lineStyle({
            width: SENSOR_STATUS_INDICATOR_OUTLINE_PX,
            color: 0xffffff,
            join: PIXI.LINE_JOIN.ROUND,
          });
          // OA used to be a rounded rect indicator but we had no way of
          // telling the sensor rotation at a glance...
          //graphics.drawRoundedRect(
          //  -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX / 2),
          //  -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX / 2),
          //  SENSOR_STATUS_INDICATOR_SIZE_PX,
          //  SENSOR_STATUS_INDICATOR_SIZE_PX,
          //  2
          //);
          graphics.moveTo(
            -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75),
            SENSOR_STATUS_INDICATOR_SIZE_PX * 0.5
          );
          graphics.lineTo(0, -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX * 1.0));
          graphics.lineTo(
            SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75,
            SENSOR_STATUS_INDICATOR_SIZE_PX * 0.5
          );
          graphics.lineTo(
            -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75),
            SENSOR_STATUS_INDICATOR_SIZE_PX * 0.5
          );
          graphics.endFill();
        }

        const coverageIntersectionsForSensor = coverageIntersectionPoints.get(
          sensor.id
        );
        if (
          coverageIntersectionsForSensor &&
          coverageIntersectionsForSensor.perimeter.length > 0
        ) {
          const isGreen =
            isFocused || !(focusedObject && focusedObject.type === 'sensor');

          // Draw the polygon shape
          coverageArea.lineStyle({
            width: 1,
            color:
              (isFocused || isHighlighted) && hideOpenAreaCoverage
                ? toRawHex(isGreen ? Green700 : Gray900)
                : toRawHex(isGreen ? Green400 : Gray500),
            join: PIXI.LINE_JOIN.ROUND,
            alpha: 1,
          });
          coverageArea.beginFill(
            toRawHex(isGreen ? Green400 : Gray500),
            isGreen ? 0.5 : 0.2
          );

          let first: [number, number] | null = null;
          for (
            let index = 0;
            index < coverageIntersectionsForSensor.perimeter.length;
            index += 1
          ) {
            const [x, y] = coverageIntersectionsForSensor.perimeter[index];
            const xPixels =
              x * context.floorplan.scale * context.viewport.current.zoom;
            const yPixels =
              y * context.floorplan.scale * context.viewport.current.zoom;
            if (index === 0) {
              coverageArea.moveTo(xPixels, yPixels);
              first = [xPixels, yPixels];
            } else {
              coverageArea.lineTo(xPixels, yPixels);
            }
          }
          if (first) {
            coverageArea.lineTo(first[0], first[1]);
          }
          coverageArea.endFill();
        }

        // Draw extra bits and bobs that are visible when sensor is focused
        const focusedLabels = sensorGraphic.getChildByName(
          'focused-labels'
        ) as PIXI.Graphics;
        if (!isFocused) {
          focusedLabels.renderable = false;
          drawStatus(coverageArea);
          return;
        }

        focusedLabels.angle = sensor.rotation;
        focusedLabels.renderable = true;
        focusedLabels.clear();

        // A vertical line indicates 0 degrees
        //
        // Because the whole focusedLabels container is being rotated, rotate this line a
        // correlating negative amount so that it appears vertical
        focusedLabels.lineStyle({
          width: 1,
          color: getSensorColor(sensor),
          join: PIXI.LINE_JOIN.ROUND,
        });
        focusedLabels.moveTo(
          Math.cos(degreesToRadians(-1 * sensor.rotation - 90)) * majorPixels,
          Math.sin(degreesToRadians(-1 * sensor.rotation - 90)) * majorPixels
        );
        focusedLabels.lineTo(
          Math.cos(degreesToRadians(-1 * sensor.rotation + 90)) * majorPixels,
          Math.sin(degreesToRadians(-1 * sensor.rotation + 90)) * majorPixels
        );

        const arrowRadiusPx = majorPixels - SENSOR_FOCUSED_OUTLINE_WIDTH_PX;

        focusedLabels.moveTo(0, arrowRadiusPx);
        focusedLabels.lineTo(0, -1 * arrowRadiusPx);
        // Left side of the arrow
        focusedLabels.lineTo(
          Math.cos(degreesToRadians(135)) * 10,
          Math.sin(degreesToRadians(135)) * 10 - arrowRadiusPx
        );
        focusedLabels.moveTo(0, -1 * arrowRadiusPx);
        // Right side of the arrow
        focusedLabels.lineTo(
          Math.cos(degreesToRadians(45)) * 10,
          Math.sin(degreesToRadians(45)) * 10 - arrowRadiusPx
        );

        drawStatus(focusedLabels);

        // Update positions of focused labels
        // - The sensor label group contains all labels, and is itself rotatied.
        // - This allows all labels to be positioned assuming that the sensor is not rotated.
        // - Then, all sensor labels themselves (ie, the MetricLabel) are rotated the opposite
        //   direction so they appear inthe correct orientation
        //
        // To make what's going on more clear, try togging the rotation of each level and watching
        // what that results in within the layer.
        const sensorLabelGroup = context.app.stage.getChildByName(
          'sensor-label-group'
        ) as PIXI.Container | null;
        if (sensorLabelGroup) {
          sensorLabelGroup.x = viewportCoords.x;
          sensorLabelGroup.y = viewportCoords.y;
          sensorLabelGroup.angle = sensor.rotation;

          const serialNumberLabel = sensorLabelGroup.getChildByName(
            'serial-number-label'
          );
          if (serialNumberLabel) {
            if (hideOpenAreaLabels) {
              serialNumberLabel.renderable = false;
            } else {
              serialNumberLabel.x =
                Math.cos(degreesToRadians(315 - sensor.rotation)) * minorPixels;
              serialNumberLabel.y =
                Math.sin(degreesToRadians(315 - sensor.rotation)) * majorPixels;
              serialNumberLabel.angle = -1 * sensor.rotation;
              serialNumberLabel.renderable = true;
            }
          }
        }

        const sensorCoverageIntersectionsGroup =
          context.app.stage.getChildByName(
            'sensor-coverage-intersections-group'
          ) as PIXI.Container | null;
        if (
          coverageIntersectionsForSensor &&
          sensorCoverageIntersectionsGroup
        ) {
          for (
            let index = 0;
            index < coverageIntersectionsForSensor.obstructions.length;
            index += 1
          ) {
            const [x, y] = coverageIntersectionsForSensor.obstructions[index];
            const position = FloorplanCoordinates.toViewportCoordinates(
              FloorplanCoordinates.create(
                (focusedSensorCoordinates.current || sensor.position).x + x,
                (focusedSensorCoordinates.current || sensor.position).y + y
              ),
              context.floorplan,
              context.viewport.current
            );
            sensorCoverageIntersectionsGroup.children[index].x = position.x;
            sensorCoverageIntersectionsGroup.children[index].y = position.y;
          }
        }
      }}
      onRemove={(sensor: PlanSensor, coverageArea) => {
        coverageArea.destroy(true);
      }}
    />
  );
};

export default SensorsLayer;
