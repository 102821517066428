//import pointInPolygon from 'point-in-polygon';
import { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  computeBoundingRegionExtents,
  isParallel,
} from 'lib/geometry';
import { distanceToLineSegment } from 'lib/algorithm';
import WallSegment, {
  WALL_SEGMENT_SNAP_THRESHOLD_PIXELS,
} from 'lib/wall-segment';
import AreaOfConcern from 'lib/area-of-concern';
import { SPLITS } from 'lib/treatments';
import { useTreatment } from 'contexts/treatments';
import { toast } from 'react-toastify';

import {
  ObjectLayer,
  ResizeHandle,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  drawPolygon,
  toRawHex,
} from 'components/floorplan';

import {
  Orange300,
  Blue700,
  Blue400,
  Green400,
  Gray300,
  Yellow400,
  White,
} from '@density/dust/dist/tokens/dust.tokens';

const FOCUSED_BORDER_WIDTH_PX = 2;
const FOCUSED_OUTLINE_WIDTH_PX = 5;

// The areasOfConcern layer renders a number of box, circle, and polygonal areasOfConcern to the floorplan
const AreasOfConcernLayer: React.FunctionComponent<{
  areasOfConcern: Array<AreaOfConcern>;
  walls: Array<WallSegment>;
  locked?: boolean;
  highlightedObject?: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'threshold'
      | 'photogroup'
      | 'reference'
      | 'layer';
    id: string;
  } | null;
  focusedObject?: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer' | 'threshold';
    id: string;
  };
  bulkSelectedIds?: Set<string>;
  onMouseEnter?: (
    areaOfConcern: AreaOfConcern,
    event: FederatedPointerEvent
  ) => void;
  onMouseLeave?: (
    areaOfConcern: AreaOfConcern,
    event: FederatedPointerEvent
  ) => void;
  onMouseDown?: (
    areaOfConcern: AreaOfConcern,
    event: FederatedPointerEvent
  ) => void;
  onDragMove?: (
    areaOfConcern: AreaOfConcern,
    newCoordinates: FloorplanCoordinates
  ) => void;
  onDragOrigin?: (
    areaOfConcern: AreaOfConcern,
    newOrigin: FloorplanCoordinates
  ) => void;
  onResize?: (
    areaOfConcern: AreaOfConcern,
    newPosition: FloorplanCoordinates,
    newVertices: Array<FloorplanCoordinates>
  ) => void;
  onDragEnd?: (areaOfConcern: AreaOfConcern) => void;
  onDuplicateAreaOfConcern?: (areaOfConcern: AreaOfConcern) => void;
}> = ({
  areasOfConcern,
  walls,
  locked = false,
  highlightedObject = null,
  bulkSelectedIds = new Set<string>(),
  focusedObject = null,
  onMouseEnter = null,
  onMouseLeave = null,
  onMouseDown = null,
  onDragMove = null,
  onDragOrigin = null,
  onResize = null,
  onDragEnd = null,
  onDuplicateAreaOfConcern = null,
}) => {
  const context = useFloorplanLayerContext();
  // ref tracking what's focused, when duplicating -- set this to null
  // see spaces/sensors implementation
  const selectedAreaOfConcern = useRef<AreaOfConcern | null>(null);
  const isAOCSnappingEnabled = useTreatment(SPLITS.AREAS_OF_CONCERN_SNAPPING);
  const latestOnMouseEnter = useRef(onMouseEnter);
  useEffect(() => {
    latestOnMouseEnter.current = onMouseEnter;
  }, [onMouseEnter]);

  const latestOnMouseLeave = useRef(onMouseLeave);
  useEffect(() => {
    latestOnMouseLeave.current = onMouseLeave;
  }, [onMouseLeave]);

  const latestOnMouseDown = useRef(onMouseDown);
  useEffect(() => {
    latestOnMouseDown.current = onMouseDown;
  }, [onMouseDown]);

  const latestOnDragMove = useRef(onDragMove);
  useEffect(() => {
    latestOnDragMove.current = onDragMove;
  }, [onDragMove]);

  const latestOnResize = useRef(onResize);
  useEffect(() => {
    latestOnResize.current = onResize;
  }, [onResize]);

  const latestOnDuplicateAreaOfConcern = useRef(onDuplicateAreaOfConcern);
  useEffect(() => {
    latestOnDuplicateAreaOfConcern.current = onDuplicateAreaOfConcern;
  }, [onDuplicateAreaOfConcern]);

  const latestLocked = useRef(locked);
  useEffect(() => {
    latestLocked.current = locked;
  }, [locked]);

  const areaOfConcernEdges = useRef<
    Array<[AreaOfConcern['id'], [FloorplanCoordinates, FloorplanCoordinates]]>
  >([]);

  useEffect(() => {
    const edges: Array<
      [AreaOfConcern['id'], [FloorplanCoordinates, FloorplanCoordinates]]
    > = [];
    for (const areaOfConcern of areasOfConcern) {
      AreaOfConcern.edges(areaOfConcern.vertices).forEach((edge) =>
        edges.push([areaOfConcern.id, edge])
      );
    }
    areaOfConcernEdges.current = edges;
  }, [areasOfConcern]);

  const originSpriteTexture = useMemo(() => {
    const gr = new PIXI.Graphics();
    gr.lineStyle({ width: 3, color: toRawHex(Blue400) });

    // Draw endpoint
    gr.drawCircle(0, 0, 30);

    // Draw crosshairs in endpoint
    gr.moveTo(-16, 0);
    gr.lineTo(16, 0);
    gr.moveTo(0, -16);
    gr.lineTo(0, 16);

    return context.app.renderer.generateTexture(gr);
  }, [context.app]);

  // Set this data when a user's cursor is hovering over the border of a polygonal areaOfConcern. This is
  // used to facilutate clicking to create new vertices.
  const areaOfConcernVertexPosition = useRef<{
    vertexAIndex: number;
    vertexBIndex: number;
    position: FloorplanCoordinates;
  } | null>(null);

  // The `duplicatedSensor` state stores a copy of a sensor that is being duplicated so that this
  // new sensor (which is not yet in the `sensors` prop) can be rendered via the ObjectLayer
  const [duplicatedAreaOfConcern, setDuplicatedAreaOfConcern] =
    useState<AreaOfConcern | null>(null);

  const aocsPlusDuplicatedAreaOfConcern = useMemo(
    () =>
      duplicatedAreaOfConcern
        ? [...areasOfConcern, duplicatedAreaOfConcern]
        : areasOfConcern,
    [areasOfConcern, duplicatedAreaOfConcern]
  );

  const focusedAreaOfConcern =
    focusedObject && focusedObject.type === 'areaofconcern'
      ? areasOfConcern.find(
          (areaOfConcern) => areaOfConcern.id === focusedObject.id
        )
      : null;
  useEffect(() => {
    if (!focusedAreaOfConcern) {
      selectedAreaOfConcern.current = null;
      return;
    }

    const areaOfConcern =
      duplicatedAreaOfConcern ||
      areasOfConcern.find(
        (areaOfConcern) => areaOfConcern.id === focusedAreaOfConcern.id
      );
    if (!areaOfConcern) {
      return;
    }
    selectedAreaOfConcern.current = areaOfConcern;
  }, [areasOfConcern, focusedAreaOfConcern, duplicatedAreaOfConcern]);

  function shouldRenderAreaOfConcern(
    areaOfConcern: AreaOfConcern,
    areaOfConcernViewportCoords: ViewportCoordinates
  ): boolean {
    if (!context.viewport.current) {
      return false;
    }

    // Find the vertex furthest from the center of the polygon
    const vertexDistanceApproximations = areaOfConcern.vertices.map((v) =>
      Math.max(
        Math.abs(v.x - areaOfConcern.position.x),
        Math.abs(v.y - areaOfConcern.position.y)
      )
    );
    const keyDimension = Math.max(...vertexDistanceApproximations);

    const keyDimensionInPx =
      keyDimension * context.floorplan.scale * context.viewport.current.zoom;

    return isWithinViewport(
      context,
      areaOfConcernViewportCoords,
      -1 * keyDimensionInPx
    );
  }

  const snapAreaOfConcernSides = useCallback(
    (
      areaOfConcern: AreaOfConcern,
      newPosition: FloorplanCoordinates
    ): FloorplanCoordinates => {
      if (!context.viewport.current) {
        return newPosition;
      }

      const allEdges: Array<[FloorplanCoordinates, FloorplanCoordinates]> =
        areaOfConcernEdges.current
          .filter((aoc) => aoc[0] !== areaOfConcern.id)
          .map(([_areaOfConcernId, [a, b]]) => [a, b]);

      const edges = AreaOfConcern.edges(areaOfConcern.vertices);

      //let bestSnapPartner: [FloorplanCoordinates, FloorplanCoordinates] | null = null;
      let bestDistance: number = Infinity;
      //const parallelEdges = [];
      for (const e of edges) {
        for (const e2 of allEdges) {
          let dist = isParallel(e, e2);

          if (dist === 0) {
            console.log('OVERLAP!');
          }

          if (dist !== null && dist <= bestDistance) {
            //bestSnapPartner = e2;
            bestDistance = dist;
          }
        }
      }

      //console.log(bestDistance);
      // This needs to be scalable in pixels
      if (bestDistance < 0.2) {
        return { ...newPosition, x: newPosition.x - bestDistance };
      }

      return newPosition;
    },
    [context.viewport]
  );

  const snapAreaOfConcernToWallsAndOtherAreaOfConcerns = useCallback(
    (
      areaOfConcern: AreaOfConcern,
      newPosition: FloorplanCoordinates
    ): FloorplanCoordinates => {
      if (!context.viewport.current) {
        return newPosition;
      }

      // Snap to walls if they exist
      if (walls.length === 0 && areasOfConcern.length === 0) {
        return newPosition;
      }

      const verticesPlusPosition = [...areaOfConcern.vertices, newPosition];
      let [upperLeft, lowerRight] =
        computeBoundingRegionExtents(verticesPlusPosition);

      if (!upperLeft || !lowerRight) {
        return newPosition;
      }

      // Pad the bounding box a little on each side so that wall segments within the snap
      // threshold outside of the regular bounding region will be included
      const paddingInPixels =
        WALL_SEGMENT_SNAP_THRESHOLD_PIXELS *
        context.floorplan.scale *
        context.viewport.current.zoom;
      upperLeft = FloorplanCoordinates.create(
        upperLeft.x - paddingInPixels,
        upperLeft.y - paddingInPixels
      );
      lowerRight = FloorplanCoordinates.create(
        lowerRight.x + paddingInPixels,
        lowerRight.y + paddingInPixels
      );

      const wallSegmentsInRegion =
        WallSegment.computeWallSegmentsInBoundingRegion(
          [
            ...walls,
            // FIXME: converting each areaOfConcern into "wallsegments" like this seems like a bit of
            // a bad idea. Come up with a better way of doing this? Like create a more
            // abstract notion of a "Segment" where a "WallSegment" is a more specific
            // version?
            ...areaOfConcernEdges.current.map(([_areaOfConcernId, [a, b]]) =>
              WallSegment.create(a, b)
            ),
          ],
          upperLeft,
          lowerRight
        );

      const [snapPoint] = WallSegment.computeNearestSnapPointInBoundingRegion(
        wallSegmentsInRegion,
        newPosition,
        upperLeft,
        lowerRight,
        context.floorplan,
        context.viewport.current,
        WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
      );

      if (snapPoint) {
        return snapPoint;
      }

      return newPosition;
    },
    [context.floorplan, context.viewport, areasOfConcern.length, walls]
  );

  return (
    <ObjectLayer
      objects={aocsPlusDuplicatedAreaOfConcern}
      extractId={(areaOfConcern) =>
        `${areaOfConcern.id},${areaOfConcern.vertices.length}`
      }
      onCreate={(getAreaOfConcern) => {
        // Called on first render, the pixi behavior is defined here
        if (!context.viewport.current) {
          return null;
        }

        const container = new PIXI.Container();

        const shape = new PIXI.Graphics();
        shape.name = 'shape';
        shape.interactive = true;
        shape.cursor = 'grab';
        container.addChild(shape);

        shape.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          } else if (!latestOnResize.current) {
            return;
          }

          let isDuplicating = evt.data.originalEvent.altKey;

          if (latestOnMouseDown.current) {
            latestOnMouseDown.current(getAreaOfConcern(), evt);
          }

          if (latestLocked.current || getAreaOfConcern().locked) {
            if (isDuplicating) {
              toast.error(
                'You must unlock the Area of Coverage before duplicating.'
              );
            }
            return;
          }

          // If a user clicks on an area of concern when near the edge, create a new vertex
          if (
            selectedAreaOfConcern.current &&
            getAreaOfConcern().id === selectedAreaOfConcern.current.id &&
            areaOfConcernVertexPosition.current
          ) {
            const position = selectedAreaOfConcern.current.position;
            const vertices = selectedAreaOfConcern.current.vertices.slice();
            vertices.splice(
              areaOfConcernVertexPosition.current.vertexBIndex,
              0,
              areaOfConcernVertexPosition.current.position
            );

            selectedAreaOfConcern.current = {
              ...selectedAreaOfConcern.current,
              vertices,
            };
            latestOnResize.current(getAreaOfConcern(), position, vertices);
            return;
          }

          // If a user alt/option clicked, then duplicate the AOC instead
          //
          // the `duplicatedAreaOfConcern` state stores the AOC that is being duplicated so that this
          // AOC (which is not yet in the `areasOfConcern` prop) can be rendered via the ObjectLayer
          if (latestOnDuplicateAreaOfConcern.current && isDuplicating) {
            const newAreaOfConcern = AreaOfConcern.duplicateAreaOfConcern(
              getAreaOfConcern()
            );
            newAreaOfConcern.name = AreaOfConcern.generateName(areasOfConcern);
            setDuplicatedAreaOfConcern(newAreaOfConcern);
          }

          //FIXME: Fix duplicating...

          // Otherwise, the user's trying to move the areaOfConcern
          //isSelectedAreaOfConcernMoving.current = true;

          addDragHandler(
            context,
            getAreaOfConcern().position,
            evt,
            (newPosition) => {
              if (!selectedAreaOfConcern.current) {
                return;
              }

              const oldPosition = selectedAreaOfConcern.current.position;

              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                position: newPosition,
              };

              if (isAOCSnappingEnabled) {
                newPosition = snapAreaOfConcernSides(
                  selectedAreaOfConcern.current,
                  newPosition
                );
              }

              // Snap to walls if they exist
              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                position: newPosition,
              };

              // Translate all vertices when moving areas of concern
              const positionDeltaX = newPosition.x - oldPosition.x;
              const positionDeltaY = newPosition.y - oldPosition.y;

              const newVertices = selectedAreaOfConcern.current.vertices.map(
                (v) =>
                  FloorplanCoordinates.create(
                    v.x + positionDeltaX,
                    v.y + positionDeltaY
                  )
              );

              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                position: newPosition,
                vertices: newVertices,

                // Translate origin position too
                sensorsOrigin: FloorplanCoordinates.create(
                  selectedAreaOfConcern.current.sensorsOrigin.x +
                    positionDeltaX,
                  selectedAreaOfConcern.current.sensorsOrigin.y + positionDeltaY
                ),
              };
            },
            () => {
              if (!selectedAreaOfConcern.current) {
                return;
              }

              // If the space was duplicated, then call a different method once the drag has
              // completed
              if (latestOnDuplicateAreaOfConcern.current && isDuplicating) {
                latestOnDuplicateAreaOfConcern.current(
                  selectedAreaOfConcern.current
                );
                setDuplicatedAreaOfConcern(null);
                return;
              }

              // Skip calling onDragMove if the space hasn't actually moved
              if (
                selectedAreaOfConcern.current.position.x ===
                  getAreaOfConcern().position.x &&
                selectedAreaOfConcern.current.position.y ===
                  getAreaOfConcern().position.y
              ) {
                return;
              }

              if (latestOnDragMove.current) {
                latestOnDragMove.current(
                  getAreaOfConcern(),
                  selectedAreaOfConcern.current.position
                );
              }
            }
          );
        });
        shape.on(
          'mouseover',
          (evt) =>
            latestOnMouseEnter.current &&
            latestOnMouseEnter.current(getAreaOfConcern(), evt)
        );
        shape.on(
          'mouseout',
          (evt) =>
            latestOnMouseLeave.current &&
            latestOnMouseLeave.current(getAreaOfConcern(), evt)
        );
        shape.on('mousemove', (event) => {
          // not dragging, see addDragHandler
          if (!context.viewport.current) {
            return;
          }

          const isFocused =
            selectedAreaOfConcern.current &&
            selectedAreaOfConcern.current.id === getAreaOfConcern().id;

          if (!isFocused) {
            return;
          }

          const positionViewport = ViewportCoordinates.create(
            event.data.global.x,
            event.data.global.y
          );

          // Figure out the vertices that the mouse position is in between
          let vertexAIndex = -1;
          let vertexBIndex = -1;
          let minDistance = Infinity;
          for (const [a, b] of AreaOfConcern.edges(
            getAreaOfConcern().vertices
          )) {
            const aViewport = FloorplanCoordinates.toViewportCoordinates(
              a,
              context.floorplan,
              context.viewport.current
            );
            const bViewport = FloorplanCoordinates.toViewportCoordinates(
              b,
              context.floorplan,
              context.viewport.current
            );

            const result = distanceToLineSegment(
              positionViewport,
              aViewport,
              bViewport
            );
            if (result < minDistance) {
              vertexAIndex = getAreaOfConcern().vertices.indexOf(a);
              vertexBIndex = getAreaOfConcern().vertices.indexOf(b);
              minDistance = result;
            }
          }

          // Only place vertices if the mouse is near an edge of the polygon
          if (minDistance > 2 * FOCUSED_OUTLINE_WIDTH_PX) {
            areaOfConcernVertexPosition.current = null;
            return;
          }

          const position = ViewportCoordinates.toFloorplanCoordinates(
            positionViewport,
            context.viewport.current,
            context.floorplan
          );

          areaOfConcernVertexPosition.current = {
            vertexAIndex,
            vertexBIndex,
            position,
          };
        });

        const sensorPlacements = new PIXI.Graphics();
        sensorPlacements.name = 'sensor-placements';
        container.addChild(sensorPlacements);

        // Render a resize handle at every polygon vertex around the perimeter of the area of
        // coverage
        const resizeHandles = new PIXI.Container();
        resizeHandles.name = 'resize-handles';
        container.addChild(resizeHandles);

        const onResizeHandleReleased = () => {
          if (!selectedAreaOfConcern.current) {
            return;
          } else if (!latestOnResize.current) {
            return;
          }

          latestOnResize.current(
            getAreaOfConcern(),
            selectedAreaOfConcern.current.position,
            selectedAreaOfConcern.current.vertices
          );
        };

        const onResizeHandleDeleted = (index: number) => {
          if (!selectedAreaOfConcern.current) {
            return;
          } else if (selectedAreaOfConcern.current.vertices.length <= 3) {
            // Don't allow deleting a vertex if the resulting shape won't have three points
            return;
          } else if (!latestOnResize.current) {
            return;
          }

          // Remove the given resize handle
          const position = selectedAreaOfConcern.current.position;
          const vertices = selectedAreaOfConcern.current.vertices.slice();
          vertices.splice(index, 1);
          selectedAreaOfConcern.current = {
            ...selectedAreaOfConcern.current,
            vertices,
          };
          latestOnResize.current(getAreaOfConcern(), position, vertices);
        };

        for (
          let index = 0;
          index < getAreaOfConcern().vertices.length;
          index += 1
        ) {
          const resizeHandle = new ResizeHandle(
            context,
            (newPosition, _, event) => {
              if (!context.viewport.current) {
                return;
              }
              if (!selectedAreaOfConcern.current) {
                return;
              }

              // If we're working with a rectangular shape, snap to a square angle
              // on drag-resize if Shift is pressed.
              const _vertices = getAreaOfConcern().vertices;

              if (event.shiftKey && _vertices.length === 4) {
                const vertexBefore =
                  selectedAreaOfConcern.current.vertices[
                    index === 0 ? _vertices.length - 1 : index - 1
                  ];
                const vertexAfter =
                  selectedAreaOfConcern.current.vertices[
                    index === _vertices.length - 1 ? 0 : index + 1
                  ];
                const vertexOpposite =
                  selectedAreaOfConcern.current.vertices[(index + 2) % 4];

                const newPositionA = FloorplanCoordinates.create(
                  vertexBefore.x,
                  vertexAfter.y
                );
                const newPositionB = FloorplanCoordinates.create(
                  vertexAfter.x,
                  vertexBefore.y
                );
                const distA =
                  Math.pow(vertexOpposite.x - newPositionA.x, 2) +
                  Math.pow(vertexOpposite.y - newPositionA.y, 2);
                const distB =
                  Math.pow(vertexOpposite.x - newPositionB.x, 2) +
                  Math.pow(vertexOpposite.y - newPositionB.y, 2);

                if (distA > distB) {
                  newPosition = newPositionA;
                } else {
                  newPosition = newPositionB;
                }
              } else {
                if (isAOCSnappingEnabled) {
                  // Snap to walls if they exist
                  newPosition = snapAreaOfConcernToWallsAndOtherAreaOfConcerns(
                    selectedAreaOfConcern.current,
                    newPosition
                  );
                }
              }

              const vertices = selectedAreaOfConcern.current.vertices.slice();
              vertices[index] = newPosition;
              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                vertices,
              };
            },
            {
              color: toRawHex(Orange300),
              onRelease: onResizeHandleReleased,
              onDelete: () => onResizeHandleDeleted(index),
            }
          );
          resizeHandle.cursor = 'move';
          resizeHandles.addChild(resizeHandle);
        }

        // Render a marker to indicate the origin position
        const originHandleSprite = new PIXI.Sprite(originSpriteTexture);
        originHandleSprite.name = 'origin-handle';
        originHandleSprite.anchor.set(0.5, 0.5);
        originHandleSprite.interactive = false; // set in onUpdate when sensorsEnabled === true
        originHandleSprite.cursor = 'grab';
        originHandleSprite.on('mousedown', (evt) => {
          originHandleSprite.cursor = 'grabbing';
          addDragHandler(
            context,
            getAreaOfConcern().sensorsOrigin,
            evt,
            (newPosition) => {
              if (!selectedAreaOfConcern.current) {
                return;
              }
              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                sensorsOrigin: newPosition,
              };
            },
            () => {
              if (!selectedAreaOfConcern.current || !onDragOrigin) {
                return;
              }
              onDragOrigin(
                getAreaOfConcern(),
                selectedAreaOfConcern.current.sensorsOrigin
              );
              originHandleSprite.cursor = 'grab';
            }
          );
        });
        container.addChild(originHandleSprite);

        return container;
      }}
      onUpdate={(s: AreaOfConcern, areaOfConcernContainer: PIXI.Container) => {
        // Called at 60fps
        if (!context.viewport.current) {
          return;
        }
        const isFocused =
          focusedObject &&
          focusedObject.type === 'areaofconcern' &&
          focusedObject.id === s.id;
        const isHighlighted =
          bulkSelectedIds.has(s.id) ||
          (highlightedObject &&
            highlightedObject.type === 'areaofconcern' &&
            highlightedObject.id === s.id);

        const areaOfConcern: AreaOfConcern =
          isFocused && selectedAreaOfConcern.current
            ? selectedAreaOfConcern.current
            : s;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          areaOfConcern.position,
          context.floorplan,
          context.viewport.current
        );

        areaOfConcernContainer.renderable = shouldRenderAreaOfConcern(
          areaOfConcern,
          viewportCoords
        );
        if (!areaOfConcernContainer.renderable) {
          // When this object moves out of the Viewport, clean it up.
          // Without clearing it, the object was selectable by clicking
          // on the very border of the Viewport.

          const areasOfConcernShape = areaOfConcernContainer.getChildByName(
            'shape'
          ) as PIXI.Graphics | null;

          if (areasOfConcernShape) {
            areasOfConcernShape.clear();
          }
          return;
        }

        areaOfConcernContainer.x = viewportCoords.x;
        areaOfConcernContainer.y = viewportCoords.y;

        const areasOfConcernShape = areaOfConcernContainer.getChildByName(
          'shape'
        ) as PIXI.Graphics | null;
        if (!areasOfConcernShape) {
          return;
        }

        if (areaOfConcern.locked) {
          // When locked, only show a pointer
          areasOfConcernShape.cursor = 'pointer';
        } else if (isFocused && areaOfConcernVertexPosition.current) {
          // When a new point can be added, show a special cursor
          areasOfConcernShape.cursor = 'copy';
        } else {
          areasOfConcernShape.cursor = 'grab';
        }
        areasOfConcernShape.clear();
        areasOfConcernShape.beginFill(
          areaOfConcern.sensorsEnabled
            ? toRawHex(Gray300)
            : toRawHex(Orange300),
          0.2
        );

        if (isHighlighted || isFocused) {
          areasOfConcernShape.lineStyle({
            width: FOCUSED_BORDER_WIDTH_PX,
            color: toRawHex(Orange300),
            join: PIXI.LINE_JOIN.ROUND,
          });
        }

        // Render polygon
        const verticesViewport = areaOfConcern.vertices.map((v) => {
          if (!context.viewport.current) {
            throw new Error('This is impossible');
          }

          return ViewportCoordinates.create(
            (v.x - areaOfConcern.position.x) *
              context.floorplan.scale *
              context.viewport.current.zoom,
            (v.y - areaOfConcern.position.y) *
              context.floorplan.scale *
              context.viewport.current.zoom
          );
        });
        drawPolygon(areasOfConcernShape, verticesViewport);
        areasOfConcernShape.endFill();

        const resizeHandles = areaOfConcernContainer.getChildByName(
          'resize-handles'
        ) as PIXI.Container | undefined;
        if (!resizeHandles) {
          return;
        }

        if (isFocused) {
          // Add outline
          areasOfConcernShape.lineStyle({
            width: FOCUSED_OUTLINE_WIDTH_PX,
            color: toRawHex(Orange300),
            alpha: 0.2,
            alignment: 1,
            join: PIXI.LINE_JOIN.ROUND,
          });
          drawPolygon(areasOfConcernShape, verticesViewport);

          // Ensure resize handles are positioned in the right spots
          resizeHandles.visible =
            !latestLocked.current &&
            !areaOfConcern.locked &&
            (!areaOfConcern.sensorsEnabled ||
              (areaOfConcern.sensorsEnabled &&
                areaOfConcern.sensorPlacements.type === 'done'));

          for (
            let vertexIndex = 0;
            vertexIndex < verticesViewport.length;
            vertexIndex += 1
          ) {
            const handle = resizeHandles.children[vertexIndex];
            if (!handle) {
              continue;
            }

            handle.x = verticesViewport[vertexIndex].x;
            handle.y = verticesViewport[vertexIndex].y;
          }
        } else {
          resizeHandles.visible = false;
        }

        const sensorPlacements = areaOfConcernContainer.getChildByName(
          'sensor-placements'
        ) as PIXI.Graphics | null;
        if (!sensorPlacements) {
          return;
        }
        sensorPlacements.clear();

        // Draw the position of each sensor placement
        if (
          areaOfConcern.sensorsEnabled &&
          (areaOfConcern.sensorPlacements.type === 'loading' ||
            areaOfConcern.sensorPlacements.type === 'done')
        ) {
          for (const sensorPlacement of areaOfConcern.sensorPlacements.data) {
            const pointViewportOffsetX =
              sensorPlacement.positionOffset[0] *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const pointViewportOffsetY =
              sensorPlacement.positionOffset[1] *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const isActualCoverage =
              (areaOfConcern.coverageIntersectionWallsEnabled ||
                areaOfConcern.coverageIntersectionHeightMapEnabled) &&
              sensorPlacement.coveragePolygon.length > 0;

            sensorPlacements.lineStyle({
              width: 1,
              color: isActualCoverage ? toRawHex(Green400) : toRawHex(Blue400),
              join: PIXI.LINE_JOIN.ROUND,
            });
            sensorPlacements.beginFill(
              isActualCoverage ? toRawHex(Green400) : toRawHex(Blue400),
              0.5
            );

            let firstX: number | null = null;
            let firstY: number | null = null;
            for (const [x, y] of sensorPlacement.coveragePolygon) {
              const pointViewportOffsetX =
                x * context.floorplan.scale * context.viewport.current.zoom;
              const pointViewportOffsetY =
                y * context.floorplan.scale * context.viewport.current.zoom;

              if (firstX === null && firstY === null) {
                sensorPlacements.moveTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
                firstX = pointViewportOffsetX;
                firstY = pointViewportOffsetY;
              } else {
                sensorPlacements.lineTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
              }
            }
            if (firstX !== null && firstY !== null) {
              sensorPlacements.lineTo(firstX, firstY);
            }
            sensorPlacements.endFill();

            sensorPlacements.beginFill(toRawHex(Blue700), 1);
            sensorPlacements.lineStyle({
              width: 3,
              color: toRawHex(White),
              join: PIXI.LINE_JOIN.ROUND,
            });
            sensorPlacements.drawRoundedRect(
              pointViewportOffsetX - 5,
              pointViewportOffsetY - 5,
              10,
              10,
              2
            );
            sensorPlacements.endFill();
          }
          for (const autodetectedRoom of areaOfConcern.sensorPlacements
            .autodetectedRooms) {
            // Draw center point of room
            const centerViewportOffsetX =
              (autodetectedRoom.centerPoint.x - areaOfConcern.position.x) *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const centerViewportOffsetY =
              (autodetectedRoom.centerPoint.y - areaOfConcern.position.y) *
              context.floorplan.scale *
              context.viewport.current.zoom;

            sensorPlacements.lineStyle({
              width: 2,
              color: toRawHex(Yellow400),
              join: PIXI.LINE_JOIN.ROUND,
            });

            sensorPlacements.beginFill(toRawHex(Yellow400), 0.75);
            sensorPlacements.drawCircle(
              centerViewportOffsetX,
              centerViewportOffsetY,
              5
            );
            sensorPlacements.endFill();

            // Draw sensor placements within room
            let first = true;
            for (const point of autodetectedRoom.sensorPlacements) {
              const pointViewportOffsetX =
                (point.x - areaOfConcern.position.x) *
                context.floorplan.scale *
                context.viewport.current.zoom;
              const pointViewportOffsetY =
                (point.y - areaOfConcern.position.y) *
                context.floorplan.scale *
                context.viewport.current.zoom;

              sensorPlacements.drawCircle(
                pointViewportOffsetX,
                pointViewportOffsetY,
                5
              );

              if (first) {
                sensorPlacements.moveTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
                first = false;
              } else {
                sensorPlacements.lineTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
              }
            }

            // Draw polygon border of room
            let firstX: number | null = null;
            let firstY: number | null = null;
            for (const point of autodetectedRoom.polygon) {
              const pointViewportOffsetX =
                (point.x - areaOfConcern.position.x) *
                context.floorplan.scale *
                context.viewport.current.zoom;
              const pointViewportOffsetY =
                (point.y - areaOfConcern.position.y) *
                context.floorplan.scale *
                context.viewport.current.zoom;

              if (firstX === null && firstY === null) {
                sensorPlacements.moveTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
                firstX = pointViewportOffsetX;
                firstY = pointViewportOffsetY;
              } else {
                sensorPlacements.lineTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
              }
            }
            if (firstX !== null && firstY !== null) {
              sensorPlacements.lineTo(firstX, firstY);
            }
          }
        }

        // Update the position of the origin position sprite to reflext the origin position of the
        // sensors
        const originHandleSprite = areaOfConcernContainer.getChildByName(
          'origin-handle'
        ) as PIXI.Sprite | undefined;
        if (!originHandleSprite) {
          return;
        }

        originHandleSprite.interactive = areaOfConcern.sensorsEnabled === true;

        if (
          areaOfConcern.sensorsOrigin &&
          !areaOfConcern.locked &&
          areaOfConcern.sensorsEnabled &&
          areaOfConcern.sensorPlacements.type !== 'loading'
        ) {
          originHandleSprite.renderable = true;
          const sensorsOriginViewport =
            FloorplanCoordinates.toViewportCoordinates(
              areaOfConcern.sensorsOrigin,
              context.floorplan,
              context.viewport.current
            );
          originHandleSprite.x = sensorsOriginViewport.x - viewportCoords.x;
          originHandleSprite.y = sensorsOriginViewport.y - viewportCoords.y;
        } else {
          originHandleSprite.renderable = false;
        }
      }}
      onRemove={(areaOfConcern: AreaOfConcern, container) => {
        // Called only once when object gets deleted...
        container.getChildByName('shape').destroy();
        container.getChildByName('sensor-placements').destroy();
        container.getChildByName('resize-handles').destroy();

        // NOTE: The origin texture is shared between all area of concern instances, so don't
        // destroy the underlying texture
        container
          .getChildByName('origin-handle')
          .destroy({ texture: false, baseTexture: false });
      }}
    />
  );
};

export default AreasOfConcernLayer;
