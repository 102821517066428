import { v4 as uuidv4 } from 'uuid';
import robustPointInPolygon from 'robust-point-in-polygon';
import { union, MultiPolygon } from 'polygon-clipping';

import Floorplan from 'lib/floorplan';
import { State } from 'components/editor/state';

import { Meters, LengthUnit } from 'lib/units';
import {
  CADCoordinates,
  FloorplanCoordinates,
  computeBoundingRegionExtents,
  computeCentroid,
} from 'lib/geometry';

import {
  FloorplanV2AreaOfConcern,
  isFloorplanAreaOfConcernId,
  Unsaved,
} from 'lib/api';
import { ParsedPlanDXFAreaOfConcern } from 'lib/dxf';
import FloorplanCollection from 'lib/floorplan-collection';

export function displayAreaOfConcernTotalArea(
  areasOfConcern: FloorplanCollection<AreaOfConcern>,
  displayUnit: LengthUnit
) {
  // Given a collection of Areas, return total area in the desired unit of measurement.

  const polygons = FloorplanCollection.list(areasOfConcern).map((aoc) =>
    AreaOfConcern.toPolygon(aoc)
  );

  /*
  // This is the old way of doing things where overlaps would be double counted...
  let totalArea = 0;

  for (const areaOfConcern of FloorplanCollection.list(areasOfConcern)) {
    totalArea += areaOfConcern.areaMeters;
  }
  */

  let totalArea = multiPolygonArea(union(polygons));

  switch (displayUnit) {
    case 'feet_and_inches':
      totalArea = Meters.toSquareFeet(totalArea);
      return `${totalArea.toFixed(2)} sq. ft`;
    case 'inches':
      totalArea = Meters.toSquareInches(totalArea);
      return `${totalArea.toFixed(2)} sq. in`;
    case 'centimeters':
      totalArea = Meters.toSquareCentimeters(totalArea);
      return `${totalArea.toFixed(2)} sq. cm`;
    case 'millimeters':
      totalArea = Meters.toSquareMillimeters(totalArea);
      return `${totalArea.toFixed(2)} sq. mm`;
    case 'meters':
      return `${totalArea.toFixed(2)} sq. m`;
  }
}

function calculatePolygonArea(polygon: number[][][]): number {
  let area = 0;
  let negativeArea = 0;

  const n = polygon[0].length;

  for (let i = 0; i < n; i++) {
    const [x1, y1] = polygon[0][i];
    const [x2, y2] = polygon[0][(i + 1) % n];

    area += x1 * y2 - x2 * y1;
  }

  if (polygon.length > 1) {
    for (let p = 1; p < polygon.length; p++) {
      const ring = polygon[p];
      const m = ring.length;

      for (let i = 0; i < m; i++) {
        const [x1, y1] = ring[i];
        const [x2, y2] = ring[(i + 1) % m];

        negativeArea += x1 * y2 - x2 * y1;
      }
    }
  }

  return Math.abs(area + negativeArea) / 2;
}

function multiPolygonArea(polygons: MultiPolygon): number {
  // Given polygon-clipping's MultiPolygon, calculate the area.
  let area = 0;
  if (!polygons || polygons.length < 1) {
    return 0;
  }

  for (const polygon of polygons) {
    area += calculatePolygonArea(polygon);
  }

  return area;
}

export type AreaOfConcernSensorPlacementData = {
  positionOffset: [number, number];
  heightMeters: number;
  angleDegrees: number;
  coveragePolygon: Array<[number, number]>;
};

type AreaOfConcern = {
  id: string;
  name: string;
  position: FloorplanCoordinates;
  vertices: Array<FloorplanCoordinates>;
  locked: boolean;
  areaMeters: number;
  notes: string;

  sensorsEnabled: boolean;

  minimumExclusiveArea: number;
  sensorHeight: number;
  sensorBaseAngleDegrees: number;
  sensorsOrigin: FloorplanCoordinates;
  cadIdPrefix: string;
  safetyFactorPercentage: number;

  coverageIntersectionHeightMapEnabled: boolean;
  coverageIntersectionWallsEnabled: boolean;
  smallRoomMode: boolean;

  sensorPlacements:
    | { type: 'empty' }
    | {
        type: 'loading';
        startTimestamp: number;
        data: Array<AreaOfConcernSensorPlacementData>;
        autodetectedRooms: Array<{
          centerPoint: FloorplanCoordinates;
          sensorPlacements: Array<FloorplanCoordinates>;
          polygon: Array<FloorplanCoordinates>;
        }>;
      }
    | {
        type: 'done';
        data: Array<AreaOfConcernSensorPlacementData>;
        autodetectedRooms: Array<{
          centerPoint: FloorplanCoordinates;
          sensorPlacements: Array<FloorplanCoordinates>;
          polygon: Array<FloorplanCoordinates>;
        }>;
        ellapsedMilliseconds: number;
      }
    | { type: 'failed'; error: Error };
};

namespace AreaOfConcern {
  export function create(
    vertices: Array<FloorplanCoordinates>,
    name: string | null = null,
    notes: string = ''
  ): AreaOfConcern {
    const id = uuidv4();
    const position = computeCentroid(vertices);
    const sensorsOrigin = AreaOfConcern.generateInitialOriginPosition(vertices);

    const areaMeters = AreaOfConcern.area(vertices);

    const areaOfConcern: AreaOfConcern = {
      id,
      name: name ? name : id,
      vertices,
      position,
      locked: false,
      areaMeters,
      notes,

      sensorsEnabled: false,
      sensorsOrigin,

      minimumExclusiveArea: 4,
      sensorHeight: Meters.fromFeetAndInches(8, 0),
      sensorBaseAngleDegrees: 0,
      cadIdPrefix: '',
      safetyFactorPercentage: 65,

      coverageIntersectionHeightMapEnabled: false,
      coverageIntersectionWallsEnabled: false,
      smallRoomMode: false,

      sensorPlacements: { type: 'empty' },
    };

    return areaOfConcern;
  }

  export function duplicateAreaOfConcern(
    areaOfConcern: AreaOfConcern
  ): AreaOfConcern {
    const id = uuidv4();
    return { ...areaOfConcern, id };
  }
  // Given a ParsedPlanDXFAreaOfConcern generated from a DXF import, create a corresponding
  // AreaOfConcern that can be placed on the plan.
  //
  // This is used heavily within the DXF import workflow!
  export function createFromCADAreaOfConcern(
    processedCADAreaOfConcern: ParsedPlanDXFAreaOfConcern,
    areasOfConcern: State['areasOfConcern'],
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number
  ): AreaOfConcern {
    const vertices: Array<FloorplanCoordinates> =
      processedCADAreaOfConcern.vertices.map((v) =>
        CADCoordinates.toFloorplanCoordinates(
          v,
          floorplan,
          floorplanCADOrigin,
          cadFileUnitOrDefault,
          cadFileScaleOrDefault
        )
      );

    const areaOfConcern = AreaOfConcern.create(
      vertices,
      processedCADAreaOfConcern.name
    );

    areaOfConcern.locked = true;
    return areaOfConcern;
  }

  export function generateName(existingAreas: Array<AreaOfConcern>): string {
    // Looks for the highest space named "Space X" and returns Space X+1
    let maxNumFound = 0;
    for (let area of existingAreas) {
      let result = area.name.match(/^AOC (\d+)/);
      if (result && result.length === 2) {
        // the second result should be the capture group which is the number
        const areaNum = parseInt(result[1], 10);
        if (areaNum > maxNumFound) {
          maxNumFound = areaNum;
        }
      }
    }
    return `AOC ${maxNumFound + 1}`;
  }

  export function generateInitialOriginPosition(
    vertices: Array<FloorplanCoordinates>
  ): FloorplanCoordinates {
    // Given a polygon, generate a possible origin position to use when generating sensors within an
    // area of concern

    // The best origin position is the center of the axis aligned bounding box the vertices make up
    const [upperLeft, lowerRight] = computeBoundingRegionExtents(vertices);
    if (!upperLeft || !lowerRight) {
      throw new Error(
        `Unable to compute bounding box for ${vertices.length} vertices!`
      );
    }
    const centerOfBoundingBox = FloorplanCoordinates.create(
      upperLeft.x + (lowerRight.x - upperLeft.x) / 2,
      upperLeft.y + (lowerRight.y - upperLeft.y) / 2
    );
    let sensorsOrigin = centerOfBoundingBox;

    while (true) {
      // Check to see if the origin position is within the area of concern. If so - that's the
      // final origin position!
      const isOriginInsideAreaOfConcern =
        robustPointInPolygon(
          vertices.map((v) => [v.x, v.y]),
          [sensorsOrigin.x, sensorsOrigin.y]
        ) !== 1; /* 1 = outside polygon */
      if (isOriginInsideAreaOfConcern) {
        return sensorsOrigin;
      }

      // If not, pick a random origin position within the polygon bounding box
      sensorsOrigin = FloorplanCoordinates.create(
        Math.random() * (lowerRight.x - upperLeft.x) + upperLeft.x,
        Math.random() * (lowerRight.y - upperLeft.y) + upperLeft.y
      );
    }
  }

  export function area(
    vertices: AreaOfConcern['vertices'] | ParsedPlanDXFAreaOfConcern['vertices']
  ): number {
    if (!vertices || vertices.length < 3) {
      return 0;
    }

    const _vertices = vertices.map((coord) => [coord.x, coord.y]);
    const n = _vertices.length;

    let area = 0;
    for (let i = 0; i < n; i++) {
      area += _vertices[i][0] * _vertices[(i + 1) % n][1];
      area -= _vertices[i][1] * _vertices[(i + 1) % n][0];
    }
    return Math.abs(area / 2);
  }

  export function edges(
    vertices: Array<FloorplanCoordinates>
  ): Array<[FloorplanCoordinates, FloorplanCoordinates]> {
    if (vertices.length <= 1) {
      return [];
    } else if (vertices.length === 2) {
      return [[vertices[0], vertices[1]]];
    } else {
      const vertexPairs: Array<[FloorplanCoordinates, FloorplanCoordinates]> =
        [];
      for (let i = 0, j = 1; j < vertices.length; i++, j++) {
        vertexPairs.push([vertices[i], vertices[j]]);
      }
      vertexPairs.push([vertices[vertices.length - 1], vertices[0]]);
      return vertexPairs;
    }
  }

  // Given an AOC from the Plans api, construct a new AreaOfConcern.
  export function createFromFloorplanAreaOfConcern(
    floorplanAreaOfConcern: FloorplanV2AreaOfConcern
  ): AreaOfConcern {
    const vertices = floorplanAreaOfConcern.polygon_vertices.map((v) =>
      FloorplanCoordinates.create(
        v.x_from_origin_meters,
        v.y_from_origin_meters
      )
    );

    const areaOfConcern = AreaOfConcern.create(
      vertices,
      floorplanAreaOfConcern.name
    );

    areaOfConcern.id = floorplanAreaOfConcern.id;
    areaOfConcern.locked = floorplanAreaOfConcern.locked;
    areaOfConcern.notes = floorplanAreaOfConcern.notes;

    if (floorplanAreaOfConcern.minimum_exclusive_area)
      areaOfConcern.minimumExclusiveArea =
        floorplanAreaOfConcern.minimum_exclusive_area;

    if (floorplanAreaOfConcern.sensor_height)
      areaOfConcern.sensorHeight = floorplanAreaOfConcern.sensor_height;

    if (floorplanAreaOfConcern.sensor_height)
      areaOfConcern.sensorBaseAngleDegrees =
        floorplanAreaOfConcern.sensor_base_angle_degrees;

    if (floorplanAreaOfConcern.safety_factor_pct)
      areaOfConcern.safetyFactorPercentage =
        floorplanAreaOfConcern.safety_factor_pct;

    if (floorplanAreaOfConcern.coverage_intersection_heightmap_enabled)
      areaOfConcern.coverageIntersectionWallsEnabled =
        floorplanAreaOfConcern.coverage_intersection_heightmap_enabled;

    if (floorplanAreaOfConcern.small_room_mode)
      areaOfConcern.smallRoomMode = floorplanAreaOfConcern.small_room_mode;

    return areaOfConcern;
  }

  // Given an AOC, format it the normal represetation used by the floorplan api.
  export function toFloorplanAreaOfConcern(
    areaOfConcern: AreaOfConcern
  ): FloorplanV2AreaOfConcern | Unsaved<FloorplanV2AreaOfConcern> {
    // NOTE: Only include the id if it is an areaOfConcern id from the database (already saved areaOfConcern)
    // NOTE: Using `undefined` here means that when JSON.stringify is called on it, the key is not included
    const id = isFloorplanAreaOfConcernId(areaOfConcern.id)
      ? areaOfConcern.id
      : undefined;

    const floorplanAreaOfConcern:
      | FloorplanV2AreaOfConcern
      | Unsaved<FloorplanV2AreaOfConcern> = {
      id,
      name: areaOfConcern.name,
      locked: areaOfConcern.locked,
      polygon_vertices: areaOfConcern.vertices.map((v) => ({
        x_from_origin_meters: v.x,
        y_from_origin_meters: v.y,
      })),
      notes: areaOfConcern.notes,
      minimum_exclusive_area: areaOfConcern.minimumExclusiveArea,
      sensor_height: areaOfConcern.sensorHeight,
      sensor_base_angle_degrees: areaOfConcern.sensorBaseAngleDegrees,
      safety_factor_pct: areaOfConcern.safetyFactorPercentage,
      coverage_intersection_heightmap_enabled:
        areaOfConcern.coverageIntersectionHeightMapEnabled,
      small_room_mode: areaOfConcern.smallRoomMode,
      //sensor_placements: areaOfConcern.sensorPlacements,
    };
    return floorplanAreaOfConcern;
  }

  export function toPolygon(
    areaOfConcern: AreaOfConcern
  ): Array<Array<[number, number]>> {
    return [areaOfConcern.vertices.map((v) => [v.x, v.y])];
  }

  export function toAOCVertices(
    polygon: Array<Array<[number, number]>>
  ): Array<FloorplanCoordinates> {
    return polygon.flat().map((vertex) => {
      return FloorplanCoordinates.create(vertex[0], vertex[1]);
    });
  }
}

export default AreaOfConcern;
