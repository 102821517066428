import { useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from '@densityco/ui';
import { DataTable, DataTableData } from './data-table';
import { FloorplanV2Sensor } from 'lib/api';
import { SpaceBuildingManager } from './spaces-info';

type InfoSectionHeader = {
  id: string;
  label: string;
};

type InfoValue = {
  label: string;
  value: string | number;
  data?: FloorplanV2Sensor[] | SpaceBuildingManager[];
  headers?: InfoSectionHeader[];
  contents?: React.ReactNode;
};

type DataTableInfo = {
  show: boolean;
  data: FloorplanV2Sensor[] | SpaceBuildingManager[];
  headers: InfoSectionHeader[];
};

type InfoSectionProps = {
  title: string;
  data: InfoValue[];
  imageUrl?: string;
  link?: string;
  orgId: string;
  planId: string;
};

export const InfoSection: React.FunctionComponent<InfoSectionProps> = ({
  title,
  data,
  orgId,
  planId,
  imageUrl,
}) => {
  const [dataTable, setDataTable] = useState<DataTableInfo>({
    show: false,
    data: [],
    headers: [],
  });

  const showTable = (
    data: FloorplanV2Sensor[] | SpaceBuildingManager[],
    headers: InfoSectionHeader[]
  ) => {
    setDataTable({ show: true, data, headers });
  };

  return (
    <div className={styles.infoSectionCard}>
      {dataTable.show && dataTable.data.length > 0 && (
        <DataTable
          data={dataTable.data as DataTableData[]}
          headers={
            dataTable.headers as {
              id: 'floorName' | 'id' | 'floorId';
              label: string;
            }[]
          }
          type={
            dataTable.data[0].hasOwnProperty('sensor_function')
              ? 'sensor'
              : 'space'
          }
          closeModal={() =>
            setDataTable({ show: false, data: [], headers: [] })
          }
        />
      )}
      <Link
        className={styles.infoSectionTitle}
        to={`/${orgId}/floorplans/${planId}`}
      >
        {title}
      </Link>
      {imageUrl && (
        <Link
          to={`/${orgId}/floorplans/${planId}`}
          className={styles.infoSectionImage}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      )}
      {data.map((info) => {
        return (
          <Tooltip
            key={info.label}
            contents={info.contents}
            target={
              <div
                className={styles.infoSectionDataRow}
                onClick={
                  info.data && (() => showTable(info.data!, info.headers!))
                }
                style={{
                  cursor: info.contents ? 'pointer' : 'default',
                  userSelect: info.contents ? 'none' : 'auto',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>{info.label}: </span>
                {info.value}
              </div>
            }
          />
        );
      })}
    </div>
  );
};
