import { FunctionComponent, useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Icons } from '@density/dust';
import pointInPolygon from 'point-in-polygon';
import { intersection } from 'polygon-clipping';
import { Action } from './actions';
import { State, UndoStack } from './state';
import styles from './styles.module.scss';

import Button from 'components/button';
import Tooltip from 'components/tooltip';
import SpaceMetaCSVImport from './csv-space-metadata-error-modal';
import Space from 'lib/space';
import { convertUTCtoLocalDateString } from 'lib/date-time';
import PlanSensor, { PlanSensorFunction } from 'lib/sensor';
import {
  CSVImportUpdate,
  CSVImportError,
  CSVImportMutation,
  SpaceMetaCSVImportError,
  SpaceMetaCSVImportUpdate,
} from 'lib/csv';

import { FloorplanV2Plan, FloorplanAPI, CoreAPI } from 'lib/api';
import FloorplanCollection from 'lib/floorplan-collection';

const CSVPanel: FunctionComponent<{
  state: State;
  floorName: string;
  client: AxiosInstance;
  plan: FloorplanV2Plan;
  dispatch: React.Dispatch<Action>;
}> = ({ state, client, floorName, plan, dispatch }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [showCSVInput, setShowCSVInput] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [metadataCSVErrors, setMetadataCSVErrors] = useState<
    Array<SpaceMetaCSVImportError>
  >([]);
  // Fetch a list of all space functions to use in the dropdown
  const [spaceFunctions, setSpaceFunctions] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | {
        status: 'complete';
        data: Array<{
          function: string;
          function_display_name: string;
          program: string;
          program_display_name: string;
        }>;
      }
    | { status: 'error' }
  >(
    // FIXME: this cached space functions thing is really gross and probably is going to break
    // someday... but the idea is to somehow cache this data so that when the second focused panel
    // opens, it won't need to refetch this data
    (window as any).CACHED_SPACE_FUNCTIONS
      ? { status: 'complete', data: (window as any).CACHED_SPACE_FUNCTIONS }
      : { status: 'pending' }
  );
  useEffect(() => {
    if (spaceFunctions.status !== 'pending') {
      return;
    }
    const abortController = new AbortController();

    setSpaceFunctions({ status: 'loading', abortController });
    CoreAPI.getSpaceFunctions(client, abortController.signal)
      .then((response) => {
        (window as any).CACHED_SPACE_FUNCTIONS = response.data;
        setSpaceFunctions({
          status: 'complete',
          data: response.data,
        });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setSpaceFunctions({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setSpaceFunctions({ status: 'pending' });
    };
    // FIXME: Ignore dependencies on this because adding one on spaceFunctions.status will cause this
    // hook to run way too often, and I can't figure out an easy way to work around this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    setUploading(state.csvImport.uploading);
  }, [state.csvImport.uploading]);

  useEffect(() => {
    let csvUploadInput: HTMLInputElement;
    if (showCSVInput) {
      csvUploadInput = document.querySelector('#csv-input') as HTMLInputElement;
      csvUploadInput.click();
    }
  }, [showCSVInput, client, dispatch]);

  const arrayToCsv = (
    headers: { label: string; key: string }[],
    data: { [key: string]: any }[]
  ) => {
    const csvRows: string[] = [];

    const headerValues = headers.map((header) => header.label);
    csvRows.push(headerValues.join(','));

    for (const row of data) {
      const rowValues = headers.map((header) => {
        const escaped = ('' + row[header.key]).replace(/"/g, '""');
        if (escaped.includes(',')) {
          return `"${escaped}"`;
        }
        return `${escaped}`;
      });
      csvRows.push(rowValues.join(','));
    }

    return csvRows.join('\n');
  };

  const download = (data: string, fileName: string) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateCSV = (
    header: { label: string; key: string }[],
    data: { [key: string]: any }[],
    filename: string
  ) => {
    const csvData = arrayToCsv(header, data);
    download(csvData, filename);
  };

  return (
    <div className={styles.exportPanel}>
      <Tooltip
        contents="Import/Export CSV"
        placement="bottom"
        target={
          <Button
            onClick={() =>
              dispatch({
                type: 'panels.csv.setState',
                setOpen: !state.panels.csvOpen,
              })
            }
            trailingIcon={<Icons.OnePagerPaperDoc size={18} />}
            size="medium"
            type="outlined"
            //disabled={state.activeDXFId === null}
            data-cy="export-csv-panel-button"
          />
        }
      />
      <div
        className={classnames(styles.exportMenu, {
          [styles.open]: state.panels.csvOpen,
        })}
      >
        <div className={styles.csvMenuSection}>
          <>
            <div className={styles.settingsMenuRowHeader}>
              Import Sensor Serials
            </div>
            Link serial numbers to CAD IDs by uploading a .csv mapping. Two
            columns expected.
            <Button
              size="small"
              trailingIcon={<Icons.UploadArrow size={18} />}
              disabled={uploading}
              onClick={() => {
                const inputTypeFile = document.createElement('input');
                inputTypeFile.type = 'file';
                inputTypeFile.accept = 'text/csv';
                inputTypeFile.style.display = 'none';
                document.body.appendChild(inputTypeFile);

                inputTypeFile.addEventListener('change', (e) => {
                  // Once a file is selected, remove the dummy input[type=file]
                  document.body.removeChild(inputTypeFile);

                  const target = e.target as HTMLInputElement;
                  if (!target.files) {
                    return;
                  }

                  const file = target.files[0];
                  if (!file) {
                    return;
                  }

                  dispatch({
                    type: 'csvImport.uploadStarted',
                  });

                  const reader = new FileReader();
                  reader.onload = async (e) => {
                    if (!e.target) {
                      return;
                    }
                    const dataUrl = e.target.result;
                    if (!dataUrl || dataUrl instanceof ArrayBuffer) {
                      return;
                    }

                    const [, imageData] = dataUrl.split(',');

                    let csvBytesAsString = null;
                    try {
                      csvBytesAsString = atob(imageData);
                    } catch (err) {
                      toast.error(
                        'There is something wrong with the file you uploaded! Is it a text file?'
                      );
                      dispatch({
                        type: 'csvImport.uploadCompleted',
                      });
                      return;
                    }
                    // Convert data to Uint8Array
                    const byteNumbers = new Array(csvBytesAsString.length);
                    for (let i = 0; i < csvBytesAsString.length; i++) {
                      byteNumbers[i] = csvBytesAsString.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    // Create a Blob from the Uint8Array
                    const blob = new Blob([byteArray], { type: 'text/csv' });

                    // Create a File object from the Blob
                    const file = new File([blob], 'filename.csv', {
                      type: 'text/csv',
                    });

                    try {
                      const response = await FloorplanAPI.uploadCSV(
                        client,
                        plan.id,
                        file
                      );

                      dispatch({
                        type: 'csvImport.uploadCompleted',
                      });

                      const csvErrors: Array<CSVImportError> =
                        response.data.errors.map((e) => {
                          return {
                            cadId: e.placement_id || null,
                            serialNumber: e.serial_num || null,
                            errorMessage: e.message
                              ? e.message
                              : (() => {
                                  // If there is an error type that lacks details populated in core_api,
                                  // it can be addressed here.
                                  switch (e.error_type) {
                                    default:
                                      return 'No error details.';
                                  }
                                })(),
                            errorType: e.error_type,
                          };
                        });

                      const csvUpdates: Array<CSVImportUpdate> =
                        response.data.updates.updates.map((e) => {
                          return {
                            cadId: e.body.cad_id,
                            serialNumber: e.body.sensor_serial_number,
                            oldSerialNumber: null,
                          };
                        });

                      dispatch({
                        type: 'csvImport.begin',
                        mutations: {
                          forwards: response.data.updates.updates.map((c) => {
                            return c as CSVImportMutation;
                          }),
                          backwards: response.data.undos.updates.map((c) => {
                            return c as CSVImportMutation;
                          }),
                        },
                        csvErrors,
                        csvUpdates,
                      });
                    } catch (err) {
                      dispatch({
                        type: 'csvImport.uploadCompleted',
                      });

                      toast.error('Error uploading the .csv file!');
                    }
                  };
                  reader.onerror = () => {
                    console.error('Error reading cloud plan file!');
                  };
                  reader.readAsDataURL(file);
                });

                inputTypeFile.click();
              }}
            >
              {uploading ? 'Uploading... ' : 'Upload CSV '}&nbsp;
            </Button>
          </>
          <>
            <div className={styles.settingsMenuRowHeader}>
              Export Sensors & Space Info (FOV)
            </div>
            Export info about sensors and any Space that intersects their field
            of view (FOV).
            <Button
              size="small"
              trailingIcon={<Icons.DownloadArrow size={18} />}
              disabled={
                FloorplanCollection.list(state.planSensors).length === 0
              }
              onClick={() => {
                const sensorData = [];

                for (let sensor of FloorplanCollection.list(
                  state.planSensors
                )) {
                  // get a polygon representing the field of view of the sensor.
                  const polygonFoV = PlanSensor.getSensorFoV(sensor);
                  // close the polygon
                  polygonFoV[0].push(polygonFoV[0][0]);

                  const spaceNames = [];
                  const spaceIDs = [];

                  for (let space of FloorplanCollection.list(state.spaces)) {
                    const vertices = Space.toPolygon(space);
                    // must close the polygon
                    vertices[0].push(vertices[0][0]);

                    if (intersection(polygonFoV, vertices).length > 0) {
                      if (space.name.includes(',')) {
                        spaceNames.push("'" + space.name + "'");
                      } else {
                        spaceNames.push(space.name);
                      }
                      spaceIDs.push(space.id);
                    }
                  }

                  sensorData.push({
                    cad_id: sensor.cadId,
                    serial_number: sensor.serialNumber
                      ? sensor.serialNumber
                      : '',
                    sensor_type: sensor.type,
                    sensor_function: PlanSensorFunction.generateDisplayName(
                      sensor.sensorFunction
                    ),
                    height_meters: sensor.height,
                    status: sensor.status,
                    last_heartbeat: sensor.last_heartbeat
                      ? convertUTCtoLocalDateString(sensor.last_heartbeat)
                      : '',
                    ip_address: sensor.ipv4 || '',
                    mac_address: sensor.mac || '',
                    os: sensor.os || '',
                    space_names: spaceNames.length > 0 ? spaceNames : '',
                    space_ids: spaceIDs.length > 0 ? spaceIDs : '',
                  });
                }

                const csvHeader = [
                  { label: 'CAD ID', key: 'cad_id' },
                  { label: 'Serial Number', key: 'serial_number' },
                  { label: 'Sensor Type', key: 'sensor_type' },
                  { label: 'Sensor Function', key: 'sensor_function' },
                  { label: 'Height (Meters)', key: 'height_meters' },
                  { label: 'Status', key: 'status' },
                  { label: 'Last Heartbeat', key: 'last_heartbeat' },
                  { label: 'IP Address', key: 'ip_address' },
                  { label: 'MAC', key: 'mac_address' },
                  { label: 'OS', key: 'os' },
                  { label: 'Spaces Measured (Name)', key: 'space_names' },
                  { label: 'Spaces Measured (ID)', key: 'space_ids' },
                ];

                // Replace all special characters and whitespace.
                const filename = `${Date.now()}-${
                  plan.id
                }-${floorName}`.replace(/[^a-zA-Z0-9-_]/g, '_');

                generateCSV(csvHeader, sensorData, filename);
              }}
            >
              Download CSV &nbsp;
            </Button>
          </>
          <>
            <div className={styles.settingsMenuRowHeader}>
              Export Sensors & Space Info
            </div>
            Export info about sensors and any space that they are physically
            located in (see above for FOV intersections with Spaces).
            <Button
              size="small"
              trailingIcon={<Icons.DownloadArrow size={18} />}
              disabled={
                FloorplanCollection.list(state.planSensors).length === 0
              }
              onClick={() => {
                const sensorData = [];

                for (let sensor of FloorplanCollection.list(
                  state.planSensors
                )) {
                  const spaceNames = [];
                  const spaceIDs = [];

                  for (let space of FloorplanCollection.list(state.spaces)) {
                    const polygonSpace = Space.toPolygon(space);

                    if (
                      pointInPolygon(
                        [sensor.position.x, sensor.position.y],
                        polygonSpace.flat()
                      )
                    ) {
                      if (space.name.includes(',')) {
                        spaceNames.push("'" + space.name + "'");
                      } else {
                        spaceNames.push(space.name);
                      }
                      spaceIDs.push(space.id);
                    }
                  }

                  sensorData.push({
                    cad_id: sensor.cadId,
                    serial_number: sensor.serialNumber
                      ? sensor.serialNumber
                      : '',
                    sensor_type: sensor.type,
                    sensor_function: PlanSensorFunction.generateDisplayName(
                      sensor.sensorFunction
                    ),
                    status: sensor.status,
                    height_meters: sensor.height,
                    last_heartbeat: sensor.last_heartbeat
                      ? convertUTCtoLocalDateString(sensor.last_heartbeat)
                      : '',
                    ip_address: sensor.ipv4 || '',
                    mac_address: sensor.mac || '',
                    os: sensor.os || '',
                    space_names: spaceNames.length > 0 ? spaceNames : '',
                    space_ids: spaceIDs.length > 0 ? spaceIDs : '',
                  });
                }

                const csvHeader = [
                  { label: 'CAD ID', key: 'cad_id' },
                  { label: 'Serial Number', key: 'serial_number' },
                  { label: 'Sensor Type', key: 'sensor_type' },
                  { label: 'Sensor Function', key: 'sensor_function' },
                  { label: 'Height (Meters)', key: 'height_meters' },
                  { label: 'Status', key: 'status' },
                  { label: 'Last Heartbeat', key: 'last_heartbeat' },
                  { label: 'IP Address', key: 'ip_address' },
                  { label: 'MAC', key: 'mac_address' },
                  { label: 'OS', key: 'os' },
                  { label: 'Inside Space Names', key: 'space_names' },
                  { label: 'Inside Space IDs', key: 'space_ids' },
                ];

                // Replace all special characters and whitespace.
                const filename = `${Date.now()}-${
                  plan.id
                }-${floorName}`.replace(/[^a-zA-Z0-9-_]/g, '_');

                generateCSV(csvHeader, sensorData, filename);
              }}
            >
              Download CSV &nbsp;
            </Button>
          </>

          <>
            <div className={styles.settingsMenuRowHeader}>
              Import Space Metadata
            </div>
            {showCSVInput && (
              <>
                <input
                  type="file"
                  id="csv-input"
                  accept="text/csv"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    if (!target.files) {
                      return;
                    }

                    const file = target.files[0];
                    if (!file) {
                      return;
                    }

                    dispatch({
                      type: 'spaceMetaCSVImport.uploadStarted',
                    });

                    const reader = new FileReader();
                    reader.onload = async (e) => {
                      if (!e.target) {
                        return;
                      }
                      const dataUrl = e.target.result;
                      if (!dataUrl || dataUrl instanceof ArrayBuffer) {
                        return;
                      }

                      const [, imageData] = dataUrl.split(',');

                      let csvBytesAsString = null;
                      try {
                        csvBytesAsString = atob(imageData);
                      } catch (err) {
                        toast.error(
                          'There is something wrong with the file you uploaded! Is it a text file?'
                        );
                        dispatch({
                          type: 'spaceMetaCSVImport.uploadCompleted',
                        });
                        return;
                      }
                      // Convert data to Uint8Array
                      const byteNumbers = new Array(csvBytesAsString.length);
                      for (let i = 0; i < csvBytesAsString.length; i++) {
                        byteNumbers[i] = csvBytesAsString.charCodeAt(i);
                      }
                      const byteArray = new Uint8Array(byteNumbers);

                      // Create a Blob from the Uint8Array
                      const blob = new Blob([byteArray], { type: 'text/csv' });

                      // Create a File object from the Blob
                      const file = new File([blob], 'filename.csv', {
                        type: 'text/csv',
                      });

                      try {
                        // Accessing the current URI to get the org id to pass to the request
                        const orgId = window.location.pathname.split('/')[1];
                        const response = await CoreAPI.bulkUpdateSpaceMetadata(
                          client,
                          orgId,
                          file
                        );

                        dispatch({
                          type: 'spaceMetaCSVImport.uploadCompleted',
                        });

                        const spaceMetaCsvErrors: Array<SpaceMetaCSVImportError> =
                          response.data.failed_space_updates.map((e: any) => {
                            return {
                              spaceId: e.space_id,
                              errorMessage: e.error
                                ? e.error
                                : (() => {
                                    // If there is an error message that lacks details populated in core_api,
                                    // it can be addressed here.
                                    return 'No error details.';
                                  })(),
                            };
                          });
                        spaceMetaCsvErrors.push(
                          ...response.data.label_failures.map((e: any) => {
                            return {
                              spaceId: e.space_id,
                              errorMessage: e.error
                                ? e.error
                                : (() => {
                                    // If there is an error message that lacks details populated in core_api,
                                    // it can be addressed here.
                                    return 'No error details.';
                                  })(),
                            };
                          })
                        );

                        if (spaceMetaCsvErrors.length > 0) {
                          setShowErrorModal(true);
                          setMetadataCSVErrors(spaceMetaCsvErrors);
                          toast.error('Some errors found!');
                        } else {
                          toast.success('Successfully uploaded metadata!');
                        }

                        const spaceMetaCsvUpdates: Array<SpaceMetaCSVImportUpdate> =
                          response.data.space_updates.map((e) => {
                            return {
                              spaceId: e.space_id,
                              name: e.name,
                            };
                          });

                        if (spaceMetaCsvUpdates.length > 0) {
                          UndoStack.clear(state.undoStack);
                        }

                        dispatch({
                          type: 'spaceMetaCSVImport.begin',
                          spaceMetaCsvErrors,
                          spaceMetaCsvUpdates,
                        });
                      } catch (err: any) {
                        dispatch({
                          type: 'csvImport.uploadCompleted',
                        });
                        toast.error('Error uploading the .csv file!');

                        const spaceMetaCsvErrors: Array<SpaceMetaCSVImportError> =
                          err.response.data.failed_space_updates.map(
                            (e: any) => {
                              return {
                                spaceId: e.space_id,
                                errorMessage: e.error
                                  ? e.error
                                  : (() => {
                                      // If there is an error message that lacks details populated in core_api,
                                      // it can be addressed here.
                                      return 'No error details.';
                                    })(),
                              };
                            }
                          );
                        spaceMetaCsvErrors.push(
                          ...err.response.data.label_failures.map((e: any) => {
                            return {
                              spaceId: e.space_id,
                              errorMessage: e.error
                                ? e.error
                                : (() => {
                                    // If there is an error message that lacks details populated in core_api,
                                    // it can be addressed here.
                                    return 'No error details.';
                                  })(),
                            };
                          })
                        );

                        if (spaceMetaCsvErrors.length > 0) {
                          setShowErrorModal(true);
                          setMetadataCSVErrors(spaceMetaCsvErrors);
                        }
                      }
                    };
                    reader.onerror = () => {
                      console.error('Error reading file!');
                    };
                    reader.readAsDataURL(file);
                    setShowCSVInput(false);
                  }}
                />
              </>
            )}
            {showErrorModal && (
              <>
                <SpaceMetaCSVImport
                  csvErrors={metadataCSVErrors}
                  onClose={() => setShowErrorModal(false)}
                />
              </>
            )}
            Bulk import space metadata in this floorplan such as space
            functions, capacity, iwms mappings, and labels. NOTE: This will
            clear the undo/redo queue.
            <Button
              size="small"
              trailingIcon={<Icons.DownloadArrow size={18} />}
              disabled={FloorplanCollection.list(state.spaces).length === 0}
              onClick={() => {
                const spacesData = [];

                const functionDisplayNames: { [key: string]: any } = {};

                if (spaceFunctions.status === 'complete') {
                  spaceFunctions.data.forEach((spaceFunction) => {
                    functionDisplayNames[spaceFunction.function] =
                      spaceFunction.function_display_name;
                  });
                }

                for (let space of FloorplanCollection.list(state.spaces)) {
                  const labelsList = [];
                  for (let label of space.coreSpaceLabels) {
                    labelsList.push(label.name);
                  }
                  spacesData.push({
                    space_id: space.id,
                    space_name: space.name,
                    capacity: space.coreSpaceCapacity
                      ? space.coreSpaceCapacity
                      : '',
                    iwms_id: space.iwmsId ? space.iwmsId : '',
                    space_function: space.coreSpaceFunction
                      ? functionDisplayNames[space.coreSpaceFunction]
                      : '',
                    labels: labelsList,
                    labels_to_remove: '',
                  });
                }

                const csvHeader = [
                  { label: 'Space ID', key: 'space_id' },
                  { label: 'Name', key: 'space_name' },
                  { label: 'Capacity', key: 'capacity' },
                  { label: 'IWMS ID', key: 'iwms_id' },
                  { label: 'Function', key: 'space_function' },
                  { label: 'Labels To Add', key: 'labels' },
                  { label: 'Labels To Remove', key: 'labels_to_remove' },
                ];

                const filename = `${floorName.replace(
                  ' ',
                  '-'
                )}-space-metadata`;

                generateCSV(csvHeader, spacesData, filename);
              }}
            >
              Download Template &nbsp;
            </Button>
            <Button
              size="small"
              trailingIcon={<Icons.UploadArrow size={18} />}
              disabled={uploading}
              onClick={() => {
                setShowCSVInput(true);
              }}
            >
              {uploading ? 'Uploading... ' : 'Upload CSV '}&nbsp;
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default CSVPanel;
