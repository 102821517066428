import * as React from 'react';
import styles from './styles.module.scss';

import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const LoadingProgressCircle: React.FunctionComponent<{
  numerator: number;
  denominator: number;
  size?: number;
}> = ({ numerator, denominator, size = 14 }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.fraction}>
        {numerator} / {denominator}
      </span>
      <div style={{ width: size, height: size }}>
        <CircularProgressbar
          value={numerator / denominator}
          minValue={0}
          maxValue={1}
          styles={{
            root: { verticalAlign: 'unset' },
            path: { stroke: Blue400, strokeWidth: 10 },
            trail: { stroke: 'transparent' },
          }}
        />
      </div>
    </div>
  );
};

export default LoadingProgressCircle;
