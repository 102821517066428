import AreaOfConcern from 'lib/area-of-concern';
import { FloorplanCoordinates } from 'lib/geometry';
import PlanSensor from 'lib/sensor';
import Space from 'lib/space';

/**
 * Function to calculate the distance between two points within a certain angle scope.
 * @param candidateObject
 * @returns
 */

export const selectClosestObject = (
  focusedObject: { type: string; id: string },
  objectMap:
    | ReadonlyMap<string, PlanSensor>
    | ReadonlyMap<string, Space>
    | ReadonlyMap<string, AreaOfConcern>,
  direction: string
) => {
  // check if the object is in the direction of the focused object
  const checkDirection = (
    direction: string,
    objectPosition: FloorplanCoordinates,
    current: FloorplanCoordinates
  ) => {
    switch (direction) {
      case 'up':
        return objectPosition.y < current.y;
      case 'down':
        return objectPosition.y > current.y;
      case 'left':
        return objectPosition.x < current.x;
      case 'right':
        return objectPosition.x > current.x;
      default:
    }
  };

  // check if the object is within the angle scope of the focused object
  const isWithinAngleScope = (
    candidateObject: FloorplanCoordinates,
    focusedObject: FloorplanCoordinates,
    maxAngleDegrees: number = 40
  ) => {
    const deltaX = candidateObject.x - focusedObject.x;
    const deltaY = candidateObject.y - focusedObject.y;
    const angleRadians = Math.atan2(deltaY, deltaX);

    // Convert the angle to degrees
    const angleDegrees = angleRadians * (180 / Math.PI);

    // Define the angle limits based on direction
    let lowerBound, upperBound;
    switch (direction) {
      case 'right':
        lowerBound = -maxAngleDegrees;
        upperBound = maxAngleDegrees;
        break;
      case 'left':
        lowerBound = 180 - maxAngleDegrees;
        upperBound = 180 + maxAngleDegrees;
        break;
      case 'up':
        lowerBound = -90 - maxAngleDegrees;
        upperBound = -90 + maxAngleDegrees;
        break;
      case 'down':
        lowerBound = 90 - maxAngleDegrees;
        upperBound = 90 + maxAngleDegrees;
        break;
      default:
        return false;
    }

    // Check if the angle is within the bounds
    if (lowerBound < -180) lowerBound += 360; // Correct lower bound wrap-around
    if (upperBound > 180) upperBound -= 360; // Correct upper bound wrap-around

    if (lowerBound <= upperBound) {
      return angleDegrees >= lowerBound && angleDegrees <= upperBound;
    } else {
      // If the angle wraps past -180 or 180 degrees, it must be outside the range between upperBound and lowerBound
      return angleDegrees <= upperBound || angleDegrees >= lowerBound;
    }
  };

  // calculate the distance between two points based on direction
  const calculateDistance = (
    candidateObject: FloorplanCoordinates,
    focusedObject: FloorplanCoordinates
  ) => {
    // Euclidean distance: sqrt((x2-x1)^2 + (y2-y1)^2)
    const yDistance = Math.abs(candidateObject.y - focusedObject.y);
    const xDistance = Math.abs(candidateObject.x - focusedObject.x);

    // return euclidean distance
    return Math.pow(xDistance, 2) + Math.pow(yDistance, 2);
  };

  // get the position of the focused object
  const focusedObj = objectMap.get(focusedObject.id);
  if (focusedObj === undefined) return null;
  const focusedObjPosition = focusedObj.position;

  // iterate over all the objects of the same type and find the
  // closest object within the angle scope
  let smallestDistance = Infinity;
  let closestObject = null;
  Array.from(
    objectMap.values() as IterableIterator<PlanSensor | AreaOfConcern | Space>
  ).forEach((candidateObject) => {
    if (
      checkDirection(direction, candidateObject.position, focusedObjPosition) &&
      isWithinAngleScope(candidateObject.position, focusedObjPosition)
    ) {
      const distance = calculateDistance(
        candidateObject.position,
        focusedObjPosition
      );
      // set the object with the smallest distance to ultimately return
      if (distance < smallestDistance) {
        smallestDistance = distance;
        closestObject = candidateObject.id;
      }
    }
  });
  return closestObject;
};
