import { v4 as uuidv4 } from 'uuid';
import { analyzeGraph } from 'graph-cycles';

import {
  FloorplanCoordinates,
  computeLineSegmentsInBoundingRegion,
} from 'lib/geometry';
import { closestPointOnLineSegment } from 'lib/algorithm';
import { distance, radiansToDegrees } from 'lib/math';
import { Viewport } from 'lib/viewport';
import Floorplan from 'lib/floorplan';
import {
  Unsaved,
  FloorplanV2WallSegment,
  isFloorplanWallSegmentId,
} from 'lib/api';
import _ from 'lodash';

export const WALL_SEGMENT_SNAP_THRESHOLD_PIXELS = 10;

type WallSegment = {
  id: string;
  positionA: FloorplanCoordinates;
  positionB: FloorplanCoordinates;
  isDoorway: boolean;
};

const WallSegment = {
  create(
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates,
    isDoorway: boolean = false
  ): WallSegment {
    const id = uuidv4();
    return { id, positionA, positionB, isDoorway };
  },

  createFromFloorplanWallSegment(
    floorplanWallSegment: FloorplanV2WallSegment
  ): WallSegment {
    return {
      id: floorplanWallSegment.id,
      positionA: FloorplanCoordinates.create(
        floorplanWallSegment.position_a_x_meters,
        floorplanWallSegment.position_a_y_meters
      ),
      positionB: FloorplanCoordinates.create(
        floorplanWallSegment.position_b_x_meters,
        floorplanWallSegment.position_b_y_meters
      ),
      isDoorway: floorplanWallSegment.type === 'doorway',
    };
  },

  toFloorplanWallSegment(
    wallSegment: WallSegment
  ): Unsaved<FloorplanV2WallSegment> | FloorplanV2WallSegment {
    return {
      id: isFloorplanWallSegmentId(wallSegment.id) ? wallSegment.id : undefined,
      position_a_x_meters: wallSegment.positionA.x,
      position_a_y_meters: wallSegment.positionA.y,
      position_b_x_meters: wallSegment.positionB.x,
      position_b_y_meters: wallSegment.positionB.y,
      type: wallSegment.isDoorway ? 'doorway' : 'wall',
    };
  },

  computeWallSegmentsInBoundingRegion(
    wallSegments: Array<WallSegment>,
    upperLeft: FloorplanCoordinates,
    lowerRight: FloorplanCoordinates
  ): Array<WallSegment> {
    const wallSegmentCopy = _.cloneDeep(wallSegments);
    return computeLineSegmentsInBoundingRegion(
      wallSegmentCopy,
      upperLeft,
      lowerRight,
      (wallSegment) => [wallSegment.positionA, wallSegment.positionB]
    );
  },

  computePotentialOpenings(
    wallSegments: Array<WallSegment>
  ): Array<[FloorplanCoordinates, FloorplanCoordinates]> {
    // console.log('====');

    // Step 1: Compute a mapping of each vertex to each segment that each vertex is in
    const verticesToSegmentsRaw = new Map<string, Array<WallSegment>>();
    for (const segment of wallSegments) {
      for (const vertex of [segment.positionA, segment.positionB]) {
        // Store each vertex in a string format in the map so that value equality checks will work
        const key = `${vertex.x},${vertex.y}`;
        const segments = verticesToSegmentsRaw.get(key) || [];
        verticesToSegmentsRaw.set(key, [...segments, segment]);
      }
    }

    // // Step 2: Filter out vertices that are only in a single segment
    // const verticesWithOnlyOneSegment: Array<[FloorplanCoordinates, WallSegment]> = Array.from(verticesToSegmentsRaw)
    //   .filter(([vertex, segments]) => segments.length === 1)
    //   .map(([vertex, segments]) => {
    //     const [x, y] = vertex.split(',');
    //     const coord = FloorplanCoordinates.create(parseFloat(x), parseFloat(y));
    //     return [coord, segments[0]];
    //   });

    // Compute the angle each segment forms when rendered on the floorplan
    const segmentAnglesInDegrees: Map<WallSegment['id'], number> = new Map();
    for (const segment of wallSegments) {
      let angleInDegrees = radiansToDegrees(
        Math.atan2(
          segment.positionA.y - segment.positionB.y,
          segment.positionA.x - segment.positionB.x
        )
      );

      // Bound this angle within 0 < theta < 360
      while (angleInDegrees < 0) {
        angleInDegrees += 360;
      }
      while (angleInDegrees > 360) {
        angleInDegrees -= 360;
      }

      segmentAnglesInDegrees.set(segment.id, angleInDegrees);
    }

    // Step 2: Filter out vertices that could potentially be endpoints of the vertex
    const potentialOpeningEndpoints: Array<
      [FloorplanCoordinates, Array<WallSegment>]
    > = Array.from(verticesToSegmentsRaw)
      .filter(([_vertex, segments]) => {
        // If there is only one segment associated with this vertex, then it's by definition
        // protuding into some area, so count it!
        if (segments.length === 1) {
          return true;
        }

        // Otherwise, compute the angle in between the segment most on one side and most on the
        // other side. That angle must not span more than a little over 90 degrees - otherwise it's
        // not "protrude-y" enough :)
        const segmentAngles = segments.map(
          (segment) => segmentAnglesInDegrees.get(segment.id) as number
        );
        const minAngle = Math.min(...segmentAngles);
        const maxAngle = Math.max(...segmentAngles);
        // console.log('VERTEX', _vertex, minAngle, maxAngle);

        const isVertexProtrudingIntoEmptyRegion =
          Math.abs(maxAngle - minAngle) < 100;
        return isVertexProtrudingIntoEmptyRegion;
      })
      .map(([vertex, segments]) => {
        const [x, y] = vertex.split(',');
        const coord = FloorplanCoordinates.create(parseFloat(x), parseFloat(y));
        return [coord, segments];
      });

    // Step 3: Place potential openings between vertices that:
    //         - are in only a single segment
    //         - are at least a certain distance apart
    //         - are of an angle similar to the angle of the segment
    const openingsByVertex: Map<
      string,
      [FloorplanCoordinates, FloorplanCoordinates, number]
    > = new Map();
    for (const [vertexA, vertexASegments] of potentialOpeningEndpoints) {
      for (const [vertexB, vertexBSegments] of potentialOpeningEndpoints) {
        if (vertexA === vertexB) {
          continue;
        }

        // Make sure the distance between the vertices isn't really massive
        // because a doorway generally isn't like hundreds of meters wide
        const distanceInMeters = distance(vertexA, vertexB);
        // if (distanceInMeters > 10) {
        //   continue;
        // }

        // Make sure this potential opening doesn't overlap an existing wall segment
        const potentialOpeningMatchesSegment = wallSegments.find((segment) => {
          const matchesForwards =
            segment.positionA.x === vertexA.x &&
            segment.positionA.y === vertexA.y &&
            segment.positionB.x === vertexB.x &&
            segment.positionB.y === vertexB.y;
          if (matchesForwards) {
            return true;
          }

          const matchesBackwards =
            segment.positionA.x === vertexB.x &&
            segment.positionA.y === vertexB.y &&
            segment.positionB.x === vertexA.x &&
            segment.positionB.y === vertexA.y;
          if (matchesBackwards) {
            return true;
          }

          return false;
        });
        if (potentialOpeningMatchesSegment) {
          continue;
        }
        // console.log('POTENTIAL OPENING:', vertexA, vertexB, wallSegments);

        // Compute the angle that the new opening would render at
        // Bound this angle within 0 < theta < 180
        let angleOfOpeningInDegrees = radiansToDegrees(
          Math.atan2(vertexA.y - vertexB.y, vertexA.x - vertexB.x)
        );
        while (angleOfOpeningInDegrees < 0) {
          angleOfOpeningInDegrees += 180;
        }
        while (angleOfOpeningInDegrees > 180) {
          angleOfOpeningInDegrees -= 180;
        }

        // Find the pair of segments which are the most parallel with each other that are attached
        // to each vertex. The assumption being made here is that these segments are the segments on
        // either side of the opening.
        let mostParallelAngleDifferenceInDegrees = Infinity;
        let mostParallelSegmentA: WallSegment | null = null;
        let mostParallelSegmentB: WallSegment | null = null;
        for (const segmentA of vertexASegments) {
          for (const segmentB of vertexBSegments) {
            if (segmentA === segmentB) {
              continue;
            }
            const segmentAAngle = segmentAnglesInDegrees.get(
              segmentA.id
            ) as number;
            const segmentBAngle = segmentAnglesInDegrees.get(
              segmentB.id
            ) as number;

            const angleDifference = Math.abs(segmentAAngle - segmentBAngle);
            if (angleDifference < mostParallelAngleDifferenceInDegrees) {
              mostParallelAngleDifferenceInDegrees = angleDifference;
              mostParallelSegmentA = segmentA;
              mostParallelSegmentB = segmentB;
            }
          }
        }
        if (!mostParallelSegmentA || !mostParallelSegmentB) {
          continue;
        }

        let mostParallelSegmentAAngleInDegrees = segmentAnglesInDegrees.get(
          mostParallelSegmentA.id
        ) as number;
        if (mostParallelSegmentAAngleInDegrees > 180) {
          mostParallelSegmentAAngleInDegrees -= 180;
        }

        let mostParallelSegmentBAngleInDegrees = segmentAnglesInDegrees.get(
          mostParallelSegmentB.id
        ) as number;
        if (mostParallelSegmentBAngleInDegrees > 180) {
          mostParallelSegmentBAngleInDegrees -= 180;
        }

        // And make sure the segment angles aren't wildly different from the angle of the opening
        // between them, if so, then this probably is a false positive.
        if (
          Math.abs(
            mostParallelSegmentAAngleInDegrees - angleOfOpeningInDegrees
          ) > 10
        ) {
          continue;
        }
        if (
          Math.abs(
            mostParallelSegmentBAngleInDegrees - angleOfOpeningInDegrees
          ) > 10
        ) {
          continue;
        }

        // console.log(
        //   'ANGLES',
        //   mostParallelAngleDifferenceInDegrees,
        //   mostParallelSegmentA,
        //   mostParallelSegmentB,
        //   segmentAnglesInDegrees.get(mostParallelSegmentA.id),
        //   segmentAnglesInDegrees.get(mostParallelSegmentB.id),
        // );

        // Finally, see if there's an opening already associated with either one of the vertices
        // If so, if this opening is smaller, replace it with this one
        let alreadyExistingOpeningByVertexA = openingsByVertex.get(
          `${vertexA.x},${vertexA.y}`
        );
        let alreadyExistingOpeningByVertexB = openingsByVertex.get(
          `${vertexB.x},${vertexB.y}`
        );
        if (alreadyExistingOpeningByVertexA) {
          if (distanceInMeters > alreadyExistingOpeningByVertexA[2]) {
            continue;
          }
        }
        if (alreadyExistingOpeningByVertexB) {
          if (distanceInMeters > alreadyExistingOpeningByVertexB[2]) {
            continue;
          }
        }

        openingsByVertex.set(`${vertexA.x},${vertexA.y}`, [
          vertexA,
          vertexB,
          distanceInMeters,
        ]);
        openingsByVertex.set(`${vertexB.x},${vertexB.y}`, [
          vertexB,
          vertexA,
          distanceInMeters,
        ]);
      }
    }

    // return Array.from(openingsByVertex.values()).map(a => [a[0], a[1]]);
    // Step 4: Deduplicate openings by vertex to calculate a final list of openings
    // console.log('BY VERTEX', openingsByVertex);
    const openings: Array<[FloorplanCoordinates, FloorplanCoordinates]> = [];
    for (const [vertexA, vertexB] of Array.from(openingsByVertex.values())) {
      // console.log(' ->', vertexA, vertexB);
      let isUnique = true;
      for (const [a, b] of openings) {
        if (
          a.x === vertexA.x &&
          a.y === vertexA.y &&
          b.x === vertexB.x &&
          b.y === vertexB.y
        ) {
          isUnique = false;
          break;
        }
        if (
          b.x === vertexA.x &&
          b.y === vertexA.y &&
          a.x === vertexB.x &&
          a.y === vertexB.y
        ) {
          isUnique = false;
          break;
        }
      }

      if (isUnique) {
        openings.push([vertexA, vertexB]);
      }
    }

    // console.log('OPENINGS', openings);
    return openings;
  },

  // Given a list of wall segments, return an array of pairs. Each pair contains a set of wall
  // segments that make a closed loop and the vertices in floorplan coordinates associated so that a
  // polygon can be drawn.
  computeWallSegmentLoops(
    wallSegments: Array<WallSegment>
  ): Array<Array<FloorplanCoordinates>> {
    if (!wallSegments.length) {
      return [];
    }

    // Generate a directed graph where each segment vertex is linked to it's opposing vertex in both
    // directions.
    const graph = new Map<string, Array<string>>();
    for (const segment of wallSegments) {
      // Store each vertex in a string format in the map so that value equality checks will work
      const positionAKey = `${segment.positionA.x},${segment.positionA.y}`;
      const positionBKey = `${segment.positionB.x},${segment.positionB.y}`;

      graph.set(positionAKey, [
        ...(graph.get(positionAKey) || []),
        positionBKey,
      ]);
      graph.set(positionBKey, [
        ...(graph.get(positionBKey) || []),
        positionAKey,
      ]);
    }

    // Cycles in this graph represent "polygons" in the wall segment set.
    const { cycles } = analyzeGraph(Array.from(graph));

    return cycles.map((cycle) => {
      return cycle.map((vertexKey) => {
        const [xRaw, yRaw] = vertexKey.split(',');
        const x = parseFloat(xRaw);
        const y = parseFloat(yRaw);
        return FloorplanCoordinates.create(x, y);
      });
    });
  },

  computeNearestSnapPointInBoundingRegion(
    wallSegments: Array<WallSegment>,
    position: FloorplanCoordinates,
    upperLeft: FloorplanCoordinates,
    lowerRight: FloorplanCoordinates,
    floorplan: Floorplan,
    viewport: Viewport,
    wallSnapThresholdPixels = WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
  ): [FloorplanCoordinates | null, null | 'point' | 'line'] {
    if (wallSegments.length === 0) {
      return [null, null];
    }

    // Step 1: get all wall segments that should be checked for snapping
    const wallSegmentsInRegion =
      WallSegment.computeWallSegmentsInBoundingRegion(
        wallSegments,
        upperLeft,
        lowerRight
      );

    // Step 2: find the closest point on the closest wall segment - this is what should be snapped to
    let closestWallSegment: WallSegment | null = null;
    let closestPoint: FloorplanCoordinates | null = null;
    let closestPointDistance = Infinity;
    for (const wallSegment of wallSegmentsInRegion) {
      const closestPointOnWallSegment = closestPointOnLineSegment(
        position,
        wallSegment.positionA,
        wallSegment.positionB
      );
      const distanceToClosestPointOnWallSegmentMeters = distance(
        closestPointOnWallSegment,
        position
      );

      // Make sure that the distance to tis segment in pixels is within the snap threshold
      const distanceToClosestPointOnWallSegmentPixels =
        distanceToClosestPointOnWallSegmentMeters *
        floorplan.scale *
        viewport.zoom;
      if (distanceToClosestPointOnWallSegmentPixels > wallSnapThresholdPixels) {
        continue;
      }

      if (distanceToClosestPointOnWallSegmentMeters < closestPointDistance) {
        closestWallSegment = wallSegment;
        closestPoint = closestPointOnWallSegment;
        closestPointDistance = distanceToClosestPointOnWallSegmentMeters;
      }
    }

    if (!closestWallSegment || !closestPoint) {
      return [null, null];
    }
    const closestPointViewport = FloorplanCoordinates.toViewportCoordinates(
      closestPoint,
      floorplan,
      viewport
    );

    // Step 3: Attempt th try to snap to the endpoints of the wall segment
    const wallSegmentPositionAViewport =
      FloorplanCoordinates.toViewportCoordinates(
        closestWallSegment.positionA,
        floorplan,
        viewport
      );
    if (
      distance(wallSegmentPositionAViewport, closestPointViewport) <
      wallSnapThresholdPixels
    ) {
      // Snap to the wall segment "positionA" endpoint
      return [closestWallSegment.positionA, 'point'];
    }

    const wallSegmentPositionBViewport =
      FloorplanCoordinates.toViewportCoordinates(
        closestWallSegment.positionB,
        floorplan,
        viewport
      );
    if (
      distance(wallSegmentPositionBViewport, closestPointViewport) <
      wallSnapThresholdPixels
    ) {
      // Snap to the wall segment "positionB" endpoint
      return [closestWallSegment.positionB, 'point'];
    }

    // It's not near either end point, so don't snap to an endpoint
    return [closestPoint, 'line'];
  },
};

export default WallSegment;
