import styles from './styles.module.scss';

export const LoadingBar: React.FunctionComponent<{ percentage: number }> = ({
  percentage,
}) => {
  if (percentage > 100) percentage = 100;
  return (
    <div className={styles.loadingBarContainer}>
      <div
        className={styles.loadingBarProgress}
        style={{ width: `${percentage}%` }}
      />
      <div className={styles.loadingPercentage}> {percentage.toFixed(2)}%</div>
    </div>
  );
};
