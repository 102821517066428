import {
  Modal,
  AppBar,
  AppBarTitle,
  AppBarSection,
  Button,
} from '@densityco/ui';
import * as React from 'react';
import { css } from '@emotion/react';

import { useFFOverrides, useTreatments } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import Switch from 'components/switch/switch';

const featureNames = Object.values(SPLITS);

const TreatmentsOverrides: React.FC<{ isOpen: boolean; onClose: () => void }> =
  (props) => {
    const [fFOverrides, setFFOverrides] = useFFOverrides();
    const originalFFValues = useTreatments(featureNames);

    return (
      <Modal width={500} visible={props.isOpen}>
        <AppBar>
          <AppBarTitle>Feature Flipper Overrides</AppBarTitle>
          <AppBarSection>
            <div
              css={css`
                display: flex;
                gap: 8px;
              `}
            >
              <Button type="danger" onClick={() => setFFOverrides({})}>
                Reset
              </Button>

              <Button type="primary" onClick={props.onClose}>
                Close
              </Button>
            </div>
          </AppBarSection>
        </AppBar>

        <div
          css={css`
            display: grid;
            row-gap: 8px;
            margin: 24px;
          `}
        >
          {featureNames.map((fn) => {
            const originalValue = originalFFValues[fn];
            const overrideValue = fFOverrides?.[fn];
            const value = overrideValue || originalValue;
            const isActive = value === 'on';

            return (
              <div
                key={fn}
                className="row"
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <span>{fn}</span>

                <Switch
                  // NOTE: don't allow turning off the ff overrides by mistake
                  isDisabled={fn === SPLITS.FF_OVERRIDES}
                  isChecked={isActive}
                  onChange={(e) => {
                    const newIsActive = e.target.checked;

                    setFFOverrides((prev) => {
                      if (!prev) {
                        return {};
                      }

                      const originalIsActive = originalValue === 'on';

                      if (
                        // if is the same as the value set in split. delete override
                        newIsActive === originalIsActive
                      ) {
                        delete prev[fn];
                      } else {
                        prev[fn] = newIsActive ? 'on' : 'off';
                      }

                      return prev;
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal>
    );
  };

export default TreatmentsOverrides;
