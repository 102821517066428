import { SessionToken, SessionTokenLike } from '@mapbox/search-js-core';
import { createContext, ReactNode, useContext, useMemo } from 'react';

type MapboxSessionContextType = {
  sessionToken: SessionTokenLike;
};

const MapboxSessionContext = createContext<
  MapboxSessionContextType | undefined
>(undefined);

export const useMapboxSession = () => {
  const context = useContext(MapboxSessionContext);
  if (!context) {
    throw new Error(
      'useMapboxSession must be used within a MapboxSessionProvider'
    );
  }

  return context;
};

export const MapboxSessionProvider: React.FC<{ children?: ReactNode }> = (
  props
) => {
  const value = useMemo(() => {
    return {
      sessionToken: new SessionToken(),
    };
  }, []);
  return (
    <MapboxSessionContext.Provider value={value}>
      {props.children}
    </MapboxSessionContext.Provider>
  );
};
