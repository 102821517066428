import { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { FloorplanCoordinates } from 'lib/geometry';

import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { Gray300 } from '@density/dust/dist/tokens/dust.tokens';

// Drag across a selection "box" to select anything
// that falls within the box.
export type SelectingState = {
  type: 'select';
  vertexA: FloorplanCoordinates | null;
  vertexB: FloorplanCoordinates | null;
  nextPlacement: FloorplanCoordinates | null;
};

const RenderSelectingLayer: React.FunctionComponent<{
  selectingStateRef: React.RefObject<SelectingState | null>;
}> = ({ selectingStateRef }) => {
  const context = useFloorplanLayerContext();

  useEffect(() => {
    const box = new PIXI.Graphics();
    box.name = 'select-box';
    context.app.stage.addChild(box);
    return () => {
      context.app.stage.removeChild(box);
    };
  }, [context.app]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        } else if (!selectingStateRef.current?.nextPlacement) {
          return;
        }

        const viewport = context.viewport.current;
        const shape = context.app.stage.getChildByName(
          'select-box'
        ) as PIXI.Graphics;
        shape.visible = false;

        if (
          !selectingStateRef.current ||
          selectingStateRef.current.type !== 'select' ||
          !selectingStateRef.current.vertexA
        ) {
          shape.visible = false;
          return;
        }

        shape.clear();

        // Generate vertex coordinate positions
        const viewportVertices = [
          FloorplanCoordinates.toViewportCoordinates(
            selectingStateRef.current.vertexA,
            context.floorplan,
            viewport
          ),
          FloorplanCoordinates.toViewportCoordinates(
            selectingStateRef.current.nextPlacement,
            context.floorplan,
            viewport
          ),
        ];

        // Calculate the other two vertices of the rectangle
        const vertex1 = { x: viewportVertices[0].x, y: viewportVertices[1].y };
        const vertex2 = { x: viewportVertices[1].x, y: viewportVertices[0].y };

        // Draw the rectangle
        shape.beginFill(toRawHex(Gray300), 0.12);
        shape.lineStyle({ width: 1, color: toRawHex(Gray300) });
        shape.moveTo(viewportVertices[0].x, viewportVertices[0].y);
        shape.lineTo(vertex1.x, vertex1.y);
        shape.lineTo(viewportVertices[1].x, viewportVertices[1].y);
        shape.lineTo(vertex2.x, vertex2.y);
        shape.lineTo(viewportVertices[0].x, viewportVertices[0].y);
        shape.endFill();

        shape.visible = true;
      }}
    />
  );
};

export default RenderSelectingLayer;
