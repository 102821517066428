import { css } from '@emotion/react';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

const Switch: React.FC<{
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  'data-cy'?: string;
}> = (props) => {
  const uniqueId = uuidv4();

  // When the label is clicked, it dispatches a click to the associated input
  // This then will propagate upward to parent elements of the input
  // If you want to have a parent element be able to toggle the input, this will
  //   not be possible because the click on that parent will fire twice, once from
  //   the click on the element itself, and again from the label's dispatched click
  // Stopping propagation from the input click prevents this issue.
  const onInputClick = React.useCallback<
    React.FormEventHandler<HTMLInputElement>
  >((evt) => {
    evt.stopPropagation();
  }, []);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      data-cy={props['data-cy']}
    >
      <input
        id={uniqueId}
        css={css`
          display: none;
        `}
        type="checkbox"
        onChange={props.isDisabled ? undefined : props.onChange}
        onClick={onInputClick}
        checked={props.isChecked}
      />

      <label
        htmlFor={uniqueId}
        css={[
          css`
            outline: 0;
            margin-bottom: 0;
            display: inline-block;
            width: 26px;
            height: 16px;
            position: relative;
            cursor: pointer;
            user-select: none;
            background-color: ${props.isChecked ? dust.Blue400 : dust.Gray300};
            border-radius: 8px;
            transition: all 200ms ease;

            &:after {
              position: relative;
              display: block;
              content: '';
              width: 14px;
              height: 14px;
              box-sizing: border-box;
              left: ${props.isChecked ? 11 : 1}px;
              top: 1px;
              border-radius: 50%;
              background-color: ${dust.White};
              border: 1px solid ${props.isChecked ? dust.Blue400 : dust.Gray300};
              transition: all 200ms ease;
            }
          `,
          props.isDisabled &&
            css`
              cursor: not-allowed;
              background-color: ${props.isChecked
                ? dust.Blue200
                : dust.Gray200};

              &:after {
                border-color: ${props.isChecked ? dust.Blue200 : dust.Gray200};
              }
            `,
        ]}
      />
    </div>
  );
};

export default Switch;
