import { useEffect, FunctionComponent } from 'react';
import { useFloorplanLayerContext } from './floorplan-layer-context';
import { AnimationFrameHandler } from './types';

type LayerProps = {
  onAnimationFrame: AnimationFrameHandler;
};

const Layer: FunctionComponent<LayerProps> = ({ onAnimationFrame }) => {
  const floorplanLayerContextData = useFloorplanLayerContext();

  // Register "onAnimationFrame" with the parent floorplan instance
  useEffect(() => {
    floorplanLayerContextData.animationFrameHandlers.push(onAnimationFrame);

    return () => {
      const index =
        floorplanLayerContextData.animationFrameHandlers.indexOf(
          onAnimationFrame
        );
      floorplanLayerContextData.animationFrameHandlers.splice(index, 1);
    };
  }, [floorplanLayerContextData, onAnimationFrame]);

  return null;
};

export default Layer;
