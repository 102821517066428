import lottie from 'lottie-web';
import { useLayoutEffect } from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

import spinner from './spinner.json';

// lottie-react-web is out of date so we are using lottie-web directly
// ref: https://github.com/felippenardi/lottie-react-web/blob/master/src/index.js

export default function LoadingOverlay({ text }: { text: string }) {
  useLayoutEffect(() => {
    const container = document.getElementById('lottie');
    if (!container) {
      return;
    }

    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      animationData: spinner,
    });

    animation.play();

    return () => {
      animation.stop();
    };
  }, []);

  return (
    <div
      data-cy="loading-overlay"
      css={css`
        background: ${dust.White};
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        id="lottie"
        css={css`
          height: 160px;
          width: 160px;
        `}
      ></div>

      <span
        css={css`
          font-size: ${dust.TextScale5};
          font-weight: ${dust.FontWeightBold};
          color: ${dust.Gray600};
        `}
      >
        {text}
      </span>
    </div>
  );
}
