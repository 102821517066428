export function modulo(a: number, n: number) {
  return ((a % n) + n) % n;
}

export function round(n: number, places: number) {
  const powerOfTen = Math.pow(10, places);
  return Math.round(n * powerOfTen) / powerOfTen;
}

export function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

export function radiansToDegrees(radians: number) {
  return radians / (Math.PI / 180);
}

export function distance<T extends string>(
  pointA: { type: T; x: number; y: number },
  pointB: { type: T; x: number; y: number }
) {
  return Math.hypot(pointA.x - pointB.x, pointA.y - pointB.y);
}
