import { v4 as uuidv4 } from 'uuid';

import {
  FloorplanV2Threshold,
  FloorplanV2ThresholdWrite,
  Unsaved,
} from 'lib/api';
import { FloorplanCoordinates, computeCentroid } from 'lib/geometry';
import Floorplan from 'lib/floorplan';
import { Viewport } from 'lib/viewport';
import PlanSensor from 'lib/sensor';
import Space from 'lib/space';

const REFRENCE_DISTANCE_LABEL_CENTER_SNAP_PX = 32;
/*
{
   "id":"drw_1230251619153609197",
   "name":"New Vanilla Doorway",
   "plan_id":"pln_906278489433309225",
   "plan_sensor_ids":[
      "psr_1230251619161997807",
      "psr_1230251619149414891"
   ],
   "sensor_ids":[
      "snr_1234567892"
   ],
   "sensor_serial_numbers":[
      "C3DKP015"
   ],
   "doorway_to_space_links":[
      {
         "space_id":"spc_1230251619170386416",
         "space_name":"Space 1",
         "type":"space",
         "link_id":"lnk_1230251619384295923",
         "created_at":"2023-08-22T20:20:42.982000",
         "updated_at":"None",
         "sensor_placement":-1,
         "is_main_link":true
      },
      {
         "space_id":"spc_1230251619195552241",
         "space_name":"Space 2",
         "type":"space",
         "link_id":"lnk_1230251619401073140",
         "created_at":"2023-08-22T20:20:42.987000",
         "updated_at":"None",
         "sensor_placement":1,
         "is_main_link":true
      }
   ],
   "position_a_x_meters":1.1,
   "position_a_y_meters":1.3,
   "position_b_x_meters":1.2,
   "position_b_y_meters":1.4
}
*/

export type RelatedSpace = {
  spaceId: Space['id'];
  sensorPlacement: number;
  linkId: string | null;
  spaceName: string | null;
  spaceType: string | null;
  planId: string | null;
};

export interface Threshold {
  id: string;
  name: string;
  positionA: FloorplanCoordinates;
  positionB: FloorplanCoordinates;

  relatedPlanSensors: Array<PlanSensor['id']>;
  distanceLabelPosition: FloorplanCoordinates;

  relatedSpaces: Array<RelatedSpace>;

  locked: boolean;
  notes: string;
  triggerRegion?: Array<FloorplanCoordinates>;
  ingressRegion?: Array<FloorplanCoordinates>;
  egressRegion?: Array<FloorplanCoordinates>;
  triggerRegionCentroid?: FloorplanCoordinates | null;

  swingsIntoFOV: boolean;
}

export const Threshold = {
  createThreshold(
    name: string,
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates,
    swingsIntoFOV: boolean = false,
    relatedPlanSensors?: Array<PlanSensor['id']>,
    distanceLabelPosition?: FloorplanCoordinates,
    relatedSpaces?: Array<RelatedSpace>,
    locked?: boolean,
    notes?: string,
    triggerRegion?: Array<FloorplanCoordinates>,
    triggerRegionCentroid?: FloorplanCoordinates | null
  ): Threshold {
    const id = uuidv4();
    return {
      id,
      name: name || 'Doorway-' + id.slice(0, 4),
      positionA,
      positionB,
      relatedPlanSensors: relatedPlanSensors || [],
      distanceLabelPosition:
        distanceLabelPosition ||
        Threshold.calculateCenterPoint({ positionA, positionB }),
      relatedSpaces: relatedSpaces || [],
      locked: locked || false,
      notes: notes || '',
      triggerRegion: triggerRegion || [],
      triggerRegionCentroid:
        triggerRegionCentroid ||
        (triggerRegion ? computeCentroid(triggerRegion) : null),
      swingsIntoFOV: swingsIntoFOV,
    };
  },

  toggle(threshold: Threshold): Threshold {
    return {
      ...threshold,
    };
  },

  duplicate(oldThreshold: Threshold): Threshold {
    const id = uuidv4();
    return { ...oldThreshold, id };
  },

  widthMeters(threshold: Threshold): number {
    const positionA = threshold.positionA;
    const positionB = threshold.positionB;

    // Calculate the Euclidean distance between two points
    const distance = Math.sqrt(
      Math.pow(positionB.x - positionA.x, 2) +
        Math.pow(positionB.y - positionA.y, 2)
    );

    return distance;
  },

  calculateCenterPoint(
    threshold: Pick<Threshold, 'positionA' | 'positionB'>
  ): FloorplanCoordinates {
    return FloorplanCoordinates.create(
      (threshold.positionA.x + threshold.positionB.x) / 2,
      (threshold.positionA.y + threshold.positionB.y) / 2
    );
  },

  // Returns a boolean indicating if the label showing the length of a threshold line should snap to
  // the center of the threshold line when moving it around the plan.
  isDistanceLockedToCenter(
    floorplan: Floorplan,
    viewport: Viewport,
    distanceLabelPosition: FloorplanCoordinates,
    centerPoint: FloorplanCoordinates
  ): boolean {
    const centerPointViewport = FloorplanCoordinates.toViewportCoordinates(
      centerPoint,
      floorplan,
      viewport
    );
    const distanceLabelPositionViewport =
      FloorplanCoordinates.toViewportCoordinates(
        distanceLabelPosition,
        floorplan,
        viewport
      );

    const distanceFromCenter = Math.hypot(
      Math.abs(centerPointViewport.y - distanceLabelPositionViewport.y),
      Math.abs(centerPointViewport.x - distanceLabelPositionViewport.x)
    );

    return distanceFromCenter < REFRENCE_DISTANCE_LABEL_CENTER_SNAP_PX;
  },

  toFloorplanThreshold(
    threshold: Threshold
  ): FloorplanV2ThresholdWrite | Unsaved<FloorplanV2ThresholdWrite> {
    return {
      id: threshold.id,
      name: threshold.name,
      position_a_x_meters: threshold.positionA.x,
      position_a_y_meters: threshold.positionA.y,
      position_b_x_meters: threshold.positionB.x,
      position_b_y_meters: threshold.positionB.y,
      spaces: threshold.relatedSpaces.map((space) => {
        return {
          space_id: space.spaceId,
          sensor_placement: space.sensorPlacement,
          link_id: space.linkId,
        };
      }),
      plan_sensor_ids: threshold.relatedPlanSensors,
      locked: threshold.locked,
      notes: threshold.notes,
      swings_into_fov: threshold.swingsIntoFOV,
    };
  },

  createFromFloorplanThreshold(
    apiThreshold: FloorplanV2Threshold,
    floorplan?: Floorplan,
    nullGeometryIdx?: number
  ): Threshold {
    // If geometry is null, let's render this Threshold to the right of the floorplan.
    // This is a special case to handle legacy ToF sensors that never had any geometry.
    // The moment these entities are moved, they will persist their new location and this become
    // redundant.
    const positionA_x =
      apiThreshold.position_a_x_meters ||
      (floorplan
        ? (floorplan.width - floorplan.origin.x) / floorplan.scale
        : 0);

    const positionB_x =
      apiThreshold.position_b_x_meters ||
      positionA_x +
        (floorplan
          ? (floorplan.width - floorplan.origin.x) / floorplan.scale / 20
          : 1);

    const positionA_y =
      apiThreshold.position_a_y_meters ||
      (floorplan && nullGeometryIdx
        ? floorplan.origin.y / floorplan.scale / 20 + 2 * nullGeometryIdx
        : 0);

    const positionB_y = apiThreshold.position_b_y_meters || positionA_y;

    const middleX = (positionA_x + positionB_x) / 2;
    const middleY = (positionA_y + positionB_y) / 2;

    let triggerRegion: Array<FloorplanCoordinates> | undefined =
      apiThreshold.trigger_region?.trigger_region?.flat()?.map((v) => {
        const vertex = v as unknown as number[];
        return FloorplanCoordinates.create(vertex[0], vertex[1]);
      });

    let ingressRegion: Array<FloorplanCoordinates> | undefined =
      apiThreshold.trigger_region?.ingress_region?.flat()?.map((v) => {
        const vertex = v as unknown as number[];
        return FloorplanCoordinates.create(vertex[0], vertex[1]);
      });

    let egressRegion: Array<FloorplanCoordinates> | undefined =
      apiThreshold.trigger_region?.egress_region?.flat()?.map((v) => {
        const vertex = v as unknown as number[];
        return FloorplanCoordinates.create(vertex[0], vertex[1]);
      });

    if (!triggerRegion || triggerRegion.length < 3) {
      triggerRegion = [];
    }

    if (!ingressRegion || ingressRegion.length < 3) {
      ingressRegion = [];
    }

    if (!egressRegion || egressRegion.length < 3) {
      egressRegion = [];
    }

    return {
      id: apiThreshold.id,
      name: apiThreshold.name,
      positionA: FloorplanCoordinates.create(positionA_x, positionA_y),
      positionB: FloorplanCoordinates.create(positionB_x, positionB_y),
      distanceLabelPosition: FloorplanCoordinates.create(middleX, middleY),
      relatedPlanSensors: apiThreshold.plan_sensor_ids,
      relatedSpaces: apiThreshold.spaces
        ? apiThreshold.spaces.map((threshold) => {
            return {
              spaceId: threshold.space_id,
              sensorPlacement: threshold.sensor_placement,
              linkId: threshold.link_id,
              spaceName: threshold.space_name || null,
              spaceType: threshold.type || null,
              planId: threshold.plan_id || null,
            };
          })
        : [],
      locked: apiThreshold.locked,
      notes: apiThreshold.notes,
      swingsIntoFOV: apiThreshold.swings_into_fov || false,
      triggerRegion: triggerRegion || [],
      triggerRegionCentroid:
        triggerRegion && triggerRegion.length >= 3
          ? computeCentroid(triggerRegion)
          : null,
      ingressRegion: ingressRegion || [],
      egressRegion: egressRegion || [],
    };
  },
};

export default Threshold;
