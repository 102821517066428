import * as React from 'react';
import { DateAndTimeTextField } from 'components/date-text-field';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';
import { FloorplanV2Plan } from 'lib/api';
import { SMALLEST_MAX_MILLISECONDS_VALUE } from 'lib/heatmap';

import Button from 'components/button';
import FillCenter from 'components/fill-center';
import FormLabel from 'components/form-label';
import OpacityField from 'components/opacity-field';
import Panel, { PanelBody, PanelTitle, PanelActions } from 'components/panel';
import RangeExtentsSlider from 'components/range-extents-slider';

const FocusedHeatMapLayerPanel: React.FunctionComponent<{
  state: State;
  dispatch: React.Dispatch<Action>;
  plan: FloorplanV2Plan;
}> = ({ state, dispatch, plan }) => {
  const startDate = state.heatmap.enabled ? state.heatmap.startDate : null;
  const endDate = state.heatmap.enabled ? state.heatmap.endDate : null;

  if (!state.heatmap.enabled) {
    return null;
  }

  switch (state.heatmap.data.status) {
    case 'pending':
    case 'loading':
    case 'computing-heatmap':
      return (
        <Panel position="top-left" width={320}>
          <PanelTitle icon={<Icons.Bullseye size={16} />}>
            Heatmap Loading
          </PanelTitle>
          <PanelBody>
            {state.heatmap.data.status === 'pending' ||
            state.heatmap.data.status === 'loading' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 150,
                }}
              >
                <div
                  style={{
                    marginBottom: dust.Space2,
                    fontSize: dust.TextScale5,
                  }}
                >
                  Loading heatmap data
                </div>
                <div
                  style={{
                    marginBottom: dust.Space2,
                    fontSize: dust.TextScale4,
                  }}
                >
                  This may take up to a few minutes...
                </div>
                <div className={styles.spinner}>
                  <Icons.Loading size={30} />
                </div>
              </div>
            ) : null}
            {state.heatmap.data.status === 'computing-heatmap' ? (
              <FillCenter>Computing heatmap...</FillCenter>
            ) : null}
          </PanelBody>

          <PanelActions
            right={
              <Button
                type="cleared"
                size="medium"
                onClick={() => dispatch({ type: 'layers.dismiss' })}
              >
                Done
              </Button>
            }
          />
        </Panel>
      );

    case 'error':
      return (
        <Panel position="top-left" width={320}>
          <PanelTitle icon={<Icons.Bullseye size={16} />}>
            Heatmap Error
          </PanelTitle>
          <PanelBody>
            <div style={{ height: 64, color: dust.Red400 }}>
              <FillCenter>Error fetching heatmap data!</FillCenter>
            </div>
          </PanelBody>

          <PanelActions
            left={
              <Button
                type="cleared"
                size="medium"
                onClick={() => dispatch({ type: 'heatmap.cancel' })}
                leadingIcon={<Icons.ArrowLeftBack size={16} />}
              >
                Back
              </Button>
            }
            right={
              <Button
                type="cleared"
                size="medium"
                onClick={() => dispatch({ type: 'layers.dismiss' })}
              >
                Done
              </Button>
            }
          />
        </Panel>
      );

    case 'complete':
      return (
        <Panel position="top-left" width={320}>
          <PanelTitle icon={<Icons.Bullseye size={16} />}>
            Heatmap Settings
          </PanelTitle>
          <PanelBody>
            <FormLabel
              label="Start Date"
              input={
                <DateAndTimeTextField
                  value={startDate}
                  validate={(startDate) =>
                    startDate.length > 0 && startDate < (endDate || '')
                  }
                  width="100%"
                  size="medium"
                  onChange={(startDate) => {
                    dispatch({
                      type: 'heatmap.setStartDate',
                      startDate,
                    });
                  }}
                />
              }
            />
            <FormLabel
              label="End Date"
              input={
                <DateAndTimeTextField
                  value={endDate}
                  validate={(endDate) => {
                    console.log('FOO', startDate, endDate);
                    return endDate.length > 0 && (startDate || '') < endDate;
                  }}
                  width="100%"
                  size="medium"
                  onChange={(endDate) => {
                    dispatch({
                      type: 'heatmap.setEndDate',
                      endDate,
                    });
                  }}
                />
              }
            />
            <FormLabel
              label="Opacity"
              input={
                <OpacityField
                  value={state.heatmap.opacity}
                  onChange={(opacity) =>
                    dispatch({ type: 'heatmap.changeOpacity', opacity })
                  }
                />
              }
            />

            <FormLabel
              label="Scale"
              input={
                state.heatmap.data.globalMax > 0 ? (
                  <div style={{ marginRight: '8px' }}>
                    <RangeExtentsSlider
                      rangeMinValue={0}
                      rangeMaxValue={Math.max(
                        state.heatmap.data.globalMax,
                        SMALLEST_MAX_MILLISECONDS_VALUE
                      )}
                      minRangeWidth={SMALLEST_MAX_MILLISECONDS_VALUE}
                      minValue={state.heatmap.data.limits.min}
                      maxValue={state.heatmap.data.limits.max}
                      markerValueFormatter={(milliseconds) => {
                        const seconds = milliseconds / 1000;
                        if (seconds < 60) {
                          return `${Math.round(seconds)}s`;
                        } else if (seconds < 60 * 60) {
                          return `${Math.round(seconds / 60)}m`;
                        } else if (seconds < 24 * 60 * 60) {
                          return `${Math.round(seconds / 60 / 60)}h`;
                        } else {
                          return `${Math.round(seconds / 24 / 60 / 60)}d`;
                        }
                      }}
                      onChange={(min, max) =>
                        dispatch({ type: 'heatmap.changeLimits', min, max })
                      }
                    >
                      {[
                        'rgb(229, 241, 247)',
                        'rgb(194, 221, 231)',
                        'rgb(253, 255, 131)',
                        'rgb(248, 196, 61)',
                        'rgb(235, 115, 0)',
                        'rgb(216, 19, 10)',
                      ].map((rgb) => (
                        <div
                          key={rgb}
                          style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            width: '100%',
                            height: '100%',
                            backgroundColor: rgb,
                          }}
                        />
                      ))}
                    </RangeExtentsSlider>
                  </div>
                ) : (
                  <span>No data found!</span>
                )
              }
            />
          </PanelBody>

          <PanelActions
            right={
              <Button
                type="cleared"
                size="medium"
                onClick={() => dispatch({ type: 'layers.dismiss' })}
              >
                Dismiss
              </Button>
            }
          />
        </Panel>
      );
  }
};

export default FocusedHeatMapLayerPanel;
