import { useRef, useState, useMemo, useEffect } from 'react';
import * as PIXI from 'pixi.js';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  snapToAngle,
} from 'lib/geometry';
import { displayLength } from 'lib/units';
import { radiansToDegrees } from 'lib/math';
import { ReferenceRuler } from 'lib/reference';

import {
  ObjectLayer,
  MetricLabel,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  toRawHex,
} from 'components/floorplan';

import { Yellow400, Yellow700 } from '@density/dust/dist/tokens/dust.tokens';

const LEADER_LINE_STUB_LENGTH_PX = 16;
const RULER_ENDPOINT_CIRCLE_RADIUS_PX = 8;
const REFERENCE_RULER_ARROW_HEAD_SIZE = 16;

// The reference ruler layer renders a list of reference lines with draggable endpoints to the
// floorplan.
//
// Holding shift while dragging an endpoint will cause the reference ruler to snap to
// 45 degree angles.
//
// Finally, clicking and dragging the center label will move the whole reference ruler. Clicking and
// dragging while holding shift will cause it to undock and be rendered seperate, with a leader line
// connecting to the floorplan.
const ReferenceRulerLayer: React.FunctionComponent<{
  referenceRulers: Array<ReferenceRuler & { distanceLabelText?: string }>;
  locked?: boolean;
  highlightedObject?: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'threshold'
      | 'reference'
      | 'layer';
    id: string;
  } | null;
  rulerColor?: number;
  rulerColorHighlighted?: number;
  labelVisibilityZoomThreshold?: number;
  onEndpointsMoved?: (
    referenceRuler: ReferenceRuler,
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates,
    distanceLabelPosition: FloorplanCoordinates
  ) => void;
  onDuplicateRuler?: (ruler: ReferenceRuler) => void;
}> = ({
  referenceRulers,
  locked = false,
  highlightedObject = null,
  rulerColor = toRawHex(Yellow400),
  rulerColorHighlighted = toRawHex(Yellow700),
  labelVisibilityZoomThreshold = 0.25,
  onEndpointsMoved = null,
  onDuplicateRuler = null,
}) => {
  const context = useFloorplanLayerContext();

  // FIXME: this probably is not the best approach to this, but because `onCreate` is not updated
  // within ObjectLayer due to dependency issues in the useEffect, it's possible that invocations of
  // `onDragMove` and friends within `onCreate` might be holding onto older references of these
  // functions from a previous render. So, cache the latest versions here in a ref so that the
  // latest version can always be called.
  //
  // The proper way to address this is by fixing the dependency issues within the `ObjectLayer`, but
  // this is a larger problem because the `onCreate` / `onUpdate` / `onRemove` function references
  // change every render and moving away from this is a large project across all layers.
  const latestOnEndpointsMoved = useRef(onEndpointsMoved);
  useEffect(() => {
    latestOnEndpointsMoved.current = onEndpointsMoved;
  }, [onEndpointsMoved]);

  const latestOnDuplicateRuler = useRef(onDuplicateRuler);
  useEffect(() => {
    latestOnDuplicateRuler.current = onDuplicateRuler;
  }, [onDuplicateRuler]);

  const latestLocked = useRef(locked);
  useEffect(() => {
    latestLocked.current = locked;
  }, [locked]);

  // The `duplicatedRuler` state stores a copy of a reference ruler that is being duplicated so that
  // this new space (which is not yet in the `referenceRulers` prop) can be rendered via the
  // ObjectLayer
  const [duplicatedRuler, setDuplicatedRuler] = useState<ReferenceRuler | null>(
    null
  );

  // Cache the endpoint "crosshairs" texture shown at each end of the ruler
  const [endpointTexture, endpointHighlightedTexture] = useMemo(() => {
    return [rulerColor, rulerColorHighlighted].map((color) => {
      const gr = new PIXI.Graphics();
      gr.lineStyle({ width: 1, color });

      // Draw endpoint
      gr.drawCircle(0, 0, RULER_ENDPOINT_CIRCLE_RADIUS_PX);

      // Draw crosshairs in endpoint
      gr.moveTo(-1 * RULER_ENDPOINT_CIRCLE_RADIUS_PX, 0);
      gr.lineTo(RULER_ENDPOINT_CIRCLE_RADIUS_PX, 0);
      gr.moveTo(0, -1 * RULER_ENDPOINT_CIRCLE_RADIUS_PX);
      gr.lineTo(0, RULER_ENDPOINT_CIRCLE_RADIUS_PX);

      return context.app.renderer.generateTexture(gr);
    });
  }, [context.app, rulerColor, rulerColorHighlighted]);

  // Cache the arrow sprite texture shown at the end of the leader line
  const [arrowheadTexture, arrowheadHighlightedTexture] = useMemo(() => {
    return [rulerColor, rulerColorHighlighted].map((color) => {
      const gr = new PIXI.Graphics();
      gr.lineStyle({ width: 0 });
      gr.beginFill(color);

      gr.moveTo(REFERENCE_RULER_ARROW_HEAD_SIZE / 2, 0);
      gr.lineTo(
        REFERENCE_RULER_ARROW_HEAD_SIZE,
        REFERENCE_RULER_ARROW_HEAD_SIZE
      );
      gr.lineTo(0, REFERENCE_RULER_ARROW_HEAD_SIZE);
      gr.lineTo(REFERENCE_RULER_ARROW_HEAD_SIZE / 2, 0);

      return context.app.renderer.generateTexture(gr);
    });
  }, [context.app, rulerColor, rulerColorHighlighted]);

  // Cache the length of each reference ruler as it would be rendered to the user
  // Computing this on each frame is quite expensive it turns out
  const referenceDisplayLengths = useMemo(() => {
    const referenceDisplayLengths: { [referenceId: string]: string } = {};

    for (const reference of referenceRulers) {
      const floorplanX = reference.positionB.x - reference.positionA.x;
      const floorplanY = reference.positionB.y - reference.positionA.y;
      const distanceMeters = Math.hypot(floorplanX, floorplanY);
      referenceDisplayLengths[reference.id] = displayLength(
        distanceMeters,
        context.lengthUnit
      );
    }

    return referenceDisplayLengths;
  }, [referenceRulers, context.lengthUnit]);

  const focusedReferenceRulerPositions = useRef<{
    id: ReferenceRuler['id'];
    positionA: FloorplanCoordinates;
    positionB: FloorplanCoordinates;
    distanceLabelPosition: FloorplanCoordinates;
  } | null>(null);

  const referenceRulersPlusDuplicatedRuler: Array<
    ReferenceRuler & { distanceLabelText?: string }
  > = useMemo(
    () =>
      duplicatedRuler ? [...referenceRulers, duplicatedRuler] : referenceRulers,
    [referenceRulers, duplicatedRuler]
  );

  return (
    <ObjectLayer
      objects={referenceRulersPlusDuplicatedRuler}
      extractId={(referenceRuler) => referenceRuler.id}
      onCreate={(getReferenceRuler) => {
        const container = new PIXI.Container();

        const line = new PIXI.Graphics();
        line.name = 'line';
        container.addChild(line);

        const endpointA = new PIXI.Sprite(endpointTexture);
        endpointA.name = 'endpoint-a';
        endpointA.anchor.set(0.5, 0.5);
        endpointA.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          focusedReferenceRulerPositions.current = {
            id: getReferenceRuler().id,
            positionA: getReferenceRuler().positionA,
            positionB: getReferenceRuler().positionB,
            distanceLabelPosition: getReferenceRuler().distanceLabelPosition,
          };

          const isDistanceLockedToCenter =
            ReferenceRuler.isDistanceLockedToCenter(
              context.floorplan,
              context.viewport.current,
              getReferenceRuler().distanceLabelPosition,
              ReferenceRuler.calculateCenterPoint(getReferenceRuler())
            );

          addDragHandler(
            context,
            getReferenceRuler().positionA,
            evt,
            (newPosition, rawMousePosition) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedReferenceRulerPositions.current) {
                return;
              }
              endpointA.cursor = 'grabbing';

              // Snap reference lines when shift is held
              if (evt.data.originalEvent.shiftKey) {
                const rawMousePositionFloorplan =
                  ViewportCoordinates.toFloorplanCoordinates(
                    rawMousePosition,
                    context.viewport.current,
                    context.floorplan
                  );
                const snappedPosition = snapToAngle(
                  focusedReferenceRulerPositions.current.positionB,
                  rawMousePositionFloorplan
                );
                focusedReferenceRulerPositions.current.positionA =
                  snappedPosition;
              } else {
                focusedReferenceRulerPositions.current.positionA = newPosition;
              }

              // If distance label is locked to center then keep it there
              if (isDistanceLockedToCenter) {
                focusedReferenceRulerPositions.current.distanceLabelPosition =
                  ReferenceRuler.calculateCenterPoint(
                    focusedReferenceRulerPositions.current
                  );
              }
            },
            () => {
              if (!focusedReferenceRulerPositions.current) {
                return;
              }

              endpointA.cursor = 'grab';

              latestOnEndpointsMoved.current &&
                latestOnEndpointsMoved.current(
                  getReferenceRuler(),
                  focusedReferenceRulerPositions.current.positionA,
                  focusedReferenceRulerPositions.current.positionB,
                  focusedReferenceRulerPositions.current.distanceLabelPosition
                );
              focusedReferenceRulerPositions.current = null;
            }
          );
        });
        container.addChild(endpointA);

        const endpointB = new PIXI.Sprite(endpointTexture);
        endpointB.name = 'endpoint-b';
        endpointB.anchor.set(0.5, 0.5);
        endpointB.interactive = true;
        endpointB.cursor = 'grab';
        endpointB.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          focusedReferenceRulerPositions.current = {
            id: getReferenceRuler().id,
            positionA: getReferenceRuler().positionA,
            positionB: getReferenceRuler().positionB,
            distanceLabelPosition: getReferenceRuler().distanceLabelPosition,
          };

          endpointB.cursor = 'grabbing';

          const isDistanceLockedToCenter =
            ReferenceRuler.isDistanceLockedToCenter(
              context.floorplan,
              context.viewport.current,
              getReferenceRuler().distanceLabelPosition,
              ReferenceRuler.calculateCenterPoint(getReferenceRuler())
            );

          addDragHandler(
            context,
            getReferenceRuler().positionB,
            evt,
            (newPosition, rawMousePosition) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedReferenceRulerPositions.current) {
                return;
              }

              endpointB.cursor = 'grabbing';

              // Snap reference lines when shift is held
              if (evt.data.originalEvent.shiftKey) {
                const rawMousePositionFloorplan =
                  ViewportCoordinates.toFloorplanCoordinates(
                    rawMousePosition,
                    context.viewport.current,
                    context.floorplan
                  );
                const snappedPosition = snapToAngle(
                  focusedReferenceRulerPositions.current.positionA,
                  rawMousePositionFloorplan
                );
                focusedReferenceRulerPositions.current.positionB =
                  snappedPosition;
              } else {
                focusedReferenceRulerPositions.current.positionB = newPosition;
              }

              // If distance label is locked to center then keep it there
              if (isDistanceLockedToCenter) {
                focusedReferenceRulerPositions.current.distanceLabelPosition =
                  ReferenceRuler.calculateCenterPoint(
                    focusedReferenceRulerPositions.current
                  );
              }
            },
            () => {
              if (!focusedReferenceRulerPositions.current) {
                return;
              }
              endpointB.cursor = 'grab';
              if (latestOnEndpointsMoved.current) {
                latestOnEndpointsMoved.current(
                  getReferenceRuler(),
                  focusedReferenceRulerPositions.current.positionA,
                  focusedReferenceRulerPositions.current.positionB,
                  focusedReferenceRulerPositions.current.distanceLabelPosition
                );
              }
              focusedReferenceRulerPositions.current = null;
            }
          );
        });
        container.addChild(endpointB);

        const lengthLabel = new MetricLabel(
          getReferenceRuler().distanceLabelText ||
            referenceDisplayLengths[getReferenceRuler().id],
          { backgroundColor: rulerColor }
        );
        lengthLabel.name = 'length-label';
        lengthLabel.interactive = true;
        lengthLabel.cursor = 'grab';
        lengthLabel.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          focusedReferenceRulerPositions.current = {
            id: getReferenceRuler().id,
            positionA: getReferenceRuler().positionA,
            positionB: getReferenceRuler().positionB,
            distanceLabelPosition: getReferenceRuler().distanceLabelPosition,
          };

          // If a user alt/option clicked, then duplicate the ruler instead
          //
          // the `duplicatedRuler` state stores the ruler that is being duplicated so that this
          // ruler (which is not yet in the `rulers` prop) can be rendered via the ObjectLayer
          let isDuplicating =
            latestOnDuplicateRuler.current !== null &&
            evt.data.originalEvent.altKey;
          let duplicatedReferenceRuler: ReferenceRuler | null = null;
          if (isDuplicating) {
            duplicatedReferenceRuler = ReferenceRuler.duplicate(
              getReferenceRuler()
            );
            focusedReferenceRulerPositions.current.id =
              duplicatedReferenceRuler.id;
            setDuplicatedRuler(duplicatedReferenceRuler);
          }

          addDragHandler(
            context,
            getReferenceRuler().distanceLabelPosition,
            evt,
            (newDistanceLabelPosition, raw, evt) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedReferenceRulerPositions.current) {
                return;
              }
              lengthLabel.cursor = 'grabbing';

              // If the shift key is pressed, then "undock" the label
              if (evt.shiftKey) {
                focusedReferenceRulerPositions.current.distanceLabelPosition =
                  newDistanceLabelPosition;
                focusedReferenceRulerPositions.current.positionA =
                  getReferenceRuler().positionA;
                focusedReferenceRulerPositions.current.positionB =
                  getReferenceRuler().positionB;

                // Figure out if the distance label should re-dock to the center
                const centerPoint = ReferenceRuler.calculateCenterPoint(
                  getReferenceRuler()
                );
                const isNowLockedInCenter =
                  ReferenceRuler.isDistanceLockedToCenter(
                    context.floorplan,
                    context.viewport.current,
                    newDistanceLabelPosition,
                    centerPoint
                  );
                if (isNowLockedInCenter) {
                  focusedReferenceRulerPositions.current.distanceLabelPosition =
                    centerPoint;
                }
              } else {
                // Otherwise, move / translate the whole reference line
                const deltaX =
                  newDistanceLabelPosition.x -
                  getReferenceRuler().distanceLabelPosition.x;
                const deltaY =
                  newDistanceLabelPosition.y -
                  getReferenceRuler().distanceLabelPosition.y;

                // Move reference endpoints in sync when the label is dragged
                const newPositionA = FloorplanCoordinates.create(
                  getReferenceRuler().positionA.x + deltaX,
                  getReferenceRuler().positionA.y + deltaY
                );
                const newPositionB = FloorplanCoordinates.create(
                  getReferenceRuler().positionB.x + deltaX,
                  getReferenceRuler().positionB.y + deltaY
                );

                focusedReferenceRulerPositions.current.distanceLabelPosition =
                  newDistanceLabelPosition;
                focusedReferenceRulerPositions.current.positionA = newPositionA;
                focusedReferenceRulerPositions.current.positionB = newPositionB;
              }
            },
            () => {
              if (!focusedReferenceRulerPositions.current) {
                return;
              }

              // If the space was duplicated, then call a different method once the drag has
              // completed
              if (duplicatedReferenceRuler && latestOnDuplicateRuler.current) {
                latestOnDuplicateRuler.current({
                  ...duplicatedReferenceRuler,
                  positionA: focusedReferenceRulerPositions.current.positionA,
                  positionB: focusedReferenceRulerPositions.current.positionB,
                  distanceLabelPosition:
                    focusedReferenceRulerPositions.current
                      .distanceLabelPosition,
                });
                setDuplicatedRuler(null);
                return;
              }

              if (latestOnEndpointsMoved.current) {
                latestOnEndpointsMoved.current(
                  getReferenceRuler(),
                  focusedReferenceRulerPositions.current.positionA,
                  focusedReferenceRulerPositions.current.positionB,
                  focusedReferenceRulerPositions.current.distanceLabelPosition
                );
              }
              focusedReferenceRulerPositions.current = null;
              lengthLabel.cursor = 'grab';
            }
          );
        });
        container.addChild(lengthLabel);

        // The arrowhead is used when rendering the leader line. It's hidden by default.
        const arrowhead = new PIXI.Sprite(arrowheadTexture);
        arrowhead.name = 'arrowhead';
        arrowhead.renderable = false;
        // Anchor the sprite at the arrowhead tip
        arrowhead.anchor.set(0.5, 0);
        container.addChild(arrowhead);

        return container;
      }}
      onUpdate={(referenceRuler, container) => {
        if (!context.viewport.current) {
          return;
        }

        // Hide reference rulers that are disabled
        container.renderable = referenceRuler.enabled;
        if (!container.renderable) {
          // When this object moves out of the Viewport, clean it up.
          // Without clearing it, the object was selectable by clicking
          // on the very border of the Viewport.

          const line = container.getChildByName('line') as PIXI.Graphics;

          if (line) {
            line.clear();
          }
          return;
        }

        const isMovable =
          !latestLocked.current && latestOnEndpointsMoved.current !== null;

        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'reference' &&
          highlightedObject.id === referenceRuler.id;

        const isBeingDragged =
          focusedReferenceRulerPositions.current &&
          focusedReferenceRulerPositions.current.id === referenceRuler.id;
        const positionA =
          isBeingDragged && focusedReferenceRulerPositions.current
            ? focusedReferenceRulerPositions.current.positionA
            : referenceRuler.positionA;
        const positionB =
          isBeingDragged && focusedReferenceRulerPositions.current
            ? focusedReferenceRulerPositions.current.positionB
            : referenceRuler.positionB;
        const distanceLabelPosition =
          isBeingDragged && focusedReferenceRulerPositions.current
            ? focusedReferenceRulerPositions.current.distanceLabelPosition
            : referenceRuler.distanceLabelPosition;

        const positionACoords = FloorplanCoordinates.toViewportCoordinates(
          positionA,
          context.floorplan,
          context.viewport.current
        );

        const positionBCoords = FloorplanCoordinates.toViewportCoordinates(
          positionB,
          context.floorplan,
          context.viewport.current
        );

        const distanceLabelPositionCoords =
          FloorplanCoordinates.toViewportCoordinates(
            distanceLabelPosition,
            context.floorplan,
            context.viewport.current
          );

        // Don't render reference lines that are outside of the viewport
        const lengthLabel = container.getChildByName(
          'length-label'
        ) as MetricLabel;
        container.renderable =
          isWithinViewport(
            context,
            positionACoords,
            -1 * lengthLabel.contents.width
          ) ||
          isWithinViewport(
            context,
            positionBCoords,
            -1 * lengthLabel.contents.width
          ) ||
          isWithinViewport(
            context,
            distanceLabelPositionCoords,
            -1 * lengthLabel.contents.width
          );
        if (!container.renderable) {
          return;
        }

        const line = container.getChildByName('line') as PIXI.Graphics;
        line.clear();
        line.lineStyle({
          width: 2,
          color: isHighlighted ? rulerColorHighlighted : rulerColor,
        });
        line.moveTo(positionACoords.x, positionACoords.y);
        line.lineTo(positionBCoords.x, positionBCoords.y);

        const endpointA = container.getChildByName('endpoint-a') as PIXI.Sprite;
        endpointA.x = positionACoords.x;
        endpointA.y = positionACoords.y;
        endpointA.interactive = isMovable;
        endpointA.cursor = isMovable ? 'grab' : 'default';
        endpointA.texture = isHighlighted
          ? endpointHighlightedTexture
          : endpointTexture;

        const endpointB = container.getChildByName('endpoint-b') as PIXI.Sprite;
        endpointB.x = positionBCoords.x;
        endpointB.y = positionBCoords.y;
        endpointB.interactive = isMovable;
        endpointB.cursor = isMovable ? 'grab' : 'default';
        endpointB.texture = isHighlighted
          ? endpointHighlightedTexture
          : endpointTexture;

        const arrowhead = container.getChildByName('arrowhead') as PIXI.Sprite;
        arrowhead.texture = isHighlighted
          ? arrowheadHighlightedTexture
          : arrowheadTexture;

        // Show the distance label only if not super zoomed out
        // This speeds up floorplan rendering noticably
        if (context.viewport.current.zoom > labelVisibilityZoomThreshold) {
          lengthLabel.renderable = true;
          lengthLabel.interactive = isMovable;
          lengthLabel.cursor = isMovable ? 'grab' : 'default';
          lengthLabel.x = distanceLabelPositionCoords.x;
          lengthLabel.y = distanceLabelPositionCoords.y;

          const newLabelBackgroundColor = isHighlighted
            ? rulerColorHighlighted
            : rulerColor;
          if (newLabelBackgroundColor !== lengthLabel.options.backgroundColor) {
            lengthLabel.options.backgroundColor = newLabelBackgroundColor;
            lengthLabel.redrawTextBounds();
          }

          // If a custom text string is provided, then use that
          if (referenceRuler.distanceLabelText) {
            lengthLabel.setText(referenceRuler.distanceLabelText);

            // Otherwise if it's being dragged, compute the value based off of local cached state
          } else if (isBeingDragged) {
            const floorplanX = positionB.x - positionA.x;
            const floorplanY = positionB.y - positionA.y;
            const distanceMeters = Math.hypot(floorplanX, floorplanY);
            lengthLabel.setText(
              displayLength(distanceMeters, context.lengthUnit)
            );

            // Default to the cached measurement values
          } else {
            lengthLabel.setText(referenceDisplayLengths[referenceRuler.id]);
          }

          // Render distance label
          const centerPoint = ReferenceRuler.calculateCenterPoint({
            positionA,
            positionB,
          });
          const isDistanceLockedToCenter =
            ReferenceRuler.isDistanceLockedToCenter(
              context.floorplan,
              context.viewport.current,
              distanceLabelPosition,
              centerPoint
            );

          // Only render leader line / arrowhead if the distance label is seperated from the
          // reference line
          if (isDistanceLockedToCenter) {
            arrowhead.renderable = false;
            return;
          }

          const centerPointCoords = FloorplanCoordinates.toViewportCoordinates(
            centerPoint,
            context.floorplan,
            context.viewport.current
          );

          // Render the leader line connecting the metric label and the reference line
          line.lineStyle({
            width: 1,
            color: isHighlighted ? rulerColorHighlighted : rulerColor,
          });
          line.moveTo(
            distanceLabelPositionCoords.x,
            distanceLabelPositionCoords.y
          );
          // Leader lines have a little "stub" that comes out of the metric label that is
          // horizontal before connecting to the center of the main reference line
          let stubEndpointPositionCoords: ViewportCoordinates;
          if (distanceLabelPositionCoords.x > centerPointCoords.x) {
            // Stub goes off to the left
            stubEndpointPositionCoords = ViewportCoordinates.create(
              distanceLabelPositionCoords.x -
                lengthLabel.contents.width / 2 -
                LEADER_LINE_STUB_LENGTH_PX,
              distanceLabelPositionCoords.y
            );
          } else {
            // Stub goes off to the right
            stubEndpointPositionCoords = ViewportCoordinates.create(
              distanceLabelPositionCoords.x +
                lengthLabel.contents.width / 2 +
                LEADER_LINE_STUB_LENGTH_PX,
              distanceLabelPositionCoords.y
            );
          }
          line.lineTo(
            stubEndpointPositionCoords.x,
            stubEndpointPositionCoords.y
          );
          line.lineTo(centerPointCoords.x, centerPointCoords.y);

          // Draw arrow on the end of the leader line
          arrowhead.renderable = true;
          arrowhead.x = centerPointCoords.x;
          arrowhead.y = centerPointCoords.y;

          const lineAngleRadians = Math.atan2(
            centerPointCoords.y - stubEndpointPositionCoords.y,
            centerPointCoords.x - stubEndpointPositionCoords.x
          );
          arrowhead.angle = radiansToDegrees(lineAngleRadians) + 90;
        } else {
          lengthLabel.renderable = false;
          arrowhead.renderable = false;
        }
      }}
      onRemove={(referenceRuler, container) => {
        const line = container.getChildByName('line') as PIXI.Graphics;
        line.destroy();

        // NOTE: The endpoint texture is shared between all reference lines, so when cleaning them
        // up, don't destroy the underlying texture
        const endpointA = container.getChildByName('endpoint-a') as PIXI.Sprite;
        endpointA.destroy({ texture: false, baseTexture: false });
        const endpointB = container.getChildByName('endpoint-b') as PIXI.Sprite;
        endpointB.destroy({ texture: false, baseTexture: false });

        const lengthLabel = container.getChildByName(
          'length-label'
        ) as MetricLabel;
        lengthLabel.destroy();

        // NOTE: The arrowhead texture is shared between all reference lines, so when cleaning them
        // up, don't destroy the underlying texture
        const arrowhead = container.getChildByName('arrowhead') as PIXI.Sprite;
        arrowhead.destroy({ texture: false, baseTexture: false });
      }}
    />
  );
};

export default ReferenceRulerLayer;
