import { ChangeEvent, Fragment, useRef, useState } from 'react';
import Modal from 'components/modal';
import AppBar from 'components/app-bar';
import styles from './styles.module.scss';
import KeyboardShortcut from 'components/keyboard-shortcut';
import Button from 'components/button';
import { defaultKeybindings, getKeybindings } from './keybindings';
import { toast } from 'react-toastify';
import { Icons } from '@density/dust';

type Keys = {
  modifiers: string[];
  keys: string[];
};

type Binding = {
  name: string;
  id: number;
  customizable: boolean;
  modifiers: string[];
  keys: string[];
  combo: string[];
};

type BindingCategory = {
  name: string;
  bindings: Binding[];
};

// if the user is using shift with a number, we need to convert the symbol to the number
const symbolMap: { [key: string]: string } = {
  '!': '1',
  '@': '2',
  '#': '3',
  '$': '4', // prettier-ignore
  '%': '5',
  '^': '6',
  '&': '7',
  '*': '8',
  '(': '9',
  ')': '0',
  ' ': 'space',
};

// translate the key name to mousetrap name
const mousetrapMap: { [key: string]: string } = {
  arrowright: 'keyright',
  arrowup: 'keyup',
  arrowdown: 'keydown',
  arrowleft: 'keyleft',
};

const ignoreKeys = ['shift', 'ctrl', 'control', 'alt', 'backspace', 'escape'];

type KeybindingsModalProps = {
  visible: boolean;
  onCancel: () => void;
  onDismiss: () => void;
};

const KeybindingsModal = ({
  visible,
  onCancel,
  onDismiss,
}: KeybindingsModalProps) => {
  const initialBindings = JSON.parse(
    localStorage.getItem('keybindings') || '{}'
  );

  const [showEdit, setShowEdit] = useState({ name: '', edit: false });
  const [bindingList, setBindingList] = useState({ ...initialBindings });
  const [keys, setKeys] = useState<Keys>({ modifiers: [], keys: [] });
  const [isEditing, setIsEditing] = useState(false);

  const loadInputRef = useRef<HTMLInputElement>(null);

  // updates the bindings in the modal
  function updateBindingList(category: string, i: number, keys: Keys) {
    if (!validation(category, i, keys)) {
      toast.error('Invalid keybinding.');
      return;
    }
    const newBindingList = { ...bindingList };
    const newBinding = newBindingList[category].bindings[i];

    newBinding.modifiers = keys.modifiers;
    newBinding.keys = keys.keys;

    setBindingList({ ...newBindingList });
  }

  // restores the binding to the original value
  function restoreBinding(category: string, i: number) {
    const newBindingList = { ...bindingList };
    const newBinding = newBindingList[category].bindings[i];
    const originalBinding = initialBindings[category].bindings[i];
    const keys = {
      modifiers: [...originalBinding.modifiers],
      keys: [...originalBinding.keys],
    };

    newBinding.modifiers = [...keys.modifiers];
    newBinding.keys = [...keys.keys];

    if (!validation(category, i, keys)) {
      toast.error('Invalid keybinding.');
      return;
    }
    setBindingList({ ...newBindingList });
  }

  // saves the bindings to local storage, the rerender will trigger the useEffect in the Editor component and update the mousetrap bindings
  function saveBindings() {
    localStorage.setItem('keybindings', JSON.stringify(bindingList));
    toast.success('Keybindings saved.');
    onDismiss();
  }

  function restoreDefaults() {
    setBindingList(JSON.parse(JSON.stringify(defaultKeybindings)));
    toast.success('Keybindings reset to default. Set Binds to apply changes.');
  }

  function clearBinding(category: string, index: number) {
    setBindingList({
      ...bindingList,
      ...(bindingList[category].bindings[index].modifiers = []),
    });
    setBindingList({
      ...bindingList,
      ...(bindingList[category].bindings[index].keys = []),
    });
  }

  function displayConflictWarning(bindingName: string) {
    const message = (
      <p>
        <span style={{ color: 'white' }}>{bindingName}</span> was using this
        binding. It is now unbound.
      </p>
    );
    toast.warning(message);
  }

  function validateBinding() {
    if (keys.keys.length === 0) {
      return false;
    }
    return true;
  }

  function validateConflict(category: string, index: number, keys: Keys) {
    if (keys.keys.length === 0) return;
    const updatedBindingList = { ...bindingList };

    Object.entries(updatedBindingList).forEach(([currentCategory, value]) => {
      const bindings = (value as BindingCategory).bindings;

      bindings.forEach((binding: Binding, bindingIndex: number) => {
        if (!binding.customizable) return;

        const isSameBinding =
          bindingIndex === index && currentCategory.toLowerCase() === category;
        const isKeyCombinationMatch =
          binding.modifiers.join() === keys.modifiers.join() &&
          binding.keys.join() === keys.keys.join();

        if (!isSameBinding && isKeyCombinationMatch) {
          clearBinding(currentCategory, bindingIndex);
          displayConflictWarning(binding.name);
        }
      });
    });
  }

  function validation(category: string, index: number, keys: Keys) {
    const isValidBinding = validateBinding();
    if (!isValidBinding) return false;
    validateConflict(category, index, keys);
    return true;
  }

  function readFile(e: ChangeEvent<HTMLInputElement>) {
    const displayError = (message: string = 'Error reading file.') => {
      toast.error(message);
      e.target.value = '';
    };
    if (!e.target || !e.target.files) {
      displayError();
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      displayError();
      return;
    }

    if (file.type !== 'application/json') {
      displayError('Wrong file type.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      if (!loadEvent.target) {
        displayError();
        return;
      }
      const content = loadEvent.target.result;

      if (!content || typeof content !== 'string') {
        displayError();
        return;
      }

      try {
        const bindings = getKeybindings(content);
        setBindingList({ ...bindings });
        e.target.value = '';
      } catch (error) {
        displayError();
      }
    };

    reader.readAsText(file);
  }

  function downloadKeybindings() {
    // Retrieve the data from local storage
    const data = localStorage.getItem('keybindings');
    if (!data) {
      console.error('keys are not stored locally');
      toast.error('Error downloading keybindings.');
      return;
    }

    // Convert the data to a Blob
    const blob = new Blob([data], { type: 'application/json' });

    // Create a downloadable URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `planner_keybindings_${Date.now()}.json`; // Name of the file to be downloaded
    document.body.appendChild(a); // Append to body to make it work in Firefox
    a.click(); // Simulate a click on the anchor
    document.body.removeChild(a); // Clean up

    // Free up the blob URL memory
    URL.revokeObjectURL(url);
  }
  function cancelBindings() {
    setBindingList(JSON.parse(localStorage.getItem('keybindings') || '{}'));
    onCancel();
  }
  return (
    <Fragment>
      <input
        ref={loadInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => readFile(e)}
      />
      <Modal
        visible={visible}
        width={600}
        height={650}
        onEscape={onDismiss}
        onBlur={onDismiss}
      >
        <AppBar title="Key Bindings" />
        <div style={{ overflowY: 'scroll', height: '550px' }}>
          {Object.keys(bindingList).map((category) => {
            if (bindingList[category].name === 'Modifiers') return null;
            const bindingOptions = bindingList[category].bindings.map(
              (binding: Binding, i: number) => {
                if (binding.customizable === false) return null;
                const currentBinding = `${
                  binding.modifiers.length > 0
                    ? `${binding.modifiers.join('+')}+`
                    : ''
                }${binding.keys}`;
                return (
                  <div className={styles.settingsMenuRow}>
                    <div className={styles.settingsMenuRowLabel}>
                      <div className={styles.settingsMenuRowLabelText}>
                        {binding.name}
                      </div>
                    </div>

                    <button
                      className={styles.keyBindButton}
                      onClick={() =>
                        setShowEdit({ name: binding.name, edit: true })
                      }
                    >
                      {showEdit.name === binding.name && showEdit.edit ? (
                        <input
                          type="text"
                          autoFocus
                          value={currentBinding}
                          onBlur={() => setShowEdit({ name: '', edit: false })}
                          onKeyDown={(e) => {
                            setIsEditing(true);
                            if (e.key === 'Escape' || e.key === 'Enter') {
                              setShowEdit({ name: '', edit: false });
                              return;
                            }
                            const keys: Keys = {
                              modifiers: [],
                              keys: [],
                            };
                            if (e.shiftKey) {
                              keys.modifiers.push('shift');
                            }

                            if (e.ctrlKey) {
                              keys.modifiers.push('ctrl');
                            }

                            if (e.altKey) {
                              keys.modifiers.push('alt');
                            }
                            const keyPress = e.key.toLowerCase();
                            if (!ignoreKeys.find((key) => key === keyPress)) {
                              const newKey = mousetrapMap.hasOwnProperty(
                                keyPress
                              )
                                ? mousetrapMap[keyPress]
                                : keyPress;
                              keys.keys.push(
                                symbolMap.hasOwnProperty(newKey)
                                  ? symbolMap[newKey]
                                  : newKey.toLowerCase()
                              );
                            }
                            setKeys(keys);
                          }}
                          onKeyUp={(e) => {
                            if (!isEditing) return;
                            updateBindingList(category, i, keys);
                            setIsEditing(false);
                          }}
                        />
                      ) : (
                        <KeyboardShortcut>{currentBinding}</KeyboardShortcut>
                      )}
                    </button>
                    <Button
                      type="hollow"
                      size="small"
                      onClick={() => restoreBinding(category, i)}
                    >
                      <Icons.Redo size={15} />
                    </Button>
                  </div>
                );
              }
            );
            return (
              <Fragment>
                <div className={styles.settingsMenuRowHeader}>
                  {initialBindings[category].name}
                </div>
                {bindingOptions}
              </Fragment>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px 20px 0px 20px',
          }}
        >
          <Button size="medium" type="outlined" onClick={cancelBindings}>
            Cancel
          </Button>
          <Button
            size="medium"
            type="outlined"
            onClick={() => {
              if (loadInputRef.current) {
                loadInputRef.current.click();
              }
            }}
          >
            Load
          </Button>
          <Button size="medium" type="outlined" onClick={downloadKeybindings}>
            Download
          </Button>
          <Button size="medium" type="outlined" onClick={restoreDefaults}>
            Reset Defaults
          </Button>
          <Button size="medium" onClick={saveBindings}>
            Set Binds
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default KeybindingsModal;
