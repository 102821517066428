import { loadCookieData } from '@densityco/lib-common-auth';
import axios from 'axios';

export interface DensityAuthHeaders {
  Authorization: string;
  'X-Impersonate-User'?: string;
}

export function createDensityAPIClient(authHeaders: DensityAuthHeaders) {
  const client = axios.create({
    baseURL: loadCookieData().environment.coreHost,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeaders,
    },
  });

  // better stack traces from axios
  // https://github.com/axios/axios/issues/2387
  client.interceptors.request.use((config) => {
    // @ts-ignore
    config.errorContext = new Error('Thrown at:');
    return config;
  });

  client.interceptors.response.use(undefined, async (error) => {
    const originalStackTrace = error.config?.errorContext?.stack;
    if (originalStackTrace) {
      error.stack = `${error.stack}\n${originalStackTrace}`;
    }

    throw error;
  });

  return client;
}
