import { FloorplanAPI } from 'lib/api';
import PlanSensor from 'lib/sensor';
import { useAppSelector } from 'redux/store';
import React, { useState, useEffect } from 'react';
import wasmInit, { readParquet } from 'parquet-wasm';
import { toast } from 'react-toastify';
import * as arrow from 'apache-arrow';
import { ObjectLayer, useFloorplanLayerContext } from 'components/floorplan';
import * as PIXI from 'pixi.js';
import { FloorplanCoordinates, SensorCoordinates } from 'lib/geometry';

import {
  Green400,
  Red400,
  Blue300,
  Yellow300,
  Purple300,
  Purple600,
  Orange400,
  Green700,
  Pink300,
  Blue100,
} from '@density/dust/dist/tokens/dust.tokens';
import { State } from '../state';

interface ParquetReaderProps {
  arrayBuffer: ArrayBuffer;
  sensor: PlanSensor;
  state: State;
}

interface Track {
  sensorSerialNumber: string;
  index: number;
  track: TrackPoint[];
}

interface TrackPoint {
  timestamp: any;
  x: number;
  y: number;
}
const TRACK_COLORS = [
  hexToNumber(Red400), // Red
  hexToNumber(Green400), // Green
  hexToNumber(Blue300), // Blue
  hexToNumber(Yellow300), // Yellow
  hexToNumber(Purple600), // Magenta
  hexToNumber(Blue100), // Cyan
  hexToNumber(Orange400), // Orange
  hexToNumber(Purple300), // Purple
  hexToNumber(Green700), // Dark Green
  hexToNumber(Pink300), // Pink
];

// convert hex to number
function hexToNumber(hex: string): number {
  return parseInt(hex.replace('#', ''), 16);
}

function getColorForTrack(index: number): number {
  return TRACK_COLORS[index % TRACK_COLORS.length];
}

const ParquetReader: React.FC<ParquetReaderProps> = ({
  arrayBuffer,
  sensor,
  state,
}) => {
  const [data, setData] = useState<Track[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const context = useFloorplanLayerContext();
  const [serialNumber, setSerialNumber] = useState<string | undefined | null>(
    sensor.serialNumber
  );

  useEffect(() => {
    if (sensor && sensor.serialNumber !== serialNumber) {
      setData([]);
      setSerialNumber(sensor.serialNumber);
    }
  }, [sensor, serialNumber]);

  useEffect(() => {
    async function initializeWasm() {
      try {
        await wasmInit();
        setIsInitialized(true);
      } catch (err) {
        console.error('Failed to initialize parquet-wasm:', err);
        setError(
          'Failed to initialize Parquet reader. Please check the console for more details.'
        );
      }
    }

    initializeWasm();
  }, []);

  useEffect(() => {
    if (!isInitialized || !arrayBuffer) return;
    async function parseParquet() {
      try {
        const arrowWasmTable = await readParquet(new Uint8Array(arrayBuffer));
        const table = arrow.tableFromIPC(arrowWasmTable.intoIPCStream());

        // get the field index for timestamp, x, and y
        const timestampIndex = table.schema.fields.findIndex(
          (field) => field.name === 'timestamp'
        );
        const xIndex = table.schema.fields.findIndex(
          (field) => field.name === 'x'
        );
        const yIndex = table.schema.fields.findIndex(
          (field) => field.name === 'y'
        );

        // Assuming table.batches represents all tracks
        const allTracks = table.batches;
        const randomTracks: Track[] = [];

        // Function to get random tracks
        const getRandomTracks = (tracks: any[], count: number) => {
          const shuffled = [...tracks].sort(() => 0.5 - Math.random());
          return shuffled.slice(0, count);
        };

        // Get 10 random tracks
        const selectedTracks = getRandomTracks(allTracks, state.tracksCount);

        // Process the selected tracks
        selectedTracks.forEach((batch, index) => {
          let track: TrackPoint[] = [];
          const timestampData = batch.data.children[timestampIndex];
          const xData = batch.data.children[xIndex];
          const yData = batch.data.children[yIndex];

          for (let j = 0; j < timestampData.length; j++) {
            const readableDate = new Date(
              Number(timestampData.values[j]) / 1000000
            );
            const formattedDate = readableDate.toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              fractionalSecondDigits: 3,
            });

            const floorplanCoords = SensorCoordinates.toFloorplanCoordinates(
              SensorCoordinates.create(xData.values[j], yData.values[j]),
              sensor
            );

            if (!context.floorplan || !context.viewport.current) {
              console.error('No floorplan or viewport found');
              return;
            }

            const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
              FloorplanCoordinates.create(floorplanCoords.x, floorplanCoords.y),
              context.floorplan,
              context.viewport.current
            );

            const timestamp = formattedDate;
            const x = viewportCoords.x;
            const y = viewportCoords.y;

            track.push({ timestamp, x, y });
          }

          randomTracks.push({
            sensorSerialNumber: sensor.serialNumber || '',
            track,
            index: index,
          });
        });

        setData(randomTracks);
      } catch (err) {
        console.error('Error reading Parquet file:', err);
        if (err instanceof Error) {
          setError(`Failed to read Parquet file: ${err.message}`);
        } else {
          setError('An unknown error occurred while reading the Parquet file.');
        }
      }
    }

    if (arrayBuffer) {
      parseParquet();
    }
  }, [
    arrayBuffer,
    isInitialized,
    sensor,
    state.tracksCount,
    context.floorplan,
    context.viewport,
  ]);

  if (error || !data || data.length === 0) {
    return null;
  }

  return (
    <ObjectLayer
      objects={data}
      extractId={(track) => `${track.sensorSerialNumber}-${track.index}`}
      onCreate={(getTrack) => {
        const trackLinesGraphic = new PIXI.Container();

        const track = getTrack();

        const trackLinesPolygons = new PIXI.Graphics();
        trackLinesPolygons.name = `track-lines-${track.index}`;
        trackLinesPolygons.interactive = false;
        trackLinesGraphic.addChild(trackLinesPolygons);

        return trackLinesGraphic;
      }}
      onUpdate={(track, trackLinesGraphic) => {
        // Draw main sensor coverage area
        const trackLinesPolygons = trackLinesGraphic.getChildByName(
          `track-lines-${track.index}`
        ) as PIXI.Graphics;

        if (!trackLinesPolygons) {
          return;
        }
        trackLinesPolygons.clear();

        if (track.sensorSerialNumber !== serialNumber) {
          return;
        }
        // Draw dots for each point
        trackLinesPolygons.lineStyle(0); // Reset line style
        trackLinesPolygons.beginFill(getColorForTrack(track.index)); // Set fill color to black

        for (let i = 0; i < track.track.length; i++) {
          const point = track.track[i];
          trackLinesPolygons.drawCircle(point.x, point.y, 1.5); // Draw a small circle (dot) at each point
        }

        trackLinesPolygons.endFill();

        trackLinesGraphic.x = 0;
        trackLinesGraphic.y = 0;
      }}
      onRemove={(trackLine, trackLinesGraphic) => {
        trackLine.track.forEach((_, index) => {
          const trackLineGraphic = trackLinesGraphic.getChildByName(
            `track-lines-${index}`
          );
          trackLineGraphic?.destroy(true);
        });
      }}
    />
  );
};

export const TracksLayer: React.FunctionComponent<{
  sensor: PlanSensor | undefined;
  state: State;
}> = ({ sensor, state }) => {
  const [data, setData] = useState<ArrayBuffer>();
  const client = useAppSelector((state) => state.auth.appAPIClient);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string | undefined | null>(
    sensor?.serialNumber
  );

  useEffect(() => {
    if (sensor) {
      setSerialNumber(sensor.serialNumber);
      if (sensor.serialNumber !== serialNumber) {
        setIsReady(false);
      }
    }
  }, [sensor, serialNumber]);

  const { tokenCheckResponse } = useAppSelector((state) => state.auth);
  const organizationId = tokenCheckResponse?.organization?.id;

  useEffect(() => {
    async function fetchData() {
      if (!client || isReady || !sensor?.serialNumber) {
        return;
      }

      const minute = 60 * 1000;
      const hour = 60 * minute;
      const day = 24 * hour;
      const fiveDays = 10 * day;
      // start date should be YYYY-MM-DD 10 days ago
      const fiveDaysAgo = new Date(Date.now() - fiveDays)
        .toISOString()
        .split('T')[0];
      const today = new Date().toISOString().split('T')[0];

      const startDate = state.tracksStartDate
        ? `${state.tracksStartDate.split('T')[0]}T${
            state.tracksStartDate.split('T')[1].split('-')[0]
          }`
        : fiveDaysAgo;
      const endDate = state.tracksEndDate
        ? `${state.tracksEndDate.split('T')[0]}T${
            state.tracksEndDate.split('T')[1].split('-')[0]
          }`
        : today;

      toast.info(`Fetching tracks for ${sensor.serialNumber}`);

      // end date should be YYYY-MM-DD
      // You can await here
      try {
        setIsReady(true);
        const response = await FloorplanAPI.getSensorTracks(
          client,
          organizationId || '',
          [sensor.serialNumber],
          startDate,
          endDate
        );
        toast.success(`Successfully fetched tracks for ${sensor.serialNumber}`);
        setData(response.data);
      } catch (err) {
        toast.error(`Error fetching tracks: ${err}`);
        return;
      }
    }
    fetchData();
  }, [
    client,
    isReady,
    sensor?.serialNumber,
    state.tracksStartDate,
    state.tracksEndDate,
    organizationId,
  ]);

  if (!data) {
    return <div>Loading...</div>;
  }

  if (!sensor || !isReady || sensor.serialNumber !== serialNumber) {
    return null;
  }

  return <ParquetReader arrayBuffer={data} sensor={sensor} state={state} />;
};
