import {
  useEffect,
  Fragment,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import * as PIXI from 'pixi.js';
import Mousetrap from 'mousetrap';

import styles from './styles.module.scss';
import {
  Gray900,
  Green400,
  Space4,
  Radius200,
} from '@density/dust/dist/tokens/dust.tokens';
import {
  applyToPoint,
  inverse,
  compose,
  translate,
  rotateDEG,
  scale as scaleTransformation,
} from 'transformation-matrix';

import { Icons } from '@density/dust';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import Button from 'components/button';
import Switch from 'components/switch/switch';
import Floorplan, { toRawHex, ResizeHandle } from 'components/floorplan';
import FloorplanZoomControls from 'components/floorplan-zoom-controls';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelBody, PanelHeader } from 'components/panel';
import FloorplanCoordinatesField from 'components/floorplan-coordinates-field';
import RotationField from 'components/rotation-field';
import ScaleField from 'components/scale-field';
import FormLabel from 'components/form-label';
import MultiTagField from 'components/multi-tag-field';
import SupplementaryActionsLayer from 'components/editor/action-layers/actions-layer';
import ImageRegistrationLayer from 'components/editor/floorplan-layers/image-registration-layer';

import {
  FloorplanCoordinates,
  ImageCoordinates,
  computeLineSegmentsInBoundingRegion,
} from 'lib/geometry';
import { modulo } from 'lib/math';
import FloorplanType from 'lib/floorplan';
import { LengthUnit } from 'lib/units';
import WallSegment from 'lib/wall-segment';
import { FixMe } from 'types/fixme';

export interface LineSegment {
  id: string;
  positionA: ImageCoordinates;
  positionB: ImageCoordinates;
  strokeColors: Array<string>;
  layers: Array<string>;
}

export type ImageLineSegmentImportActive = {
  lineSegments: LineSegment[];
  imageWidth: number;
  imageHeight: number;
  strokeColors: Array<string>;
  layers: Array<string>;
};

// The line segment import workflow allows a user to import line segments from a DXF file previously
// imported and convert each bit of geometry in the DXF file into wall segments.
//
// The way this works is by fetching the SVG artifact generated when the dxf import is performed,
// parsing this in a webworker, and rendering this below component, which allows the user to
// position / preview the output prior to doing the conversion.
const WallEditorLineSegmentImport: React.FunctionComponent<{
  floorplan: FloorplanType;
  floorplanImage: HTMLImageElement;
  displayUnit: LengthUnit;
  data: ImageLineSegmentImportActive;
  onCancel?: () => void;
  onSubmit: (wallSegments: Array<WallSegment>) => void;
}> = ({ displayUnit, floorplan, floorplanImage, data, onCancel, onSubmit }) => {
  const [[position, rotation, scale], setTransform] = useState<
    [FloorplanCoordinates, number, number]
  >([FloorplanCoordinates.create(0, 0), 0, 0.0254]);

  const floorplanRef = useRef<FixMe | null>(null);
  const [scaleByDraw, setScaleByDraw] = useState<boolean>(false);

  useEffect(() => {
    const mousetrap = new Mousetrap();

    mousetrap.bind('esc', () => setScaleByDraw(false));

    return () => {
      mousetrap.reset();
    };
  }, []);

  // This matrix transforms from old floorplan coordinates to new floorplan
  // coordinates based off the specified transformation.
  const matrix = useMemo(() => {
    return compose(
      translate(position.x * floorplan.scale, position.y * floorplan.scale),
      translate(floorplan.origin.x, floorplan.origin.y),
      rotateDEG(rotation),
      scaleTransformation(floorplan.scale * scale, floorplan.scale * scale)
    );
  }, [position, rotation, floorplan.scale, floorplan.origin, scale]);

  const [filters, setFilters] = useState<
    Array<{
      id: string;
      upperLeft: ImageCoordinates;
      lowerRight: ImageCoordinates;
      strokeColors: Array<string>;
      layers: Array<string>;
    }>
  >([]);

  const segmentIdToStrokeColorOrNull = useMemo(() => {
    const defaultVisibility = filters.length === 0 ? true : false;
    const segmentIdToStrokeColorOrNull = new Map<string, string | null>(
      data.lineSegments.map((i) => [
        i.id,
        defaultVisibility && i.strokeColors.length > 0
          ? i.strokeColors[0]
          : null,
      ])
    );

    for (const filter of filters) {
      const segmentsInRegion = computeLineSegmentsInBoundingRegion(
        data.lineSegments,
        ImageCoordinates.toFloorplanCoordinates(filter.upperLeft, floorplan),
        ImageCoordinates.toFloorplanCoordinates(filter.lowerRight, floorplan),
        (segment) => [
          ImageCoordinates.toFloorplanCoordinates(segment.positionA, floorplan),
          ImageCoordinates.toFloorplanCoordinates(segment.positionB, floorplan),
        ]
      );

      for (const segment of segmentsInRegion) {
        const strokeColor = segment.strokeColors.find((color) =>
          filter.strokeColors.includes(color)
        );
        if (typeof strokeColor !== 'string') {
          continue;
        }

        if (
          filter.layers.length === 0 ||
          (filter.layers.length > 0 &&
            segment.layers.find((l) => filter.layers.includes(l)))
        ) {
          segmentIdToStrokeColorOrNull.set(segment.id, strokeColor);
        }
      }
    }

    return segmentIdToStrokeColorOrNull;
  }, [data.lineSegments, filters, floorplan]);

  const numberOfVisibleSegments = useMemo(
    () =>
      Array.from(segmentIdToStrokeColorOrNull).filter(([_key, value]) => value)
        .length,
    [segmentIdToStrokeColorOrNull]
  );

  const addFilter = useCallback(() => {
    setFilters((filters) => [
      ...filters,
      {
        id: uuid4(),
        upperLeft: ImageCoordinates.create(0, 0),
        lowerRight: ImageCoordinates.create(data.imageWidth, data.imageHeight),
        strokeColors: data.strokeColors,
        layers: [],
      },
    ]);
  }, [data]);

  return (
    <Fragment>
      <DarkTheme>
        <AppBar
          title="Import Wall Data"
          actions={
            <HorizontalForm size="medium">
              {onCancel ? (
                <Button
                  size="medium"
                  type="cleared"
                  onClick={() => {
                    if (!onCancel) {
                      return;
                    }
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                size="medium"
                disabled={numberOfVisibleSegments === 0}
                onClick={() => {
                  const walls = data.lineSegments.flatMap((lineSegment) => {
                    if (
                      segmentIdToStrokeColorOrNull.get(lineSegment.id) === null
                    ) {
                      return [];
                    }

                    const positionATransformedRaw = applyToPoint(matrix, [
                      lineSegment.positionA.x,
                      lineSegment.positionA.y,
                    ]);
                    const positionA = ImageCoordinates.toFloorplanCoordinates(
                      ImageCoordinates.create(
                        positionATransformedRaw[0],
                        positionATransformedRaw[1]
                      ),
                      floorplan
                    );

                    const positionBTransformedRaw = applyToPoint(matrix, [
                      lineSegment.positionB.x,
                      lineSegment.positionB.y,
                    ]);
                    const positionB = ImageCoordinates.toFloorplanCoordinates(
                      ImageCoordinates.create(
                        positionBTransformedRaw[0],
                        positionBTransformedRaw[1]
                      ),
                      floorplan
                    );

                    return [WallSegment.create(positionA, positionB)];
                  });
                  onSubmit(walls);
                }}
              >
                {numberOfVisibleSegments > 0
                  ? `Import ${numberOfVisibleSegments} wall segments`
                  : 'No wall segments selected'}
              </Button>
            </HorizontalForm>
          }
        />
      </DarkTheme>

      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Floorplan
            ref={floorplanRef}
            image={floorplanImage}
            floorplan={floorplan}
            width="100%"
            height="100%"
            lengthUnit={displayUnit}
          >
            <ImageRegistrationLayer
              imageWidth={data.imageWidth}
              imageHeight={data.imageHeight}
              createImage={(container) => {
                const graphics = new PIXI.Graphics();
                graphics.name = 'graphics';
                container.addChild(graphics);

                // Add a backdrop sprite so that the entire thing is clickable / dragable
                // Otherwise, only the lines on the graphics element are clickable
                const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
                backdrop.width = data.imageWidth;
                backdrop.height = data.imageHeight;
                backdrop.alpha = 0;
                backdrop.interactive = true;
                container.addChild(backdrop);

                const filterContainer = new PIXI.Container();
                filterContainer.name = 'filter-container';
                container.addChild(filterContainer);
              }}
              updateImage={(container, context) => {
                if (!context.viewport.current) {
                  return;
                }

                // This is the scale factor needed to draw a 1px wide line within the ImageRegistrationLayer
                // 1px when rendered within the ImageRegistrationLayer is scaled by the `scale` prop
                // of the component and that can result in it being very wide
                const scaleFactor =
                  scale *
                  context.floorplan.scale *
                  context.viewport.current.zoom;

                const graphics = container.getChildByName(
                  'graphics'
                ) as PIXI.Graphics | null;
                if (!graphics) {
                  return;
                }

                graphics.clear();
                for (const lineSegment of data.lineSegments) {
                  const strokeColorOrNull = segmentIdToStrokeColorOrNull.get(
                    lineSegment.id
                  );
                  if (strokeColorOrNull === null) {
                    continue;
                  }

                  graphics.lineStyle({
                    width: 1 / scaleFactor,
                    color: toRawHex(strokeColorOrNull || Gray900),
                    join: PIXI.LINE_JOIN.ROUND,
                  });
                  graphics.moveTo(
                    lineSegment.positionA.x,
                    lineSegment.positionA.y
                  );
                  graphics.lineTo(
                    lineSegment.positionB.x,
                    lineSegment.positionB.y
                  );
                }

                const filterContainer = container.getChildByName(
                  'filter-container'
                ) as PIXI.Container | null;
                if (!filterContainer) {
                  return;
                }

                if (filterContainer.children.length > filters.length) {
                  while (filters.length !== filterContainer.children.length) {
                    filterContainer.removeChild(filterContainer.children[0]);
                  }
                } else if (filterContainer.children.length < filters.length) {
                  while (filters.length !== filterContainer.children.length) {
                    const container = new PIXI.Container();

                    const graphics = new PIXI.Graphics();
                    graphics.name = 'graphics';
                    container.addChild(graphics);

                    const leftResizeHandle = new ResizeHandle(
                      context,
                      (newPosition) => {
                        const newPositionImage =
                          FloorplanCoordinates.toImageCoordinates(
                            newPosition,
                            floorplan
                          );
                        const newPositionTransformedRaw = applyToPoint(
                          inverse(matrix),
                          [newPositionImage.x, newPositionImage.y]
                        );

                        setFilters((filters) =>
                          filters.map((f) => {
                            if (f.id === container.name) {
                              return {
                                ...f,
                                upperLeft: ImageCoordinates.create(
                                  newPositionTransformedRaw[0],
                                  f.upperLeft.y
                                ),
                              };
                            } else {
                              return f;
                            }
                          })
                        );
                      },
                      { color: toRawHex(Green400) }
                    );
                    leftResizeHandle.name = 'left-resize-handle';
                    container.addChild(leftResizeHandle);

                    const topResizeHandle = new ResizeHandle(
                      context,
                      (newPosition) => {
                        const newPositionImage =
                          FloorplanCoordinates.toImageCoordinates(
                            newPosition,
                            floorplan
                          );
                        const newPositionTransformedRaw = applyToPoint(
                          inverse(matrix),
                          [newPositionImage.x, newPositionImage.y]
                        );

                        setFilters((filters) =>
                          filters.map((f) => {
                            if (f.id === container.name) {
                              return {
                                ...f,
                                upperLeft: ImageCoordinates.create(
                                  f.upperLeft.x,
                                  newPositionTransformedRaw[1]
                                ),
                              };
                            } else {
                              return f;
                            }
                          })
                        );
                      },
                      { color: toRawHex(Green400) }
                    );
                    topResizeHandle.name = 'top-resize-handle';
                    container.addChild(topResizeHandle);

                    const rightResizeHandle = new ResizeHandle(
                      context,
                      (newPosition) => {
                        const newPositionImage =
                          FloorplanCoordinates.toImageCoordinates(
                            newPosition,
                            floorplan
                          );
                        const newPositionTransformedRaw = applyToPoint(
                          inverse(matrix),
                          [newPositionImage.x, newPositionImage.y]
                        );

                        setFilters((filters) =>
                          filters.map((f) => {
                            if (f.id === container.name) {
                              return {
                                ...f,
                                lowerRight: ImageCoordinates.create(
                                  newPositionTransformedRaw[0],
                                  f.lowerRight.y
                                ),
                              };
                            } else {
                              return f;
                            }
                          })
                        );
                      },
                      { color: toRawHex(Green400) }
                    );
                    rightResizeHandle.name = 'right-resize-handle';
                    container.addChild(rightResizeHandle);

                    const bottomResizeHandle = new ResizeHandle(
                      context,
                      (newPosition) => {
                        const newPositionImage =
                          FloorplanCoordinates.toImageCoordinates(
                            newPosition,
                            floorplan
                          );
                        const newPositionTransformedRaw = applyToPoint(
                          inverse(matrix),
                          [newPositionImage.x, newPositionImage.y]
                        );

                        setFilters((filters) =>
                          filters.map((f) => {
                            if (f.id === container.name) {
                              return {
                                ...f,
                                lowerRight: ImageCoordinates.create(
                                  f.lowerRight.x,
                                  newPositionTransformedRaw[1]
                                ),
                              };
                            } else {
                              return f;
                            }
                          })
                        );
                      },
                      { color: toRawHex(Green400) }
                    );
                    bottomResizeHandle.name = 'bottom-resize-handle';
                    container.addChild(bottomResizeHandle);

                    filterContainer.addChild(container);
                  }
                }

                for (let i = 0; i < filters.length; i += 1) {
                  const filter = filters[i];
                  const container = filterContainer.children[
                    i
                  ] as PIXI.Container;
                  container.name = filter.id;
                  const graphics = container.getChildByName(
                    'graphics'
                  ) as PIXI.Graphics;
                  graphics.clear();
                  graphics.lineStyle({
                    width: 1 / scaleFactor,
                    color: toRawHex(Green400),
                  });
                  graphics.drawRect(
                    filter.upperLeft.x,
                    filter.upperLeft.y,
                    filter.lowerRight.x - filter.upperLeft.x,
                    filter.lowerRight.y - filter.upperLeft.y
                  );

                  const leftResizeHandle = container.getChildByName(
                    'left-resize-handle'
                  ) as ResizeHandle;
                  leftResizeHandle.x = filter.upperLeft.x;
                  leftResizeHandle.y =
                    (filter.upperLeft.y + filter.lowerRight.y) / 2;
                  leftResizeHandle.cursor = 'move';
                  leftResizeHandle.scale.set(
                    1 /
                      context.viewport.current.zoom /
                      context.floorplan.scale /
                      scale
                  );

                  const topResizeHandle = container.getChildByName(
                    'top-resize-handle'
                  ) as ResizeHandle;
                  topResizeHandle.x =
                    (filter.upperLeft.x + filter.lowerRight.x) / 2;
                  topResizeHandle.y = filter.upperLeft.y;
                  topResizeHandle.cursor = 'move';
                  topResizeHandle.scale.set(
                    1 /
                      context.viewport.current.zoom /
                      context.floorplan.scale /
                      scale
                  );

                  const rightResizeHandle = container.getChildByName(
                    'right-resize-handle'
                  ) as ResizeHandle;
                  rightResizeHandle.x = filter.lowerRight.x;
                  rightResizeHandle.y =
                    (filter.upperLeft.y + filter.lowerRight.y) / 2;
                  rightResizeHandle.cursor = 'move';
                  rightResizeHandle.scale.set(
                    1 /
                      context.viewport.current.zoom /
                      context.floorplan.scale /
                      scale
                  );

                  const bottomResizeHandle = container.getChildByName(
                    'bottom-resize-handle'
                  ) as ResizeHandle;
                  bottomResizeHandle.x =
                    (filter.upperLeft.x + filter.lowerRight.x) / 2;
                  bottomResizeHandle.y = filter.lowerRight.y;
                  bottomResizeHandle.cursor = 'move';
                  bottomResizeHandle.scale.set(
                    1 /
                      context.viewport.current.zoom /
                      context.floorplan.scale /
                      scale
                  );
                }
                container.interactive = scaleByDraw
                  ? false
                  : container.interactive; //disable when scaling with rulers
              }}
              removeImage={(container: PIXI.Container) =>
                container.destroy(true)
              }
              position={position}
              rotationInDegrees={rotation}
              scale={scale}
              isMovable
              isRotatable
              isScalable
              onDragMove={(position, scale, rotation) =>
                setTransform([position, rotation, scale])
              }
            />
            {scaleByDraw ? (
              <SupplementaryActionsLayer
                type={'scale'}
                onScalingCompleted={({
                  scale,
                  rotation,
                  translationX,
                  translationY,
                }) => {
                  setTransform(([oldPosition, oldRotation, oldScale]) => {
                    const newScale = oldScale / scale;
                    const newRotation = rotation + oldRotation; //in degrrees
                    const newPosition = FloorplanCoordinates.create(
                      translationX,
                      translationY
                    );
                    return [newPosition, newRotation, newScale];
                  });
                  setScaleByDraw(false);
                }}
                wallLineSegments={data.lineSegments}
              />
            ) : null}
          </Floorplan>
        </div>

        <Panel position="top-left" width={320}>
          <PanelBody>
            {scaleByDraw ? (
              <>
                <FormLabel
                  label="Draw like a pro!"
                  input={
                    <div style={{ fontSize: '10pt', lineHeight: '1.5' }}>
                      <span>
                        {'\u2022 '}Hold <strong>Shift</strong> to draw beautiful
                        straight lines.
                      </span>
                      <br />
                      <span>{'\u2022 '}Draw left to right, top to down.</span>
                      <br />
                      <span>
                        {'\u2022 '}Zoom in (or out) to draw accurately.
                      </span>
                      <br />
                      <span>
                        {'\u2022 '}Longer reference lines = more accurate
                        scaling.
                      </span>
                      <br />
                      <span>
                        {'\u2022 '}Thick walls? Draw from center to center!
                      </span>
                    </div>
                  }
                />
                <FormLabel
                  label="It's as easy as 1-2-3!"
                  input={
                    <>
                      <div style={{ fontSize: '10pt', lineHeight: '1.5' }}>
                        <span>
                          (1) Measure a simple, <strong>long</strong>, and
                          (preferably) straight Wall Segment.
                        </span>
                        <br />
                        <span>
                          (2) Measure the equivalent segment on the Floorplan
                          image.
                        </span>
                        <br />
                        <span>
                          (3) Align the Wall layer over the Floorplan.
                        </span>
                        <br />
                        <br />
                      </div>
                      <Button
                        size="medium"
                        type="outlined"
                        trailingIcon={<Icons.CancelUnavailable size={18} />}
                        onClick={() => setScaleByDraw(false)}
                        danger
                      >
                        Cancel Scale by Drawing
                      </Button>
                    </>
                  }
                />
              </>
            ) : (
              <>
                <FormLabel
                  label="Position"
                  input={
                    <FloorplanCoordinatesField
                      value={position}
                      onChange={(position) =>
                        setTransform((t) => [position, t[1], t[2]])
                      }
                      computeDefaultValue={() =>
                        FloorplanCoordinates.create(0, 0)
                      }
                    />
                  }
                />

                <FormLabel
                  label="Rotation"
                  input={
                    <RotationField
                      value={rotation}
                      onChange={(rotation) =>
                        setTransform((t) => [t[0], rotation, t[2]])
                      }
                      onRotateRight90={() =>
                        setTransform((t) => [
                          t[0],
                          modulo(t[1] + 90, 360),
                          t[2],
                        ])
                      }
                    />
                  }
                />
                <FormLabel
                  label="Scale"
                  input={
                    <>
                      <ScaleField
                        value={scale}
                        onChange={(scale) =>
                          setTransform((t) => [t[0], t[1], scale])
                        }
                        computeDefaultValue={() => 0.0254}
                      />
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          padding: '10px',
                        }}
                      >
                        or
                      </div>
                      <Button
                        size="medium"
                        trailingIcon={<Icons.DrawFreehand size={18} />}
                        onClick={() => {
                          setTransform((t) => [
                            FloorplanCoordinates.create(0, 0),
                            t[1],
                            t[2],
                          ]);
                          setScaleByDraw(true);
                        }}
                      >
                        Scale by Drawing
                      </Button>
                    </>
                  }
                />
              </>
            )}
          </PanelBody>
          {!scaleByDraw ? (
            <>
              <PanelHeader>
                <div className={styles.filterLinesHeaderWrapper}>
                  <strong style={{ flexGrow: 1 }}>Filter Lines</strong>
                  <HorizontalForm size="medium">
                    <Switch
                      isChecked={filters.length > 0}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          addFilter();
                        } else {
                          setFilters([]);
                        }
                      }}
                    />
                  </HorizontalForm>
                </div>
              </PanelHeader>

              {filters.length === 0 ? (
                <PanelBody>
                  <div className={styles.addFilterWell}>
                    <span className={styles.addFilterWellText}>
                      By default, all{' '}
                      <strong>{numberOfVisibleSegments} lines</strong> in this
                      DXF will be processed as walls.
                    </span>
                    <Button
                      size="medium"
                      leadingIcon={<Icons.Filter size={18} />}
                      onClick={addFilter}
                    >
                      Filter Lines...
                    </Button>
                  </div>
                </PanelBody>
              ) : (
                <PanelBody>
                  {filters.map((filter) => (
                    <div className={styles.filterBox} key={filter.id}>
                      {/*
                  <div className={styles.filterBoxDelete}>
                    <Button
                      type="hollow"
                      size="medium"
                      danger
                      trailingIcon={<Icons.Trash size={18} />}
                      onClick={() =>
                        setFilters((filters) =>
                          filters.filter((f) => f.id !== filter.id)
                        )
                      }
                    />
                  </div>
                  */}
                      <FormLabel
                        label="Bounding Region"
                        input={
                          <div className={styles.boundingRegionWrapper}>
                            <FloorplanCoordinatesField
                              value={FloorplanCoordinates.create(
                                filter.upperLeft.x,
                                filter.upperLeft.y
                              )}
                              units="px"
                              onChange={(newCoords) => {
                                setFilters((filters) =>
                                  filters.map((f) => {
                                    if (f.id !== filter.id) {
                                      return f;
                                    } else if (
                                      newCoords.x === Infinity &&
                                      newCoords.y === Infinity
                                    ) {
                                      // When the reset button is pressed, reset both sets of
                                      // coordinates to fill the whole dxf modelspace
                                      return {
                                        ...f,
                                        upperLeft: ImageCoordinates.create(
                                          0,
                                          0
                                        ),
                                        lowerRight: ImageCoordinates.create(
                                          data.imageWidth,
                                          data.imageHeight
                                        ),
                                      };
                                    } else {
                                      return {
                                        ...f,
                                        upperLeft: ImageCoordinates.create(
                                          newCoords.x,
                                          newCoords.y
                                        ),
                                      };
                                    }
                                  })
                                );
                              }}
                              computeDefaultValue={() =>
                                FloorplanCoordinates.create(Infinity, Infinity)
                              }
                            />
                            <FloorplanCoordinatesField
                              value={FloorplanCoordinates.create(
                                filter.lowerRight.x,
                                filter.lowerRight.y
                              )}
                              units="px"
                              onChange={(newCoords) => {
                                setFilters((filters) =>
                                  filters.map((f) =>
                                    f.id === filter.id
                                      ? {
                                          ...f,
                                          lowerRight: ImageCoordinates.create(
                                            newCoords.x,
                                            newCoords.y
                                          ),
                                        }
                                      : f
                                  )
                                );
                              }}
                            />
                          </div>
                        }
                      />
                      {data.layers.length > 0 ? (
                        <FormLabel
                          label="Layers"
                          input={
                            <MultiTagField
                              options={data.layers.map((id) => ({
                                id,
                                label: id,
                              }))}
                              value={filter.layers.map((id) => ({
                                id,
                                label: id,
                              }))}
                              placeholder="Enter a layer name to filter lines..."
                              popupMaxHeight={64}
                              onChange={(choices) => {
                                const layers = choices.map((c) => c.id);
                                setFilters((filters) =>
                                  filters.map((f) => {
                                    if (f.id !== filter.id) {
                                      return f;
                                    }
                                    return { ...f, layers };
                                  })
                                );
                              }}
                            />
                          }
                        />
                      ) : null}
                      <FormLabel
                        label="Line Colors"
                        input={
                          <div className={styles.lineColorsWrapper}>
                            {data.strokeColors.map((strokeColor) => (
                              <Button
                                key={strokeColor}
                                type={
                                  filter.strokeColors.includes(strokeColor)
                                    ? 'filled'
                                    : 'hollow'
                                }
                                size="medium"
                                onClick={() => {
                                  setFilters((filters) =>
                                    filters.map((f) => {
                                      if (f.id !== filter.id) {
                                        return f;
                                      }
                                      if (
                                        filter.strokeColors.includes(
                                          strokeColor
                                        )
                                      ) {
                                        return {
                                          ...f,
                                          strokeColors: f.strokeColors.filter(
                                            (c) => c !== strokeColor
                                          ),
                                        };
                                      } else {
                                        return {
                                          ...f,
                                          strokeColors: [
                                            ...f.strokeColors,
                                            strokeColor,
                                          ],
                                        };
                                      }
                                    })
                                  );
                                }}
                                trailingIcon={
                                  strokeColor === '' ? (
                                    // NOTE: special empty stroke icon
                                    <div
                                      style={{
                                        width: Space4,
                                        height: Space4,
                                        flexShrink: 0,
                                        borderRadius: Radius200,
                                        border: `1px solid ${Gray900}`,
                                      }}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        width: Space4,
                                        height: Space4,
                                        flexShrink: 0,
                                        borderRadius: Radius200,
                                        backgroundColor: strokeColor,
                                      }}
                                    />
                                  )
                                }
                              />
                            ))}
                          </div>
                        }
                      />
                    </div>
                  ))}
                  {/*
              <div className={styles.addFilterRightAlignBar}>
                <Button
                  size="medium"
                  leadingIcon={<Icons.Plus size={18} />}
                  onClick={addFilter}
                >
                  Add Filter
                </Button>
              </div>
              */}
                </PanelBody>
              )}
            </>
          ) : null}
        </Panel>

        <FloorplanZoomControls
          onZoomToFitClick={() => {
            if (floorplanRef.current) {
              floorplanRef.current.zoomToFit();
            }
          }}
        />
      </div>
    </Fragment>
  );
};

export default WallEditorLineSegmentImport;
