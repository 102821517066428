import { AxiosInstance } from 'axios';
import styles from './styles.module.scss';
import { InfoSection } from './info-section';
import { SpaceBuildingManager, calculateArea } from './spaces-info';
import { BuildingEntityData, FloorData } from './building-manager';
import { FloorplanV2Sensor } from 'lib/api';
import { Meters } from 'lib/units';

type InfoSectionHeader = {
  id: string;
  label: string;
};

const sensorDataHeaders: InfoSectionHeader[] = [
  { id: 'status', label: 'Status' },
  { id: 'sensor_function', label: 'Type' },
  { id: 'cad_id', label: 'CAD ID' },
  { id: 'id', label: 'Sensor ID' },
  { id: 'sensor_serial_number', label: 'Serial Number' },
  { id: 'mac', label: 'MAC' },
  { id: 'ip', label: 'IP' },
  { id: 'floorName', label: 'Floor' },
];

const spaceDataHeaders: InfoSectionHeader[] = [
  { id: 'name', label: 'Name' },
  { id: 'id', label: 'ID' },
  { id: 'iwms_id', label: 'IWMS ID' },
  { id: 'capacity', label: 'Capacity' },
  { id: 'counting_mode', label: 'Counting Mode' },
  { id: 'size', label: 'Area(sqft)' },
  { id: 'function', label: 'Function' },
  { id: 'floorName', label: 'Floor' },
];

export type InfoSectionData = {
  label: string;
  value: string | number;
  contents?: JSX.Element;
  data?: FloorplanV2Sensor[] | SpaceBuildingManager[];
  headers?: InfoSectionHeader[];
};

type FloorInfoProps = {
  data: BuildingEntityData | undefined;
  client: AxiosInstance | null | undefined;
  orgId: string;
};

export const FloorInfo: React.FunctionComponent<FloorInfoProps> = ({
  data,
  client,
  orgId,
}) => {
  if (!client || !data) {
    return null;
  }

  const { floors } = data;
  return (
    <div className={styles.dataSection}>
      <h2>Floors</h2>
      <div className={styles.infoSectionContainer}>
        {floors?.map((floor: FloorData) => {
          const sensorContents = (
            <div className={styles.tooltipContents}>
              <p>
                <span>OALR: </span>{' '}
                {
                  floor.sensors.filter(
                    (sensor: FloorplanV2Sensor) =>
                      sensor.sensor_function === 'oalr'
                  ).length
                }
              </p>
              <p>
                <span>OA: </span>{' '}
                {
                  floor.sensors.filter(
                    (sensor: FloorplanV2Sensor) =>
                      sensor.sensor_function === 'oa'
                  ).length
                }
              </p>
              <p>
                <span>Entry: </span>{' '}
                {
                  floor.sensors.filter(
                    (sensor: FloorplanV2Sensor) =>
                      sensor.sensor_function === 'oe' ||
                      sensor.sensor_function === 'entry'
                  ).length
                }
              </p>
            </div>
          );
          const spaceContents = (
            <div className={styles.tooltipContents}>
              <p>
                OA:{' '}
                {
                  floor.spaces.filter(
                    (space: SpaceBuildingManager) =>
                      space.counting_mode === 'oa'
                  ).length
                }
              </p>
              <p>
                Entry:{' '}
                {
                  floor.spaces.filter(
                    (space: SpaceBuildingManager) =>
                      space.counting_mode === 'entry'
                  ).length
                }
              </p>
            </div>
          );
          const areaCovered = Meters.toSquareFeet(
            floor.aoc.reduce((acc, val) => {
              return acc + val.area_meters_squared;
            }, 0)
          );
          const data: InfoSectionData[] = [
            { label: 'Status', value: floor.status },
            {
              label: 'Total Sensors',
              value: floor.sensors.length,
              contents: sensorContents,
              data: floor.sensors.map((sensor: FloorplanV2Sensor) => {
                return { ...sensor, status: sensor.status || 'planning' };
              }) as FloorplanV2Sensor[],
              headers: sensorDataHeaders,
            },
            {
              label: 'Total Spaces',
              value: floor.spaces.length,
              contents: spaceContents,
              data: floor.spaces.map((space: SpaceBuildingManager) => {
                return {
                  ...space,
                  function: space.function || ' ',
                  size: calculateArea(space),
                };
              }) as SpaceBuildingManager[],
              headers: spaceDataHeaders,
            },
            {
              label: 'Coverage',
              value: areaCovered
                ? areaCovered.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + ' sqft'
                : 'No areas of coverage',
              headers: [],
            },
            {
              label: 'Updated',
              value: new Date(floor.updatedAt as string).toDateString(),
            },
          ];
          return (
            <InfoSection
              title={floor.name}
              data={data}
              planId={floor.id}
              imageUrl={floor.image ?? ''}
              orgId={orgId}
              key={floor.name}
            />
          );
        })}
      </div>
    </div>
  );
};
