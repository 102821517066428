import { useState, useRef, useEffect } from 'react';
import * as React from 'react';
import { AxiosInstance } from 'axios';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Checkbox } from '@densityco/ui';
import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';
import { Icons } from '@density/dust';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';
import { generateExportCanvas } from './export';

import { FixMe } from 'types/fixme';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import HorizontalForm from 'components/horizontal-form';
import LoadingProgressCircle from 'components/loading-progress-circle';
import SelectField from 'components/select-field';

import { FloorplanV2Plan, FloorplanAPI } from 'lib/api';
import { Analytics } from 'lib/analytics';
import FloorplanCollection from 'lib/floorplan-collection';

const EXPORT_STEP_WORKFLOW_ORDER = [
  'fetching',
  'processing',
  'uploading',
  'complete',
  'saving',
  'saving-complete',
];

const ExportPanel: React.FunctionComponent<{
  state: State;
  floorName: string;
  client: AxiosInstance;
  plan: FloorplanV2Plan;
  dispatch: React.Dispatch<Action>;
}> = ({ state, client, floorName, plan, dispatch }) => {
  const [mode, setMode] = useState<'dxf' | 'image' | 'image-entities'>('image');
  const [imageLoading, setImageLoading] = useState(false);

  const [dxfIncludeCoverage, setDXFIncludeCoverage] = useState<boolean>(false);
  const [dxfIncludeWalls, setDXFIncludeWalls] = useState<boolean>(false);
  useEffect(() => {
    setDXFIncludeWalls(!FloorplanCollection.isEmpty(state.walls));
  }, [state.walls, state.panels.dxfOpen]);
  const [imageInvertColors, setImageInvertColors] = useState<boolean>(false);

  const fileName = floorName.toLowerCase().replace(/\W/g, '-');
  const fileNameWithExtension = `${fileName}.${mode === 'dxf' ? 'dxf' : 'png'}`;

  const fileHandleRef = useRef<FixMe | null>(null);

  // When the dxf has completed processing, save it to the local filesystem
  useEffect(() => {
    if (!state.activeExport || state.activeExport.status !== 'complete') {
      return;
    }

    const dxfExportUrl = state.activeExport.exported_object_url;
    if (!dxfExportUrl) {
      return;
    }

    dispatch({ type: 'export.saving' });

    if (fileHandleRef.current) {
      // More info: https://web.dev/file-system-access
      fileHandleRef.current
        .createWritable()
        .then(async (writable: FixMe) => {
          let response;
          try {
            response = await fetch(dxfExportUrl);
          } catch (err) {
            toast.error(
              'Cannot export plan: failed to make request to server!'
            );
            return;
          }
          if (!response.ok) {
            toast.error(
              'Cannot export plan: failed to fetch dxf data from server!'
            );
            return;
          }
          if (!response.body) {
            toast.error(
              'Cannot export plan: dxf data from server was missing!'
            );
            return;
          }

          // More about streaming fetch responses:
          // https://developer.chrome.com/articles/fetch-streaming-requests/
          const reader = response.body.getReader();
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            await writable.write(value);
          }
          await writable.close();

          dispatch({ type: 'export.savingComplete' });
        })
        .catch((err: Error) => {
          console.error('Error downloading file:', err);
          dispatch({ type: 'export.savingFailed' });
        });
    } else {
      // If the file system access api isn't available, then download the file like
      // normal
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = dxfExportUrl;
        link.download = fileNameWithExtension;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(dxfExportUrl);
      }

      dispatch({ type: 'export.savingComplete' });
    }
  }, [state.activeExport, fileNameWithExtension, dispatch]);

  // Once the export is complete, close the panel
  useEffect(() => {
    if (state.activeExport && state.activeExport.status === 'saving-complete') {
      dispatch({
        type: 'panels.dxf.setState',
        setOpen: false,
      });

      toast.success('Plan exported!');
    }
  }, [state.activeExport, dispatch]);

  // If the export fails, show a toast
  useEffect(() => {
    if (state.activeExport && state.activeExport.status === 'error') {
      toast.error('Plan export failed!');
    }
  }, [state.activeExport, dispatch]);

  // When the panel is closed, reset the active export
  useEffect(() => {
    if (!state.panels.dxfOpen) {
      setDXFIncludeCoverage(false);
      dispatch({ type: 'export.reset' });
    }
  }, [state.panels.dxfOpen, dispatch]);

  return (
    <div className={styles.exportPanel}>
      <Tooltip
        contents="Export Plan"
        placement="bottom"
        target={
          <Button
            onClick={() =>
              dispatch({
                type: 'panels.dxf.setState',
                setOpen: !state.panels.dxfOpen,
              })
            }
            trailingIcon={<Icons.DownloadArrow size={18} />}
            size="medium"
            type="outlined"
            data-cy="export-panel-button"
          />
        }
      />
      <div
        className={classnames(styles.exportMenu, {
          [styles.open]: state.panels.dxfOpen,
        })}
      >
        <div className={styles.exportPreview}>
          <div
            className={styles.exportPreviewImage}
            style={
              state.floorplanImage
                ? { backgroundImage: `url(${state.floorplanImage.src})` }
                : {}
            }
          />
          <div className={styles.exportPreviewDetails}>
            <div
              className={styles.exportPreviewTitle}
              data-cy="export-panel-status"
              data-cy-status={
                state.activeExport ? state.activeExport.status : null
              }
            >
              {state.activeExport
                ? {
                    requesting: 'Loading...',
                    created: 'Queueing...',
                    fetching: 'Downloading...',
                    processing: 'Processing...',
                    uploading: 'Uploading...',
                    complete: 'Generated',
                    error: 'Failed',
                    'request-failed': 'Failed',
                    saving: 'Saving...',
                    'saving-complete': 'Complete',
                    'saving-failed': 'Failed',
                  }[state.activeExport.status] || 'Floorplan'
                : 'Floorplan'}
              {state.activeExport &&
              EXPORT_STEP_WORKFLOW_ORDER.includes(state.activeExport.status) ? (
                <LoadingProgressCircle
                  numerator={
                    EXPORT_STEP_WORKFLOW_ORDER.indexOf(
                      state.activeExport.status
                    ) + 1
                  }
                  denominator={EXPORT_STEP_WORKFLOW_ORDER.length}
                />
              ) : null}
              {!state.activeExport ? (
                <div className={styles.exportPreviewBadge}>
                  {mode === 'dxf' ? 'DXF' : 'IMAGE'}
                </div>
              ) : null}
            </div>
            <div className={styles.exportPreviewName}>
              <div className={styles.prefix}>{fileName.slice(0, -3)}</div>
              <div className={styles.suffix}>
                {fileName.slice(-3)}.{mode === 'dxf' ? 'dxf' : 'png'}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.exportControls}>
          <SelectField
            size="medium"
            choices={[
              { id: 'image', label: 'Raw Image' },
              { id: 'image-entities', label: 'Image & Entities' },
              { id: 'dxf', label: 'DXF' },
            ]}
            data-cy="export-format-select"
            onChange={(choice) => setMode(choice.id)}
            value={mode}
            disabled={imageLoading}
          />

          {mode === 'dxf' ? (
            <div>
              <div data-cy="export-panel-include-walls">
                <Checkbox
                  label="Include Walls"
                  checked={dxfIncludeWalls}
                  onChange={(event) =>
                    setDXFIncludeWalls(event.currentTarget.checked)
                  }
                  disabled={
                    state.activeExport !== null ||
                    FloorplanCollection.isEmpty(state.walls)
                  }
                  color={Blue400}
                />
              </div>
              <div data-cy="export-panel-include-coverage">
                <Checkbox
                  label="Include Coverage Ellipses"
                  checked={dxfIncludeCoverage}
                  onChange={(event) =>
                    setDXFIncludeCoverage(event.currentTarget.checked)
                  }
                  disabled={state.activeExport !== null}
                  color={Blue400}
                />
              </div>
            </div>
          ) : null}

          {mode === 'image-entities' ? (
            <Checkbox
              label="Invert Colors"
              checked={imageInvertColors}
              onChange={(event) =>
                setImageInvertColors(event.currentTarget.checked)
              }
              color={Blue400}
              disabled={imageLoading}
            />
          ) : null}
        </div>

        {mode === 'dxf' ? (
          <div className={styles.exportPanelActionBar}>
            <HorizontalForm size="small">
              {state.activeExport ? (
                <Button disabled size="medium" data-cy="export-panel-submit">
                  {state.activeExport.status === 'request-failed' ||
                  state.activeExport.status === 'error'
                    ? 'Export'
                    : 'Loading...'}
                </Button>
              ) : (
                <Button
                  size="medium"
                  onClick={async () => {
                    Analytics.track('Click Export', {
                      spaceId: plan.floor.id,
                      type: 'dxf',
                    });
                    fileHandleRef.current = null;

                    // If it's available, use the new FileSystemAccess API
                    //
                    // This is only available in chrome, but allows one to make a "save dialog" and
                    // write to a local file on disk.
                    if ((window as FixMe).showSaveFilePicker) {
                      // More info: https://web.dev/file-system-access
                      fileHandleRef.current = await (
                        window as FixMe
                      ).showSaveFilePicker({
                        id: 'densityDXFExport',
                        startIn: 'downloads',
                        suggestedName: fileNameWithExtension,
                      });
                    }

                    // When the popup is opened, start the export process
                    dispatch({ type: 'export.begin' });

                    let response;
                    try {
                      response = await FloorplanAPI.createExport(
                        client,
                        plan.id,
                        {
                          options: {
                            include_coverage: dxfIncludeCoverage,
                            include_walls: dxfIncludeWalls,
                            use_base_dxf: plan.active_dxf_id !== null,
                            new_dxf_length_units: state.displayUnit,
                          },
                        }
                      );
                    } catch (err) {
                      console.error(err);
                      toast.error('Error generating export!');
                      dispatch({ type: 'export.beginFailed' });
                      return;
                    }

                    dispatch({
                      type: 'export.update',
                      planExport: response.data,
                      force: true,
                    });
                  }}
                  data-cy="export-panel-submit"
                >
                  Export DXF
                </Button>
              )}
            </HorizontalForm>
          </div>
        ) : null}
        {mode === 'image' ? (
          <div className={styles.exportPanelActionBar}>
            <HorizontalForm size="small">
              <Button
                size="medium"
                onClick={async () => {
                  Analytics.track('Click Export', {
                    spaceId: plan.floor.id,
                    type: 'image',
                  });

                  if (!plan.image_url) {
                    return;
                  }
                  setImageLoading(true);

                  // If it's available, use the new FileSystemAccess API
                  //
                  // This is only available in chrome, but allows one to make a "save dialog" and
                  // write to a local file on disk.
                  // More info: https://web.dev/file-system-access
                  if ((window as FixMe).showSaveFilePicker) {
                    let fileHandle: FixMe;
                    try {
                      fileHandle = await (window as FixMe).showSaveFilePicker({
                        id: 'densityImageExport',
                        startIn: 'downloads',
                        suggestedName: fileNameWithExtension,
                      });
                    } catch {
                      setImageLoading(false);
                      return;
                    }

                    try {
                      const writable = await fileHandle.createWritable();
                      let response;
                      try {
                        response = await fetch(plan.image_url);
                      } catch (err) {
                        toast.error(
                          'Cannot export plan: failed to make request to server!'
                        );
                        setImageLoading(false);
                        return;
                      }
                      if (!response.ok) {
                        toast.error(
                          'Cannot export plan: failed to fetch png data from server!'
                        );
                        setImageLoading(false);
                        return;
                      }
                      if (!response.body) {
                        toast.error(
                          'Cannot export plan: png data from server was missing!'
                        );
                        setImageLoading(false);
                        return;
                      }

                      // More about streaming fetch responses:
                      // https://developer.chrome.com/articles/fetch-streaming-requests/
                      const reader = response.body.getReader();
                      while (true) {
                        const { value, done } = await reader.read();
                        if (done) {
                          break;
                        }
                        await writable.write(value);
                      }
                      await writable.close();

                      toast.success('Plan exported!');
                      setImageLoading(false);
                    } catch (err) {
                      console.error('Error exporting plan:', err);
                      toast.error('Error exporting plan!');
                    }
                    return;
                  }

                  // If the file system access api isn't available, then download the file like
                  // normal
                  const link = document.createElement('a');

                  if (typeof link.download === 'string') {
                    link.href = plan.image_url;
                    link.download = fileNameWithExtension;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } else {
                    window.open(plan.image_url);
                  }
                }}
                disabled={imageLoading}
              >
                {imageLoading ? 'Loading...' : 'Export Raw Image'}
              </Button>
            </HorizontalForm>
          </div>
        ) : null}
        {mode === 'image-entities' ? (
          <div className={styles.exportPanelActionBar}>
            <HorizontalForm size="small">
              <Button
                size="medium"
                onClick={async () => {
                  Analytics.track('Click Export', {
                    spaceId: plan.floor.id,
                    type: 'image-entities',
                  });

                  if (!state.floorplanImage) {
                    return;
                  }
                  setImageLoading(true);

                  // If it's available, use the new FileSystemAccess API
                  //
                  // This is only available in chrome, but allows one to make a "save dialog" and
                  // write to a local file on disk.
                  // More info: https://web.dev/file-system-access
                  let fileHandle: FixMe | null = null;
                  if ((window as FixMe).showSaveFilePicker) {
                    // More info: https://web.dev/file-system-access
                    try {
                      fileHandle = await (window as FixMe).showSaveFilePicker({
                        id: 'densityImageExport',
                        startIn: 'downloads',
                        suggestedName: fileNameWithExtension,
                      });
                    } catch {
                      setImageLoading(false);
                      return;
                    }
                  }

                  // Generate the canvas image
                  //
                  // FIXME: this is done on the main thread, it should probably be moved to a
                  // webworker...
                  let canvas;
                  try {
                    canvas = await generateExportCanvas(
                      state.floorplanImage,
                      state.planSensors,
                      state.references,
                      state.floorplan,
                      state.viewport,
                      state.measurement,
                      state.displayUnit
                    );
                  } catch (err) {
                    console.error('Unable to generate image:', err);
                    toast.error('Unable to generate image!');
                    setImageLoading(false);
                    return;
                  }

                  // If the "invert" checkbox was checked, then invert the canvas colors prior to
                  // exporting
                  if (imageInvertColors) {
                    const newCanvas = document.createElement('canvas');
                    newCanvas.width = canvas.width;
                    newCanvas.height = canvas.height;

                    const ctx = newCanvas.getContext('2d');
                    if (!ctx) {
                      throw new Error('Unable to get canvas context!');
                    }

                    // Invert the colors in this image
                    // ref: https://stackoverflow.com/a/70097101/4115328
                    ctx.filter = 'invert(1)';

                    ctx.drawImage(canvas, 0, 0);

                    canvas = newCanvas;
                  }

                  const uri = canvas.toDataURL(`image/png`, 1.0);

                  if (fileHandle) {
                    try {
                      const writable = await fileHandle.createWritable();
                      let response;
                      try {
                        response = await fetch(uri);
                      } catch (err) {
                        toast.error(
                          'Cannot export plan: failed to make request to server!'
                        );
                        return;
                      }
                      if (!response.ok) {
                        toast.error(
                          'Cannot export plan: failed to fetch png data from server!'
                        );
                        return;
                      }
                      if (!response.body) {
                        toast.error(
                          'Cannot export plan: png data from server was missing!'
                        );
                        return;
                      }

                      // More about streaming fetch responses:
                      // https://developer.chrome.com/articles/fetch-streaming-requests/
                      const reader = response.body.getReader();
                      while (true) {
                        const { value, done } = await reader.read();
                        if (done) {
                          break;
                        }
                        await writable.write(value);
                      }
                      await writable.close();

                      toast.success('Plan exported!');
                      setImageLoading(false);
                    } catch (err) {
                      console.error('Error exporting plan:', err);
                      toast.error('Error exporting plan!');
                    }
                    return;
                  }

                  // If the file system access api isn't available, then download the file like
                  // normal
                  const link = document.createElement('a');

                  if (typeof link.download === 'string') {
                    link.href = uri;
                    link.download = fileNameWithExtension;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } else {
                    window.open(uri);
                  }
                }}
                disabled={imageLoading}
              >
                {imageLoading ? 'Loading...' : 'Export Image & Entities'}
              </Button>
            </HorizontalForm>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExportPanel;
