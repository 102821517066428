import { FloorplanCoordinates } from 'lib/geometry';

export class Meters {
  static fromCentimeters = (centimeters: number) => centimeters * 1e-2;
  static fromMillimeters = (millimeters: number) => millimeters * 1e-3;
  static fromFeet = (feet: number) => feet * 0.3048;
  static fromInches = (inches: number) => inches * 0.0254;
  static fromFeetAndInches = (feet: number, inches: number) =>
    Meters.fromFeet(feet) + Meters.fromInches(inches);

  static toCentimeters = (meters: number) => meters * 1e2;
  static toMillimeters = (meters: number) => meters * 1e3;
  static toFeet = (meters: number) => meters * (1 / 0.3048);
  static toInches = (meters: number) => meters * (1 / 0.0254);
  static toFeetAndInches = (meters: number) => {
    const totalInches = Math.round(Meters.toInches(meters));
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;
    return [feet, inches] as const;
  };

  // Area conversions. Note that input is assumed to be in meters squared...
  static toSquareCentimeters = (meters: number) => meters * 1e4;
  static toSquareMillimeters = (meters: number) => meters * 1e6;
  static toSquareInches = (meters: number) => meters * 1550.0031;
  static toSquareFeet = (meters: number) => meters * 10.7639104;
  static toSquareFeetAndInches = (meters: number) => {
    const totalInches = Math.round(Meters.toSquareInches(meters));
    const feet = Math.floor((totalInches / 12) * 12);
    const inches = (totalInches % 12) * 12;
    return [feet, inches] as const;
  };

  static fromSquareCentimeters = (centimeters: number) => centimeters * 1e-4;
  static fromSquareMillimeters = (millimeters: number) => millimeters * 1e-6;
  static fromSquareInches = (squareInches: number) => squareInches / 1550.0031;
  static fromSquareFeet = (squareFeet: number) => squareFeet / 10.7639104;
  static fromSquareFeetAndInches = (feet: number, inches: number) =>
    Meters.fromSquareFeet(feet) + Meters.fromSquareInches(inches);
}

export const CONVERT_TO_METERS: {
  [key in LengthUnit as string]: (n: number) => number;
} = {
  feet_and_inches: Meters.fromFeet,
  inches: Meters.fromInches,
  meters: (n: number) => n,
  centimeters: Meters.fromCentimeters,
  millimeters: Meters.fromMillimeters,
};

export const CONVERT_FROM_METERS: {
  [key in LengthUnit as string]: (n: number) => number;
} = {
  feet_and_inches: Meters.toFeet,
  inches: Meters.toInches,
  meters: (n: number) => n,
  centimeters: Meters.toCentimeters,
  millimeters: Meters.toMillimeters,
};

export class Bytes {
  static fromKilobytes = (kilobytes: number) => kilobytes * 1e3;
  static fromMegabytes = (megabytes: number) => megabytes * 1e6;
  static fromGigabytes = (gigabytes: number) => gigabytes * 1e9;
  static fromTerabytes = (terabytes: number) => terabytes * 1e12;

  static toKilobytes = (bytes: number) => bytes * 1e-3;
  static toMegabytes = (bytes: number) => bytes * 1e-6;
  static toGigabytes = (bytes: number) => bytes * 1e-9;
  static toTerabytes = (bytes: number) => bytes * 1e-12;
}

export class Seconds {
  static fromMicroseconds = (microseconds: number) => microseconds * 1e-6;
  static fromMilliseconds = (milliseconds: number) => milliseconds * 1e-3;
  static toMilliseconds = (seconds: number) => seconds * 1e3;
}

export type LengthUnit =
  | 'inches'
  | 'feet_and_inches'
  | 'centimeters'
  | 'millimeters'
  | 'meters';

export const LengthUnit = {
  fromDXFUnit(dxfUnit: number): LengthUnit | null {
    switch (dxfUnit) {
      // ref: https://github.com/mozman/ezdxf/blob/master/src/ezdxf/units.py#L21-L29
      case 1:
        return 'inches' as const;
      case 2:
        return 'feet_and_inches' as const;
      case 4:
        return 'millimeters' as const;
      case 5:
        return 'centimeters' as const;
      case 6:
        return 'meters' as const;
      default:
        return null;
    }
  },
};

export const LengthUnitChoices: Array<LengthUnit> = [
  'inches',
  'feet_and_inches',
  'centimeters',
  'millimeters',
  'meters',
];

export function displayLength(meters: number, displayUnit: LengthUnit) {
  switch (displayUnit) {
    case 'feet_and_inches':
      const [feet, inches] = Meters.toFeetAndInches(meters);
      return `${feet}' ${inches}"`;
    case 'inches':
      return `${Meters.toInches(meters).toFixed(1)}"`;
    case 'centimeters':
      return `${Meters.toCentimeters(meters).toFixed(1)} cm`;
    case 'millimeters':
      return `${Meters.toMillimeters(meters).toFixed(1)} mm`;
    case 'meters':
      return `${meters.toFixed(3)} m`;
  }
}

function circularArea(radius: number) {
  return ellipticalArea(radius, radius);
}

function ellipticalArea(major: number, minor: number) {
  return Math.PI * major * minor;
}

export function displayCircularArea(
  radiusMeters: number,
  displayUnit: LengthUnit
) {
  switch (displayUnit) {
    case 'feet_and_inches':
      return `${circularArea(Meters.toFeet(radiusMeters)).toFixed(2)} sq. ft`;
    case 'inches':
      return `${circularArea(Meters.toInches(radiusMeters)).toFixed(1)} sq. in`;
    case 'centimeters':
      return `${circularArea(Meters.toCentimeters(radiusMeters)).toFixed(
        1
      )} sq. cm`;
    case 'millimeters':
      return `${circularArea(Meters.toMillimeters(radiusMeters)).toFixed(
        1
      )} sq. mm`;
    case 'meters':
      return `${circularArea(radiusMeters).toFixed(2)} sq. m`;
  }
}

export function displayEllipticalArea(
  majorMeters: number,
  minorMeters: number,
  displayUnit: LengthUnit
) {
  switch (displayUnit) {
    case 'feet_and_inches':
      return `${ellipticalArea(
        Meters.toFeet(majorMeters),
        Meters.toFeet(minorMeters)
      ).toFixed(2)} sq. ft`;
    case 'inches':
      return `${ellipticalArea(
        Meters.toInches(majorMeters),
        Meters.toInches(minorMeters)
      ).toFixed(1)} sq. in`;
    case 'centimeters':
      return `${ellipticalArea(
        Meters.toCentimeters(majorMeters),
        Meters.toCentimeters(minorMeters)
      ).toFixed(1)} sq. cm`;
    case 'millimeters':
      return `${ellipticalArea(
        Meters.toMillimeters(majorMeters),
        Meters.toMillimeters(minorMeters)
      ).toFixed(1)} sq. mm`;
    case 'meters':
      return `${ellipticalArea(majorMeters, minorMeters).toFixed(2)} sq. m`;
  }
}

export function displayBoxArea(
  widthMeters: number,
  heightMeters: number,
  displayUnit: LengthUnit
) {
  switch (displayUnit) {
    case 'feet_and_inches':
      const widthFeet = Meters.toFeet(widthMeters);
      const heightFeet = Meters.toFeet(heightMeters);
      return `${(widthFeet * heightFeet).toFixed(2)} sq. ft`;
    case 'inches':
      const widthInches = Meters.toInches(widthMeters);
      const heightInches = Meters.toInches(heightMeters);
      return `${(widthInches * heightInches).toFixed(2)} sq. in`;
    case 'centimeters':
      const widthCentimeters = Meters.toCentimeters(widthMeters);
      const heightCentimeters = Meters.toCentimeters(heightMeters);
      return `${(widthCentimeters * heightCentimeters).toFixed(2)} sq. cm`;
    case 'millimeters':
      const widthMillimeters = Meters.toMillimeters(widthMeters);
      const heightMillimeters = Meters.toMillimeters(heightMeters);
      return `${(widthMillimeters * heightMillimeters).toFixed(2)} sq. mm`;
    case 'meters':
      return `${(widthMeters * heightMeters).toFixed(2)} sq. m`;
  }
}

// From https://stackoverflow.com/a/33670691/4115328
export function polygonalArea(
  vertices: Array<{ x: number; y: number }>
): number {
  var total = 0;

  for (var i = 0, l = vertices.length; i < l; i++) {
    var addX = vertices[i].x;
    var addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
    var subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
    var subY = vertices[i].y;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
}

export function displayArea(
  areaMeters: number,
  displayUnit: LengthUnit
): string {
  let area = -1;
  switch (displayUnit) {
    case 'feet_and_inches':
      area = Meters.toSquareFeet(areaMeters);
      return `${area.toFixed(0)} sq. ft`;
    case 'inches':
      area = Meters.toSquareInches(areaMeters);
      return `${area.toFixed(0)} sq. in`;
    case 'centimeters':
      area = Meters.toSquareCentimeters(areaMeters);
      return `${area.toFixed(0)} sq. cm`;
    case 'millimeters':
      area = Meters.toSquareMillimeters(areaMeters);
      return `${area.toFixed(0)} sq. mm`;
    case 'meters':
      area = areaMeters;
      return `${area.toFixed(0)} sq. m`;
  }
}

export function displayPolygonalArea(
  vertices: Array<FloorplanCoordinates>,
  displayUnit: LengthUnit
) {
  switch (displayUnit) {
    case 'feet_and_inches':
      const verticesFeet: Array<{ x: number; y: number }> = vertices.map(
        (v) => ({ x: Meters.toFeet(v.x), y: Meters.toFeet(v.y) })
      );
      return `${polygonalArea(verticesFeet).toFixed(2)} sq. ft`;
    case 'inches':
      const verticesInches: Array<{ x: number; y: number }> = vertices.map(
        (v) => ({ x: Meters.toInches(v.x), y: Meters.toInches(v.y) })
      );
      return `${polygonalArea(verticesInches).toFixed(2)} sq. in`;
    case 'centimeters':
      const verticesCentimeters: Array<{ x: number; y: number }> = vertices.map(
        (v) => ({ x: Meters.toCentimeters(v.x), y: Meters.toCentimeters(v.y) })
      );
      return `${polygonalArea(verticesCentimeters).toFixed(2)} sq. cm`;
    case 'millimeters':
      const verticesMillimeters: Array<{ x: number; y: number }> = vertices.map(
        (v) => ({ x: Meters.toMillimeters(v.x), y: Meters.toMillimeters(v.y) })
      );
      return `${polygonalArea(verticesMillimeters).toFixed(2)} sq. mm`;
    case 'meters':
      return `${polygonalArea(vertices).toFixed(2)} sq. m`;
  }
}
