import {
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
  FunctionComponent,
} from 'react';
import debounce from 'lodash.debounce';

import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
  MetricLabel,
} from 'components/floorplan';
import ReferenceRulersLayer from './reference-rulers-layer';

import { useParseGeoTiff } from 'components/height-map-editor';
import {
  FloorplanCoordinates,
  ViewportCoordinates,
  snapToAngle,
} from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import { ParsedGeoTiff, GEOTIFF_NO_DATA } from 'lib/geotiff';
import { ReferenceRuler } from 'lib/reference';
import { displayLength, LengthUnit } from 'lib/units';
import PlanSensor from 'lib/sensor';
import HeightMap from 'lib/heightmap';

import { Gray900, Red400 } from '@density/dust/dist/tokens/dust.tokens';

async function queryHeightAtPosition(
  position: FloorplanCoordinates,
  heightMap: HeightMap,
  geotiffParseResults: ParsedGeoTiff,
  abortController: AbortController
): Promise<number | null> {
  const heightMapPosition = FloorplanCoordinates.toHeightMapCoordinates(
    position,
    heightMap,
    geotiffParseResults.scale
  );

  const heightMeters = await geotiffParseResults.getHeightAtPoint(
    Math.round(heightMapPosition.x),
    Math.round(heightMapPosition.y),
    abortController.signal
  );

  // If the user clicks outside the geotiff, disregard that point
  if (heightMeters === GEOTIFF_NO_DATA) {
    return null;
  }

  return heightMeters;
}

const ObjectMeasureLayer: FunctionComponent<{
  focusedObject: {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer' | 'threshold';
    id: string;
  } | null;
  sensors: FloorplanCollection<PlanSensor>;
  heightMap: HeightMap | null;
  displayUnit: LengthUnit;
}> = ({ focusedObject, sensors, heightMap, displayUnit }) => {
  const context = useFloorplanLayerContext();
  const geotiffParseResults = useParseGeoTiff(heightMap ? heightMap.url : null);

  const [enabled, setEnabled] = useState<boolean>(false);

  const [startPosition, setStartPosition] =
    useState<FloorplanCoordinates | null>(null);
  const [mousePosition, setMousePosition] =
    useState<FloorplanCoordinates | null>(null);

  const startPositionAbortController = useRef<AbortController | null>(null);
  const mousePositionAbortController = useRef<AbortController | null>(null);

  const focusedObjectPosition = useMemo(() => {
    if (!focusedObject) {
      return null;
    }

    switch (focusedObject.type) {
      case 'sensor': {
        const focusedSensor = sensors.items.get(focusedObject.id);
        if (!focusedSensor) {
          return null;
        }
        return focusedSensor.position;
      }
      case 'space': {
        return null;
      }
    }
  }, [focusedObject, sensors]);

  const onCalculateHeightAtMouse = useMemo(() => {
    return debounce((mousePosition) => {
      if (geotiffParseResults.status !== 'complete' || !heightMap) {
        return;
      }

      // If there's a request already in progress, abort it!
      if (mousePositionAbortController.current) {
        mousePositionAbortController.current.abort();
      }
      mousePositionAbortController.current = new AbortController();

      queryHeightAtPosition(
        mousePosition,
        heightMap,
        geotiffParseResults,
        mousePositionAbortController.current
      ).then((heightMeters) => {
        mousePositionAbortController.current = null;

        const heightText =
          typeof heightMeters === 'number'
            ? displayLength(heightMeters, displayUnit)
            : 'No height';

        const mouseHeightMetricLabel = context.app.stage.getChildByName(
          'object-measure-mouse-height-metric-label'
        ) as MetricLabel | null;
        if (mouseHeightMetricLabel) {
          mouseHeightMetricLabel.setText(heightText);
        }
      });
    }, 100);
  }, [geotiffParseResults, heightMap, context.app.stage, displayUnit]);

  useEffect(() => {
    const startHeightMetricLabel = new MetricLabel('...', {
      backgroundColor: toRawHex(Red400),
    });
    startHeightMetricLabel.renderable = false;
    startHeightMetricLabel.name = 'object-measure-start-height-metric-label';
    context.app.stage.addChild(startHeightMetricLabel);

    const mouseHeightMetricLabel = new MetricLabel('...', {
      backgroundColor: toRawHex(Red400),
    });
    mouseHeightMetricLabel.renderable = false;
    mouseHeightMetricLabel.name = 'object-measure-mouse-height-metric-label';
    context.app.stage.addChild(mouseHeightMetricLabel);

    return () => {
      context.app.stage.removeChild(startHeightMetricLabel);
      context.app.stage.removeChild(mouseHeightMetricLabel);
    };
  }, [context.app.stage]);

  useEffect(() => {
    const canvasElement = context.app.view;
    const canvasBBox = canvasElement.getBoundingClientRect();

    const onMouseMoveCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      const coords = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );

      let floorplanCoords = ViewportCoordinates.toFloorplanCoordinates(
        coords,
        context.viewport.current,
        context.floorplan
      );

      if (startPosition && evt.shiftKey) {
        floorplanCoords = snapToAngle(startPosition, floorplanCoords);
      }

      setMousePosition(floorplanCoords);

      if (enabled) {
        const mouseHeightMetricLabel = context.app.stage.getChildByName(
          'object-measure-mouse-height-metric-label'
        ) as MetricLabel | null;
        if (mouseHeightMetricLabel) {
          mouseHeightMetricLabel.setText('...');
        }
        onCalculateHeightAtMouse(mousePosition);
      }
    };
    canvasElement.addEventListener('mousemove', onMouseMoveCanvas);

    const onKeyDownWindow = (evt: KeyboardEvent) => {
      if (!context.viewport.current) {
        return;
      }

      if (!evt.altKey) {
        return;
      }

      if (enabled) {
        return;
      }
      setEnabled(true);

      const startPosition = focusedObjectPosition || mousePosition;
      if (!startPosition) {
        return;
      }

      setStartPosition(startPosition);
      setMousePosition(startPosition);

      // If the geotiff has loaded, get the height at that position
      if (geotiffParseResults.status === 'complete' && heightMap) {
        startPositionAbortController.current = new AbortController();
        queryHeightAtPosition(
          startPosition,
          heightMap,
          geotiffParseResults,
          startPositionAbortController.current
        ).then((heightMeters) => {
          startPositionAbortController.current = null;

          const heightText =
            typeof heightMeters === 'number'
              ? displayLength(heightMeters, displayUnit)
              : 'No height';

          const startHeightMetricLabel = context.app.stage.getChildByName(
            'object-measure-start-height-metric-label'
          ) as MetricLabel | null;
          if (startHeightMetricLabel) {
            startHeightMetricLabel.setText(heightText);
          }
        });
      }
    };
    window.addEventListener('keydown', onKeyDownWindow);

    const onKeyUpWindow = (evt: KeyboardEvent) => {
      if (!evt.altKey) {
        setEnabled(false);
        setStartPosition(null);
        if (startPositionAbortController.current) {
          startPositionAbortController.current.abort();
        }
        if (mousePositionAbortController.current) {
          mousePositionAbortController.current.abort();
        }
      }
    };
    window.addEventListener('keyup', onKeyUpWindow);

    return () => {
      canvasElement.removeEventListener('mousemove', onMouseMoveCanvas);
      window.removeEventListener('keydown', onKeyDownWindow);
      window.removeEventListener('keyup', onKeyUpWindow);
    };
  }, [
    enabled,
    geotiffParseResults,
    focusedObjectPosition,
    startPosition,
    mousePosition,
    heightMap,
    displayUnit,
    context.app.view,
    context.app.stage,
    context.floorplan,
    context.viewport,
    onCalculateHeightAtMouse,
  ]);

  const referenceRulers = useMemo(() => {
    if (!mousePosition) {
      return null;
    }

    if (!startPosition) {
      return null;
    }

    if (startPosition === mousePosition) {
      return null;
    }

    return [
      {
        id: 'measure' as const,
        type: 'ruler' as const,
        positionA: startPosition,
        positionB: mousePosition,
        distanceLabelPosition: ReferenceRuler.calculateCenterPoint({
          positionA: startPosition,
          positionB: mousePosition,
        }),
        enabled: true,
      },
    ];
  }, [startPosition, mousePosition]);

  return (
    <Fragment>
      <Layer
        onAnimationFrame={() => {
          if (!context.viewport.current) {
            return;
          }

          const startHeightMetricLabel = context.app.stage.getChildByName(
            'object-measure-start-height-metric-label'
          ) as MetricLabel | null;
          if (!startHeightMetricLabel) {
            return;
          }

          startHeightMetricLabel.renderable = heightMap !== null && enabled;
          if (startPosition && mousePosition) {
            const startPositionViewport =
              FloorplanCoordinates.toViewportCoordinates(
                startPosition,
                context.floorplan,
                context.viewport.current
              );
            startHeightMetricLabel.x = startPositionViewport.x;
            if (mousePosition.y > startPosition.y) {
              startHeightMetricLabel.y = startPositionViewport.y - 24;
            } else {
              startHeightMetricLabel.y = startPositionViewport.y + 24;
            }
          }

          const mouseHeightMetricLabel = context.app.stage.getChildByName(
            'object-measure-mouse-height-metric-label'
          ) as MetricLabel | null;
          if (!mouseHeightMetricLabel) {
            return;
          }

          mouseHeightMetricLabel.renderable =
            enabled && heightMap !== null && mousePosition !== null;
          if (startPosition && mousePosition) {
            const mousePositionViewport =
              FloorplanCoordinates.toViewportCoordinates(
                mousePosition,
                context.floorplan,
                context.viewport.current
              );
            mouseHeightMetricLabel.x = mousePositionViewport.x;
            if (startPosition.y > mousePosition.y) {
              mouseHeightMetricLabel.y = mousePositionViewport.y - 24;
            } else {
              mouseHeightMetricLabel.y = mousePositionViewport.y + 24;
            }
          }
        }}
      />
      {enabled && referenceRulers ? (
        <ReferenceRulersLayer
          referenceRulers={referenceRulers}
          rulerColor={toRawHex(Gray900)}
          // Always show custom "measurement" label, no matter how far in or out the user zooms
          labelVisibilityZoomThreshold={0}
        />
      ) : null}
    </Fragment>
  );
};
export default ObjectMeasureLayer;
