import { Fragment, FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from '@density/dust';
import styles from './styles.module.scss';
import classnames from 'classnames';

import { CSVImportMutation, CSVImportUpdate, CSVImportError } from 'lib/csv';

import NotesBox from 'components/notes-box';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelBody, PanelActions } from 'components/panel';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';

const DiffOperationIndicator: FunctionComponent<{
  type: 'error' | 'update';
}> = ({ type }) => {
  const [Icon, className] = {
    update: [Icons.Plus, styles.addition],
    error: [Icons.Minus, styles.deletion],
  }[type];

  return (
    <div className={classnames(styles.operationTypeIndicator, className)}>
      <Icon size={14} />
    </div>
  );
};

const CSVDiffTableSuccess: FunctionComponent<{
  csvUpdates: Array<CSVImportUpdate>;
}> = ({ csvUpdates }) => {
  let counter = 0;
  return (
    <table className={styles.diffCSVTable} style={{ opacity: 1 }}>
      <thead>
        <tr>
          <th className={styles.diffHeader}></th>
          <th className={styles.diffHeader}>#</th>
          <th className={styles.diffHeader}>CAD ID</th>
          <th className={styles.diffHeader}>Serial Number</th>
        </tr>
      </thead>

      <tbody>
        {csvUpdates
          .sort((a, b) => a.cadId.localeCompare(b.cadId))
          .map((change) => {
            counter++;
            return (
              <tr
                className={classnames(styles.diffRow, {
                  [styles.addition]: true,
                })}
                data-cy={`csv-diff-row`}
                key={uuidv4()}
              >
                <td>
                  <DiffOperationIndicator type={'update'} />
                </td>
                <td>
                  <div style={{ height: 24 }}>{counter}</div>
                </td>
                <td>
                  <div style={{ height: 24 }}>{change.cadId}</div>
                </td>
                <td>
                  <div style={{ height: 24 }}>{change.serialNumber}</div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const CSVDiffTableError: FunctionComponent<{
  csvErrors: Array<CSVImportError>;
}> = ({ csvErrors }) => {
  let counter = 0;
  return (
    <table className={styles.diffCSVTable} style={{ opacity: 1 }}>
      <thead>
        <tr>
          <th className={styles.diffHeader}></th>
          <th className={styles.diffHeader}>#</th>
          <th className={styles.diffHeader}>Error</th>
          <th className={styles.diffHeader}>CAD ID</th>
          <th className={styles.diffHeader}>Serial Number</th>
          <th className={styles.diffHeader}>Error Details</th>
        </tr>
      </thead>

      <tbody>
        {csvErrors.map((change) => {
          counter++;
          return (
            <tr
              className={classnames(styles.diffRow, {
                [styles.deletion]: true,
              })}
              data-cy={`csv-diff-row`}
              key={uuidv4()}
            >
              <td>
                <DiffOperationIndicator type={'error'} />
              </td>
              <td>
                <div style={{ height: 24 }}>{counter}</div>
              </td>
              <td>
                <div style={{ height: 24 }}>{change.errorType}</div>
              </td>
              <td>
                <div>{change.cadId ? change.cadId : '---'}</div>
              </td>

              <td>
                <div style={{ height: 24 }}>
                  {change.serialNumber ? change.serialNumber : ''}
                </div>
              </td>
              <td>
                <div>{change.errorMessage}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const CSVImport: FunctionComponent<{
  onSubmit: () => void;
  onCancel: () => void;
  csvUpdates: Array<CSVImportUpdate>;
  csvErrors: Array<CSVImportError>;
  mutations: {
    forwards: Array<CSVImportMutation>;
    backwards: Array<CSVImportMutation>;
  };
}> = ({ onSubmit, onCancel, csvUpdates, csvErrors, mutations }) => {
  return (
    <Fragment>
      <DarkTheme>
        <AppBar title={'CSV Import Mapping'} />
      </DarkTheme>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div style={{ marginLeft: 'auto' }}>
            <Panel position="top-left" width="60%">
              <PanelActions
                right={
                  <HorizontalForm size="medium">
                    <Button
                      size="medium"
                      type="cleared"
                      data-cy="csv-import-cancel"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="medium"
                      data-cy="csv-import-submit"
                      onClick={() => {
                        onSubmit();
                      }}
                      disabled={csvUpdates.length === 0}
                    >
                      {csvUpdates.length === 0
                        ? 'No Changes To Apply'
                        : csvUpdates.length === 1
                        ? 'Apply 1 Change'
                        : `Apply ${csvUpdates.length} Changes`}
                    </Button>
                  </HorizontalForm>
                }
              />
              <PanelBody>
                {csvErrors.length === 1 &&
                csvErrors[0].errorType === 'malformed_file' ? (
                  <>
                    <div className={styles.diffCSVTableTitle}>
                      Error Processing CSV
                    </div>
                    <div className={styles.diffCSVTableDetail}>
                      <br />
                      The .csv you uploaded is not in correct format.
                      <br />
                      The mapping should contain only two columns - serial
                      numbers and cad ids.
                      <br />
                      <br />
                      <b>Headers are optional</b>.
                      <br />
                      <br />
                      See example .csv list of sensors below:
                    </div>
                    <div style={{ maxWidth: '500px' }}>
                      <NotesBox
                        notes={`Optional SN Header, Optional CAD ID Header
                        A0DKP991, Cafe-Sensor-101
                        A0DKP992, Cafe-Sensor-102
                        A0DKP993, Cafe-Sensor-103
                        A0DKP994, Cafe-Sensor-104
                        A0DKP995, Desk-Sensor-101
                        A0DKP996, Desk-Sensor-102
                        A0DKP997, Desk-Sensor-103
                        A0DKP998, Desk-Sensor-104
                        `}
                        disabled={true}
                        onNotesEdited={() => {}}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {csvErrors.length === 0 ? null : (
                      <>
                        <div
                          className={styles.diffCSVTableTitle}
                          style={{ marginTop: '25px' }}
                        >
                          Errors
                        </div>
                        <div className={styles.diffCSVTableDetail}>
                          NOTE: The rows below will not be imported.
                        </div>
                        <CSVDiffTableError csvErrors={csvErrors} />
                      </>
                    )}
                    {csvUpdates.length === 0 ? null : (
                      <>
                        <div
                          className={styles.diffCSVTableTitle}
                          style={{ marginTop: '25px' }}
                        >
                          Imports
                        </div>
                        <CSVDiffTableSuccess csvUpdates={csvUpdates} />
                      </>
                    )}
                  </>
                )}
              </PanelBody>
            </Panel>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40%',
              opacity: 0.2,
            }}
          >
            <Icons.OnePagerPaperDoc size={300} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CSVImport;
