import { useRef, useEffect } from 'react';
import * as PIXI from 'pixi.js';

import { FloorplanCoordinates } from 'lib/geometry';
import { BUCKET_SIZE_METERS } from 'lib/heatmap';

import { Layer, useFloorplanLayerContext } from 'components/floorplan';

type ShaderUniforms = {
  uOpacity: number;
  uMaxMilliseconds: number;
  uMaxLimit: number;
  uMinLimit: number;
};

const HeightMapLayer: React.FunctionComponent<{
  heatmapData: Uint32Array;
  maxMilliseconds: number;
  minLimit?: number;
  maxLimit?: number;
  opacity?: number;
}> = ({ heatmapData, maxMilliseconds, minLimit, maxLimit, opacity = 100 }) => {
  const context = useFloorplanLayerContext();

  const shaderUniforms = useRef<ShaderUniforms>({
    uOpacity: opacity / 100,
    uMaxMilliseconds: maxMilliseconds,
    uMaxLimit: typeof maxLimit === 'number' ? maxLimit : maxMilliseconds,
    uMinLimit: typeof minLimit === 'number' ? minLimit : 0,
  });

  // Render the heightmap in a container
  useEffect(() => {
    const container = new PIXI.Container();
    container.name = 'heatmap-layer-container';

    // Information about PIXI built in shader uniforms:
    // https://github.com/pixijs/pixijs/wiki/v5-Creating-filters
    const filter = new PIXI.Filter(
      undefined,
      `
      varying vec2 vTextureCoord;

      uniform sampler2D uSampler;
      uniform float uMaxMilliseconds;
      uniform float uMinLimit;
      uniform float uMaxLimit;
      uniform float uOpacity;

      // Define color band stop points in the gradient
      // COLOR_A = People spent very little time in this area
      // COLOR_F = People spent tons of time in this area
      const vec3 COLOR_A = vec3(0.7607843137254902, 0.8666666666666667, 0.9058823529411765);
      const vec3 COLOR_B = vec3(0.8980392156862745, 0.9450980392156862, 0.9686274509803922);
      const vec3 COLOR_C = vec3(0.9921568627450981, 1.0, 0.5137254901960784);
      const vec3 COLOR_D = vec3(0.9725490196078431, 0.7686274509803922, 0.23921568627450981);
      const vec3 COLOR_E = vec3(0.9215686274509803, 0.45098039215686275, 0.0);
      const vec3 COLOR_F = vec3(0.8470588235294118, 0.07450980392156863, 0.0392156862745098);

      const float ONE_FIFTH = 1.0 / 5.0;
      const float TWO_FIFTHS = 2.0 / 5.0;
      const float THREE_FIFTHS = 3.0 / 5.0;
      const float FOUR_FIFTHS = 4.0 / 5.0;
      const float FIVE_FIFTHS = 5.0 / 5.0;

      void main(void) {
        vec4 source = texture2D(uSampler, vTextureCoord);

        float original = source.r;

        // Ignore empty areas
        if (original == 0.0) {
          gl_FragColor = vec4(0, 0, 0, 0);
          return;
        }

        // Back-compute the milliseconds of linger time using known min and max value
        float milliseconds = original * uMaxMilliseconds;

        // Then normalize that height value within the specified limits
        float ratio = (milliseconds - uMinLimit) / (uMaxLimit - uMinLimit);
        if (ratio < 0.0) {
          ratio = 0.0;
        }
        if (ratio > 1.0) {
          ratio = 1.0;
        }

        // Mix colors according to gradient scale
        vec3 result;
        if (ratio < ONE_FIFTH) {
          result = mix(COLOR_A, COLOR_B, ratio);
        } else if (ratio < TWO_FIFTHS) {
          result = mix(COLOR_B, COLOR_C, (ratio - ONE_FIFTH) * 5.0);
        } else if (ratio < THREE_FIFTHS) {
          result = mix(COLOR_C, COLOR_D, (ratio - TWO_FIFTHS) * 5.0);
        } else if (ratio < FOUR_FIFTHS) {
          result = mix(COLOR_D, COLOR_E, (ratio - THREE_FIFTHS) * 5.0);
        } else {
          result = mix(COLOR_E, COLOR_F, (ratio - FOUR_FIFTHS) * 5.0);
        }

        gl_FragColor = vec4(result, uOpacity);
      }
      `,
      shaderUniforms.current
    );
    container.filters = [filter];

    const MAX_TEXTURE_SIZE_PX = 1024;
    const planWidthInBuckets = Math.ceil(
      context.floorplan.width / context.floorplan.scale / BUCKET_SIZE_METERS
    );
    const planHeightInBuckets = Math.ceil(
      context.floorplan.height / context.floorplan.scale / BUCKET_SIZE_METERS
    );

    const originShiftX = Math.ceil(
      context.floorplan.origin.x / context.floorplan.scale / BUCKET_SIZE_METERS
    );
    const originShiftY = Math.ceil(
      context.floorplan.origin.y / context.floorplan.scale / BUCKET_SIZE_METERS
    );

    const originShiftXMod = originShiftX % planWidthInBuckets;
    const originShiftYMod = originShiftY % planHeightInBuckets;

    for (
      let tileY = 0;
      tileY < planHeightInBuckets;
      tileY += MAX_TEXTURE_SIZE_PX
    ) {
      for (
        let tileX = 0;
        tileX < planWidthInBuckets;
        tileX += MAX_TEXTURE_SIZE_PX
      ) {
        // Calculate the actual width and height of the tile
        const tileWidth = Math.min(
          MAX_TEXTURE_SIZE_PX,
          planWidthInBuckets - tileX
        );
        const tileHeight = Math.min(
          MAX_TEXTURE_SIZE_PX,
          planHeightInBuckets - tileY
        );

        // Create texture buffer for the current tile
        const textureBuffer = new Uint8Array(4 * tileWidth * tileHeight);

        for (let y = 0; y < tileHeight; y += 1) {
          for (let x = 0; x < tileWidth; x += 1) {
            // Calculate the coordinates within the floorplan for the current pixel
            let xCoord = tileX + x - originShiftXMod;
            let yCoord = tileY + y - originShiftYMod;

            // Calculate the heatmap index for the current pixel
            const heatmapIndex = yCoord * planWidthInBuckets + xCoord;

            // Normalize the value for the current pixel to a value from 0 to 255
            const value = heatmapData[heatmapIndex];
            const normalizedValue = (value / maxMilliseconds) * 255;

            // Put the normalized value into the texture buffer
            const index = 4 * (y * tileWidth + x);
            textureBuffer[index] = normalizedValue;
            textureBuffer[index + 1] = normalizedValue;
            textureBuffer[index + 2] = normalizedValue;
            textureBuffer[index + 3] = 255;
          }
        }

        // Convert the pixel data into a pixi.js texture.
        const texture = PIXI.Texture.fromBuffer(
          textureBuffer,
          tileWidth,
          tileHeight
        );

        const tile = new PIXI.Sprite(texture);
        tile.name = `tile-${tileX}-${tileY}`;
        tile.x = tileX - originShiftX;
        tile.y = tileY - originShiftY;

        tile.width = tileWidth;
        tile.height = tileHeight;
        // NOTE: uncomment the below line for debugging, it will tint each tile a random color
        // tile.tint = Math.floor(Math.random() * 0xffffff);

        container.addChild(tile);
      }
    }

    // Add this layer right above the base image layer
    context.app.stage.addChildAt(container, 1);

    return () => {
      context.app.stage.removeChild(container);
      container.destroy(true);
    };
  }, [context.app.stage, context.floorplan, heatmapData, maxMilliseconds]);

  // When the rendering parameters change, update the shader
  useEffect(() => {
    shaderUniforms.current.uOpacity = opacity / 100;
    shaderUniforms.current.uMaxMilliseconds = maxMilliseconds;
    shaderUniforms.current.uMaxLimit =
      typeof maxLimit === 'number' ? maxLimit : maxMilliseconds;
    shaderUniforms.current.uMinLimit =
      typeof minLimit === 'number' ? minLimit : 0;
  }, [opacity, maxMilliseconds, minLimit, maxLimit]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }

        const container = context.app.stage.getChildByName(
          'heatmap-layer-container'
        ) as PIXI.Container;

        const positionViewport = FloorplanCoordinates.toViewportCoordinates(
          FloorplanCoordinates.create(0, 0),
          context.floorplan,
          context.viewport.current
        );

        container.x = positionViewport.x;
        container.y = positionViewport.y;
        container.angle = context.floorplan.rotation;
        container.scale.set(
          BUCKET_SIZE_METERS *
            context.floorplan.scale *
            context.viewport.current.zoom
        );
      }}
    />
  );
};

export default HeightMapLayer;
