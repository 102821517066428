import Mousetrap from 'mousetrap';
import { MutationOptions, State } from '../state';
import { AxiosInstance } from 'axios';
import { FloorplanV2Plan } from 'lib/api';
import { keybindActions } from './keybindActions';

/*
To add another keybind:

1. Add the keybind action ID to the KEYBIND_ID enum
  example: TOGGLE_WALLS: 0 - this maps the action of toggling walls (defined in the Keybind Class) 
           to the keybind id 0. This ID is referenced to bind the keybind to the corresponding action
2. Add the keybind to the defaultKeybindings object in the corresponding category
  This is how the keybinds get set on startup if the user does not have the binding cached in their local storage
  Fields:
    name: the name of the keybind
    id: the keybind action id (from the KEYBIND_ID enum)
    customizable: whether the keybind can be customized by the user in the keybind modal
    modifiers: the keybind modifiers that accompany the binding(ctrl, shift, alt)
    keys: the keybind key
    combo: the keybind combo (keydown, keyup)
3. Add the keybind action in the keybindActions module
4. Add the keybind mapping to the initializeCallbacks method

The registerAllKeybindings method will iterate over the keybind mapping and automatically bind the keybinds to the corresponding action

*/

const KEYBIND_ID = {
  TOGGLE_WALLS: 0,
  TOGGLE_ALL_ON: 1,
  TOGGLE_ALL_OFF: 2,
  TOGGLE_OFF_ALL_BUT_SPACES: 3,
  TOGGLE_OFF_ALL_BUT_OA: 4,
  TOGGLE_OFF_ALL_BUT_ENTRY: 5,
  LOAD_TOGGLES: 6,
  CTRL: 7,
  CTRL_UP: 8,
  CTRL_SHIFT: 9,
  SHIFT_UP: 10,
  ALT: 11,
  ALT_UP: 12,
  ESCAPE: 13,
  ADD_OA_SENSOR: 14,
  ADD_ENTRY_SENSOR: 15,
  ADD_OA_BOX_SPACE: 16,
  ADD_OA_CIRCLE_SPACE: 17,
  ADD_OA_POLYGON_SPACE: 18,
  ADD_ENTRY_BOX_SPACE: 19,
  ADD_ENTRY_POLYGON_SPACE: 20,
  DRAW_AOC: 21,
  ZOOM_TO_FIT: 22,
  DEL_BACKSPACE: 23,
  ADD_REFERENCE_RULER: 24,
  ADD_REFERENCE_HEIGHT: 25,
  FLIP_RENDER_ORDER: 26,
  UNDO: 27,
  REDO: 28,
  UPKEY: 29,
  DOWNKEY: 30,
  LEFTKEY: 31,
  RIGHTKEY: 32,
  CMD: 33,
  CMD_UP: 34,
};

enum Modifier {
  CTRL = 'ctrl',
  SHIFT = 'shift',
  ALT = 'alt',
  CMD = 'mod',
}

enum Combo {
  KEYDOWN = 'keydown',
  KEYUP = 'keyup',
}

export const defaultKeybindings = {
  planning: {
    name: 'Planning',
    bindings: [
      {
        name: 'Toggle Wall Segments',
        id: KEYBIND_ID.TOGGLE_WALLS,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['0'],
        combo: [],
      },
      {
        name: 'Toggle On All Layers',
        id: KEYBIND_ID.TOGGLE_ALL_ON,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['1'],
        combo: [],
      },
      {
        name: 'Toggle Off All Layers',
        id: KEYBIND_ID.TOGGLE_ALL_OFF,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['2'],
        combo: [],
      },
      {
        name: 'Toggle Off All But Spaces',
        id: KEYBIND_ID.TOGGLE_OFF_ALL_BUT_SPACES,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['3'],
        combo: [],
      },
      {
        name: 'Toggle Off All But OA',
        id: KEYBIND_ID.TOGGLE_OFF_ALL_BUT_OA,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['4'],
        combo: [],
      },
      {
        name: 'Toggle Off All But Entry',
        id: KEYBIND_ID.TOGGLE_OFF_ALL_BUT_ENTRY,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['5'],
        combo: [],
      },
      {
        name: 'Load Toggles',
        id: KEYBIND_ID.LOAD_TOGGLES,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['b'],
        combo: [],
      },
    ],
  },
  modifiers: {
    name: 'Modifiers',
    bindings: [
      {
        name: 'Ctrl',
        id: KEYBIND_ID.CTRL,
        customizable: false,
        modifiers: [Modifier.CTRL],
        keys: [],
        combo: [],
      },
      {
        name: 'Ctrl Up',
        id: KEYBIND_ID.CTRL_UP,
        customizable: false,
        modifiers: [Modifier.CTRL],
        keys: [],
        combo: [Combo.KEYUP],
      },
      {
        name: 'Cmd',
        id: KEYBIND_ID.CMD,
        customizable: false,
        modifiers: [Modifier.CMD],
        keys: [],
        combo: [],
      },
      {
        name: 'Cmd Up',
        id: KEYBIND_ID.CMD_UP,
        customizable: false,
        modifiers: [Modifier.CMD],
        keys: [],
        combo: [Combo.KEYUP],
      },

      {
        name: 'Ctrl + Shift',
        id: KEYBIND_ID.CTRL_SHIFT,
        customizable: false,
        modifiers: [Modifier.CTRL, Modifier.SHIFT],
        keys: [],
        combo: [],
      },
      {
        name: 'Shift Up',
        id: KEYBIND_ID.SHIFT_UP,
        customizable: false,
        modifiers: [Modifier.SHIFT],
        keys: [],
        combo: [Combo.KEYUP],
      },
      {
        name: 'Alt',
        id: KEYBIND_ID.ALT,
        customizable: false,
        modifiers: [Modifier.ALT],
        keys: [],
        combo: [],
      },
      {
        name: 'Alt Up',
        id: KEYBIND_ID.ALT_UP,
        customizable: false,
        modifiers: [Modifier.ALT],
        keys: [],
        combo: [Combo.KEYUP],
      },
      {
        name: 'Escape',
        id: KEYBIND_ID.ESCAPE,
        customizable: false,
        modifiers: [],
        keys: ['esc'],
        combo: [],
      },
    ],
  },
  sensor: {
    name: 'Sensor',
    bindings: [
      {
        name: 'Add OA Sensor',
        id: KEYBIND_ID.ADD_OA_SENSOR,
        customizable: true,
        modifiers: [],
        keys: ['1'],
        combo: [],
      },
      {
        name: 'Add Entry Sensor',
        id: KEYBIND_ID.ADD_ENTRY_SENSOR,
        customizable: true,
        modifiers: [],
        keys: ['2'],
        combo: [],
      },
    ],
  },
  space: {
    name: 'Space',
    bindings: [
      {
        name: 'Add OA Box Space',
        id: KEYBIND_ID.ADD_OA_BOX_SPACE,
        customizable: true,
        modifiers: [],
        keys: ['3'],
        combo: [],
      },
      {
        name: 'Add OA Circle Space',
        id: KEYBIND_ID.ADD_OA_CIRCLE_SPACE,
        customizable: true,
        modifiers: [],
        keys: ['4'],
        combo: [],
      },
      {
        name: 'Add OA Polygon Space',
        id: KEYBIND_ID.ADD_OA_POLYGON_SPACE,
        customizable: true,
        modifiers: [],
        keys: ['5'],
        combo: [],
      },
      {
        name: 'Add Entry Box Space',
        id: KEYBIND_ID.ADD_ENTRY_BOX_SPACE,
        customizable: true,
        modifiers: [],
        keys: ['6'],
        combo: [],
      },
      {
        name: 'Add Entry Polygon Space',
        id: KEYBIND_ID.ADD_ENTRY_POLYGON_SPACE,
        customizable: true,
        modifiers: [],
        keys: ['7'],
        combo: [],
      },
    ],
  },
  aoc: {
    name: 'Area of Coverage',
    bindings: [
      {
        name: 'Draw AOC',
        id: KEYBIND_ID.DRAW_AOC,
        customizable: true,
        modifiers: [],
        keys: ['8'],
        combo: [],
      },
    ],
  },
  reference: {
    name: 'Reference',
    bindings: [
      {
        name: 'Add Reference Ruler',
        id: KEYBIND_ID.ADD_REFERENCE_RULER,
        customizable: true,
        modifiers: [],
        keys: ['r'],
        combo: [],
      },
      {
        name: 'Add Reference Height',
        id: KEYBIND_ID.ADD_REFERENCE_HEIGHT,
        customizable: true,
        modifiers: [],
        keys: ['h'],
        combo: [],
      },
    ],
  },
  control: {
    name: 'Control',
    bindings: [
      {
        name: 'Zoom to Fit',
        id: KEYBIND_ID.ZOOM_TO_FIT,
        customizable: true,
        modifiers: [Modifier.SHIFT],
        keys: ['space'],
        combo: [],
      },
      {
        name: 'Delete/Backspace',
        id: KEYBIND_ID.DEL_BACKSPACE,
        customizable: false,
        modifiers: [],
        keys: ['del', 'backspace'],
        combo: [],
      },
      {
        name: 'Flip Render Order',
        id: KEYBIND_ID.FLIP_RENDER_ORDER,
        customizable: true,
        modifiers: [],
        keys: ['x'],
        combo: [],
      },
      {
        name: 'Undo',
        id: KEYBIND_ID.UNDO,
        customizable: false,
        modifiers: [Modifier.CTRL],
        altModifiers: [Modifier.CMD],
        keys: ['z'],
        combo: [],
      },
      {
        name: 'Redo',
        id: KEYBIND_ID.REDO,
        customizable: false,
        modifiers: [Modifier.CTRL, Modifier.SHIFT],
        altModifiers: [Modifier.CMD, Modifier.SHIFT],
        keys: ['z'],
        combo: [],
      },
      {
        name: 'Up Key',
        id: KEYBIND_ID.UPKEY,
        customizable: false,
        modifiers: [],
        keys: ['up'],
        combo: [Combo.KEYDOWN],
      },
      {
        name: 'Down Key',
        id: KEYBIND_ID.DOWNKEY,
        customizable: false,
        modifiers: [],
        keys: ['down'],
        combo: [Combo.KEYDOWN],
      },
      {
        name: 'Left Key',
        id: KEYBIND_ID.LEFTKEY,
        customizable: false,
        modifiers: [],
        keys: ['left'],
        combo: [Combo.KEYDOWN],
      },
      {
        name: 'Right Key',
        id: KEYBIND_ID.RIGHTKEY,
        customizable: false,
        modifiers: [],
        keys: ['right'],
        combo: [Combo.KEYDOWN],
      },
    ],
  },
};
interface KeyBindings {
  [key: string]: {
    name: string;
    bindings: Binding[];
  };
}

type Binding = {
  name: string;
  id: number;
  customizable: boolean;
  modifiers: string[];
  altModifiers?: string[];
  keys: string[];
  combo: string[];
};

export type KeybindConfig = {
  dispatch: (action: any) => void;
  state: State;
  mutation: (options: MutationOptions) => Promise<void>;
  client: AxiosInstance;
  plan: FloorplanV2Plan;
  onZoomToFitClick: () => void;
  onUndoClick: () => void;
  onRedoClick: () => void;
};

// KeybindManager class that handles keybinds, currently instantiated in the Editor component, but may have other use cases in the future
export class KeybindManager {
  keybindings: KeyBindings;
  keybindCallbacks: Map<number, (e: Mousetrap.ExtendedKeyboardEvent) => void>;
  mousetrap: Mousetrap.MousetrapInstance;
  actions: Record<string, Function>;

  constructor(config: KeybindConfig) {
    this.actions = keybindActions(config);

    this.mousetrap = new Mousetrap();

    this.keybindings = getKeybindings();
    this.keybindCallbacks = this.initializeCallbacks();
    this.registerAllKeybindings();
  }

  private registerAllKeybindings() {
    for (const category of Object.keys(this.keybindings)) {
      for (const binding of this.keybindings[category].bindings) {
        // validate action is defined
        if (!this.getAction(binding.id)) {
          console.error(
            `Keybinding action not defined for id: ${binding.id} in category: ${category}`
          );
          continue;
        }

        const createBind = (
          mods: Binding['modifiers'] | Binding['altModifiers'],
          keys: Binding['keys'] = binding.keys
        ) => {
          if (!mods || mods.length === 0) return keys;
          if (!keys || keys.length === 0) return [mods.join('+')];
          const allModifiers = this.getPermutations(mods);

          return allModifiers
            .map((mod) => {
              return keys.map((key) => mod.join('+') + '+' + key);
            })
            .flat();
        };

        let bind = createBind(binding.modifiers);
        if (binding.altModifiers) {
          bind = bind.concat(createBind(binding.altModifiers));
        }
        this.mousetrap.bind(
          bind,
          this.getAction(binding.id)!,
          ...binding.combo
        );
      }
    }
  }

  public resetKeybindings() {
    this.mousetrap.reset();
  }

  private getAction(id: number) {
    return this.keybindCallbacks.get(id);
  }

  private initializeCallbacks() {
    return new Map([
      [KEYBIND_ID.TOGGLE_WALLS, () => this.actions.toggleWalls()],
      [KEYBIND_ID.TOGGLE_ALL_ON, () => this.actions.toggleAllOn()],
      [KEYBIND_ID.TOGGLE_ALL_OFF, () => this.actions.toggleAllOff()],
      [
        KEYBIND_ID.TOGGLE_OFF_ALL_BUT_SPACES,
        () => this.actions.toggleOffAllButSpaces(),
      ],
      [
        KEYBIND_ID.TOGGLE_OFF_ALL_BUT_OA,
        () => this.actions.toggleOffAllButOA(),
      ],
      [
        KEYBIND_ID.TOGGLE_OFF_ALL_BUT_ENTRY,
        () => this.actions.toggleOffAllButEntry(),
      ],
      [KEYBIND_ID.LOAD_TOGGLES, () => this.actions.loadToggles()],
      [KEYBIND_ID.CTRL, () => this.actions.ctrl()],
      [KEYBIND_ID.CTRL_UP, () => this.actions.ctrlUp()],
      [KEYBIND_ID.CMD, () => this.actions.cmd()],
      [KEYBIND_ID.CMD_UP, () => this.actions.cmdUp()],
      [KEYBIND_ID.CTRL_SHIFT, () => this.actions.ctrlShift()],
      [KEYBIND_ID.SHIFT_UP, () => this.actions.ctrlShiftUp()],
      [KEYBIND_ID.ALT, () => this.actions.alt()],
      [KEYBIND_ID.ALT_UP, () => this.actions.altUp()],
      [KEYBIND_ID.ESCAPE, () => this.actions.escape()],
      [KEYBIND_ID.ADD_OA_SENSOR, () => this.actions.addOASensor()],
      [KEYBIND_ID.ADD_ENTRY_SENSOR, () => this.actions.addEntrySensor()],
      [KEYBIND_ID.ADD_OA_BOX_SPACE, () => this.actions.addOABoxSpace()],
      [KEYBIND_ID.ADD_OA_CIRCLE_SPACE, () => this.actions.addOACircleSpace()],
      [KEYBIND_ID.ADD_OA_POLYGON_SPACE, () => this.actions.addOAPolygonSpace()],
      [KEYBIND_ID.ADD_ENTRY_BOX_SPACE, () => this.actions.addEntryBoxSpace()],
      [
        KEYBIND_ID.ADD_ENTRY_POLYGON_SPACE,
        () => this.actions.addEntryPolygonSpace(),
      ],
      [KEYBIND_ID.DRAW_AOC, () => this.actions.drawAOC()],
      [KEYBIND_ID.ZOOM_TO_FIT, () => this.actions.zoomToFit()],
      [KEYBIND_ID.DEL_BACKSPACE, () => this.actions.delBackspace()],
      [KEYBIND_ID.ADD_REFERENCE_RULER, () => this.actions.addReferenceRuler()],
      [
        KEYBIND_ID.ADD_REFERENCE_HEIGHT,
        () => this.actions.addReferenceHeight(),
      ],
      [KEYBIND_ID.FLIP_RENDER_ORDER, () => this.actions.flipRenderOrder()],
      [
        KEYBIND_ID.UNDO,
        (e: Mousetrap.ExtendedKeyboardEvent) => this.actions.undo(e),
      ],
      [
        KEYBIND_ID.REDO,
        (e: Mousetrap.ExtendedKeyboardEvent) => this.actions.redo(e),
      ],
      [KEYBIND_ID.UPKEY, () => this.actions.upKey()],
      [KEYBIND_ID.DOWNKEY, () => this.actions.downKey()],
      [KEYBIND_ID.LEFTKEY, () => this.actions.leftKey()],
      [KEYBIND_ID.RIGHTKEY, () => this.actions.rightKey()],
    ]);
  }

  // getting all permeations of the modifiers
  private getPermutations(array: string[]) {
    const result: string[][] = [];
    const permute = (arr: string[], m: string[] = []) => {
      if (arr.length === 0 && m.length > 0) {
        result.push(m);
      } else {
        for (let i = 0; i < arr.length; i++) {
          const curr: string[] = arr.slice();
          const next: string[] = curr.splice(i, 1);
          permute(curr.slice(), m.concat(next));
        }
      }
    };
    permute(array);
    return result;
  }
}

export function getKeybindings(
  loadedKeybindingsString = localStorage.getItem('keybindings') || ''
) {
  // local storage set operations fail some cypress tests, so skip if window.Cypress is defined
  if ((window as any).Cypress) {
    return defaultKeybindings as KeyBindings;
  }

  let loadedKeybindings;
  try {
    loadedKeybindings = loadedKeybindingsString
      ? JSON.parse(loadedKeybindingsString)
      : null;
  } catch (e) {
    console.error('Error parsing keybindings from localStorage:', e);
    loadedKeybindings = null;
  }

  if (!loadedKeybindings) {
    try {
      localStorage.setItem('keybindings', JSON.stringify(defaultKeybindings));
    } catch (e) {
      console.error('Error setting keybindings to localStorage:', e);
    }
    return defaultKeybindings as KeyBindings;
  }

  // Merge the loaded keybindings with the defaultKeybindings to ensure all defaults are present
  const mergedKeybindings: KeyBindings = { ...defaultKeybindings };
  for (const category of Object.keys(mergedKeybindings)) {
    if (loadedKeybindings[category]) {
      // For each binding in the default keybindings, check if it exists in the loaded keybindings
      for (const defaultBinding of mergedKeybindings[category].bindings) {
        const existingBindingIndex = loadedKeybindings[
          category
        ].bindings.findIndex((b: Binding) => b.name === defaultBinding.name);
        if (existingBindingIndex === -1) {
          // If the default binding does not exist in the loaded keybindings, add it
          loadedKeybindings[category].bindings.unshift(defaultBinding);
        }
      }
    } else {
      // If the category does not exist in the loaded keybindings, use the defaults for that category
      loadedKeybindings[category] = mergedKeybindings[category];
    }
  }

  // update all the bindings that are not customizable to be the default keybindings - this avoids having to version control for these changes
  for (const category of Object.keys(mergedKeybindings)) {
    for (const defaultBinding of mergedKeybindings[category].bindings) {
      if (!defaultBinding.customizable) {
        const existingBindingIndex = loadedKeybindings[
          category
        ].bindings.findIndex((b: Binding) => b.name === defaultBinding.name);
        if (existingBindingIndex !== -1) {
          loadedKeybindings[category].bindings[existingBindingIndex] =
            defaultBinding;
        }
      }
    }
  }

  // Save the merged keybindings back to localStorage in case there were updates
  try {
    localStorage.setItem('keybindings', JSON.stringify(loadedKeybindings));
  } catch (e) {
    console.error('Error setting keybindings to localStorage:', e);
  }

  return loadedKeybindings as KeyBindings;
}
