import {
  AutofillSuggestion,
  MapboxAutofill,
  SessionTokenLike,
} from '@mapbox/search-js-core';
import AsyncSelect from 'react-select/async';
import { useEffect, useMemo, useRef } from 'react';
import {
  GroupBase,
  SelectInstance,
  StylesConfig,
  components,
} from 'react-select';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { MAPBOX_TOKEN } from 'config';
import { useEvent } from 'hooks/use-event';

// Types
type SingleOption<T = undefined> = {
  label: string;
  value: string;
  icon?: React.ReactNode;
  metadata?: T;
  role?: 'destroy';
};

type AddressInputSelectProps = {
  value?: string;
  name: string;
  theme: 'dark' | 'light';
  placeholder: string;
  sessionToken: SessionTokenLike;
  dataCy?: string;
  onExit: () => void;
  onChange?: (v: MapboxData) => void;
};

type AddressOption = SingleOption<AutofillSuggestion>;

type MapboxData = {
  longitude: number;
  latitude: number;
  timeZone: string;
  address: string;
};

const NoOptionsMessage = () => {
  return (
    <div
      style={{ color: dust.Gray500, display: 'flex', justifyContent: 'center' }}
    >
      <p>No results</p>
    </div>
  );
};

const LoadingMessage = () => {
  return <>Loading...</>;
};

const SingleValue = (props: any) => {
  return (
    <components.SingleValue
      {...props}
      innerProps={{ 'data-cy': 'building-address-value' }}
    >
      {props.children}
    </components.SingleValue>
  );
};

const TestableInput = (props: any) => {
  return (
    <components.Input
      {...props}
      data-cy="building-address-editable-text-field"
    />
  );
};

const IconOption = (props: any) => {
  return (
    <components.Option {...props}>
      {props.data.icon ? (
        <div style={{ fontSize: '8px', color: 'yellow', marginRight: '3px' }}>
          {props.data.icon}
        </div>
      ) : null}

      <p
        style={{ fontSize: '12px', margin: 0, padding: 0 }}
        data-cy="building-address-input-select-option"
      >
        {props.data.label}
      </p>
    </components.Option>
  );
};

const getMapboxData = async (
  autofill: MapboxAutofill,
  suggestion: AutofillSuggestion,
  sessionToken: SessionTokenLike
) => {
  const retrieved = await autofill.retrieve(suggestion, { sessionToken });

  const [longitude, latitude] = retrieved.features[0].geometry.coordinates;

  // use the tileset api to get timezone
  // ref https://docs.mapbox.com/help/tutorials/create-a-timezone-finder-with-mapbox-tilequery-api/
  const tileRes = await fetch(
    `https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`,
    {
      method: 'GET',
    }
  );
  if (!tileRes.ok) {
    const text = await tileRes.text();
    throw new Error(
      `Error fetching mapbox tilequery api with 'longitude:${longitude} latitude:${latitude}': ${text}. Status: ${tileRes.status}`
    );
  }

  // const image = await fetch(
  //   `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s+ff0000(${longitude},${latitude})/${longitude},${latitude},10.00,0/300x200?access_token=${MAPBOX_TOKEN}&logo=false`
  // );

  const tileJson = await tileRes.json();

  const timeZone = tileJson.features[0].properties.TZID;

  const d: MapboxData = {
    timeZone,
    longitude,
    latitude,
    address: suggestion.full_address || '',
  };

  return d;
};

function useStyles<Option = unknown, IsMulti extends boolean = boolean>(
  theme = 'dark',
  overrides?: StylesConfig<Option, IsMulti>
) {
  const isDark = theme === 'dark';
  const colors = {
    color: isDark ? dust.White : dust.Gray900,
    placeholder: isDark ? dust.Gray400 : dust.Gray300,
    border: isDark ? dust.Gray700 : dust.Gray200,
    background: isDark ? dust.Gray700 : dust.White,
  };
  return useMemo(() => {
    const styles: StylesConfig<Option, IsMulti> = {
      control: (base, state) => ({
        ...base,
        border: 'transparent',
        borderRadius: '6px',
        background: 'transparent',
        color: colors.color,
      }),
      input: (base, state) => ({
        ...base,
        border: `1px solid ${colors.border}`,
        borderRadius: '6px',
        fontWeight: 'lighter',
        lineHeight: '2px',
        padding: 7,
        paddingLeft: 9,
        background: 'transparent',
        color: colors.color,
        ...overrides?.input?.(base, state),
      }),
      valueContainer: (base, state) => ({
        ...base,
        padding: 0,
        minWidth: '463px',
        color: colors.color,
        background: colors.background,
        // ...overrides?.valueContainer?.(base, state),
      }),
      singleValue: (base, state) => ({
        ...base,
        padding: 4,
        paddingLeft: 11,
        fontWeight: 'lighter',
        color: colors.color,
        ...overrides?.singleValue?.(base, state),
      }),
      groupHeading: (base, state) => ({
        ...base,
        fontSize: '14px',
        background: dust.Gray400,
        color: colors.color,
        fontWeight: 'bold',
        ...overrides?.groupHeading?.(base, state),
      }),
      placeholder: (base, state) => ({
        ...base,
        padding: 8,
        paddingLeft: 11,
        fontSize: '14px',
        fontWeight: 'lighter',
        color: colors.placeholder,
        ...overrides?.placeholder?.(base, state),
      }),
      option: (base, state) => ({
        ...base,
        display: 'flex',
        color: colors.color,
        background: colors.background,
        alignItems: 'center',
        minHeight: '32px',
        ':active': {
          background: colors.background,
          color: colors.color,
        },
        ':hover': {
          background: colors.background,
          color: colors.color,
        },
        ...overrides?.option?.(base, state),
      }),
      menu: (base, state) => ({
        ...base,
        border: `1px solid ${dust.Gray200}`,
        borderRadius: '6px',
        color: colors.color,
        background: colors.background,
        ...overrides?.menu?.(base, state),
      }),
      menuList: (base, state) => ({
        ...base,
        padding: 0,
        color: colors.color,
        ...overrides?.menuList?.(base, state),
      }),
    };

    return styles;
  }, [
    overrides,
    colors.background,
    colors.border,
    colors.color,
    colors.placeholder,
  ]);
}

const AddressInputSelect: React.FC<AddressInputSelectProps> = ({
  value,
  name,
  sessionToken,
  theme,
  onChange,
  onExit,
  placeholder,
  dataCy,
}) => {
  const styles = useStyles<AddressOption, false>(theme);

  const autofill = useRef(new MapboxAutofill({ accessToken: MAPBOX_TOKEN }));
  const ref =
    useRef<SelectInstance<AddressOption, false, GroupBase<AddressOption>>>(
      null
    );

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleChange = useEvent((v: AddressOption | null) => {
    if (!v?.metadata) {
      return;
    }

    getMapboxData(autofill.current, v.metadata, sessionToken)
      .then(onChange)
      .catch((e) => console.error(e));
  });

  return (
    <AsyncSelect<AddressOption, false>
      menuPosition="fixed"
      name={name}
      data-cy={`${dataCy}-text-field`}
      ref={ref}
      placeholder={placeholder || 'Search an address'}
      maxMenuHeight={300}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onExit && onExit();
        }
      }}
      styles={styles}
      filterOption={() => true}
      loadOptions={(inputValue, callback) => {
        autofill.current
          .suggest(inputValue, { sessionToken })
          .then((res) => {
            callback(
              res.suggestions.map((suggestion) => {
                return {
                  value: suggestion.full_address || '',
                  label: suggestion.full_address || '',
                  metadata: suggestion,
                };
              })
            );
          })
          .catch((e) => console.error(e));
      }}
      defaultValue={value ? { label: value, value: value } : undefined}
      onChange={handleChange}
      onBlur={() => {
        onExit && onExit();
      }}
      components={{
        SingleValue,
        Input: TestableInput,
        NoOptionsMessage,
        LoadingMessage,
        Option: IconOption,
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        LoadingIndicator: () => null,
      }}
    />
  );
};

export default AddressInputSelect;
