import { css } from '@emotion/react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

const ErrorMessage: React.FC = (props) => {
  return (
    <span
      css={css`
        font-size: ${dust.TextScale5};
        font-weight: ${dust.FontWeightBold};
        color: ${dust.Gray600};
      `}
    >
      {props.children}
    </span>
  );
};

export default ErrorMessage;
