import { useEffect, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { FloorplanCoordinates } from 'lib/geometry';

import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { Blue300 } from '@density/dust/dist/tokens/dust.tokens';

// We're drawing two "rulers" on two entities to get a
// sense of the scale difference by looking at the ratio.
export type ScalingState = {
  type: 'scale';
  rulerA: {
    vertexA: FloorplanCoordinates | null;
    vertexB: FloorplanCoordinates | null;
  };
  rulerB: {
    vertexA: FloorplanCoordinates | null;
    vertexB: FloorplanCoordinates | null;
  };
  nextPlacement: FloorplanCoordinates | null;
};

const RenderScalingLayer: React.FunctionComponent<{
  scalingStateRef: React.RefObject<ScalingState | null>;
}> = ({ scalingStateRef }) => {
  const context = useFloorplanLayerContext();

  // Cache the endpoint "crosshairs" texture shown at each end ruler.
  const endpointTexture = useMemo(() => {
    const RULER_ENDPOINT_CIRCLE_RADIUS_PX = 8;

    const graphic = new PIXI.Graphics();
    graphic.lineStyle({ width: 2, color: toRawHex(Blue300) });

    // Draw endpoint -
    graphic.drawCircle(0, 0, RULER_ENDPOINT_CIRCLE_RADIUS_PX);

    // Draw crosshairs in endpoint -
    graphic.moveTo(-1 * RULER_ENDPOINT_CIRCLE_RADIUS_PX, 0);
    graphic.lineTo(RULER_ENDPOINT_CIRCLE_RADIUS_PX, 0);
    graphic.moveTo(0, -1 * RULER_ENDPOINT_CIRCLE_RADIUS_PX);
    graphic.lineTo(0, RULER_ENDPOINT_CIRCLE_RADIUS_PX);

    return context.app.renderer.generateTexture(graphic);
  }, [context.app]);

  useEffect(() => {
    const rulerA = new PIXI.Graphics();
    rulerA.name = 'ruler-a';
    context.app.stage.addChild(rulerA);

    const rulerB = new PIXI.Graphics();
    rulerB.name = 'ruler-b';
    context.app.stage.addChild(rulerB);

    const endpointA1 = new PIXI.Sprite(endpointTexture);
    endpointA1.name = 'endpoint-a-1';
    endpointA1.anchor.set(0.5, 0.5);
    context.app.stage.addChild(endpointA1);

    const endpointA2 = new PIXI.Sprite(endpointTexture);
    endpointA2.name = 'endpoint-a-2';
    endpointA2.anchor.set(0.5, 0.5);
    context.app.stage.addChild(endpointA2);

    const endpointB1 = new PIXI.Sprite(endpointTexture);
    endpointB1.name = 'endpoint-b-1';
    endpointB1.anchor.set(0.5, 0.5);
    context.app.stage.addChild(endpointB1);

    const placementEndpoint = new PIXI.Sprite(endpointTexture);
    placementEndpoint.name = 'placement-endpoint';
    placementEndpoint.anchor.set(0.5, 0.5);
    context.app.stage.addChild(placementEndpoint);

    return () => {
      rulerA.renderable = false;
      rulerB.renderable = false;
      context.app.stage.removeChild(rulerA);
      context.app.stage.removeChild(rulerB);

      endpointA1.renderable = false;
      endpointA2.renderable = false;
      endpointB1.renderable = false;
      placementEndpoint.renderable = false;
      context.app.stage.removeChild(endpointA1);
      context.app.stage.removeChild(endpointA2);
      context.app.stage.removeChild(endpointB1);
      context.app.stage.removeChild(placementEndpoint);
    };
  }, [context.app, endpointTexture]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (
          !context.viewport.current ||
          !scalingStateRef.current ||
          !scalingStateRef.current.nextPlacement
        ) {
          return;
        }

        const rulerA = context.app.stage.getChildByName(
          'ruler-a'
        ) as PIXI.Graphics;
        const rulerB = context.app.stage.getChildByName(
          'ruler-b'
        ) as PIXI.Graphics;
        const viewport = context.viewport.current;
        const floorplan = context.floorplan;

        const rulers: {
          [key: string]: {
            vertexA: FloorplanCoordinates | null;
            vertexB: FloorplanCoordinates | null;
          };
        } = {
          rulerA: scalingStateRef.current.rulerA,
          rulerB: scalingStateRef.current.rulerB,
        };

        for (const rulerKey in rulers) {
          const ruler = rulers[rulerKey];
          const vertexKey = ruler.vertexA ? 'vertexB' : 'vertexA';

          if (vertexKey === 'vertexA') {
            // There is nothing to draw with one ruler point. Maybe draw a vertex?
            continue;
          }

          const positionA = FloorplanCoordinates.toViewportCoordinates(
            ruler.vertexA!,
            floorplan,
            viewport
          );

          const positionB = FloorplanCoordinates.toViewportCoordinates(
            ruler.vertexB || scalingStateRef.current.nextPlacement,
            floorplan,
            viewport
          );

          if (rulerKey === 'rulerA') {
            const endpointA1 = context.app.stage.getChildByName(
              'endpoint-a-1'
            ) as PIXI.Sprite;
            endpointA1.x = positionA.x;
            endpointA1.y = positionA.y;

            const endpointA2 = context.app.stage.getChildByName(
              'endpoint-a-2'
            ) as PIXI.Sprite;
            endpointA2.x = positionB.x;
            endpointA2.y = positionB.y;
          } else if (rulerKey === 'rulerB') {
            const endpointB1 = context.app.stage.getChildByName(
              'endpoint-b-1'
            ) as PIXI.Sprite;
            endpointB1.x = positionA.x;
            endpointB1.y = positionA.y;
          }

          const placementEndpoint = context.app.stage.getChildByName(
            'placement-endpoint'
          ) as PIXI.Sprite;
          placementEndpoint.x = positionB.x;
          placementEndpoint.y = positionB.y;

          const rulerGraphic = rulerKey === 'rulerA' ? rulerA : rulerB;
          rulerGraphic.clear();
          rulerGraphic.lineStyle({ width: 3, color: toRawHex(Blue300) });
          rulerGraphic.moveTo(positionA.x, positionA.y);
          rulerGraphic.lineTo(positionB.x, positionB.y);
        }

        rulerA.visible = true;
        rulerB.visible = true;
      }}
    />
  );
};

export default RenderScalingLayer;
