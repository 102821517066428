export function addDragListener(
  startClientX: number,
  startClientY: number,
  onMove: (dx: number, dy: number, evt: MouseEvent) => void,
  onRelease?: () => void
) {
  let lastX = startClientX;
  let lastY = startClientY;

  function onMouseMove(evt: MouseEvent) {
    const dx = evt.clientX - lastX;
    const dy = evt.clientY - lastY;
    lastX = evt.clientX;
    lastY = evt.clientY;
    onMove(dx, dy, evt);
  }

  function unregister() {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    if (onRelease) {
      onRelease();
    }
  }

  function onMouseUp() {
    unregister();
  }

  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);

  return () => {
    unregister();
  };
}
