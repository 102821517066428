import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Icons } from '@density/dust';

import Space from 'lib/space';
import Threshold from 'lib/threshold';
import { FloorplanV2ExternalSpace } from 'lib/api';

import FormLabel from 'components/form-label';
import SelectField from 'components/select-field';
import buttonStyles from 'components/button/styles.module.scss';
import Button from 'components/button';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';

import { Action } from './actions';
import styles from './styles.module.scss';

const ExternalSpaceSelectionPanel: React.FunctionComponent<{
  externalSpaces: FloorplanV2ExternalSpace[];
  selectedDoorway: Threshold;
  onSpaceSelected: ({
    spaceId,
    sensorInsideSpace,
  }: {
    spaceId: Space['id'] | null;
    sensorInsideSpace: boolean | null;
  }) => void;
  dispatch: React.Dispatch<Action>;
}> = ({ selectedDoorway, externalSpaces, onSpaceSelected, dispatch }) => {
  const [selectedSpace, setSelectedSpace] =
    useState<FloorplanV2ExternalSpace | null>(null);
  const [buildingIdFilter, setBuildingIdFilter] = useState<
    FloorplanV2ExternalSpace['id'] | null
  >(null);
  const [floorIdFilter, setFloorIdFilter] = useState<
    FloorplanV2ExternalSpace['id'] | null
  >(null);

  const [buildingList, setBuildingList] = useState<
    { id: string; label: string }[]
  >([]);
  const [floorList, setFloorList] = useState<{ id: string; label: string }[]>(
    []
  );
  const [spaceList, setSpaceList] = useState<{ id: string; label: string }[]>(
    []
  );

  const [showInfoTip, setShowInfoTip] = useState<boolean>(false);
  const [sensorInsideSpace, setSensorInsideSpace] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    // filter externalSpaces for buildings only
    const buildingOptions = externalSpaces
      .filter((space) => space.space_type === 'building')
      .map((space, idx) => {
        return {
          id: space.id,
          label: `${space.name} (${space.id})`,
        };
      });

    if (buildingOptions.length > 1) {
      buildingOptions.unshift({ id: '', label: '' });
    }

    setBuildingList(buildingOptions);
  }, [externalSpaces]);

  useEffect(() => {
    // filter externalSpaces for floors only and filter
    // for the building they're in, if applicable
    const floorOptions = externalSpaces
      .filter((space) => space.space_type === 'floor')
      .filter(
        (space) => !buildingIdFilter || space.building_id === buildingIdFilter
      )
      .map((space, idx) => {
        return {
          id: space.id,
          label: `${space.name} (${space.id})`,
        };
      });

    floorOptions.unshift({ id: '', label: '' });

    setFloorList(floorOptions);
  }, [buildingIdFilter, externalSpaces]);

  useEffect(() => {
    // filter externalSpaces for spaces only and filter
    // for the floor and/or building they're in, if applicable
    const spaceOptions = externalSpaces
      .filter(
        (space) => !buildingIdFilter || space.building_id === buildingIdFilter
      )
      .filter((space) => !floorIdFilter || space.floor_id === floorIdFilter)
      .map((space, idx) => {
        return {
          id: space.id,
          label: `${space.name}, ${space.space_type}, ${space.id}`,
        };
      });

    spaceOptions.unshift({ id: '', label: '' });
    setSpaceList(spaceOptions);
  }, [buildingIdFilter, floorIdFilter, externalSpaces]);

  return (
    <Panel position="top-left" width={510}>
      <PanelHeader>
        <label className={styles.externalSpaceLinkPanelHeader}>
          <Icons.SpaceTypeSpace />
          &nbsp;
          <Icons.ArrowRightForwardSmall />
          <Icons.DoorEntry />
          &nbsp;&nbsp;Link Space
          {selectedDoorway.name ? ` to ${selectedDoorway.name}` : null}
        </label>
      </PanelHeader>

      <div className={styles.externalSpaceLinkSection}>
        This panel facilitates linking of spaces that live outside of this
        floorplan. If you are looking to link a "local" space, use
        <button
          className={classNames([
            buttonStyles.button,
            buttonStyles.typeSmall,
            buttonStyles.typeLink,
          ])}
          style={{
            display: 'inline',
            padding: '0px 1px 0px 3px',
            margin: '2px 4px 0px 4px',
          }}
          onClick={() => {
            onSpaceSelected({ spaceId: null, sensorInsideSpace: null });
            dispatch({
              type: 'selectionMode.begin',
              expectedSelectionType: 'space',
              hideAllLayersBut: ['thresholds', 'entrySpaces'],
              initialActionCreator: selectedDoorway['id'],
              pendingAction: 'threshold.linkSpace',
            });
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Link Local Space&nbsp;&nbsp;
            <Icons.SpaceAdd size={17} />
          </div>
        </button>
        to select it in the editor instead.
      </div>
      <PanelBody>
        <label className={styles.externalSpaceLinkSectionHeader}>
          <Icons.SearchMagnifier />
          &nbsp;Optional Filters
        </label>
        <div className={styles.externalSpaceLinkSection}>
          <FormLabel
            label="Filter by Building"
            input={
              <SelectField
                value={buildingIdFilter}
                choices={buildingList}
                onChange={(selection: { id: string }) => {
                  if (!selection.id) setBuildingIdFilter(null);
                  else setBuildingIdFilter(selection.id);
                }}
                disabled={false}
                size="medium"
              />
            }
          />
          <FormLabel
            label="Filter by Floor"
            input={
              <SelectField
                value={floorIdFilter}
                choices={floorList}
                onChange={(selection: { id: string }) => {
                  if (!selection.id) setFloorIdFilter(null);
                  else setFloorIdFilter(selection.id);
                }}
                disabled={floorList.length === 0}
                size="medium"
              />
            }
          />
        </div>

        <label className={styles.externalSpaceLinkSectionHeader}>
          <Icons.SpaceTypeSpace />
          &nbsp;Target Space
        </label>
        <div className={styles.externalSpaceLinkSection}>
          <FormLabel
            label="Choose Space"
            input={
              <SelectField
                value={selectedSpace?.id}
                choices={spaceList}
                onChange={(selection: { id: string }) => {
                  if (!selection.id) {
                    setSelectedSpace(null);
                    setSensorInsideSpace(null);
                  } else {
                    const selectedSpace = externalSpaces.filter(
                      (space) => space.id === selection.id
                    );
                    if (selectedSpace.length === 1) {
                      setSelectedSpace(selectedSpace[0]);
                    }
                  }
                }}
                disabled={spaceList.length === 0}
                size="medium"
              />
            }
          />
          {selectedSpace ? (
            <>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="radio"
                  checked={sensorInsideSpace === true}
                  onChange={(evt) => {
                    setSensorInsideSpace(evt.target.checked);
                  }}
                />
                <span>
                  {' '}
                  &nbsp;&nbsp;The sensor is installed <strong>inside</strong> of
                  this space.
                </span>
              </label>

              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2px',
                }}
              >
                <input
                  type="radio"
                  checked={sensorInsideSpace === false}
                  onChange={(evt) => {
                    setSensorInsideSpace(!evt.target.checked);
                  }}
                />
                <span>
                  {' '}
                  &nbsp;&nbsp;The sensor is installed <strong>
                    outside
                  </strong>{' '}
                  of this space.
                </span>
              </label>
              <label className={classNames([styles.muted, styles.inline])}>
                No sweat! You can change this selection later in the focused
                Doorway panel.
              </label>
            </>
          ) : null}
        </div>
        {selectedSpace ? (
          <div className={styles.externalSpaceLinkSection}>
            <FormLabel
              label="Selected Space Metadata"
              input={
                <div className={styles.externalSpaceMeta}>
                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>Name:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.name}
                    </span>
                  </div>

                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>Type:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.space_type}
                    </span>
                  </div>

                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>Time Zone:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.time_zone}
                    </span>
                  </div>

                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>Status:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.status}
                    </span>
                  </div>

                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>Created:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.created_at}
                    </span>
                  </div>

                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>ID:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.id}
                    </span>
                  </div>

                  <div className={styles.externalSpaceMetaRow}>
                    <span className={styles.metaLabel}>Parent ID:</span>
                    <span className={`${styles.muted} ${styles.metaDetail}`}>
                      {selectedSpace.parent_id || 'n/a'}
                    </span>
                  </div>
                </div>
              }
            />
          </div>
        ) : (
          <div className={styles.externalSpaceLinkSection}>
            <label
              className={styles.externalSpaceLinkInfoPrompt}
              onMouseEnter={() => setShowInfoTip(true)}
              onMouseLeave={() => setShowInfoTip(false)}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <Icons.Information size={21} /> I am not seeing the Space I am
                looking for, but it 100% exists.
              </div>
            </label>
            {showInfoTip
              ? [
                  <div className={styles.externalSpaceLinkInfoTip}>
                    <span>
                      &#8226; Try looking for it without the Optional Filters.
                    </span>
                    <br />
                    <span>
                      &#8226; Confirm the space is of <strong>Entry</strong>{' '}
                      type. OA spaces <strong>can't</strong> be linked to
                      doorways.
                    </span>
                    <br />
                    <span>
                      &#8226; If your Space belongs to <strong>this</strong>{' '}
                      Floorplan, use "Link Local Space" instead.
                    </span>
                    <br />
                    <span>
                      &#8226; Are you sure you're inside the right
                      Floorplan/Organization?
                    </span>
                  </div>,
                ]
              : null}
          </div>
        )}
      </PanelBody>

      <PanelActions
        left={
          <Button
            type="cleared"
            size="medium"
            onClick={() => {
              onSpaceSelected({ spaceId: null, sensorInsideSpace: null });
            }}
          >
            Cancel
          </Button>
        }
        right={
          <Button
            type="cleared"
            size="medium"
            onClick={() =>
              onSpaceSelected({
                spaceId: selectedSpace ? selectedSpace.id : null,
                sensorInsideSpace,
              })
            }
            disabled={!selectedSpace || sensorInsideSpace === null}
            trailingIcon={<Icons.LinkLinked size={18} />}
          >
            Link Space
          </Button>
        }
      />
    </Panel>
  );
};

export default ExternalSpaceSelectionPanel;
