import { BehaviorSubject, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

export const devicePixelRatio = window.devicePixelRatio || 1;

export function makeWindowSizeSubject() {
  const subject = new BehaviorSubject({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const eventStream = fromEvent(window, 'resize').pipe(
    map((evt) => {
      const window = evt.currentTarget as Window;
      const width = window.innerWidth;
      const height = window.innerHeight;
      return {
        width,
        height,
      };
    })
  );
  eventStream.subscribe(subject);
  return subject;
}
