import { CoreUser } from '@densityco/lib-api-types';
import { createSlice } from '@reduxjs/toolkit';

import {
  getFulfilledState,
  getInitialAsyncTaskState,
  getPendingState,
  getRejectedState,
} from 'lib/redux';

import { asyncFetchUserThunk } from './async-fetch-user-thunk';

const userSlice = createSlice({
  name: 'user',
  initialState: getInitialAsyncTaskState<CoreUser>(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncFetchUserThunk.pending, (state, action) => {
      return getPendingState(state, action.meta.requestId);
    });

    builder.addCase(asyncFetchUserThunk.fulfilled, (state, action) => {
      return getFulfilledState(state, action.meta.requestId, action.payload);
    });

    builder.addCase(asyncFetchUserThunk.rejected, (state, action) => {
      return getRejectedState(state, action.meta.requestId, action.error);
    });
  },
});

export default userSlice.reducer;
