import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/store';
import Draggable, { DraggableEventHandler } from 'react-draggable';

import { EntryCountSocketMessage } from 'lib/entry-counts-live-socket';
import FloorplanCollection from 'lib/floorplan-collection';
import PlanSensor from 'lib/sensor';
import Threshold from 'lib/threshold';
import styles from './styles.module.scss';
import { Action } from '../actions';
import { Icons, Tooltip } from '@density/dust';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

// TODO: Potentially add an event log viewer with timestamps?

type SpaceRelation = {
  thresholds: { name: string; id: string }[];
  sensors: { serial: string; id: string }[];
  name: string;
  type: 'building' | 'floor' | 'space' | null;
};

type ShowEntryTable = { [key: string]: { show: boolean; popout: boolean } };

/**
 * Processes thresholds and entry sensors to create space relations.
 */
const processThresholdsAndSensors = (
  thresholdCollection: Threshold[],
  entrySensors: { [key: string]: string }
) => {
  const spaces = new Map<string, SpaceRelation>();
  const thresholdIds = new Set<string>();
  const sensorSerials = new Set<string>();
  const spaceIds = new Set<string>();

  thresholdCollection.forEach((threshold) => {
    if (threshold.relatedSpaces.length > 0) {
      threshold.relatedSpaces.forEach((space) => {
        if (!space.spaceId) return;
        spaceIds.add(space.spaceId);
        thresholdIds.add(threshold.id);
        if (!spaces.has(space.spaceId)) {
          spaces.set(space.spaceId, {
            thresholds: [{ name: threshold.name, id: threshold.id }],
            sensors: threshold.relatedPlanSensors
              .map((sensor) => {
                sensorSerials.add(entrySensors[sensor]);
                return { serial: entrySensors[sensor], id: sensor };
              })
              .filter((val) => val !== undefined),
            name: space.spaceName || 'Unnamed Space',
            type: space.spaceType as 'building' | 'floor' | 'space' | null,
          });
        } else {
          const spaceRelation = spaces.get(space.spaceId);
          if (!spaceRelation) return;
          if (
            !spaceRelation.thresholds.some(
              (t) => t.id === threshold.id && t.name === threshold.name
            )
          ) {
            spaceRelation.thresholds.push({
              name: threshold.name,
              id: threshold.id,
            });
          }
          threshold.relatedPlanSensors.forEach((sensor) => {
            if (
              !spaceRelation.sensors.some(
                (relatedSensor) => relatedSensor.id === sensor
              ) &&
              entrySensors[sensor]
            ) {
              spaceRelation.sensors.push({
                serial: entrySensors[sensor],
                id: sensor,
              });
            }
          });
        }
      });
    }
  });
  return { spaces, thresholdIds, sensorSerials, spaceIds };
};

type EventCounts = {
  [key: string]: {
    ingress: number;
    egress: number;
    total: number;
    net: number;
  };
};

type CountsBySpace = {
  [key: string]: {
    thresholds: EventCounts;
    sensors: EventCounts;
    ingress: number;
    egress: number;
    total: number;
    net: number;
  };
};
/**
 * Initializes counts for each threshold.
 */
const initializeCountsByThreshold = (
  thresholds: { name: string; id: string }[]
) => {
  const initialCounts: EventCounts = {};
  thresholds.forEach((threshold) => {
    initialCounts[threshold.id] = {
      ingress: 0,
      egress: 0,
      total: 0,
      net: 0,
    };
  });
  return initialCounts;
};

/**
 * Initializes counts for each space and its associated thresholds and sensors.
 */
const initializeCounts = (spaces: Map<string, SpaceRelation>) => {
  const initialCounts: CountsBySpace = {};
  Array.from(spaces.entries()).forEach(([spaceId, values]) => {
    initialCounts[spaceId] = {
      thresholds: initializeCountsByThreshold(values.thresholds),
      sensors: values.sensors.reduce(
        (acc: EventCounts, sensor: { serial: string; id: string }) => {
          acc[sensor.serial] = {
            ingress: 0,
            egress: 0,
            total: 0,
            net: 0,
          };
          return acc;
        },
        {}
      ),
      ingress: 0,
      egress: 0,
      total: 0,
      net: 0,
    };
  });

  return initialCounts;
};

const handleMessage = (
  message: string,
  serialNumbers: React.MutableRefObject<(string | null)[]>,
  spaceIds: string[],
  lastUpdate: React.MutableRefObject<any>,
  setCounts: React.Dispatch<React.SetStateAction<CountsBySpace>>
) => {
  const event: EntryCountSocketMessage = JSON.parse(message);
  if (!event.payload || event.payload.type !== 'count') {
    return;
  }
  const sensorSerial = event.payload.serial_number;
  if (sensorSerial === null || !serialNumbers.current.includes(sensorSerial)) {
    return;
  }

  const thresholdId = event.payload.doorway_id;
  const spaceId = event.payload.space_id;
  const dir = event.payload.direction;
  const ingress = dir === 1 ? 1 : 0;
  const egress = dir === -1 ? 1 : 0;

  if (!spaceIds.includes(spaceId)) return;

  lastUpdate.current = {
    sensor: sensorSerial,
    threshold: thresholdId,
    space: spaceId,
    dir,
  };

  setCounts((prev) => ({
    ...prev,
    [spaceId]: {
      ...prev[spaceId],
      thresholds: {
        ...prev[spaceId].thresholds,
        [thresholdId]: {
          ingress: prev[spaceId].thresholds[thresholdId].ingress + ingress,
          egress: prev[spaceId].thresholds[thresholdId].egress + egress,
          net: prev[spaceId].thresholds[thresholdId].net + ingress - egress,
          total: prev[spaceId].thresholds[thresholdId].total + 1,
        },
      },
      sensors: {
        ...prev[spaceId].sensors,
        [sensorSerial]: {
          ingress: prev[spaceId].sensors[sensorSerial].ingress + ingress,
          egress: prev[spaceId].sensors[sensorSerial].egress + egress,
          net: prev[spaceId].sensors[sensorSerial].net + ingress - egress,
          total: prev[spaceId].sensors[sensorSerial].total + 1,
        },
      },
      ingress: prev[spaceId].ingress + ingress,
      egress: prev[spaceId].egress + egress,
      total: prev[spaceId].total + 1,
      net: prev[spaceId].net + ingress - egress,
    },
  }));
};

interface EntryDashSpaceProps {
  sensors: FloorplanCollection<PlanSensor>;
  thresholds: FloorplanCollection<Threshold>;
  onWebsocketConnectionClosed: () => void;
  dispatch: (action: Action) => void;
}
/**
 * Displays a table of ELR ingress, egress, net, and total counts for each space and
 * its associated thresholds and sensors.
 */
const EntryDashSpace: React.FC<EntryDashSpaceProps> = ({
  sensors,
  thresholds,
  onWebsocketConnectionClosed,
  dispatch,
}) => {
  const thresholdCollection = useRef(FloorplanCollection.list(thresholds));
  const openEntrySensors = FloorplanCollection.list(sensors).filter(
    (sensor) => {
      return sensor.sensorFunction === 'openEntry';
    }
  );

  const dateAccessed = useRef(Date.now());
  const [showTable, setShowTable] = useState<ShowEntryTable>({});
  const [counts, setCounts] = useState<CountsBySpace>({});
  const lastUpdate = useRef({
    sensor: '',
    threshold: '',
    space: '',
    dir: 1,
  });

  const spaceIds = useRef(
    thresholdCollection.current.flatMap((threshold) =>
      threshold.relatedSpaces.map((space) => space.spaceId)
    )
  );

  const [entrySpaces, setEntrySpaces] = useState<Map<string, SpaceRelation>>(
    new Map()
  );

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const entrySensors = useRef<{ [key: string]: string }>({});

  const serialNumbers = useRef(
    openEntrySensors
      .map((sensor) => {
        if (!sensor.serialNumber) return null;
        entrySensors.current = {
          ...entrySensors.current,
          [sensor.id]: sensor.serialNumber,
        };
        return sensor.serialNumber;
      })
      .filter((val) => val !== null)
  );

  // Initialize the counts and spaces
  useEffect(() => {
    const { spaces } = processThresholdsAndSensors(
      thresholdCollection.current,
      entrySensors.current
    );
    setCounts(initializeCounts(spaces));
    setEntrySpaces(spaces);
  }, [thresholdCollection, entrySensors]);

  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    let socket: WebSocket | null = null;

    const authURL = `/v2/sockets`;

    densityAPIClient
      .post(authURL)
      .then((response) => {
        const socketURL = response.data.url;

        socket = new WebSocket(socketURL);

        socket.addEventListener('open', (evt) => {});

        socket.addEventListener('message', (message) => {
          handleMessage(
            message.data,
            serialNumbers,
            spaceIds.current,
            lastUpdate,
            setCounts
          );
        });

        socket.addEventListener('close', () => {
          onWebsocketConnectionClosed();
        });

        socket.addEventListener('error', () => {
          toast.error('Sensor data stream encountered an error!');
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error('Unable to stream sensor data!');
      });

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [densityAPIClient, onWebsocketConnectionClosed]);

  // Dragging control
  const [canDrag, setCanDrag] = useState(false);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      setCanDrag(true);
    }
  }, []);

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      setCanDrag(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const handleStart: DraggableEventHandler = useCallback(() => {
    if (!canDrag) return false;
  }, [canDrag]);

  const dashboardTableOptions = useMemo(() => {
    const tables: {
      embeddedTables: (ReactJSXElement | null)[];
      popoutTables: (ReactJSXElement | null)[];
    } = { embeddedTables: [], popoutTables: [] };
    const iconMap = {
      floor: <Icons.MapFloorPlan size={18} />,
      building: <Icons.SpaceTypeBuilding size={18} />,
      space: <Icons.SpaceTypeSpace size={18} />,
    };
    Array.from(entrySpaces.entries()).forEach(([key, value]) => {
      if (value.sensors.every((sensor) => !sensor.serial)) return null;
      const table = (
        <div key={key} className={styles.entryDashTable}>
          <div className={styles.entryDashTableActions}>
            <h5
              className={styles.entryDashSpaceHeader}
              onClick={(e) => {
                if (e.shiftKey) return; // it's being dragged, don't fire the event
                if (showTable.hasOwnProperty(key)) {
                  setShowTable({
                    ...showTable,
                    [key]: {
                      show: !showTable[key].show,
                      popout: showTable[key].popout,
                    },
                  });
                } else {
                  setShowTable({
                    ...showTable,
                    [key]: { show: true, popout: false },
                  });
                }
              }}
            >
              <div className={styles.entryDashTableTitle}>
                <span>{value.name}</span>{' '}
                <span>{value.type && iconMap[value.type]}</span>
              </div>
            </h5>
            <div className={styles.entryDashTablePopoutContainer}>
              <Tooltip text={showTable[key]?.popout ? 'Pop-in' : 'Pop-out'}>
                <button
                  className={styles.entryDashTablePopout}
                  onClick={() => {
                    if (showTable.hasOwnProperty(key)) {
                      setShowTable({
                        ...showTable,
                        [key]: {
                          show: showTable[key].show,
                          popout: !showTable[key].popout,
                        },
                      });
                    } else {
                      setShowTable({
                        ...showTable,
                        [key]: { show: false, popout: true },
                      });
                    }
                  }}
                >
                  {showTable[key]?.popout ? (
                    <Icons.AlignCenter size={20} />
                  ) : (
                    <Icons.OpenExternalArrow size={20} />
                  )}
                </button>
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              visibility: showTable[key]?.show ? 'visible' : 'hidden',
              height: showTable[key]?.show ? 'auto' : '0',
            }}
          >
            <EntryDashboardTable
              key={key}
              spaceRelations={value}
              counts={counts[key]}
              lastUpdate={lastUpdate}
              dispatch={dispatch}
            />
          </div>
        </div>
      );
      if (showTable[key]?.popout) {
        tables.popoutTables.push(table);
      } else {
        tables.embeddedTables.push(table);
        tables.popoutTables.push(null); // push null to retain the position of the other popouts
      }
    });
    return tables;
  }, [counts, showTable, dispatch, lastUpdate, entrySpaces]);

  return (
    <>
      <Draggable onStart={handleStart}>
        <div className={styles.entryDashContainer}>
          <div className={styles.entryDashHeaderContainer}>
            <h2 className={styles.entryDashHeader}>
              Live ELR Counts for Spaces
            </h2>
            <EntryDashTime dateTime={dateAccessed.current} />
            {dashboardTableOptions.embeddedTables}
          </div>
          <span style={{ margin: '0 auto .5rem auto' }}>
            (shift+click to drag)
          </span>
        </div>
      </Draggable>
      {dashboardTableOptions.popoutTables.map((table, i) =>
        table === null ? null : (
          <div key={i} className={styles.entryDashPopoutTableContainer}>
            <Draggable onStart={handleStart}>
              <div style={{ boxShadow: '3px 5px 5px rgba(34, 42, 46, 0.5)' }}>
                {table}
              </div>
            </Draggable>
          </div>
        )
      )}
    </>
  );
};

interface EntryDashTimeProps {
  dateTime: number;
}

const EntryDashTime: React.FC<EntryDashTimeProps> = ({ dateTime }) => {
  const date = new Date(dateTime).toLocaleTimeString('en-US');

  const [timeElapsed, setTimeElapsed] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const time = formatTime((Date.now() - dateTime) / 1000);
      setTimeElapsed(time);
    }, 1000);
    return () => clearInterval(interval);
  }, [dateTime]);

  // function to format time
  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    let hourString,
      minuteString,
      secondString = '';

    hours < 10 ? (hourString = `0${hours}`) : (hourString = hours.toString());
    minutes < 10
      ? (minuteString = `0${minutes}`)
      : (minuteString = minutes.toString());
    seconds < 10
      ? (secondString = `0${seconds}`)
      : (secondString = seconds.toString());

    return hours > 0
      ? `${hourString}:${minuteString}:${secondString}`
      : minutes >= 10
      ? `${minuteString}min`
      : `${minuteString}:${secondString}s`;
  };

  return (
    <div style={{ width: '100%' }}>
      <span style={{ margin: '.4em' }}>
        Counts as of: <b>{date}</b> ({timeElapsed} ago)
      </span>
    </div>
  );
};

interface EntryDashboardTableProps {
  spaceRelations: {
    sensors: { serial: string; id: string }[];
    thresholds: { name: string; id: string }[];
  };
  counts: CountsBySpace[string];
  lastUpdate: React.MutableRefObject<{
    sensor: string;
    threshold: string;
    space: string;
    dir: number;
  }>;
  dispatch: (action: Action) => void;
}

const EntryDashboardTable: React.FC<EntryDashboardTableProps> = memo(
  ({ spaceRelations, counts, lastUpdate, dispatch }) => {
    const { sensors, thresholds } = spaceRelations;
    const update = lastUpdate.current;

    const updateStyle = update.dir === -1 ? styles.fadeRed : styles.fadeGreen;

    return (
      <div className={styles.entryDashTableContainer}>
        <EntryDashboardTableData counts={counts} />
        {sensors.length > 0 && (
          <table>
            <EntryDashboardTableHeader type="sensor" />
            <tbody>
              {sensors.map((sensor) => {
                if (!sensor.serial) return null;
                const style =
                  update.sensor === sensor.serial ? updateStyle : '';
                const forceRerender =
                  update.sensor === sensor.serial ? Date.now() : 0;
                return (
                  <EntryDashboardTableRow
                    key={sensor.serial}
                    type="sensor"
                    entity={sensor}
                    style={style}
                    dispatch={dispatch}
                    counts={counts}
                    forceRerender={forceRerender}
                  />
                );
              })}
            </tbody>
          </table>
        )}
        {thresholds.length > 0 && (
          <table>
            <EntryDashboardTableHeader type="threshold" />
            <tbody>
              {thresholds.map((threshold, i) => {
                const style =
                  update.threshold === threshold.id ? updateStyle : '';
                const forceRerender =
                  update.threshold === threshold.id ? Date.now() : 0;
                return (
                  <EntryDashboardTableRow
                    key={threshold.id}
                    style={style}
                    type="threshold"
                    entity={threshold}
                    counts={counts}
                    dispatch={dispatch}
                    forceRerender={forceRerender}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
);

const EntryDashboardTableData: React.FunctionComponent<{
  counts: CountsBySpace[string];
}> = ({ counts }) => {
  return (
    <>
      <ol className={styles.entryDashTableData}>
        <li>
          <b>Ingress:</b> {counts.ingress}
        </li>
        <li>
          <b>Egress:</b> {counts.egress}
        </li>
        <li>
          <b>Net:</b> {counts.net}
        </li>
        <li>
          <b>Total:</b> {counts.total}
        </li>
      </ol>
      <em>
        This table shows counts only related to this space while testing, it is
        not the total for the entity
      </em>
    </>
  );
};

const EntryDashboardTableHeader: React.FunctionComponent<{
  type: 'sensor' | 'threshold';
}> = ({ type }) => {
  return (
    <thead style={{ fontWeight: 'bold' }}>
      <tr>
        <th>{type === 'sensor' ? 'Sensor Serial' : 'Threshold Name'}</th>
        <th>Ingress</th>
        <th>Egress</th>
        <th>Net</th>
      </tr>
    </thead>
  );
};

interface SensorEntity {
  serial: string;
  id: string;
}

interface ThresholdEntity {
  name: string;
  id: string;
}

interface EntryDashboardTableRowProps {
  type: 'sensor' | 'threshold';
  entity: SensorEntity | ThresholdEntity;
  counts: CountsBySpace[string];
  style: string;
  dispatch: (action: Action) => void;
  forceRerender: number;
}

const EntryDashboardTableRow: React.FunctionComponent<EntryDashboardTableRowProps> =
  ({ type, entity, counts, style, dispatch, forceRerender }) => {
    const rowRef = useRef<HTMLTableRowElement>(null);
    // trigger css animation even if it's the same class being added
    useEffect(() => {
      if (rowRef.current && style !== '') {
        try {
          rowRef.current.classList.remove(style);
        } catch (e) {
          console.error(e);
        }
        requestAnimationFrame(() => {
          if (rowRef.current) {
            rowRef.current.classList.add(style);
          }
        });
      }
    }, [style, forceRerender]);

    const label =
      type === 'sensor'
        ? (entity as SensorEntity).serial
        : (entity as ThresholdEntity).name;
    const index =
      type === 'sensor'
        ? (entity as SensorEntity).serial
        : (entity as ThresholdEntity).id;
    const entityCounts = type === 'sensor' ? counts.sensors : counts.thresholds;
    const { ingress, egress, net } = entityCounts[index];
    return (
      <tr ref={rowRef} className={style}>
        <td
          className={styles.entryDashTableLabel}
          onClick={() =>
            dispatch({
              type: 'item.menu.mousedown',
              itemType: type,
              itemId: entity.id,
            })
          }
          onMouseOver={() =>
            dispatch({
              type: 'item.graphic.mouseenter',
              itemType: type,
              itemId: entity.id,
            })
          }
          onMouseLeave={() =>
            dispatch({
              type: 'item.graphic.mouseleave',
              itemType: type,
              itemId: entity.id,
            })
          }
        >
          {label}
        </td>
        <td>{ingress}</td>
        <td>{egress}</td>
        <td>{net}</td>
      </tr>
    );
  };
export default EntryDashSpace;
