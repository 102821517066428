import { createContext, useContext, useMemo } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const DataContext = createContext<{ path: string | null }>({ path: null });

export default function TestRouteLoaderComponent() {
  const { path } = useRouteMatch();

  const data = useMemo(() => {
    return {
      path,
    };
  }, [path]);

  return (
    <DataContext.Provider value={data}>
      <Switch>
        <Route path={`${path}/one`} component={SubRouteOne} />
        <Route path={`${path}/two`} component={SubRouteTwo} />
        <Redirect to={`${path}/one`} />
      </Switch>
    </DataContext.Provider>
  );
}

function SubRouteOne() {
  const data = useContext(DataContext);

  return (
    <div>
      <div>Hi this is SubRouteOne</div>
      <div>{JSON.stringify(data)}</div>
      <div>
        <Link to={'/test/two'}>Go to SubRouteTwo</Link>
      </div>
    </div>
  );
}

function SubRouteTwo() {
  return (
    <div>
      <div>This is SubRouteTwo</div>
      <div>
        <Link to={'/test/one'}>Go to SubRouteOne</Link>
      </div>
    </div>
  );
}
