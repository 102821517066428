import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as React from 'react';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import classnames from 'classnames';

import styles from '../styles.module.scss';

import {
  ParsedPlanDXFAreaOfConcern,
  ParsedPlanDXFSensorPlacement,
  ParsedPlanDXFSpace,
} from 'lib/dxf';
import { LengthUnit, displayLength } from 'lib/units';
import FloorplanType from 'lib/floorplan';
import { FloorplanCoordinates } from 'lib/geometry';
import PlanSensor from 'lib/sensor';
import AreaOfConcern from 'lib/area-of-concern';
import Space from 'lib/space';
import {
  FloorplanSensorChange,
  FloorplanAreaOfConcernChange,
  FloorplanSpaceChange,
} from 'lib/cad';

const DiffOperationIndicator: React.FunctionComponent<{
  type: FloorplanSensorChange['type'];
}> = ({ type }) => {
  const [Icon, className] = {
    addition: [Icons.Plus, styles.addition],
    deletion: [Icons.Close, styles.deletion],
    modification: [Icons.SwapHorizontalArrow, styles.modification],
    'no-change': [Icons.Minus, styles.noChange],
  }[type];

  return (
    <div className={classnames(styles.operationTypeIndicator, className)}>
      <Icon size={12} />
    </div>
  );
};

const sensorFunctionDisplayName = (name: string): string => {
  switch (name) {
    case 'oaMidRange':
      return 'OA';
    case 'oaLongRange':
      return 'OA LR';
    case 'oaShortRange':
      return 'OA SR';
    case 'openEntry':
      return 'Entry LR';
    case 'tofEntry':
      return 'ToF Entry';
    default:
      return 'N/A';
  }
};

export const ImportDetailsTableSpaces: React.FunctionComponent<{
  floorplanSpacesChanges: FloorplanSpaceChange[];
  floorplan: FloorplanType;
  floorplanCADOrigin: FloorplanCoordinates;
  loading: boolean;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
  displayUnit: LengthUnit;
}> = ({
  floorplanSpacesChanges,
  floorplan,
  floorplanCADOrigin,
  loading,
  cadFileUnit,
  cadFileScale,
  displayUnit,
}) => {
  return (
    <table className={styles.diffTable} style={{ opacity: loading ? 0.5 : 1 }}>
      <thead>
        <tr>
          <th className={styles.diffHeader}></th>
          <th className={styles.diffHeader}>ID</th>
          <th className={styles.diffHeader}>Space Type</th>
          <th className={styles.diffHeader}>X Pos.</th>
          <th className={styles.diffHeader}>Y Pos.</th>
        </tr>
      </thead>
      <tbody>
        {floorplanSpacesChanges
          .sort((a, b) => a.data.name.localeCompare(b.data.name))
          .map((change) => {
            const renderCellForField = (
              template: (row: ParsedPlanDXFSpace | Space) => React.ReactNode,
              checkIfModified: (
                newData: ParsedPlanDXFSpace,
                oldData: Space
              ) => boolean
            ) => {
              switch (change.type) {
                case 'no-change':
                case 'addition':
                case 'deletion':
                  return (
                    <div style={{ height: 24 }}>{template(change.data)}</div>
                  );
                case 'modification':
                  if (!change.oldData) {
                    return;
                  }
                  if (checkIfModified(change.data, change.oldData)) {
                    return (
                      <div>
                        {template(change.data)}
                        <br />
                        <span
                          style={{
                            color: dust.Gray300,
                            textDecoration: 'line-through',
                          }}
                        >
                          {template(change.oldData)}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ height: 24 }}>{template(change.data)}</div>
                    );
                  }
              }
            };

            return (
              <tr
                className={classnames(styles.diffRow, {
                  [styles.addition]: change.type === 'addition',
                  [styles.deletion]: change.type === 'deletion',
                  [styles.modification]: change.type === 'modification',
                  [styles.noChange]: change.type === 'no-change',
                })}
                data-cy={`aoc-cad-import-diff-row-${change.type}`}
                key={uuidv4()}
              >
                <td>
                  <DiffOperationIndicator type={change.type} />
                </td>
                <td>{change.data.name}</td>
                <td>{change.data.countingMode}</td>
                <td>
                  {renderCellForField(
                    (row) => {
                      if (row.position.type === 'floorplan-coordinates') {
                        const cadCoord = FloorplanCoordinates.toCADCoordinates(
                          row.position,
                          floorplan,
                          floorplanCADOrigin,
                          cadFileUnit,
                          cadFileScale
                        );
                        return displayLength(cadCoord.x, displayUnit);
                      } else {
                        return displayLength(row.position.x, displayUnit);
                      }
                    },
                    (newData, oldData) => {
                      const cadCoord = FloorplanCoordinates.toCADCoordinates(
                        oldData.position,
                        floorplan,
                        floorplanCADOrigin,
                        cadFileUnit,
                        cadFileScale
                      );
                      return newData.position.x !== cadCoord.x;
                    }
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (row) => {
                      if (row.position.type === 'floorplan-coordinates') {
                        const cadCoord = FloorplanCoordinates.toCADCoordinates(
                          row.position,
                          floorplan,
                          floorplanCADOrigin,
                          cadFileUnit,
                          cadFileScale
                        );
                        return displayLength(cadCoord.y, displayUnit);
                      } else {
                        return displayLength(row.position.y, displayUnit);
                      }
                    },
                    (newData, oldData) => {
                      const cadCoord = FloorplanCoordinates.toCADCoordinates(
                        oldData.position,
                        floorplan,
                        floorplanCADOrigin,
                        cadFileUnit,
                        cadFileScale
                      );
                      return newData.position.y !== cadCoord.y;
                    }
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export const ImportDetailsTableAreasOfConcern: React.FunctionComponent<{
  floorplanAreasOfConcernChanges: Array<FloorplanAreaOfConcernChange>;
  floorplan: FloorplanType;
  floorplanCADOrigin: FloorplanCoordinates;
  loading: boolean;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
  displayUnit: LengthUnit;
}> = ({
  floorplanAreasOfConcernChanges,
  floorplan,
  floorplanCADOrigin,
  loading,
  cadFileUnit,
  cadFileScale,
  displayUnit,
}) => {
  return (
    <table className={styles.diffTable} style={{ opacity: loading ? 0.5 : 1 }}>
      <thead>
        <tr>
          <th className={styles.diffHeader}></th>
          <th className={styles.diffHeader}>ID</th>
          <th className={styles.diffHeader}>X Pos.</th>
          <th className={styles.diffHeader}>Y Pos.</th>
          <th className={styles.diffHeader}>Area</th>
        </tr>
      </thead>
      <tbody>
        {floorplanAreasOfConcernChanges
          .sort((a, b) => a.data.name.localeCompare(b.data.name))
          .map((change) => {
            const renderCellForField = (
              template: (
                row: ParsedPlanDXFAreaOfConcern | AreaOfConcern
              ) => React.ReactNode,
              checkIfModified: (
                newData: ParsedPlanDXFAreaOfConcern,
                oldData: AreaOfConcern
              ) => boolean
            ) => {
              switch (change.type) {
                case 'no-change':
                case 'addition':
                case 'deletion':
                  return (
                    <div style={{ height: 24 }}>{template(change.data)}</div>
                  );
                case 'modification':
                  if (!change.oldData) {
                    return;
                  }
                  if (checkIfModified(change.data, change.oldData)) {
                    return (
                      <div>
                        {template(change.data)}
                        <br />
                        <span
                          style={{
                            color: dust.Gray300,
                            textDecoration: 'line-through',
                          }}
                        >
                          {template(change.oldData)}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ height: 24 }}>{template(change.data)}</div>
                    );
                  }
              }
            };

            return (
              <tr
                className={classnames(styles.diffRow, {
                  [styles.addition]: change.type === 'addition',
                  [styles.deletion]: change.type === 'deletion',
                  [styles.modification]: change.type === 'modification',
                  [styles.noChange]: change.type === 'no-change',
                })}
                data-cy={`aoc-cad-import-diff-row-${change.type}`}
                key={uuidv4()}
              >
                <td>
                  <DiffOperationIndicator type={change.type} />
                </td>
                <td>{change.data.name}</td>
                <td>
                  {renderCellForField(
                    (row) => {
                      if (row.position.type === 'floorplan-coordinates') {
                        const cadCoord = FloorplanCoordinates.toCADCoordinates(
                          row.position,
                          floorplan,
                          floorplanCADOrigin,
                          cadFileUnit,
                          cadFileScale
                        );
                        return displayLength(cadCoord.x, displayUnit);
                      } else {
                        return displayLength(row.position.x, displayUnit);
                      }
                    },
                    (newData, oldData) => {
                      const cadCoord = FloorplanCoordinates.toCADCoordinates(
                        oldData.position,
                        floorplan,
                        floorplanCADOrigin,
                        cadFileUnit,
                        cadFileScale
                      );
                      return newData.position.x !== cadCoord.x;
                    }
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (row) => {
                      if (row.position.type === 'floorplan-coordinates') {
                        const cadCoord = FloorplanCoordinates.toCADCoordinates(
                          row.position,
                          floorplan,
                          floorplanCADOrigin,
                          cadFileUnit,
                          cadFileScale
                        );
                        return displayLength(cadCoord.y, displayUnit);
                      } else {
                        return displayLength(row.position.y, displayUnit);
                      }
                    },
                    (newData, oldData) => {
                      const cadCoord = FloorplanCoordinates.toCADCoordinates(
                        oldData.position,
                        floorplan,
                        floorplanCADOrigin,
                        cadFileUnit,
                        cadFileScale
                      );
                      return newData.position.y !== cadCoord.y;
                    }
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (n) => Math.round(n.areaMeters).toString() + ' m\u00B2',
                    (oldData, newData) =>
                      Math.round(oldData.areaMeters) !==
                      Math.round(newData.areaMeters)
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export const ImportDetailsTableSensors: React.FunctionComponent<{
  floorplanSensorsChanges: Array<FloorplanSensorChange>;
  floorplan: FloorplanType;
  floorplanCADOrigin: FloorplanCoordinates;
  loading: boolean;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
  displayUnit: LengthUnit;
}> = ({
  floorplanSensorsChanges,
  floorplan,
  floorplanCADOrigin,
  loading,
  cadFileUnit,
  cadFileScale,
  displayUnit,
}) => {
  return (
    <table className={styles.diffTable} style={{ opacity: loading ? 0.5 : 1 }}>
      <thead>
        <tr>
          <th className={styles.diffHeader}></th>
          <th className={styles.diffHeader}>ID</th>
          <th className={styles.diffHeader}>Serial #</th>
          <th className={styles.diffHeader}>Function</th>
          <th className={styles.diffHeader}>X Pos.</th>
          <th className={styles.diffHeader}>Y Pos.</th>
          <th className={styles.diffHeader}>Ht.</th>
          <th className={styles.diffHeader}>Rot.</th>
        </tr>
      </thead>
      <tbody>
        {floorplanSensorsChanges
          .sort((a, b) => a.data.cadId.localeCompare(b.data.cadId))
          .map((change) => {
            const renderCellForField = (
              template: (
                row: ParsedPlanDXFSensorPlacement | PlanSensor
              ) => React.ReactNode,
              checkIfModified: (
                newData: ParsedPlanDXFSensorPlacement,
                oldData: PlanSensor
              ) => boolean,
              warning?: boolean
            ) => {
              switch (change.type) {
                case 'no-change':
                case 'addition':
                case 'deletion':
                  return (
                    <div style={{ height: 24 }}>
                      {warning ? (
                        <>
                          <Icons.DangerWarningFill
                            size={13}
                            color={dust.Red500}
                          />
                          &nbsp;
                        </>
                      ) : null}
                      {template(change.data)}
                    </div>
                  );
                case 'modification':
                  if (!change.oldData) {
                    return;
                  }
                  if (checkIfModified(change.data, change.oldData)) {
                    return (
                      <div>
                        {template(change.data)}
                        <br />
                        <span
                          style={{
                            color: dust.Gray300,
                            textDecoration: 'line-through',
                          }}
                        >
                          {template(change.oldData)}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ height: 24 }}>{template(change.data)}</div>
                    );
                  }
              }
            };

            return (
              <tr
                className={classnames(styles.diffRow, {
                  [styles.addition]: change.type === 'addition',
                  [styles.deletion]: change.type === 'deletion',
                  [styles.modification]: change.type === 'modification',
                  [styles.noChange]: change.type === 'no-change',
                })}
                data-cy={`cad-import-diff-row-${change.type}`}
                key={uuidv4()}
              >
                <td>
                  <DiffOperationIndicator type={change.type} />
                </td>
                <td>{change.data.cadId}</td>
                <td>
                  {renderCellForField(
                    (n) => n.serialNumber || '',
                    (oldData, newData) =>
                      oldData.serialNumber !== newData.serialNumber,
                    change.snHardwareMatch === false
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (n) => sensorFunctionDisplayName(n.sensorFunction),
                    (oldData, newData) =>
                      oldData.sensorFunction !== newData.sensorFunction,
                    change.snHardwareMatch === false
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (row) => {
                      if (row.position.type === 'floorplan-coordinates') {
                        const cadCoord = FloorplanCoordinates.toCADCoordinates(
                          row.position,
                          floorplan,
                          floorplanCADOrigin,
                          cadFileUnit,
                          cadFileScale
                        );
                        return displayLength(cadCoord.x, displayUnit);
                      } else {
                        return displayLength(row.position.x, displayUnit);
                      }
                    },
                    (newData, oldData) => {
                      const cadCoord = FloorplanCoordinates.toCADCoordinates(
                        oldData.position,
                        floorplan,
                        floorplanCADOrigin,
                        cadFileUnit,
                        cadFileScale
                      );
                      return newData.position.x !== cadCoord.x;
                    }
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (row) => {
                      if (row.position.type === 'floorplan-coordinates') {
                        const cadCoord = FloorplanCoordinates.toCADCoordinates(
                          row.position,
                          floorplan,
                          floorplanCADOrigin,
                          cadFileUnit,
                          cadFileScale
                        );
                        return displayLength(cadCoord.y, displayUnit);
                      } else {
                        return displayLength(row.position.y, displayUnit);
                      }
                    },
                    (newData, oldData) => {
                      const cadCoord = FloorplanCoordinates.toCADCoordinates(
                        oldData.position,
                        floorplan,
                        floorplanCADOrigin,
                        cadFileUnit,
                        cadFileScale
                      );
                      return newData.position.y !== cadCoord.y;
                    }
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (n) => displayLength(n.height, displayUnit),
                    (oldData, newData) => oldData.height !== newData.height
                  )}
                </td>
                <td>
                  {renderCellForField(
                    (n) => `${n.rotation}\u00B0`,
                    (oldData, newData) => oldData.rotation !== newData.rotation
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
