import { useState } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

import { Icons } from '@density/dust';
import Button from 'components/button';
import { DarkTheme, LightTheme } from 'components/theme';
import HorizontalForm from 'components/horizontal-form';
import Popup from 'components/popup';

import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';

const SyncingIndicator: React.FunctionComponent<{
  status: 'disconnected' | 'connecting' | 'connected' | 'error' | 'saving';
  undoEnabled: boolean;
  onUndoClick: () => void;
  redoEnabled: boolean;
  onRedoClick: () => void;
}> = ({ status, undoEnabled, redoEnabled, onUndoClick, onRedoClick }) => {
  const [autoSavingDisabledPopupVisible, setAutoSavingDisabledPopupVisible] =
    useState(false);
  const isAutoSavingDisabled = useTreatment(SPLITS.DISABLE_AUTO_SAVING);
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.disableAutoSave]: isAutoSavingDisabled,
      })}
    >
      <DarkTheme>
        <HorizontalForm size="small">
          <Button
            size="medium"
            type="cleared"
            disabled={!undoEnabled || status !== 'connected'}
            onClick={onUndoClick}
            data-cy="undo-button"
            trailingIcon={<Icons.Undo size={18} />}
          />
          <Button
            size="medium"
            type="cleared"
            disabled={!redoEnabled || status !== 'connected'}
            onClick={onRedoClick}
            data-cy="redo-button"
            trailingIcon={<Icons.Redo size={18} />}
          />
        </HorizontalForm>
        {isAutoSavingDisabled ? (
          <LightTheme>
            <Popup
              open={autoSavingDisabledPopupVisible}
              onClose={() => setAutoSavingDisabledPopupVisible(true)}
              position={{ right: 0, top: 36 }}
              popupWidth={320}
              target={
                <div
                  className={styles.status}
                  data-cy="syncing-status"
                  onMouseEnter={() => setAutoSavingDisabledPopupVisible(true)}
                  onMouseLeave={() => setAutoSavingDisabledPopupVisible(false)}
                >
                  <Icons.HelpQuestionMark size={18} />
                  Not Saving!
                </div>
              }
            >
              <h2 style={{ marginBottom: 0 }}>Wait... what is this?</h2>
              <p>
                Planner has the <code>{SPLITS.DISABLE_AUTO_SAVING}</code>{' '}
                feature flag enabled, and is{' '}
                <strong style={{ color: 'red' }}>not saving changes</strong> to
                the server or listening to changes from other connected clients.
              </p>
              <p>
                This mode is primarily meant for developers looking to try
                things with plans without those changes making their way to the
                server.
              </p>
              <p>
                To turn this mode off, disable the feature flag and reload the
                page!
              </p>
            </Popup>
          </LightTheme>
        ) : (
          <div className={styles.status} data-cy="syncing-status">
            <div
              className={classnames(styles.dot, {
                [styles.success]: status === 'connected' || status === 'saving',
                [styles.warning]: status === 'connecting',
                [styles.danger]: status === 'error',
              })}
            />
            {status === 'disconnected' ? 'Disconnected' : null}
            {status === 'connected' ? 'Saved' : null}
            {status === 'saving' ? 'Saving...' : null}
            {status === 'connecting' ? 'Connecting...' : null}
            {status === 'error' ? 'Error' : null}
          </div>
        )}
      </DarkTheme>
    </div>
  );
};

export default SyncingIndicator;
