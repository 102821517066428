import Floorplan from 'lib/floorplan';
import { Viewport } from 'lib/viewport';
import { degreesToRadians, radiansToDegrees } from 'lib/math';
import PlanSensor from 'lib/sensor';
import HeightMap from 'lib/heightmap';
import { CONVERT_TO_METERS, CONVERT_FROM_METERS, LengthUnit } from 'lib/units';
import { distance } from 'lib/math';
import { lineSegmentIntersection2d } from 'lib/algorithm';

export type GridCoordinates = {
  type: 'grid-coordinates';
  x: number;
  y: number;
};

export const GridCoordinates = {
  create(x: number, y: number): GridCoordinates {
    return {
      type: 'grid-coordinates',
      x,
      y,
    };
  },
};

export type FloorplanCoordinates = {
  type: 'floorplan-coordinates';
  x: number;
  y: number;
};
export const FloorplanCoordinates = {
  create(x: number, y: number): FloorplanCoordinates {
    return {
      type: 'floorplan-coordinates',
      x,
      y,
    };
  },
  isEqual(a: FloorplanCoordinates, b: FloorplanCoordinates) {
    return a.x === b.x && a.y === b.y;
  },
  toImageCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    floorplan: Floorplan
  ) {
    const pixelsFromOriginX = floorplanCoordinates.x * floorplan.scale;
    const pixelsFromOriginY = floorplanCoordinates.y * floorplan.scale;
    const x = floorplan.origin.x + pixelsFromOriginX;
    const y = floorplan.origin.y + pixelsFromOriginY;
    return ImageCoordinates.create(x, y);
  },
  toViewportCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    floorplan: Floorplan,
    viewport: Viewport
  ) {
    return ImageCoordinates.toViewportCoordinates(
      FloorplanCoordinates.toImageCoordinates(floorplanCoordinates, floorplan),
      viewport
    );
  },

  toGridCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    gridStepSize: number
  ) {
    return GridCoordinates.create(
      Math.floor(floorplanCoordinates.x / gridStepSize),
      Math.floor(floorplanCoordinates.y / gridStepSize)
    );
  },

  toCADCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number
  ) {
    const x = floorplanCoordinates.x - floorplanCADOrigin.x;
    const y = floorplanCADOrigin.y - floorplanCoordinates.y;

    return CADCoordinates.create(
      CONVERT_FROM_METERS[cadFileUnit](x) * cadFileScale,
      CONVERT_FROM_METERS[cadFileUnit](y) * cadFileScale
    );
  },

  toHeightMapCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    const x = (floorplanCoordinates.x - heightMap.position.x) / heightMapScale;
    const y = (floorplanCoordinates.y - heightMap.position.y) / heightMapScale;

    // Convert the ceiling raster into polar coordinates
    const distance = Math.hypot(x, y);
    let rotation = radiansToDegrees(Math.atan2(y, x));

    rotation -= heightMap.rotation;
    while (rotation < 0) {
      rotation += 360;
    }

    const rotationInRadians = degreesToRadians(rotation);

    const finalX = Math.cos(rotationInRadians) * distance;
    const finalY = Math.sin(rotationInRadians) * distance;

    return HeightMapCoordinates.create(finalX, finalY);
  },
};

export type ImageCoordinates = {
  type: 'image-coordinates';
  x: number;
  y: number;
};
export const ImageCoordinates = {
  create(x: number, y: number): ImageCoordinates {
    return {
      type: 'image-coordinates',
      x,
      y,
    };
  },
  isEqual(a: ImageCoordinates, b: ImageCoordinates) {
    return a.x === b.x && a.y === b.y;
  },
  toFloorplanCoordinates(
    imageCoordinates: ImageCoordinates,
    floorplan: Floorplan
  ) {
    const offsetX = imageCoordinates.x - floorplan.origin.x;
    const offsetY = imageCoordinates.y - floorplan.origin.y;
    const x = offsetX / floorplan.scale;
    const y = offsetY / floorplan.scale;
    return FloorplanCoordinates.create(x, y);
  },

  toViewportCoordinates(
    imageCoordinates: ImageCoordinates,
    viewport: Viewport
  ) {
    const x = (imageCoordinates.x - viewport.left) * viewport.zoom;
    const y = (imageCoordinates.y - viewport.top) * viewport.zoom;
    return ViewportCoordinates.create(x, y);
  },

  toCADCoordinates(
    imageCoordinates: ImageCoordinates,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number
  ) {
    return FloorplanCoordinates.toCADCoordinates(
      ImageCoordinates.toFloorplanCoordinates(imageCoordinates, floorplan),
      floorplan,
      floorplanCADOrigin,
      cadFileUnit,
      cadFileScale
    );
  },
};

export type ViewportCoordinates = {
  type: 'viewport-coordinates';
  x: number;
  y: number;
};
export const ViewportCoordinates = {
  create(x: number, y: number): ViewportCoordinates {
    return {
      type: 'viewport-coordinates',
      x,
      y,
    };
  },
  toImageCoordinates(
    viewportCoordinates: ViewportCoordinates,
    viewport: Viewport
  ) {
    const x = viewportCoordinates.x / viewport.zoom + viewport.left;
    const y = viewportCoordinates.y / viewport.zoom + viewport.top;
    return ImageCoordinates.create(x, y);
  },
  toFloorplanCoordinates(
    viewportCoordinates: ViewportCoordinates,
    viewport: Viewport,
    floorplan: Floorplan
  ) {
    return ImageCoordinates.toFloorplanCoordinates(
      ViewportCoordinates.toImageCoordinates(viewportCoordinates, viewport),
      floorplan
    );
  },
  toHeightMapCoordinates(
    viewportCoordinates: ViewportCoordinates,
    viewport: Viewport,
    floorplan: Floorplan,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    const floorplanCoordinates = ViewportCoordinates.toFloorplanCoordinates(
      viewportCoordinates,
      viewport,
      floorplan
    );
    return FloorplanCoordinates.toHeightMapCoordinates(
      floorplanCoordinates,
      heightMap,
      heightMapScale
    );
  },
};

export type SensorCoordinates = {
  type: 'sensor-coordinates';
  x: number;
  z: number;
};
export namespace SensorCoordinates {
  export function create(x: number, z: number): SensorCoordinates {
    return {
      type: 'sensor-coordinates',
      x,
      z,
    };
  }

  export function toFloorplanCoordinates(
    sensorCoordinates: SensorCoordinates,
    sensor: PlanSensor
  ) {
    const rotationRadians = degreesToRadians(sensor.rotation + 180);
    const x =
      -sensorCoordinates.x * Math.cos(rotationRadians) -
      sensorCoordinates.z * Math.sin(rotationRadians);
    const y =
      -sensorCoordinates.x * Math.sin(rotationRadians) +
      sensorCoordinates.z * Math.cos(rotationRadians);
    return FloorplanCoordinates.create(
      sensor.position.x + x + (sensor.dataNudgeX || 0),
      sensor.position.y + y + (sensor.dataNudgeY || 0)
    );
  }
}

export type CADCoordinates = {
  type: 'cad-coordinates';
  x: number;
  y: number;
};
export const CADCoordinates = {
  create(x: number, y: number): CADCoordinates {
    return {
      type: 'cad-coordinates',
      x,
      y,
    };
  },
  toFloorplanCoordinates(
    cadCoordinates: CADCoordinates,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number
  ) {
    const x = CONVERT_TO_METERS[cadFileUnit](cadCoordinates.x / cadFileScale);
    const y = CONVERT_TO_METERS[cadFileUnit](cadCoordinates.y / cadFileScale);

    return FloorplanCoordinates.create(
      // The CAD origin is in the lower left corner of the cad drawing
      x + floorplanCADOrigin.x,
      -1 * y + floorplanCADOrigin.y
    );
  },
  toImageCoordinates(
    cadCoordinates: CADCoordinates,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number,
    floorplan: Floorplan
  ) {
    return FloorplanCoordinates.toImageCoordinates(
      CADCoordinates.toFloorplanCoordinates(
        cadCoordinates,
        floorplan,
        floorplanCADOrigin,
        cadFileUnit,
        cadFileScale
      ),
      floorplan
    );
  },
  toViewportCoordinates(
    cadCoordinates: CADCoordinates,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number,
    floorplan: Floorplan,
    viewport: Viewport
  ) {
    return FloorplanCoordinates.toViewportCoordinates(
      CADCoordinates.toFloorplanCoordinates(
        cadCoordinates,
        floorplan,
        floorplanCADOrigin,
        cadFileUnit,
        cadFileScale
      ),
      floorplan,
      viewport
    );
  },
};

// Height map coordinates are pixel coordinates within the ceiling raster image
export type HeightMapCoordinates = {
  type: 'height-map-coordinates';
  x: number;
  y: number;
};
export const HeightMapCoordinates = {
  create(x: number, y: number): HeightMapCoordinates {
    return {
      type: 'height-map-coordinates',
      x,
      y,
    };
  },
  isEqual(a: HeightMapCoordinates, b: HeightMapCoordinates) {
    return a.x === b.x && a.y === b.y;
  },

  toFloorplanCoordinates(
    heightMapCoordinates: HeightMapCoordinates,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    // Convert the ceiling raster into polar coordinates
    const distance = Math.hypot(heightMapCoordinates.x, heightMapCoordinates.y);
    let rotation = radiansToDegrees(
      Math.atan2(heightMapCoordinates.y, heightMapCoordinates.x)
    );
    rotation += heightMap.rotation;
    while (rotation >= 360) {
      rotation -= 360;
    }

    const rotationInRadians = degreesToRadians(rotation);
    const x = Math.cos(rotationInRadians) * distance;
    const y = Math.sin(rotationInRadians) * distance;

    return FloorplanCoordinates.create(
      x * heightMapScale + heightMap.position.x,
      y * heightMapScale + heightMap.position.y
    );
  },
  toViewportCoordinates(
    heightMapCoordinates: HeightMapCoordinates,
    viewport: Viewport,
    floorplan: Floorplan,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    return FloorplanCoordinates.toViewportCoordinates(
      HeightMapCoordinates.toFloorplanCoordinates(
        heightMapCoordinates,
        heightMap,
        heightMapScale
      ),
      floorplan,
      viewport
    );
  },
};

export class ViewportVector {
  constructor(public readonly x: number, public readonly y: number) {}

  toImageVector(viewport: Viewport) {
    return new ImageVector(this.x / viewport.zoom, this.y / viewport.zoom);
  }
}

export class ImageVector {
  constructor(public readonly x: number, public readonly y: number) {}

  toFloorplanVector(floorplan: Floorplan) {
    return new FloorplanVector(
      this.x / floorplan.scale,
      this.y / floorplan.scale
    );
  }

  toViewportVector(viewport: Viewport) {
    return new ViewportVector(this.x * viewport.zoom, this.y * viewport.zoom);
  }
}

export class FloorplanVector {
  constructor(public readonly x: number, public readonly y: number) {}
}

export function sensorToFloorplanCoordinates(
  sensorCoordinates: SensorCoordinates,
  sensorPosition: FloorplanCoordinates,
  sensorRotation: number
) {
  const rotationRadians = degreesToRadians(sensorRotation + 180);
  const x =
    -sensorCoordinates.x * Math.cos(rotationRadians) -
    sensorCoordinates.z * Math.sin(rotationRadians);
  const y =
    -sensorCoordinates.x * Math.sin(rotationRadians) +
    sensorCoordinates.z * Math.cos(rotationRadians);
  return FloorplanCoordinates.create(
    sensorPosition.x + x,
    sensorPosition.y + y
  );
}

// Adapted from https://stackoverflow.com/a/3368118/1525769
export function drawRoundedRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
}

// Given any kind of coordinate, snap the coordinate to an angle that is a multiple of the
// `snapAngle` about a center point `center`
export function snapToAngle<T extends string>(
  center: { type: T; x: number; y: number },
  outer: { type: T; x: number; y: number },
  snapAngle = 45,
  snapAngleStartPoint = 0
): { type: T; x: number; y: number } {
  // Compute the distance between the two points
  const magnitude = Math.hypot(outer.x - center.x, outer.y - center.y);

  // Run through each possible point `magnitude` away from `center`, and see which is closest to the
  // `outer` point passed in. The closest point represents the snap location!
  let distance = Infinity;
  let point = { type: center.type, x: 0, y: 0 };
  for (let angle = snapAngleStartPoint; angle < 360; angle += snapAngle) {
    const testPoint = {
      type: center.type,
      x: center.x + magnitude * Math.cos(degreesToRadians(angle)),
      y: center.y + magnitude * Math.sin(degreesToRadians(angle)),
    };
    const distanceFromOuterPointToTestPoint = Math.hypot(
      testPoint.x - outer.x,
      testPoint.y - outer.y
    );

    if (distance > distanceFromOuterPointToTestPoint) {
      distance = distanceFromOuterPointToTestPoint;
      point = testPoint;
    }
  }
  return point;
}

// Given a list of vertices of a polygon, compute the centroid
// More info: https://math.stackexchange.com/a/700059
export function calculatePolygonCentroid(
  vertices: Array<FloorplanCoordinates>
): FloorplanCoordinates {
  if (vertices.length === 0) {
    throw new Error('Cannot compute centroid of an empty vertex list!');
  }
  if (vertices.length === 1) {
    return vertices[0];
  }
  if (vertices.length === 2 || vertices.length === 3) {
    const totalX = vertices.reduce((acc, i) => acc + i.x, 0);
    const totalY = vertices.reduce((acc, i) => acc + i.y, 0);
    return FloorplanCoordinates.create(
      totalX / vertices.length,
      totalY / vertices.length
    );
  }

  // For more complex polygons, split up into triples of points
  const results: Array<FloorplanCoordinates> = [];
  for (let i = 0, j = 1, k = 2; k < vertices.length; i += 1, j += 1, k += 1) {
    const triple = [vertices[i], vertices[j], vertices[k]];
    // Compute the centroid of each triangle
    results.push(calculatePolygonCentroid(triple));
  }

  // And then compute the centroid of all the centroids
  return calculatePolygonCentroid(results);
}

export function computeCentroid<T extends string>(
  vertices: Array<{ type: T; x: number; y: number }>
): { type: T; x: number; y: number } {
  if (vertices.length < 3) {
    throw new Error(
      `Unable to compute bounding box for ${vertices.length} vertices!`
    );
  }
  // Given a set of vertices, determine the "center" (x,y) position.
  // ATTN: Centroid may sometimes fall OUTSIDE of the polygon [sharp triangles, etc].

  let sumX = 0;
  let sumY = 0;

  for (let i = 0; i < vertices.length; i++) {
    sumX += vertices[i].x;
    sumY += vertices[i].y;
  }

  const centerX = sumX / vertices.length;
  const centerY = sumY / vertices.length;

  return { type: vertices[0].type, x: centerX, y: centerY };
}

// Given a set of vertices, compute the upper left and lower right coordinates of the axis aligned
// bounding box
export function computeBoundingRegionExtents<T extends string>(
  vertices: Array<{ type: T; x: number; y: number }>
):
  | [{ type: T; x: number; y: number }, { type: T; x: number; y: number }]
  | [null, null] {
  if (vertices.length < 1) {
    return [null, null];
  }

  // Get all wall segments that should be checked for snapping
  const upperLeft = {
    type: vertices[0].type,
    x: Math.min(...vertices.map((v) => v.x)),
    y: Math.min(...vertices.map((v) => v.y)),
  };
  const lowerRight = {
    type: vertices[0].type,
    x: Math.max(...vertices.map((v) => v.x)),
    y: Math.max(...vertices.map((v) => v.y)),
  };
  return [upperLeft, lowerRight];
}

export function isParallel<T extends string>(
  verticesA: Array<{ type: T; x: number; y: number }>,
  verticesB: Array<{ type: T; x: number; y: number }>
): number | null {
  if (verticesA.length <= 1 || verticesB.length <= 1) {
    return null;
  }

  const deltaX1 = verticesA[1].x - verticesA[0].x;
  const deltaX2 = verticesB[1].x - verticesB[0].x;

  if (deltaX1 === 0 && deltaX2 === 0) {
    // Both lines are vertical
    const minY1 = Math.min(verticesA[0].y, verticesA[1].y).toFixed(3);
    const maxY1 = Math.max(verticesA[0].y, verticesA[1].y).toFixed(3);
    const minY2 = Math.min(verticesB[0].y, verticesB[1].y).toFixed(3);
    const maxY2 = Math.max(verticesB[0].y, verticesB[1].y).toFixed(3);

    if (maxY1 === minY2 && minY1 === maxY2) {
      // Vertical lines are overlapping
      return 0;
    }

    // Calculate distance between non-overlapping vertical lines
    const distance = Math.abs(verticesA[0].x - verticesB[0].x);
    return parseFloat(distance.toFixed(4)); // Round the distance to 4 decimal places
  }

  const slope1 =
    deltaX1 === 0 ? Infinity : (verticesA[1].y - verticesA[0].y) / deltaX1;
  const slope2 =
    deltaX2 === 0 ? Infinity : (verticesB[1].y - verticesB[0].y) / deltaX2;

  const tolerance = 1e-5; // Adjust tolerance as needed

  if (Math.abs(slope1 - slope2) <= tolerance) {
    // Lines are parallel or coincident, compute orthogonal distance
    const intercept1 = verticesA[0].y - slope1 * verticesA[0].x;
    const distance =
      Math.abs(intercept1 - verticesB[0].y + slope1 * verticesB[0].x) /
      Math.sqrt(slope1 ** 2 + 1);
    return parseFloat(distance.toFixed(3)); // Round the distance to 4 decimal places
  }

  return null; // Lines are not parallel, distance is null
}

// Generate pairs of points that make up all edges of a polygon
export function computePolygonEdges(
  vertices: Array<FloorplanCoordinates>
): Array<[FloorplanCoordinates, FloorplanCoordinates]> {
  if (vertices.length <= 1) {
    return [];
  } else if (vertices.length === 2) {
    return [[vertices[0], vertices[1]]];
  } else {
    const vertexPairs: Array<[FloorplanCoordinates, FloorplanCoordinates]> = [];
    for (let i = 0, j = 1; j < vertices.length; i++, j++) {
      vertexPairs.push([vertices[i], vertices[j]]);
    }
    vertexPairs.push([vertices[vertices.length - 1], vertices[0]]);
    return vertexPairs;
  }
}

// Given a list of pairs of points that correspond to line segments, return a list of all line
// segments that intersect the bounding region.
//
// NOTE: The typing of this function is a little tricky. It's generic so that you can pass in
// whatever sort of list you want, and then use the optional "mapper" function to map each
// item in the list to a pair of floorplan coordinates
export function computeLineSegmentsInBoundingRegion<
  T extends any,
  C extends { type: string; x: number; y: number },
  U extends [C, C]
>(
  lineSegments: Array<T>,
  upperLeft: C,
  lowerRight: C,
  mapper: (input: T) => U = (pair: T): U => pair as U
): Array<T> {
  // Use the cohen-southerland algorithm to figure out all wall segments that are in the region
  // More info: https://stackoverflow.com/a/3746601
  type Region =
    | 'northwest'
    | 'west'
    | 'southwest'
    | 'north'
    | 'center'
    | 'south'
    | 'northeast'
    | 'east'
    | 'southeast';
  const REGION_TO_CODE: { [region: string]: number } = {
    northwest: 0b1001,
    west: 0b1000,
    southwest: 0b1010,
    north: 0b001,
    center: 0b0000,
    south: 0b0010,
    northeast: 0b0101,
    east: 0b0100,
    southeast: 0b0110,
  };
  const pointToCode = new Map<string, number>();

  const computeRegionForPosition = (position: C): Region => {
    if (position.y < upperLeft.y) {
      // north
      if (position.x < upperLeft.x) {
        return 'northwest';
      } else if (position.x > lowerRight.x) {
        return 'northeast';
      } else {
        return 'north';
      }
    } else if (position.y > lowerRight.y) {
      // south
      if (position.x < upperLeft.x) {
        return 'southwest';
      } else if (position.x > lowerRight.x) {
        return 'southeast';
      } else {
        return 'south';
      }
    } else {
      // center
      if (position.x < upperLeft.x) {
        return 'west';
      } else if (position.x > lowerRight.x) {
        return 'east';
      } else {
        return 'center';
      }
    }
  };

  const filteredLineSegments: Array<T> = [];
  for (const rawLineSegment of lineSegments) {
    const lineSegment = mapper(rawLineSegment);

    // Part 1: Calculate the region that every endpoint of each segment is within
    const positionAKey = `${lineSegment[0].x},${lineSegment[0].y}`;
    let positionACode = pointToCode.get(positionAKey);
    if (typeof positionACode === 'undefined') {
      positionACode = REGION_TO_CODE[
        computeRegionForPosition(lineSegment[0])
      ] as number;
      pointToCode.set(positionAKey, positionACode);
    }
    const positionBKey = `${lineSegment[1].x},${lineSegment[1].y}`;
    let positionBCode = pointToCode.get(positionBKey);
    if (typeof positionBCode === 'undefined') {
      positionBCode = REGION_TO_CODE[
        computeRegionForPosition(lineSegment[1])
      ] as number;
      pointToCode.set(positionBKey, positionBCode);
    }

    // Part 2: AND together the codes for each wall segment endpoint, and if that doesn't result in
    // zero, then then segment isn't in the bounding box
    const lineSegmentPassesThroughBoundingRegion =
      (positionACode & positionBCode) === 0;
    if (lineSegmentPassesThroughBoundingRegion) {
      filteredLineSegments.push(rawLineSegment);
    }
  }

  return filteredLineSegments;
}
type LineSegment = {
  positionA: { x: number; y: number };
  positionB: { x: number; y: number };
};
// Check to see if any segment intersects with any other segment. If it does, split both
// segments so that they have an explicit point at the intersection they both share.
export function splitLineSegmentsWithEachOther<
  T extends any,
  C extends { type: string; x: number; y: number },
  U extends [C, C]
>(
  lineSegments: Array<T>,
  mapper: (input: T) => U = (pair: T): U => pair as U,
  createNewSegment: (sourceSegment: T, startPoint: C, endPoint: C) => T | null,
  shouldSplitSegments: (a: T, b: T) => boolean = () => true,
  setParsingPercent: (percent: number) => void = () => {},
  minSegmentLength = 1e-3
) {
  let splitLineSegments = lineSegments.slice();

  let i = 0;
  let prevASegment: LineSegment | null = null;
  let prevBSegment: LineSegment | null = null;
  outer: while (true) {
    for (; i < splitLineSegments.length; i += 1) {
      const rawSegmentA = splitLineSegments[i];
      const segmentA = mapper(rawSegmentA);
      if (distance(segmentA[0], segmentA[1]) < minSegmentLength) {
        continue;
      }

      for (let j = i + 1; j < splitLineSegments.length; j += 1) {
        if (i === j) {
          continue;
        }

        const rawSegmentB = splitLineSegments[j];
        if (!shouldSplitSegments(rawSegmentA, rawSegmentB)) {
          continue;
        }

        const segmentB = mapper(rawSegmentB);

        // Filter out really short segments
        const d = distance(segmentB[0], segmentB[1]);
        if (d < minSegmentLength) {
          continue;
        }

        // If the lines have the same slope - don't split them, they will either never intersect, or
        // the two lines are on top of each other, and they intersect at multiple points
        const segmentASlope =
          (segmentA[0].y - segmentA[1].y) / (segmentA[0].x - segmentA[1].x);
        const segmentBSlope =
          (segmentB[0].y - segmentB[1].y) / (segmentB[0].x - segmentB[1].x);
        if (segmentASlope === segmentBSlope) {
          continue;
        }

        const result = lineSegmentIntersection2d(
          [],
          [segmentA[0].x, segmentA[0].y],
          [segmentA[1].x, segmentA[1].y],
          [segmentB[0].x, segmentB[0].y],
          [segmentB[1].x, segmentB[1].y]
        );
        if (!result) {
          continue;
        }

        // This line was split by another segment!
        const splitPoint = {
          type: segmentA[0].type,
          x: result[0],
          y: result[1],
        } as C;

        // Make sure that the split point isn't one of the end points of the segment
        if (segmentA[0].x === splitPoint.x && segmentA[0].y === splitPoint.y) {
          continue;
        }
        if (segmentA[1].x === splitPoint.x && segmentA[1].y === splitPoint.y) {
          continue;
        }

        // Check to see if the previous segments are close to the current segments to
        // avoid possible infinite loops or over-splitting
        const checkLineSegmentEquivalence = (
          segment1: LineSegment,
          segment2: LineSegment,
          tolerance: number = 0.001
        ) => {
          if (!segment2 || !segment1) return false;

          const diff = (a: number, b: number) =>
            Math.abs((a - b) / Math.max(1, Math.abs(a), Math.abs(b))) <
            tolerance;

          const { positionA: posA1, positionB: posB1 } = segment1;
          const { positionA: posA2, positionB: posB2 } = segment2;

          return (
            diff(posA1.x, posA2.x) &&
            diff(posA1.y, posA2.y) &&
            diff(posB1.x, posB2.x) &&
            diff(posB1.y, posB2.y)
          );
        };
        // if the previous segments are close, skip the split
        if (
          prevASegment &&
          prevBSegment &&
          checkLineSegmentEquivalence(
            prevASegment,
            rawSegmentA as LineSegment
          ) &&
          checkLineSegmentEquivalence(prevBSegment, rawSegmentB as LineSegment)
        )
          continue;

        // reassign the previous segments
        prevASegment = rawSegmentA as LineSegment;
        prevBSegment = rawSegmentB as LineSegment;

        // Found a pair of segments that should be split!
        // 1. Remove both segments
        splitLineSegments = splitLineSegments.filter(
          (l) => l !== rawSegmentA && l !== rawSegmentB
        );

        // 2. Add back both segments, only this time, with them both bisected at the split point

        // Segment A:
        const rawNewSegmentAPt1 = createNewSegment(
          rawSegmentA,
          segmentA[0],
          splitPoint
        );
        if (rawNewSegmentAPt1) {
          const newSegmentAPt1 = mapper(rawNewSegmentAPt1);
          if (
            distance(newSegmentAPt1[0], newSegmentAPt1[1]) > minSegmentLength
          ) {
            splitLineSegments = [...splitLineSegments, rawNewSegmentAPt1];
          }
        }

        const rawNewSegmentAPt2 = createNewSegment(
          rawSegmentA,
          splitPoint,
          segmentA[1]
        );
        if (rawNewSegmentAPt2) {
          const newSegmentAPt2 = mapper(rawNewSegmentAPt2);
          if (
            distance(newSegmentAPt2[0], newSegmentAPt2[1]) > minSegmentLength
          ) {
            splitLineSegments = [...splitLineSegments, rawNewSegmentAPt2];
          }
        }

        // Segment B:
        const rawNewSegmentBPt1 = createNewSegment(
          rawSegmentB,
          segmentB[0],
          splitPoint
        );
        if (rawNewSegmentBPt1) {
          const newSegmentBPt1 = mapper(rawNewSegmentBPt1);
          if (
            distance(newSegmentBPt1[0], newSegmentBPt1[1]) > minSegmentLength
          ) {
            splitLineSegments = [...splitLineSegments, rawNewSegmentBPt1];
          }
        }

        const rawNewSegmentBPt2 = createNewSegment(
          rawSegmentB,
          splitPoint,
          segmentB[1]
        );
        if (rawNewSegmentBPt2) {
          const newSegmentBPt2 = mapper(rawNewSegmentBPt2);
          if (
            distance(newSegmentBPt2[0], newSegmentBPt2[1]) > minSegmentLength
          ) {
            splitLineSegments = [...splitLineSegments, rawNewSegmentBPt2];
          }
        }

        const percentageComplete =
          ((i + j / splitLineSegments.length) / splitLineSegments.length) * 100;
        setParsingPercent(percentageComplete);
        continue outer;
      }
    }

    // All pairs of segments have finished processing!
    break;
  }

  return splitLineSegments;
}
