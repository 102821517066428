import { FloorplanCoordinates } from 'lib/geometry';
import Space from 'lib/space';

import {
  ObjectLayer,
  useFloorplanLayerContext,
  toRawHex,
  MetricLabel,
} from 'components/floorplan';

import { Purple400 } from '@density/dust/dist/tokens/dust.tokens';

const SPACE_LABELS_LAYER_INNER_BORDER_WIDTH_PX = 2;
const SPACE_LABELS_LAYER_OUTER_BORDER_WIDTH_PX = 2;
export const SpaceLabelsLayer: React.FunctionComponent<{
  spaces: Array<Space>;
  showSpaceName?: boolean;
  showSpacesEntry?: boolean;
  showSpacesOpenArea?: boolean;
  backgroundColor?: string;
  focusedObject?: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer' | 'threshold';
    id: string;
  };
}> = ({
  spaces,
  showSpaceName = false,
  showSpacesEntry = true,
  showSpacesOpenArea = true,
  backgroundColor = Purple400,
  focusedObject = null,
}) => {
  const context = useFloorplanLayerContext();
  const backgroundColorRaw = toRawHex(backgroundColor);

  return (
    <ObjectLayer
      objects={spaces}
      extractId={(space) => space.id}
      onCreate={(getSpace) => {
        const spaceNameLabel = new MetricLabel(getSpace().name, {
          backgroundColor: backgroundColorRaw,
          pinVertical: getSpace().shape.type === 'box' ? 'start' : 'middle',
          pinHorizontal: getSpace().shape.type === 'box' ? 'start' : 'middle',
        });

        return spaceNameLabel;
      }}
      onUpdate={(space: Space, spaceNameLabel: MetricLabel) => {
        if (!context.viewport.current) {
          return;
        }

        const isFocused =
          focusedObject &&
          focusedObject.type === 'space' &&
          focusedObject.id === space.id;

        if (!showSpacesOpenArea && space.countingMode === 'oa') {
          spaceNameLabel.renderable = false;
          return;
        }

        if (!showSpacesEntry && space.countingMode === 'entry') {
          spaceNameLabel.renderable = false;
          return;
        }

        // If name labels are hidden, skip rendering them
        spaceNameLabel.renderable = showSpaceName && !isFocused;
        if (!spaceNameLabel.renderable) {
          return;
        }

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          space.position,
          context.floorplan,
          context.viewport.current
        );

        spaceNameLabel.setText(space.name);

        if (spaceNameLabel.options.backgroundColor !== backgroundColorRaw) {
          spaceNameLabel.options.backgroundColor = backgroundColorRaw;
          spaceNameLabel.redrawTextBounds();
        }

        switch (space.shape.type) {
          case 'box': {
            // Show the metric label in the upper left of the box shape
            const widthPixels =
              space.shape.width *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const heightPixels =
              space.shape.height *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const upperLeftX = viewportCoords.x + (-1 * widthPixels) / 2;
            const upperLeftY = viewportCoords.y + (-1 * heightPixels) / 2;

            spaceNameLabel.x =
              upperLeftX + SPACE_LABELS_LAYER_INNER_BORDER_WIDTH_PX;
            spaceNameLabel.y =
              upperLeftY + SPACE_LABELS_LAYER_OUTER_BORDER_WIDTH_PX;
            break;
          }
          case 'circle': {
            // Show the metric label in the top middle of the circle
            const radiusPixels =
              space.shape.radius *
              context.floorplan.scale *
              context.viewport.current.zoom;

            spaceNameLabel.x = viewportCoords.x;
            spaceNameLabel.y = viewportCoords.y - radiusPixels;
            break;
          }
          case 'polygon': {
            // Render the metric label at the vertex with the smallest y value (ie, the uppermost
            // vertex in the polygon)
            const vertexHeights = space.shape.vertices.map(
              (vertex) => vertex.y
            );
            const highestVertex =
              space.shape.vertices[
                vertexHeights.indexOf(Math.min(...vertexHeights))
              ];
            const highestVertexViewport =
              FloorplanCoordinates.toViewportCoordinates(
                highestVertex,
                context.floorplan,
                context.viewport.current
              );

            spaceNameLabel.x = highestVertexViewport.x;
            spaceNameLabel.y = highestVertexViewport.y;
            break;
          }
        }
      }}
      onRemove={(_space, spaceNameLabel) => spaceNameLabel.destroy(true)}
    />
  );
};

export default SpaceLabelsLayer;
