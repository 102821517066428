import { createContext, useRef, useState } from 'react';

const initialState = {
  buildingName: '',
  setBuildingName: (name: string) => {},
  dateString: '',
};

const BuildingContext = createContext(initialState);

export const BuildingProvider: React.FunctionComponent = ({ children }) => {
  const [buildingName, setBuildingName] = useState<string>('');
  const dateString = useRef<string>(
    new Date()
      .toISOString()
      .replace(/[:.]/g, '-')
      .replace('T', '_')
      .slice(0, 19)
  );
  return (
    <BuildingContext.Provider
      value={{
        buildingName,
        setBuildingName,
        dateString: dateString.current,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
};

export { BuildingContext };
