import React from 'react';
import styles from './styles.module.scss';

import LoadingProgressCircle from 'components/loading-progress-circle';

const ContentMessage: React.FunctionComponent<{
  header?: string;
  content: string;
  icon?: React.ReactNode;
  messageType: string;
  loadingStep?: number;
}> = ({ header, content, messageType, loadingStep = 0, icon }) => {
  return (
    <div>
      {messageType === 'loading' ? (
        <div className={styles.contentMessage}>
          <div className={styles.contentMessageHeader}>
            <div className={styles.contentMessageHeaderContent}>{header}</div>
            <div className={styles.contentMessageLoadingIndicator}>
              <LoadingProgressCircle
                numerator={loadingStep}
                denominator={100}
              />
            </div>
          </div>
          <div className={styles.contentMessageBody}>{content}</div>
        </div>
      ) : null}
      {messageType === 'normal' ? (
        <div className={styles.contentMessage}>
          {header ? (
            <div className={styles.contentMessageHeader}>
              {icon ? (
                <span className={styles.contentMessageHeaderIcon}>{icon}</span>
              ) : null}
              {header}
            </div>
          ) : null}
          <div className={styles.contentMessageBody}>{content}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ContentMessage;
