import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

import { useTheme } from 'components/theme';

const BUTTON_TYPE_CLASSES = {
  filled: styles.typeFilled,
  outlined: styles.typeOutlined,
  hollow: styles.typeHollow,
  cleared: styles.typeCleared,
  link: styles.typeLink,
};

const BUTTON_SIZE_CLASSES = {
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
  nano: styles.sizeNano,
};

const BUTTON_THEME_CLASSES = {
  light: styles.light,
  dark: styles.dark,
};

type ButtonPropsBase = {
  size?: 'nano' | 'small' | 'medium' | 'large';
  type?: 'filled' | 'outlined' | 'hollow' | 'cleared' | 'link';
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;

  disabled?: boolean;
  active?: boolean;

  buttonType?: 'button' | 'submit' | 'reset';

  width?: React.ReactText;
  round?: boolean;

  danger?: boolean;
  ellipseOverflowText?: boolean;
  'data-cy'?: string;
};

type NativeButtonProps = React.HTMLProps<HTMLButtonElement>;

// Allow passing native button props, but Omit those that we define in ButtonPropsBase so they don't conflict
type ButtonProps = Omit<NativeButtonProps, keyof ButtonPropsBase> &
  ButtonPropsBase;

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    size = 'large',
    type = 'filled',
    leadingIcon = null,
    trailingIcon = null,

    disabled = false,
    active = false,

    buttonType,
    round,
    width,
    danger,
    ellipseOverflowText,

    children,
    ...restProps
  } = props;
  const dataCy = props['data-cy'];

  const theme = useTheme();
  return (
    <button
      {...restProps}
      disabled={disabled}
      type={buttonType || 'button'}
      className={classnames(
        styles.button,
        BUTTON_TYPE_CLASSES[type],
        BUTTON_SIZE_CLASSES[size],
        BUTTON_THEME_CLASSES[theme],
        {
          [styles.round]: round,
          [styles.danger]: danger,
          [styles.active]: active,
        }
      )}
      style={{ width, maxWidth: ellipseOverflowText ? '90%' : undefined }}
      data-cy={dataCy}
    >
      {leadingIcon ? (
        <div className={styles.leadingIcon}>{leadingIcon}</div>
      ) : null}

      {children ? (
        <div
          className={styles.buttonText}
          style={
            ellipseOverflowText
              ? {
                  whiteSpace: 'nowrap' as 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }
              : undefined
          }
        >
          {children}
        </div>
      ) : null}
      {trailingIcon ? (
        <div className={styles.trailingIcon}>{trailingIcon}</div>
      ) : null}
    </button>
  );
};

export default Button;
