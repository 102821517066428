import { Fragment, useRef, useMemo, useState, useEffect } from 'react';
import * as React from 'react';
import { Icons } from '@density/dust';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

import styles from '../styles.module.scss';

import NewBaseImageLayer from './new-base-image-layer';
import ImportPanel, { PlanDXFImportOptions } from './import-panel';
import {
  SpacesDiffLayer,
  SensorsDiffLayer,
  AreasOfConcernDiffLayer,
} from './diff-layers';

import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import Floorplan from 'components/floorplan';
import FloorplanZoomControls from 'components/floorplan-zoom-controls';
import Panel from 'components/panel';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import SupplementaryActionsLayer from 'components/editor/action-layers/actions-layer';

import { ParsedPlanDXF, PlanDXF } from 'lib/dxf';
import { LengthUnit } from 'lib/units';
import FloorplanType from 'lib/floorplan';
import {
  FloorplanCoordinates,
  CADCoordinates,
  ImageCoordinates,
} from 'lib/geometry';
import PlanSensor from 'lib/sensor';
import AreaOfConcern from 'lib/area-of-concern';
import Space from 'lib/space';
import { TargetLayers } from 'lib/dxf';
import {
  FloorplanSensorChange,
  FloorplanSpaceChange,
  FloorplanAreaOfConcernChange,
  computeDefaultCADOrigin,
  computeFloorplanSensorChanges,
  computeFloorplanSpacesChanges,
  computeFloorplanAreasOfConcernChanges,
  DEFAULT_CAD_FILE_LENGTH_UNIT,
} from 'lib/cad';
import { FloorplanV2Plan, FloorplanAPI } from 'lib/api';

import { FixMe } from 'types/fixme';

export const uploadDXFFile = (
  floorId: FloorplanV2Plan['floor']['id'],
  planId: FloorplanV2Plan['id'],
  client: AxiosInstance,
  file: File,
  onBeginUpload: () => void = () => {},
  onUploadProgress: (percent: number) => void = () => {},
  onUploadError: (err: Error) => void = () => {},
  onUploadComplete: (planDXF: PlanDXF) => void = () => {}
) => {
  onBeginUpload();

  // Read file contents
  const reader = new FileReader();
  reader.onload = async (e) => {
    if (!e.target) {
      return;
    }
    const fileContents = e.target.result;
    if (!fileContents || typeof fileContents === 'string') {
      return;
    }

    // Upload DXF
    let signedUrlResponse: AxiosResponse<{ key: string; signed_url: string }>;
    try {
      signedUrlResponse = await FloorplanAPI.imageUpload(client, {
        floor_id: floorId,
        ext: 'dxf',
        content_type: 'application/dxf',
      });
    } catch (err) {
      toast.error('Error uploading DXF!');
      return;
    }
    if (signedUrlResponse.status !== 201) {
      toast.error('Error uploading DXF!');
      return;
    }

    const { key: objectKey, signed_url: signedUrl } = signedUrlResponse.data;

    try {
      await axios.put(signedUrl, fileContents, {
        headers: {
          'Content-Type': 'application/dxf',
        },
        onUploadProgress: (event) => {
          if (typeof event.total === 'undefined') {
            return;
          }
          const percent = (event.loaded / event.total) * 100;
          onUploadProgress(percent);
        },
      });
    } catch (err) {
      console.warn('Error uploading dxf:', err);
      onUploadError(err as Error);
      return;
    }

    // Create the dxf on the serverside using the newly uploaded file
    let createPlanDXFResponse;
    try {
      createPlanDXFResponse = await FloorplanAPI.createAndProcessDXF(
        client,
        planId,
        objectKey
      );
    } catch (err) {
      console.warn('Error creating DXF!', err);
      onUploadError(err as Error);
      return;
    }
    onUploadComplete(createPlanDXFResponse.data);
  };
  reader.onerror = () => {
    toast.error('Error reading DXF file!');
  };
  reader.readAsArrayBuffer(file);
};

const DiffOperationIndicator: React.FunctionComponent<{
  type: FloorplanSensorChange['type'];
}> = ({ type }) => {
  const [Icon, className] = {
    addition: [Icons.Plus, styles.addition],
    deletion: [Icons.Close, styles.deletion],
    modification: [Icons.SwapHorizontalArrow, styles.modification],
    'no-change': [Icons.Minus, styles.noChange],
  }[type];

  return (
    <div className={classnames(styles.operationTypeIndicator, className)}>
      <Icon size={12} />
    </div>
  );
};

// This component renders a legend in the lower right hand corner clarifying the sensor diff colors
const CADImportLegend: React.FunctionComponent<{
  sensorsChanges: Array<FloorplanSensorChange>;
  areasOfConcernChanges: Array<FloorplanAreaOfConcernChange>;
}> = ({ sensorsChanges, areasOfConcernChanges }) => {
  // Compute the number of each type of change shown to the user
  const counts = useMemo(() => {
    let counts = { addition: 0, deletion: 0, modification: 0, 'no-change': 0 };

    for (let item of sensorsChanges) {
      counts[item.type] += 1;
    }

    for (let item of areasOfConcernChanges) {
      counts[item.type] += 1;
    }

    return counts;
  }, [sensorsChanges, areasOfConcernChanges]);

  return (
    <Panel position="top-right" width={150} top={8}>
      <table className={styles.legendTable}>
        <tbody>
          {counts.addition > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="addition" />
              </td>
              <td>Added</td>
              <td>
                <span className={styles.tag}>{counts.addition}</span>
              </td>
            </tr>
          ) : null}
          {counts.deletion > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="deletion" />
              </td>
              <td>Removed</td>
              <td>
                <span className={styles.tag}>{counts.deletion}</span>
              </td>
            </tr>
          ) : null}
          {counts.modification > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="modification" />
              </td>
              <td>Changed</td>
              <td>
                <span className={styles.tag}>{counts.modification}</span>
              </td>
            </tr>
          ) : null}
          {counts['no-change'] > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="no-change" />
              </td>
              <td>Unchanged</td>
              <td>
                <span className={styles.tag}>{counts['no-change']}</span>
              </td>
            </tr>
          ) : null}
          <tr className={styles.totalRow}>
            <td></td>
            <td>Total Sensors</td>
            <td>
              <span className={styles.tag}>{sensorsChanges.length}</span>
            </td>
          </tr>
          <tr className={styles.totalRow}>
            <td></td>
            <td>Total Areas</td>
            <td>
              <span className={styles.tag}>{areasOfConcernChanges.length}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>
  );
};

type CADImportCreationProps = {
  mode: 'create';
  cadFileUnit: LengthUnit | null;
  planDXF: ParsedPlanDXF;
  onChangeCADFileUnit: (unit: LengthUnit) => void;
  cadFileScale: number;
  onChangeCADFileScale: (newScale: number) => void;
  displayUnit: LengthUnit;
  pixelsPerCADUnit: number;

  onSubmit: (
    sensorChanges: Array<FloorplanSensorChange>,
    spaceChanges: Array<FloorplanSpaceChange>,
    aocChanges: Array<FloorplanAreaOfConcernChange>,
    floorplan: FloorplanType,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number,
    pixelsPerCADUnit: number,
    imports: PlanDXFImportOptions
  ) => void;
  onCancel: () => void;
  onReprocessDXF: (layersOfInterest: TargetLayers) => void;
};

type CADImportUpdateProps = {
  mode: 'update';
  cadFileUnit: LengthUnit | null;
  planDXF: ParsedPlanDXF;
  onChangeCADFileUnit: (unit: LengthUnit) => void;
  cadFileScale: number;
  onChangeCADFileScale: (newScale: number) => void;
  displayUnit: LengthUnit;
  pixelsPerCADUnit: number;

  image: HTMLImageElement;
  spaces: Array<Space>;
  planSensors: Array<PlanSensor>;
  areasOfConcern: Array<AreaOfConcern>;
  initialFloorplan: FloorplanType;
  floorplanCADOrigin: FloorplanCoordinates;

  onDragMoveFloorplanCADOrigin: (coords: FloorplanCoordinates) => void;

  onSubmit: (
    sensorChanges: Array<FloorplanSensorChange>,
    spaceChanges: Array<FloorplanSpaceChange>,
    aocChanges: Array<FloorplanAreaOfConcernChange>,
    floorplan: FloorplanType,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number,
    pixelsPerCADUnit: number,
    imports: PlanDXFImportOptions
  ) => void;
  onCancel: () => void;
  onReprocessDXF: (layersOfInterest: TargetLayers) => void;
};

const CADImport: React.FunctionComponent<
  CADImportCreationProps | CADImportUpdateProps
> = (props) => {
  const floorplanRef = useRef<FixMe | null>(null);
  const [newBaseImage, setNewBaseImage] = useState<HTMLImageElement | null>(
    null
  );

  // User decides which entities to import. Think "import options".
  // Defaults:
  // If there is nothing to import in the selected layer, default import to false.
  const [imports, setImports] = useState<PlanDXFImportOptions>({
    importFloorplan: props.mode === 'create' ? true : false,
    importSensors: props.planDXF.sensorPlacements.length > 0 ? true : false,
    importSensorsKeepExisting: false,
    importAreasOfConcern: props.planDXF.areasOfConcern.unmerged.length
      ? true
      : false,
    importSpaces: props.planDXF.spaces.length > 0 ? true : false,
  });

  // User specifies which layers to look for entities on.
  const [layersOfInterest, setLayersOfInterest] = useState<TargetLayers>(
    props.planDXF.layersOfInterest
  );

  const [scaleByDrawActive, setScaleByDraw] = useState<boolean>(false);

  // If the layer selections changed, signal to user they may want to reprocess the .dxf.
  const [userChangedLayerSelections, setUserChangedLayerSelections] =
    useState<boolean>(false);

  useEffect(() => {
    // When user changed target layer selections - for example, they excluded layers
    // from the raster render or chose another layer for sensors import,
    // compare the new selections with original import settings and surface a "reprocess"
    // button if the rendering is not up to date.

    let change = false;
    if (
      layersOfInterest.oaSensors !== props.planDXF.layersOfInterest.oaSensors
    ) {
      if (imports.importSensors) {
        change = true;
      }
    } else if (
      layersOfInterest.entrySensors !==
      props.planDXF.layersOfInterest.entrySensors
    ) {
      if (imports.importSensors) {
        change = true;
      }
    } else if (
      layersOfInterest.oaSpaces !== props.planDXF.layersOfInterest.oaSpaces ||
      layersOfInterest.oaSpaceNames !==
        props.planDXF.layersOfInterest.oaSpaceNames
    ) {
      if (imports.importSpaces) {
        change = true;
      }
    } else if (
      layersOfInterest.entrySpaces !==
        props.planDXF.layersOfInterest.entrySpaces ||
      layersOfInterest.entrySpaceNames !==
        props.planDXF.layersOfInterest.entrySpaceNames
    ) {
      if (imports.importSpaces) {
        change = true;
      }
    } else if (
      props.planDXF.layersOfInterest.excludedFromRasterImage.length !==
      layersOfInterest.excludedFromRasterImage.length
    ) {
      // TODO: This should be a more proper key by key comparison.
      change = true;
    }

    if (change) {
      setUserChangedLayerSelections(true);
      return;
    }

    setUserChangedLayerSelections(false);
  }, [
    props.planDXF.layersOfInterest,
    layersOfInterest,
    imports.importSensors,
    imports.importSpaces,
    imports.importAreasOfConcern,
  ]);

  useEffect(() => {
    // When the component mounts, fetch the dxf's raster image.
    const image = new Image();
    for (const asset of props.planDXF.assets) {
      if (asset.name === 'full_image' && asset.content_type === 'image/png') {
        image.src = asset.object_url;
        image.onload = () => {
          setNewBaseImage(image);
        };
        break;
      }
    }
  }, [props.planDXF]);

  if (!newBaseImage) {
    return <LoadingOverlay text="Loading base image..." />;
  }

  const cadFileUnit: LengthUnit =
    props.cadFileUnit ||
    props.planDXF.lengthUnit ||
    DEFAULT_CAD_FILE_LENGTH_UNIT;

  const floorplan =
    props.mode === 'create'
      ? (() => {
          const initialFloorplan = {
            width: newBaseImage.width,
            height: newBaseImage.height,
            scale: 1,
            origin: ImageCoordinates.create(0, 0),
            rotation: 0,
          };

          // Calcualte the initial scale for the floorplan
          const coordA = CADCoordinates.toFloorplanCoordinates(
            CADCoordinates.create(0, 0),
            initialFloorplan,
            FloorplanCoordinates.create(0, 0),
            cadFileUnit,
            props.cadFileScale
          );
          const coordB = CADCoordinates.toFloorplanCoordinates(
            CADCoordinates.create(1, 0),
            initialFloorplan,
            FloorplanCoordinates.create(0, 0),
            cadFileUnit,
            props.cadFileScale
          );
          const metersPerCADUnit = coordB.x - coordA.x;
          const ratio = metersPerCADUnit / props.pixelsPerCADUnit;
          const cadImageScale = ratio * initialFloorplan.scale;

          return { ...initialFloorplan, scale: 1 / cadImageScale };
        })()
      : props.initialFloorplan;

  const floorplanCADOriginOrDefault =
    props.mode === 'create'
      ? computeDefaultCADOrigin(floorplan)
      : props.floorplanCADOrigin;

  const floorplanSensorsChanges = computeFloorplanSensorChanges(
    imports.importSensors,
    imports.importSensorsKeepExisting,
    props.planDXF.sensorPlacements,
    props.mode === 'create' ? [] : props.planSensors,
    floorplan,
    floorplanCADOriginOrDefault,
    cadFileUnit,
    props.cadFileScale
  );

  const floorplanAreasOfConcernChanges = computeFloorplanAreasOfConcernChanges(
    imports.importAreasOfConcern,
    props.planDXF.areasOfConcern.merged, // merged or unmerged?,
    props.mode === 'create' ? [] : props.areasOfConcern,
    floorplan,
    floorplanCADOriginOrDefault,
    cadFileUnit,
    props.cadFileScale
  );

  const floorplanSpacesChanges = computeFloorplanSpacesChanges(
    imports.importSpaces,
    props.planDXF.spaces,
    props.mode === 'create' ? [] : props.spaces,
    floorplan,
    floorplanCADOriginOrDefault,
    cadFileUnit,
    props.cadFileScale
  );

  const numberOfRealFloorplanChanges =
    floorplanSensorsChanges.filter((i) => i.type !== 'no-change').length +
    floorplanSpacesChanges.filter((i) => i.type !== 'no-change').length +
    floorplanAreasOfConcernChanges.filter((i) => i.type !== 'no-change').length;

  let submitText = props.mode === 'create' ? 'Create' : 'Update';
  let submitDisabled = false;

  if (numberOfRealFloorplanChanges === 0)
    if (imports.importFloorplan) {
      // If there are no object changes, we're importing/swapping the floorplan
      // or not doing anything at all, in which case we disable the submit button
      if (props.mode === 'create') {
        submitText = 'Import the floorplan image only';
      } else {
        submitText = 'Swap the floorplan image';
      }
    } else {
      if (props.mode === 'create') {
        submitText = 'Nothing to import';
      } else {
        submitText = 'No changes found';
      }
      submitDisabled = true;
    }
  else {
    if (props.mode === 'create') {
      submitText = 'Import the selected entities';
    } else {
      submitText = 'Swap the selected entities';
    }

    if (imports.importFloorplan) {
      submitText += ' and the floorplan image';
    }
  }

  return (
    <Fragment>
      <DarkTheme>
        <AppBar
          title={
            props.mode === 'create' ? 'Create Floorplan' : 'Preview Changes'
          }
          actions={
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="cleared"
                data-cy="cad-import-cancel"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                data-cy="cad-import-submit"
                onClick={() => {
                  props.onSubmit(
                    floorplanSensorsChanges,
                    floorplanSpacesChanges,
                    floorplanAreasOfConcernChanges,
                    floorplan,
                    floorplanCADOriginOrDefault,
                    cadFileUnit,
                    props.cadFileScale,
                    props.pixelsPerCADUnit,
                    imports
                  );
                }}
                disabled={submitDisabled}
              >
                {submitText}
              </Button>
            </HorizontalForm>
          }
        />
      </DarkTheme>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Floorplan
            ref={floorplanRef}
            image={props.mode === 'create' ? newBaseImage : props.image}
            floorplan={floorplan}
            width="100%"
            height="100%"
            lengthUnit={'feet_and_inches'}
          >
            {props.mode === 'update' ? (
              <Fragment>
                <NewBaseImageLayer
                  image={newBaseImage}
                  floorplanCADOrigin={props.floorplanCADOrigin}
                  cadFileUnit={cadFileUnit}
                  cadFileScale={props.cadFileScale}
                  pixelsPerCADUnit={props.pixelsPerCADUnit}
                  onDragMove={props.onDragMoveFloorplanCADOrigin}
                  interactive={!scaleByDrawActive}
                />
              </Fragment>
            ) : null}

            <AreasOfConcernDiffLayer
              floorplanAreasOfConcernChanges={floorplanAreasOfConcernChanges}
              floorplanCADOrigin={floorplanCADOriginOrDefault}
              cadFileUnit={cadFileUnit}
              cadFileScale={props.cadFileScale}
            />
            <SensorsDiffLayer
              floorplanSensorsChanges={floorplanSensorsChanges}
              floorplanCADOrigin={floorplanCADOriginOrDefault}
              cadFileUnit={cadFileUnit}
              cadFileScale={props.cadFileScale}
            />
            <SpacesDiffLayer
              floorplanSpacesChanges={floorplanSpacesChanges}
              floorplanCADOrigin={floorplanCADOriginOrDefault}
              cadFileUnit={cadFileUnit}
              cadFileScale={props.cadFileScale}
            />
            {scaleByDrawActive && props.mode === 'update' ? (
              <SupplementaryActionsLayer
                type={'scale'}
                onScalingCompleted={({
                  scale,
                  rotation,
                  translationX,
                  translationY,
                }) => {
                  const newScale = props.cadFileScale * scale;
                  props.onChangeCADFileScale(newScale);
                  // TODO: This scales the floorplan only, implement translation + rotation
                  setScaleByDraw(false);
                }}
              />
            ) : null}
          </Floorplan>
        </div>

        <ImportPanel
          mode={props.mode}
          floorplan={floorplan}
          floorplanSensorsChanges={floorplanSensorsChanges}
          floorplanSpacesChanges={floorplanSpacesChanges}
          floorplanAreasOfConcernChanges={floorplanAreasOfConcernChanges}
          loading={false}
          imports={imports}
          onImportsChanged={(newImports) => setImports(newImports)}
          layerNames={props.planDXF.layersAll}
          frozenLayerNames={props.planDXF.layersFrozen}
          layersOfInterest={layersOfInterest}
          onLayersOfInterestChanged={(newLayersOfInterest) =>
            setLayersOfInterest(newLayersOfInterest)
          }
          displayUnit={props.displayUnit}
          floorplanCADOrigin={floorplanCADOriginOrDefault}
          onChangeFloorplanCADOrigin={
            props.mode === 'update' ? props.onDragMoveFloorplanCADOrigin : null
          }
          cadFileUnit={cadFileUnit}
          onChangeCADFileUnit={props.onChangeCADFileUnit}
          cadFileScale={props.cadFileScale}
          pixelsPerCADUnit={props.pixelsPerCADUnit}
          onChangeCADFileScale={props.onChangeCADFileScale}
          userChangedLayerSelections={userChangedLayerSelections}
          onReprocessDXF={props.onReprocessDXF}
          scaleByDrawActive={scaleByDrawActive}
          setScaleByDraw={(scaleByDrawActive) =>
            setScaleByDraw(scaleByDrawActive)
          }
        />

        <CADImportLegend
          sensorsChanges={floorplanSensorsChanges}
          areasOfConcernChanges={floorplanAreasOfConcernChanges}
        />

        <FloorplanZoomControls
          onZoomToFitClick={() => {
            if (floorplanRef.current) {
              floorplanRef.current.zoomToFitWithSidebar(600);
            }
          }}
        />
      </div>
    </Fragment>
  );
};

export default CADImport;
