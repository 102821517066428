import { AsyncThunkOptions } from '@reduxjs/toolkit';
import { AppThunkApiConfig } from 'redux/store';
import produce from 'immer';
import { FixMe } from 'types/fixme';

// ref: https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/core/apiState.ts#L34
// https://redux-toolkit.js.org/rtk-query/usage/queries#query-loading-state
export enum AsyncTaskStatus {
  Uninitialized = 'uninitialized',
  Loading = 'loading', // query being in flight for the first time
  Fetching = 'fetching', // query being in flight but not nessarily for the first time
  Success = 'success',
  Failure = 'failure',
}

// a more flexible type here rather than a strict discriminated union type
// following the example of redux query network states
// for example: it is possible to have data when the the request is pending
// TODO(wuweiweiwu): this may be too flexible. evaluate after usage to see if the types need to be stricter
export type AsyncTaskState<TData> = {
  id?: string;
  data?: TData;
  error?: unknown;
  status: AsyncTaskStatus;
};

export const getInitialAsyncTaskState = <TData>(
  initialData?: TData
): AsyncTaskState<TData> => {
  return {
    data: initialData,
    status: AsyncTaskStatus.Uninitialized,
    error: undefined,
  };
};

export const isAsyncTaskMatched = <TData>(
  asyncState: AsyncTaskState<TData>,
  requestId: string
) => {
  return asyncState.id === requestId;
};

export const isAsyncTaskUninitialized = <TData>(
  asyncState: AsyncTaskState<TData>
) => {
  return asyncState.status === AsyncTaskStatus.Uninitialized;
};

export const isAsyncTaskLoading = <TData>(
  asyncState: AsyncTaskState<TData>
) => {
  return asyncState.status === AsyncTaskStatus.Loading;
};

export const isAsyncTaskFetching = <TData>(
  asyncState: AsyncTaskState<TData>
) => {
  return asyncState.status === AsyncTaskStatus.Fetching;
};

export const isAsyncTaskSuccess = <TData>(
  asyncState: AsyncTaskState<TData>
) => {
  return asyncState.status === AsyncTaskStatus.Success;
};

export const isAsyncTaskFailure = <TData>(
  asyncState: AsyncTaskState<TData>
) => {
  return asyncState.status === AsyncTaskStatus.Failure;
};

// this is a helper to help reduce loading indicator jank
// since we dispatch api calls in effect hooks, there will always be
// a slight delay where the task is still uninitialized but is also not fetching
// see https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/react/buildHooks.ts#L484
export const isAsyncTaskUninitializedOrFetching = <TData>(
  asyncState: AsyncTaskState<TData>
) => {
  return isAsyncTaskLoading(asyncState) || isAsyncTaskUninitialized(asyncState);
};

// see https://redux-toolkit.js.org/usage/usage-with-typescript#createaction
// so we can narrow the action type
export function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export const AVAILABILITY_SOCKET_PREFIX = 'availability';
export const OA_LIVE_SOCKET_PREFIX = 'oa-live';

// this is used for createAsyncThunk options
// to not serialize errors so sentry can correctly give us stack traces
export const defaultAppThunkOptions: AsyncThunkOptions<any, AppThunkApiConfig> =
  {
    serializeError: (err) => err,
  };

// some utils for common stale-while-revalidating patterns with createAsyncThunk
// only meant to be used within reducer builder functions
// referencing https://github.com/reduxjs/redux-toolkit/blob/fee16b95c5/packages/toolkit/src/entities/sorted_state_adapter.ts
// except we are returning a new state always rather than allowing mutable updates

export const hasValidPendingState = <TData>(
  asyncState: AsyncTaskState<TData>,
  requestId: string
) => {
  return (
    (isAsyncTaskLoading(asyncState) || isAsyncTaskFetching(asyncState)) &&
    isAsyncTaskMatched(asyncState, requestId)
  );
};

export const getPendingState = <TData>(
  asyncState: AsyncTaskState<TData>,
  requestId: string,
  options?: {
    // always force a brand new fetch
    force?: boolean;

    // allow use to check other places other than async state for data
    hasData?: boolean;
  }
): AsyncTaskState<TData> => {
  return produce(asyncState, (draft) => {
    if ((!draft.data && !options?.hasData) || options?.force) {
      return {
        id: requestId,
        status: AsyncTaskStatus.Loading,
      };
    }

    // don't reset the whole state since when we're refetching we may have previous data
    draft.id = requestId;
    draft.status = AsyncTaskStatus.Fetching;
  });
};

export const getFulfilledState = <TData>(
  asyncState: AsyncTaskState<TData>,
  requestId: string,
  payload: TData
): AsyncTaskState<TData> => {
  return produce(asyncState, (draft) => {
    if (hasValidPendingState(draft, requestId)) {
      return {
        status: AsyncTaskStatus.Success,
        data: payload as FixMe,
      };
    }
  });
};

export const getRejectedState = <TData>(
  asyncState: AsyncTaskState<TData>,
  requestId: string,
  error: unknown
): AsyncTaskState<TData> => {
  return produce(asyncState, (draft) => {
    if (hasValidPendingState(draft, requestId)) {
      return {
        status: AsyncTaskStatus.Failure,
        error,
      };
    }
  });
};
