export const MAX_IMAGE_WIDTH = 4096;

// Given an image, resize it so that it's no wider than `maxWidth` pixels
// Returns the image resized, as well as the scale factor the image was resized to for further math
// later on
export function resizeImageToMaxSize(
  image: HTMLImageElement,
  maxWidth: number = MAX_IMAGE_WIDTH
): [HTMLImageElement, number] {
  const imageWidth = Math.min(maxWidth, image.width);
  const imageResizeScale = imageWidth / image.width;

  const canvas = document.createElement('canvas');

  canvas.width = image.width * imageResizeScale;
  canvas.height = image.height * imageResizeScale;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Unable to get 2d context from canvas!');
  }

  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const resizedImage = new Image();
  resizedImage.src = canvas.toDataURL('image/png');

  return [resizedImage, imageResizeScale];
}
