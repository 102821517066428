import {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  MouseEvent,
} from 'react';
import Modal from 'components/modal';
import AppBar from 'components/app-bar';
import TextField from 'components/text-field';
import styles from './styles.module.scss';
import EditableAddress from 'components/editable-address';
import { AxiosInstance } from 'axios';
import SelectField from 'components/select-field';
import Button from 'components/button';
import { z } from 'zod';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { V2Building } from './building-manager';
import { MapboxSessionProvider } from 'components/mapbox/mapbox-session';
import { CoreAPI } from 'lib/api';
import { LabelFrame } from 'components/label-frame';
import { toast } from 'react-toastify';
import { Icons } from '@density/dust';
import { MAPBOX_TOKEN } from 'config';

type BuildingSettingsModalProps = {
  visible: true | false;
  title: string;
  building: V2Building;
  client: AxiosInstance | null | undefined;
  onDismiss: () => void;
  updateSpace: (data: FormFields) => Promise<Error | undefined>;
};

interface FormFields {
  name: string;
  id: string;
  address: string | null;
  status: string | null;
  capacity: number | null;
  target_capacity: number | null;
  cost_per_sqft: number | null;
  cost_per_sqft_currency: string | null;
  iwms_id: string | null;
  counting_mode: string | null;
  daily_reset: string | null;
  time_zone: string | null;
  updated_at: string | null;
  created_at: string | null;
  longitude: number | null;
  latitude: number | null;
}

type MetadataField = {
  id: keyof FormFields;
  label: string;
  editable: boolean;
  type: string;
  choices?: { label: string; id: string | null }[];
  helper?: keyof FormFields;
  category: string;
};

const METADATA_FIELDS: MetadataField[] = [
  {
    id: 'id',
    label: 'Building ID',
    editable: false,
    type: 'string_copy',
    category: 'id',
  },
  { id: 'name', label: 'Name', editable: true, type: 'string', category: 'id' },
  {
    id: 'address',
    label: 'Address',
    editable: true,
    type: 'address',
    category: 'location',
  },
  {
    id: 'status',
    label: 'Status',
    editable: true,
    type: 'list',
    choices: [
      { id: 'planning', label: 'Planning' },
      { id: 'live', label: 'Live' },
    ],
    category: 'configuration',
  },
  {
    id: 'time_zone',
    label: 'Time zone',
    editable: false,
    type: 'timeZone',
    category: 'location',
  },
  {
    id: 'updated_at',
    label: 'Updated at',
    editable: false,
    type: 'date',
    category: 'audit',
  },
  {
    id: 'capacity',
    label: 'Capacity',
    editable: true,
    type: 'number',
    category: 'capacity',
  },
  {
    id: 'target_capacity',
    label: 'Target capacity',
    editable: true,
    type: 'number',
    category: 'capacity',
  },
  {
    id: 'cost_per_sqft',
    label: 'Cost per sqft',
    editable: true,
    type: 'cost',
    category: 'cost',
  },
  {
    id: 'cost_per_sqft_currency',
    label: 'Cost per sqft currency',
    editable: false,
    type: 'currency',
    category: 'cost',
  },
  {
    id: 'counting_mode',
    label: 'Counting mode',
    editable: true,
    type: 'list',
    choices: [
      { id: null, label: 'None' },
      { id: 'oa', label: 'Open Area' },
      { id: 'entry', label: 'Entry' },
    ],
    category: 'configuration',
  },
  {
    id: 'created_at',
    label: 'Created at',
    editable: false,
    type: 'date',
    category: 'audit',
  },
  {
    id: 'daily_reset',
    label: 'Daily reset',
    editable: true,
    type: 'time',
    category: 'configuration',
  },
  {
    id: 'iwms_id',
    label: 'IWMS ID',
    editable: true,
    type: 'string',
    category: 'id',
  },
  {
    id: 'latitude',
    label: 'Latitude',
    editable: false,
    type: 'string',
    category: 'location',
  },
  {
    id: 'longitude',
    label: 'Longitude',
    editable: false,
    type: 'string',
    category: 'location',
  },
];

// used to determine input type for the input component
const fieldTypeMap: { [key: string]: string } = {
  address: 'string',
  cost: 'number',
  currency: 'currency',
  timeZone: 'string',
  date: 'string',
  string_copy: 'string',
};

const formSchema = z.object({
  name: z.string().min(1, { message: 'Building must have a name' }),
  address: z.string().or(z.null()),
  status: z.enum(['planning', 'live']),
  capacity: z
    .number()
    .int()
    .min(1, { message: 'Must be a positive number empty' })
    .or(z.null()),
  target_capacity: z
    .number()
    .int()
    .min(1, { message: 'Must be a positive number or empty' })
    .or(z.null()),
  cost_per_sqft: z
    .number()
    .min(0, { message: 'Must be a positive number or empty' })
    .or(z.null()),
  iwms_id: z.string().or(z.null()),
  time_zone: z.string().or(z.null()),
  counting_mode: z.string().or(z.null()),
  daily_reset: z.string().or(z.null()),
  longitude: z.number().or(z.null()),
  latitude: z.number().or(z.null()),
});

export const BuildingSettingsModal: React.FunctionComponent<BuildingSettingsModalProps> =
  ({ visible, onDismiss, title, building, client, updateSpace }) => {
    const initialBuildingData: FormFields = useMemo(() => {
      return {
        id: '',
        name: '',
        address: null,
        capacity: null,
        target_capacity: null,
        cost_per_sqft_currency: 'USD',
        iwms_id: null,
        counting_mode: null,
        daily_reset: '04:00:00',
        cost_per_sqft: null,
        status: 'planning',
        time_zone: null,
        updated_at: null,
        created_at: null,
        longitude: null,
        latitude: null,
      };
    }, []);

    const {
      control,
      handleSubmit,
      getValues,
      setValue,
      reset,
      formState: { errors },
    } = useForm({
      resolver: zodResolver(formSchema),
      defaultValues: {
        ...initialBuildingData,
      },
    });
    const [buildingData, setBuildingData] =
      useState<FormFields>(initialBuildingData);

    const [mapSrc, setMapSrc] = useState('');

    const generateMapSrc = (lon: number | null, lat: number | null) => {
      if (!lon || !lat) return;
      setMapSrc(
        `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s+ff0000(${lon},${lat})/${lon},${lat},6.00,0/650x150?access_token=${MAPBOX_TOKEN}&logo=false`
      );
    };
    const getSpaceData = useCallback(
      (abortController: AbortController) => {
        if (!building || !client) return;
        CoreAPI.getSpace(client, building.id, abortController.signal).then(
          (response) => {
            const data = response.data;
            const spaceData = {
              id: data.id,
              name: data.name,
              address: data.address || '',
              time_zone: data.time_zone,
              updated_at: data.updated_at,
              counting_mode: data.counting_mode,
              created_at: data.created_at,
              longitude: data.longitude,
              latitude: data.latitude,
              iwms_id: data.iwms_id,
              daily_reset: data.daily_reset,
              cost_per_sqft: data.cost_per_sqft,
              cost_per_sqft_currency: data.cost_per_sqft_currency,
              capacity: data.capacity,
              target_capacity: data.target_capacity,
              status: data.status,
            };
            // TODO: Move this to the API module
            if (
              (!response.data.longitude || !response.data.latitude) &&
              spaceData.address
            ) {
              fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                  spaceData.address
                )}.json?access_token=${MAPBOX_TOKEN}`
              )
                .then((response) => response.json())
                .then((data) => {
                  spaceData.longitude = data.features[0].center[0];
                  spaceData.latitude = data.features[0].center[1];
                  generateMapSrc(spaceData.longitude, spaceData.latitude);
                  reset(spaceData);
                  setBuildingData({ ...spaceData });
                })
                .catch((err: Error) => console.error(err));
            } else {
              generateMapSrc(spaceData.longitude, spaceData.latitude);
              reset(spaceData);
              setBuildingData({ ...spaceData });
            }
          }
        );
      },
      [client, building, reset]
    );

    const countingMode = useWatch({ control, name: 'counting_mode' });

    const buildingInfo = (
      <LabelFrame label="Info" type="top">
        {METADATA_FIELDS.filter((data) => data.category === 'id').map(
          (data) => {
            return (
              <FormController
                key={data.id}
                data={data}
                control={control}
                setValue={setValue}
                getValues={getValues}
                countingMode={countingMode}
                errors={errors}
              />
            );
          }
        )}
      </LabelFrame>
    );
    const currentVals: FormFields = getValues();
    const locationInformation = (
      <LabelFrame label="Location" type="top">
        {METADATA_FIELDS.filter((data) => data.category === 'location').map(
          (data) => {
            if (data.id === 'address') {
              return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormController
                    key={data.id}
                    data={data}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    countingMode={countingMode}
                    errors={errors}
                    generateMapSrc={(lon, lat) => generateMapSrc(lon, lat)}
                  />
                  {mapSrc && (
                    <img
                      src={mapSrc}
                      alt="map of location"
                      style={{ alignSelf: 'center' }}
                    />
                  )}
                </div>
              );
            } else {
              return (
                <div
                  key={data.id}
                  style={{
                    display: 'inline-block',
                    fontSize: '12px',
                    marginLeft: '10px',
                  }}
                >
                  <em>
                    {currentVals &&
                      currentVals[data.id] &&
                      `${data.label}: ${currentVals[data.id]}`}
                  </em>
                  {'  '}
                </div>
              );
            }
          }
        )}
      </LabelFrame>
    );

    const configurationInfo = (
      <LabelFrame label="Configuration" type="top">
        {METADATA_FIELDS.filter(
          (data) => data.category === 'configuration'
        ).map((data) => (
          <FormController
            key={data.id}
            data={data}
            control={control}
            setValue={setValue}
            getValues={getValues}
            countingMode={countingMode}
            errors={errors}
          />
        ))}
      </LabelFrame>
    );

    const costInfo = (
      <LabelFrame label="Cost" type="top">
        {METADATA_FIELDS.filter((data) => data.category === 'cost').map(
          (data) => (
            <FormController
              key={data.id}
              data={data}
              control={control}
              setValue={setValue}
              getValues={getValues}
              countingMode={countingMode}
              errors={errors}
            />
          )
        )}
      </LabelFrame>
    );

    const auditInfo = (
      <LabelFrame label="Audit Info" type="top">
        {METADATA_FIELDS.filter((data) => data.category === 'audit').map(
          (data) => (
            <FormController
              key={data.id}
              data={data}
              control={control}
              setValue={setValue}
              getValues={getValues}
              countingMode={countingMode}
              errors={errors}
            />
          )
        )}
      </LabelFrame>
    );

    useEffect(() => {
      if (!building) reset({ ...initialBuildingData });
      getSpaceData(new AbortController());
    }, [building, getSpaceData, initialBuildingData, reset]);

    const saveData = async (e: FormFields) => {
      try {
        await updateSpace(e);
        setBuildingData({ ...e });
        onDismiss();
        toast.success('Building metadata saved');
      } catch (err: any) {
        toast.error('Error saving building metadata: ' + err.message);
        console.error(err);
      }
    };

    const onCancel = () => {
      toast.warning('Changes discarded');
      onDismiss();
      reset({ ...buildingData });
    };

    const formCancel = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onCancel();
    };

    return (
      <MapboxSessionProvider>
        <Modal
          visible={visible}
          width={700}
          onBlur={onDismiss}
          onEscape={onCancel}
        >
          <form onSubmit={handleSubmit(saveData)}>
            <AppBar title={<Fragment>{title}</Fragment>} />
            <br />
            {buildingInfo}
            {locationInformation}
            {configurationInfo}
            {costInfo}
            {auditInfo}
            <div className={styles.metadataButtonRow}>
              <Button
                type="hollow"
                onClick={formCancel}
                data-cy="building-metadata-modal-close"
              >
                Cancel
              </Button>
              <Button
                type="filled"
                buttonType="submit"
                data-cy="building-metadata-modal-save"
              >
                Save
              </Button>
            </div>
          </form>
        </Modal>
      </MapboxSessionProvider>
    );
  };

interface FormControllerProps {
  data: MetadataField;
  control: Control<FormFields>;
  setValue: UseFormSetValue<FormFields>;
  getValues: UseFormGetValues<FormFields>;
  countingMode: string | null;
  errors: FieldErrors<FormFields>;
  generateMapSrc?: (lon: number | null, lat: number | null) => void;
}

const FormController: React.FC<FormControllerProps> = ({
  data,
  control,
  setValue,
  getValues,
  countingMode,
  errors,
  generateMapSrc,
}) => {
  const { type, label, choices, editable } = data;
  const fieldType = fieldTypeMap[type] || type;
  const id = data.id as keyof FormFields;
  const currentVals = getValues();
  const isDailyWithOa = countingMode === 'oa' && id === 'daily_reset';
  if (type === 'currency') return null; // Skip currency fields
  return (
    <div className={type === 'address' ? '' : styles.spaceMetaRow}>
      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
        {label}
        {id === 'cost_per_sqft' &&
          ` (${currentVals['cost_per_sqft_currency']})`}
      </div>
      <Controller
        name={id as keyof FormFields}
        control={control}
        render={({ field: { onChange, value } }) => {
          if (type === 'list') {
            return (
              <SelectField
                size="small"
                data-cy={`building-metadata-modal-${id}`}
                value={value || ''}
                onChange={(choice) => {
                  setValue(id, choice.id);
                  if (choice.id === 'oa') {
                    setValue('daily_reset', null);
                  } else if (choice.id === 'entry' && value === 'oa') {
                    setValue('daily_reset', '04:00:00');
                  }
                }}
                choices={choices || []}
              />
            );
          } else if (type === 'address') {
            return (
              <div style={{ display: 'flex' }}>
                <EditableAddress
                  address={value as string}
                  buttons={false}
                  theme="light"
                  placeholder={'eg. 101 Front Ave'}
                  onEditAddress={(v, tz, lat, lon) => {
                    if (generateMapSrc) generateMapSrc(lon, lat);
                    setValue('latitude', lat);
                    setValue('longitude', lon);
                    setValue('time_zone', tz);
                    setValue(id, v);
                  }}
                  loading={value ? false : true}
                />
              </div>
            );
          } else if (type === 'string_copy') {
            return (
              <div>
                <span
                  className={styles.spaceMetaRowCopy}
                  data-cy={`building-metadata-modal-${id}`}
                  onClick={() => {
                    toast.success('Copied to clipboard');
                    navigator.clipboard.writeText(value as string);
                  }}
                >
                  <Icons.CopyPaper size={15} /> {value}
                </span>
              </div>
            );
          } else {
            return (
              <div className={styles.spaceMetaRowInput}>
                <TextField
                  size="small"
                  type={fieldType}
                  data-cy={`building-metadata-modal-${id}`}
                  disabled={!editable || isDailyWithOa}
                  placeholder="null"
                  value={
                    type === 'date'
                      ? new Date(value as string).toLocaleString()
                      : value ?? ''
                  }
                  error={errors[id]?.message ? true : false}
                  onChange={(e) => {
                    let v: string | number | null = e.currentTarget.value;
                    if (fieldType === 'number') {
                      v = parseFloat(v);
                      if (isNaN(v)) v = null;
                    }
                    onChange(v);
                  }}
                />
                {errors[id]?.message && (
                  <p className={styles.floorMetadataError}>
                    {errors[id]?.message}
                  </p>
                )}
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default BuildingSettingsModal;
