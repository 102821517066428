import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import Space from 'lib/space';
import PlanSensor from 'lib/sensor';
import Threshold from 'lib/threshold';
import { Meters, LengthUnit } from 'lib/units';
import { FloorplanAPI, FloorplanV2Plan } from 'lib/api';
import { FloorplanV2ExternalSpace, isFloorplanThresholdId } from 'lib/api';

import Tooltip from 'components/tooltip';
import Button from 'components/button';
import NotesBox from 'components/notes-box';
import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';

import ExternalSpaceSelectionPanel from './external-space-selection-panel';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';

// The following few functions/components were ripped out of
// focused-sensor-panel. Threshold needs similar adjustability.
const parseMeasurement = (m: string): number =>
  Math.round(window.parseFloat(m));

const reduceDecimalPlaces = (value: number): string => {
  const numDigits = value.toString().length;
  if (numDigits <= 5) {
    return value.toString();
  } else if (value > 10000) {
    return value.toFixed(0);
  } else if (value > 1000) {
    return value.toFixed(1);
  } else if (value > 100) {
    return value.toFixed(2);
  } else if (value > 10) {
    return value.toFixed(3);
  } else if (value > 1) {
    return value.toFixed(4);
  } else {
    return value.toFixed(5);
  }
};

// Need a completely separate component for feet + inches to handle inches overflow
const DoorwayFeetInchesField: React.FunctionComponent<{
  valueMeters: number;
  disabled: boolean;
  onChange: (height: number) => void;
}> = ({ valueMeters, disabled, onChange }) => {
  const [feet, inches] = Meters.toFeetAndInches(valueMeters);
  const [feetInput, setFeetInput] = useState<string>(`${feet}`);
  const [inchesInput, setInchesInput] = useState<string>(`${inches}`);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);
  const [focusingFeet, setFocusingFeet] = useState<boolean>(false);
  const [focusingInches, setFocusingInches] = useState<boolean>(false);

  // When the value changes externally, update the feet box and inches box contents
  useEffect(() => {
    const [feet, inches] = Meters.toFeetAndInches(valueMeters);
    setFeetInput(`${feet}`);
    setInchesInput(`${inches}`);
  }, [valueMeters]);

  useEffect(() => {
    const newValue = Meters.fromFeetAndInches(
      parseMeasurement(feetInput),
      parseMeasurement(inchesInput)
    );
    if (isNaN(newValue)) {
      setIsValueValid(false);
    } else {
      setIsValueValid(true);
    }
  }, [feetInput, inchesInput]);

  const handleSubmit = () => {
    const feet = parseMeasurement(feetInput);
    const inches = parseMeasurement(inchesInput);
    setFeetInput(`${feet}`);
    setInchesInput(`${inches}`);

    const newMeters = Meters.fromFeetAndInches(feet, inches);
    setIsValueValid(true);
    onChange(newMeters);
  };

  return (
    <div className={styles.sensorHeightFormGroup}>
      <div className={styles.sensorHeightFormFields}>
        <div className={styles.inputInlineIcon}>
          <Icons.RulerVertical size={18} />
        </div>
        <TextField
          type="number"
          width={40}
          size="medium"
          disabled={disabled}
          step={1}
          value={feetInput}
          onChange={(evt) => {
            setFeetInput(evt.currentTarget.value);
          }}
          onBlur={() => {
            setFocusingFeet(false);
            handleSubmit();
          }}
          onFocus={() => setFocusingFeet(true)}
          error={!focusingFeet && !focusingInches && !isValueValid}
          data-cy="sensor-height-feet"
        />
        <label className={styles.sensorHeightInputLabel}>ft</label>
        <TextField
          type="number"
          width={40}
          size="medium"
          disabled={disabled}
          min={-1}
          max={12}
          step={1}
          value={inchesInput}
          onChange={(evt) => {
            const value = parseMeasurement(evt.currentTarget.value);
            if (value === -1) {
              const nextFeet = feet - 1;
              const nextInches = 11;
              setFeetInput(nextFeet.toString());
              setInchesInput(nextInches.toString());
            } else if (value === 12) {
              const nextFeet = feet + 1;
              const nextInches = 0;
              setFeetInput(nextFeet.toString());
              setInchesInput(nextInches.toString());
              onChange(Meters.fromFeetAndInches(nextFeet, nextInches));
            } else {
              setInchesInput(value.toString());
            }
          }}
          onBlur={() => {
            setFocusingInches(false);
            handleSubmit();
          }}
          onFocus={() => setFocusingInches(true)}
          error={!focusingFeet && !focusingInches && !isValueValid}
          data-cy="sensor-height-inches"
        />
        <label className={styles.sensorHeightInputLabel}>in</label>
      </div>
      {/* .sensorHeightInputFieldss */}
      <div className={styles.sensorHeightValue}>
        {feet}' {inches}"
      </div>
    </div>
  );
};

const DoorwayWidthField: React.FunctionComponent<{
  width: number;
  value: number;
  disabled: boolean;
  displayUnits: LengthUnit; // only here so we can use it as a useEffect dependency
  handleSendToState: (height: number) => void;
}> = ({ width, value, disabled, displayUnits, handleSendToState }) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());
  const [focusing, setFocusing] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(true);

  useEffect(() => {
    const newValue = Number(inputValue);
    if (isNaN(newValue)) {
      setIsInputValid(false);
    }
  }, [inputValue]);

  useEffect(() => {
    // Reset the input value if the units change
    setInputValue(value.toString());
  }, [displayUnits]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Only update the input value to be the value from state if:
    // 1) the input is not currently being updated (not focused)
    // 2) it's a valid input (we want to show the invalid input to make it clear it needs to be updated)

    if (!focusing && isInputValid) {
      setInputValue(value.toString());
    }
  }, [value, inputValue, focusing, isInputValid]);

  return (
    <TextField
      type="number"
      width={width}
      size="medium"
      disabled={disabled}
      step={1}
      value={inputValue}
      onChange={(evt) => setInputValue(evt.currentTarget.value)}
      onBlur={(evt) => {
        setFocusing(false);
        const newValue = Number(evt.currentTarget.value);
        handleSendToState(newValue);
      }}
      onFocus={() => setFocusing(true)}
      error={!focusing && !isInputValid}
      data-cy="doorway-width-field"
    />
  );
};

export const DoorwayWidthInput: React.FunctionComponent<{
  value: number;
  disabled: boolean;
  displayUnits: LengthUnit;
  onChange: (height: number) => void;
}> = ({ value, disabled, displayUnits, onChange }) => {
  switch (displayUnits) {
    case 'inches': {
      const inches = Math.round(Meters.toInches(value));
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <DoorwayWidthField
              width={80}
              value={parseFloat(inches.toFixed(1))}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromInches(Math.round(val)));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>in</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(inches)}"
          </div>
        </div>
      );
    }
    case 'centimeters': {
      const centimeters = Meters.toCentimeters(value);
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <DoorwayWidthField
              width={80}
              value={parseFloat(centimeters.toFixed(0))}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromCentimeters(val));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>cm</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(centimeters)}cm
          </div>
        </div>
      );
    }
    case 'millimeters': {
      const millimeters = Meters.toMillimeters(value);
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <DoorwayWidthField
              width={80}
              value={parseFloat(millimeters.toFixed(0))}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromMillimeters(val));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>mm</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(millimeters)}mm
          </div>
        </div>
      );
    }
    case 'meters': {
      const meters = value;
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical size={18} />
            </div>
            <DoorwayWidthField
              width={80}
              value={parseFloat(meters.toFixed(2))}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(val);
              }}
            />
            <label className={styles.sensorHeightInputLabel}>m</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(meters)}m
          </div>
        </div>
      );
    }
    case 'feet_and_inches': {
      return (
        <DoorwayFeetInchesField
          valueMeters={value}
          onChange={onChange}
          disabled={disabled}
        />
      );
    }
  }
};

const FocusedThresholdPanel: React.FunctionComponent<{
  state: State;
  threshold: Threshold;
  client: AxiosInstance;
  planId: FloorplanV2Plan['id'];
  displayUnit: LengthUnit;
  dispatch: React.Dispatch<Action>;
  onChangeRelatedSpaces: (spaces: Threshold['relatedSpaces']) => void;
  onChangeRelatedPlanSensors: (
    planSensors: Threshold['relatedPlanSensors']
  ) => void;
  onChangeName: (name: Threshold['name']) => void;
  onChangeLocked: (locked: Threshold['locked']) => void;
  onDelete: () => void;
  onChangeNotes: (notes: Threshold['notes']) => void;
  onChangeDoorSwing: (doorSwingIntoFOV: boolean) => void;
  onChangeWidth?: (widthMeters: number) => void;
  wallsPresent?: boolean;
}> = ({
  state,
  threshold,
  client,
  planId,
  displayUnit,
  dispatch,
  onChangeRelatedSpaces,
  onChangeRelatedPlanSensors,
  onChangeName,
  onChangeLocked,
  onDelete,
  onChangeNotes,
  onChangeDoorSwing,
  onChangeWidth = null,
  wallsPresent = null,
}) => {
  const [workingName, setWorkingName] = useState<Space['name']>(threshold.name);
  const [externalSpaces, setExternalSpaces] = useState<
    FloorplanV2ExternalSpace[] | null
  >(null);

  const isGrafanaLinksEnabled = useTreatment(SPLITS.GRAFANA_LINKS);
  useEffect(() => setWorkingName(threshold.name), [threshold.name]);

  function findFloorAndBuildingId(
    space: FloorplanV2ExternalSpace,
    spaceMap: Map<string, FloorplanV2ExternalSpace>,
    depth: number = 0,
    floor_id: string | null = null,
    building_id: string | null = null
  ): { floor_id: string | null; building_id: string | null } {
    if (depth > 5 || building_id !== null) {
      // Prevent infinite loops in case of unexpected data
      return { floor_id, building_id };
    }

    if (space.parent_id && spaceMap.has(space.parent_id)) {
      const parent = spaceMap.get(space.parent_id);

      if (!parent) {
        // This shouldn't ever execute but better safe than sorry.
        return { floor_id, building_id };
      }

      if (parent.space_type === 'floor') {
        floor_id = parent.id;
      } else if (parent.space_type === 'building') {
        building_id = parent.id;
      }

      return findFloorAndBuildingId(
        parent,
        spaceMap,
        depth + 1,
        floor_id,
        building_id
      );
    } else {
      return { floor_id, building_id };
    }
  }

  return (
    <div data-cy="focused-threshold-panel">
      <Panel position="top-left" width={320}>
        <PanelHeader>
          <TextField
            type="text"
            size="medium"
            value={workingName}
            placeholder="eg: Conf. Room Doorway"
            disabled={
              state.locked || threshold.locked || state.selectionMode.active
            }
            onChange={(evt) => setWorkingName(evt.currentTarget.value)}
            leadingIcon={<Icons.DoorEntry size={18} color={dust.Gray900} />}
            width="100%"
            onBlur={() => onChangeName(workingName)}
            data-cy="threshold-name"
          />
        </PanelHeader>

        <PanelBody>
          <label className={styles.thresholdSectionHeader}>
            Doorway Width:
          </label>
          <div className={styles.thresholdSection}>
            <DoorwayWidthInput
              value={Threshold.widthMeters(threshold)}
              disabled={true || state.locked || threshold.locked}
              displayUnits={state.displayUnit}
              onChange={(height: number) => {}}
            />
          </div>

          <label className={styles.thresholdSectionHeader}>
            Swing Direction:
          </label>
          <HorizontalForm size="small">
            <label className={styles.thresholdSectionBody}>
              {"This doorway swings into sensor's FOV:"}
            </label>
            <input
              type="checkbox"
              checked={threshold.swingsIntoFOV}
              onChange={(evt) => {
                onChangeDoorSwing(evt.target.checked);
              }}
            />
          </HorizontalForm>

          <label className={styles.thresholdSectionHeader}>
            Linked Sensors:
          </label>
          <div className={styles.thresholdSection}>
            {threshold.relatedPlanSensors.length > 0 ? (
              threshold.relatedPlanSensors.map((planSensorId, idx) => {
                const planSensor = state.planSensors.items.get(
                  planSensorId
                ) as PlanSensor;
                if (!planSensor) {
                  return null;
                }
                return (
                  <div
                    key={planSensorId}
                    className={styles.thresholdPanelListObject}
                  >
                    <Button
                      size="medium"
                      type="link"
                      ellipseOverflowText={true}
                      leadingIcon={<Icons.DeviceEntryTopFront size={18} />}
                      onClick={() => {
                        dispatch({
                          type: 'item.menu.mousedown',
                          itemType: 'sensor',
                          itemId: planSensorId,
                        });
                      }}
                      onMouseEnter={() => {
                        dispatch({
                          type: 'menu.focusObjectViaLink',
                          objectId: planSensorId,
                        });
                      }}
                      onMouseLeave={() => {
                        dispatch({
                          type: 'menu.unfocusLinkedObject',
                        });
                      }}
                    >
                      {planSensor.cadId || planSensor.id}{' '}
                      {planSensor.serialNumber
                        ? `| ${planSensor.serialNumber}`
                        : ''}
                    </Button>

                    <Tooltip
                      contents={'Unlink Sensor from Doorway'}
                      placement="bottom"
                      target={
                        <Button
                          size="small"
                          type="link"
                          disabled={state.locked || threshold.locked}
                          trailingIcon={<Icons.LinkBroken size={18} />}
                          onClick={() => {
                            onChangeRelatedPlanSensors(
                              threshold.relatedPlanSensors.filter(
                                (planSensorId) => planSensorId !== planSensor.id
                              )
                            );
                          }}
                        />
                      }
                    />
                  </div>
                );
              })
            ) : (
              <div className={classNames([styles.thresholdPanelListObject])}>
                <label
                  className={classNames([styles.large, styles.muted])}
                  style={{ paddingTop: '4px', paddingLeft: '2px' }}
                >
                  No sensors linked.
                </label>
                <Button
                  size="small"
                  type="hollow"
                  disabled={state.locked || threshold.locked}
                  trailingIcon={<Icons.DeviceEntryTopFront size={18} />}
                  onClick={() => {
                    dispatch({
                      type: 'selectionMode.begin',
                      expectedSelectionType: 'sensor',
                      hideAllLayersBut: ['entrySensors', 'thresholds'],
                      initialActionCreator: threshold['id'],
                      pendingAction: 'threshold.linkPlanSensor',
                    });
                  }}
                >
                  Link Entry Sensor
                </Button>
              </div>
            )}
          </div>

          <label className={styles.thresholdSectionHeader}>
            Linked Spaces:
          </label>
          <div className={styles.thresholdSection}>
            {threshold.relatedSpaces.length > 0 ? (
              threshold.relatedSpaces.map((relatedSpace, idx) => {
                const space = state.spaces.items.get(
                  relatedSpace.spaceId
                ) as Space;
                return (
                  <div style={{ display: 'inline-block', width: '100%' }}>
                    <div key={idx} className={styles.thresholdPanelListObject}>
                      <Button
                        size="medium"
                        ellipseOverflowText={true}
                        type="link"
                        active={false}
                        leadingIcon={
                          relatedSpace.spaceType === 'building' ? (
                            <Icons.SpaceTypeBuilding size={18} />
                          ) : relatedSpace.spaceType === 'floor' ? (
                            <Icons.SpacesAreasFloorplanPlanner size={18} />
                          ) : (
                            <Icons.SpaceTypeSpace size={18} />
                          )
                        }
                        disabled={space ? false : true}
                        onClick={() => {
                          dispatch({
                            type: 'item.menu.mousedown',
                            itemType: 'space',
                            itemId: relatedSpace.spaceId,
                          });
                        }}
                        onMouseEnter={() => {
                          dispatch({
                            type: 'menu.focusObjectViaLink',
                            objectId: relatedSpace.spaceId,
                          });
                        }}
                        onMouseLeave={() => {
                          dispatch({
                            type: 'menu.unfocusLinkedObject',
                          });
                        }}
                      >
                        {space && space.name
                          ? space.name
                          : relatedSpace.spaceName || 'Unknown'}
                      </Button>
                      {isGrafanaLinksEnabled && (
                        <HorizontalForm size="medium">
                          <Tooltip
                            contents={'Go to Grafana'}
                            placement="bottom"
                            target={
                              <Button
                                size="small"
                                type="link"
                                active={false}
                                disabled={!relatedSpace.spaceId}
                                onClick={() => {
                                  window.open(
                                    `https://grafana.data-prod.kgm3.net/d/cb082f2a-6d72-4d7f-b32c-2ab544146f35/drift-by-topology-space?var-space_id=${
                                      relatedSpace.spaceId.split('_')[1]
                                    }`,
                                    '_blank'
                                  );
                                }}
                                data-cy="external-link"
                                trailingIcon={<Icons.ChartLine size={16} />}
                              />
                            }
                          />
                          {!space ? (
                            <a
                              href={
                                relatedSpace.planId
                                  ? window.location.href.replace(
                                      /pln_\d+/,
                                      relatedSpace.planId
                                    )
                                  : undefined
                              }
                              target="_blank" // Open in a new tab
                              rel="noopener noreferrer" // Security best practice for opening new tabs
                              data-cy="external-link"
                            >
                              <Tooltip
                                contents={'Go to Floorplan'}
                                placement="bottom"
                                target={
                                  <Button
                                    size="small"
                                    type="link"
                                    active={false}
                                    disabled={!relatedSpace.planId}
                                    onClick={() => {}}
                                    data-cy="external-link"
                                    trailingIcon={
                                      <Icons.ArrowUpRight size={16} />
                                    }
                                  />
                                }
                              />
                            </a>
                          ) : null}
                          <Tooltip
                            contents={'Unlink Space from Doorway'}
                            placement="bottom"
                            target={
                              <Button
                                size="small"
                                type="link"
                                active={false}
                                disabled={state.locked || threshold.locked}
                                onClick={() => {
                                  onChangeRelatedSpaces(
                                    threshold.relatedSpaces.filter(
                                      (s) => s.spaceId !== relatedSpace.spaceId
                                    )
                                  );
                                }}
                                data-cy="unlink-space-from-threshold"
                                trailingIcon={<Icons.LinkBroken size={18} />}
                              />
                            }
                          />
                        </HorizontalForm>
                      )}
                    </div>

                    <div className={styles.thresholdSpaceActions}>
                      <Tooltip
                        contents={"Click to Flip Sensor's Location"}
                        placement="bottom"
                        target={
                          <Button
                            size="small"
                            type="link"
                            active={false}
                            disabled={state.locked || threshold.locked}
                            onClick={() => {
                              onChangeRelatedSpaces(
                                threshold.relatedSpaces.map((s) => {
                                  if (s.spaceId === relatedSpace.spaceId) {
                                    s.sensorPlacement *= -1;
                                  }
                                  return s;
                                })
                              );
                            }}
                            data-cy="space-flip-sensor-placement"
                            leadingIcon={<Icons.SwapVerticalArrow size={16} />}
                          >
                            {relatedSpace.sensorPlacement === 1
                              ? 'The sensor is installed :inside: this space.'
                              : 'The sensor is installed :outside: this space.'}
                          </Button>
                        }
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={classNames([styles.thresholdPanelListObject])}>
                <label
                  className={classNames([styles.large, styles.muted])}
                  style={{ marginTop: '4px', marginLeft: '2px' }}
                >
                  There are no spaces linked.
                </label>
              </div>
            )}
          </div>

          <div
            className={classNames([styles.thresholdPanelListObject])}
            style={{ paddingTop: '5px' }}
          >
            <Tooltip
              contents={'Space Inside This Floor'}
              placement="bottom"
              target={
                <Button
                  size="small"
                  type="hollow"
                  active={state.selectionMode.active}
                  disabled={state.locked || threshold.locked}
                  onClick={() => {
                    dispatch({
                      type: 'selectionMode.begin',
                      expectedSelectionType: 'space',
                      hideAllLayersBut: ['thresholds', 'entrySpaces'],
                      initialActionCreator: threshold['id'],
                      pendingAction: 'threshold.linkSpace',
                    });
                  }}
                  data-cy="space-add-from-threshold"
                  trailingIcon={<Icons.SpaceAdd size={18} />}
                >
                  Link Local Space
                </Button>
              }
            />

            <Tooltip
              contents={'Space Outside This Floor'}
              placement="bottom"
              target={
                <Button
                  size="small"
                  type="hollow"
                  active={externalSpaces !== null}
                  disabled={
                    state.locked ||
                    threshold.locked ||
                    state.selectionMode.active
                  }
                  onClick={() => {
                    const fetchData = async () => {
                      try {
                        const response =
                          await FloorplanAPI.listAllOrganizationSpaces(client);

                        // Filter out:
                        //   - All OA spaces, as we are not interesting in linking them.
                        //     Except for Floors and Buildings (even if OA)

                        const spaceList: FloorplanV2ExternalSpace[] =
                          response.data.results.filter(
                            (s) =>
                              (s.counting_mode === 'entry' &&
                                s.parent_id !== state.planMetadata.floorId) ||
                              (s.space_type === 'floor' &&
                                s.parent_id ===
                                  state.planMetadata.buildingId) ||
                              s.space_type === 'building'
                          );

                        // Create a map using the 'id' as the key
                        const spaceMap: Map<string, FloorplanV2ExternalSpace> =
                          new Map();
                        spaceList.forEach((obj) => {
                          spaceMap.set(obj.id, obj);
                        });

                        // Iterate over the list to recursively add "parent_name," "floor_id," and "building_id"
                        // Every AOI/Space level space, should have a floor and building ids.
                        spaceList.forEach((obj) => {
                          const { floor_id, building_id } =
                            findFloorAndBuildingId(obj, spaceMap);
                          obj.floor_id = floor_id;
                          obj.building_id = building_id;
                          if (obj.space_type === 'building') {
                            obj.building_id = obj.id;
                          }
                          if (obj.space_type === 'floor') {
                            obj.floor_id = obj.id;
                          }
                          if (obj.parent_id && spaceMap.has(obj.parent_id)) {
                            obj.parent_name = spaceMap.get(obj.parent_id)!.name;
                          }
                        });

                        // Limit all to only spaces in this building.
                        const spaceListThisBuilding = spaceList.filter(
                          (s) => s.building_id === state.planMetadata.buildingId
                        );

                        // This will bring up the ExternalSpacesPanel as externalSpaces !== null
                        setExternalSpaces(spaceListThisBuilding);
                      } catch (err) {
                        console.error(
                          'Something went wrong while generating the trigger region:',
                          err
                        );
                      }
                    };

                    fetchData();
                  }}
                  trailingIcon={<Icons.SpaceTypeBuilding size={18} />}
                >
                  Link External Space
                </Button>
              }
            />
          </div>

          {state.planSensors.items.get(threshold.relatedPlanSensors[0])
            ?.sensorFunction === 'openEntry' ? (
            <>
              <label className={styles.thresholdSectionHeader}>
                Trigger Region
              </label>

              {threshold.relatedPlanSensors.length === 0 ? (
                'You must link at least one Entry LR sensor to generate a trigger region.'
              ) : !wallsPresent ? (
                'Walls must be imported in order to generate trigger regions.'
              ) : (
                <Button
                  size="small"
                  type="hollow"
                  disabled={
                    threshold.relatedPlanSensors.length === 0 ||
                    threshold.locked ||
                    state.locked
                  }
                  onClick={() => {
                    FloorplanAPI.calculateTriggerRegion(
                      client,
                      planId,
                      threshold['id']
                    )
                      .then((response) => {
                        if (response.status === 200) {
                          toast.success(
                            `Successfully generated a trigger region for ${threshold.name}!`
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(
                          'Something went wrong while generating the trigger region.'
                        );
                        toast.error(
                          `Generation of trigger region failed. Inspect console logs for details.`
                        );
                      });
                  }}
                  data-cy="generate-trigger-region"
                  leadingIcon={<Icons.CleanMagicSparkle size={18} />}
                >
                  {threshold.triggerRegion &&
                  threshold.triggerRegion?.length > 0
                    ? 'Recalculate Trigger Region'
                    : 'Generate Trigger Region'}
                </Button>
              )}
            </>
          ) : null}

          <div className={styles.thresholdSectionHeader}>
            <label htmlFor="threshold-notes">Notes:</label>
            <NotesBox
              notes={threshold.notes}
              disabled={state.locked || threshold.locked}
              onNotesEdited={(notes) => onChangeNotes(notes)}
              data-cy="threshold-notes"
            />
          </div>
          <div className={styles.spaceConfigMeta}>
            <div
              className={classNames(styles.thresholdConfigMetaRow, {
                [styles.dimmed]: isFloorplanThresholdId(threshold.id) === null,
              })}
            >
              <span>ID:&nbsp;</span>
              {isFloorplanThresholdId(threshold.id) ? (
                <HorizontalForm size="small">
                  {threshold.id}
                  <Button
                    size="nano"
                    type="cleared"
                    trailingIcon={<Icons.CopyDuplicate size={14} />}
                    onClick={() => {
                      navigator.clipboard.writeText(threshold.id);
                      toast.success('Copied to clipboard.');
                    }}
                  />
                </HorizontalForm>
              ) : (
                'Unsaved'
              )}
            </div>
          </div>
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.Trash size={18} />}
                danger
                disabled={
                  state.locked || threshold.locked || state.selectionMode.active
                }
                onClick={() => onDelete()}
                data-cy="threshold-delete-button"
              />
              <Button
                type="cleared"
                size="medium"
                data-cy={
                  threshold.locked
                    ? 'threshold-unlock-button'
                    : 'threshold-lock-button'
                }
                trailingIcon={
                  threshold.locked ? (
                    <Icons.LockClosed
                      size={18}
                      color={!state.locked ? dust.Yellow400 : 'currentColor'}
                    />
                  ) : (
                    <Icons.LockOpen size={18} />
                  )
                }
                onClick={() => {
                  onChangeLocked(!threshold.locked);
                }}
                disabled={state.locked || state.selectionMode.active}
              />
              <Tooltip
                contents={'Link Another Space'}
                placement="bottom"
                target={
                  <Button
                    type="cleared"
                    size="medium"
                    data-cy="menu-threshold-link-space"
                    trailingIcon={<Icons.SpaceAdd size={19} />}
                    onClick={() => {
                      dispatch({
                        type: 'selectionMode.begin',
                        expectedSelectionType: 'space',
                        hideAllLayersBut: ['thresholds', 'entrySpaces'],
                        initialActionCreator: threshold['id'],
                        pendingAction: 'threshold.linkSpace',
                      });
                    }}
                    disabled={
                      state.locked ||
                      threshold.locked ||
                      state.selectionMode.active
                    }
                  />
                }
              />
              <Tooltip
                contents={'Link Another Entry Sensor'}
                placement="bottom"
                target={
                  <Button
                    type="cleared"
                    size="medium"
                    data-cy="menu-threshold-link-sensor"
                    trailingIcon={<Icons.DeviceEntryTopFront size={18} />}
                    onClick={() => {
                      dispatch({
                        type: 'selectionMode.begin',
                        expectedSelectionType: 'sensor',
                        hideAllLayersBut: ['entrySensors', 'thresholds'],
                        initialActionCreator: threshold['id'],
                        pendingAction: 'threshold.linkPlanSensor',
                      });
                    }}
                    disabled={
                      state.locked ||
                      threshold.locked ||
                      state.selectionMode.active
                    }
                  />
                }
              />
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              onClick={() => {
                dispatch({ type: 'threshold.dismiss', id: threshold.id });
              }}
              data-cy="threshold-dismiss"
            >
              Done
            </Button>
          }
        />
      </Panel>
      {externalSpaces ? (
        <ExternalSpaceSelectionPanel
          selectedDoorway={threshold}
          externalSpaces={externalSpaces}
          onSpaceSelected={({ spaceId, sensorInsideSpace }) => {
            // if null, the user cancelled the action
            if (spaceId) {
              onChangeRelatedSpaces([
                ...threshold.relatedSpaces,
                {
                  spaceId: spaceId,
                  sensorPlacement: sensorInsideSpace ? 1 : -1,
                  linkId: null,
                  spaceName: null,
                  spaceType: null,
                  planId: null,
                },
              ]);
            }
            setExternalSpaces(null);
          }}
          dispatch={dispatch}
        />
      ) : null}
    </div>
  );
};

export default FocusedThresholdPanel;
