import { useEffect } from 'react';
import * as React from 'react';

import { useAppSelector } from 'redux/store';

const SensorDataStreamOALive: React.FunctionComponent<{
  planId: string;
  sensorSerial: string;
  onOpen: () => void;
  onClose: () => void;
  onError: () => void;
  onMessage: (msg: string) => void;
}> = ({ planId, sensorSerial, onOpen, onClose, onError, onMessage }) => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    let socket: WebSocket | null = null;

    const authURL = `/v2/presence/status/floorplan/${planId}/auth`;

    densityAPIClient
      .post(authURL)
      .then(
        (response) => {
          const socketURL = `${response.data.url}?targets=true&points=true&entity_filter=${sensorSerial}`;

          socket = new WebSocket(socketURL);

          socket.addEventListener('open', () => {
            onOpen();
          });

          socket.addEventListener('message', (evt) => {
            const payload: unknown = evt.data;

            if (typeof payload !== 'string') {
              throw new Error('Socket message data must be of type string');
            }

            onMessage(payload);
          });

          socket.addEventListener('close', () => {
            onClose();
          });

          socket.addEventListener('error', (evt) => {
            onError();
          });
        },
        () => {
          onError();
        }
      )
      .catch((err) => {
        console.error(err);
        onError();
      });

    // Make sure the socket gets closed before unloading this component
    return () => {
      socket && socket.close();
    };
  }, [
    densityAPIClient,
    onClose,
    onError,
    onMessage,
    onOpen,
    planId,
    sensorSerial,
  ]);

  return null;
};

export default SensorDataStreamOALive;
