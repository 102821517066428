import { Meters } from 'lib/units';
import styles from './styles.module.scss';

export type AOC = {
  area_meters_squared: number;
  coverage_intersection_heightmap_enabled: boolean;
  floorId: string;
  floorName: string;
  floorStatus: 'planning' | 'live';
  id: string;
  locked: boolean;
  minimum_exclusive_area: number;
  name: string;
  notes: string;
  polygon_vertices: { x: number; y: number }[];
  safety_factor_pct: number;
  sensor_base_angle_degrees: number;
  sensor_height: number;
  small_room_mode: boolean;
};

type AOCInfoProps = {
  aocArr: AOC[];
};

export const AOCInfo: React.FunctionComponent<AOCInfoProps> = ({ aocArr }) => {
  const coverage: { live: number; planning: number } = { live: 0, planning: 0 };
  aocArr.forEach((aoc: AOC) => {
    if (aoc.floorStatus === 'live') {
      coverage['live'] = coverage['live'] + aoc.area_meters_squared;
    } else {
      coverage['planning'] = coverage['planning'] + aoc.area_meters_squared;
    }
  });

  return (
    <div style={{ padding: '.25em 1em' }}>
      <div className={styles.aocDataRow} data-cy="aoc-live-coverage">
        Live Floor Coverage:
        <em>
          {coverage.live
            ? Meters.toSquareFeet(coverage.live).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })
            : 0}{' '}
          sqft{' '}
        </em>
      </div>
      <div className={styles.aocDataRow} data-cy="aoc-planning-coverage">
        Planning Floor Coverage:
        <em>
          {coverage.planning
            ? Meters.toSquareFeet(coverage.planning).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })
            : 0}
        </em>
        sqft
      </div>
    </div>
  );
};
