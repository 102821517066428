// split.io split names
export const SPLITS = {
  FF_OVERRIDES: 'web_oa_ff_overrides',
  HEIGHT_MAP: 'web_oa_height_maps',
  AREAS_OF_CONCERN_VALIDATION: 'web_planner_areas_of_concern_validation',
  AREAS_OF_CONCERN_MERGING: 'web_planner_areas_of_concern_merging',
  AREAS_OF_CONCERN_SNAPPING: 'web_planner_areas_of_concern_snapping',
  WALLS_FILTER: 'web_planner_walls_filter',
  WALLS_STEP_IN_CREATION_FLOW: 'web_planner_walls_step_in_creation_flow',
  PROJECT_SHARING: 'web_planner_project_sharing',
  LIST_PAGE_DELETE: 'web_planner_list_page_delete',
  GLOBAL_POINTS: 'web_planner_global_points',
  SPACE_SNAPPING: 'web_planner_space_snapping',
  VALIDATION: 'web_planner_validation',
  MAPS_IMPORT: 'web_planner_maps_import',
  HEATMAP: 'web_planner_heatmap',
  HIDE_INDIVIDUAL_SENSOR_STREAMING:
    'web_planner_hide_individual_sensor_streaming',
  DISABLE_AUTO_SAVING: 'web_planner_disable_auto_saving',
  HEIGHT_SYNC_BUTTON: 'web_planner_height_sync_button',
  NOISE_POLYGONS_BUTTON: 'web_planner_generate_noise_polygons_button',
  GRAFANA_LINKS: 'web_planner_grafana_links',
} as const;

// features to override in split.io localhost mode
export const LOCAL_FEATURES = {
  [SPLITS.FF_OVERRIDES]: 'on',
  [SPLITS.HEIGHT_MAP]: 'on',
  [SPLITS.AREAS_OF_CONCERN_VALIDATION]: 'off',
  [SPLITS.AREAS_OF_CONCERN_MERGING]: 'off',
  [SPLITS.AREAS_OF_CONCERN_SNAPPING]: 'off',
  [SPLITS.WALLS_FILTER]: 'on',
  [SPLITS.WALLS_STEP_IN_CREATION_FLOW]: 'on',
  [SPLITS.PROJECT_SHARING]: 'off',
  [SPLITS.LIST_PAGE_DELETE]: 'off',
  [SPLITS.GLOBAL_POINTS]: 'off',
  [SPLITS.SPACE_SNAPPING]: 'off',
  [SPLITS.VALIDATION]: 'off',
  [SPLITS.MAPS_IMPORT]: 'off',
  [SPLITS.HEATMAP]: 'off',
  [SPLITS.HIDE_INDIVIDUAL_SENSOR_STREAMING]: 'off',
  [SPLITS.DISABLE_AUTO_SAVING]: 'off',
  [SPLITS.HEIGHT_SYNC_BUTTON]: 'off',
  [SPLITS.NOISE_POLYGONS_BUTTON]: 'off',
  [SPLITS.GRAFANA_LINKS]: 'on',
};
