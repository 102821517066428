import * as React from 'react';
import { Fragment, useEffect, useMemo, useState, useCallback } from 'react';
import { Icons } from '@density/dust';
import { toast } from 'react-toastify';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { css } from '@emotion/react';
import { Impersonate, EnvironmentSwitcher } from '@densityco/lib-common-auth';
import styles from './styles.module.scss';

import { CoreSpace, CoreOrganization } from '@densityco/lib-api-types';
import { loadCookieData } from '@densityco/lib-common-auth';
import { TokenProviderContext } from '@densityco/lib-common-auth';

import AuthWrapper from 'components/auth-wrapper/auth-wrapper';
import LogoutFinish from 'components/logout-finish';
import FloorPlanning from 'components/floor-planning/floor-planning';
import TestRouteLoaderComponent from 'components/test-route-loader-component';
import TreatmentsOverrides from 'components/treatments-overrides/treatments-overrides';
import { TreatmentsProvider } from 'contexts/treatments';
import { ErrorReporting } from 'lib/error-reporting';
import { Analytics } from 'lib/analytics';
import { modulo } from 'lib/math';
import { useAppDispatch, useAppSelector } from 'redux/store';
import ToastContainer from 'components/toast-container/toast-container';
import FloorplanMeasure from 'components/floorplan-measure';
import WallsEditor from 'components/walls-editor';
import HeightMapImport from 'components/height-map-editor';
import WallSegment from 'lib/wall-segment';
import { Dialogger } from '@densityco/ui';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import Tooltip from 'components/tooltip';
import FillCenter from 'components/fill-center/fill-center';
import Floorplan from 'lib/floorplan';
import Measurement from 'lib/measurement';
import FloorplanCollection from 'lib/floorplan-collection';
import { getLogoutURL } from 'lib/user';
import { SPLITS } from 'lib/treatments';
import { FloorplanCoordinates } from 'lib/geometry';
import { useTreatment } from 'contexts/treatments';
import ErrorMessage from 'components/error-message/error-message';
import { asyncFetchUserThunk } from 'redux/features/user/async-fetch-user-thunk';

import { FloorplanAPI, CoreAPI, FloorplanV2Plan } from 'lib/api';
import { DarkTheme } from 'components/theme';
import AppBar from 'components/app-bar';
import Button from 'components/button';
import BuildingList from 'components/building-list';
import HorizontalForm from 'components/horizontal-form';
import AnalyticsOptInPopup from 'components/analytics-opt-in-popup';
import HeightMap from 'lib/heightmap';
import BuildingManager from 'components/building-manager/building-manager';

function App() {
  const user = useAppSelector((state) => state.user);
  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );

  // this is where we identify our user to services like sentry or intercom
  React.useEffect(
    function initialize() {
      if (!user.data) {
        return;
      }
      if (!tokenCheckResponse) {
        return;
      }

      ErrorReporting.identify({
        id: user.data.id,
        name: user.data.full_name,
        email: user.data.email,
        organizationId: user.data.organization.id,
      });

      Analytics.identify(user.data.id);
      Analytics.register({
        id: user.data.id,
        name: user.data.full_name,
        email: user.data.email,
        organizationId: user.data.organization.id,
        organizationName: user.data.organization.name,
        coreConsent: user.data.core_consent,
        role: user.data.role,
        isImpersonating: tokenCheckResponse.user?.id !== user.data.id,
        realOrganizationId: tokenCheckResponse.organization?.id,
        realOrganizationName: tokenCheckResponse.organization?.name,
        impersonatedOrganizationUserId: tokenCheckResponse.user?.id,
        impersonatedOrganizationUserEmail: tokenCheckResponse.user?.email,
      });
    },
    [user, tokenCheckResponse]
  );

  return (
    <BrowserRouter>
      <TreatmentsProvider splitKey={user.data?.id}>
        <div
          css={css`
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
          `}
        >
          <AnalyticsPageViewTracker />
          <ToastContainer />
          <Dialogger />
          <Switch>
            <Route path="/logout-finish" component={LogoutFinish} />
            <Route path="/:organizationId" component={AuthedRoutes} />
            <Route exact path="/" component={RedirectToCurrentOrganization} />
            <Redirect to="/" />
          </Switch>
        </div>
      </TreatmentsProvider>
    </BrowserRouter>
  );
}

export default React.memo(App);

function AnalyticsPageViewTracker() {
  const location = useLocation();

  React.useEffect(() => {
    Analytics.track('Pageview', {
      path: location.pathname + location.search,
    });
  }, [location]);

  return null;
}

function UserFetcher() {
  const dispatch = useAppDispatch();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  React.useEffect(() => {
    dispatch(asyncFetchUserThunk());
  }, [densityAPIClient, dispatch]);

  return null;
}

function AuthedRoutes() {
  const { organizationId } = useParams<{
    organizationId: CoreOrganization['id'];
  }>();

  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );

  const isFFOverridesOn = useTreatment(SPLITS.FF_OVERRIDES);
  const [isFFOverridesModalOpen, setIsFFOverridesModalOpen] = useState(false);

  return (
    <AuthWrapper organizationId={organizationId}>
      <DarkTheme>
        <div className={styles.topBar}>
          <div className={styles.topBarBrand}>
            <div className={styles.topBarBrandIcon}>
              <Icons.WidgetsObjectsElements size={18} />
            </div>
            Planner
          </div>
          <HorizontalForm size="small">
            <AnalyticsOptInPopup />
            {isFFOverridesOn ? (
              <Button
                size="small"
                type="hollow"
                onClick={() => setIsFFOverridesModalOpen(true)}
              >
                FF Overrides
              </Button>
            ) : null}
            <EnvironmentSwitcher
              showButton
              enabled={
                tokenCheckResponse?.permissions.includes('impersonate') || false
              }
            />
            <Impersonate />
            <Tooltip
              target={
                <Button
                  size="medium"
                  type="cleared"
                  leadingIcon={
                    <div style={{ transform: 'translate(-2px, 1px)' }}>
                      <Icons.LogoutPowerOnOff size={18} />
                    </div>
                  }
                  onClick={() => {
                    window.location.href = getLogoutURL();
                  }}
                />
              }
              contents={`Logout ${tokenCheckResponse?.user?.email}`}
            />
          </HorizontalForm>
        </div>
      </DarkTheme>

      <UserFetcher />

      {isFFOverridesOn ? (
        <TreatmentsOverrides
          isOpen={isFFOverridesModalOpen}
          onClose={() => setIsFFOverridesModalOpen(false)}
        />
      ) : null}

      <Switch>
        {/* TODO: leaving this here for debugging for now, remove before release */}
        <Route path="/test" component={TestRouteLoaderComponent} />

        {/* New, new landing  page */}
        <Route
          path="/:organizationId"
          component={AuthedAndImpersonatedRoutes}
        />
      </Switch>
    </AuthWrapper>
  );
}

function RedirectToCurrentOrganization() {
  // If an organization is currently being impersonated, then use that organization id
  const cookieData = loadCookieData();
  if (cookieData.impersonate.enabled) {
    const organizationId = cookieData.impersonate.organizationId;
    return <Redirect to={`/${organizationId}/buildings`} />;
  }

  return (
    <AuthWrapper>
      <TokenProviderContext.Consumer>
        {(tokenProviderData) => {
          if (
            !tokenProviderData ||
            !tokenProviderData.tokenCheckResponse ||
            !tokenProviderData.tokenCheckResponse.organization
          ) {
            return (
              <FillCenter>
                Token provider organization data is missing!
              </FillCenter>
            );
          }
          return (
            <Redirect
              to={`/${tokenProviderData.tokenCheckResponse.organization.id}`}
            />
          );
        }}
      </TokenProviderContext.Consumer>
    </AuthWrapper>
  );
}

function AuthedAndImpersonatedRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/buildings`} component={BuildingList} />
      <Route
        exact
        path={`${path}/buildings/:buildingId`}
        component={BuildingManager}
      />
      <Route
        exact
        path={`${path}/floorplans/:planId`}
        component={FloorplansDetailRoute}
      />

      <Redirect to={`${path}/buildings`} />
    </Switch>
  );
}

const LoadingOverlayWithDarkAppBar: React.FunctionComponent<{
  text: string;
}> = (props) => {
  return (
    <Fragment>
      <DarkTheme>
        <AppBar />
      </DarkTheme>
      <LoadingOverlay {...props} />
    </Fragment>
  );
};

function FloorplansDetailRoute() {
  const history = useHistory();
  const search = useLocation().search;
  const nextSetupStep = useMemo(
    () => new URLSearchParams(search).get('setupstep'),
    [search]
  );

  const params = useParams<{
    organizationId: CoreOrganization['id'];
    planId: FloorplanV2Plan['id'];
  }>();
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const isWallsStepInCreationFlow = useTreatment(
    SPLITS.WALLS_STEP_IN_CREATION_FLOW
  );

  const [planData, setPlanData] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error'; statusCode: number }
    | {
        status: 'complete';
        plan: FloorplanV2Plan;
      }
  >({ status: 'pending' });
  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    setPlanData({ status: 'loading' });
    FloorplanAPI.getFloorplan(densityAPIClient, params.planId)
      .then((planResponse) => {
        // If the plan doesn't have a scale, then make sure the user goes to the first visit
        // workflow step to set a scale first.
        const isPlanNotScaled =
          planResponse.data.image_url !== null &&
          planResponse.data.image_pixels_per_meter === 0;

        switch (isPlanNotScaled ? 'scale' : nextSetupStep) {
          case 'scale': {
            setFirstVisitPlanWorkflowStep('scale');
            break;
          }
          case 'heightmap': {
            setFirstVisitPlanWorkflowStep('heightmap');
            break;
          }
        }

        setPlanData({
          status: 'complete',
          plan: planResponse.data,
        });
      })
      .catch((err) => {
        setPlanData({ status: 'error', statusCode: err.response.status });
      });

    return () => {
      setPlanData({ status: 'pending' });
    };
  }, [densityAPIClient, params.planId, nextSetupStep]);

  const [firstVisitPlanWorkflowStep, setFirstVisitPlanWorkflowStep] = useState<
    | 'scale'
    | 'scale-saving'
    | 'heightmap'
    | 'heightmap-saving'
    | 'walls'
    | 'walls-saving'
    | null
  >(null);
  const planDataId = planData.status === 'complete' ? planData.plan.id : null;
  useEffect(() => {
    if (firstVisitPlanWorkflowStep !== null) {
      return;
    }
    if (!planDataId) {
      return;
    }

    // After finishing the first setup step workflow, get rid of the query parameter
    history.replace(`/${params.organizationId}/floorplans/${planDataId}`);
  }, [history, params.organizationId, planDataId, firstVisitPlanWorkflowStep]);

  const [editNameLoading, setEditNameLoading] = useState(false);
  const onEditFloorName = useCallback(
    async (newName: CoreSpace['name']) => {
      if (!densityAPIClient) {
        return;
      }
      if (planData.status !== 'complete') {
        return;
      }

      setEditNameLoading(true);
      try {
        await CoreAPI.updateSpace(densityAPIClient, planData.plan.floor.id, {
          name: newName,
        });
      } catch (err) {
        setEditNameLoading(false);
        toast.error('Error renaming floor.');
        console.warn(`Error renaming floor: ${err}`);
        return;
      }
      setEditNameLoading(false);

      // Perform update on the plan data to use the new name once the save has completed successfully
      setPlanData({
        status: 'complete',
        plan: {
          ...planData.plan,
          floor: {
            ...planData.plan.floor,
            name: newName,
          },
        },
      });

      toast.success('Renamed floor');
    },
    [densityAPIClient, planData]
  );

  const [editStatusLoading, setEditStatusLoading] = useState(false);
  const onEditFloorStatus = useCallback(
    async (newStatus: CoreSpace['status']) => {
      if (!densityAPIClient) {
        return;
      }
      if (planData.status !== 'complete') {
        return;
      }
      const floorId = planData.plan.floor.id;
      Analytics.track('Space Status Change', { spaceId: floorId, newStatus });

      setEditStatusLoading(true);

      try {
        await CoreAPI.updateSpace(densityAPIClient, floorId, {
          status: newStatus,
        });
      } catch (err) {
        setEditStatusLoading(false);
        toast.error('Error updating space status!');
        console.error('Error updating space status:', err);
        return;
      }

      setEditStatusLoading(false);
      toast.success('Updated space status');

      // Perform update on the plan data to use the new name once the save has completed successfully
      setPlanData({
        status: 'complete',
        plan: {
          ...planData.plan,
          floor: {
            ...planData.plan.floor,
            status: newStatus,
          },
        },
      });
    },
    [densityAPIClient, planData]
  );

  // TODO: instead of padding an Image object, it might make more sense to just pass the src to
  // everything that used `planImage` because image fetching is handled by the floorplan component
  const planImage = useMemo(() => {
    const image = new Image();
    if (planData.status !== 'complete') {
      return null;
    }
    if (!planData.plan.image_url) {
      return null;
    }
    image.src = planData.plan.image_url;
    image.width = planData.plan.image_width_pixels;
    image.height = planData.plan.image_height_pixels;
    return image;
  }, [planData]);

  const [heightMap, setHeightMap] = useState<HeightMap | null>(null);
  useEffect(() => {
    if (planData.status !== 'complete') {
      return;
    }
    setHeightMap(HeightMap.fromFloorplanAPIResponse(planData.plan));
  }, [planData]);
  const [heightMapAdditionalScale, setHeightMapAdditionalScale] = useState<
    number | null
  >(null);

  if (planData.status === 'pending') {
    return null;
  }
  if (planData.status === 'loading') {
    return <LoadingOverlayWithDarkAppBar text="Loading floorplan..." />;
  }
  if (planData.status === 'error') {
    if (planData.statusCode === 404) {
      return (
        <FillCenter>
          <ErrorMessage>Floorplan {params.planId} not found!</ErrorMessage>
        </FillCenter>
      );
    }
    return (
      <FillCenter>
        <ErrorMessage>Error loading floorplan!</ErrorMessage>
      </FillCenter>
    );
  }

  switch (firstVisitPlanWorkflowStep) {
    // If there isn't a scale set on the plan, then show the measuring workflow
    case 'scale':
    case 'scale-saving':
      if (!planImage) {
        return (
          <FillCenter>
            <ErrorMessage>
              Error loading floorplan scale: image is null
            </ErrorMessage>
          </FillCenter>
        );
      }
      return (
        <FloorplanMeasure
          image={planImage}
          submitInProgress={firstVisitPlanWorkflowStep === 'scale-saving'}
          onSubmit={(measurement) => {
            if (planData.status !== 'complete') {
              return;
            }
            if (!densityAPIClient) {
              return;
            }

            setFirstVisitPlanWorkflowStep('scale-saving');

            const measurementUpdate =
              Measurement.toFloorplanScaleUpdate(measurement);

            (async () => {
              try {
                await FloorplanAPI.updateFloorplanScale(
                  densityAPIClient,
                  planData.plan.id,
                  measurementUpdate
                );
              } catch (err) {
                setFirstVisitPlanWorkflowStep('scale');
                toast.error('Error updating plan scale.');
                console.warn(`Error updating plan scale: ${err}`);
                return;
              }

              setPlanData({
                status: 'complete',
                plan: { ...planData.plan, ...measurementUpdate },
              });

              // Next, optionally go to the wall editor to allow users to add walls.
              if (!isWallsStepInCreationFlow) {
                setFirstVisitPlanWorkflowStep(null);
                return;
              }

              let totalNumberOfWallSegments: number;
              try {
                const response = await FloorplanAPI.listWallSegments(
                  densityAPIClient,
                  planData.plan.id,
                  1,
                  1
                );
                totalNumberOfWallSegments = response.data.total;
              } catch (err) {
                toast.error(
                  'Error fetching number of associated wall segments!'
                );
                console.warn(`Error fetching wall segments: ${err}`);
                return;
              }

              // After setting the scane, check to see if the plan has any walls set
              if (totalNumberOfWallSegments === 0) {
                setFirstVisitPlanWorkflowStep('walls');
              } else {
                setFirstVisitPlanWorkflowStep(null);
              }
            })();
          }}
          onCancel={() => history.push(`/${params.organizationId}/buildings`)}
        />
      );

    // If there isn't a scale set and there is a geotiff heightmap, then show the geotiff heightmap,
    // and use it to properly scale the base floorplan image.
    case 'heightmap':
    case 'heightmap-saving':
      if (!planImage) {
        return (
          <FillCenter>
            <ErrorMessage>
              Error loading floorplan heightmap: image is null
            </ErrorMessage>
          </FillCenter>
        );
      }
      if (!heightMap) {
        return (
          <FillCenter>
            <ErrorMessage>
              Error loading floorplan heightmap: heightmap is null!
            </ErrorMessage>
          </FillCenter>
        );
      }
      return (
        <HeightMapImport
          floorplan={Floorplan.fromFloorplanAPIResponse(planData.plan)}
          floorplanImage={planImage}
          displayUnit="feet_and_inches"
          heightMap={heightMap}
          heightMapAdditionalScale={heightMapAdditionalScale}
          onChangeHeightMapAdditionalScale={setHeightMapAdditionalScale}
          submitInProgress={firstVisitPlanWorkflowStep === 'heightmap-saving'}
          onChangeRegistration={(position, rotation) =>
            setHeightMap((heightMap) =>
              heightMap
                ? {
                    ...heightMap,
                    position,
                    rotation,
                  }
                : null
            )
          }
          onChangeBounds={(minMeters, maxMeters) =>
            setHeightMap((heightMap) =>
              heightMap
                ? {
                    ...heightMap,
                    limits: { enabled: true, minMeters, maxMeters },
                  }
                : null
            )
          }
          onRotateRight90={() =>
            setHeightMap((heightMap) =>
              heightMap
                ? {
                    ...heightMap,
                    rotation: modulo(heightMap.rotation + 90, 360),
                  }
                : null
            )
          }
          onChangeOpacity={(opacity) =>
            setHeightMap((heightMap) =>
              heightMap
                ? {
                    ...heightMap,
                    opacity,
                  }
                : null
            )
          }
          onSubmit={(heightMap) => {
            if (planData.status !== 'complete') {
              return;
            }
            if (!densityAPIClient) {
              return;
            }

            setFirstVisitPlanWorkflowStep('heightmap-saving');

            const heightMapUpdate = HeightMap.toFloorplanAPIResponse({
              ...heightMap,
              position:
                heightMapAdditionalScale === null
                  ? heightMap.position
                  : FloorplanCoordinates.create(
                      heightMap.position.x / heightMapAdditionalScale,
                      heightMap.position.y / heightMapAdditionalScale
                    ),
            });
            const measurement = Measurement.fromFloorplanAPIResponse(
              planData.plan
            );
            if (heightMapAdditionalScale !== null) {
              measurement.computedScale *= heightMapAdditionalScale;
              measurement.computedLength *= heightMapAdditionalScale;
            }
            const measurementUpdate =
              Measurement.toFloorplanScaleUpdate(measurement);

            (async () => {
              try {
                await FloorplanAPI.updateFloorplanScale(
                  densityAPIClient,
                  planData.plan.id,
                  measurementUpdate
                );
              } catch (err) {
                setFirstVisitPlanWorkflowStep('heightmap');
                toast.error('Error updating floorplan scale.');
                console.warn(`Error updating floorplan scale: ${err}`);
                return;
              }

              try {
                await FloorplanAPI.updateCeilingRaster(
                  densityAPIClient,
                  planData.plan.id,
                  heightMapUpdate.ceiling_raster_key,
                  heightMapUpdate.ceiling_raster_floorplan_origin_x,
                  heightMapUpdate.ceiling_raster_floorplan_origin_y,
                  heightMapUpdate.ceiling_raster_floorplan_angle_degrees,
                  heightMapUpdate.ceiling_raster_notes,
                  heightMapUpdate.ceiling_raster_opacity_percent,
                  heightMapUpdate.ceiling_raster_min_height_limit_meters,
                  heightMapUpdate.ceiling_raster_max_height_limit_meters
                );
              } catch (err) {
                setFirstVisitPlanWorkflowStep('heightmap');
                toast.error('Error updating heightmap.');
                console.warn(`Error updating heightmap: ${err}`);
                return;
              }

              setPlanData({
                status: 'complete',
                plan: {
                  ...planData.plan,
                  ...heightMapUpdate,
                  ...measurementUpdate,
                },
              });

              // Next, optionally go to the wall editor to allow users to add walls.
              if (!isWallsStepInCreationFlow) {
                setFirstVisitPlanWorkflowStep(null);
                return;
              }

              let totalNumberOfWallSegments: number;
              try {
                const response = await FloorplanAPI.listWallSegments(
                  densityAPIClient,
                  planData.plan.id,
                  1,
                  1
                );
                totalNumberOfWallSegments = response.data.total;
              } catch (err) {
                toast.error(
                  'Error fetching number of associated wall segments!'
                );
                console.warn(`Error fetching wall segments: ${err}`);
                return;
              }

              // After setting the scane, check to see if the plan has any walls set
              if (totalNumberOfWallSegments === 0) {
                setFirstVisitPlanWorkflowStep('walls');
              } else {
                setFirstVisitPlanWorkflowStep(null);
              }
            })();
          }}
          onCancel={() => history.push(`/${params.organizationId}/buildings`)}
        />
      );

    // After scaling, draw walls
    case 'walls':
    case 'walls-saving':
      if (!planImage) {
        return (
          <FillCenter>
            <ErrorMessage>
              Error loading floorplan walls editor: image is null
            </ErrorMessage>
          </FillCenter>
        );
      }
      return (
        <WallsEditor
          floorplan={Floorplan.fromFloorplanAPIResponse(planData.plan)}
          floorplanImage={planImage}
          displayUnit="feet_and_inches"
          submitInProgress={firstVisitPlanWorkflowStep === 'walls-saving'}
          onSubmit={(walls) => {
            setFirstVisitPlanWorkflowStep('walls-saving');

            if (planData.status !== 'complete') {
              return;
            }
            if (!densityAPIClient) {
              return;
            }

            const updates = FloorplanCollection.list(walls).map((wall) => ({
              type: 'plan.wall_segment.create' as const,
              body: WallSegment.toFloorplanWallSegment(wall),
            }));

            FloorplanAPI.bulk(densityAPIClient, planData.plan.id, updates)
              .then(() => {
                // Once walls are placed, go to the plan!
                setFirstVisitPlanWorkflowStep(null);
              })
              .catch((err) => {
                toast.error('Error updating wall segments.');
                console.warn(`Error updating wall segments: ${err}`);
              });
          }}
        />
      );

    default:
      return (
        <FloorPlanning
          plan={planData.plan}
          planImage={planImage || null}
          editNameLoading={editNameLoading}
          onEditName={onEditFloorName}
          editStatusLoading={editStatusLoading}
          onEditStatus={onEditFloorStatus}
        />
      );
  }
}
