import reduxWebsocket from '@giantmachines/redux-websocket';

import { AVAILABILITY_SOCKET_PREFIX, OA_LIVE_SOCKET_PREFIX } from 'lib/redux';

// create availability websocket middleware instance
// we can have multiple instances of this middleware for multiple instances of websockets
export const availabilityWebsocketMiddleware = reduxWebsocket({
  prefix: AVAILABILITY_SOCKET_PREFIX,
});

export const OALiveWebsocketMiddleware = reduxWebsocket({
  prefix: OA_LIVE_SOCKET_PREFIX,
});
