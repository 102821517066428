import styles from './styles.module.scss';

interface LabelFrameProps {
  label: string;
  type: 'full' | 'half' | 'top';
}

export const LabelFrame: React.FC<LabelFrameProps> = ({
  children,
  label,
  type,
}) => {
  const frameType =
    type === 'full'
      ? styles.fullFrame
      : type === 'half'
      ? styles.halfFrame
      : styles.topFrame;
  return (
    <div className={frameType}>
      <label className={styles.title}>{label}</label>
      {children}
    </div>
  );
};
