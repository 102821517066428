import { TokenCheckResponse } from '@densityco/lib-common-auth';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';

type AuthState = {
  tokenCheckResponse?: TokenCheckResponse | null;
  densityAPIClient?: AxiosInstance | null;
  appAPIClient?: AxiosInstance | null;
};

const initialState: AuthState = {
  tokenCheckResponse: undefined,
  densityAPIClient: undefined,
  appAPIClient: undefined,
};

// use createAction + extraReducers for optimal type safety
const setAuthState = createAction<{
  tokenCheckResponse: TokenCheckResponse;
  densityAPIClient: AxiosInstance;
  appAPIClient: AxiosInstance;
}>('auth/setAuthState');

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  // https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers
  extraReducers: (builder) => {
    builder.addCase(setAuthState, (state, action) => {
      state.tokenCheckResponse = action.payload.tokenCheckResponse;
      state.densityAPIClient = action.payload.densityAPIClient;
      state.appAPIClient = action.payload.appAPIClient;
    });
  },
});

// export actions as named exports
export { setAuthState };

// export reducer as default expoort
export default authSlice.reducer;
