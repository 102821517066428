import { useState, useEffect } from 'react';
import * as React from 'react';
import { Modal as DensityUiModal } from '@densityco/ui';

type ModalProps = {
  visible: boolean;
  width?: string | number;
  height?: string | number;
  onBlur?: () => void;
  onEscape?: () => void;
};

const Modal: React.FunctionComponent<ModalProps> = ({ visible, ...props }) => {
  // TODO: This is a hack that needs to be upstreamed into density ui in order
  // to properly let the modal open and close without the animation behavior
  // acting weirdly.
  const [openModal, setOpenModal] = useState(true);
  const [renderModal, setRenderModal] = useState(false);
  useEffect(() => {
    let unmounted = false;
    if (visible) {
      setRenderModal(true);
      setOpenModal(true);
    } else {
      setOpenModal(false);
      setTimeout(() => {
        if (!unmounted) {
          setRenderModal(false);
        }
      }, 500);
    }

    return () => {
      unmounted = true;
    };
  }, [visible]);

  if (renderModal) {
    return <DensityUiModal {...props} visible={openModal} />;
  } else {
    return null;
  }
};

export default Modal;
