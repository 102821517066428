import { useCallback, useLayoutEffect, useRef } from 'react';

type Fn<Args extends any[], Return> = (...args: Args) => Return;

export const useEvent = <A extends any[], R>(fn: Fn<A, R>): Fn<A, R> => {
  const ref = useRef<Fn<A, R>>(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: A): R => {
    return ref.current(...args);
  }, []);
};
